import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';

import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';

export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-offline-iap-complete',
    templateUrl: './meraemi-offline-iap-complete.component.html',
    styleUrls: ['./meraemi-offline-iap-complete.component.scss']
})
export class MeraemiOfflineIapCompleteComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id"
     },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
       name:"Loan Amount",
       sortBy:"Amount"
     },
     
     {
       name:"Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
     {
      name:"Lender",
      sortBy:"Lender Name"
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     {
       name:"View",
       sortBy:"send_message"
     },

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id: any;
  to_user_id: any;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  messages: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  
  
 
  constructor( 
      private cryptojsService:CryptojsService,
      //private firebasedataService:FirebasedataService,      
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,) { }

  ngOnInit() {
    this.optionsSelect= [

      { value: 'WEB', label: 'WEB' },      
      { value: 'BOT', label: 'BOT' },
      
      ];  
      this.optionsStage= [
      { value: 'Eligibility Pending', label: 'Eligibility Pending' },      
      { value: 'Eligibility Clear', label: 'Eligibility Clear' },      
      { value: 'Eligibility Fail', label: 'Eligibility Fail' },
      { value: 'IAP Pending', label: 'IAP Pending' },
      { value: 'IAP Complete', label: 'IAP Complete' },
      { value: 'IAP Error', label: 'IAP Error' },
      { value: 'IAP Fail', label: 'IAP Fail' },
      { value: 'IAP Success', label: 'IAP Success' },    
      { value: 'Success', label: 'Success' },
        
        ];  
        
        this.leadsService
        .getCityname()
        .subscribe( (result:any) => {
        //console.log(result.filter_city);
                if (result.status = 'true') {
                  
                  for (let i = 0; i < result.filter_city.length; i++) {
                    const menuarray = result.filter_city[i];
                   //console.log(menuarray.data[0].pincode);
                   if(menuarray.data[0].location !=undefined){
                    this.messages.push(
                      {
                        value: menuarray.data[0].location.toUpperCase(),
                        label: menuarray.data[0].location.toUpperCase(),                          
                       
                      }
                      );
                      //console.log(this.messages);
                     }
                  
                     
                      
                  }
                  this.optionslocation =this.messages;
                  //console.log(this.optionslocation);
                }else{
                  console.log('Location Api faile');
                }
                
              });




    this.registerForm = this.formBuilder.group({
      idFilter: this.formBuilder.group({
        fromuserid: ['', Validators.required],
        touserid: ['', Validators.required],
      }),
      sourceFilter: this.formBuilder.group({
        source: ['', Validators.required]
      }),
      adidFilter: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      loanFilter: this.formBuilder.group({
        fromLonAmt: ['', Validators.required],
        toLonAmt: ['', Validators.required],
      }),
      stageFilter: this.formBuilder.group({
        stage: ['', Validators.required]
       
      }),
      dateFilter: this.formBuilder.group({
        fromdate: ['', Validators.required],
        todate: ['', Validators.required],
       
      }),
      locationFilter: this.formBuilder.group({
        location: ['', Validators.required],
       
       
      })
    });
    this.leadsService
    .getAllAdId()
    .subscribe( (result:any) => {
      //
             if (result.status = 'true') {
               
               for (let i = 0; i < result.fb_adid.length; i++) {
                 const menuarray = result.fb_adid[i];
               // console.log(menuarray);
                if(menuarray.data[0].ad_id !=0){
                 this.All_ad_id.push(
                   {
                    
                                                       
                    
                    value: menuarray.data[0].ad_id,
                    label: menuarray.data[0].ad_id,
                   }
                   );
                   
                  }
               
                  
                   
               }
               this.optionsadid =this.All_ad_id;
               
             }else{
              console.log('Ad Api faile');
            }
             
           });
          // console.log(this.All_ad_id);
    
    this.leadsService
    .getAllLeads()
    .subscribe( (result:any) => {
     // console.log(result);
      if (result.status = 'true') {              
        for (let i = 0; i < result.leads.length; i++) {
          const element = result.leads[i];
          
           // console.log(element);
            this.stage_color = "info-color";
            
               if(element.data[0].app_stage == 0){
               // this.stage ='Lead';
                this.appstage ='Eligibility Pending';
                this.stage_color = "info-color";
                this.urlstage ='Eligibility_Pending';

            
               }else if(element.data[0].app_stage == '1'){
                 
                  if(element.data[0].elig_status == "1"){
                    this.elig_status ='Eligibility Clear';
                    this.urlstage ='Eligibility_Clear';
                    this.stage_color = "info-color";
                  }else if(element.data[0].elig_status == "0"){
                    this.elig_status ='Eligibility Fail';
                    this.urlstage ='Eligibility_fail';
                    this.stage_color = "info-color";
                  }else{
                    this.elig_status ='Eligibility Pending';
                    this.urlstage ='Eligibility_Pending';
                    this.stage_color = "info-color";
                  }
                 // console.log( this.elig_status)
                  this.appstage = this.elig_status;
            
              }else if(element.data[0].app_stage == 2){
                
                  this.appstage ="IAP Pending";
                  this.urlstage ='IAP_Pending';
                  this.stage_color = "info-color";
            
              }else if(element.data[0].app_stage == 3){
                 if(element.data[0].trx_status != undefined){
                 
                  if(element.data[0].sfdc_id != "null" || element.data[0].trx_status == 'DDE' || element.data[0].trx_status == 'QDE'){
                 
                    this.appstage ="IAP Complete";
                    this.urlstage ='IAP_Complete';
                    this.stage_color = "info-color";
                  }if(element.data[0].sfdc_id == "null" || element.data[0].trx_status == 'Cancel'){
                  
                    this.appstage ="IAP Error";
                    this.urlstage ='IAP_Error';
                    //console.log(this.stage);
                    this.stage_color = "info-color";
                  } if(element.data[0].trx_status == "Reject"){
                 
                    this.appstage ="IAP Fail";
                    this.urlstage ='IAP_Fail';
                    this.stage_color = "info-color";
                
                  }
                  if(element.data[0].trx_status == 'Data Verification'){
                    this.appstage = "IAP Success";
                    this.urlstage ='IAP_Success';
                  }
               
                }else {

                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                  this.stage_color = "info-color";
                }
             
                if(element.data[0].resp_status_code != undefined){
            
                  if(element.data[0].resp_status_code == "1" ){
                     this.appstage ="IAP Complete";
                     this.urlstage ='IAP_Complete';
                   }
                   if(element.data[0].resp_status_code == "0" || element.data[0].resp_status_code == "3" || element.data[0].resp_status_code == "4"){
                     this.appstage ="IAP Fail";
                     this.urlstage ='IAP_Fail';
                   }
                 }
                
                
              }else if(element.data[0].app_stage == 4){
                this.appstage = 'Document Upload';
                this.stage_color = "info-color";
              }else if(element.data[0].app_stage == 5){
                this.appstage = 'Meeting Scheduler';
                this.stage_color = "info-color";
              }else if(element.data[0].app_stage == 6){
                this.appstage = 'Success';
                this.urlstage ='Success';
                this.stage_color = "info-color";
              }else{
                this.appstage = '';
              }
              if(element.data[0].provider_id == "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }
              if(element.data[0].lender_name !=null){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else{
                  this.lender_name = element.data[0].lender_name;
                }

                
               }else{
                this.lender_name = "Lender Not Selected";
               }
               if(this.lender_name === "Offline" && this.appstage ==="IAP Complete"){
                 //console.log(this.stage);
                 this.elements.push(
                  {
                    user_id: element.data[0].user_id, 
                    first_name:  element.data[0].first_name, 
                    last_name:  element.data[0].last_name,              
                    provider: this.provider,
                    city_name: element.data[0].city_name,
                    loan_amount: element.data[0].loan_amount,
                    ad_id: element.data[0].ad_id,
                    reg_date: element.data[0].date,
                    status: this.appstage,
                    feedbackstage:this.urlstage,
                    lender_name:this.lender_name
                  }
                  );
                  this.element_main.push(
                    {
                      user_id: element.data[0].user_id, 
                      first_name:  element.data[0].first_name, 
                      last_name:  element.data[0].last_name,              
                      provider: this.provider,
                      city_name: element.data[0].city_name,
                      loan_amount: element.data[0].loan_amount,
                      ad_id: element.data[0].ad_id,
                      reg_date: element.data[0].date,
                      status: this.appstage,
                      feedbackstage:this.urlstage,
                      lender_name:this.lender_name
 
                    }
                    );


               }
                           

            }
            //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  adidSubmitted: boolean = false;
  useridSubmitted: boolean = false;
  sourceSubmitted: boolean = false;
  loanSubmitted: boolean = false;
  stageSubmitted: boolean = false;
  dateSubmitted: boolean = false;
  locationSubmitted: boolean = false;
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }

  get fc() { return (<FormGroup>this.registerForm.get('sourceFilter')).controls; }
  get fa() { return (<FormGroup>this.registerForm.get('adidFilter')).controls; }
  get fd() { return (<FormGroup>this.registerForm.get('loanFilter')).controls; }
  get fg() { return (<FormGroup>this.registerForm.get('stageFilter')).controls; }
  get fh() { return (<FormGroup>this.registerForm.get('dateFilter')).controls; }
  get fi() { return (<FormGroup>this.registerForm.get('locationFilter')).controls; }

  get idFilter() {
    return this.registerForm.get('idFilter');
  }

  get sourceFilter() {
    return this.registerForm.get('sourceFilter');
  }
 get adidFilter(){
  return this.registerForm.get('adidFilter');
}
get loanFilter(){
  return this.registerForm.get('loanFilter');
}get stageFilter(){
  return this.registerForm.get('stageFilter');
}
get dateFilter(){
  return this.registerForm.get('dateFilter');
}
get locationFilter(){
  return this.registerForm.get('locationFilter');
}

 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
    //filter user id
    filterUserId(){
      this.useridSubmitted = true;
      // stop here if form is invalid
      if (this.idFilter.invalid) {
        return;
      }else{
        //console.log("ok");
        this.valuemain=1;
        this.userid =1;
        this.activeClass ="activbutton";
        this.display = "none";
        //user id
        this.from_user_id =this.idFilter.value.fromuserid;
        this.to_user_id =this.idFilter.value.touserid;
        const data = this.mdbTable.getDataSource();
        const toid = this.to_user_id;
        const  fromid = this.from_user_id;
        var range = {
          min: fromid,
          max: toid
        };
        var newArray = data.filter(function (el) {
          return el.user_id <= toid &&
                 el.user_id >= fromid  ;
        });
        this.result = data.filter(d => {
          const  id= d.user_id;                
          return id >= fromid  && id  <=  toid ;    
          //return (id >= fromid && id  <=  toid);    
          
        });
       
        console.log(newArray);
        console.log(this.result);
        this.elementsfilter.length=0;
        if(this.result.length > 0){
        for (let i = 0; i < this.result.length; i++) {
          const elements = this.result[i];
          //console.log(elements);
          this.stage_color = "info-color";

          this.elementsfilter.push(
            {
            user_id: elements.user_id, 
            first_name:  elements.first_name, 
            last_name:  elements.last_name,              
            provider: elements.provider,
            city_name: elements.city_name,
            loan_amount: elements.loan_amount,
            ad_id: elements.ad_id,
            reg_date: elements.reg_date,
            status: elements.status,
            feedbackstage: elements.feedbackstage,
            lender_name: elements.lender_name,
            }
            );
           //
        }
      }else{
        this.messageDiv=1;
        this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
        this.clearid=11;
      }
        
        this.mdbTable.setDataSource(this.elementsfilter);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();      
        
                  
    }

      }
      //filter user source
      filterSource(){
        this.sourceSubmitted = true;
        if (this.sourceFilter.invalid) {
          return;
        }else{
          this.valuemain=1;           
          this.sourcename=1;
          this.basicModal2.hide();
          this.activeSource ="activbutton";
          //source
          this.source =this.sourceFilter.value.source;
          
          
          const data = this.mdbTable.getDataSource();
 
          const  source = this.source;
         
          let filtered = [];
          
          source.filter(function(newsourceData) {
            return   data.filter(function(mainArray) {
                if (newsourceData === mainArray.provider) {
                filtered.push({
                user_id:     mainArray.user_id, 
                first_name:  mainArray.first_name, 
                last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,

                
                })
              }
            })
          });
          this.elementsSource.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];       
                this.stage_color = "info-color";
        
                this.elementsSource.push(
                  {
                  user_id: element.user_id, 
                  first_name:  element.first_name, 
                  last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Source'+"  "  +this.source;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elementsSource);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          






        
        }
      }
      //ad id
      filterAdid() {
        this.adidSubmitted = true;
        if (this.adidFilter.invalid) {
          //return;
          alert('add value');
        }else{
          this.valuemain=1;           
          this.ad_id=1;
          this.basicModal4.hide();
          this.activeadid ="activbutton";
          // add id
          this.adid =this.adidFilter.value.adid;
          //console.log(this.adid);
          //console.log(this.result);
          const data = this.mdbTable.getDataSource();
         
          const  adid = this.adid;
         
          let filtered = [];
          
          adid.filter(function(newadid) {
            return   data.filter(function(mainArray) {
                if (newadid === mainArray.ad_id) {
                filtered.push({
                user_id:     mainArray.user_id, 
                first_name:  mainArray.first_name, 
                last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,

                
                })
              }
            })
          });
          //console.log(filtered);
          this.elements_adid.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];
              
                this.stage_color = "info-color";
        
                this.elements_adid.push(
                  {
                  user_id: element.user_id, 
                  first_name:  element.first_name, 
                  last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Add id'+"  "  +this.adid;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elements_adid);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
          
        }
      }
      //loan amt filter
      filterLonAmt(){
        this.loanSubmitted = true;    
          // stop here if form is invalid
          if (this.loanFilter.invalid) {
            return;
          }else{
            
            
            this.valuemain=1;           
            this.loan_amt=1;
            this.basicModal.hide();
            this.activeloan ="activbutton";
            //Loan amt
            this.fromLonAmt =this.loanFilter.value.fromLonAmt;
            this.toLonAmt =this.loanFilter.value.toLonAmt;
            const data = this.mdbTable.getDataSource();
           
            const  toamt = this.toLonAmt;
            const  fromamt = this.fromLonAmt;

              
              //user id
              this.result = data.filter(d => {
                const  loanamt= d.loan_amount;
                //return (fromamt  <= loanamt && loanamt  <=  toamt );
                return (loanamt >= fromamt  && loanamt  <=  toamt );

                
              });
              this.elementsLoan.length=0;
            for (let i = 0; i < this.result.length; i++) {
              const element = this.result[i];
            // console.log(element);
              this.stage_color = "info-color";

              this.elementsLoan.push(
                {
                user_id: element.user_id, 
                first_name:  element.first_name, 
                last_name:  element.last_name,              
                provider: element.provider,
                city_name: element.city_name,
                loan_amount: element.loan_amount,
                ad_id: element.ad_id,
                reg_date: element.reg_date,
                status: element.status,
                feedbackstage: element.feedbackstage,
                lender_name: element.lender_name,
                }
                );
              // console.log(this.elementsfilter);
            }
            this.mdbTable.setDataSource(this.elementsLoan);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          //loan_amount
          
            //console.log(this.toLonAmt);
          }
      }
      // satge filter
      filterstage(){
        this.stageSubmitted = true;    
          // stop here if form is invalid
          if (this.stageFilter.invalid) {
            return;
          }else{
            
            this.valuemain=1;
            this.loan_stage =1;
            this.activestage ="activbutton";
            this.basicModal3.hide();
            //elig stage
            this.stage =this.stageFilter.value.stage;
            // add id
            
            const data = this.mdbTable.getDataSource();
 
            const  stage = this.stage;
            let filtered = [];
          
            stage.filter(function(newStageData) {
            return   data.filter(function(mainArray) {
                if (newStageData === mainArray.status) {
                filtered.push({
                user_id:     mainArray.user_id, 
                first_name:  mainArray.first_name, 
                last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,

                
                })
              }
            })
          });
            //console.log(filtered);
            this.elements_stage.length=0;
            if(filtered.length > 0){
              for (let i = 0; i < filtered.length; i++) {
                  const element = filtered[i];
              
                  this.stage_color = "info-color";
          
                  this.elements_stage.push(
                    {
                    user_id: element.user_id, 
                    first_name:  element.first_name, 
                    last_name:  element.last_name,              
                    provider: element.provider,
                    city_name: element.city_name,
                    loan_amount: element.loan_amount,
                    ad_id: element.ad_id,
                    reg_date: element.reg_date,
                    status: element.status,
                    feedbackstage: element.feedbackstage,
                    lender_name: element.lender_name,
                    }
                    );
                  // console.log(this.elementsfilter);
                }
              }else{
              this.messageDiv=1;
              this.message ='Record not for stage'+"  "  +this.stage;
              this.clearid=14;
             
             
            }
            this.mdbTable.setDataSource(this.elements_stage);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          }
      }
      //date
      filterdate(){
        this.dateSubmitted = true;    
        // stop here if form is invalid
        if (this.dateFilter.invalid) {
          return;
        }else{
          this.valuemain=1;
          this.loan_date =1;
          this.activedate ="activbutton";
          this.frame.hide();

          //elig date
          this.fromdate =this.dateFilter.value.fromdate;
          this.todate =this.dateFilter.value.todate;
            const data = this.mdbTable.getDataSource();
              
              const  fromdate = this.fromdate;
              const  todate = this.todate;
              
            //user id
            this.result = data.filter(d => {
              const  reg_date= d.reg_date;
              const regdate = reg_date.split(' ')[0];
              //return (fromdate  <= regdate && regdate  <=  todate );            
              return (regdate >= fromdate  && regdate  <=  todate );
              
            });
            //console.log(this.result);
            this.elementsdate.length=0;
            if(this.result.length > 0){
              for (let i = 0; i < this.result.length; i++) {
                const element = this.result[i];
            
                this.stage_color = "info-color";
  
                this.elementsdate.push(
                  {
                  user_id: element.user_id, 
                  first_name:  element.first_name, 
                  last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  }
                  );
                // console.log(this.elementsfilter);
              }
            }else{
              this.messageDiv=1;
              this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
              this.clearid=14;
             
             
            }
           
            
            this.mdbTable.setDataSource(this.elementsdate);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            
        
        }
      }
      filterlocation(){
        //console.log(this.elements);
        //console.log(this.locationFilter.value.location);
        this.locationSubmitted = true;    
        //
        if (this.myControl.invalid) {
          return;
        }else{
         // console.log(this.elementscityname);
          
          this.valuemain=1;
          this.city_name =1;
          this.activeLocation ="activbutton";
          this.basicModal1.hide();
          //fiter location
          this.cityname =this.locationFilter.value.location;
          
            const  data = this.mdbTable.getDataSource();
             
                const  cityname = this.cityname;
               // console.log(cityname);
                let filtered = [];
                
                cityname.filter(function(newCityData) {
                  return   data.filter(function(mainArray) {
                      if (newCityData === mainArray.city_name) {
                      filtered.push({
                      user_id:     mainArray.user_id, 
                      first_name:  mainArray.first_name, 
                      last_name:   mainArray.last_name,              
                      provider:    mainArray.provider,
                      city_name:   mainArray.city_name,
                      loan_amount: mainArray.loan_amount,
                      ad_id:       mainArray.ad_id,
                      reg_date:    mainArray.reg_date,
                      status:      mainArray.status,
                      feedbackstage: mainArray.feedbackstage,
                      lender_name: mainArray.lender_name,

                      
                      })
                    }
                  })
                });
                
              
//console.log(filtered);
                this.elementscityname.length=0;
              if(filtered.length > 0){
                for (let i = 0; i < filtered.length; i++) {
                    const element = filtered[i];
                  // 
                    this.stage_color = "info-color";
            
                    this.elementscityname.push(
                      {
                      user_id: element.user_id, 
                      first_name:  element.first_name, 
                      last_name:  element.last_name,              
                      provider: element.provider,
                      city_name: element.city_name,
                      loan_amount: element.loan_amount,
                      ad_id: element.ad_id,
                      reg_date: element.reg_date,
                      status: element.status,
                      feedbackstage: element.feedbackstage,
                      lender_name: element.lender_name,

                      }
                      );
                    // console.log(this.elementsfilter);
                  }
              }else{
                this.messageDiv=1;
                this.message ='Record not for city name'+"  "  +this.cityname;
                this.clearid=14;
               
               
              }
              this.mdbTable.setDataSource(this.elementscityname);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
          
        }
      }   
      //master cleare dynamic value
      clearUserid(user_id){
       // console.log(user_id);
        //this.cityname;
        this.messageDiv=0;
        this.message=null;
        this.clearid=null;
        this.filterarray.length = 0;
        //this.result.length=0;
        this.filtered_people.length=0;
        if(user_id ===10){
          //user id
          this.elements.length = 0;
          this.clear_stagr.length = 0;
          this.valuemain=1;
          this.userid =0;
          this.activeClass ="savemessage";
          this.display = "none";
          this.idFilter.reset();
         
        }else if(user_id ===11){
          //source
          this.clear_stagr.length = 0;
          this.elements.length = 0;
          this.valuemain=1;           
          this.sourcename=0;
          this.basicModal2.hide();
          this.activeSource ="savemessage";
          this.sourceFilter.reset(); 
          //this.filtered_people.length=0;
             
        }else if(user_id ===12){
          //Location
          this.clear_stagr.length = 0;
          this.elements.length = 0;       
          this.valuemain=1;
          this.city_name =0;
          this.activeLocation ="savemessage";
          this.basicModal1.hide();
          this.locationFilter.reset(); 
          //this.filtered_people.length=0;
          
        }else if(user_id ===13){
          //loan amt
          this.clear_stagr.length = 0;
          this.elements.length = 0;
          this.valuemain=1;           
          this.loan_amt=0;
          this.basicModal.hide();
          this.activeloan ="savemessage";
          this.loanFilter.reset();
         
    
        }else if(user_id ===14){
          //date
          this.elements.length = 0;    
          this.valuemain=1;           
          this.loan_date=0;
          this.basicModal.hide();     
          this.activedate ="savemessage"; 
          this.dateFilter.reset();
         
          
        }else if(user_id ===15){
          //add id
          this.clear_stagr.length = 0;
          this.elements.length = 0;    
          this.valuemain=1;           
          this.ad_id=0;
          this.basicModal.hide();       
          this.activeadid ="savemessage";  
          this.adidFilter.reset(); 
          //this.filtered_people.length=0;
          
          
        }else if(user_id ===16){
          //stage
          this.clear_stagr.length = 0;
          this.elements.length = 0;
          this.valuemain=1;           
          this.loan_stage=0;
          this.basicModal.hide();
          this.activestage ="savemessage"; 
          this.stageFilter.reset();
          //this.filtered_people.length=0;
         
        }
            // user id
            if(this.idFilter.value.fromuserid !='' && this.idFilter.value.touserid !='')
            {
              this.from_user_id =this.idFilter.value.fromuserid;
              this.to_user_id =this.idFilter.value.touserid;
                         
            }else {
              this.from_user_id =null;
              this.to_user_id =null;
            }
           
            // source
            if(this.sourceFilter.value.source !=''){
              this.source =this.sourceFilter.value.source;
              this.filterarray.push(this.source);
            }else {
              this.source =null;
              this.filterarray.push(this.source);
            }
            
            //city name
            if( this.locationFilter.value.location !='')
            {
              this.user_city =this.locationFilter.value.location;
              this.filterarray.push(this.user_city);
            }else {
              this.user_city = null;
              this.filterarray.push(this.user_city);
            } 
            
            //loan amt
            if(this.loanFilter.value.fromLonAmt !='' && this.loanFilter.value.toLonAmt !=''){
              this.fromLonAmt =this.loanFilter.value.fromLonAmt;
              this.toLonAmt =this.loanFilter.value.toLonAmt;
          
            }else {
              this.fromLonAmt =null;
              this.toLonAmt =null;
            }
            // add id
            if(this.adidFilter.value.adid !=''){
              this.adid =this.adidFilter.value.adid;
              this.filterarray.push(this.adid);
            }else {
              this.adid =null;
              this.filterarray.push(this.adid);
            }  
            
            //elig date
           // console.log(this.dateFilter.value.fromdate);
            if(this.dateFilter.value.fromdate !='' && this.dateFilter.value.todate !='')
            {
            this.fromdate =this.dateFilter.value.fromdate;
            this.todate =this.dateFilter.value.todate;
            
            }else {
              this.fromdate =null;
              this.todate =null;
            }
            
            //stage
            if(this.stageFilter.value.stage !=''){
              this.stage =this.stageFilter.value.stage;
              this.filterarray.push(this.stage);
            }else {
              this.stage =null;
              this.filterarray.push(this.stage);
            }
            
          //console.log(this.filterarray);
              
          
           var filter = {
              provider : this.filterarray[0],
              city_name : this.filterarray[1],
              ad_id : this.filterarray[2],
              status : this.filterarray[3],
          }
         
          //console.log(filter);
          const  data = this.element_main;
          if(filter.ad_id != null &&   filter.city_name != null && filter.provider != null && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)) && (filter.city_name.includes(person.city_name)) && (filter.status.includes(person.status)) && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null &&   filter.city_name != null && filter.provider != null ){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)) && (filter.city_name.includes(person.city_name))  && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null &&   filter.city_name != null  && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)) && (filter.city_name.includes(person.city_name)) && (filter.status.includes(person.status)) )
           
          }else if(filter.ad_id != null  && filter.provider != null && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id))  && (filter.status.includes(person.status)) && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null  && filter.provider != null && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)) && (filter.status.includes(person.status)) && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null &&   filter.city_name != null ){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)) && (filter.city_name.includes(person.city_name)) )
           
          }else if(filter.ad_id != null &&     filter.provider != null ){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id))  && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null  && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id))  && (filter.status.includes(person.status)) )
           
          }else if(filter.city_name != null && filter.provider != null ){
            this.filtered_people = data.filter((person) => (filter.city_name.includes(person.city_name)) && (filter.provider.includes(person.provider)))
           
          }else if(filter.city_name != null  && filter.status != null){
            //console.log('5454');
            this.filtered_people = data.filter((person) =>  (filter.city_name.includes(person.city_name)) && (filter.status.includes(person.status)) )
           
          }else if(filter.provider != null && filter.status != null){
            this.filtered_people = data.filter((person) => (filter.status.includes(person.status)) && (filter.provider.includes(person.provider)))
           
          }else if(filter.ad_id != null ){
            this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)))
           
          }else if(filter.city_name != null ){
            this.filtered_people = data.filter((person) => (filter.city_name.includes(person.city_name)) )
           
          }else if(filter.provider != null ){
            this.filtered_people = data.filter((person) => (filter.provider.includes(person.provider)))
           
          }else if(filter.status != null){
            this.filtered_people = data.filter((person) => (filter.status.includes(person.status)))
           
          }
         // console.log(this.filtered_people.length);
          if(this.filtered_people.length != 0)
          {
            this.arrayele =this.filtered_people;
           // console.log(this.arrayele);
          }else {
            this.arrayele =data;
            this.result.length=0;
            //console.log(this.arrayele);
          }
          //console.log(this.arrayele.length);
           const from_user_id= this.from_user_id;
           const to_user_id= this.to_user_id;
           const from_Amt= this.fromLonAmt;
           const to_Amt= this.toLonAmt;
           const from_date= this.fromdate;
           const to_date= this.todate;
          // console.log(from_user_id);
          // console.log(to_user_id);
          // console.log(from_Amt);
           //console.log(to_Amt);
           //console.log(from_date);
           //console.log(to_date);
          // console.log(this.arrayele.length);
          
        if(from_user_id !=null && to_user_id !=null && from_Amt !=null && to_Amt !=null  &&  from_date !=null &&  to_date !=null ){
          this.result =  this.arrayele.filter(d => {   
            const  id= d.user_id;
            const  loanamt= d.loan_amount;
            const  reg_date= d.reg_date;
            const regdate = reg_date.split(' ')[0];
            

             return  ((id >= from_user_id && id  <=  to_user_id) && (loanamt >= from_Amt  && loanamt  <=  to_Amt ) && (regdate >= from_date  && regdate  <=  to_date ));            
                 
               });
        }else  if(from_user_id !=null && to_user_id !=null && from_Amt !=null && to_Amt !=null   ){
          this.result =  this.arrayele.filter(d => {     
            const  id= d.user_id;
            const  loanamt= d.loan_amount;   
             return  ((id >= from_user_id && id  <=  to_user_id) && (loanamt >= from_Amt  && loanamt  <=  to_Amt ) );            
                 
               });
        }else if(from_user_id !=null  && to_Amt !=null  &&  from_date !=null &&  to_date !=null ){
          this.result =  this.arrayele.filter(d => {    
            const  id= d.user_id;
            const  reg_date= d.reg_date;
            const regdate = reg_date.split(' ')[0];
                                      
             return  ((id >= from_user_id && id  <=  to_user_id)  && (regdate >= from_date  && regdate  <=  to_date ));            
                 
               });
        }else if(from_Amt !=null && to_Amt !=null  &&  from_date !=null &&  to_date !=null ){
          this.result =  this.arrayele.filter(d => {   
            const  loanamt= d.loan_amount;   
            const  reg_date= d.reg_date;
            const regdate = reg_date.split(' ')[0];                         
             return  ((loanamt >= from_Amt  && loanamt  <=  to_Amt ) && (regdate >= from_date  && regdate  <=  to_date ));            
                 
               });
        }else if(from_user_id !=null && to_user_id !=null ){
          this.result =  this.arrayele.filter(d => {   
            const  id= d.user_id;
            
             return  (id >= from_user_id && id  <=  to_user_id);            
                 
               });
        }else if(from_Amt !=null && to_Amt !=null ){
          this.result =  this.arrayele.filter(d => { 
            const  loan_amount= d.loan_amount;              
             return  (loan_amount >= from_Amt    &&  loan_amount  <=  to_Amt);            
                                          
             //return  ((loanamt >= from_Amt  && loanamt  <=  to_Amt ) );            
                 
               });
        }else if(from_date !=null &&  to_date !=null ){
          this.result =  this.arrayele.filter(d => { 
            const  reg_date= d.reg_date;
            const regdate = reg_date.split(' ')[0];               
             return  (regdate >= from_date    &&  regdate  <=  to_date);            
             // (regdate >= this.date_from   && regdate  <= this.date_to );
               });
        } else {
          this.result  =this.arrayele;
        }
         //console.log(this.result);   
          
         
           // console.log(d.length);                   
         // console.log(this.arrayele.length);
          
          
         
                for (let i = 0; i < this.result.length; i++) {
                  const elements = this.result[i];
                 
                  //console.log(elements);
                  this.stage_color = "info-color";
    
                  this.clear_stagr.push(
                    {
                    user_id: elements.user_id, 
                    first_name:  elements.first_name, 
                    last_name:  elements.last_name,              
                    provider: elements.provider,
                    city_name: elements.city_name,
                    loan_amount: elements.loan_amount,
                    ad_id: elements.ad_id,
                    reg_date: elements.reg_date,
                    status: elements.status,
                    lender_name: elements.lender_name,
                    }
                    );
                  
                }
    
                this.mdbTable.setDataSource(this.clear_stagr);
                this.elements = this.mdbTable.getDataSource();
                this.previous = this.mdbTable.getDataSource();            
    
      }
     //user Details
       getLeadDetails(user,data){
         let stage=  data;
        // console.log(data);
        this.useid = this.cryptojsService.encryptData((user.user_id));       
        const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
        const detailsurl = base_url + this.useid.toString();
        const dateur=detailsurl+"&stage="+stage.toString();
       
        //console.log(detailsurl);
        window.open(dateur, "_blank");
    }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
}