
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';




import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';

import { WhatsappService } from 'src/app/services/whatsapp/whatsapp.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';

@Component({
 
      selector: 'app-mera-emi-whatsapp-leads-details',
      templateUrl: './mera-emi-whatsapp-leads-details.component.html',
      styleUrls: ['./mera-emi-whatsapp-leads-details.component.scss']
})
export class MeraEMIWhatsappLeadsDetailsComponent implements OnInit {

  fullerton_lender_id:  number=0;
  idfc_lender_id:  number=0;
  loantab_lender_id:  number=0;
  public radioModel: string = 'Left';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  Clicked : boolean
  tabval: number=1;
  inputs: any;

  getProduct: any = []; 
  data: any;
  loginAdmin: any;
  rolecode: any;
  subs: Subscription;
  sub: any;
  user:any;
  feedback_Stage: any;
  userstage:string;
  productArray: any;
  decryptData: any;
  apiresult: any;
  message: any;
  otp_status: any;
  date: any;
  elements: any = [];
  drivelength: number=0;
  userid: any;
  loanid: any;
  adid: any;
  datewhataap: any;
  fname: any;
  lname: any;
  mobileno: any;
  email: any;
  loder: number;
  useid: any;
  feedbackloanid: any;
  FeedbackPopup: number=0;
  maincrmstageFilter: any= []; 
  userdocReceived: number=0;
  getmeraemistages: any = [];
  GetDocReceived: any = [];
  documentcomplete: any = [];
  optionsmeraemistage: { value: string; label: string; }[];
  GetDocReceivedlist: { value: string; label: string; }[];
  crmdocveryfi: { value: string; label: string; }[];
  crmstageFilter: any= []; 
  popupaddBtn: number=0;
  transfertabopen: number=0;
  elements_feedback: any=[];
  docPending:  any = [];
  docReceived:  any = [];
  lenderloantabactive: number;
  lenderHDBactive: number;
  crmStage: string;
  crmDate: string;
  docPendingStatus: number=0;
  ReceivedStatus: number=0;
  docReceiveduser:  any = [];
 
  fedbacklength: number=0;
  registerForm: FormGroup;
  remainingText: number;
  status: number;
  feedbackLendername: number;
  leadDetails: any;
  feedbackProductname: string;
  currentdate: string;
  followupdate: any;
  CrmStage: any;
  feedbackdatepikerdisable: number;

  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,    
    private router:Router,
    public afAuth: AngularFireAuth,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,    
    private WhatsappService:WhatsappService,
    private FeedbackService:FeedbackService,
    private formBuilder: FormBuilder,
  
  ) { }
 

    ngOnInit() {

      this.registerForm = this.formBuilder.group({
        idFilter: this.formBuilder.group({
        message: ['', Validators.required],        
        toDate: ['', Validators.required],
        meraemistage: ['', Validators.required],
        productname: ['', Validators.required],
        lendername: ['', Validators.required],
        
      
      }),
      
    });
      
              this.FeedbackService.GetMeraemiStage().subscribe( (result:any) => {   
        // console.log(result);  
                if (result.status == true  && result.response== 200) {                
                  for (let i = 0; i < result.meraemistage.length; i++) {
                    const menuarray = result.meraemistage[i];
                  //console.log(menuarray);
                  
                  // console.log(this.userdocReceived);
                    if(this.userdocReceived ==1){
      
                      this.getmeraemistages.push(
                        {
                          value: menuarray.data[0].stage_id,
                          label: menuarray.data[0].meraemi_stage,                         
                          lender_short_name : menuarray.data[0].meraemi_stage, 
                        }
                        );
                    }else{
                      if(menuarray.data[0].stage_id !="18" && menuarray.data[0].stage_id !="19" && menuarray.data[0].stage_id !="17"){
                        this.getmeraemistages.push(
                          {
                            value: menuarray.data[0].stage_id,
                            label: menuarray.data[0].meraemi_stage,                         
                            lender_short_name : menuarray.data[0].meraemi_stage, 
                          }
                          );
                      }
                    }
                  // console.log(menuarray.data[0].stage_id);
      
                      
                
                //   console.log(menuarray.data[0].stage_id);
                    if(menuarray.data[0].stage_id =='21' ||   menuarray.data[0].stage_id =='14'  || menuarray.data[0].stage_id =='16'|| menuarray.data[0].stage_id =='20' || menuarray.data[0].stage_id =='26'  ){
                      this.GetDocReceived.push(
                        {
                          value: menuarray.data[0].stage_id,
                          label: menuarray.data[0].meraemi_stage,                         
                          lender_short_name : menuarray.data[0].meraemi_stage, 
                        }
                        );
                    }
                    if(menuarray.data[0].stage_id =='25' ||  menuarray.data[0].stage_id =='26'){
                      this.documentcomplete.push(
                        {
                          value: menuarray.data[0].stage_id,
                          label: menuarray.data[0].meraemi_stage,                         
                          lender_short_name : menuarray.data[0].meraemi_stage, 
                        }
                        );
                    }   
                  }
                  this.GetDocReceivedlist =this.GetDocReceived; 
                  this.optionsmeraemistage =this.getmeraemistages;   
                  this.crmdocveryfi=this.documentcomplete;
                  //maincrmstageFilter
                //console.log( this.crmdocveryfi);
                  let detail = { results: [{ "user_id":parseInt(this.decryptData)}] };
                  detail['results']; 
                  this.FeedbackService.GetMeraemiStageFilter(detail).subscribe((result: any) => {
                    if(result.status== true && result.response== 200){
                      for (let i = 0; i < result.crmstagefilter.length; i++) {
                        const crmstage = result.crmstagefilter[i];
      
                      
                      
                      
                        
                        this.crmstageFilter.push(
                          {
                            value: crmstage.data[0].stage_id,
                            label: crmstage.data[0].meraemi_stage,  
                            lender_short_name : crmstage.data[0].meraemi_stage,                 
                          
                          }
                          );
                        
                    
                      }
                    
                    //console.log(this.crmstageFilter);
                    //console.log(this.crmstageFilter.length);
                      if(this.crmstageFilter.length !=0){
                    
                
                        let mainarray= this.getmeraemistages;
                        //console.log(mainarray);
                        let subarray=this.crmstageFilter;
                      // console.log(subarray);
      
                        var onlyInA = mainarray.filter(comparer(subarray));
                        var onlyInB = subarray.filter(comparer(mainarray));
                        
                        result = onlyInA.concat(onlyInB);
        
                        //this.maincrmstageFilter=result;
                        this.maincrmstageFilter= this.getmeraemistages;
                    // console.log(this.maincrmstageFilter);
                      }else{
                        this.maincrmstageFilter= this.getmeraemistages;
                        //console.log(this.mainlenderarrayfilter);
                      }
                      
                      // console.log(filteredList); 
        
                    }else{
                      this.maincrmstageFilter=  this.optionsmeraemistage ;
                      //console.log(this.mainlenderarrayfilter);
                    }
                    
                  });
      
      
      
      
      
      
                }else{
                  console.log('Product Api faile');
      
                
                }
                
              });
      
      // console.log(this.optionsmeraemistage);

   
    
  //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
          //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
          //console.log(this.loginAdmin)
          }
        )
      }
    
    
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        this.decryptData = this.cryptojsService.decryptData(this.data);
        //console.log(this.decryptData);
       
   
    });

    function comparer(otherArray){
      return function(current){
        return otherArray.filter(function(other){
          return other.value == current.value && other.display == current.display
        }).length == 0;
      }
    }
 


    let details={ results: [{"loanid":(this.decryptData)}]};
    this.WhatsappService.getWhatsappJourneyDetils(details).subscribe(    
      (result:any) => {
        //console.log( result);
        if(result.response==200 && result.status==true ){
          this.apiresult=result.details[0].data[0];
          // this.otp=result.details[0].data[0].OTP;
          this.userid=result.details[0].data[0].user_id;
          this.loanid=result.details[0].data[0].loan_id;
          this.adid=result.details[0].data[0].ad_id;
          this.datewhataap=result.details[0].data[0].date;
          this.fname=result.details[0].data[0].first_name;
          this.lname=result.details[0].data[0].last_name;
          this.mobileno=result.details[0].data[0].mobileno;
          this.email=result.details[0].data[0].email;
          
          
          //console.log(this.apiresult);
        }else{
          console.log(result);
        }
    
        
      // 
      });
      
      this.WhatsappService.getUserWhatsappMsg(details).subscribe(    
        (result:any) => {
         //  console.log(result.msg);
          if(result.response==200 && result.status==true ){
            
            for (let i = 0; i < result.msg.length; i++) {
              const menuarray = result.msg[i];
              
              this.message=   menuarray.data[0].message;
           
              this.date=   menuarray.data[0].created_on;
              
              this.elements.push(
                {
                  
                 
                                                         
                  
                  
                  created_by:  menuarray.data[0].created_by,
                  annual_turnover:  menuarray.data[0].annual_turnover,
                  bus_nature:  menuarray.data[0].bus_nature,
                  date:  this.date,  
                  credit_history:  menuarray.data[0].credit_history,
                  ctwa_doc_url:  menuarray.data[0].ctwa_doc_url,
                  gst_filing:  menuarray.data[0].gst_filing,
                  loan_amount:  menuarray.data[0].loan_amount,
                  message: this.message, 
                  message_id:  menuarray.data[0].message_id,
                  message_title: menuarray.data[0].message_title,
                  message_type: menuarray.data[0].message_type,
                  office_Status:  menuarray.data[0].office_Status,
                  office_type:  menuarray.data[0].office_type,
                  residence_status:  menuarray.data[0].residence_status,
                  
                }
                );
                

            //this.apiresult=result.message[0].data[0];
            //console.log(result.message[0].data[0]);
            }

          
          }else{
            console.log(result);
          }
      
          
       
        });



        //console.log(this.loanid);
        let detail={ results: [{"tempid":(this.decryptData)}]};
     //   let detail =  { results: [{ "tempid":"18"}] } ;
        detail['results']; 
        //console.log(detail);
        this.WhatsappService.getFeedbackCTWA(detail)
        .subscribe((result: any) => {  
          //console.log(result);
          if (result.status == true) {
          //console.log(result);
            for (let i = 0; i < result.feedvalue.length; i++) {
              const element = result.feedvalue[i];


              if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                this.docPending.push(
                  { 
                    docPending:"1"

                  });


              }
              
              
              
              if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                this.docPending.push(
                  { 
                    Received:"1"

                  });
            
              }else{
                
              }





              if(element.data[0].lender_name == "LoanTap"){
                this.lenderloantabactive=1;
              }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                this.lenderHDBactive=1;
              }
              if(element.data[0].status == "1"){
                this.crmDate=element.data[0].interactiondate;
                this.crmStage= element.data[0].meraemi_stage;
              }
              //console.log(element.data[0].status);
              //console.log(this.elements_feedback);
          
                this.elements_feedback.push(
                  {
                    admin_name: element.data[0].admin_name, 
                    feedback_id: element.data[0].feedback_id, 
                    feedback_message:  element.data[0].feedback_message, 
                    followupdate:  element.data[0].follow_update, 
                    interactiondate:  element.data[0].interactiondate, 
                    stage:  element.data[0].stage, 
                    user_id:  element.data[0].user_id, 
                    status:  element.data[0].status,
                    meraemi_stage:  element.data[0].meraemi_stage,
                    lender_name:  element.data[0].lender_name,
                    product_name:  element.data[0].product_name,
                  }
                  );
            

                //console.log(this.elements_feedback);
              
            
              
              

                
            }
          
          
            
             // this.docPendingStatus=this.docPending[0]['docPending'];
             // this.ReceivedStatus=this.docPending[0]['Received'];
                                            
            
              
          // console.log(this.OldFeedbackMessage);
          //console.log(this.elements_feedback);
        
          //console.log(this.popupbtnactive);
            this.fedbacklength =this.elements_feedback.length;
            //console.log(this.fedbacklength);  
            this.message = result.message;
            this.popupaddBtn=1;
            
          }else{
            this.popupaddBtn =0;
            //("hello");
          }
        }); 

    }





















    get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }
    get idFilter() {
      return this.registerForm.get('idFilter');
    }
    valueChange(value) {
      //console.log(value);
      if(value !=undefined){
        this.remainingText = 300 - value.length;
      //console.log(this.remainingText);
      }
      
    }  
    getValue(event) {
      this.CrmStage =event.label;
    //  console.log(this.CrmStage);
      if( event.value==="14" || event.value==="15"  || event.value==="16" || event.value==="19"){
          this.transfertabopen=0;
          this.feedbackdatepikerdisable=1;
       }else if( event.value==="18"){
         this.transfertabopen=1;
         this.feedbackdatepikerdisable=1;
       }else{
         this.transfertabopen=0;
         this.feedbackdatepikerdisable=1;
       }
   
    // console.log(this.feedbackdatepikerdisable);
   }  
    filterUserId(){
     // console.log(this.idFilter.value.meraemistage);
      if(this.idFilter.value.meraemistage ==="18"){
        this.status =parseInt(this.idFilter.value.meraemistage);
        this.feedbackLendername =(Number(this.idFilter.value.lendername));
        
       // this.feedbackProductname =this.idFilter.value.productname;
      }else if(this.idFilter.value.meraemistage !="18"){
        this.status =parseInt(this.idFilter.value.meraemistage);
        this.feedbackLendername =0;

      }else{
        alert("Pl Select Meraemi Stage !");
        this.idFilter.reset();
      }
        //console.log(this.feedbackLendername);
        //console.log(this.status);
  
        //message
        if(this.idFilter.value.message !='' || this.idFilter.value.message !=undefined || this.idFilter.value.message !=null){
          this.message =this.idFilter.value.message;
        }else{
            alert("Pl Input Feedback Message !");
            this.idFilter.reset();
        }
      
        this.feedbackProductname ="4";
        


        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);

        //console.log(this.idFilter.value.toDate);
        if(this.idFilter.value.toDate !="" || this.idFilter.value.toDate !=null){
         let followupdate =this.idFilter.value.toDate;

            if(this.currentdate <= followupdate){
              this.followupdate =this.idFilter.value.toDate;
              //console.log("1");
            }else{
              this.followupdate ="0000-00-00 00:00:00";
      
            }
         
        }else{
         this.followupdate ="0000-00-00 00:00:00";

        }

       
        let details = {
          
            results: [{
              "message":this.message,            
              "interactiondatetime":this.currentdate,          
              "status": this.status,
              "user_id":0,
              "admin_name":this.loginAdmin, 
              "temp_id":this.loanid,                     
              "loan_id":"0",
              "followupdate":this.currentdate,
              "feedbackProductname" :this.feedbackProductname,
              "feedbackLendername":0,
              "CrmStage":this.CrmStage,
              "campstatus":"1",
              "username":this.fname+' '+this.lname,
              "mobile":this.mobileno,
              "createdAt": this.currentdate,
              "email": this.email,
             
            }]
        
          
        };
      
        details['results']; 
        this.WhatsappService.postfeedbackCTWA(details)
        .subscribe((result: any) => { 
      





          this.FeedbackPopup=0;
          this.idFilter.reset();
          this.elements_feedback.length=0;
              //console.log(this.loanid);
           //console.log(this.loanid);
           let detail={ results: [{"tempid":(this.decryptData)}]};
           //   let detail =  { results: [{ "tempid":"18"}] } ;
           detail['results']; 
           //console.log(detail);
           this.WhatsappService.getFeedbackCTWA(detail)
           .subscribe((result: any) => {  
             //console.log(result);
             if (result.status == true) {
             //console.log(result);
               for (let i = 0; i < result.feedvalue.length; i++) {
                 const element = result.feedvalue[i];
   
   
                 if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                   this.docPending.push(
                     { 
                       docPending:"1"
   
                     });
   
   
                 }
                 
                 
                 
                 if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                   this.docPending.push(
                     { 
                       Received:"1"
   
                     });
               
                 }else{
                   
                 }
   
   
   
   
   
                 if(element.data[0].lender_name == "LoanTap"){
                   this.lenderloantabactive=1;
                 }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                   this.lenderHDBactive=1;
                 }
                 if(element.data[0].status == "1"){
                   this.crmDate=element.data[0].interactiondate;
                   this.crmStage= element.data[0].meraemi_stage;
                 }
                 //console.log(element.data[0].status);
                 //console.log(this.elements_feedback);
             
                   this.elements_feedback.push(
                     {
                       admin_name: element.data[0].admin_name, 
                       feedback_id: element.data[0].feedback_id, 
                       feedback_message:  element.data[0].feedback_message, 
                       followupdate:  element.data[0].follow_update, 
                       interactiondate:  element.data[0].interactiondate, 
                       stage:  element.data[0].stage, 
                       user_id:  element.data[0].user_id, 
                       status:  element.data[0].status,
                       meraemi_stage:  element.data[0].meraemi_stage,
                       lender_name:  element.data[0].lender_name,
                       product_name:  element.data[0].product_name,
                     }
                     );
               
   
                   //console.log(this.elements_feedback);
                 
               
                 
                 
   
                   
               }
             
             
               
                // this.docPendingStatus=this.docPending[0]['docPending'];
                // this.ReceivedStatus=this.docPending[0]['Received'];
                                               
               
                 
             // console.log(this.OldFeedbackMessage);
             //console.log(this.elements_feedback);
           
             //console.log(this.popupbtnactive);
               this.fedbacklength =this.elements_feedback.length;
               //console.log(this.fedbacklength);  
               this.message = result.message;
               this.popupaddBtn=1;
               
             }else{
               this.popupaddBtn =0;
               //("hello");
             }
           });  
        });








        
    }










      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }

    onClick(check){
          //console.log(check);
          if(check==1){
            this.tab = 'tab1';
            this.tabval=1;
          }else if(check==2){
            this.tab = 'tab2';
            this.tabval=2;
          // console.log(this.cust);
          }else if(check==3){
            this.tab = 'tab3';
            this.tabval=3;
          console.log(this.tabval);
          }     
        
      }
    onSwitch(check){
    
      switch (check) {
      case 1:
        return 'tab1';
      case 2:
        return 'tab2';
        case 3:
        return 'tab3';
      
    }
    }

      
    addUserInformation(){
      console.log(this.userid);
      console.log(this.adid);
      console.log(this.fname);
      console.log(this.lname);
      console.log(this.loanid);
      console.log(this.mobileno);
    //  this.loder=1;
      //setTimeout(()=>{ 
        //this.loder=0;

        this.useid = this.cryptojsService.encryptData((this.userid));       
        const base_url=location.origin+"/#/meraEMI-user-register?uid=";        
        const detailsurl = base_url + this.useid.toString();
        const add=detailsurl+"&add="+this.adid.toString();
        const loanid=add+"&loanid="+this.loanid.toString();
        const mobileno=loanid+"&mobileno="+this.mobileno.toString();
        const fname=mobileno+"&fname="+this.fname.toString();
        const lname=fname+"&lname="+this.lname.toString();
        window.open(lname, "_blank");



    //  },5000); 
      
    }





 

  


    uncheck() {
        this.inputs.forEach(check => {
            check.nativeElement.checked = false;
        });
    }
  
     
    feedbackPopup(loanid){
      console.log(loanid);
      this.feedbackloanid=loanid;
       this.popupaddBtn=1;
       // console.log(data2);
        this.FeedbackPopup=1;
       
      } 
      resetfeedback() {
        this.FeedbackPopup =0;
         this.idFilter.reset();
     }
     updateUserInformation(){
     
    //  this.loder=1;
      //setTimeout(()=>{ 
        //this.loder=0;

        this.useid = this.cryptojsService.encryptData((this.userid));       
        const base_url=location.origin+"/#/meraEMI-registerEdit?uid=";        
        const detailsurl = base_url + this.useid.toString();
        const add=detailsurl+"&add="+this.adid.toString();
        const loanid=add+"&loanid="+this.loanid.toString();
        const mobileno=loanid+"&mobileno="+this.mobileno.toString();
        const fname=mobileno+"&fname="+this.fname.toString();
        const lname=fname+"&lname="+this.lname.toString();
        window.open(lname, "_blank");



    //  },5000); 
      
    }

    redirectToIntrakt(mobileno){

   
      // console.log(mobileno);return false;
      const externalUrl = 'https://app.interakt.ai/inbox?channelPhoneNumber=91'+mobileno;
      window.open(externalUrl, '_blank');
     }
     docUplodsUrl(loanid){
      //console.log(data);
     // console.log(user); return false;
       
       if(loanid !=undefined){
        

         this.useid = this.cryptojsService.encryptData((loanid));       
         const base_url=location.origin+"/#/docUplods?data=";        
         const detailsurl = base_url + this.useid.toString();
 
        
        
         //console.log(detailsurl);
         window.open(detailsurl, "_blank");
       }
 
     }
}
