import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    getWhatsappJourney(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'crmWhatsapp/getWhatsappJourney',{headers:headers});
    }
     //post meaasge elig
     getWhatsappJourneyDetils(data){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
      return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/getWhatsappJourneyDetils',{data:data},{headers:headers});
  
    }
      //post meaasge elig
      getUserWhatsappMsg(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/getUserWhatsappMsg',{data:data},{headers:headers});
    
      }
      
      //post meaasge elig
      insertUserWhatsappUserRegister(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/WhatsappIAPUserDataInsert',{data:data},{headers:headers});
        
      }
      getResidenceStatus(){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getResidenceStatus/2',{headers:headers});
      }
      getBusinessYear(){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusinessYear',{headers:headers});
      }
      getEmployerCat(){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getEmployerCat/2',{headers:headers});
      }
      postfeedbackCTWA(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/insertFeedbackCTWA',{data:data},{headers: headers});
       console.log(headers);
     }
     getFeedbackCTWA(data){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/getFeedbackCTWA',{data:data},{headers: headers});
     console.log(headers);
   }
 
 getBusinessCategory(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusinessCategory',{headers:headers});
}
getBusNatureMaster(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusNatureMaster',{headers:headers});
}
businessNameid(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/businessNameid',{headers:headers});
}
getBusinessEstablishmentStatus(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusinessEstablishmentStatus',{headers:headers});
}
getCreditPofile(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getCreditPofile',{headers:headers});
}

getDocumentproofBusiness(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getDocumentproofBusiness',{headers:headers});
}
getIndustry(){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getIndustry',{headers:headers});
}

WhatsappIAPUserDataInsertTest(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/WhatsappIAPUserDataInsert',{data:data},{headers:headers});
  
}
getWhatsappIAPUserEditData(uid){
  console.log(uid);
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getWhatsappIAPUserEditData/'+uid+'',{headers:headers});
}  
getCTWADocListing(uid){
  console.log(uid);
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
.set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
return this.http.get<Global>(`${this.global.apiurl}`+'crmWhatsapp/getCTWADocListing/'+uid+'',{headers:headers});
}  
}
