import { Component, OnInit } from '@angular/core';
import { FacebookAdService } from 'src/app/services/facebook-ad/facebook-ad.service';
@Component({
  selector: 'app-facebook-ad',
  templateUrl: './facebook-ad.component.html',
  styleUrls: ['./facebook-ad.component.scss']
})
export class FacebookAdComponent implements OnInit {
  adId: any;
  adName: any;
  AdStatus: any=[];
  AdSpend: any = [];
  AdbBidStrategy: any = [];
  AdDailyBudget: any = [];
  AdResultStage: any = [];
  mainAdArray: any = [];
  
  constructor(private FacebookAdService:FacebookAdService,) { }
  ngOnInit() {
  }
  // ngOnInit() {
  //   this.FacebookAdService
  //   .GetFbAdName()
  //   .subscribe( (result:any) => {
  //     //console.log(result);
  //             if (result !=null) {
               
               
  //               for (let i = 0; i < result.data.length; i++) {
  //                 const element = result.data[i]; 
  //                 let details = {data: { results: [{ "id":element.id}] } };
  //                 details['data']['results']; 
  //                 setTimeout(()=>{  
  //                 this.FacebookAdService.PostAdActive(details).subscribe( 
  //                   (result:any) => {
  //                     if(result.status==="PAUSED"){
  //                       // console.log('Loop is going to break.'); 
  //                        return false; 
  //                      }else{
  //                        let  obj =JSON.parse(JSON.stringify(result));
  //                        this.mainAdArray.push(result);
                  
                      
  //                      }
  //                   }); 
                   
  //                 },2000);            
                             
                 
                 
  //               }
               
            
               
  //             }else{
  //               console.log("Facebook Ad Api Connection Error !");
  //             }
  //           });
  //           setTimeout(()=>{ 
  //           const stage =this.mainAdArray;
  //           for (let i = 0; i < stage.length; i++) {
  //             const element = stage[i];
  //             let details = {data: { results: [{ "id":element.id}] } };
  //               details['data']['results']; 
               
  //               this.FacebookAdService.PostAdAmountSpent(details).subscribe( 
  //                 (result:any) => {
                 
  //                  // console.log(result['data']); 
  //                  // console.log();
  //                   if(result['data'].length !=0){
  //                     this.AdSpend.push({
  //                       budget_remaining:element.budget_remaining,
  //                       daily_budget: element.daily_budget,
  //                       id: element.id,
  //                       name: element.name,
  //                       status: element.status,
  //                       spend:result['data'][0].spend
  //                     });
  //                   }
                    
  //                 });
                 
  //           }
  //         },5000);
              
           
  //           setTimeout(()=>{  
  //             const AdSpend=this.AdSpend;
  //             for (let i = 0; i < AdSpend.length; i++) {
  //               const element = AdSpend[i];
  //               let details = {data: { results: [{ "id":element.id}] } };
  //                 details['data']['results']; 
  //                 this.FacebookAdService.PostAdBidStrategy(details).subscribe( 
  //                   (result:any) => {
  //                     this.AdbBidStrategy.push({
  //                       budget_remaining:element.budget_remaining,
  //                       daily_budget: element.daily_budget,
  //                       id: element.id,
  //                       name: element.name,
  //                       status: element.status,
  //                       spend:element.spend,
  //                       bid_strategy:result.bid_strategy
  //                     });
  //                   });
                   
  //             }
            
             
  //           },8000);
  //           setTimeout(()=>{ 
  //             const AdbBidStrategy=this.AdbBidStrategy; 
  //             for (let i = 0; i < AdbBidStrategy.length; i++) {
  //               const element = AdbBidStrategy[i];
  //               let details = {data: { results: [{ "id":element.id}] } };
  //                 details['data']['results']; 
  //                 this.FacebookAdService.PostAdResultStage(details).subscribe( 
  //                   (result:any) => {
  //                    // console.log(result['data'][0].action_values[0]); 
  //                     if(result['data'][0].action_values.length !=0){
  //                       this.AdResultStage.push({
  //                         budget_remaining:element.budget_remaining,
  //                         daily_budget: element.daily_budget,
  //                         id: element.id,
  //                         name: element.name,
  //                         status: element.status,
  //                         spend:element.spend,
  //                         bid_strategy:element.bid_strategy,
  //                         result:result['data'][0].action_values[0].value,
  //                         result_action_type:result['data'][0].action_values[0].action_type,
                          
  //                       });
  //                     }
                      
  //                   });
                   
  //             }
  //            // console.log(this.AdbBidStrategy); 
            
  //         },11000);
  //         setTimeout(()=>{ 
          
  //         console.log(this.AdResultStage); 
  //       },14000);
  // }
  userModel(userModel: any) {
    throw new Error('Method not implemented.');
  }


}
