import { Injectable } from '@angular/core';
import { CalendarEventTitleComponent } from 'angular-calendar/modules/common/calendar-event-title.component';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class MisSendLenderService {

  constructor() { }

  exportExcel(excelData) {
   // const imgBase64 = "../../../assets/img/angular-mdb.png";
    //var imageData=base64.encode(imgBase64);
    //Title, Header & Data
   
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
   // const titletext2 = excelData.titletext2;
  
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');

    worksheet.addRow([]);
    //Add Row and formatting
    //1 left-text1
    worksheet.mergeCells('A1', 'E1');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = header.left.text1
    titleRow.font = {
      name: 'Calibri',
      size: 12,    
      bold: true,
      color:'212529'
    }
    titleRow.alignment = { vertical: 'middle' }

    //2 left-text2
    worksheet.mergeCells('A2', 'E2');
    let titleRow2 = worksheet.getCell('A2');
    titleRow2.value = header.left.text2
    titleRow2.font = {
      name: 'Calibri',
      size: 12,    
      bold: true,
      color:'212529'
    }
    titleRow2.alignment = { vertical: 'middle' }
      //3 left-text3
      worksheet.mergeCells('A3', 'E3');
      let titleRowleft3 = worksheet.getCell('A3');
      titleRowleft3.value = header.left.text3
      titleRowleft3.font = {
        name: 'Calibri',
        size: 12,    
        bold: true,
        color:'212529'
      }
      titleRowleft3.alignment = { vertical: 'middle' }
    // text3

    //3 middle title or image
    worksheet.mergeCells('F1', 'J2');
    let titleRow3 = worksheet.getCell('F1');
    titleRow3.value = title
    titleRow3.font = {
      name: 'Calibri',
      size: 12,    
      bold: true,
      color:'212529'
    }
    titleRow3.alignment = { vertical: 'middle' }

    //4 right text1
    worksheet.mergeCells('K1', 'Q1');
    let titleRight1 = worksheet.getCell('K1');
    titleRight1.value = header.right.text1
    titleRight1.font = {
      name: 'Calibri',
      size: 12,    
      bold: true,
      color:'212529'
    }
    titleRight1.alignment = { vertical: 'middle' }

    worksheet.mergeCells('K2', 'Q2');
    let titleRight2 = worksheet.getCell('K2');
    titleRight2.value = header.right.text2
    titleRight2.font = {
      name: 'Calibri',
      size: 12,   
      bold: true,
      color: '212529'
    }
    titleRight2.alignment = { vertical: 'middle' }
  //3 left-text3
  worksheet.mergeCells('K3', 'Q3');
  let titleRight3 = worksheet.getCell('K3');
  titleRight3.value = header.right.text3
  titleRight3.font = {
    name: 'Calibri',
    size: 12,    
    bold: true,
    color:'212529'
  }
  titleRight3.alignment = { vertical: 'middle' }
 
   //sub_stage
   worksheet.mergeCells('F3', 'J3');
   let titleRight4 = worksheet.getCell('F3');
   titleRight4.value = header.right.text4
   titleRight4.font = {
     name: 'Calibri',
     size: 12,    
     bold: true,
     color:'212529'
   }
   titleRight4.alignment = { vertical: 'middle' }

    // text3
    
    // worksheet.mergeCells('E1', 'j5');
    // let titleRow2 = worksheet.getCell('E2');
    // titleRow2.value = titletext2
    // titleRow2.font = {
    //   name: 'Calibri',
    //   size: 16,
    //   underline: 'single',
    //   bold: true,
    //   color: { argb: '0085A3' }
    // }
    // titleRow2.alignment = { vertical: 'middle' }
   
    // Date
    // worksheet.mergeCells('G1:H4');
    // let d = new Date();
    // let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
    // let dateCell = worksheet.getCell('G1');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // }
    // dateCell.alignment = { vertical: 'middle' }

    //Add Image
    // let myLogoImage = workbook.addImage({
    //  base64: imgBase64,
    //   extension: 'png',
    //     });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row 
    worksheet.addRow([]);
   
     // body section 
    //1 left-text1
    worksheet.mergeCells('B5', 'H5');
    let titleRowbody = worksheet.getCell('B5');
    titleRowbody.value = data.left.text1
    titleRowbody.font = {
      name: 'Calibri',
      size: 10,    
      bold: true,
      color:'212529'
    }
    titleRowbody.alignment = { vertical: 'middle' }


    worksheet.mergeCells('J5', 'Q5');
    let titleRightbody = worksheet.getCell('J5');
    titleRightbody.value = data.right.text1
    titleRightbody.font = {
      name: 'Calibri',
      size: 10,    
      bold: false,
      color:'212529'
    }
    titleRightbody.alignment = { vertical: 'middle' }
//2nd
 //1 left-text1
 worksheet.mergeCells('B6', 'H6');
 let titleRowbody2 = worksheet.getCell('B6');
 titleRowbody2.value = data.left.text2
 titleRowbody2.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
 titleRowbody2.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J6', 'Q6');
 let titleRightbody2 = worksheet.getCell('J6');
 titleRightbody2.value = data.right.text2
 titleRightbody2.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody2.alignment = { vertical: 'middle' }
//3rd
 //1 left-text1
 worksheet.mergeCells('B7', 'H7');
 let titleRowbody3 = worksheet.getCell('B7');
titleRowbody3.value = data.left.text3
titleRowbody3.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody3.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J7', 'Q7');
 let titleRightbody3 = worksheet.getCell('J7');
 titleRightbody3.value = data.right.text3
 titleRightbody3.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody3.alignment = { vertical: 'middle' }
//4rd
 //1 left-text1
 worksheet.mergeCells('B8', 'H8');
 let titleRowbody4 = worksheet.getCell('B8');
titleRowbody4.value = data.left.text4
titleRowbody4.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody4.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J8', 'Q8');
 let titleRightbody4 = worksheet.getCell('J8');
 titleRightbody4.value = data.right.text4
 titleRightbody4.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody4.alignment = { vertical: 'middle' }
//5rd
 //1 left-text1
 worksheet.mergeCells('B9', 'H9');
 let titleRowbody5 = worksheet.getCell('B9');
titleRowbody5.value = data.left.text5
titleRowbody5.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody5.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J9', 'Q9');
 let titleRightbody5 = worksheet.getCell('J9');
 titleRightbody5.value = data.right.text5
 titleRightbody5.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody5.alignment = { vertical: 'middle' }
 //6rd
 //1 left-text1
 worksheet.mergeCells('B10', 'H10');
 let titleRowbody6 = worksheet.getCell('B10');
titleRowbody6.value = data.left.text6
titleRowbody6.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody6.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J10', 'Q10');
 let titleRightbody6 = worksheet.getCell('J10');
 titleRightbody6.value = data.right.text6
 titleRightbody6.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody6.alignment = { vertical: 'middle' }
 //7rd
 //1 left-text1
 worksheet.mergeCells('B11', 'H11');
 let titleRowbody7 = worksheet.getCell('B11');
titleRowbody7.value = data.left.text7
titleRowbody7.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody7.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J11', 'Q11');
 let titleRightbody7 = worksheet.getCell('J11');
 titleRightbody7.value = data.right.text7
 titleRightbody7.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody7.alignment = { vertical: 'middle' }
  //8rd
 //1 left-text1
 worksheet.mergeCells('B12', 'H12');
 let titleRowbody8 = worksheet.getCell('B12');
titleRowbody8.value = data.left.text8
titleRowbody8.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody8.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J12', 'Q12');
 let titleRightbody8 = worksheet.getCell('J12');
 titleRightbody8.value = data.right.text8
 titleRightbody8.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody8.alignment = { vertical: 'middle' }
   //9rd
 //1 left-text1
 worksheet.mergeCells('B13', 'H13');
 let titleRowbody9 = worksheet.getCell('B13');
titleRowbody9.value = data.left.text9
titleRowbody9.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody9.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J13', 'Q13');
 let titleRightbody9 = worksheet.getCell('J13');
 titleRightbody9.value = data.right.text9
 titleRightbody9.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody9.alignment = { vertical: 'middle' }
//10rd
 //1 left-text1
 worksheet.mergeCells('B14', 'H14');
 let titleRowbody10 = worksheet.getCell('B14');
titleRowbody10.value = data.left.text10
titleRowbody10.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody10.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J14', 'Q14');
 let titleRightbody10 = worksheet.getCell('J14');
 titleRightbody10.value = data.right.text10
 titleRightbody10.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody10.alignment = { vertical: 'middle' }
 //11rd
 //1 left-text1
 worksheet.mergeCells('B15', 'H15');
 let titleRowbody11 = worksheet.getCell('B15');
titleRowbody11.value = data.left.text11
titleRowbody11.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody11.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J15', 'Q15');
 let titleRightbody11 = worksheet.getCell('J15');
 titleRightbody11.value = data.right.text11
 titleRightbody11.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody11.alignment = { vertical: 'middle' }
  //12rd
 //1 left-text1
 worksheet.mergeCells('B16', 'H16');
 let titleRowbody12 = worksheet.getCell('B16');
titleRowbody12.value = data.left.text12
titleRowbody12.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody12.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J16', 'Q16');
 let titleRightbody12 = worksheet.getCell('J16');
 titleRightbody12.value = data.right.text12
 titleRightbody12.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody12.alignment = { vertical: 'middle' }
 //13rd
 //1 left-text1
 worksheet.mergeCells('B17', 'H17');
 let titleRowbody13 = worksheet.getCell('B17');
titleRowbody13.value = data.left.text13
titleRowbody13.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody13.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J17', 'Q17');
 let titleRightbody13 = worksheet.getCell('J17');
 titleRightbody13.value = data.right.text13
 titleRightbody13.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody13.alignment = { vertical: 'middle' }
  //14rd
 //1 left-text1
 worksheet.mergeCells('B18', 'H18');
 let titleRowbody14 = worksheet.getCell('B18');
titleRowbody14.value = data.left.text14
titleRowbody14.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody14.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J18', 'Q18');
 let titleRightbody14 = worksheet.getCell('J18');
 titleRightbody14.value = data.right.text14
 titleRightbody14.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody14.alignment = { vertical: 'middle' }
   //15rd
 //1 left-text1
 worksheet.mergeCells('B19', 'H19');
 let titleRowbody15 = worksheet.getCell('B19');
titleRowbody15.value = data.left.text15
titleRowbody15.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody15.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J19', 'Q19');
 let titleRightbody15 = worksheet.getCell('J19');
 titleRightbody15.value = data.right.text15
 titleRightbody15.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody15.alignment = { vertical: 'middle' }
   //16rd
 //1 left-text1
 worksheet.mergeCells('B20', 'H20');
 let titleRowbody16 = worksheet.getCell('B20');
titleRowbody16.value = data.left.text16
titleRowbody16.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody16.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J20', 'Q20');
 let titleRightbody16 = worksheet.getCell('J20');
 titleRightbody16.value = data.right.text16
 titleRightbody16.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody16.alignment = { vertical: 'middle' }
//17rd
 //1 left-text1
 worksheet.mergeCells('B21', 'H21');
 let titleRowbody17 = worksheet.getCell('B21');
titleRowbody17.value = data.left.text17
titleRowbody17.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody17.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J21', 'Q21');
 let titleRightbody17 = worksheet.getCell('J21');
 titleRightbody17.value = data.right.text17
 titleRightbody17.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody17.alignment = { vertical: 'middle' }
  //18rd
 //1 left-text1
 worksheet.mergeCells('B22', 'H22');
 let titleRowbody18 = worksheet.getCell('B22');
titleRowbody18.value = data.left.text18
titleRowbody18.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody18.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J22', 'Q22');
 let titleRightbody18 = worksheet.getCell('J22');
 titleRightbody18.value = data.right.text18
 titleRightbody18.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody18.alignment = { vertical: 'middle' }
 
 //19rd
 //1 left-text1
 worksheet.mergeCells('B23', 'H23');
 let titleRowbody19 = worksheet.getCell('B23');
titleRowbody19.value = data.left.text19
titleRowbody19.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody19.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J23', 'Q23');
 let titleRightbody19 = worksheet.getCell('J23');
 titleRightbody19.value = data.right.text19
 titleRightbody19.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody19.alignment = { vertical: 'middle' }
 //20rd
 //1 left-text1
 worksheet.mergeCells('B24', 'H24');
 let titleRowbody20 = worksheet.getCell('B24');
titleRowbody20.value = data.left.text20
titleRowbody20.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody20.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J24', 'Q24');
 let titleRightbody20 = worksheet.getCell('J24');
 titleRightbody20.value = data.right.text20
 titleRightbody20.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody20.alignment = { vertical: 'middle' }
 //21rd
 //1 left-text1
 worksheet.mergeCells('B25', 'H25');
 let titleRowbody21 = worksheet.getCell('B25');
titleRowbody21.value = data.left.text21
titleRowbody21.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody21.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J25', 'Q25');
 let titleRightbody21 = worksheet.getCell('J25');
 titleRightbody21.value = data.right.text21
 titleRightbody21.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody21.alignment = { vertical: 'middle' }
 //22rd
 //1 left-text1
 worksheet.mergeCells('B26', 'H26');
 let titleRowbody22 = worksheet.getCell('B26');
titleRowbody22.value = data.left.text22
titleRowbody22.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody22.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J26', 'Q26');
 let titleRightbody22 = worksheet.getCell('J26');
 titleRightbody22.value = data.right.text22
 titleRightbody22.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody22.alignment = { vertical: 'middle' }
 //23rd
 //1 left-text1
 worksheet.mergeCells('B27', 'H27');
 let titleRowbody23 = worksheet.getCell('B27');
titleRowbody23.value = data.left.text23
titleRowbody23.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody23.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J27', 'Q27');
 let titleRightbody23 = worksheet.getCell('J27');
 titleRightbody23.value = data.right.text23
 titleRightbody23.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody23.alignment = { vertical: 'middle' }
  //24rd
 //1 left-text1
 worksheet.mergeCells('B28', 'H28');
 let titleRowbody24 = worksheet.getCell('B28');
titleRowbody24.value = data.left.text24
titleRowbody24.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody24.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J28', 'Q28');
 let titleRightbody24 = worksheet.getCell('J28');
 titleRightbody24.value = data.right.text24
 titleRightbody24.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody24.alignment = { vertical: 'middle' }

   //25rd
 //1 left-text1
 worksheet.mergeCells('B29', 'H29');
 let titleRowbody25 = worksheet.getCell('B29');
titleRowbody25.value = data.left.text25
titleRowbody25.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody25.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J29', 'Q29');
 let titleRightbody25 = worksheet.getCell('J29');
 titleRightbody25.value = data.right.text25
 titleRightbody25.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody25.alignment = { vertical: 'middle' }
   //26rd
 //1 left-text1
 worksheet.mergeCells('B30', 'H30');
 let titleRowbody26 = worksheet.getCell('B30');
titleRowbody26.value = data.left.text26
titleRowbody26.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody26.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J30', 'Q30');
 let titleRightbody26 = worksheet.getCell('J30');
 titleRightbody26.value = data.right.text26
 titleRightbody26.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody26.alignment = { vertical: 'middle' }
    //27rd
 //1 left-text1
 worksheet.mergeCells('B31', 'H31');
 let titleRowbody27 = worksheet.getCell('B31');
titleRowbody27.value = data.left.text27
titleRowbody27.font = {
   name: 'Calibri',
   size: 10,    
   bold: true,
   color:'212529'
 }
titleRowbody27.alignment = { vertical: 'middle' }


 worksheet.mergeCells('J31', 'Q31');
 let titleRightbody27 = worksheet.getCell('J31');
 titleRightbody27.value = data.right.text27
 titleRightbody27.font = {
   name: 'Calibri',
   size: 10,    
   bold: false,
   color:'212529'
 }
 titleRightbody27.alignment = { vertical: 'middle' }
 
        //1 right-text1
        
    //Adding Header Row
    // let headerRow = worksheet.addRow(header);
    // headerRow.eachCell((cell, number) => {
    //   headerRow.font = {
    //     name: 'Calibri',
    //     size: 16,
    //     underline: 'single',
    //     bold: true,
    //     color: { argb: '0085A3' }
    //   }
    // })
    


    // // Adding Data with Conditional Formatting
    // data.forEach(d => {
    //   let row = worksheet.addRow(d);
    //   d.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: '4167B8' },
    //     bgColor: { argb: '' }
    //   }
    //   d.font = {
    //     bold: true,
    //     color: { argb: 'FFFFFF' },
    //     size: 12
    //   }
   

    // }
    // );

    // worksheet.getColumn(3).width = 20;
    // worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow(['sclaimer:']);
    footerRow.getCell(200).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };
     //Merge Cells
     worksheet.mergeCells(`A${footerRow.number}:Q${footerRow.number}`);
    let footersRowTwo = worksheet.addRow(['1. We would also like to inform you that MeraEMI does not charge any platform fees or charges to its users to check eligibility or apply for a loan with its platform lenders. All interest, fees and charges are charged by the participating lenders to you directly in accordance with their policies.']);
    footersRowTwo.getCell(200).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };
   //Merge Cells
   worksheet.mergeCells(`A${footersRowTwo.number}:Q${footersRowTwo.number}`);
   let footersRowThree = worksheet.addRow(['2. This message contains confidential information and is intended only for the individual named/intended recipient. If you are not the named addressee/ intended recipient, you should not disseminate, distribute or copy this e-mail. Please notify the sender immediately by e-mail on contact@meraemi.com if you have received this e-mail by mistake and delete this e-mail from your system.']);
   footersRowThree.getCell(200).fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFB050' }
   };
  //Merge Cells
  worksheet.mergeCells(`A${footersRowThree.number}:Q${footersRowThree.number}`);
  

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }
}