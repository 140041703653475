import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
 

   GetuserDashbord(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'dashboardCont/getUserDashbord',{headers:headers});
  }



   GetdocReceived(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/getDocReceived',{data:data},{headers: headers});
   console.log(headers);
  }
  
  lenderAPIResponse(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderAPIResponse',{data:data},{headers: headers});
   console.log(headers);
  }
    // GetDashboard(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    // .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    // return this.http.get<Global>(`${this.global.apiurl}`+'dashboardCont/dashboard',{headers:headers});
    // }


    
  
   lenderApiFullResponse(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderApiFullResponse',{data:data},{headers: headers});
   console.log(headers);
 }
 lenderApiHDBfResponse(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderApiHDBfResponse',{data:data},{headers: headers});
 console.log(headers);
}
lenderApiLoanTapfResponse(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderApiLoanTapfResponse',{data:data},{headers: headers});
 console.log(headers);
}
lenderTransfer(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderTransfer',{data:data},{headers: headers});
 console.log(headers);
}
getActiveApplications(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'activeApplications/getActiveApplications',{data:data},{headers: headers});
 console.log(headers);
}
getActiveUserLenderName(uid){
  //console.log(data);
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'activeApplications/getActiveUserLenderName/'+uid+'',{headers:headers});
 // console.log(url);
}
getInActiveApplications(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'activeApplications/getInActiveApplications',{data:data},{headers: headers});
 console.log(headers);
}
//all user get crm stage
getMeraEmiStage(uid){
  //console.log(data);
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'activeApplications/getMeraEmiStage/'+uid+'',{headers:headers});
 // console.log(url);
}
getAllApplicationSelfEmpGraph(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'activeApplications/getAllApplicationSelfEmpGraph',{data:data},{headers: headers});
 console.log(headers);
}

}
