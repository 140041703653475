import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable } from 'rxjs';
import { Role } from 'src/app/model/role/role';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']

})

export class AppComponent implements OnInit {
  user:any;
  sub: Subscription;
  values: string[] = ['Tag 1', 'Tag 2', 'Tag 4'];

  specialPage: boolean;

  private specialPages: any[] = [
    '/login',
    '/register',
    '/set-password'
  ];

  private currentUrl = '';

  constructor(
    private router: Router,
    private location: Location,
    public afAuth: AngularFireAuth,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService
  ) {

    this.router.events.subscribe((route: any) => {

     this.currentUrl = '';
      if (route.routerEvent) {
        this.currentUrl = route.routerEvent.url;
        
      //console.log(route.routerEvent.url)
      } else {
        this.currentUrl = route.url;
      }
      // console.log(route.url)
      // console.log(this.currentUrl)
      this.specialPage = this.specialPages.indexOf(this.currentUrl) !== -1;
      // console.log(this.specialPage)
    });
  }

  ngOnInit() {
    this.sub = this.authState$.subscribe( user => {

      this.user = user;
      if(this.user){
          // console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
           //console.log(this.user)
          }
        )
      }
     
    
    });
    

  }

  get authenticated() {
    return this.user !== null;
  }
  
  
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }

  goBack(): void {
    this.location.back();
  }
}
