import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Leads } from 'src/app/model/admin/leads.model';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;
  getAllbusinessPartner(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'businessPartner/getBusinessPartnerData',{headers:headers});
  }
  getAllbusinessPartnerDetails(pid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'businessPartner/getBusinessPartnerDetails/'+pid+'',{headers:headers});
   // console.log(url);
  }
  
}

