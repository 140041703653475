import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService {

  constructor(private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
  bearer_token = this.TokenService.bearer_token ;
  Postdriveservices(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'drive/getdriveservices',{data:data},{headers: headers});

   }
   Postdriveapi(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'drive/Postdriveapi',{data:data},{headers: headers});

   }
   GetUserDocErrorListing(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'drive/GetUserDocErrorListing',{data:data},{headers: headers});

   }
   InsertFeedbackDoc(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   return this.http.post<any>(`${this.global.apiurl}`+'drive/InsertFeedbackDoc',{data:data},{headers: headers});
   console.log(headers);
 }
 ListOfDocMsg(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'drive/ListOfDocMsg',{data:data},{headers: headers});
 console.log(headers);
}

docReadAdmin(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'drive/docReadAdmin',{data:data},{headers: headers});
 console.log(headers);
}
 

listAllDocMaster(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'drive/listAllDocMaster',{data:data},{headers: headers});
 console.log(headers);
}
deleteDocComment(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
 .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
 return this.http.post<any>(`${this.global.apiurl}`+'drive/deleteDocComment',{data:data},{headers: headers});
 console.log(headers);
}
}
