import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrackProgressService {
  dummyRes={};

  constructor(
    private http: HttpClient,
    public global:Global
  ) { }

  updateTrackProgress(data):any{
  //console.log(data)
   
    return this.http.post<any>(`${this.global.apiurl}`+'trackprogress/UpdateUserTrackProgress',{data:data});

  }
}
