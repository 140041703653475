/*app.component.ts*/
import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
 
export class DashboardComponent implements OnInit {
	
	optionsadid: any = []; 
	All_ad_id: any = [];
	cityname: any = [];
	optionslocation: any = [];
	optionslogdate
	appstage: string;
	elig_status: string;
	elements: any = [];
	elements_eliPend: any = [];
	mainarray: any;
	countIAPPending: any = [];
	countIAPSuccess: any = [];
	countIAPFail: any = [];
	countIAPComplete: any = [];
	countEligibilityFail: any = [];
	countLoanamt: any = [];
	countEligibilityClear:any = [];
	chart:any = [];
	totalLoanamt: number=0;
	totalEligibilityClear: number=0;
	totalEligibilityFail: number=0;
	totalIAPPending: number=0;
	totalIAPSuccess: number=0;
	totalIAPFail: number=0;
	totalIAPComplete: number=0;
	CountIAPCP: number=0;
	TotaltIAPCP: number=0;
	CountIAPFail: number=0;
	TotaltIAPFail: number=0;
	CountIAPSuss: number=0;
	TotaltIAPSuss: number=0;
	TotaltIAPPend: number=0;
	CountIAPPend: number=0;
	CountEligibilityClear: number=0;
	TotalEligibilityClear: number=0;
	CountEligibilityPen: number=0;
	TotalEligibilityPend: number=0;
	CountEligibilityFail: number=0;
	TotaltEligibilityFail: number=0;
	CountEligFail: number=0;
	TotaltEligFail: number=0;
	myObjArray:any = [];
	registerForm: FormGroup;
	adid: any;
	location: any;
	dateto: any;
	datefrom: any;
	total: number=0;
	totalcount: number=0;
	countLoanamt1: any = [];
	totalLoanamt1: number=0;
    totalEligibilityClear1: number=0;									
	countEligibilityClear1: any = []; 
	totalEligibilityFail1: number=0;	
	countEligibilityFail1: any = []; 
	totalIAPPending1: number=0;									
	countIAPPending1: any = []; 
    totalIAPSuccess1: number=0;									
	countIAPSuccess1: any = []; 
	totalIAPFail1: number=0;									
	countIAPFail1: any = []; 
	totalIAPComplete1: number=0;										
	countIAPComplete1: any = []; 
	
	
	
	appStage: any;
	filter: any;
	filterStage: any;
	aadkey: string;
	filterCityname: any;
	adidarray: any=[];
	main_array: any=[];
	date_from: any;
	date_to: any;
	cityarray: any=[];
	result: any;	
	ToandFromDate: string;
	fulldate: string;	
	currentdate: string;
	FirstDaydate: string;
	result1: any;
	aad_key: string;
	adDate: string;
	datemainArry: any=[];
	datemain_Arry: any=[];
	showLoader: boolean;
	router: any;
	constructor(  private formBuilder: FormBuilder, private cryptojsService:CryptojsService,
		private leadsService:LeadsService) { }
	
		  
	ngOnInit() {
		var ourDate = new Date();
		//Change it so that it is 7 days in the past.
		var pastDate = ourDate.getDate() - 7;
		ourDate.setDate(pastDate);
		let lastseveDay=ourDate;
		
		let date = new Date();
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//console.log(this.currentdate); 
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		// adjust 0 before single digit date
		let datesFirstDay = ("0" + lastseveDay.getDate()).slice(-2);
		// current month
		let monthFirstDay = ("0" + (lastseveDay.getMonth() + 1)).slice(-2);
		// current year
		let yearFirstDay = lastseveDay.getFullYear();
		this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
		this.registerForm = this.formBuilder.group({			
			filterchart: this.formBuilder.group({
			  adid: ['', Validators.required],
			  location: ['', Validators.required],
			 datefrom: ['', Validators.required],
			  dateto: ['', Validators.required],
			}),
			
		  });
		//ad id
		this.leadsService
		.getAllAdId()
		.subscribe( (result:any) => {
		  //console.log(result);
				 if (result.status = 'true') {
				   
				   for (let i = 0; i < result.fb_adid.length; i++) {
					 const menuarray = result.fb_adid[i];
				    
					if(menuarray.data[0].ad_id !=0){
					 this.All_ad_id.push(
					   {
						value: menuarray.data[0].ad_id,
						label: menuarray.data[0].ad_id,
					   }
					   );
					   
					  }
				   
					   
				   }
				  // this.optionsadid =this.All_ad_id;
				   
				 }
				 let AllAdId = [
					{ value: "All-Adid-Select", label: "All-Adid-Select"} ];
					const AdMainArray = AllAdId.concat(this.All_ad_id);
					this.optionsadid =AdMainArray;
				  // console.log(array3);
				   
				 });
				 
			   
			   //location
			   this.leadsService
			   .getCityname()
			   .subscribe( (result:any) => {
			   // console.log(result.filter_city);
					   if (result.status = 'true') {
						 
						 for (let i = 0; i < result.filter_city.length; i++) {
						   const menuarray = result.filter_city[i];
						  //console.log(menuarray.data[0].pincode);
						  if(menuarray.data[0].location !=undefined){
						   this.cityname.push(
							 {
							   value: menuarray.data[0].location.toUpperCase(),
							   label: menuarray.data[0].location.toUpperCase(),                          
							  
							 }
							 );
							 //
							}
						 
						 }
						// this.optionslocation =this.cityname;
						 
					   }
					   let AllCity = [
						{ value: "All-city-Select", label: "All-city-Select"} ];
						const CitymainArray = AllCity.concat(this.cityname);
						this.optionslocation =CitymainArray;
					  // console.log(array3);
					   
					 });
	   
					 this.leadsService
					 .getAllLeads()
					 .subscribe( (result:any) => {
					// console.log(result);
							 if (result.status = 'true' && result.response==200) 
							{              
							   for (let i = 0; i < result.leads.length; i++) {
								 const element = result.leads[i];
							  
							 
								if(element.data[0].app_stage == 0){
								// this.stage ='Lead';
								 this.appstage ='Eligibility_Pending';
								
								
							 
								}else if(element.data[0].app_stage == '1'){
								  
								   if(element.data[0].elig_status == '1'){
									 this.elig_status ='Eligibility_Clear';
									
								   }else if(element.data[0].elig_status == '0'){
									 this.elig_status ='Eligibility_Fail';
									
								   }else{
									 this.elig_status ='Eligibility_Pending';
									
								   }
								  // console.log( this.elig_status)
								   this.appstage = this.elig_status;
							 
							   }else if(element.data[0].app_stage == 2){
								 
								   this.appstage ="IAP_Pending";
								  
							 
							   }else if(element.data[0].app_stage == 3){
								  if(element.data[0].trx_status != undefined){
								  
								   if(element.data[0].sfdc_id != "null" || element.data[0].trx_status == 'DDE' || element.data[0].trx_status == 'QDE'){
								  
									 this.appstage ="IAP_Complete";
									
								   }if(element.data[0].sfdc_id == "null" || element.data[0].trx_status == 'Cancel'){
								   
									 this.appstage ="IAP_Error";
									 //console.log(this.stage);
									
								   } if(element.data[0].trx_status == "Reject"){
								  
									 this.appstage ="IAP_Fail";
									
								 
								   }
								   if(element.data[0].trx_status == 'Data Verification'){
									 this.appstage = "IAP_Success";
								   }
								
								 }else{
									this.appstage ="IAP_Complete";
								 }
							  
								 if(element.data[0].resp_status_code != undefined){
							 
								   if(element.data[0].resp_status_code == "1" ){
									  this.appstage ="IAP_Complete";
									}
									if(element.data[0].resp_status_code == "0" || element.data[0].resp_status_code == "3" || element.data[0].resp_status_code == "4"){
									  this.appstage ="IAP_Fail";
									}
								  }
								 
								 
							   }
							  
							  //console.log(this.stage);
								 this.elements.push(
								   {
									 user_id: element.data[0].user_id, 
									 first_name:  element.data[0].first_name, 
									 last_name:  element.data[0].last_name,           
									 city_name: element.data[0].city_name,
									 loan_amount: element.data[0].loan_amount,
									 ad_id: element.data[0].ad_id,
									 reg_date: element.data[0].date,
									 status: this.appstage
								   }
								   );
								   
									  //console.log(this.elements);
								   
							 }
							 
							 this.mainarray= this.elements;
							 const mainarray= this.mainarray;
							// this.result1 = mainarray.filter(d => {var time = d.reg_date;
							// 	return (this.FirstDaydate <= time && time <= this.currentdate);
							// });
							this.result1 = mainarray.filter(d => {
								const  reg_date= d.reg_date;
								const regdate = reg_date.split(' ')[0];
								return (this.FirstDaydate  <= regdate && regdate  <=  this.currentdate );            
							
								
							  });
							//console.log(this.result1.length);
							//console.log(this.currentdate);
							// console.log(this.result1);			 

							 this.totalLoanamt = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								const element = this.result1[i];
								if(element.status === "Eligibility_Pending"){
									
								   this.countLoanamt.push({ status: this.appstage});
								   
								}
								if(element.status == "Eligibility_Pending" && element.loan_amount != undefined){
								   this.totalLoanamt += Number(element.loan_amount); 
								}
							
								
							}	
							 this.CountEligibilityPen=this.countLoanamt.length;
							 this.TotalEligibilityPend=this.totalLoanamt;
							 //console.log(this.FirstDaydate);			 
		
							 //console.log(this.TotalEligibilityPend);
							 this.totalEligibilityClear = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "Eligibility_Clear"){
																	
									this.countEligibilityClear.push({ status: this.appstage}); 
								}
								if(element.status === "Eligibility_Clear" && element.loan_amount != undefined){
									this.totalEligibilityClear += Number(element.loan_amount);									
									
								}
								 
							 }	
							 this.CountEligibilityClear=this.countEligibilityClear.length;
							 this.TotalEligibilityClear=this.totalEligibilityClear;
							 // console.log(this.CountEligibilityClear);	 
		                      //console.log(this.TotalEligibilityClear);
							 this.totalEligibilityFail = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "Eligibility_Fail"){
									 
									this.countEligibilityFail.push({ status: this.appstage});
								 }
								 if(element.status === "Eligibility_Fail" && element.loan_amount != undefined){
									this.totalEligibilityFail += Number(element.loan_amount);	 
									
								 }
								 
							 }	
							 this.CountEligFail=this.countEligibilityFail.length;
							 this.TotaltEligFail=this.totalEligibilityFail;
							 //console.log(this.CountEligFail);		
							 //console.log(this.TotaltEligFail);	

							 this.totalIAPPending = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Pending"){
									
									this.countIAPPending.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Pending" && element.loan_amount != undefined){
									this.totalIAPPending += Number(element.loan_amount);									
								
									
								 }
								 
							 }	
							 this.CountIAPPend=this.countIAPPending.length;
							 this.TotaltIAPPend=this.totalIAPPending;
							 //console.log(this.countIAPPending.length);			 
		
							 //console.log(this.totalIAPPending);
							 this.totalIAPSuccess = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Success"){
																	
									this.countIAPSuccess.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Success" && element.loan_amount != undefined){
									this.totalIAPSuccess += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							this.CountIAPSuss=this.countIAPSuccess.length;
							 this.TotaltIAPSuss=this.totalIAPSuccess;
							 //console.log(this.CountIAPSuss);
							 //console.log(this.TotaltIAPSuss);
							 this.totalIAPFail = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Fail" ){
																		
									this.countIAPFail.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Fail" && element.loan_amount != undefined){
									this.totalIAPFail += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CountIAPFail=this.countIAPFail.length;
							 this.TotaltIAPFail=this.totalIAPFail;
							 //console.log(this.countIAPFail.length);			 
		
							 //console.log(this.totalIAPFail);
							 this.totalIAPComplete = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Complete" ){
																	
									this.countIAPComplete.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Complete" && element.loan_amount != undefined){
									this.totalIAPComplete += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CountIAPCP=this.countIAPComplete.length;
							 this.TotaltIAPCP=this.totalIAPComplete;
							 this.total =(this.TotalEligibilityPend+this.TotalEligibilityClear+this.TotaltEligFail+this.TotaltIAPPend+this.TotaltIAPSuss+this.TotaltIAPFail+this.TotaltIAPCP);
							 this.totalcount =(this.CountEligibilityPen+this.CountEligibilityClear+this.CountEligFail+this.CountIAPPend+this.CountIAPSuss+this.CountIAPFail+this.CountIAPCP);
							 //console.log(this.total);		
							 //console.log(this.totalcount);
							 	this.myObjArray.push([
									   {TotalEligibilityPend : this.TotalEligibilityPend} ,
									   {TotalEligibilityClear : this.TotalEligibilityClear},
									   {TotaltEligFail : this.TotaltEligFail},
									   {TotaltIAPPend : this.TotaltIAPPend},
									   {TotaltIAPSuss : this.TotaltIAPSuss},
									   {TotaltIAPFail : this.TotaltIAPFail},
									   {TotaltIAPCP : this.TotaltIAPCP}
										

								 ]);
								

							 }else{
								 alert("API connection Error");
							 }
								// console.log(this.TotaltIAPFail);
								let epval =this.TotalEligibilityPend;
								let ecval =this.TotalEligibilityClear;
								let efval =this.TotaltEligFail;
								let ipval =this.TotaltIAPPend;
								let isval =this.TotaltIAPSuss;
								let ifval =this.TotaltIAPFail;
								let icval =this.TotaltIAPCP;
								//Canvas 
								let chart = new CanvasJS.Chart("chartContainer", {
									theme: "light",
									animationEnabled: true,
									///exportEnabled: false,
									title:{text: "MeraEMI Data ANALYSIS"},
									data: [{
										type: "pie",
										showInLegend: true,
										toolTipContent: "<b>{name}</b>: ₹{y} (#percent%)",
										indexLabel: "{name} - #percent%",
								
										dataPoints: [
											{ y: epval, name: "Eligibility Pending" },
											{ y: ecval, name: "Eligibility Clear" },
											{ y: efval, name: "Eligibility Fail" },
											{ y: ipval, name: "IAP Pending" },
											{ y: isval, name: "IAP Success" },
											{ y: ifval, name: "IAP Fail"},
											{ y: icval, name: "IAP Complete" }
											
										]
										
									}]
								});
									
								chart.render();
						   });
						  
  

	
	}
	chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }
 
	filter_chart(){
		
		this.adid =this.filterchart.value.adid;
		this.location =this.filterchart.value.location;
		this.date_from =this.filterchart.value.datefrom;
		this.date_to =this.filterchart.value.dateto;
		this.CountEligibilityPen=0;
		this.TotalEligibilityPend=0;
		this.CountEligibilityClear=0;
		this.TotalEligibilityClear=0;
		this.CountEligFail=0;
		this.TotaltEligFail=0;
		this.CountIAPPend=0;
		this.TotaltIAPPend=0;
		this.CountIAPSuss=0;
		this.TotaltIAPSuss=0;
		this.CountIAPFail=0;
		this.TotaltIAPFail=0;
		this.CountIAPCP=0;
		this.TotaltIAPCP=0;
		this.countLoanamt1.length=0;
		this.countEligibilityClear1.length=0;
		this.countEligibilityFail1.length=0;
		this.countIAPPending1.length=0;
		this.countIAPSuccess1.length=0;
		this.countIAPFail1.length=0;
		this.countIAPComplete1.length=0;
		this.total=0;
		this.totalcount	=0;	
		this.adid =this.filterchart.value.adid;
		this.filter =this.filterchart.value.adid;
		let date = new Date();
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//this.datemainArry.length=0;
		//console.log(this.currentdate);
		 if(this.adid !="" && this.location !="" && this.date_from !="" && this.date_to !=""){
			if(this.adid !=""){
				    this.aadkey ="adkey";
				    const mainarray= this.elements;				
					const  adid =this.filterchart.value.adid;
					//console.log(adid[0]);
					if(adid[0]==="All-Adid-Select"){
						this.adidarray =this.elements;
					}else{

					
					//console.log(adid);
					let filtered = [];
					
					adid.filter(function(newadid) {
						return   mainarray.filter(function(mainArray) {
							if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
							filtered.push({
							user_id:     mainArray.user_id, 
							first_name:  mainArray.first_name, 
							last_name:   mainArray.last_name,              
							provider:    mainArray.provider,
							city_name:   mainArray.city_name,
							loan_amount: mainArray.loan_amount,
							ad_id:       mainArray.ad_id,
							reg_date:    mainArray.reg_date,
							status:      mainArray.status
			
							
							})
						  }
						})
					  });
					  this.adidarray =filtered;
					}
					 
			 } 
			if(this.location !=""){
				    const mainarray=this.adidarray;				  
				    this.aadkey ="cityname";
					const  location =this.location;
					if(location[0]==="All-city-Select"){
						this.cityarray =mainarray;
						let resultdate =[]
					
					resultdate = mainarray.filter(d => {
						const  reg_date= d.reg_date;
						const regdate = reg_date.split(' ')[0];					            
						return (regdate >= this.date_from   && regdate  <= this.date_to );            
					
						
					});
					}else{

					
					//console.log(location);
					let filtered = [];
					
					location.filter(function(newCity) {
				    return   mainarray.filter(function(mainArray) {
					if (newCity === mainArray.city_name && newCity !="All-city-Select") {
					filtered.push({
					user_id:     mainArray.user_id, 
					first_name:  mainArray.first_name, 
					last_name:   mainArray.last_name,              
					provider:    mainArray.provider,
					city_name:   mainArray.city_name,
					loan_amount: mainArray.loan_amount,
					ad_id:       mainArray.ad_id,
					reg_date:    mainArray.reg_date,
					status:      mainArray.status
	
					
					})
				  }
				})
			  });
			  this.cityarray =filtered;
			}
            }
			if(this.date_from !="" && this.date_to !=""){
				this.date_from =this.filterchart.value.datefrom;
				this.date_to =this.filterchart.value.dateto;
				this.adDate="2020-05-02";
				if(this.adDate >= this.date_from &&  this.adDate >= this.date_to){
					alert('Ad Id  select  should be  greter then 2020-05-02 !');
					return;
			  
				}else{
					
					this.ToandFromDate =(this.date_from+'/'+this.date_to);
					const mainarray= this.cityarray;
					//console.log(mainarray);
					this.aadkey ="filterDate";
					let resultdate =[]
					
					resultdate = mainarray.filter(d => {
						const  reg_date= d.reg_date;
						const regdate = reg_date.split(' ')[0];					            
						return (regdate >= this.date_from   && regdate  <= this.date_to );            
					
						
					});
					this.datemainArry=resultdate;
				}
			
				
			}

		 }else if(this.adid !="" && this.location !=""){
			if(this.adid !=""){
				this.aadkey ="ad_location";
				const mainarray= this.elements;				
				const  adid =this.filterchart.value.adid;
				if(adid[0]==="All-Adid-Select"){
					let resultdate =[]  
					this.adDate="2020-05-02";
			
					resultdate = mainarray.filter(d => {
						const  reg_date= d.reg_date;
						const regdate = reg_date.split(' ')[0];					            
						return (regdate >= this.adDate   && regdate  <= this.currentdate );            
					
						
					});
					this.adidarray =resultdate;
					
				}else{


								//
				let filtered = [];				
				adid.filter(function(newadid) {
					return   mainarray.filter(function(mainArray) {
						if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
						filtered.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status
		
						
						})
					  }
					})
				  });
				  this.adidarray =filtered;
				}
		 } 
		if(this.location !=""){
				const mainarrayL=this.adidarray;  
				//console.log(mainarrayL);
				const  location =this.location;
				if(location[0]==="All-city-Select"){
					this.datemainArry =mainarrayL;
				}else{

				let filtered = [];
				
				location.filter(function(newadid) {
				return   mainarrayL.filter(function(mainArray) {
				if (newadid === mainArray.city_name && newadid!="All-city-Select") {
				filtered.push({
				user_id:     mainArray.user_id, 
				first_name:  mainArray.first_name, 
				last_name:   mainArray.last_name,              
				provider:    mainArray.provider,
				city_name:   mainArray.city_name,
				loan_amount: mainArray.loan_amount,
				ad_id:       mainArray.ad_id,
				reg_date:    mainArray.reg_date,
				status:      mainArray.status

				
				})
			  }
			})
		  });
		  this.datemainArry =filtered;
		}
		}
		 }else if(this.adid !="" && this.date_from !="" && this.date_to !=""){			
			if(this.adid !=""){
				this.aadkey ="ad_date";
				const mainarray= this.elements;				
				const  adid =this.filterchart.value.adid;
				if(adid[0]==="All-Adid-Select"){
					this.adidarray =this.elements;
				}else{			
				let filtered = [];				
				adid.filter(function(newadid) {
					return   mainarray.filter(function(mainArray) {
						if (newadid === mainArray.ad_id  && newadid !="All-Adid-Select") {
						filtered.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status
		
						
						})
					  }
					})
				  });
				  this.adidarray =filtered;
				}
				  
				  if(this.date_from !="" && this.date_to !=""){
					this.date_from =this.filterchart.value.datefrom;
					this.date_to =this.filterchart.value.dateto;
					//console.log(this.date_from);
					this.adDate="2020-05-02";
					if(this.adDate >= this.date_from &&  this.adDate >= this.date_to){
						alert('Ad Id  select  should be  greter then 2020-05-02 !');
						return;
				  
					}else{
						
						this.ToandFromDate =(this.date_from+'/'+this.date_to);
						const mainarray= this.adidarray;
						let filtered =[]
					
						filtered = mainarray.filter(d => {
							const  reg_date= d.reg_date;
							const regdate = reg_date.split(' ')[0];
							//console.log(regdate);
							//console.log(this.date_from);
							//console.log(this.date_to);					            
							return (regdate >= this.date_from   && regdate  <= this.date_to );            
						
							
						});
						this.datemainArry =filtered;
						//console.log(this.datemainArry);
						//console.log(mainarray);
					}
				
					
				}
				 
		 }

		 }else if(this.location !="" && this.date_from !="" && this.date_to !=""){
			if(this.location !=""){
				const mainarray=this.elements;					  
				this.aadkey ="location_date";
				const  location =this.location;
				if(location[0]==="All-city-Select"){
					this.cityarray =mainarray;
				}else{
				let filtered = [];
				
				location.filter(function(newadid) {
				return   mainarray.filter(function(mainArray) {
				if (newadid === mainArray.city_name && newadid!="All-city-Select") {
				filtered.push({
				user_id:     mainArray.user_id, 
				first_name:  mainArray.first_name, 
				last_name:   mainArray.last_name,              
				provider:    mainArray.provider,
				city_name:   mainArray.city_name,
				loan_amount: mainArray.loan_amount,
				ad_id:       mainArray.ad_id,
				reg_date:    mainArray.reg_date,
				status:      mainArray.status

				
				})
			  }
			})
		  });
		  this.cityarray =filtered;
		}
		}
		if(this.date_from !="" && this.date_to !=""){
			this.date_from =this.filterchart.value.datefrom;
			this.date_to =this.filterchart.value.dateto;
			this.adDate="2020-05-02";
			if(this.adDate >= this.date_from &&  this.adDate >= this.date_to){
				alert('Ad Id  select  should be  greter then 2020-05-02 !');
				return;
		  
			}else{
				
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.cityarray;
				//console.log(mainarray);				
				let resultdate =[]			
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];					            
					return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				console.log(this.datemainArry);
			}
		
			
		}
		 }else if(this.adid !="" ){
			this.aadkey ="addkey";
			const mainarray= this.elements;				
			const  adid =this.filterchart.value.adid;
			//console.log(adid);
			if(adid[0]==="All-Adid-Select"){
				let resultdate =[]  
				this.adDate="2020-05-02";
			
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];					            
					return (regdate >= this.adDate   && regdate  <= this.currentdate );            
				
					
				});
				
				this.datemainArry =resultdate;
			}else{

			let filtered = [];
		
			adid.filter(function(newadid) {
				return   mainarray.filter(function(mainArray) {
					if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
					filtered.push({
					user_id:     mainArray.user_id, 
					first_name:  mainArray.first_name, 
					last_name:   mainArray.last_name,              
					provider:    mainArray.provider,
					city_name:   mainArray.city_name,
					loan_amount: mainArray.loan_amount,
					ad_id:       mainArray.ad_id,
					reg_date:    mainArray.reg_date,
					status:      mainArray.status
		
					
					})
				  }
				})
			  });
			  this.datemainArry =filtered;
			}
		}else if(this.location !=""){
			const mainarray=this.elements;				  
			this.aadkey ="cityname";
			const  Cityname =this.location;
			//console.log(Cityname);
			if(Cityname[0]==="All-city-Select"){
				this.datemainArry =mainarray;
				const  location =this.optionslocation;
	
							
						let filtered1 = [];
						
						location.filter(function(newadid) {
							//console.log(newadid.value);
						return   mainarray.filter(function(mainArray) {
						if (newadid.value === mainArray.city_name && newadid.value !="All-city-Select") {
						filtered1.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status
					
						
						})
					}
					})
					});
					this.datemainArry =filtered1;
					console.log(this.datemainArry);
			}else{
			let filtered = [];
			const  location =this.location;
			location.filter(function(newadid) {
			return   mainarray.filter(function(mainArray) {
			if (newadid === mainArray.city_name && newadid!="All-city-Select") {
			filtered.push({
			user_id:     mainArray.user_id, 
			first_name:  mainArray.first_name, 
			last_name:   mainArray.last_name,              
			provider:    mainArray.provider,
			city_name:   mainArray.city_name,
			loan_amount: mainArray.loan_amount,
			ad_id:       mainArray.ad_id,
			reg_date:    mainArray.reg_date,
			status:      mainArray.status
		
			
			})
		  }
		})
		});
		this.datemainArry =filtered;
	}
		}else if( this.date_from !="" && this.date_to !=""){
			this.date_from =this.filterchart.value.datefrom;
			this.date_to =this.filterchart.value.dateto;
			this.adDate="2020-05-02";
			if(this.adDate >= this.date_from &&  this.adDate >= this.date_to){
				alert('Ad Id  select  should be  greter then 2020-05-02 !');
				return;
		  
			}else{
				
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.elements;
				//console.log(mainarray);
				this.aadkey ="Date";
				let resultdate =[]
				
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];	
									            
					return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				//console.log(this.datemainArry.length);
			}
		 }
		 //graph
		 if(this.datemainArry.length > 0){
			this.main_array=this.datemainArry;
			
		 }
		let mainarry= this.main_array;
		//console.log(mainarry.length);
		//console.log(mainarry);
		 this.totalLoanamt1 = 0;
									
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 //console.log(element);
			 if(element.status == "Eligibility_Pending"){
			   //console.log(element);
				this.countLoanamt1.push({ status: element.appstage});
				
			 }
		    if(element.status === "Eligibility_Pending" && element.loan_amount != undefined){
				this.totalLoanamt1 += Number(element.loan_amount); 
				//console.log(this.totalLoanamt1);
			   
			 }
			 
		 }	
		 this.CountEligibilityPen=this.countLoanamt1.length;
		 this.TotalEligibilityPend=this.totalLoanamt1
		//console.log(this.CountEligibilityPen);			 
		 
		 //console.log(this.TotalEligibilityPend);
		 this.totalEligibilityClear1 = 0;
		 
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "Eligibility_Clear"){
				//console.log(element);
				this.countEligibilityClear1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "Eligibility_Clear" && element.loan_amount != undefined){
				this.totalEligibilityClear1 += Number(mainarry[i].loan_amount);									
 
			}
			 
		 }	
		 this.CountEligibilityClear=this.countEligibilityClear1.length;
		 this.TotalEligibilityClear=this.totalEligibilityClear1;
		 // console.log(this.CountEligibilityClear);	 
		 // console.log(this.TotalEligibilityClear);
		 this.totalEligibilityFail1 = 0;
		 
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "Eligibility_Fail"){
				//console.log(element);
				this.countEligibilityFail1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "Eligibility_Fail" && element.loan_amount != undefined){
				this.totalEligibilityFail1 += Number(mainarry[i].loan_amount); 
				//this.countEligibilityFail1.push({ status: this.appstage});
			 }
			 
		 }	
		 this.CountEligFail=this.countEligibilityFail1.length;
		 this.TotaltEligFail=this.totalEligibilityFail1;
		 //console.log(this.CountEligFail);		
		 //console.log(this.TotaltEligFail);	
		 
		 this.totalIAPPending1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Pending"){
				//console.log(element);
				this.countIAPPending1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Pending" && element.loan_amount != undefined){
				this.totalIAPPending1 += Number(mainarry[i].loan_amount);									
				//this.countIAPPending1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPPend=this.countIAPPending1.length;
		 this.TotaltIAPPend=this.totalIAPPending1;
		 //console.log(this.countIAPPending.length);			 
		 
		 //console.log(this.totalIAPPending);
		 this.totalIAPSuccess1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Success"){
				//console.log(element);
				this.countIAPSuccess1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Success" && element.loan_amount != undefined){
				this.totalIAPSuccess1 += Number(mainarry[i].loan_amount);									
				//this.countIAPSuccess1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPSuss=this.countIAPSuccess1.length;
		 this.TotaltIAPSuss=this.totalIAPSuccess1;
		 //console.log(this.CountIAPSuss);
		 //console.log(this.TotaltIAPSuss);
		 this.totalIAPFail1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Fail"){
				//console.log(element);
				this.countIAPFail1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Fail" && element.loan_amount != undefined){
				this.totalIAPFail1 += Number(mainarry[i].loan_amount);									
				//this.countIAPFail1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPFail=this.countIAPFail1.length;
		 this.TotaltIAPFail=this.totalIAPFail1;
		 //console.log(this.countIAPFail.length);			 
		 
		 //console.log(this.totalIAPFail);
		 this.totalIAPComplete1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Complete"){
				//console.log(element);
				this.countIAPComplete1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Complete" && element.loan_amount != undefined){
				this.totalIAPComplete1 += Number(mainarry[i].loan_amount);									
				//this.countIAPComplete1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPCP=this.countIAPComplete1.length;
		 this.TotaltIAPCP=this.totalIAPComplete1;	
		 this.total =(this.TotalEligibilityPend+this.TotalEligibilityClear+this.TotaltEligFail+this.TotaltIAPPend+this.TotaltIAPSuss+this.TotaltIAPFail+this.TotaltIAPCP);
		 this.totalcount =(this.CountEligibilityPen+this.CountEligibilityClear+this.CountEligFail+this.CountIAPPend+this.CountIAPSuss+this.CountIAPFail+this.CountIAPCP);		
	   // console.log(this.TotaltIAPFail);
							   let epval =this.TotalEligibilityPend;
							   let ecval =this.TotalEligibilityClear;
							   let efval =this.TotaltEligFail;
							   let ipval =this.TotaltIAPPend;
							   let isval =this.TotaltIAPSuss;
							   let ifval =this.TotaltIAPFail;
							   let icval =this.TotaltIAPCP;
		   //Canvas 
		   let chart = new CanvasJS.Chart("chartContainer", {
			   theme: "light",
			   animationEnabled: true,
			   //exportEnabled: true,
			   title:{text: "MeraEMI Data ANALYSIS"},
			   data: [{
				   type: "pie",
				   showInLegend: true,
				   toolTipContent: "<b>{name}</b>: ₹{y} (#percent%)",
				   indexLabel: "{name} - #percent%",
		   
				   dataPoints: [
					   { y: epval, name: "Eligibility Pending" },
					   { y: ecval, name: "Eligibility Clear" },
					   { y: efval, name: "Eligibility Fail" },
					   { y: ipval, name: "IAP Pending" },
					   { y: isval, name: "IAP Success" },
					   { y: ifval, name: "IAP Fail"},
					   { y: icval, name: "IAP Complete" }
					   
				   ]
				   
			   }]
		   });
			   
		   chart.render();

		}
	ngAfterViewInit(){
	
	}
	getLeadStage(stage){ 
		//console.log(stage); 
		var res = stage.split("/");
		//console.log(res); 
		const appstage =res[0];
		const filter =res[1];
		const cityname =res[2];
		const adkey =res[3];
		const date =res[4]+'/'+res[5];
		if(adkey === "filterDate"){
			//alert('ok');
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;	  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();
			const main_url=mainurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			// console.log(user.user_id);
		//console.log(dateurl2);
		window.open(dateurl2, "_blank");
		}else if(adkey === "ad_location"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;	  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();
			const main_url=mainurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="ad_date"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();			
			const dateurl=mainurl+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			//console.log(this.fulldate); 
			window.open(dateurl2, "_blank");
		}else if(adkey ==="location_date"){
			this.appStage = this.cryptojsService.encryptData((appstage));				  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();			
			const main_url=detailsurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="addkey"){
			
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();		
			const dateurl2=mainurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="cityname"){
			this.appStage = this.cryptojsService.encryptData((appstage));				  
			this.filterCityname = cityname;
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();			
			const main_url=detailsurl+"&data3="+this.filterCityname.toString();			
			const dateurl2=main_url+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="Date"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const dateurl=detailsurl+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}
		
		else{
		this.appStage = this.cryptojsService.encryptData((appstage));
		this.filterStage = filter;
	   //const  out =  useid.replace(/\s/g, "");
	   //console.log(this.useid);
		const base_url=location.origin+"/#/leads-stage-least?data=";
		const detailsurl = base_url + this.appStage.toString();
		const mainurl=detailsurl+"&data2="+this.filterStage.toString();
	   // console.log(user.user_id);
		//console.log(mainurl);
		window.open(mainurl, "_blank");
		}
		
		
		
	}
	DashbordReset(user_id){
		this.filterchart.reset();
		this.main_array=0;
		window.location.reload();
	}
	backmenu(){
		this.showLoader = false;
		setTimeout(()=>{    
		  //this.showLoader = true;
		  this.router.navigate(['/dashboard']);
		}, 2000);
	   
		
		}
		rotate(event){
			event.srcElement.classList.remove("rotate");
			event.srcElement.classList.remove("tooltiptext");
			setTimeout(()=>{
			  event.srcElement.classList.add("rotate");
			  event.srcElement.classList.remove("tooltiptext");
			location.reload();
			},0)
		  }
}