import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  AngularFireAuth
} from 'angularfire2/auth';
import {
  FirebasedataService
} from 'src/app/services/firebasedata/firebasedata.service';
import {
  Observable,
  Subscription
} from 'rxjs';
import {
  User
} from 'firebase';
import {
  ReportService
} from 'src/app/services/report/report.service';
import * as firebase from 'firebase';
export {
  User
}
from 'firebase';
import {
  CookieService
} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  public authState: User = null;
  private user: User;
  private sub: Subscription;
  error: string;
  progress: boolean;
  errorMessage: string;
  currentUser: any = [];
  showLoader: boolean;
  hide: boolean = true;
  cryptojsService: any;
  decryptData: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private firebasedataService: FirebasedataService,
   
    public cookieService: CookieService

  ) {}

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.loginForm = this.formBuilder.group({
      login_email: ['', [Validators.required, Validators.email]],
      login_password: ['', [Validators.required,Validators.minLength(6)]],

    });
    this.sub = this.authState$.subscribe(user => {

      this.user = user;
      //  console.log(user)

    });


  }

  private showError(error: string) {

    this.error = error;
    this.progress = false;
    // setTimeout(() => this.error = null, 5000);
  }

  get authenticated() {
    return this.user !== null;
  }


  get authState$(): Observable < User | null > {
    return this.afAuth.user;
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.signIn(this.f.login_email.value, this.f.login_password.value)
    // this.updateloginTime()

  }

  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    //this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }

  public signIn(email: string, password: string): Promise < any > {
    //console.log("Signing in as: " + email);

    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(
        () => {
          this.showLoader = false;
          //   console.log( this.afAuth.auth)
         // this.decryptData = this.cryptojsService.decryptData(this.afAuth.auth.currentUser);
             // console.log(this.decryptData)
          //  if(this.user){
          this.firebasedataService.checkUserExist(this.afAuth.auth.currentUser.uid)
         // console.log(this.afAuth.auth.currentUser.uid)

          this.firebasedataService.getUserData().subscribe(data => {
            // localStorage.setItem('currentUser',JSON.stringify(data))
             localStorage.setItem('token_rm',data)

           // console.log(data)
            if (data && data != null) {
              this.cookieService.set('currentUser', JSON.stringify(data));
              this.cookieService.set('token_rm', data.token);
              this.reportSuccess('Signed in as ' + email, 'meraemi-dashboard')
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
            } else {
              this.showLoader = false;
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
              this.errorMessage = "Error while login";
              this.showError(this.errorMessage);
              //  this.reportSuccess('Signed in as ' + email,'rm-login')


            }


          })

          //  }

        }


      ).catch(error => {
        console.log(error)
        if (error.code == 'auth/user-not-found') {
          this.errorMessage = "Username doesn't exist.";
        } else if (error.code == 'auth/wrong-password') {
          this.errorMessage = "Password is invalid.";
        }
        this.showError(this.errorMessage);

      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

  }

}
