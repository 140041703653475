import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';


import { BusinessPartnerService } from 'src/app/services/business-partner/business-partner.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-mera-emi-business-partner',
  templateUrl: './mera-emi-business-partner.component.html',
  styleUrls: ['./mera-emi-business-partner.component.scss']
})
export class MeraEMIBusinessPartnerComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  headElements = [
    {
       name:"Partner id",
       sortBy:"user_id",
       width: 150
     },
     {
      name:"Partner Name",
      sortBy:"Name",
      width: 150
    },
     {
       name:"Partner Email",
       sortBy:"Email"
     },
     
     {
       name:"Partner Contact ",
       sortBy:"Mob_no"
     },
     
     {
       name:"Partner Operating From",
       sortBy:"Source"
     },
     
     {
       name:"Partner Pincode",
       sortBy:"status"
     },
     {
      name:"Partner Date",
      sortBy:"status"
    },
     {
       name:"View",
       sortBy:"send_message"
     },
  
   ];
  searchText: any;
  previous: any = [];
  elements: any = [];
 
  constructor( 
      private cryptojsService:CryptojsService,     
    
      private router: Router,
      private cdRef: ChangeDetectorRef,      
      private BusinessPartnerService:BusinessPartnerService,
      
      ) { }

  ngOnInit() {
     
        
        
   
    
    this.BusinessPartnerService
    .getAllbusinessPartner()
    .subscribe( (result:any) => {
   // console.log(result);
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.data[0].partner.length;  i++) {
          const element = result.data[0].partner[i];
         
          
         
       
                            this.elements.push(
                              {
                                partnerId:  element.partnerId,  
                                partnerFname:  element.partnerFname,
                                partnerLname:  element.partnerLname,
                                partnerEmail:  element.partnerEmail,  
                                partnerMobile:  element.partnerMobile, 
                                partnerPincode:  element.partnerPincode,  
                                partnerOperatingFrom:  element.partnerOperatingFrom,  
                                partnerDate:  element.partnerDate, 


                                   
                                
                              }
                              );
                              
                                
            }
         // console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
   

  
  // Employer  Login Method
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter







        
     
     //user Details
       getLeadDetails(data){
        console.log(data);
        const partnerid= this.cryptojsService.encryptData((data));       
        const base_url=location.origin+"/#/MeraEMI-Business-Partner-details?data=";        
        const detailsurl = base_url + partnerid.toString();

        const dateur=detailsurl;
       
        //console.log(detailsurl);
        window.open(dateur, "_blank");
   
       }


rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
 
   
   
}