// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 
    firebase:{
      apiKey: "AIzaSyDmCsB6ShhTLMiy3UXqW7QlDyCAaFvLdd4",

      authDomain: "smart-setting-246106.firebaseapp.com",

      databaseURL: "https://smart-setting-246106.firebaseio.com",

      projectId: "smart-setting-246106",

      storageBucket: "smart-setting-246106.appspot.com",

      messagingSenderId: "681005994628",

      appId: "1:681005994628:web:d8b195a326f819cda7a8d1",

      measurementId: "G-488PL4NQ06"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
