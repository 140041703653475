
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans/loans.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';

import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';

//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';

import { DatePipe } from '@angular/common';
import { contains } from 'jquery';

import { LenderService } from 'src/app/services/lender/lender.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhatsappService } from 'src/app/services/whatsapp/whatsapp.service';
@Component({
      providers: [DatePipe],
      selector: 'app-mera-emi-register-form-edit',
      templateUrl: './mera-emi-register-form-edit.component.html',
      styleUrls: ['./mera-emi-register-form-edit.component.scss']
})
export class MeraEMIRegisterFormEditComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("frame1", {static: true}) frame1: ModalDirective;
  name = "Angular CLI: 11.2.1,  Node: 13.14.0,  OS: win32 x64";
  tabval: number=1;
  mnameid: number;
  sub: any;
  user:any;
  subs: Subscription;
  rolecode: any;
  loginAdmin: any;
  data: any;
decryptData: any;
tab : any = 'tab1';
tab1 : any
tab2 : any
tab3 : any
Clicked : boolean
  pennoid: number;
  uppercaseValue: any;
  inputValue: any;
  residenceStatus: any = [];
  currentAddressRentedAgrementSelect: { value: string; label: string; }[];
  optionsSelect: { value: string; label: string; }[]; 
  loantenureSelect: { value: string; label: string; }[];
  maritalStatusSelect: { value: string; label: string; }[];
  emptypeSelect: { value: string; label: string; }[];
  user_id: any;
  loanid: any;
  add: any;
  mobileno: any;
  fname: any;
  lname: any;
  loder: number;
  employerCat: any = [];
  employerCatSelect: { value: string; label: string; }[];
  busYear: any = [];
  busYearSelect: { value: string; label: string; }[];
  fnemeid: number;
  lnameid: number;
  mname: any;
  mobilenoid: number;
  pincode: string;
  pincodeid: number;
  dobid: number;
  dob: string;
  loanAmtid: number;
  loanAmt: string;
  loantenureid: number;
  loantenure: string;
  residenceStatusid: number;
  residingHereid: number;
  residingHere: any;
  emptypeid: number;
  emptype: string;
  businessCategoryid: number;
  businessCategory: string;
  businessCategoryArray:any = [];
  businessCategorySelect: { value: string; label: string; }[];
  industryTypeid: number;
  industryType: string;
  businessName: string;
  yearsOfBusiness: string;
  yearsOfBusinessId: number;
  natureOfBusinessId: number;
  natureOfBusiness: string;
  establishmentStatusid: number;
  establishmentStatus: string;
  proofOfBusinessid: number;
  proofOfBusiness: any;
  natureMasterArray: any= [];
  natureMasterArraySelect:{ value: string; label: string; }[];
  businessNameid: number;
  BusinessEstablishmentArray: any= [];
  BusinessEstablishmentArraySelect: { value: string; label: string; }[];
  previousFinancialYearid: number;
  previousFinancialYear: string;
  creditProfileid: number;
  creditProfile: any;
  produnctid: any;
  prodId: number;
  maritalStatusid: number;
  maritalStatus: any;
  educationQualificationid: number;
  educationQualification: any;
  penid: number;
  penno: string;
  fatherFname: string;
  fatherFnameid: number;
  fatherLnameid: number;
  fatherLname: string;
  motherFnameid: number;
  motherFname: string;
 
  currentAddress1id: number;
  currentAddress1: string;
  currentAddress2id: number;
  currentAddress2: string;
  currentAddressLandmarkid: number;
  currentAddressLandmark: string;
  currentAddressPinid: number;
  currentAddressPin: string;
  PermanentAddress1id: number;
  PermanentAddress1: string;
  PermanentAddress2id: number;
  PermanentAddress2: string;
  PermanentAddressLandmarkid: number;
  PermanentAddressLandmark: string;
  PermanentAddressPinid: number;
  PermanentAddressPin: string;
  OfficeAddress1id: number;
  OfficeAddress1: string;
  OfficeAddress2: string;
  OfficeAddress2id: number;
  OfficeLandmarkid: number;
  OfficeLandmark: string;
  officeAddressPinid: number;
  officeAddressPin: string;
  RefernceLName1id: number;
  RefernceFName1: string;
  RefernceName1id: number;
  RefernceLName1: string;
  RefernceNameContact1id: number;
  RefernceNameContact1: string;
  RefernceRelation1id: number;
  RefernceRelation1: string;
  RefernceFName2id: number;
  RefernceFName2: string;
  RefernceLName2id: number;
  RefernceLName2: any;
  RefernceRelation2id: number;
  RefernceNameContact2: string;
  RefernceNameContact2id: number;
  RefernceRelation2: string;
  email: string;
  emailid: number;
  ischeck: number=0;
  creditPofileArray: any= [];
  creditPofileArraySelect: { value: string; label: string; }[];
  documentBusinessArray: any= [];
  documentBusinessSelect: { value: string; label: string; }[];

  industryArray: any= [];
  industrySelect: { value: string; label: string; }[];
  currentFinancialYearid: number;
  currentFinancialYear: string;
  genderSelect: { value: string; label: string; }[];
  genderid: number;
  gender: string;
  productcode: string;
  existingEmi: string;
  locationid: number;
  location: string;
  existingemiValue: string;
  adminuser_id: string;
  uid: any;
  jsonArry: any;
  email_address: any;
  first_name: any;
  middle_name: any;
  last_name: any;
  mobile_number: any;
  location_id: any;
  marital_status: any;
  loan_id: any;
  product_id: any;
  loan_amount: any;
  loan_tenure: any;
  residence_id: any;
  pan_no: any;
  industry_id: any;
  total_years_in_business: any;
  business_name: any;
  previous_fi_yr: any;
  current_fi_yr: any;
  nature_of_business: any;
  loan_appl_business_detail_id: any;
  business_type: any;
  loan_appl_emp_detail_id: any;
  employment_type_id: any;
  name_of_employer: any;
  total_years_in_service: any;
  employer_address1: any;
  employer_address2: any;
  employer_address3: any;
  employer_pin_code: any;
  Permanent_loan_appl_address_id: any;
  Permanent_address_type: any;
  Permanent_address_1: any;
  Permanent_address_2: any;
  Permanent_address_3: any;
  Permanent_address_pincode: any;
  Residence_loan_appl_address_id: any;
  Residence_address_type: any;
  Residence_address_1: any;
  Residence_address_2: any;
  Residence_address_3: any;
  Residence_address_pincodeas: any;
  ref_id: any;
  ref_first_name: any;
  ref_last_name: any;
  ref_relation: any;
  ref_mobile_no: any;
  ref_first_name1: any;
  ref_last_name1: any;
  ref_relation1: any;
  ref_mobile_no1: any;
  response_id: any;
  profile_id: any;
  app_stage: any;
  credit_history_id: any;
  new_to_loan: any;
  credit_profile_status: any;
  fatherFirstName: any;
  mother_maiden_name: any;
  fatherLastName: any;
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private leadsService:LeadsService,
    private router:Router,
    private trackProgressService:TrackProgressService,
  
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,
    private lenderService:LenderService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private datePipe: DatePipe,
    private WhatsappService:WhatsappService,
       
       private formBuilder: FormBuilder
    
  ) {  }
 
  myForm: FormGroup;

  ngOnInit() {

    this.WhatsappService
    .getResidenceStatus()
    .subscribe( (result:any) => {
      const data= result.data[0].residence;
      for (let i = 0; i < data.length; i++) {
        const residenceStatus = data[i];
         
      this.residenceStatus.push(
        {
          value: residenceStatus.residence_id,
          label: residenceStatus.residence,                 
         
        }
        );
        
      }
      this.currentAddressRentedAgrementSelect =this.residenceStatus;
      //console.log(this.currentAddressRentedAgrementSelect);
      
    });
    this.WhatsappService
    .getBusinessYear()
    .subscribe( (result:any) => {
      const data= result.data[0].businessYear;
      for (let i = 0; i < data.length; i++) {
        const busYears = data[i];
         
      this.busYear.push(
        {
          value: busYears.bus_year_id,
          label: busYears.bus_year_name,                 
         
        }
        );
      
      
     
      }
     // console.log( this.busYear);
      this.busYearSelect =this.busYear;
    });
    this.WhatsappService
    .getEmployerCat()
    .subscribe( (result:any) => {
      const empdata= result.data[0].emp_cat;
      for (let i = 0; i < empdata.length; i++) {
        const empCats = empdata[i];
         
      this.employerCat.push(
        {
          value: empCats.emp_cat_id,
          label: empCats.emp_cat,                 
         
        }
        );
     
      }
      this.employerCatSelect= this.employerCat;
     // console.log(  this.employerCatSelect);
    });
    this.WhatsappService
    .getBusinessCategory()
    .subscribe( (result:any) => {
      const busCatMaster=result.BusCatMaster;
      for (let i = 0; i < busCatMaster.length; i++) {
        const empCats = busCatMaster[i];
        this.businessCategoryArray.push(
          {
            value: empCats.employer_catg_id,
            label: empCats.employer_catg,                 
           
          }
          );
     // console.log(result.BusCatMaster.length);
      }
      this.businessCategorySelect=this.businessCategoryArray;
    });
    this.WhatsappService
    .getDocumentproofBusiness()
    .subscribe( (result:any) => {
    //  console.log(result); return false;
      const documentBusiness=result.DocumentBusiness ;
      for (let i = 0; i < documentBusiness.length; i++) {
        const empCats = documentBusiness[i];
        this.documentBusinessArray.push(
          {
            value: empCats.doc_id,
            label: empCats.dscription,                 
           
          }
          );
      
      }
      this.documentBusinessSelect=this.documentBusinessArray;
    });
    


    this.WhatsappService
    .getBusNatureMaster()
    .subscribe( (result:any) => {
      const natureMaster=result.natureMaster;
      for (let i = 0; i < natureMaster.length; i++) {
        const empCats = natureMaster[i];
        this.natureMasterArray.push(
          {
            value: empCats.bus_nature_id,
            label: empCats.bus_nature_type,                 
           
          }
          );
     // console.log(result.BusCatMaster.length);
      }
      this.natureMasterArraySelect=this.natureMasterArray;
     // console.log(result);
    });
    this.WhatsappService
    .getBusinessEstablishmentStatus()
    .subscribe( (result:any) => {
      const BusinessEstablishment=result.data[0].businessYear;
      console.log(BusinessEstablishment);
      for (let i = 0; i < BusinessEstablishment.length; i++) {
        const empCats = BusinessEstablishment[i];
        this.BusinessEstablishmentArray.push(
          {
            value: empCats.bus_estb_id,
            label: empCats.estb_status,                 
           
          }
          );
     // console.log(result.BusCatMaster.length);
      }
      this.BusinessEstablishmentArraySelect=this.BusinessEstablishmentArray;
     // console.log(result);
    });
    
    
    this.WhatsappService
    .getCreditPofile()
    .subscribe( (result:any) => {
     // console.log(result); return false;
      const CreditPofile=result.creditPofile;
    //  console.log(CreditPofile);
      for (let i = 0; i < CreditPofile.length; i++) {
        const empCats = CreditPofile[i];
        this.creditPofileArray.push(
          {
            value: empCats.credit_profile_id,
            label: empCats.credit_profile,                 
           
          }
          );
     
      }
      this.creditPofileArraySelect=this.creditPofileArray;
      //console.log(this.creditPofileArraySelect);
    });
    this.WhatsappService
    .getIndustry()
    .subscribe( (result:any) => {
    // console.log(result); return false;
      const industry=result.industry;
    //  console.log(CreditPofile);
      for (let i = 0; i < industry.length; i++) {
        const indTry = industry[i];
        this.industryArray.push(
          {
            value: indTry.industry_id,
            label: indTry.industry_name,                 
           
          }
          );
     
      }
      this.industrySelect=this.industryArray;
      //console.log(this.creditPofileArraySelect);
    });

    

    this.optionsSelect= [
      { value: '1', label: 'HL' },
      { value: '2', label: 'LAP' },
      { value: '3', label: 'BL' },
      { value: '4', label: 'PL' }
     
    ];
    this.maritalStatusSelect= [
      { value: '1', label: 'Marriage' },
      { value: '2', label: 'Single' },
     
    ];
    this.emptypeSelect= [
     
      { value: '2', label: 'Self Employed' },
      { value: '3', label: 'Professional' },
     
    ];
    this.loantenureSelect= [
      { value: '12', label: '12' },
      { value: '24', label: '24' },
      { value: '36', label: '36' },
      { value: '48', label: '48' },
      { value: '60', label: '60' },
     
    ];
    this.genderSelect= [
      { value: '1', label: 'Male' },
      { value: '2', label: 'Female' },
      
     
    ];
   
    this.myForm = this.formBuilder.group({
      // Example form controls with validators
      fname: ['', Validators.required],
      mname: ['', Validators.required],
      lname: ['', Validators.required],
      mobileno: ['', Validators.required],
      pincode: ['', Validators.required],
      dob: ['', Validators.required],
      loanAmt: ['', Validators.required],
      loantenure: ['', Validators.required],
      residenceStatus: ['', Validators.required],
      residingHere: ['', Validators.required],
      emptype: ['', Validators.required],
      businessCategory: ['', Validators.required],
      industryType: ['', Validators.required],
      businessName: ['', Validators.required],
      yearsOfBusiness: ['', Validators.required],
      natureOfBusiness: ['', Validators.required],
      proofOfBusiness: ['', Validators.required],
      establishmentStatus: ['', Validators.required],
      previousFinancialYear: ['', Validators.required],
      currentFinancialYear: ['', Validators.required],
      creditProfile: ['', Validators.required],
      educationQualification: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      produnctid: ['', Validators.required],
      penno: ['', Validators.required],
      fatherFname: ['', Validators.required],
      fatherLname: ['', Validators.required],
      motherFname: ['', Validators.required],
      

      currentAddress1: ['', Validators.required],
      currentAddress2: ['', Validators.required],
      currentAddressLandmark: ['', Validators.required],
      currentAddressPin: ['', Validators.required],
      
      PermanentAddress1: ['', Validators.required],
      PermanentAddress2: ['', Validators.required],
      PermanentAddressLandmark: ['', Validators.required],
      PermanentAddressPin: ['', Validators.required],


      OfficeAddress1: ['', Validators.required],
      OfficeAddress2: ['', Validators.required],
      OfficeLandmark: ['', Validators.required],
      officeAddressPin: ['', Validators.required],
      

      RefernceFName1: ['', Validators.required],
      RefernceLName1: ['', Validators.required],
      RefernceNameContact1: ['', Validators.required],
      RefernceRelation1: ['', Validators.required],
      
      
      RefernceFName2: ['', Validators.required],
      RefernceLName2: ['', Validators.required],
      RefernceNameContact2: ['', Validators.required],
      RefernceRelation2: ['', Validators.required],
      email: ['', Validators.required],
      gender: ['', Validators.required],
      location: ['', Validators.required],
      
      
      
      
  
  
  
      
  
  
      
      

     // penno: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],

      
      
    });
 //  get login Details
 this.sub = this.authState$.subscribe( user => {
  this.user = user;
  if(this.user){
  //  this.decryptData = this.cryptojsService.decryptData(this.user.uid);
  console.log(this.user);
      //  console.log(this.afAuth.auth.currentUser)
      this.firebasedataService.checkUserExist(this.user.uid)
      
      this.firebasedataService.getUserData().subscribe( data => {
        this.user = data;
        this.rolecode= this.user.rolecode;
        this.loginAdmin= this.user.first_name;
       //console.log(this.loginAdmin)
      }
    )
  }
 

   });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      this.uid=this.cryptojsService.decryptData(params.uid);
    
              //user_id
              const  uid = params.uid;     
              this.user_id = uid;
              //temp id ya loanid
              this.loanid = params.loanid; 
              //add
              this.add = params.add;  
              //mobileno
              this.mobileno = params.mobileno; 
              //fname
              this.fname = params.fname; 
              
              //lname
              this.lname = params.lname; 
               //console.log(loanid);       
              //console.log(this.userstage);
              
        
      },(err)=>{
        console.log(err);
      });
      function comparer(otherArray){
        return function(current){
          return otherArray.filter(function(other){
            return other.value == current.value && other.display == current.display
          }).length == 0;
        }
      }

     //  get login Details
   this.sub = this.authState$.subscribe( user => {
    this.user = user;
    if(this.user){
        //console.log(this.user.uid)
        //  console.log(this.afAuth.auth.currentUser)
        this.firebasedataService.checkUserExist(this.user.uid)
        this.firebasedataService.getUserData().subscribe( data => {
          this.user = data;
          this.rolecode= this.user.rolecode;
          this.loginAdmin= this.user.first_name;
          if(this.loginAdmin == "Deepa"){
            this.adminuser_id="407";
          }else  if(this.loginAdmin == "Mohan"){
            this.adminuser_id="1818";
          }else  if(this.loginAdmin == "Anant"){
            this.adminuser_id="424";
          }else{
            this.adminuser_id="424";
          }
        //console.log(this.loginAdmin)
        }
      )
    }
  
  
  });
  this.sub = this.route
  .queryParams
  .subscribe(params => {
   // const  out =  params.data.replace(/\s/g, "+");
      this.data = params;
      this.decryptData = this.cryptojsService.decryptData(this.data);
      console.log(this.decryptData);
     
  });

//edit

this.WhatsappService
.getWhatsappIAPUserEditData(this.uid)
.subscribe( (result:any) => {
if(result.response==200  && result.status== true){
  this.jsonArry=result.edituser[0];
  //user
  this.user_id = this.jsonArry.userTableDtata.user_id;
  this.email_address=this.jsonArry.userTableDtata.email_address;
  this.first_name=this.jsonArry.userTableDtata.first_name;
  this.middle_name=this.jsonArry.userTableDtata.middle_name;
  this.last_name=this.jsonArry.userTableDtata.last_name;
  this.mobile_number=this.jsonArry.userTableDtata.mobile_number;
 
  
  //loanDetails
  this.loan_id=this.jsonArry.loanDetails.loan_id;
  this.product_id=this.jsonArry.loanDetails.product_id;
  this.dob=this.jsonArry.loanDetails.dob;
  this.location_id=this.jsonArry.loanDetails.location_id;
  this.loan_amount=this.jsonArry.loanDetails.loan_amount;
  this.loan_tenure=this.jsonArry.loanDetails.loan_tenure;
  this.residence_id=this.jsonArry.loanDetails.residence_id;
  this.pan_no=this.jsonArry.loanDetails.pan_no;
  this.marital_status=this.jsonArry.loanDetails.marital_status;
  this.gender=this.jsonArry.loanDetails.gender;
  this.location=this.jsonArry.loanDetails.location;
  this.fatherFirstName=this.jsonArry.loanDetails.fatherFirstName;
  this.fatherLastName=this.jsonArry.loanDetails.fatherLastName;
  this.mother_maiden_name=this.jsonArry.loanDetails.mother_maiden_name;
  
  //businessDetail
  this.total_years_in_business=this.jsonArry.businessDetail.total_years_in_business;
  this.business_name=this.jsonArry.businessDetail.business_name;


  this.previous_fi_yr=this.jsonArry.businessDetail.previous_fi_yr;
  this.current_fi_yr=this.jsonArry.businessDetail.current_fi_yr;
  this.nature_of_business=this.jsonArry.businessDetail.nature_of_business;
  this.loan_appl_business_detail_id=this.jsonArry.businessDetail.loan_appl_business_detail_id;
  this.business_type=this.jsonArry.businessDetail.business_type;
 

//empAddress
this.loan_appl_emp_detail_id=this.jsonArry.empAddress.loan_appl_emp_detail_id;
this.employment_type_id=this.jsonArry.empAddress.employment_type_id;
this.name_of_employer=this.jsonArry.empAddress.name_of_employer;
this.total_years_in_service=this.jsonArry.empAddress.total_years_in_service;
this.employer_address1=this.jsonArry.empAddress.employer_address1;
this.employer_address2=this.jsonArry.empAddress.employer_address2;
this.employer_address3=this.jsonArry.empAddress.employer_address3;
this.employer_pin_code=this.jsonArry.empAddress.employer_pin_code;
this.industry_id=this.jsonArry.empAddress.industry_id;
//permanentAdd
this.Permanent_loan_appl_address_id=this.jsonArry.permanentAdd.Permanent_loan_appl_address_id;
this.Permanent_address_type=this.jsonArry.permanentAdd.Permanent_address_type;
this.Permanent_address_1=this.jsonArry.permanentAdd.Permanent_address_1;
this.Permanent_address_2=this.jsonArry.permanentAdd.Permanent_address_2;
this.Permanent_address_3=this.jsonArry.permanentAdd.Permanent_address_3;               
this.Permanent_address_pincode=this.jsonArry.permanentAdd.Permanent_address_pincode;   

//residenceAdd

this.Residence_loan_appl_address_id=this.jsonArry.residenceAdd.Residence_loan_appl_address_id; 
this.Residence_address_type=this.jsonArry.residenceAdd.Residence_address_type; 
this.Residence_address_1=this.jsonArry.residenceAdd.Residence_address_1; 
this.Residence_address_2=this.jsonArry.residenceAdd.Residence_address_2; 
this.Residence_address_3=this.jsonArry.residenceAdd.Residence_address_3;                
this.Residence_address_pincodeas=this.jsonArry.residenceAdd.Residence_address_pincodeas;  
             
//refDetails
this.ref_id=this.jsonArry.refDetails.ref_id;  
this.ref_first_name=this.jsonArry.refDetails.ref_first_name;  
this.ref_last_name=this.jsonArry.refDetails.ref_last_name;  
this.ref_relation=this.jsonArry.refDetails.ref_relation;  
this.ref_mobile_no=this.jsonArry.refDetails.ref_mobile_no;  
this.ref_first_name1=this.jsonArry.refDetails.ref_first_name1;  
this.ref_last_name1=this.jsonArry.refDetails.ref_last_name1; 
this.ref_relation1=this.jsonArry.refDetails.ref_relation1; 
this.ref_mobile_no1=this.jsonArry.refDetails.ref_mobile_no1; 
 //appStageDetails  
 this.response_id=this.jsonArry.appStageDetails.response_id;           
 this.product_id=this.jsonArry.appStageDetails.product_id;
 this.profile_id=this.jsonArry.appStageDetails.profile_id;   
 this.app_stage=this.jsonArry.appStageDetails.app_stage;        
 
 

 //creditHistory  
 this.credit_history_id=this.jsonArry.creditHistory.credit_history_id;           
 this.new_to_loan=this.jsonArry.creditHistory.new_to_loan;
 this.credit_profile_status=this.jsonArry.creditHistory.credit_profile_status;   


}else{
  this.jsonArry=0;
}
  
  
});

  }
    


  onClick(check){
    //console.log(check);
    if(check==1){
      this.tab = 'tab1';
      this.tabval=1;
    }else if(check==2){
      this.tab = 'tab2';
      this.tabval=2;
     // console.log(this.cust);
    }else if(check==3){
      this.tab = 'tab3';
      this.tabval=3;
     console.log(this.tabval);
    }     
  
}
onSwitch(check){

switch (check) {
 case 1:
   return 'tab1';
 case 2:
   return 'tab2';
   case 3:
   return 'tab3';
 
}
}




get authState$(): Observable<User|null> {
  return this.afAuth.user;
}


submitForm() {
 // console.log('ok');
        this.frame1.show();
  if(this.myForm.value.fname == '' || this.myForm.value.fname ==null){
 
    if(this.fname == '' || this.fname == null){
      this.fnemeid=0;
      this.fname="null";
    }else{
      this.fnemeid=1;
      this.fname=this.fname;
    }
  }else{
    this.fnemeid=1;
    this.fname=this.myForm.value.fname;
  }
  if(this.myForm.value.mname == '' || this.myForm.value.mname ==null){
    this.mnameid=0;
    this.mname="null";
  
  }else{
    this.mnameid=1;
   this.mname= this.myForm.value.mname ;
  }
  if(this.myForm.value.lname == '' || this.myForm.value.lname ==null){
   
    if(this.lname == '' || this.lname == null){
      this.lnameid=0;
      this.lname="null";
    }else{
      this.lnameid=1;
      this.lname=this.lname;
    }
  }else{
    this.lnameid=1;
    this.lname=this.myForm.value.lname;
  }
  if(this.myForm.value.mobileno == '' || this.myForm.value.mobileno ==null){
 
    if(this.mobileno == '' || this.mobileno == null){
      this.mobilenoid=0;
      this.mobileno="null";
    }else{
      this.mobilenoid=1;
      this.mobileno=this.mobileno;
    }
  
  }else{
    this.mobilenoid=1;
    this.mobileno=this.myForm.value.mobileno;
  }
  if(this.myForm.value.pincode == '' || this.myForm.value.pincode ==null){
    this.pincodeid=0;
    this.pincode="null";
  }else{
    this.pincodeid=1;
    this.pincode=this.myForm.value.pincode;
  }

  if(this.myForm.value.dob == '' || this.myForm.value.dob ==null){
    this.dobid=0;
    this.dob="null";
  }else{
    this.dobid=1;
    this.dob=this.myForm.value.dob;
  }
  if(this.myForm.value.loanAmt == '' || this.myForm.value.loanAmt ==null){
    this.loanAmtid=0;
    this.loanAmt="null";
  }else{
    this.loanAmtid=1;
    this.loanAmt=this.myForm.value.loanAmt;
  }
  if(this.myForm.value.loantenure == '' || this.myForm.value.loantenure ==null){
    this.loantenureid=0;
    this.loantenure="null";
  }else{
    this.loantenureid=1;
    this.loantenure=this.myForm.value.loantenure;
  }

  if(this.myForm.value.residenceStatus == '' || this.myForm.value.residenceStatus ==null){
    this.residenceStatusid=0;
    this.residenceStatus="null";
  }else{
    this.residenceStatusid=1;
    this.residenceStatus=this.myForm.value.residenceStatus;
  }
  if(this.myForm.value.residingHere == '' || this.myForm.value.residingHere ==null){
    this.residingHereid=0;
    this.residingHere="null";
  }else{
    this.residingHereid=1;
    this.residingHere=this.myForm.value.residingHere;
  }
  if(this.myForm.value.emptype == '' || this.myForm.value.emptype ==null){
    this.emptypeid=0;
    this.emptype="null";
  }else{
    this.emptypeid=1;
    this.emptype=this.myForm.value.emptype;
  }
  if(this.myForm.value.businessCategory == '' || this.myForm.value.businessCategory ==null){
    this.businessCategoryid=0;
    this.businessCategory="null";
  }else{
    this.businessCategoryid=1;
    this.businessCategory=this.myForm.value.businessCategory;
  }
  if(this.myForm.value.industryType == '' || this.myForm.value.industryType ==null){
    this.industryTypeid=0;
    this.industryType="null";
  }else{
    this.industryTypeid=1;
    this.industryType=this.myForm.value.industryType;
  }
  if(this.myForm.value.businessName == '' || this.myForm.value.businessName ==null){
    this.businessNameid=0;
    this.businessName="null";
  }else{
    this.businessNameid=1;
    this.businessName=this.myForm.value.businessName;
  }
  if(this.myForm.value.yearsOfBusiness == '' || this.myForm.value.yearsOfBusiness ==null){
    this.yearsOfBusinessId=0;
    this.yearsOfBusiness="null";
  }else{
    this.yearsOfBusinessId=1;
    this.yearsOfBusiness=this.myForm.value.yearsOfBusiness;
  }
  if(this.myForm.value.natureOfBusiness == '' || this.myForm.value.natureOfBusiness ==null){
    this.natureOfBusinessId=0;
    this.natureOfBusiness="null";
  }else{
    this.natureOfBusinessId=1;
    this.natureOfBusiness=this.myForm.value.natureOfBusiness;
  }
  if(this.myForm.value.establishmentStatus == '' || this.myForm.value.establishmentStatus ==null){
    this.establishmentStatusid=0;
    this.establishmentStatus="null";
  }else{
    this.establishmentStatusid=1;
    this.establishmentStatus=this.myForm.value.establishmentStatus;
  }
  if(this.myForm.value.proofOfBusiness == '' || this.myForm.value.proofOfBusiness ==null){
    this.proofOfBusinessid=0;
    this.proofOfBusiness="null";
  }else{
    this.proofOfBusinessid=1;
    this.proofOfBusiness=this.myForm.value.proofOfBusiness;
  }

  if(this.myForm.value.previousFinancialYear == '' || this.myForm.value.previousFinancialYear ==null){
    this.previousFinancialYearid=0;
    this.previousFinancialYear="null";
  }else{
    this.previousFinancialYearid=1;
    this.previousFinancialYear=this.myForm.value.previousFinancialYear;
  }
  if(this.myForm.value.currentFinancialYear == '' || this.myForm.value.currentFinancialYear ==null){
    this.currentFinancialYearid=0;
    this.currentFinancialYear="null";
  }else{
    this.currentFinancialYearid=1;
    this.currentFinancialYear=this.myForm.value.previousFinancialYear;
  }
  
  if(this.myForm.value.creditProfile == '' || this.myForm.value.creditProfile ==null){
    this.creditProfileid=0;
    this.creditProfile="null";
  }else{
    this.creditProfileid=1;
    this.creditProfile=this.myForm.value.creditProfile;
  }
  if(this.myForm.value.produnctid == '' || this.myForm.value.produnctid ==null){
    this.prodId=0;
    this.produnctid="null";
    this.productcode="BL";
  }else{
    this.prodId=1;
    this.produnctid=this.myForm.value.produnctid;

    if(this.produnctid=="1"){
      this.productcode="HL";
    }else  if(this.produnctid=="2"){
      this.productcode="LAP";
    }else  if(this.produnctid=="3"){
      this.productcode="BL";
    }else  if(this.produnctid=="4"){
      this.productcode="PL";
    }
  }
  if(this.myForm.value.maritalStatus == '' || this.myForm.value.maritalStatus ==null){
    this.maritalStatusid=0;
    this.maritalStatus="null";
  }else{
    this.maritalStatusid=1;
    this.maritalStatus=this.myForm.value.maritalStatus;
  }
  if(this.myForm.value.educationQualification == '' || this.myForm.value.educationQualification ==null){
    this.educationQualificationid=0;
    this.educationQualification="null";
  }else{
    this.educationQualificationid=1;
    this.educationQualification=this.myForm.value.educationQualification;
  }
  if(this.myForm.value.penno == '' || this.myForm.value.penno ==null){
    this.penid=0;
    this.penno="null";
  }else{
    this.penid=1;
    this.penno=this.myForm.value.penno;
  }
  if(this.myForm.value.fatherFname == '' || this.myForm.value.fatherFname ==null){
    this.fatherFnameid=0;
    this.fatherFname="null";
  }else{
    this.fatherFnameid=1;
    this.fatherFname=this.myForm.value.fatherFname;
  }
  if(this.myForm.value.fatherLname == '' || this.myForm.value.fatherLname ==null){
    this.fatherLnameid=0;
    this.fatherLname="null";
  }else{
    this.fatherLnameid=1;
    this.fatherLname=this.myForm.value.fatherLname;
  }
  if(this.myForm.value.motherFname == '' || this.myForm.value.motherFname ==null){
    this.motherFnameid=0;
    this.motherFname="null";
  }else{
    this.motherFnameid=1;
    this.motherFname=this.myForm.value.motherFname;
  }
 
  if(this.myForm.value.currentAddress1 == '' || this.myForm.value.currentAddress1 ==null){
    this.currentAddress1id=0;
    this.currentAddress1="null";
  }else{
    this.currentAddress1id=1;
    this.currentAddress1=this.myForm.value.currentAddress1;
  }
  if(this.myForm.value.currentAddress2 == '' || this.myForm.value.currentAddress2 ==null){
    this.currentAddress2id=0;
    this.currentAddress2="null";
  }else{
    this.currentAddress2id=1;
    this.currentAddress2=this.myForm.value.currentAddress2;
  }
  if(this.myForm.value.currentAddressLandmark == '' || this.myForm.value.currentAddressLandmark ==null){
    this.currentAddressLandmarkid=0;
    this.currentAddressLandmark="null";
  }else{
    this.currentAddressLandmarkid=1;
    this.currentAddressLandmark=this.myForm.value.currentAddressLandmark;
  }
  if(this.myForm.value.currentAddressPin == '' || this.myForm.value.currentAddressPin ==null){
    this.currentAddressPinid=0;
    this.currentAddressPin="null";
  }else{
    this.currentAddressPinid=1;
    this.currentAddressPin=this.myForm.value.currentAddressPin;
  }
  if(this.myForm.value.PermanentAddress1 == '' || this.myForm.value.PermanentAddress1 ==null){
    if(this.ischeck == 1){
      this.PermanentAddress1id=1;
      this.PermanentAddress1= this.currentAddress1;
    }else{
      this.PermanentAddress1id=0;
      this.PermanentAddress1="null";
    }
    
  }else{
    this.PermanentAddress1id=1;
    this.PermanentAddress1=this.myForm.value.PermanentAddress1;
  }
  if(this.myForm.value.PermanentAddress2 == '' || this.myForm.value.PermanentAddress2 ==null){
    if(this.ischeck == 1){
      this.PermanentAddress2id=1;
      this.PermanentAddress2= this.currentAddress2;
    }else{
      this.PermanentAddress2id=0;
    this.PermanentAddress2="null";
    }
 
  }else{
    this.PermanentAddress2id=1;
    this.PermanentAddress2=this.myForm.value.PermanentAddress2;
  }
  if(this.myForm.value.PermanentAddressLandmark == '' || this.myForm.value.PermanentAddressLandmark ==null){
    if(this.ischeck == 1){
      this.PermanentAddressLandmarkid=1;
      this.PermanentAddressLandmark= this.currentAddressLandmark;
    }else{
      this.PermanentAddressLandmarkid=0;
      this.PermanentAddressLandmark="null";
    }
    
  }else{
    this.PermanentAddressLandmarkid=1;
    this.PermanentAddressLandmark=this.myForm.value.PermanentAddressLandmark;
  }
  if(this.myForm.value.PermanentAddressPin == '' || this.myForm.value.PermanentAddressPin ==null){
    if(this.ischeck == 1){
      this.PermanentAddressPinid=1;
      this.PermanentAddressPin= this.currentAddressPin;
    }else{
      this.PermanentAddressPinid=0;
      this.PermanentAddressPin="null";
    }
   
  }else{
    this.PermanentAddressPinid=1;
    this.PermanentAddressPin=this.myForm.value.PermanentAddressPin;
  }
  if(this.myForm.value.OfficeAddress1 == '' || this.myForm.value.OfficeAddress1 ==null){
    this.OfficeAddress1id=0;
    this.OfficeAddress1="null";
  }else{
    this.OfficeAddress1id=1;
    this.OfficeAddress1=this.myForm.value.OfficeAddress1;
  }
  if(this.myForm.value.OfficeAddress2 == '' || this.myForm.value.OfficeAddress2 ==null){
    this.OfficeAddress2id=0;
    this.OfficeAddress2="null";
  }else{
    this.OfficeAddress2id=1;
    this.OfficeAddress2=this.myForm.value.OfficeAddress2;
  }
  if(this.myForm.value.OfficeLandmark == '' || this.myForm.value.OfficeLandmark ==null){
    this.OfficeLandmarkid=0;
    this.OfficeLandmark="null";
  }else{
    this.OfficeLandmarkid=1;
    this.OfficeLandmark=this.myForm.value.OfficeLandmark;
  }
  if(this.myForm.value.officeAddressPin == '' || this.myForm.value.officeAddressPin ==null){
    this.officeAddressPinid=0;
    this.officeAddressPin="null";
  }else{
    this.officeAddressPinid=1;
    this.officeAddressPin=this.myForm.value.officeAddressPin;
  }
  if(this.myForm.value.RefernceFName1 == '' || this.myForm.value.RefernceFName1 ==null){
    this.RefernceName1id=0;
    this.RefernceFName1="null";
  }else{
    this.RefernceName1id=1;
    this.RefernceFName1=this.myForm.value.RefernceFName1;
  }
  if(this.myForm.value.RefernceLName1 == '' || this.myForm.value.RefernceLName1 ==null){
    this.RefernceLName1id=0;
    this.RefernceLName1="null";
  }else{
    this.RefernceLName1id=1;
    this.RefernceLName1=this.myForm.value.RefernceLName1;
  }
  if(this.myForm.value.RefernceNameContact1 == '' || this.myForm.value.RefernceNameContact1 ==null){
    this.RefernceNameContact1id=0;
    this.RefernceNameContact1="null";
  }else{
    this.RefernceNameContact1id=1;
    this.RefernceNameContact1=this.myForm.value.RefernceNameContact1;
  }
  if(this.myForm.value.RefernceRelation1 == '' || this.myForm.value.RefernceRelation1 ==null){
    this.RefernceRelation1id=0;
    this.RefernceRelation1="null";
  }else{
    this.RefernceRelation1id=1;
    this.RefernceRelation1=this.myForm.value.RefernceRelation1;
  }
  if(this.myForm.value.RefernceFName2 == '' || this.myForm.value.RefernceFName2 ==null){
    this.RefernceFName2id=0;
    this.RefernceFName2="null";
  }else{
    this.RefernceFName2id=1;
    this.RefernceFName2=this.myForm.value.RefernceFName2;
  }
  if(this.myForm.value.RefernceLName2 == '' || this.myForm.value.RefernceLName2 ==null){
    this.RefernceLName2id=0;
    this.RefernceLName2="null";
  }else{
    this.RefernceLName2id=1;
    this.RefernceLName2=this.myForm.value.RefernceLName2;
  }
  if(this.myForm.value.RefernceNameContact2 == '' || this.myForm.value.RefernceNameContact2 ==null){
    this.RefernceNameContact2id=0;
    this.RefernceNameContact2="null";
  }else{
    this.RefernceNameContact2id=1;
    this.RefernceNameContact2=this.myForm.value.RefernceNameContact2;
  }
  if(this.myForm.value.RefernceRelation2 == '' || this.myForm.value.RefernceRelation2 ==null){
    this.RefernceRelation2id=0;
    this.RefernceRelation2="null";
  }else{
    this.RefernceRelation2id=1;
    this.RefernceRelation2=this.myForm.value.RefernceRelation2;
  }
  if(this.myForm.value.email == '' || this.myForm.value.email ==null){
    this.emailid=0;
    this.email="null";
  }else{
    this.emailid=1;
    this.email=this.myForm.value.email;
  }
  if(this.myForm.value.gender == '' || this.myForm.value.gender ==null){
    this.genderid=0;
    this.gender="null";
  }else{
    this.genderid=1;
    this.gender=this.myForm.value.gender;
  }
  if(this.myForm.value.location == '' || this.myForm.value.location ==null){
    this.locationid=0;
    this.location="null";
  }else{
    this.locationid=1;
    this.location=this.myForm.value.location;
  }
  
  if (this.myForm.valid) {
    // Form is valid, perform the form submission
    console.log(this.myForm.value);
  } else {
    // Form is invalid, display error messages
    console.log('Form is invalid');
  }
 

 

}
insertUserRegister(){
  if(this.existingemiValue == "1"){
    this.existingEmi="1";
  }else{
    this.existingEmi="0";
  }

  
  this.loder=1;
  let details = {
     
    results: [{
      "fname":this.fname, 
      "mname":this.mname, 
      "lname":this.lname, 
      "mobileno":this.mobileno, 
      "pincode":this.pincode, 
      "dob":this.dob, 
      "loanAmt":this.loanAmt, 
      "loantenure":this.loantenure, 
      "residenceStatus":this.residenceStatus, 
      "residingHere":this.residingHere, 
      "emptype":this.emptype, 
      "businessCategory":this.businessCategory, 
      "industryType":this.industryType ,
      "businessName":this.businessName, 
      "yearsOfBusiness":this.yearsOfBusiness, 
      "natureOfBusiness":this.natureOfBusiness, 
      "proofOfBusiness":this.proofOfBusiness, 
      "establishmentStatus":this.establishmentStatus, 
      "previousFinancialYear":this.previousFinancialYear, 
      "currentFinancialYear":this.currentFinancialYear, 
      "creditProfile":this.creditProfile, 
      "educationQualification":this.educationQualification, 
      "maritalStatus":this.maritalStatus, 
      "produnctid":this.produnctid, 
      "penno":this.penno,
      "fatherFname":this.fatherFname, 
      "fatherLname":this.fatherLname, 
      "motherFname":this.motherFname, 
    
  
      "currentAddress1":this.currentAddress1, 
      "currentAddress2":this.currentAddress2, 
      "currentAddressLandmark":this.currentAddressLandmark, 
      "currentAddressPin":this.currentAddressPin, 
      
     "PermanentAddress1":this.PermanentAddress1, 
      "PermanentAddress2":this.PermanentAddress2, 
      "PermanentAddressLandmark":this.PermanentAddressLandmark, 
      "PermanentAddressPin":this.PermanentAddressPin, 
  
  
      "OfficeAddress1":this.OfficeAddress1, 
      "OfficeAddress2":this.OfficeAddress2, 
      "OfficeLandmark":this.OfficeLandmark, 
      "officeAddressPin":this.officeAddressPin, 
      
  
      "RefernceFName1":this.RefernceFName1, 
      "RefernceLName1":this.RefernceLName1, 
      "RefernceNameContact1":this.RefernceNameContact1, 
      "RefernceRelation1":this.RefernceRelation1, 
      
      
      "RefernceFName2":this.RefernceFName2, 
      "RefernceLName2":this.RefernceLName2, 
      "RefernceNameContact2":this.RefernceNameContact2, 
      "RefernceRelation2":this.RefernceRelation2, 
      "email":this.email,
      "gender":this.gender,
      "productcode":this.productcode,
      "existingEmi": this.existingEmi,
      "adminId":  this.adminuser_id,
      "location":this.location

    }]

  
  };
 
    
alert("working On Developer");

     
console.log(details);  return false;
  
  this.WhatsappService.WhatsappIAPUserDataInsertTest(details).subscribe(    
    (result:any) => {
     // this.loder=1;
      this.frame1.hide();
      setTimeout(()=>{ 
        this.loder=0;
        if(result.response == 200 && result.status == true){
          this.router.navigate(['/meraEMI-self-emp-leads']);
        }else{
          this.router.navigate(['/meraEMI-self-emp-leads']);
        }
        // console.log(result.response);
        // response: 400, message: 'Existing user record', status: false
    },5000); 
    });
}

copyCurrentToPermanent() {
  this.ischeck=1;
  //console.log(this.currentAddress1);
  this.myForm.value.PermanentAddress1 =  this.currentAddress1;
  this.myForm.value.PermanentAddress2= this.currentAddress2;
  this.myForm.value.PermanentAddressLandmark = this.currentAddressLandmark;
  this.myForm.value.PermanentAddressPin = this.currentAddressPin;

  this.PermanentAddress1 = this.currentAddress1;
  this.PermanentAddress2 = this.currentAddress2;
  this.PermanentAddressLandmark = this.currentAddressLandmark;
  this.PermanentAddressPin = this.currentAddressPin;
  
  
}
existingemi(value){

 this.existingemiValue="1"
 
  //console.log(this.existingEmi);

}
valueChange(value) {
 // console.log(value);
  const panCardPattern = /^([A-Z]){3}([P|H|F|C|A|T|B|L|J|G]){1}([A-Z]){1}\d{4}([A-Z]){1}?$/;

  const pancardValid = panCardPattern.test(value);
 // console.log(value);
  //EJAPS0276M
  if(pancardValid === true){
    console.log("if"+pancardValid);
    this.pennoid=1;
  //console.log(this.remainingText);
  }else{
    console.log("else"+pancardValid);
    this.pennoid=0;
  }
  //CGZPP0864J
  
 }
 convertToUpperCase() {
  this.uppercaseValue = this.inputValue.toUpperCase();
}

valueChangepin(currentAddressPin) {
  // console.log(value);
  const pincodeRegex = /^\d{6}$/;
  const isValid = pincodeRegex.test(currentAddressPin);
  console.log(isValid);
   //EJAPS0276M
  
   //CGZPP0864J
   
  }















  
 
}