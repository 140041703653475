import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';

import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';

export interface Color {
  name: string;
}
@Component({
  selector: 'app-meraemi-hlbt-leads',
  templateUrl: './meraemi-hlbt-leads.component.html',
  styleUrls: ['./meraemi-hlbt-leads.component.scss']
})
export class MeraemiHlbtLeadsComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  
  myControl = new FormControl(); 
  elements: any = [];
  
  headElements = [
    {
       name:"Lead Id",
       sortBy:"Lead_id"
     },          
     {
      name:"name",
      sortBy:"name"
    },
     {
       name:"Profile",
       sortBy:"Profile"
     },
     
     {
       name:"Existing Loan",
       sortBy:"Existing_Loan"
     },
     
     {
      name:"Loan Amount",
      sortBy:"Amount"
     },  
      
     {
      name:"Date ",
      sortBy:"Date "
    }, 
     {
       name:"View",
       sortBy:"View"
     }
     
    
     
   ];
 
  first_name: string;
  last_name: string;
  previous: any;
  searchText: any;
  useid: any;
  showLoader: boolean;
  stage_color: string;
  username: string;
  
  
 
  constructor( 
      private cryptojsService:CryptojsService,
      //private firebasedataService:FirebasedataService,      
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,) { }

  ngOnInit() {

 
    
    this.leadsService
    .GetMortgageUser()
    .subscribe( (result:any) => {
  //console.log(result);
      if (result.status === true) {            
        for (let i = 0; i < result.mortgage.length;  i++) {
          const element = result.mortgage[i];
          
       //  console.log(element);
           
         this.stage_color = "info-color";
   
        this.username=(element.data[0].first_name+' '+element.data[0].last_name);
                            this.elements.push(
                              {
                                lead_id: element.data[0].user_id,   
                                name:  this.username,                                                               
                                profile: element.data[0].employment_type,
                                product_name: element.data[0].product_name,
                                existing_loan: element.data[0].topup_existing_loan,
                                loan_amount: element.data[0].os_loan_amount,                            
                                created_on: element.data[0].created_on,
                               
                                feedbackstage:"IAP_Complete"
                              }
                              );
                             

            }
            //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('HLBT Leads list Api faile');
            }
            
          });
         
   
    
    
  }
  
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
 


   
    
   
   
   
    
    
     
     //user Details
       getLeadDetails(user,data){
         let stage=  data;
        console.log(user);
        this.useid = this.cryptojsService.encryptData((user.lead_id));       
        const base_url=location.origin+"/#/Meraemi-HLBT-Leads-Details?data=";        
        const detailsurl = base_url + this.useid.toString();
        const dateur=detailsurl+"&stage="+stage.toString();
       
        console.log(detailsurl);
        window.open(dateur, "_blank");
    }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
}