import { Component, OnInit} from '@angular/core';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, Observable } from 'rxjs';

import { AngularFireAuth } from 'angularfire2/auth';


import * as XLSX from  'xlsx';
@Component({
    selector: 'app-meraemi-lender-documents',
    templateUrl: './meraemi-lender-documents.component.html',
    styleUrls: ['./meraemi-lender-documents.component.scss']
})
export class MeraemiLenderDocumentsComponent implements OnInit {
  public radioModel: string = 'Left';
  data: any;
  decryptData: any;
  custsD={}
  showLoader: boolean;
  userdetils: {};
  sub: Subscription;
  user:any;
  imageur: string;
  file: any;
  arrayBuffer: any ;
  filelist: any[];
  title: any;
  src: string;
  css: string;
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
  
    private router:Router,
   
    public afAuth: AngularFireAuth,
   

   
    
  ) {  };


 
  ngOnInit() { 
   
        
    }
    
  
    
  
    backmenu(){
      this.showLoader = false;
      setTimeout(()=>{    
        //this.showLoader = true;
        this.router.navigate(['/leads']);
      }, 2000);
     
      
      }
     
     
      getapencard(el){
       // console.log(el);
        this.title=el;
        this.imageur="../../../assets/documents/pencard.png";
        this.css="border_clas";
      }
      getadharcard(el){
        this.title=el;
        this.imageur="../../../assets/documents/adharcard.png";
        this.css="border_clas";
      }
      
 
  
  



  
}
