import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-stage-transfer',
    templateUrl: './meraemi-stage-transfer.component.html',
    styleUrls: ['./meraemi-stage-transfer.component.scss']
})
export class MeraemiStageTransferComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id",
       width: 150
     },     
     {
       name:"Ad id",
       sortBy:"ad_id"
     },     
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
       name:"Amount",
       sortBy:"Amount"
     },
     {
      name:"Income",
      sortBy:"user_monthly_income"
    },
     
     {
       name:"Last  Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
      {
      name:"CRM Status",
      sortBy:"MeraEmi"
     },
     {
      name:"Lender",
      sortBy:"Lender Name"
    },
    {
      name:"User Status",
      sortBy:"first_name"
    },
     {
       name:"View",
       sortBy:"send_message"
     },

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id: any;
  to_user_id: any;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result:any = [];
  resultpush: any = [];
  responseArray:any = [];

  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  iapstage: number=0;
  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;
  usersatge: string;
  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  lenderarray: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;
  usernamelist: number = 0;
  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
   sub: any;
  aapstage: any;
  TransfBOTCount: number=0;
  providerbot: string;
  providerWeb: string;
  lendernamefirst: any;
  lendernameUser_id: any;
  lendernamefirst1: any;
  
  lendernameUser_id1: any;
  DocReceived: string;
  constructor( 
      private cryptojsService:CryptojsService,
      private FeedbackService:FeedbackService,    
      private lenderContactService:LenderContactService,
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,
      private route:ActivatedRoute,
      private DashboardService:DashboardService,
      ) { }

  ngOnInit() {
  
        this.sub = this.route
        .queryParams
        .subscribe(params => {
         // console.log(params);
        
          this.fromdate =  params.fromdate;
          this.todate =  params.todate;
          this.source =  params.source;
          this.aapstage =  params.aapstage;
            
           
    //console.log(this.userstage);
    //console.log(this.feedback_Stage);
       
            
          },(err)=>{
            console.log(err);
          });






   
    
          this.leadsService
          .getAllLeads()
          .subscribe( (result:any) => {
            const  source = this.source;
            const  aapstage = this.aapstage;
            if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.leads.length;  i++) {
                const element = result.leads[i];
              
              // console.log(element.data[0].lender_name);
              //  console.log(element.data[0].elig_status);
               //console.log(element);
                        this.stage_color = "info-color";
                  
                        if(element.data[0].app_stage  === "0"){
                          this.elig_status ='Eligibility Pending';
                          this.stage_color = "info-color";
                          this.urlstage ='Eligibility_Pending';
                          this.iapstage =4;
                          this.usersatge="Active";
                          this.appstage = this.elig_status;
                          this.usernamelist=4;
                        
                        }else if(element.data[0].app_stage  === "1"){
                           if(element.data[0].elig_status =="1"){
                            this.elig_status ='Eligibility Clear';
                            this.urlstage ='Eligibility_Clear';
                            this.stage_color = "info-color";
                            this.iapstage =4;
                            this.usernamelist=4;
                            this.usersatge="Active";
                           }else if(element.data[0].elig_status =="0"){
                            this.elig_status ='Eligibility Fail';
                            this.urlstage ='Eligibility_fail';
                            this.stage_color = "info-color";
                            this.iapstage =4;
                            this.usernamelist=4;
                            this.usersatge="Inactive";
                           }
                           this.appstage = this.elig_status;
                         
                         }else if(element.data[0].app_stage==="2"){
                      
                          this.appstage ="IAP Pending";
                          this.urlstage ='IAP_Pending';
                          this.stage_color = "info-color";
                          this.iapstage=5;
                          this.usernamelist=4;
                          this.usersatge="Active";
                    
                      }else if(element.data[0].app_stage==="3"){
                        this.appstage ="IAP Complete";
                        this.urlstage ='IAP_Complete';
                        this.iapstage=5;
                        this.usernamelist=5;
                         
                      }else if(element.data[0].app_stage === "4"){
                        this.appstage = 'Document Upload';
                        this.urlstage ="Document_Upload";
                        this.stage_color = "info-color";
                        this.iapstage=5;
                        this.usernamelist=5;
                      }else if(element.data[0].app_stage ==="5"){
                        this.appstage = 'Meeting Scheduler';
                        this.urlstage ="Meeting_Scheduler";
                        this.stage_color = "info-color";
                        this.iapstage=5;
                        this.usernamelist=5;
                      }else if(element.data[0].app_stage === "6"){
                        this.appstage = 'Success';
                        this.urlstage ='Success';
                        this.stage_color = "info-color";
                        this.iapstage=5;
                        this.usernamelist=5;
                      }
                    if(element.data[0].lender_name !="NA"){
                      if(element.data[0].lender_name=== "MeraEMI"){
                        this.lender_name = "Offline";
                      }else if(element.data[0].lender_name === "Lender Not Selected"){
                        this.lender_name = "NA";
                       }else{
                        this.lender_name = element.data[0].lender_name;
                      }
                    
                      
                     }
                     if(this.usernamelist ===5){
                       if(element.data[0].iap_fname !="NA" && element.data[0].iap_lname !="NA"){
                         this.first_name=element.data[0].iap_fname;
                        this.last_name=element.data[0].iap_lname;
                        // this.first_name="rajeev";
                         //this.last_name="pathak"
                       }
                     }
                     
                     if(this.usernamelist ===4){
                      if(element.data[0].first_name !="NA" && element.data[0].last_name !="NA"){
                        this.first_name=element.data[0].first_name;
                        this.last_name=element.data[0].last_name;
                      }
                     
                     }
                     if(this.iapstage ===5){
                       if(element.data[0].feedback_stage !=undefined){
                        this.meraemistage=element.data[0].feedback_stage;
                       }else{
                        this.meraemistage="Pending Review";
                       }
                      if(element.data[0].user_stage !=undefined){
                            this.usersatge=element.data[0].user_stage;
                          }else{
                            this.usersatge="Active";
                          }
                      
                      //.log(element.data[0].feedback_stage);
                      //console.log(element.data[0].user_stage);
                     }else{
                      if(element.data[0].feedback_stage !=undefined){
                        this.meraemistage=element.data[0].feedback_stage;
                       }else{
                        this.meraemistage="NA";
                       }
                      
                      if(element.data[0].user_stage !=undefined){
                        this.usersatge=element.data[0].user_stage;
                       }
                     }
      
      
                    if(element.data[0].provider_id === "BOT MESSENGER"){
                      this.provider = 'BOT';
                      
                    }else{
                      this.provider = 'WEB';
                    }
                    if(element.data[0].ad_id ==="NULL" || element.data[0].ad_id ==="null"){
                      this.adupdatebtnEnable=1;
                    }else{
                      this.adupdatebtnEnable=0;
                    }
                    
                   //  (CRM_Cancel , CRM Decline, Cust Cancel)
                    this.fnamelname= this.first_name+' '+this.last_name
                   // console.log(element.data[0].user_stage);
                  //   console.log(this.lender_name);
                        if(source === this.provider && aapstage === this.urlstage){
                                  this.result.push(
                                    {
                                      user_id: element.data[0].user_id, 
                                      name:  this.fnamelname,                                                 
                                      provider: this.provider,
                                      city_name: element.data[0].city_name,
                                      loan_amount: element.data[0].loan_amount,
                                      ad_id: element.data[0].ad_id,
                                      reg_date: element.data[0].date,
                                      status: this.appstage,
                                      feedbackstage:this.urlstage,
                                      lender_name:this.lender_name,
                                      feedback_stage:this.meraemistage,
                                      usersatge:this.usersatge,
                                      adupdatebtnEnable:this.adupdatebtnEnable,
                                      user_monthly_income:element.data[0].user_monthly_income,
                                      
                                    }
                                    );
                        }else  if(source === "WebOrBot" && aapstage === "totallog"){

                                  this.result.push(
                                    {
                                      user_id: element.data[0].user_id, 
                                      name:  this.fnamelname,                                                 
                                      provider: this.provider,
                                      city_name: element.data[0].city_name,
                                      loan_amount: element.data[0].loan_amount,
                                      ad_id: element.data[0].ad_id,
                                      reg_date: element.data[0].date,
                                      status: this.appstage,
                                      feedbackstage:this.urlstage,
                                      lender_name:this.lender_name,
                                      feedback_stage:this.meraemistage,
                                      usersatge:this.usersatge,
                                      adupdatebtnEnable:this.adupdatebtnEnable,
                                      user_monthly_income:element.data[0].user_monthly_income,
                                      
                                    }
                                    );
                        }else  if(source === "WebOrBot" && aapstage === this.urlstage){

                          this.result.push(
                            {
                              user_id: element.data[0].user_id, 
                              name:  this.fnamelname,                                                 
                              provider: this.provider,
                              city_name: element.data[0].city_name,
                              loan_amount: element.data[0].loan_amount,
                              ad_id: element.data[0].ad_id,
                              reg_date: element.data[0].date,
                              status: this.appstage,
                              feedbackstage:this.urlstage,
                              lender_name:this.lender_name,
                              feedback_stage:this.meraemistage,
                              usersatge:this.usersatge,
                              adupdatebtnEnable:this.adupdatebtnEnable,
                              user_monthly_income:element.data[0].user_monthly_income,
                              
                            }
                            );
                        }else  if(source === this.provider && aapstage === "totallog"){

                          this.result.push(
                            {
                              user_id: element.data[0].user_id, 
                              name:  this.fnamelname,                                                 
                              provider: this.provider,
                              city_name: element.data[0].city_name,
                              loan_amount: element.data[0].loan_amount,
                              ad_id: element.data[0].ad_id,
                              reg_date: element.data[0].date,
                              status: this.appstage,
                              feedbackstage:this.urlstage,
                              lender_name:this.lender_name,
                              feedback_stage:this.meraemistage,
                              usersatge:this.usersatge,
                              adupdatebtnEnable:this.adupdatebtnEnable,
                              user_monthly_income:element.data[0].user_monthly_income,
                              
                            }
                            );
                        }
                                  
                                   
      
                  }
                  
                  const  fromdate = this.fromdate;
                  const  todate = this.todate;
                  
               
                  const data=this.result;                 
                  this.elements = data.filter(d => {
                    // console.log(d);
                      const  reg_date= d.reg_date;
                    
                      const regdate = reg_date.split(' ')[0];
     
                      return (new Date(regdate)   >= new Date(fromdate)  && new Date(regdate)  <=  new Date(todate) );            
                                                      
                    });







               // console.log(this.result);


                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
         
   
         // console.log(this.provider);

         
         
    
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  
  // Employer  Login Method
  
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
   
       getLeadDetails(user,data){
         let stage=  data;
         if(user.usersatge !=undefined){
          let userstage=user.usersatge;
        //  console.log(user.usersatge);
           this.useid = this.cryptojsService.encryptData((user.user_id));       
           const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
           const detailsurl = base_url + this.useid.toString();
   
           const dateur=detailsurl+"&stage="+stage.toString()+"&userstage="+userstage.toString();
          
           //console.log(detailsurl);
           window.open(dateur, "_blank");
         }else{
         // console.log(user.usersatge);
          this.useid = this.cryptojsService.encryptData((user.user_id));       
          const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
          const detailsurl = base_url + this.useid.toString();
  
          const dateur=detailsurl+"&stage="+stage.toString();
         
          //console.log(detailsurl);
          window.open(dateur, "_blank");
         }
   
    }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraEMI-dashboard']);
    }, 2000);
   
    
    }
    openModaloneidid(data,data1) {
      this.adid=data1;
      this.updateuserad=data;
      this.Modaloneidid =data;
   
      
    }
    
}