import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-detils',
  templateUrl: './lead-detils.component.html',
  styleUrls: ['./lead-detils.component.scss']
})
export class LeadDetilsComponent implements OnInit {
  display: string;
  activeClass: string;
  valuemain: number;
  userid: number;
  tabdiv: number;
  isValue: number = 0;
  idfc: number;
  fullerton: number;
  document: number;
  stage: number;
  constructor() { }

  ngOnInit() {
    this.isValue = 1;
    this.idfc=1;
  }
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
   // this.activeClass ="activbutton";
    this.valuemain=1;
    this.userid =1;
    this.isValue = 1;
  }
  

toggle1() { 
  this.isValue = 1;
   this.idfc=1;
   this.fullerton=0;
   this.document=0;
   this.stage=0;
   
}
toggle2() { 
  this.isValue = 2;
  this.idfc= 0;
  this.fullerton=1;
  this.document=0;
  this.stage=0;
}
toggle3() { 
  this.isValue = 3; 
  this.idfc= 0;
  this.fullerton=0;
  this.document=1;
  this.stage=0;
}
toggle4() { 
  this.isValue = 4;
  this.idfc= 0;
  this.fullerton=0;
  this.document=0;
  this.stage=1;

}
}
