import { Component, OnInit, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription, Observable } from 'rxjs';
import { LoansService } from 'src/app/services/loans/loans.service';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';
import {ExcelService} from 'src/app/services/excel/excel.service';
import * as XLSX from 'xlsx'; 
import { MisSendLenderService } from 'src/app/services/mis-send-lender/mis-send-lender.service';
@Component({
    selector: 'app-lender-prod-offer-list',
    templateUrl: './lender-prod-offer-list.component.html',
    styleUrls: ['./lender-prod-offer-list.component.scss']
})
export class LenderProdOfferListComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;

  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  editvalidatingForm: FormGroup;
  lenderproductoffer: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  
  elements: any = []; 
  lenderName: any = []; 
  locationName: any = []; 
  location: any = []; 
  lendeEmail: any = []; 
  lende_email: any = [];
  divaction: number=0;
  sub: any;
  user: User;
  rolecode: any;
  loginAdmin: any;
  data: any;
  feedback_Stage: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  delete = {};
  cust: any;
  userdetils: {};
  edit = {};
  previous: any;
  element:any = [];

 
  currentdate: Date;
  headElements = [
    {
       name:"Interest Rate Id",
       sortBy:"id"
     },
     {
       name:"Lender Name",
       sortBy:"lender_name"
     },
     
     {
       name:"Product Name",
       sortBy:"product_name"
     },
     {
       name:"Interest Rate",
       sortBy:"rate"
     },
     {
       name:"Tenure",
       sortBy:"tenure"
     },
     
     {
       name:"Interest Rate Date",
       sortBy:"reg_date"
     },  
     {
      name:"Action",
      sortBy:"action"
    },  
     
        ];
  display: string;
  id: string;
  product_name: string;
  rate_of_interest_from: string;
  tenure: string;
  interest_rate_as_on: string;
  lenderid: string;
  registerForm: FormGroup;
  lenderdate: string;
  lender_id: string;
  product_id: string;
  rate_from: string;
  tenureedit: string;
  editlenderid: any;
  editproductid: any;
//  currentdate: string;
  constructor(fb: FormBuilder,private lenderContactService:LenderContactService,private leadsService:LeadsService,
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
   
    public afAuth: AngularFireAuth,
    private excelService:ExcelService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    public ete: MisSendLenderService
    
    ) {

   
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      
 
         // validation 

     
      this.lenderproductoffer= new FormGroup({
        lenderLocation: new FormControl(null, Validators.required),
        lenderName: new FormControl(null, Validators.required),
        lenderEmail: new FormControl(null, Validators.required),
        rate_of_interest_from: new FormControl(null, Validators.required),
        tenure: new FormControl(null, Validators.required),
      });
      this.editvalidatingForm= new FormGroup({
        product_name: new FormControl(null, Validators.required),
        lname: new FormControl(null, Validators.required),
        lender_date: new FormControl(null, Validators.required),
        rate_from: new FormControl(null, Validators.required),
        tenureedit: new FormControl(null, Validators.required),
    
      
     
    });
    //  Lender name
      this.lenderContactService
     .getLenderName()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.lender_name.length; i++) {
                 const element = result.lender_name[i];               
                 this.elements.push(
                  {
                    value: element.data[0].lender_id,
                    label: element.data[0].lender_name, 

                                 
                  });
        }
        this.lenderName =this.elements;
   
      }
       
     });
      //  Product name
      this.lenderContactService
     .getProductName()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.product_name.length; i++) {
                 const element = result.product_name[i];
               //  console.log(element);
                 this.location.push(
                  {
                    value: element.data[0].product_id,
                    label: element.data[0].product_name, 
                     
                                 
                  });
        }
        this.locationName =this.location;
      // 
      }
       
     });
  
     this.lenderContactService
     .getLenderProdOffer()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) { 
        for (let i = 0; i < result.lender_offerList.length; i++) {
          const element = result.lender_offerList[i];
          this.element.push(
            {
              id: element.data[0].len_prod_offer_id, 
              interest_rate_as_on: element.data[0].interest_rate_as_on,
              lender_name: element.data[0].lender_name,
              product_name: element.data[0].product_name,
              rate_of_interest_from: element.data[0].rate_of_interest_from,   
              tenure: element.data[0].tenure,  
              lender_id:element.data[0].lender_id,
              product_id:element.data[0].product_id       
            });
       // console.log(this.element);
        }
        this.mdbTable.setDataSource(this.element);
        this.element = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      }else{
        console.log("API Error");
      }
     });
 
    }
      get lenderN() {return this.lenderproductoffer.get('lenderName'); } 
      get lenderL() {return this.lenderproductoffer.get('lenderLocation'); }     
      get lenderE() {return this.lenderproductoffer.get('lenderEmail');  } 
      get lenderg() {return this.lenderproductoffer.get('rate_of_interest_from');  } 
      get lendert() {return this.lenderproductoffer.get('tenure');  } 
      //  edit
      get editN() {return this.editvalidatingForm.get('lname'); } 
      get editL() {return this.editvalidatingForm.get('product_name'); }     
      get editE() {return this.editvalidatingForm.get('lender_date');  } 
      get editg() {return this.editvalidatingForm.get('rate_from');  } 
      get editt() {return this.editvalidatingForm.get('tenureedit');  } 
      onSubmit() {
        let date_ob = new Date();
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // prints date in YYYY-MM-DD format    
        
      
       // this.currentdate =(year + "-" + month + "-" + date);
       this.currentdate =date_ob;
          this.custsD["results"] = [{
            "lender_name":this.lenderproductoffer.value.lenderName,
            "product_name":this.lenderproductoffer.value.lenderLocation,            
            "date":this.lenderproductoffer.value.lenderEmail, 
            "rate_of_interest_from" :this.lenderproductoffer.value.rate_of_interest_from, 
            "tenure" :this.lenderproductoffer.value.tenure, 
            "status":'1',
            "created_on":this.currentdate,
            "created_by":'1'
          }];
        
     // console.log(this.custsD);
       if(this.custsD){
      
         this.divaction=1;
      
        this.lenderContactService.PostRateofIntrest(this.custsD).subscribe( 
            
          (leadres:any) => {
            if(leadres.response=== 200 && leadres.status=== true){
              
              this.element.length=0;
           //   console.log(leadres);
              this.lenderContactService
              .getLenderProdOffer()
              .subscribe( (result:any) => {
               if (result.status === true && result.response===200) { 
                 for (let i = 0; i < result.lender_offerList.length; i++) {
                   const element = result.lender_offerList[i];
                   this.element.push(
                     {
                       id: element.data[0].len_prod_offer_id, 
                       interest_rate_as_on: element.data[0].interest_rate_as_on,
                       lender_name: element.data[0].lender_name,
                       product_name: element.data[0].product_name,
                       rate_of_interest_from: element.data[0].rate_of_interest_from,   
                       tenure: element.data[0].tenure,  
                       lender_id:element.data[0].lender_id,
                       product_id:element.data[0].product_id       
                     });
                // console.log(this.element);
                 }
                 this.mdbTable.setDataSource(this.element);
                 this.element = this.mdbTable.getDataSource();
                 this.previous = this.mdbTable.getDataSource();
               }else{
                 console.log("API Error");
               }
              });
             
            }else{
              console.log("Add lender product offer Api Error");
            }
            
          
              
        
          })
       }else{
        console.log("form value invalide!");
       }
        
        
      
      }
      onSubmitedit(){
        let date_ob = new Date();
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // prints date in YYYY-MM-DD format    
        
      
       // this.currentdate =(year + "-" + month + "-" + date);
       this.currentdate =date_ob;
        if(this.editvalidatingForm.value.lender_date !=null && this.editvalidatingForm.value.lender_date !="" ){
            this.lenderdate =this.editvalidatingForm.value.lender_date;
        }else{
          this.lenderdate =this.interest_rate_as_on;
        }
        if(this.editvalidatingForm.value.lname !=null && this.editvalidatingForm.value.lname !="" ){
              this.lender_id =this.editvalidatingForm.value.lname;
          }else{
            this.lender_id =this.editlenderid;
          }
          if(this.editvalidatingForm.value.product_name !=null && this.editvalidatingForm.value.product_name !="" ){
            this.product_id =this.editvalidatingForm.value.product_name;
            }else{
              this.product_id =this.editproductid;
            }
            if(this.editvalidatingForm.value.rate_from !=null && this.editvalidatingForm.value.rate_from !="" ){
              this.rate_from =this.editvalidatingForm.value.rate_from;
              }else{
                this.rate_from =this.rate_of_interest_from;
              }
              if(this.editvalidatingForm.value.tenureedit !=null && this.editvalidatingForm.value.tenureedit !="" ){
                this.tenureedit =this.editvalidatingForm.value.tenureedit;
                }else{
                  this.tenureedit =this.tenure;
                }

              //  console.log(this.lenderdate);
        this.edit["results"] = [{
          "lender_date": this.lenderdate,
          "lender_id": this.lender_id,
          "product_id": this.product_id,
          "rate_from": this.rate_from,
          "tenureedit": this.tenureedit,
          "id": this.id,
          "modified_by":"1",
          "modified_on":date_ob,
        }];
        if(this.edit){
          this.lenderContactService.EditRateofIntrest(this.edit).subscribe( 
            
            (leadres:any) => {
              if(leadres.response=== 200 && leadres.status=== true){
              
                this.element.length=0;
                this.display = "none";
               // console.log(leadres);
                this.lenderContactService
                .getLenderProdOffer()
                .subscribe( (result:any) => {
                 if (result.status === true && result.response===200) { 
                   for (let i = 0; i < result.lender_offerList.length; i++) {
                     const element = result.lender_offerList[i];
                     this.element.push(
                       {
                         id: element.data[0].len_prod_offer_id, 
                         interest_rate_as_on: element.data[0].interest_rate_as_on,
                         lender_name: element.data[0].lender_name,
                         product_name: element.data[0].product_name,
                         rate_of_interest_from: element.data[0].rate_of_interest_from,   
                         tenure: element.data[0].tenure,  
                         lender_id:element.data[0].lender_id,
                         product_id:element.data[0].product_id       
                       });
                  // console.log(this.element);
                   }
                   this.mdbTable.setDataSource(this.element);
                   this.element = this.mdbTable.getDataSource();
                   this.previous = this.mdbTable.getDataSource();
                 }else{
                   console.log("API Error");
                 }
                });
               
              }else{
                console.log("Edit lender product offer Api  Error");
              }
             
                
          
            })
        }
     //  console.log(this.edit);
  
      }
      lenderproductofferDelete(data){
        this.delete["results"] = [{
          "id":data,
  
        }];
        if(this.edit){
          this.lenderContactService.DeleteRateofIntrest(this.delete).subscribe( 
            
            (leadres:any) => {
              if(leadres.response=== 200 && leadres.status=== true){
              
                this.element.length=0;
                //console.log(leadres);
                this.lenderContactService
                .getLenderProdOffer()
                .subscribe( (result:any) => {
                 if (result.status === true && result.response===200) { 
                   for (let i = 0; i < result.lender_offerList.length; i++) {
                     const element = result.lender_offerList[i];
                     this.element.push(
                       {
                         id: element.data[0].len_prod_offer_id, 
                         interest_rate_as_on: element.data[0].interest_rate_as_on,
                         lender_name: element.data[0].lender_name,
                         product_name: element.data[0].product_name,
                         rate_of_interest_from: element.data[0].rate_of_interest_from,   
                         tenure: element.data[0].tenure,  
                         lender_id:element.data[0].lender_id,
                         product_id:element.data[0].product_id       
                       });
                  // console.log(this.element);
                   }
                   this.mdbTable.setDataSource(this.element);
                   this.element = this.mdbTable.getDataSource();
                   this.previous = this.mdbTable.getDataSource();
                 }else{
                   console.log("API Error");
                 }
                });
               
              }else{
                console.log("Delete  lender product offer Api  Error");
              }
             
                
          
            })
        }
      // console.log(this.delete);
      }
      calculateEMI(interest:number,tenure:number,principal:number){
        var emi = 0;
        var a = (1 + interest / 1200);
        var b = Math.pow(a, tenure);
        var c = Math.pow(a, tenure) - 1;
        emi = Math.round(principal * (interest / 1200) * b / c);
        //console.log(emi)
        return emi;
      }
      calculateFOIR(existemi:number,proposedemi:number,income:number){
        var foir= 0;
       // console.log(existemi)
       // console.log(proposedemi)
      //  console.log(income)
        foir = ((existemi + proposedemi) / income * 100);
       // console.log(foir)
        return foir;
      }
      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }
      
      openModalone(data1,data2,data3,data4,data5,data6,data7,data8) {
        this.id =data1;
        this.lenderid=data2;
        this.product_name=data3;
        this.rate_of_interest_from =data4;
        this.tenure =data5;
        this.interest_rate_as_on =data6;
        this.editlenderid =data7;
        this.editproductid =data8;
       
      //  console.log(data1);
        this.display = "block";
      } 
      onCloseHandledone() {
    
        this.display = "none";
      }
}

function die() {
  throw new Error('Function not implemented.');
}

