import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-mera-emi-all-users-salaried',
  templateUrl: './mera-emi-all-users-salaried.component.html',
  styleUrls: ['./mera-emi-all-users-salaried.component.scss']
})
export class MeraEMIAllUsersSalariedComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  searchText: any;
  appstage: any;
  elig_status: string;
  stage_color: string;
  urlstage: string;
  usersatge: string;
  usernamelist: number;
  iapstage: number;
  lender_name: string;
  first_name: any;
  last_name: any;
  meraemistage: any;
  provider: string;
  adupdatebtnEnable: number;
  emptype: string;
  campain_name: any;
  fnamelname: string;
  elements: any = [];
  setdynamicpagelength: any = [];
  elementsfilter_main: any = [];
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id",
       width: 150
     },
     {
      name:"Profile",
      sortBy:"Profile",
      width: 150
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
       name:"Amount",
       sortBy:"Amount"
     },
     {
      name:"Income",
      sortBy:"user_monthly_income"
    },
     
     {
       name:"Last  Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
      {
      name:"CRM Status",
      sortBy:"MeraEmi"
     },
     
    {
      name:"Campaign",
      sortBy:"Campaign"
    },
     {
      name:"Lender",
      sortBy:"Lender Name"
    },
    {
      name:"User Status",
      sortBy:"first_name"
    },
     {
       name:"View",
       sortBy:"send_message"
     },

   ];
   
   count: number;
   pageSize: number;
   pageIndex: number;
   pageDifference : number;
   pageEndRange : number;
   pageStartRange = 1;
  pageid: number=0;
  listofpagedata: number=0;
  pagelength: any = [];
  paginatinbtnshow: number;
  onclickpageshow: number=0;
  roundvaluepage: number;
  constructor(    
   
      private cryptojsService:CryptojsService,
      private FeedbackService:FeedbackService,    
      private lenderContactService:LenderContactService,
 
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,) { }

  ngOnInit() {

    // var numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    // console.log(numbers);
    // var result = numbers.filter(number => number > 5);
    // console.log(result);
    this.paginatinbtnshow=5;
    this.pageid=1;
    this.listofpagedata=40;
   // let a = 10;
   // let arr = [];
    // for (let i = 0; i < a; i++){
    // arr.push(i+1);
    //   }
    //   console.log(arr);
    this.leadsService
    .getAllLeadsSalaried()
    .subscribe( (result:any) => {
       //console.log(result.leads.length);
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.salaried.length;  i++) {
          const element = result.salaried[i];
        
        // console.log(element.data[0].lender_name);
        //  console.log(element.data[0].elig_status);
         //console.log(element);
                  this.stage_color = "info-color";
            
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                    this.appstage =4;
                    this.usersatge="Active";
                    this.appstage = this.elig_status;
                    this.usernamelist=4;
                  
                  }else if(element.data[0].app_stage  === "1"){
                     if(element.data[0].elig_status =="1"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Active";
                     }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Inactive";
                     }
                     this.appstage = this.elig_status;
                   
                   }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
                    this.iapstage=5;
                    this.usernamelist=4;
                    this.usersatge="Active";
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                  this.iapstage=5;
                  this.usernamelist=5;
                   
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }
              if(element.data[0].lender_name !="NA"){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else if(element.data[0].lender_name === "Lender Not Selected"){
                  this.lender_name = "NA";
                 }else{
                  this.lender_name = element.data[0].lender_name;
                }
              
                
               }
               if(this.usernamelist ===5){
                 if(element.data[0].iap_fname !="NA" && element.data[0].iap_lname !="NA"){
                   this.first_name=element.data[0].iap_fname;
                  this.last_name=element.data[0].iap_lname;
                  // this.first_name="rajeev";
                   //this.last_name="pathak"
                 }
               }
               
               if(this.usernamelist ===4){
                if(element.data[0].first_name !="NA" && element.data[0].last_name !="NA"){
                  this.first_name=element.data[0].first_name;
                  this.last_name=element.data[0].last_name;
                }
               
               }
               if(this.iapstage ===5){
                 if(element.data[0].feedback_stage !=undefined){
                  this.meraemistage=element.data[0].feedback_stage;
                 }else{
                  this.meraemistage="Pending Review";
                 }
                if(element.data[0].user_stage !=undefined){
                      this.usersatge=element.data[0].user_stage;
                    }else{
                      this.usersatge="Active";
                    }
                
                //.log(element.data[0].feedback_stage);
                //console.log(element.data[0].user_stage);
               }else{
                if(element.data[0].feedback_stage !=undefined){
                  this.meraemistage=element.data[0].feedback_stage;
                 }else{
                  this.meraemistage="NA";
                 }
                
                if(element.data[0].user_stage !=undefined){
                  this.usersatge=element.data[0].user_stage;
                 }
               }


              if(element.data[0].provider_id === "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }
              if(element.data[0].ad_id ==="NULL" || element.data[0].ad_id ==="null"){
                this.adupdatebtnEnable=1;
              }else{
                this.adupdatebtnEnable=0;
              }
              if(element.data[0].employment_type === "1"){
                this.emptype = 'SAL';
                
              }else{
                this.emptype = 'NA';
              }
              this.campain_name=element.data[0].campain_name
             //  (CRM_Cancel , CRM Decline, Cust Cancel)
              this.fnamelname= this.first_name+' '+this.last_name
             // console.log(element.data[0].user_stage);
            //   console.log(this.lender_name);
                            this.elements.push(
                              {
                                user_id: element.data[0].user_id, 
                                name:  this.fnamelname, 
                                          
                                provider: this.provider,
                                city_name: element.data[0].city_name,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:this.lender_name,
                                feedback_stage:this.meraemistage,
                                usersatge:this.usersatge,
                                adupdatebtnEnable:this.adupdatebtnEnable,
                                user_monthly_income:element.data[0].user_monthly_income,
                                emptype:this.emptype,
                                campain_name:this.campain_name
                              }
                              );
                              this.element_main.push(
                                {
                                  user_id: element.data[0].user_id, 
                                  name:  this.fnamelname,               
                                  provider: this.provider,
                                  city_name: element.data[0].city_name,
                                  loan_amount: element.data[0].loan_amount,
                                  ad_id: element.data[0].ad_id,
                                  reg_date: element.data[0].date,
                                  status: this.appstage,
                                  feedbackstage:this.urlstage,
                                  lender_name:this.lender_name,
                                   feedback_stage:this.meraemistage,
                                   usersatge:this.usersatge,
                                   adupdatebtnEnable:this.adupdatebtnEnable,
                                   user_monthly_income:element.data[0].user_monthly_income,
                                      emptype:this.emptype,
                                    campain_name:this.campain_name
             
                                }
                                );

            }
          //console.log(this.elements);
            
            }else{
              console.log('User list Api faile');
            }

            const roundvaluepage=Math.round(this.elements.length/this.listofpagedata)+1;
            //this.pagelength=roundvaluepage;
            for (let i = 0; i < roundvaluepage; i++){
              this.pagelength.push(i+1);
                }
               
                  // console.log(this.pagelength);

            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          });
         
    
    
  }
   onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  
  ngAfterViewInit() {
    // this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);
    // console.log(this.mdbTablePagination);
    // this.mdbTablePagination.calculateFirstItemIndex();
    // console.log(this.mdbTablePagination);
    // this.mdbTablePagination.calculateLastItemIndex();
    // console.log(this.mdbTablePagination);
    // this.cdRef.detectChanges();
    // console.log(this.cdRef);
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
     // console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
     // console.log(this.elements);
      this.elements = this.mdbTable.setDataSource(prev);
      this.previous = this.mdbTable.getDataSource();
     // console.log(this.mdbTable);

            // this.mdbTable.setDataSource(this.elements);
             this.elements = this.mdbTable.getDataSource();
             this.previous = this.mdbTable.getDataSource();
    }
  }
  nextpage(i){
       
    const nextpageid=(i*40);
   // console.log("nextpageid",nextpageid);
    this.pageid=1+i;
   // console.log("pageid",this.pageid);
    this.listofpagedata=40+i;
   // console.log("listofpagedata",this.listofpagedata);
    const user_id=(this.elements.slice(-1)[0].user_id);
   // console.log("user_id",user_id);
    let detail = { results: [{ "pageid":nextpageid,"user_id":user_id}] };
    detail['results']; 
    this.leadsService.nextpage(detail).subscribe((result: any) => {
      this.elements.length = 0;
      this.pagelength.length = 0;
      this.roundvaluepage=0;
      this.elementsfilter.length=0;
      this.elements.length=0;
      if(result.status===true  && result.response== 200){
        
        for (let i = 0; i <  result.leadsNextPage.length;  i++) {
          const element = result.leadsNextPage[i];
        
        // console.log(element.data[0].lender_name);
        //  console.log(element.data[0].elig_status);
         //console.log(element);
                  this.stage_color = "info-color";
            
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                    this.appstage =4;
                    this.usersatge="Active";
                    this.appstage = this.elig_status;
                    this.usernamelist=4;
                  
                  }else if(element.data[0].app_stage  === "1"){
                     if(element.data[0].elig_status =="1"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Active";
                     }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Inactive";
                     }
                     this.appstage = this.elig_status;
                   
                   }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
                    this.iapstage=5;
                    this.usernamelist=4;
                    this.usersatge="Active";
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                  this.iapstage=5;
                  this.usernamelist=5;
                   
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }
              if(element.data[0].lender_name !="NA"){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else if(element.data[0].lender_name === "Lender Not Selected"){
                  this.lender_name = "NA";
                 }else{
                  this.lender_name = element.data[0].lender_name;
                }
              
                
               }
               if(this.usernamelist ===5){
                 if(element.data[0].iap_fname !="NA" && element.data[0].iap_lname !="NA"){
                   this.first_name=element.data[0].iap_fname;
                  this.last_name=element.data[0].iap_lname;
                  // this.first_name="rajeev";
                   //this.last_name="pathak"
                 }
               }
               
               if(this.usernamelist ===4){
                if(element.data[0].first_name !="NA" && element.data[0].last_name !="NA"){
                  this.first_name=element.data[0].first_name;
                  this.last_name=element.data[0].last_name;
                }
               
               }
               if(this.iapstage ===5){
                 if(element.data[0].feedback_stage !=undefined){
                  this.meraemistage=element.data[0].feedback_stage;
                 }else{
                  this.meraemistage="Pending Review";
                 }
                if(element.data[0].user_stage !=undefined){
                      this.usersatge=element.data[0].user_stage;
                    }else{
                      this.usersatge="Active";
                    }
                
                //.log(element.data[0].feedback_stage);
                //console.log(element.data[0].user_stage);
               }else{
                if(element.data[0].feedback_stage !=undefined){
                  this.meraemistage=element.data[0].feedback_stage;
                 }else{
                  this.meraemistage="NA";
                 }
                
                if(element.data[0].user_stage !=undefined){
                  this.usersatge=element.data[0].user_stage;
                 }
               }
    
    
              if(element.data[0].provider_id === "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }
              if(element.data[0].ad_id ==="NULL" || element.data[0].ad_id ==="null"){
                this.adupdatebtnEnable=1;
              }else{
                this.adupdatebtnEnable=0;
              }
              if(element.data[0].employment_type === "Salaried"){
                this.emptype = 'SAL';
                
              }else{
                this.emptype = 'NA';
              }
              this.campain_name=element.data[0].campain_name
             //  (CRM_Cancel , CRM Decline, Cust Cancel)
              this.fnamelname= this.first_name+' '+this.last_name
             // console.log(element.data[0].user_stage);
            //   console.log(this.lender_name);
                           this.elementsfilter.push(
                              {
                                user_id: element.data[0].user_id, 
                                name:  this.fnamelname, 
                                          
                                provider: this.provider,
                                city_name: element.data[0].city_name,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:this.lender_name,
                                feedback_stage:this.meraemistage,
                                usersatge:this.usersatge,
                                adupdatebtnEnable:this.adupdatebtnEnable,
                                user_monthly_income:element.data[0].user_monthly_income,
                                emptype:this.emptype,
                                campain_name:this.campain_name
                              }
                              );
                              this.elementsfilter_main.push(
                                {
                                  user_id: element.data[0].user_id, 
                                  name:  this.fnamelname,               
                                  provider: this.provider,
                                  city_name: element.data[0].city_name,
                                  loan_amount: element.data[0].loan_amount,
                                  ad_id: element.data[0].ad_id,
                                  reg_date: element.data[0].date,
                                  status: this.appstage,
                                  feedbackstage:this.urlstage,
                                  lender_name:this.lender_name,
                                   feedback_stage:this.meraemistage,
                                   usersatge:this.usersatge,
                                   adupdatebtnEnable:this.adupdatebtnEnable,
                                   user_monthly_income:element.data[0].user_monthly_income,
                                      emptype:this.emptype,
                                    campain_name:this.campain_name
             
                                }
                                );
    
            }
           
     // console.log(result);
      }else{
        console.log("api not responce!");
      }
     // console.log("length",this.elementsfilter.length);
      //this.roundvaluepage=Math.round(this.elementsfilter.length);
      this.roundvaluepage=Math.round(i)+1;
     // console.log("roundvaluepage",this.roundvaluepage);
      //this.pagelength=roundvaluepage;
      //this.pageid=0;
      // this.listofpagedata=0;
      for (let i = 0; i < this.roundvaluepage; i++){
        this.pagelength.push(i+1);
          }
         // console.log("pagelength",this.pagelength);
         
         //this.elements=this.elementsfilter;
        // console.log("new elements",this.elementsfilter);
         this.mdbTable.setDataSource(this.elementsfilter);
         this.elements = this.mdbTable.getDataSource();
         this.previous = this.mdbTable.getDataSource();
        // console.log("listofpagedata",this.listofpagedata);  
    });

  }
  
}