import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoansService } from 'src/app/services/loans/loans.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {
  LoanDetails:any[]=[];
  data: any=[];
  loanslist: any=[];
  elements: any=[];
  sub: any;
  custsD = {};
  cust: any;
  decryptData: string;
  showLoader: boolean;
  constructor(
    private loansService:LoansService,
    private route:ActivatedRoute,
    private cryptojsService:CryptojsService,
    private router:Router,
    public location:Location,
  ) { }

  
  ngOnInit() {
    this.showLoader = false;
    setTimeout(()=>{    
      this.showLoader = true;
    }, 2000);
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      this.data = params.data;
      if(this.data != undefined){
        this.decryptData = this.cryptojsService.decryptData(this.data);
        console.log(this.decryptData)
        if( this.decryptData != 'false'){
  
          this.custsD["results"] = [{"loan_code":this.decryptData}];
          this.cust = this.custsD["results"];
          this.getDetails(this.custsD);
        }else{
  
          this.router.navigate(['rm/rm-loans']);
        }
      }else{
        this.router.navigate(['rm/rm-loans']);
      }
      
     
    });
  
}

getDetails(data){
  this.loansService.getLoanDetByUser(data).subscribe( 
    (result:any) => {
        this.loanslist =result['data'];
        console.log(this.loanslist)
  
    })
}

}
