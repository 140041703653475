import { MdbTablePaginationComponent, MdbTableDirective} from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild,  AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';


export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-stage-cancel',
    templateUrl: './meraemi-stage-cancel.component.html',
    styleUrls: ['./meraemi-stage-cancel.component.scss']
})
export class MeraemiStageCancelComponent  implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  elements: any = [];
  previous: any = [];
 
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id"
     },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     
    //  {
    //    name:"Location",
    //    sortBy:"Location"
    //  },
     {
       name:"Loan Amount",
       sortBy:"Amount"
     },
     
     {
      name:"Follow-up Date & Time",
      sortBy:"Update"
    }, {
      name:"Admin Manager",
      sortBy:"Interaction"
    }, 
      
     {
       name:"Stage",
       sortBy:"status"
     },
     
     
     {
      name:"MeraEMI stage",
      sortBy:"stage"
    },
    
     {
       name:"View",
       sortBy:"send_message"
     }

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string; 
  submitted = false;
  
  showLoader: boolean;
  useid: any;
  urlstage: string;
 
 
 

  constructor( 
      private cryptojsService:CryptojsService,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private FeedbackService:FeedbackService,) { }

  ngOnInit() { 
    
         
    
         
  
  }
 
 
 

  canclebutton(){    
    this.showLoader = false;
   
  setTimeout(() => {  
    this.showLoader = true;

    location.reload();
  }, 2000);
  }
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  
 

  //user details page next tab open
  
  getLeadDetails(user,data){  
    let stage=  data;
   // console.log(data);
   this.useid = this.cryptojsService.encryptData((user.user_id));       
   const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
   const detailsurl = base_url + this.useid.toString();
   const dateur=detailsurl+"&stage="+stage.toString();
   window.open(dateur, "_blank"); }
   backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
    rotate(event){
        event.srcElement.classList.remove("rotate");
        event.srcElement.classList.remove("tooltiptext");
        setTimeout(()=>{
          event.srcElement.classList.add("rotate");
          event.srcElement.classList.remove("tooltiptext");
        location.reload();
        },0)
      }

}