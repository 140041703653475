import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { parse } from 'querystring';
import * as moment from 'moment';
import { isEmpty } from 'rxjs/operators';
export interface Color {
  name: string;
}
@Component({
    selector: 'app-dashboard-new',
    templateUrl: './dashboard-new.component.html',
    styleUrls: ['./dashboard-new.component.scss']
})
export class DashboardNewComponent implements OnInit {
  [x: string]: any;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  eledashboard: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 

  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number=0;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id: any;
  to_user_id: any;
  valuemain: number=0;
  userid: number=0;
  activeClass: string;
  startupsLinks: any;
  series: any;

  resultpush: any = [];
  responseArray:any = [];
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number=0;
  ad_id: number=0;
  loan_amt: number=0;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number=0;
  fromdate: any;
  todate: any;
  loan_date: number=0;
  city_name: number=0;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 

  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  iapstage: number=0;
  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;
  usersatge: string;
  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;
  usernamelist: number = 0;
  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
  currentdateStart: string;
  currentdateEnd: string;
  secStartDate: string;
  secEndDate: string;
  thirdStartDate: string;
  thirdEndDate: string;
  forthStartDate: string;
  forthEndDate: string;
  fiveStartDate: string;
  fiveEndDate: string;
  SixtartDate: string;
  SixEndDate: string;
  totallogweb: any;
  totallogBot: any;
  result: any;
  results: any;
  resulttwo: any= [];
// 1st
totallogcountweb1 : number= 0;
totallogcounBot1 : number= 0;
totallDocRecBot1: number= 0;
totalDocRecdweb1: number= 0;


TransfWEBCount: number= 0;
TransfBOTCount: number= 0;
toatalLog1: number= 0;
      totalEligClerBot1: number= 0;
      totalEligPendBot1: number= 0;
      totalEligFailBot1: number= 0;
      totalIapPendBot1 : number= 0;
      totalIapCompBot1: number= 0;
      totalEligPendweb1: number= 0;
      totalEligClerweb1: number= 0;
      totalEligFailweb1: number= 0;
      totalIapPendweb1: number= 0;
      totalIapCompweb1: number= 0;

      totalEligpenCont1: number= 0;
      totalEligClearCont1: number= 0;
      totalEligFailCont1: number= 0;
      totalIAPPenCont1: number= 0;
      totalIAPCompCont1: number= 0;
      totalDocRecivCont1: number= 0;

      // 2nd
      totallogcountweb2 : number= 0;
totallogcounBot2 : number= 0;
totallDocRecBot2: number= 0;
totalDocRecdweb2: number= 0;


TransfWEBCount2: number= 0;
TransfBOTCoun2: number= 0;
toatalLog2: number= 0;
      totalEligClerBot2: number= 0;
      totalEligPendBot2: number= 0;
      totalEligFailBot2: number= 0;
      totalIapPendBot2 : number= 0;
      totalIapCompBot2: number= 0;
      totalEligPendweb2: number= 0;
      totalEligClerweb2: number= 0;
      totalEligFailweb2: number= 0;
      totalIapPendweb2: number= 0;
      totalIapCompweb2: number= 0;

      totalEligpenCont2: number= 0;
      totalEligClearCont2: number= 0;
      totalEligFailCont2: number= 0;
      totalIAPPenCont2: number= 0;
      totalIAPCompCont2: number= 0;
      totalDocRecivCont2: number= 0;
      // 3rd
      totallogcountweb3 : number= 0;
totallogcounBot3 : number= 0;
totallDocRecBot3: number= 0;
totalDocRecdweb3: number= 0;


TransfWEBCount3: number= 0;
TransfBOTCoun3: number= 0;
toatalLog3: number= 0;
      totalEligClerBot3: number= 0;
      totalEligPendBot3: number= 0;
      totalEligFailBot3: number= 0;
      totalIapPendBot3 : number= 0;
      totalIapCompBot3: number= 0;
      totalEligPendweb3: number= 0;
      totalEligClerweb3: number= 0;
      totalEligFailweb3: number= 0;
      totalIapPendweb3: number= 0;
      totalIapCompweb3: number= 0;

      totalEligpenCont3: number= 0;
      totalEligClearCont3: number= 0;
      totalEligFailCont3: number= 0;
      totalIAPPenCont3: number= 0;
      totalIAPCompCont3: number= 0;
      totalDocRecivCont3: number= 0;
      //4th
      totallogcountweb4 : number= 0;
totallogcounBot4 : number= 0;
totallDocRecBot4: number= 0;
totalDocRecdweb4: number= 0;


TransfWEBCount4: number= 0;
TransfBOTCoun4: number= 0;
toatalLog4: number= 0;
      totalEligClerBot4: number= 0;
      totalEligPendBot4: number= 0;
      totalEligFailBot4: number= 0;
      totalIapPendBot4 : number= 0;
      totalIapCompBot4: number= 0;
      totalEligPendweb4: number= 0;
      totalEligClerweb4: number= 0;
      totalEligFailweb4: number= 0;
      totalIapPendweb4: number= 0;
      totalIapCompweb4: number= 0;

      totalEligpenCont4: number= 0;
      totalEligClearCont4: number= 0;
      totalEligFailCont4: number= 0;
      totalIAPPenCont4: number= 0;
      totalIAPCompCont4: number= 0;
      totalDocRecivCont4: number= 0;
      //5th
      totallogcountweb5 : number= 0;
totallogcounBot5 : number= 0;
totallDocRecBot5: number= 0;
totalDocRecdweb5: number= 0;


TransfWEBCount5: number= 0;
TransfBOTCoun5: number= 0;
toatalLog5: number= 0;
      totalEligClerBot5: number= 0;
      totalEligPendBot5: number= 0;
      totalEligFailBot5: number= 0;
      totalIapPendBot5 : number= 0;
      totalIapCompBot5: number= 0;
      totalEligPendweb5: number= 0;
      totalEligClerweb5: number= 0;
      totalEligFailweb5: number= 0;
      totalIapPendweb5: number= 0;
      totalIapCompweb5: number= 0;

      totalEligpenCont5: number= 0;
      totalEligClearCont5: number= 0;
      totalEligFailCont5: number= 0;
      totalIAPPenCont5: number= 0;
      totalIAPCompCont5: number= 0;
      totalDocRecivCont5: number= 0;
      // 6th
      totallogcountweb6 : number= 0;
totallogcounBot6 : number= 0;
totallDocRecBot6: number= 0;
totalDocRecdweb6: number= 0;


TransfWEBCount6: number= 0;
TransfBOTCoun6: number= 0;
toatalLog6: number= 0;
      totalEligClerBot6: number= 0;
      totalEligPendBot6: number= 0;
      totalEligFailBot6: number= 0;
      totalIapPendBot6 : number= 0;
      totalIapCompBot6: number= 0;
      totalEligPendweb6: number= 0;
      totalEligClerweb6: number= 0;
      totalEligFailweb6: number= 0;
      totalIapPendweb6: number= 0;
      totalIapCompweb6: number= 0;

      totalEligpenCont6: number= 0;
      totalEligClearCont6: number= 0;
      totalEligFailCont6: number= 0;
      totalIAPPenCont6: number= 0;
      totalIAPCompCont6: number= 0;
      totalDocRecivCont6: number= 0;
      lenderarrayBot: any = [];
      lenderarrayWeb: any = [];
    lenderarrayWeb2: any = [];
    lenderarrayBot2: any = [];
    lenderarrayBot3: any = [];
    lenderarrayWeb3: any = [];
    lenderarrayBot4: any = [];
    lenderarrayWeb4: any = [];   
    lenderarrayBot5: any = [];
    lenderarrayWeb5: any = [];
    lenderarrayBot6: any = [];
    lenderarrayWeb6: any = [];
  constructor( 
      private cryptojsService:CryptojsService,
      private FeedbackService:FeedbackService,    
      private DashboardService:DashboardService,
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,) { }



	result1: any;
	totaliap:number=0;
	totaldpdm1: number=0;
  Directtotal: number=0;
  Direct: any = [];
  totalDoccomplete: number=0;
	dpdm1: any = [];
  doccomplete: any = [];
	totaldpdm2: number=0;
	dpdm2: any = [];
	totaldpdm3: number=0;
	dpdm3: any = [];
	totaldpdm4: number=0;
	dpdm4: any = [];
	totaldpdm5: number=0;
	dpdm5: any = [];
	totaldpdm6: number=0;
	dpdm6: any = [];
	totaldpdm7: number=0;
	dpdm7: any = [];
	date_from: any;
	date_to: any;
	result2: any;


  dashresult: any;
  eledashboard1: any = [];
  dashresult1: any;
  mainarraydash3: any;
eledashboard3: any = [];
eledashboard4: any = [];
mainarraydash4: any;
eledashboard5: any = [];
mainarraydash5: any;
mainarraydash6: any;
eledashboard6: any = [];
dashresultone: any = [];
  ngOnInit() {
    this.leadsService
    .getAllLeads()
    .subscribe( (result:any) => {
// console.log(result); return false;
           if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.leads.length;  i++) {
                const element = result.leads[i];
              
              // console.log(element.data[0].lender_name);
              //  console.log(element.data[0].elig_status);
              //console.log(element);
              this.stage_color = "info-color";
            
              if(element.data[0].app_stage  === "0"){
                this.elig_status ='Eligibility_Pending';
                this.stage_color = "info-color";
                this.urlstage ='Eligibility_Pending';
                this.iapstage =4;
                this.usersatge="Active";
                this.appstage = this.elig_status;
                this.usernamelist=4;
              
              }else if(element.data[0].app_stage  === "1"){
                 if(element.data[0].elig_status =="1"){
                  this.elig_status ='Eligibility_Clear';
                  this.urlstage ='Eligibility_Clear';
                  this.stage_color = "info-color";
                  this.iapstage =4;
                  this.usernamelist=4;
                  this.usersatge="Active";
                 }else if(element.data[0].elig_status =="0"){
                  this.elig_status ='Eligibility_fail';
                  this.urlstage ='Eligibility_fail';
                  this.stage_color = "info-color";
                  this.iapstage =4;
                  this.usernamelist=4;
                  this.usersatge="Inactive";
                 }
                 this.appstage = this.elig_status;
               
               }else if(element.data[0].app_stage==="2"){
              
                this.appstage ="IAP_Pending";
                this.urlstage ='IAP_Pending';
                this.stage_color = "info-color";
                this.iapstage=5;
                this.usernamelist=4;
                this.usersatge="Active";
              
              }else if(element.data[0].app_stage==="3"){
              this.appstage ="IAP_Complete";
              this.urlstage ='IAP_Complete';
              this.iapstage=5;
              this.usernamelist=5;
               
              }else if(element.data[0].app_stage === "4"){
              this.appstage = 'Document_Upload';
              this.urlstage ="Document_Upload";
              this.stage_color = "info-color";
              this.iapstage=5;
              this.usernamelist=5;
              }else if(element.data[0].app_stage ==="5"){
              this.appstage = 'Meeting_Scheduler';
              this.urlstage ="Meeting_Scheduler";
              this.stage_color = "info-color";
              this.iapstage=5;
              this.usernamelist=5;
              }else if(element.data[0].app_stage === "6"){
              this.appstage = 'Success';
              this.urlstage ='Success';
              this.stage_color = "info-color";
              this.iapstage=5;
              this.usernamelist=5;
              }
              if(element.data[0].lender_name !="NA"){
              if(element.data[0].lender_name=== "MeraEMI"){
              this.lender_name = "Offline";
              }else if(element.data[0].lender_name === "Lender Not Selected"){
              this.lender_name = "NA";
              }else{
              this.lender_name = element.data[0].lender_name;
              }
              
              
              }
              if(this.usernamelist ===5){
              if(element.data[0].iap_fname !="NA" && element.data[0].iap_lname !="NA"){
               this.first_name=element.data[0].iap_fname;
              this.last_name=element.data[0].iap_lname;
              // this.first_name="rajeev";
               //this.last_name="pathak"
              }
              }
              
              if(this.usernamelist ===4){
              if(element.data[0].first_name !="NA" && element.data[0].last_name !="NA"){
              this.first_name=element.data[0].first_name;
              this.last_name=element.data[0].last_name;
              }
              
              }
              if(this.iapstage ===5){
              if(element.data[0].feedback_stage !=undefined){
              this.meraemistage=element.data[0].feedback_stage;
              }else{
              this.meraemistage="Pending Review";
              }
              if(element.data[0].user_stage !=undefined){
                  this.usersatge=element.data[0].user_stage;
                }else{
                  this.usersatge="Active";
                }
              
              //.log(element.data[0].feedback_stage);
              //console.log(element.data[0].user_stage);
              }else{
              if(element.data[0].feedback_stage !=undefined){
              this.meraemistage=element.data[0].feedback_stage;
              }else{
              this.meraemistage="NA";
              }
              
              if(element.data[0].user_stage !=undefined){
              this.usersatge=element.data[0].user_stage;
              }
              }
              
              
              if(element.data[0].provider_id === "BOT MESSENGER"){
              this.provider = 'BOT';
              
              }else{
              this.provider = 'WEB';
              }
             


                   
                  
                                  this.element_main.push(
                                    {
                                      user_id: element.data[0].user_id, 
                                      provider_id: this.provider,
                                      ad_id: element.data[0].ad_id,
                                      date: element.data[0].date,
                                      app_stage: this.appstage,
                                     
                                    }
                                    );
                                  

                  }
                  const d = new Date();
                  var fir=new Date();
               
                    //**********************************************************start current day  ************************************************************ */
                      var firstDay = new Date(fir.getFullYear(), fir.getMonth(), 1); 
                      let firstdate=firstDay.toLocaleDateString();
                      let convstrtdate = moment(firstdate, 'M/D/YYYY');  
                      this.currentdateStart = convstrtdate.format('YYYY-MM-DD');
                     // console.log(this.currentdateStart);
                      //console.log(this.currentdateEnd);
                      let firstdateend=d.toLocaleDateString();
                      let convstrtdateend = moment(firstdateend, 'M/D/YYYY');  
                      this.currentdateEnd = convstrtdateend.format('YYYY-MM-DD');
                     

         // console.log(this.element_main); return false;
          const mainarraydash= this.element_main;
          this.dashresultone = mainarraydash.filter(d => {
            const  reg_date= d.date;
            const regdate = reg_date.split(' ')[0];
            return (new Date(regdate)   >= new Date(this.currentdateStart)  && new Date(regdate)  <=  new Date(this.currentdateEnd) );            
           
            
          });
         
          this.totallogcountweb1 = 0;
          this.totallogcounBot1= 0;
          for (let i = 0; i <  this.dashresultone.length; i++) {
            const element =  this.dashresultone[i];
           
           // console.log(element); return false;
            if(element.provider_id  === "BOT"){
             // console.log( this.dashresultone[i].length);
              this.totallogcountweb1 ++;		
            }

          }
          for (let i = 0; i <  this.dashresultone.length; i++) {
            const element =  this.dashresultone[i];
           
         
            if(element.provider_id  === "WEB"){
             // console.log(result.dashbord[i].length);
              this.totallogcounBot1 ++;
              
            }


          }

        
          
            this.totallogcounBot1=this.totallogcounBot1;
            this.totallogcountweb1=this.totallogcountweb1;
            this.toatalLog1= (this.totallogcounBot1+this.totallogcountweb1);
            this.totalEligClerBot1 = 0;
            this.totalEligPendBot1= 0;
            this.totalEligFailBot1=0;
            this.totalIapPendBot1 =0;
            this.totalIapCompBot1=0;
            this.totalEligPendweb1=0;
            this.totalEligClerweb1=0;
            this.totalEligFailweb1=0;
            this.totalIapPendweb1=0;
            this.totalIapCompweb1=0;
            for (let i = 0; i < this.dashresultone.length; i++) {
              const elements = this.dashresultone[i];
          //console.log(elements);  //return false;
              if(elements.provider_id === "BOT"){
                if(elements.app_stage  === "Eligibility_Pending"){
                  
                  this.urlstageelP ='Eligibility_Pending';
                  this.totalEligPendBot1 ++;	
                
                }else if(elements.app_stage  === "Eligibility_Clear"){
                    this.urlstage ='Eligibility_Clear';
                    this.totalEligClerBot1 ++;	
                               
                    
          
                  }else if(elements.app_stage ==="Eligibility_fail"){
                
                    this.urlstage ='Eligibility_fail';
                    this.totalEligFailBot1 ++;
                    
                  }else if(elements.app_stage ==="IAP_Pending"){
                
                    this.urlstage ='IAP_Pending';
                    this.totalIapPendBot1 ++;
                    
                  }else if(elements.app_stage ==="IAP_Complete"){
                
                    this.urlstage ='IAP_Complete';
                    this.totalIapCompBot1 ++;
                    
                  }
                  
               
               						
           //   console.log(this.totalIapCompBot1); 
              }else{
                if(elements.app_stage  === "Eligibility_Pending"){
          
                  this.urlstageelP ='Eligibility_Pending';
                  this.totalEligPendweb1 ++;	
                
                }else if(elements.app_stage  === "Eligibility_Clear"){
                  this.urlstage ='Eligibility_Clear';
                  this.totalEligClerweb1 ++;
                 
            
                  }else if(elements.app_stage ==="Eligibility_fail"){
                
                    this.urlstage ='Eligibility_fail';
                    this.totalEligFailweb1 ++;
                    
                  }else if(elements.app_stage ==="IAP_Pending"){
                
                    this.urlstage ='IAP_Pending';
                    this.totalIapPendweb1 ++;
                    
                  }else if(elements.app_stage ==="IAP_Complete"){
            
                    this.urlstage ='IAP_Complete';
                    this.totalIapCompweb1 ++;
                    
                    
                  }		
                  //console.log(this.totalEligClerweb1); 
                
                }
              // call lender api
             
             // console.log(this.totalEligPendweb1);
             // console.log(this.totalEligPendBot1);
              
            }
          
           this.totalEligpenCont1= (this.totalEligPendBot1+this.totalEligPendweb1);
           this.totalEligClearCont1= (this.totalEligClerBot1+this.totalEligClerweb1);
           this.totalEligFailCont1= (this.totalEligFailweb1+this.totalEligFailBot1);
           this.totalIAPPenCont1= (this.totalIapPendweb1+this.totalIapPendBot1);
           this.totalIAPCompCont1= (this.totalIapCompweb1+this.totalIapCompBot1);

 //**********************************************************start current day end ************************************************************ */
      
//**********************************************************start 2nd day  ************************************************************ */
      
                              const month = d.getMonth();

                              d.setMonth(d.getMonth() - 1);
                              while (d.getMonth() === month) {
                                  d.setDate(d.getDate() -1);
                              }
                              var sendstart=new Date(d.toLocaleDateString());
                              var secondtDay = new Date(sendstart.getFullYear(), sendstart.getMonth(), 1);
                              let convsecondstartdate = moment(secondtDay, 'M/D/YYYY');  
                              this.secStartDate = convsecondstartdate.format('YYYY-MM-DD');

                              //this.secStartDate=firstDay.toLocaleDateString();
                              var secend=new Date(d.toLocaleDateString());
                              var monthEndDay = new Date(secend.getFullYear(), secend.getMonth() + 1, 0);
                              // this.secEndDate=monthEndDay.toLocaleDateString();

                              let secendend=monthEndDay.toLocaleDateString();
                              let convSecEndDate = moment(secendend, 'M/D/YYYY');  
                              this.secEndDate = convSecEndDate.format('YYYY-MM-DD');


                              this.mainarraydash1 = mainarraydash.filter(d => {
                                const  reg_date= d.date;
                                const regdate = reg_date.split(' ')[0];
                                        

                                return (new Date(regdate)   >= new Date(this.secStartDate)  && new Date(regdate)  <=  new Date(this.secEndDate) );            
                                        
                              });
                              this.totallogcountweb2 = 0;
                              this.totallogcounBot2= 0;
                              
                            
                          
                            for (let i = 0; i <  this.mainarraydash1.length; i++) {
                              const elementsec =  this.mainarraydash1[i];
                             
                           
                              if(elementsec.provider_id  === "BOT"){
                               // console.log( this.mainarraydash1[i].length);
                                this.totallogcountweb2 ++;		
                              }
                        
                            }
                            for (let i = 0; i <  this.mainarraydash1.length; i++) {
                              const elementsec =  this.mainarraydash1[i];
                             
                           
                              if(elementsec.provider_id  === "WEB"){
                               // console.log(result.dashbord[i].length);
                                this.totallogcounBot2 ++;	
                              }
                        
                        
                            }
                        
                             this.totallogcounBot2=this.totallogcounBot2;
                              this.totallogcountweb2=this.totallogcountweb2;                           
                              this.toatalLog2= (this.totallogcounBot2+this.totallogcountweb2);
                              this.totalEligClerBot2 = 0;
                                this.totalEligPendBot2= 0;
                                this.totalEligFailBot2=0;
                                this.totalIapPendBot2 =0;
                                this.totalIapCompBot2=0;
                                this.totalEligPendweb2=0;
                                this.totalEligClerweb2=0;
                                this.totalEligFailweb2=0;
                                this.totalIapPendweb2=0;
                                this.totalIapCompweb2=0;
                                for (let i = 0; i < this.mainarraydash1.length; i++) {
                                  const elementsec = this.mainarraydash1[i];
                                
                                  if(elementsec.provider_id === "BOT"){
                                    if(elementsec.app_stage  === "Eligibility_Pending"){
                                      
                                      this.urlstageelP ='Eligibility_Pending';
                                      this.totalEligPendBot2 ++;	
                                    
                                    }else if(elementsec.app_stage  === "Eligibility_Clear"){
                                      this.urlstage ='Eligibility_Clear';
                                      this.totalEligClerBot2 ++;            
                                      
                                        
                                      }else if(elementsec.app_stage === "Eligibility_fail"){
                                  
                                        this.urlstage ='Eligibility_fail';
                                        this.totalEligFailBot2 ++;
                                        
                                      }else if(elementsec.app_stage === "IAP_Pending"){             
                              
                                        this.urlstage ='IAP_Pending';
                                        this.totalIapPendBot2 ++;
                                        
                                    }else if(elementsec.app_stage === "IAP_Complete"){
                                
                                      this.urlstage ='IAP_Complete';
                                      this.totalIapCompBot2 ++;
                                      
                                      
                                    }					
                                      
                                  
                                          
                              //   console.log(this.totalIapCompBot1); 
                                  }else{
                                    if(elementsec.app_stage  === "Eligibility_Pending"){
                              
                                      this.urlstageelP ='Eligibility_Pending';
                                      this.totalEligPendweb2 ++;	
                                    
                                    }else if(elementsec.app_stage  === "Eligibility_Clear"){
                                      this.urlstage ='Eligibility_Clear';
                                      this.totalEligClerweb2 ++;           
                                          
                                
                                      }else if(elementsec.app_stage === "Eligibility_fail"){
                                    
                                        this.urlstage ='Eligibility_fail';
                                        this.totalEligFailweb2 ++;
                                        
                                      }else if(elementsec.app_stage === "IAP_Pending"){
                                  
                                
                                        this.urlstage ='IAP_Pending';
                                        this.totalIapPendweb2 ++;
                                        
                                    }else if(elementsec.app_stage === "IAP_Complete"){
                                  
                                      this.urlstage ='IAP_Complete';
                                      this.totalIapCompweb2 ++;
                                      
                                      
                                    }
                                    
                                    
                                      
                                  
                                  }	
                                  // call lender api
                                
                              
                                  
                                }
                              
                                this.totalEligpenCont2= (this.totalEligPendBot2+this.totalEligPendweb2);
                                this.totalEligClearCont2= (this.totalEligClerBot2+this.totalEligClerweb2);
                                this.totalEligFailCont2= (this.totalEligFailweb2+this.totalEligFailBot2);
                                this.totalIAPPenCont2= (this.totalIapPendweb2+this.totalIapPendBot2);
                                this.totalIAPCompCont2= (this.totalIapCompweb2+this.totalIapCompBot2);
 //**********************************************************start 2nd day  End  ************************************************************ */
//**********************************************************start 3nd day    ************************************************************ */
                                const c = new Date();
                                c.setMonth(c.getMonth() - 2);
                                while (c.getMonth() === month) {
                                    c.setDate(c.getDate() -2);
                                }
                                //  console.log(c.toLocaleDateString());

                                // this.thirdStartDate=c.toLocaleDateString();

                                var thirdStart=new Date(c.toLocaleDateString());
                                var thirdDay = new Date(thirdStart.getFullYear(), thirdStart.getMonth(), 1);
                                let convthirdDaystartdate = moment(thirdDay, 'M/D/YYYY');  
                                this.thirdStartDate = convthirdDaystartdate.format('YYYY-MM-DD');






                                var third=new Date(c.toLocaleDateString());
                                var thirdMonthEndDay = new Date(third.getFullYear(), third.getMonth() + 1, 0);

                                let thirdend=thirdMonthEndDay.toLocaleDateString();
                                let convThirdEndDate = moment(thirdend, 'M/D/YYYY');  
                                this.thirdEndDate = convThirdEndDate.format('YYYY-MM-DD'); 

                                        this.mainarraydash3 = mainarraydash.filter(d => {
                                          const  reg_date= d.date;
                                          const regdate = reg_date.split(' ')[0];
                                                
                                          return (new Date(regdate)   >= new Date(this.thirdStartDate)  && new Date(regdate)  <=  new Date(this.thirdEndDate) );            
                                                              
                                          
                                        });
                                      
                                        this.totallogcountweb3 = 0;
                                        this.totallogcounBot3= 0;
                                      
                                        this.fullertonWebCount1=0;
                                      this.fullertonBotCount1=0;
                                      this.HDBBotCount=0;
                                      this.HDBWEBCount=0;
                                      this.LonTapWEBCount=0;
                                      this.LonTapBOTCount=0;
                                      for (let i = 0; i <  this.mainarraydash3.length; i++) {
                                        const elementthird =  this.mainarraydash3[i];
                                    //  console.log(element);
                                        if(elementthird.provider_id === "BOT"){
                                          this.totallogcounBot3 ++;									
                                          
                                        }
                                        
                                      }
                                      for (let i = 0; i <  this.mainarraydash3.length; i++) {
                                        const elementthird =  this.mainarraydash3[i];
                                    //  console.log(element);
                                        if(elementthird.provider_id === "WEB"){
                                          this.totallogcountweb3 ++;									
                                          
                                        }
                                        
                                      }
                                      this.totallogcounBot3=this.totallogcounBot3;
                                      this.totallogcountweb3=this.totallogcountweb3;
                                      this.toatalLog3= (this.totallogcounBot3+this.totallogcountweb3);
                                      this.totalEligClerBot3 = 0;
                                      this.totalEligPendBot3= 0;
                                      this.totalEligFailBot3=0;
                                      this.totalIapPendBot3 =0;
                                      this.totalIapCompBot3=0;
                                      this.totalEligPendweb3=0;
                                      this.totalEligClerweb3=0;
                                      this.totalEligFailweb3=0;
                                      this.totalIapPendweb3=0;
                                      this.totalIapCompweb3=0;
                                      for (let i = 0; i < this.mainarraydash3.length; i++) {
                                        const elementsthird = this.mainarraydash3[i];
                                      
                                        if(elementsthird.provider_id === "BOT"){
                                          if(elementsthird.app_stage  === "Eligibility_Pending"){
                                            
                                            this.urlstageelP ='Eligibility_Pending';
                                            this.totalEligPendBot3 ++;	
                                          
                                          }else if(elementsthird.app_stage  === "Eligibility_Clear"){
                                                          
                                              this.urlstage ='Eligibility_Clear';
                                              this.totalEligClerBot3 ++;	
                                    
                                            }else if(elementsthird.app_stage === "Eligibility_fail"){
                                          
                                              this.urlstage ='Eligibility_fail';
                                              this.totalEligFailBot3 ++;
                                              
                                            }else if(elementsthird.app_stage === "IAP_Pending"){
                                          
                                              this.urlstage ='IAP_Pending';
                                              this.totalIapPendBot3 ++;
                                              
                                            }else if(elementsthird.app_stage === "IAP_Complete"){
                                          
                                              this.urlstage ='IAP_Pending';
                                              this.totalIapCompBot3 ++;
                                              
                                            }
                                            
                                            
                                      
                                        }else{
                                          if(elementsthird.app_stage  === "Eligibility_Pending"){
                                    
                                            this.urlstageelP ='Eligibility_Pending';
                                            this.totalEligPendweb3 ++;	
                                          
                                          }else if(elementsthird.app_stage  === "Eligibility_Clear"){
                                                      
                                              this.urlstage ='Eligibility_Clear';
                                              this.totalEligClerweb3 ++;	
                                      
                                            }else if(elementsthird.app_stage === "Eligibility_fail"){
                                          
                                              this.urlstage ='Eligibility_fail';
                                              this.totalEligFailweb3 ++;
                                              
                                            }else if(elementsthird.app_stage === "IAP_Pending"){
                                          
                                              this.urlstage ='IAP_Pending';
                                              this.totalIapPendweb3 ++;
                                              
                                            }else if(elementsthird.app_stage === "IAP_Complete"){
                                          
                                              this.urlstage ='IAP_Complete';
                                              this.totalIapCompweb3 ++;
                                              
                                            }
                                            //console.log(this.totalEligClerweb3); 
                                          
                                            
                                        
                                        }	
                                        // call lender api
                                      
                                    
                                        
                                      }

                                    this.totalEligpenCont3= (this.totalEligPendBot3+this.totalEligPendweb3);
                                    this.totalEligClearCont3= (this.totalEligClerBot3+this.totalEligClerweb3);
                                    this.totalEligFailCont3= (this.totalEligFailweb3+this.totalEligFailBot3);
                                    this.totalIAPPenCont3= (this.totalIapPendweb3+this.totalIapPendBot3);
                                    this.totalIAPCompCont3= (this.totalIapCompweb3+this.totalIapCompBot3);

//**********************************************************start 3nd day  End  ************************************************************ */

//**********************************************************start 4nd day    ************************************************************ */
                                                const E = new Date();
                                                E.setMonth(E.getMonth() - 3);
                                                while (E.getMonth() === month) {
                                                  E.setDate(E.getDate() -3);
                                                }
                                                // console.log(E.toLocaleDateString());
                                                //this.forthStartDate=E.toLocaleDateString();

                                                var ForthStart=new Date(E.toLocaleDateString());
                                                var ForthDay = new Date(ForthStart.getFullYear(), ForthStart.getMonth(), 1);
                                                let convForthDaystartdate = moment(ForthDay, 'M/D/YYYY');  
                                                this.forthStartDate = convForthDaystartdate.format('YYYY-MM-DD');


                                                var forth=new Date(E.toLocaleDateString());
                                                var forthMonthEndDay = new Date(forth.getFullYear(), forth.getMonth() + 1, 0);



                                                let Forthend=forthMonthEndDay.toLocaleDateString();
                                                let convForthEndDate = moment(Forthend, 'M/D/YYYY');  
                                                this.forthEndDate = convForthEndDate.format('YYYY-MM-DD');   
                                                 // console.log(this.forthStartDate);
                                                 // console.log(this.forthEndDate);
                                                  const mainarraydash4= this.eledashboard4;
                                                  // console.log(this.mainarraydash4);
                                                    this.mainarraydash4 = mainarraydash.filter(d => {
                                                      const  reg_date= d.date;
                                                      const regdate = reg_date.split(' ')[0];
                                                      
                                                      return (new Date(regdate)   >= new Date(this.forthStartDate)  && new Date(regdate)  <=  new Date(this.forthEndDate) );            
                                                                                                
                                                    
                                                      
                                                    });

                                                this.totallogcountweb4 = 0;
                                                this.totallogcounBot4= 0;

                                                this.fullertonWebCount1=0;
                                                this.fullertonBotCount1=0;
                                                this.HDBBotCount=0;
                                                this.HDBWEBCount=0;
                                                this.LonTapWEBCount=0;
                                                this.LonTapBOTCount=0;

                                                for (let i = 0; i < this.mainarraydash4.length; i++) {
                                                  const elementforth = this.mainarraydash4[i];
                                                  //  console.log(element);
                                                  if(elementforth.provider_id === "BOT"){
                                                    this.totallogcounBot4 ++;									
                                                    
                                                  }
                                                }
                                                for (let i = 0; i < this.mainarraydash4.length; i++) {
                                                  const elementforth = this.mainarraydash4[i];
                                                  //  console.log(elementforth);
                                                  if(elementforth.provider_id === "WEB"){
                                                    this.totallogcountweb4 ++;									
                                                    
                                                  }
                                                }
                                                this.totalEligClerBot4 = 0;
                                                this.totalEligPendBot4= 0;
                                                this.totalEligFailBot4=0;
                                                this.totalIapPendBot4 =0;
                                                this.totalIapCompBot4=0;
                                                this.totalEligPendweb4=0;
                                                this.totalEligClerweb4=0;
                                                this.totalEligFailweb4=0;
                                                this.totalIapPendweb4=0;
                                                this.totalIapCompweb4=0;



                                                // console.log( this.fullertonBotCount1);
                                                //console.log( this.fullertonWebCount1);

                                                this.totallogcounBot4=this.totallogcounBot4;
                                                this.totallogcountweb4=this.totallogcountweb4;
                                                this.toatalLog4= (this.totallogcounBot4+this.totallogcountweb4);
                                                for (let i = 0; i < this.mainarraydash4.length; i++) {
                                                  const elementsforth = this.mainarraydash4[i];
                                                  
                                                  if(elementsforth.provider_id === "BOT"){
                                                    if(elementsforth.app_stage  === "Eligibility_Pending"){
                                                      
                                                      this.urlstageelP ='Eligibility_Pending';
                                                      this.totalEligPendBot4 ++;	
                                                    
                                                    }else if(elementsforth.app_stage  === "Eligibility_Clear"){
                                                      this.urlstage ='Eligibility_Clear';
                                                        this.totalEligClerBot4 ++;	            
                                                      
                                                  
                                                      }else if(elementsforth.app_stage === "Eligibility_fail"){
                                                    
                                                        this.urlstage ='Eligibility_fail';
                                                        this.totalEligFailBot4 ++;
                                                        
                                                      }else if(elementsforth.app_stage === "IAP_Pending"){
                                                    
                                                        this.urlstage ='IAP_Pending';
                                                        this.totalIapPendBot4 ++;
                                                        
                                                      }else if(elementsforth.app_stage === "IAP_Complete"){
                                                    
                                                        this.urlstage ='IAP_Complete';
                                                        this.totalIapCompBot4 ++;
                                                        
                                                      }
                                                      
                                                    // console.log(this.totalEligFailBot4);
                                                                
                                                  //   console.log(this.totalIapCompBot4); 
                                                  }else{
                                                    if(elementsforth.app_stage  === "Eligibility_Pending"){
                                                  
                                                      this.urlstageelP ='Eligibility_Pending';
                                                      this.totalEligPendweb4 ++;	
                                                    
                                                    }else if(elementsforth.app_stage  === "Eligibility_Clear"){
                                                      this.urlstage ='Eligibility_Clear';
                                                      this.totalEligClerweb4 ++;		
                                                  
                                                      }else if(elementsforth.app_stage === "Eligibility_fail"){
                                                    
                                                        this.urlstage ='Eligibility_fail';
                                                        this.totalEligFailweb4 ++;
                                                        
                                                      }else if(elementsforth.app_stage === "IAP_Pending"){
                                                    
                                                        this.urlstage ='IAP_Pending';
                                                        this.totalIapPendweb4 ++;
                                                        
                                                      }else if(elementsforth.app_stage === "IAP_Complete"){
                                                    
                                                        this.urlstage ='IAP_Complete';
                                                        this.totalIapCompweb4 ++;
                                                        
                                                      }
                                                      //console.log(this.totalEligClerweb4); 
                                                    
                                                    
                                                  }	
                                                  // call lender api
                                                  
                                                  
                                                  
                                                  }
                                                  
                                                  this.totalEligpenCont4= (this.totalEligPendBot4+this.totalEligPendweb4);
                                                  this.totalEligClearCont4= (this.totalEligClerBot4+this.totalEligClerweb4);
                                                  this.totalEligFailCont4= (this.totalEligFailweb4+this.totalEligFailBot4);
                                                  this.totalIAPPenCont4= (this.totalIapPendweb4+this.totalIapPendBot4);
                                                  this.totalIAPCompCont4= (this.totalIapCompweb4+this.totalIapCompBot4);

 //**********************************************************start 4nd day  End  ************************************************************ */

//**********************************************************start 5nd day    ************************************************************ */
                                                      const F = new Date();
                                                      F.setMonth(F.getMonth() - 4);
                                                      while (F.getMonth() === month) {
                                                        F.setDate(F.getDate() -4);
                                                      }
                                                      //   console.log(F.toLocaleDateString());
                                                      //this.fiveStartDate=F.toLocaleDateString();

                                                      var FiftStart=new Date(F.toLocaleDateString());
                                                      var fiftdDay = new Date(FiftStart.getFullYear(), FiftStart.getMonth(), 1);
                                                      let convFiftDaystartdate = moment(fiftdDay, 'M/D/YYYY');  
                                                      this.fiveStartDate = convFiftDaystartdate.format('YYYY-MM-DD');



                                                      var five=new Date(F.toLocaleDateString());
                                                      var fiveMonthEndDay = new Date(five.getFullYear(), five.getMonth() + 1, 0);
                                                      //this.fiveEndDate=fiveMonthEndDay.toLocaleDateString();

                                                      let Fiftend=fiveMonthEndDay.toLocaleDateString();
                                                      let convFifthEndDate = moment(Fiftend, 'M/D/YYYY');  
                                                      this.fiveEndDate = convFifthEndDate.format('YYYY-MM-DD'); 


                                                      this.mainarraydash5 = mainarraydash.filter(d => {
                                                        const  reg_date= d.date;
                                                        const regdate = reg_date.split(' ')[0];
                                                                
                                                        return (new Date(regdate)   >= new Date(this.fiveStartDate)  && new Date(regdate)  <=  new Date(this.fiveEndDate) );            
                                                                                                          
                                                        
                                                      });

                                                      this.totallogcountweb5 = 0;
                                                      this.totallogcounBot5= 0;

                                                      this.fullertonWebCount1=0;
                                                      this.fullertonBotCount1=0;
                                                      this.HDBBotCount=0;
                                                      this.HDBWEBCount=0;
                                                      this.LonTapWEBCount=0;
                                                      this.LonTapBOTCount=0;

                                                      for (let i = 0; i < this.mainarraydash5.length; i++) {
                                                      const elementfive = this.mainarraydash5[i];
                                                      //  console.log(element);
                                                      if(elementfive.provider_id === "BOT"){
                                                        this.totallogcounBot5 ++;									
                                                        
                                                      }
                                                      }
                                                      for (let i = 0; i < this.mainarraydash5.length; i++) {
                                                      const elementfive = this.mainarraydash5[i];
                                                      //  console.log(element);
                                                      if(elementfive.provider_id === "WEB"){
                                                        this.totallogcountweb5 ++;									
                                                        
                                                      }
                                                      }

                                                      this.totallogcounBot5=this.totallogcounBot5;
                                                      this.totallogcountweb5=this.totallogcountweb5;
                                                      this.toatalLog5= (this.totallogcounBot5+this.totallogcountweb5);
                                                      this.totalEligClerBot5 = 0;
                                                      this.totalEligPendBot5= 0;
                                                      this.totalEligFailBot5=0;
                                                      this.totalIapPendBot5 =0;
                                                      this.totalIapCompBot5=0;
                                                      this.totalEligPendweb5=0;
                                                      this.totalEligClerweb5=0;
                                                      this.totalEligFailweb5=0;
                                                      this.totalIapPendweb5=0;
                                                      this.totalIapCompweb5=0;

                                                      for (let i = 0; i < this.mainarraydash5.length; i++) {
                                                        const elementsfive = this.mainarraydash5[i];
                                                      
                                                        if(elementsfive.provider_id === "BOT"){
                                                          if(elementsfive.app_stage  === "Eligibility_Pending"){
                                                            
                                                            this.urlstageelP ='Eligibility_Pending';
                                                            this.totalEligPendBot5 ++;	
                                                          
                                                          }else if(elementsfive.app_stage  === "Eligibility_Clear"){
                                                            this.urlstage ='Eligibility_Clear';
                                                            this.totalEligClerBot5 ++;	           
                                                            

                                                            }else if(elementsfive.app_stage === "Eligibility_fail"){
                                                          
                                                              this.urlstage ='Eligibility_fail';
                                                              this.totalEligFailBot5 ++;
                                                              
                                                            }else if(elementsfive.app_stage === "IAP_Pending"){
                                                          
                                                              this.urlstage ='IAP_Pending';
                                                              this.totalIapPendBot5 ++;
                                                              
                                                            }else if(elementsfive.app_stage === "IAP_Complete"){
                                                          
                                                              this.urlstage ='IAP_Complete';
                                                              this.totalIapCompBot5 ++;
                                                              
                                                            }
                                                            
                                                            
                                                                        
                                                      //   console.log(this.totalIapCompBot5); 
                                                        }else{
                                                          if(elementsfive.app_stage  === "Eligibility_Pending"){

                                                            this.urlstageelP ='Eligibility_Pending';
                                                            this.totalEligPendweb5 ++;	
                                                          
                                                          }else if(elementsfive.app_stage  === "Eligibility_Clear"){
                                                              this.urlstage ='Eligibility_Clear';
                                                              this.totalEligClerweb5 ++;	

                                                            }else if(elementsfive.app_stage === "Eligibility_fail"){
                                                          
                                                              this.urlstage ='Eligibility_fail';
                                                              this.totalEligFailweb5 ++;
                                                              
                                                            }else if(elementsfive.app_stage === "IAP_Pending"){
                                                          
                                                              this.urlstage ='IAP_Pending';
                                                              this.totalIapPendweb5 ++;
                                                              
                                                            }else if(elementsfive.app_stage === "IAP_Complete"){
                                                          
                                                              this.urlstage ='IAP_Complete';
                                                              this.totalIapCompweb5 ++;
                                                              
                                                            }
                                                            //console.log(this.totalEligClerweb5); 
                                                          
                                                            
                                                        
                                                        }	
                                                        // call lender api
                                                      

                                                        
                                                      }

                                                      this.totalEligpenCont5= (this.totalEligPendBot5+this.totalEligPendweb5);
                                                      this.totalEligClearCont5= (this.totalEligClerBot5+this.totalEligClerweb5);
                                                      this.totalEligFailCont5= (this.totalEligFailweb5+this.totalEligFailBot5);
                                                      this.totalIAPPenCont5= (this.totalIapPendweb5+this.totalIapPendBot5);
                                                      this.totalIAPCompCont5= (this.totalIapCompweb5+this.totalIapCompBot5);
//**********************************************************start 5nd day  End  ************************************************************ */

//**********************************************************start 6th day    ************************************************************ */
                                                      const h = new Date();
                                                      h.setMonth(h.getMonth() - 5);
                                                      while (h.getMonth() === month) {
                                                        h.setDate(h.getDate() -5);
                                                      }
                                                      //  console.log(G.toLocaleDateString());
                                                      this.SixtartDate=h.toLocaleDateString();

                                                      var sixStart=new Date(h.toLocaleDateString());
                                                      var sixdDay = new Date(sixStart.getFullYear(), sixStart.getMonth(), 1);
                                                      let convSixDaystartdate = moment(sixdDay, 'M/D/YYYY');  
                                                      this.SixtartDate = convSixDaystartdate.format('YYYY-MM-DD');



                                                      var Six=new Date(h.toLocaleDateString());
                                                      var SixMonthEndDay = new Date(Six.getFullYear(), Six.getMonth() + 1, 0);

                                                      let Sixend=SixMonthEndDay.toLocaleDateString();
                                                      let convSixEndDate = moment(Sixend, 'M/D/YYYY');  
                                                      this.SixEndDate = convSixEndDate.format('YYYY-MM-DD'); 
                                                      this.mainarraydash6 = mainarraydash.filter(d => {
                                                        const  reg_date= d.date;
                                                        const regdate = reg_date.split(' ')[0];
                                                                
                                                        return (new Date(regdate)   >= new Date(this.SixtartDate)  && new Date(regdate)  <=  new Date(this.SixEndDate) );            
                                                          
                                                        
                                                      });
                                                      this.totallogcountweb6 = 0;
                                                      this.totallogcounBot6= 0;

                                                      this.fullertonWebCount1=0;
                                                      this.fullertonBotCount1=0;
                                                      this.HDBBotCount=0;
                                                      this.HDBWEBCount=0;
                                                      this.LonTapWEBCount=0;
                                                      this.LonTapBOTCount=0;
                                                      for (let i = 0; i < this.mainarraydash6.length; i++) {
                                                        const elementsix = this.mainarraydash6[i];
                                                        //  console.log(element);
                                                        if(elementsix.provider_id === "BOT"){
                                                          this.totallogcounBot6 ++;									
                                                          
                                                        }
                                                      }

                                                      for (let i = 0; i < this.mainarraydash6.length; i++) {
                                                        const elementsix = this.mainarraydash6[i];
                                                        //  console.log(elementsix);
                                                        if(elementsix.provider_id === "WEB"){
                                                          this.totallogcountweb6 ++;									
                                                          
                                                        }
                                                      }

                                                      this.totallogcounBot6=this.totallogcounBot6;
                                                      this.totallogcountweb6=this.totallogcountweb6;
                                                      this.toatalLog6= (this.totallogcounBot6+this.totallogcountweb6);
                                                      this.totalEligClerBot6 = 0;
                                                      this.totalEligPendBot6= 0;
                                                      this.totalEligFailBot6=0;
                                                      this.totalIapPendBot6 =0;
                                                      this.totalIapCompBot6=0;
                                                      this.totalEligPendweb6=0;
                                                      this.totalEligClerweb6=0;
                                                      this.totalEligFailweb6=0;
                                                      this.totalIapPendweb6=0;
                                                      this.totalIapCompweb6=0;
                                                      for (let i = 0; i < this.mainarraydash6.length; i++) {
                                                        const elemtsix = this.mainarraydash6[i];
                                                        
                                                        if(elemtsix.provider_id === "BOT"){
                                                          if(elemtsix.app_stage  === "Eligibility_Pending"){
                                                            
                                                            this.urlstageelP ='Eligibility_Pending';
                                                            this.totalEligPendBot6 ++;	
                                                          
                                                          }else if(elemtsix.app_stage  === "Eligibility_Clear"){
                                                              this.urlstage ='Eligibility_Clear';
                                                              this.totalEligClerBot6 ++;	
                                                        
                                                            }else if(elemtsix.app_stage === "Eligibility_fail"){
                                                          
                                                              this.urlstage ='Eligibility_fail';
                                                              this.totalEligFailBot6 ++;
                                                              
                                                            }else if(elemtsix.app_stage === "IAP_Pending"){
                                                          
                                                              this.urlstage ='IAP_Pending';
                                                              this.totalIapPendBot6 ++;
                                                              
                                                            }else if(elemtsix.app_stage === "IAP_Complete"){
                                                          
                                                              this.urlstage ='Eligibility_fail';
                                                              this.totalIapCompBot6 ++;
                                                              
                                                            }
                                                            
                                                          
                                                        }else{
                                                          if(elemtsix.app_stage  === "Eligibility_Pending"){
                                                        
                                                            this.urlstageelP ='Eligibility_Pending';
                                                            this.totalEligPendweb6 ++;	
                                                          
                                                          }else if(elemtsix.app_stage  === "Eligibility_Clear"){
                                                            this.urlstage ='Eligibility_Clear';
                                                            this.totalEligClerweb6 ++;              
                                                            
                                                        
                                                            }else if(elemtsix.app_stage ==="Eligibility_fail"){
                                                          
                                                              this.urlstage ='Eligibility_fail';
                                                              this.totalEligFailweb6 ++;
                                                              
                                                            }else if(elemtsix.app_stage ==="IAP_Pending"){
                                                          
                                                              this.urlstage ='IAP_Pending';
                                                              this.totalIapPendweb6 ++;
                                                              
                                                            }else if(elemtsix.app_stage ==="IAP_Complete"){
                                                          
                                                              this.urlstage ='IAP_Complete';
                                                              this.totalIapCompweb6 ++;
                                                              
                                                            }
                                                            //console.log(this.totalEligClerweb6); 
                                                          
                                                            
                                                        
                                                        }	
                                                        // call lender api
                                                        
                                                        
                                                        
                                                        }
                                                        
                                                        this.totalEligpenCont6= (this.totalEligPendBot6+this.totalEligPendweb6);
                                                        this.totalEligClearCont6= (this.totalEligClerBot6+this.totalEligClerweb6);
                                                        this.totalEligFailCont6= (this.totalEligFailweb6+this.totalEligFailBot6);
                                                        this.totalIAPPenCont6= (this.totalIapPendweb6+this.totalIapPendBot6);
                                                        this.totalIAPCompCont6= (this.totalIapCompweb6+this.totalIapCompBot6);
//**********************************************************start 6th day   end ************************************************************ */
     
            }else{
              console.log('User list Api faile');
            }
            
          });
   
      //**********************************************************start current day  ************************************************************ */
      const d = new Date();
      var fir=new Date();
      const data = this.mdbTable.getDataSource();
       var firstDay = new Date(fir.getFullYear(), fir.getMonth(), 1); 
        let firstdate=firstDay.toLocaleDateString();
        let convstrtdate = moment(firstdate, 'M/D/YYYY');  
        this.currentdateStart = convstrtdate.format('YYYY-MM-DD');
        //console.log(this.currentdateStart);
        //console.log(this.currentdateEnd);
        let firstdateend=d.toLocaleDateString();
        let convstrtdateend = moment(firstdateend, 'M/D/YYYY');  
        this.currentdateEnd = convstrtdateend.format('YYYY-MM-DD');
        //console.log(data);

     
      
          
          this.totallDocRecBot1=0;
          this.totalDocRecdweb1=0;
          let docReceived={ results: [{"fromdate":this.currentdateStart, "todate":this.currentdateEnd}]};
          // console.log(trdata);
          this.DashboardService.GetdocReceived(docReceived).subscribe(    
            (trans:any) => {
            
         // console.log(trans); return false;
             if(trans.status = true  && trans.response=== 200){
               for (let i = 0; i < trans.GetdocReceived.length; i++) {
                const eledocr = trans.GetdocReceived[i];
                    if(eledocr.data[0].provider_id  ==="BOT MESSENGER"){
                      this.totallDocRecBot1 ++;
                    }else if(eledocr.data[0].provider_id  ==="facebook.com"){
                      this.totalDocRecdweb1 ++;	
                    }
                  
                   
                 }
               
             }
             
             
             
            });			
            
     
          //  console.log( this.totalDocRecdweb1);
           // console.log( this.totallDocRecBot1);
            this.totalDocRecdweb1=this.totalDocRecdweb1;
            this.totallDocRecBot1=this.totallDocRecBot1;
            this.totalDocRecivCont1= (this.totalDocRecdweb1+this.totallDocRecBot1);
          let dates={ results: [{"startdate":this.currentdateStart, "enddate":this.currentdateEnd}]};
          this.DashboardService.lenderAPIResponse(dates).subscribe(    
            (lender:any) => {
              
              if (lender.status = true  && lender.response=== 200) {
                let mainLenderArray = [],
                 apiLenderArray = lender.lenderApi;
             
            //  console.log(lender);

               apiLenderArray.forEach(element => {
                
              
                      let    prependObject = false
                      if (element.data.length > 0) {
                        let newObject = element.data;
                      
                       
                       //  console.log('key', newObject.length);


                        for (let i = 0; i < newObject.length; i++) {
                        
                          const elementData = newObject[i];
                      
                          if (Object.keys(elementData).length>0) {
                            
                            prependObject = true
                          }
                        
                        }
                        if (prependObject ===true) {
                          mainLenderArray.push({data: newObject})
                        }
                      }
                    
                });

               // console.log(lenderele.data[0]) 
                for (let i = 0; i < mainLenderArray.length; i++) {
                  const lenderele = mainLenderArray[i];

                    // Web
                    if(lenderele.data[0].fullerton_response !=undefined){
                      if(lenderele.data[0].fullerton_response.provider_id ==="facebook.com" ){
                        this.lenderarrayWeb.push(
                          {
                            fullerton_lender_id: lenderele.data[0].fullerton_response.fullerton_lender_id, 
                            hubble_id:  lenderele.data[0].fullerton_response.hubble_id, 
                            responseDate: lenderele.data[0].fullerton_response.responseDate,
                            status: lenderele.data[0].fullerton_response.status,
                            substatus: lenderele.data[0].fullerton_response.substatus,
                            lenderHeder: lenderele.data[0].fullerton_response.lenderHeder,
                          }
                          );
                      
                        }
                    }
                    if(lenderele.data[0].Idfc_response !=undefined){
                    if(lenderele.data[0].Idfc_response.provider_id ==="facebook.com" ){
                      this.lenderarrayWeb.push(
                        {
                          substatus: lenderele.data[0].Idfc_response.fullerton_lender_id, 
                          errorInfo:  lenderele.data[0].Idfc_response.errorInfo, 
                          idfc_lender_id: lenderele.data[0].Idfc_response.idfc_lender_id,
                          responseDate: lenderele.data[0].Idfc_response.responseDate,
                          sfdc_id: lenderele.data[0].Idfc_response.sfdc_id,
                          status: lenderele.data[0].Idfc_response.status, 
                          lenderHeder: lenderele.data[0].Idfc_response.lenderHeder,
                        }
                        );
                     
                      }
                    }
                    if(lenderele.data[0].loanTab_response !=undefined){
                    if(lenderele.data[0].loanTab_response.provider_id ==="facebook.com"){
                      this.lenderarrayWeb.push(
                        {
                          substatus: lenderele.data[0].loanTab_response.lenderHeder2, 
                          loantab_lender_id:  lenderele.data[0].loanTab_response.loantab_lender_id, 
                          status: lenderele.data[0].loanTab_response.status, 
                          lenderHeder: lenderele.data[0].loanTab_response.lenderHeder,
                        }
                        );
                     
                      }
                    }
                    if(lenderele.data[0].hdbf_response !=undefined){
                    if(lenderele.data[0].hdbf_response.provider_id ==="facebook.com" ){
                      this.lenderarrayWeb.push(
                        {
                          substatus: lenderele.data[0].hdbf_response.lenderHeder2, 
                          loantab_lender_id:  lenderele.data[0].hdbf_response.loantab_lender_id, 
                          status: lenderele.data[0].hdbf_response.status, 
                          lenderHeder: lenderele.data[0].hdbf_response.lenderHeder,
                        }
                        );
                     
                      }
                    }
                    if(lenderele.data[0].bajaj_response !=undefined){
                      if(lenderele.data[0].bajaj_response.provider_id ==="facebook.com" ){
                        this.lenderarrayWeb.push(
                          {
                            bfl_response_id: lenderele.data[0].bajaj_response.bfl_response_id, 
                            bfl_unique_id:  lenderele.data[0].bajaj_response.bfl_unique_id, 
                            offerId: lenderele.data[0].bajaj_response.offerId, 
                            partner_unique_id: lenderele.data[0].bajaj_response.partner_unique_id,
                          }
                          );
                       
                        }
                      }
                      //Bot
                      if(lenderele.data[0].fullerton_response !=undefined){
                        if(lenderele.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
                          this.lenderarrayBot.push(
                            {
                              fullerton_lender_id: lenderele.data[0].fullerton_response.fullerton_lender_id, 
                              hubble_id:  lenderele.data[0].fullerton_response.hubble_id, 
                              responseDate: lenderele.data[0].fullerton_response.responseDate,
                              status: lenderele.data[0].fullerton_response.status,
                              substatus: lenderele.data[0].fullerton_response.substatus,
                              lenderHeder: lenderele.data[0].fullerton_response.lenderHeder,
                            }
                            );
                        
                          }
                      }
                      if(lenderele.data[0].Idfc_response !=undefined){
                      if(lenderele.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
                        this.lenderarrayBot.push(
                          {
                            substatus: lenderele.data[0].Idfc_response.fullerton_lender_id, 
                            errorInfo:  lenderele.data[0].Idfc_response.errorInfo, 
                            idfc_lender_id: lenderele.data[0].Idfc_response.idfc_lender_id,
                            responseDate: lenderele.data[0].Idfc_response.responseDate,
                            sfdc_id: lenderele.data[0].Idfc_response.sfdc_id,
                            status: lenderele.data[0].Idfc_response.status, 
                            lenderHeder: lenderele.data[0].Idfc_response.lenderHeder,
                          }
                          );
                       
                        }
                      }
                      if(lenderele.data[0].loanTab_response !=undefined){
                      if(lenderele.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
                        this.lenderarrayBot.push(
                          {
                            substatus: lenderele.data[0].loanTab_response.lenderHeder2, 
                            loantab_lender_id:  lenderele.data[0].loanTab_response.loantab_lender_id, 
                            status: lenderele.data[0].loanTab_response.status, 
                            lenderHeder: lenderele.data[0].loanTab_response.lenderHeder,
                          }
                          );
                       
                        }
                      }
                      if(lenderele.data[0].hdbf_response !=undefined){
                      if(lenderele.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
                        this.lenderarrayBot.push(
                          {
                            substatus: lenderele.data[0].hdbf_response.lenderHeder2, 
                            loantab_lender_id:  lenderele.data[0].hdbf_response.loantab_lender_id, 
                            status: lenderele.data[0].hdbf_response.status, 
                            lenderHeder: lenderele.data[0].hdbf_response.lenderHeder,
                          }
                          );
                       
                        }
                      }
                      if(lenderele.data[0].bajaj_response !=undefined){
                        if(lenderele.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
                          this.lenderarrayBot.push(
                            {
                              bfl_response_id: lenderele.data[0].bajaj_response.bfl_response_id, 
                              bfl_unique_id:  lenderele.data[0].bajaj_response.bfl_unique_id, 
                              offerId: lenderele.data[0].bajaj_response.offerId, 
                              partner_unique_id: lenderele.data[0].bajaj_response.partner_unique_id,
                            }
                            );
                         
                          }
                        }
                  //  console.log(this.lenderarrayWeb);
                  // console.log("fullerton_response",lenderele.data[0].fullerton_response)  
              
                  // console.log("hdbf_response",lenderele.data[0].hdbf_response)  
                  // console.log("bajaj_response",lenderele.data[0].bajaj_response)  
                  // console.log("Idfc_response",lenderele.data[0].Idfc_response) 
                  // console.log("loanTab_response",lenderele.data[0].loanTab_response) 
                   
                }
                this.TransfWEBCount=this.lenderarrayWeb.length;
                this.TransfBOTCount=this.lenderarrayBot.length;


              //  console.log("bo1",this.TransfWEBCount);
               // console.log("web1",this.TransfBOTCount);

               
               
              }
              
             // console.log(this.TransfBOTCount);
            // console.log(this.TransfWEBCount);
            
              });
        //console.log(this.currentdateStart);
       // console.log(this.currentdateEnd);
      //**********************************************************start current day end ************************************************************ */
      

//**********************************************************start 2nd day  ************************************************************ */
      
const month = d.getMonth();

d.setMonth(d.getMonth() - 1);
while (d.getMonth() === month) {
    d.setDate(d.getDate() -1);
}
var sendstart=new Date(d.toLocaleDateString());
var secondtDay = new Date(sendstart.getFullYear(), sendstart.getMonth(), 1);
let convsecondstartdate = moment(secondtDay, 'M/D/YYYY');  
this.secStartDate = convsecondstartdate.format('YYYY-MM-DD');

//this.secStartDate=firstDay.toLocaleDateString();
var secend=new Date(d.toLocaleDateString());
var monthEndDay = new Date(secend.getFullYear(), secend.getMonth() + 1, 0);
// this.secEndDate=monthEndDay.toLocaleDateString();

let secendend=monthEndDay.toLocaleDateString();
let convSecEndDate = moment(secendend, 'M/D/YYYY');  
this.secEndDate = convSecEndDate.format('YYYY-MM-DD');

this.totallDocRecBot2=0;
      this.totalDocRecdweb2=0;
  let docReceivedsec={ results: [{"fromdate":this.secStartDate, "todate":this.secEndDate}]};
  // console.log(trdata);
  this.DashboardService.GetdocReceived(docReceivedsec).subscribe(    
    (transsec:any) => {
    
 // console.log(trans); return false;
     if(transsec.status = true  && transsec.response=== 200){
       for (let i = 0; i < transsec.GetdocReceived.length; i++) {
        const eledocrsec = transsec.GetdocReceived[i];
            if(eledocrsec.data[0].provider_id  ==="BOT MESSENGER"){
              this.totallDocRecBot2 ++;
            }else if(eledocrsec.data[0].provider_id  ==="facebook.com"){
              this.totalDocRecdweb2 ++;	
            }
          
           
         }
       
     }
     
     
     
    });			
    


this.totalDocRecdweb2=this.totalDocRecdweb2;
this.totallDocRecBot2=this.totallDocRecBot2;
this.totalDocRecivCont2= (this.totalDocRecdweb2+this.totallDocRecBot2);
  let dates2={ results: [{"startdate":this.secStartDate, "enddate":this.secEndDate}]};
  this.DashboardService.lenderAPIResponse(dates2).subscribe(    
    (lender1:any) => {
      
      if (lender1.status = true  && lender1.response=== 200) {
        let mainLenderArray1 = [],
         apiLenderArray1 = lender1.lenderApi;
     
    //  console.log(lender);

       apiLenderArray1.forEach(element => {
        
      
              let    prependObject = false
              if (element.data.length > 0) {
                let newObject = element.data;
              
               
               //  console.log('key', newObject.length);


                for (let i = 0; i < newObject.length; i++) {
                
                  const elementData = newObject[i];
              
                  if (Object.keys(elementData).length>0) {
                    
                    prependObject = true
                  }
                
                }
                if (prependObject ===true) {
                  mainLenderArray1.push({data: newObject})
                }
              }
            
        });

       // console.log(lenderele1.data[0]) 
        for (let i = 0; i < mainLenderArray1.length; i++) {
          const lenderele1 = mainLenderArray1[i];

            // Web
            if(lenderele1.data[0].fullerton_response !=undefined){
              if(lenderele1.data[0].fullerton_response.provider_id ==="facebook.com" ){
                this.lenderarrayWeb2.push(
                  {
                    fullerton_lender_id: lenderele1.data[0].fullerton_response.fullerton_lender_id, 
                    hubble_id:  lenderele1.data[0].fullerton_response.hubble_id, 
                    responseDate: lenderele1.data[0].fullerton_response.responseDate,
                    status: lenderele1.data[0].fullerton_response.status,
                    substatus: lenderele1.data[0].fullerton_response.substatus,
                    lenderHeder: lenderele1.data[0].fullerton_response.lenderHeder,
                  }
                  );
              
                }
            }
            if(lenderele1.data[0].Idfc_response !=undefined){
            if(lenderele1.data[0].Idfc_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb2.push(
                {
                  substatus: lenderele1.data[0].Idfc_response.fullerton_lender_id, 
                  errorInfo:  lenderele1.data[0].Idfc_response.errorInfo, 
                  idfc_lender_id: lenderele1.data[0].Idfc_response.idfc_lender_id,
                  responseDate: lenderele1.data[0].Idfc_response.responseDate,
                  sfdc_id: lenderele1.data[0].Idfc_response.sfdc_id,
                  status: lenderele1.data[0].Idfc_response.status, 
                  lenderHeder: lenderele1.data[0].Idfc_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele1.data[0].loanTab_response !=undefined){
            if(lenderele1.data[0].loanTab_response.provider_id ==="facebook.com"){
              this.lenderarrayWeb2.push(
                {
                  substatus: lenderele1.data[0].loanTab_response.lenderHeder2, 
                  loantab_lender_id:  lenderele1.data[0].loanTab_response.loantab_lender_id, 
                  status: lenderele1.data[0].loanTab_response.status, 
                  lenderHeder: lenderele1.data[0].loanTab_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele1.data[0].hdbf_response !=undefined){
            if(lenderele1.data[0].hdbf_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb2.push(
                {
                  substatus: lenderele1.data[0].hdbf_response.lenderHeder2, 
                  loantab_lender_id:  lenderele1.data[0].hdbf_response.loantab_lender_id, 
                  status: lenderele1.data[0].hdbf_response.status, 
                  lenderHeder: lenderele1.data[0].hdbf_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele1.data[0].bajaj_response !=undefined){
              if(lenderele1.data[0].bajaj_response.provider_id ==="facebook.com" ){
                this.lenderarrayWeb2.push(
                  {
                    bfl_response_id: lenderele1.data[0].bajaj_response.bfl_response_id, 
                    bfl_unique_id:  lenderele1.data[0].bajaj_response.bfl_unique_id, 
                    offerId: lenderele1.data[0].bajaj_response.offerId, 
                    partner_unique_id: lenderele1.data[0].bajaj_response.partner_unique_id,
                  }
                  );
               
                }
              }
              //Bot
              if(lenderele1.data[0].fullerton_response !=undefined){
                if(lenderele1.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
                  this.lenderarrayBot2.push(
                    {
                      fullerton_lender_id: lenderele1.data[0].fullerton_response.fullerton_lender_id, 
                      hubble_id:  lenderele1.data[0].fullerton_response.hubble_id, 
                      responseDate: lenderele1.data[0].fullerton_response.responseDate,
                      status: lenderele1.data[0].fullerton_response.status,
                      substatus: lenderele1.data[0].fullerton_response.substatus,
                      lenderHeder: lenderele1.data[0].fullerton_response.lenderHeder,
                    }
                    );
                
                  }
              }
              if(lenderele1.data[0].Idfc_response !=undefined){
              if(lenderele1.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot2.push(
                  {
                    substatus: lenderele1.data[0].Idfc_response.fullerton_lender_id, 
                    errorInfo:  lenderele1.data[0].Idfc_response.errorInfo, 
                    idfc_lender_id: lenderele1.data[0].Idfc_response.idfc_lender_id,
                    responseDate: lenderele1.data[0].Idfc_response.responseDate,
                    sfdc_id: lenderele1.data[0].Idfc_response.sfdc_id,
                    status: lenderele1.data[0].Idfc_response.status, 
                    lenderHeder: lenderele1.data[0].Idfc_response.lenderHeder,
                  }
                  );
               
                }
              }
              if(lenderele1.data[0].loanTab_response !=undefined){
              if(lenderele1.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
                this.lenderarrayBot2.push(
                  {
                    substatus: lenderele1.data[0].loanTab_response.lenderHeder2, 
                    loantab_lender_id:  lenderele1.data[0].loanTab_response.loantab_lender_id, 
                    status: lenderele1.data[0].loanTab_response.status, 
                    lenderHeder: lenderele1.data[0].loanTab_response.lenderHeder,
                  }
                  );
               
                }
              }
              if(lenderele1.data[0].hdbf_response !=undefined){
              if(lenderele1.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot2.push(
                  {
                    substatus: lenderele1.data[0].hdbf_response.lenderHeder2, 
                    loantab_lender_id:  lenderele1.data[0].hdbf_response.loantab_lender_id, 
                    status: lenderele1.data[0].hdbf_response.status, 
                    lenderHeder: lenderele1.data[0].hdbf_response.lenderHeder,
                  }
                  );
               
                }
              }
              if(lenderele1.data[0].bajaj_response !=undefined){
                if(lenderele1.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
                  this.lenderarrayBot2.push(
                    {
                      bfl_response_id: lenderele1.data[0].bajaj_response.bfl_response_id, 
                      bfl_unique_id:  lenderele1.data[0].bajaj_response.bfl_unique_id, 
                      offerId: lenderele1.data[0].bajaj_response.offerId, 
                      partner_unique_id: lenderele1.data[0].bajaj_response.partner_unique_id,
                    }
                    );
                 
                  }
                }
          //  console.log(this.lenderarrayWeb2);
          // console.log("fullerton_response",lenderele1.data[0].fullerton_response)  
      
          // console.log("hdbf_response",lenderele1.data[0].hdbf_response)  
          // console.log("bajaj_response",lenderele1.data[0].bajaj_response)  
          // console.log("Idfc_response",lenderele1.data[0].Idfc_response) 
          // console.log("loanTab_response",lenderele1.data[0].loanTab_response) 
           
        }
        this.TransfBOTCoun2 =this.lenderarrayBot2.length;	
        this.TransfWEBCount2 = this.lenderarrayWeb2.length;	

      //  console.log("bo1",this.TransfWEBCount);
       // console.log("web1",this.TransfBOTCount);

       
       
      }
      
     // console.log(this.TransfBOTCount);
    // console.log(this.TransfWEBCount);
    
      });
//console.log(this.currentdateStart);
// console.log(this.currentdateEnd);

//**********************************************************start 2nd day  End  ************************************************************ */
//**********************************************************start 3nd day    ************************************************************ */

const c = new Date();
c.setMonth(c.getMonth() - 2);
while (c.getMonth() === month) {
    c.setDate(c.getDate() -2);
}
//  console.log(c.toLocaleDateString());

// this.thirdStartDate=c.toLocaleDateString();

var thirdStart=new Date(c.toLocaleDateString());
var thirdDay = new Date(thirdStart.getFullYear(), thirdStart.getMonth(), 1);
let convthirdDaystartdate = moment(thirdDay, 'M/D/YYYY');  
this.thirdStartDate = convthirdDaystartdate.format('YYYY-MM-DD');






var third=new Date(c.toLocaleDateString());
var thirdMonthEndDay = new Date(third.getFullYear(), third.getMonth() + 1, 0);

let thirdend=thirdMonthEndDay.toLocaleDateString();
let convThirdEndDate = moment(thirdend, 'M/D/YYYY');  
this.thirdEndDate = convThirdEndDate.format('YYYY-MM-DD'); 
this.totallDocRecBot3=0;
this.totalDocRecdweb3=0;
let docReceivedthird={ results: [{"fromdate":this.thirdStartDate, "todate":this.thirdEndDate}]};
// console.log(trdata);
this.DashboardService.GetdocReceived(docReceivedthird).subscribe(    
(transthird:any) => {

// console.log(trans); return false;
if(transthird.status = true  && transthird.response=== 200){
 for (let i = 0; i < transthird.GetdocReceived.length; i++) {
  const eledoctrird = transthird.GetdocReceived[i];
      if(eledoctrird.data[0].provider_id  ==="BOT MESSENGER"){
        this.totallDocRecBot3 ++;
      }else if(eledoctrird.data[0].provider_id  ==="facebook.com"){
        this.totalDocRecdweb3 ++;	
      }
    
     
   }
 
}



});			



this.totalDocRecdweb3=this.totalDocRecdweb3;
this.totallDocRecBot3=this.totallDocRecBot3;
this.totalDocRecivCont3= (this.totalDocRecdweb3+this.totallDocRecBot3);

let dates3={ results: [{"startdate":this.thirdStartDate, "enddate":this.thirdEndDate}]};
this.DashboardService.lenderAPIResponse(dates3).subscribe(    
(lender3:any) => {
  
  if (lender3.status = true  && lender3.response=== 200) {
    let mainLenderArray3 = [],
     apiLenderArray3 = lender3.lenderApi;
 
//  console.log(lender);

   apiLenderArray3.forEach(element3 => {
    
  
          let    prependObject3 = false
          if (element3.data.length > 0) {
            let newObject3 = element3.data;
          
           
           //  console.log('key', newObject3.length);


            for (let i = 0; i < newObject3.length; i++) {
            
              const elementData3 = newObject3[i];
          
              if (Object.keys(elementData3).length>0) {
                
                prependObject3 = true
              }
            
            }
            if (prependObject3 ===true) {
              mainLenderArray3.push({data: newObject3})
            }
          }
        
    });

   // console.log(lenderele3.data[0]) 
    for (let i = 0; i < mainLenderArray3.length; i++) {
      const lenderele3 = mainLenderArray3[i];

        // Web
        if(lenderele3.data[0].fullerton_response !=undefined){
          if(lenderele3.data[0].fullerton_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb3.push(
              {
                fullerton_lender_id: lenderele3.data[0].fullerton_response.fullerton_lender_id, 
                hubble_id:  lenderele3.data[0].fullerton_response.hubble_id, 
                responseDate: lenderele3.data[0].fullerton_response.responseDate,
                status: lenderele3.data[0].fullerton_response.status,
                substatus: lenderele3.data[0].fullerton_response.substatus,
                lenderHeder: lenderele3.data[0].fullerton_response.lenderHeder,
              }
              );
          
            }
        }
        if(lenderele3.data[0].Idfc_response !=undefined){
        if(lenderele3.data[0].Idfc_response.provider_id ==="facebook.com" ){
          this.lenderarrayWeb3.push(
            {
              substatus: lenderele3.data[0].Idfc_response.fullerton_lender_id, 
              errorInfo:  lenderele3.data[0].Idfc_response.errorInfo, 
              idfc_lender_id: lenderele3.data[0].Idfc_response.idfc_lender_id,
              responseDate: lenderele3.data[0].Idfc_response.responseDate,
              sfdc_id: lenderele3.data[0].Idfc_response.sfdc_id,
              status: lenderele3.data[0].Idfc_response.status, 
              lenderHeder: lenderele3.data[0].Idfc_response.lenderHeder,
            }
            );
         
          }
        }
        if(lenderele3.data[0].loanTab_response !=undefined){
        if(lenderele3.data[0].loanTab_response.provider_id ==="facebook.com"){
          this.lenderarrayWeb3.push(
            {
              substatus: lenderele3.data[0].loanTab_response.lenderHeder2, 
              loantab_lender_id:  lenderele3.data[0].loanTab_response.loantab_lender_id, 
              status: lenderele3.data[0].loanTab_response.status, 
              lenderHeder: lenderele3.data[0].loanTab_response.lenderHeder,
            }
            );
         
          }
        }
        if(lenderele3.data[0].hdbf_response !=undefined){
        if(lenderele3.data[0].hdbf_response.provider_id ==="facebook.com" ){
          this.lenderarrayWeb3.push(
            {
              substatus: lenderele3.data[0].hdbf_response.lenderHeder2, 
              loantab_lender_id:  lenderele3.data[0].hdbf_response.loantab_lender_id, 
              status: lenderele3.data[0].hdbf_response.status, 
              lenderHeder: lenderele3.data[0].hdbf_response.lenderHeder,
            }
            );
         
          }
        }
        if(lenderele3.data[0].bajaj_response !=undefined){
          if(lenderele3.data[0].bajaj_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb3.push(
              {
                bfl_response_id: lenderele3.data[0].bajaj_response.bfl_response_id, 
                bfl_unique_id:  lenderele3.data[0].bajaj_response.bfl_unique_id, 
                offerId: lenderele3.data[0].bajaj_response.offerId, 
                partner_unique_id: lenderele3.data[0].bajaj_response.partner_unique_id,
              }
              );
           
            }
          }
          //Bot
          if(lenderele3.data[0].fullerton_response !=undefined){
            if(lenderele3.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot3.push(
                {
                  fullerton_lender_id: lenderele3.data[0].fullerton_response.fullerton_lender_id, 
                  hubble_id:  lenderele3.data[0].fullerton_response.hubble_id, 
                  responseDate: lenderele3.data[0].fullerton_response.responseDate,
                  status: lenderele3.data[0].fullerton_response.status,
                  substatus: lenderele3.data[0].fullerton_response.substatus,
                  lenderHeder: lenderele3.data[0].fullerton_response.lenderHeder,
                }
                );
            
              }
          }
          if(lenderele3.data[0].Idfc_response !=undefined){
          if(lenderele3.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
            this.lenderarrayBot3.push(
              {
                substatus: lenderele3.data[0].Idfc_response.fullerton_lender_id, 
                errorInfo:  lenderele3.data[0].Idfc_response.errorInfo, 
                idfc_lender_id: lenderele3.data[0].Idfc_response.idfc_lender_id,
                responseDate: lenderele3.data[0].Idfc_response.responseDate,
                sfdc_id: lenderele3.data[0].Idfc_response.sfdc_id,
                status: lenderele3.data[0].Idfc_response.status, 
                lenderHeder: lenderele3.data[0].Idfc_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele3.data[0].loanTab_response !=undefined){
          if(lenderele3.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
            this.lenderarrayBot3.push(
              {
                substatus: lenderele3.data[0].loanTab_response.lenderHeder2, 
                loantab_lender_id:  lenderele3.data[0].loanTab_response.loantab_lender_id, 
                status: lenderele3.data[0].loanTab_response.status, 
                lenderHeder: lenderele3.data[0].loanTab_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele3.data[0].hdbf_response !=undefined){
          if(lenderele3.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
            this.lenderarrayBot3.push(
              {
                substatus: lenderele3.data[0].hdbf_response.lenderHeder2, 
                loantab_lender_id:  lenderele3.data[0].hdbf_response.loantab_lender_id, 
                status: lenderele3.data[0].hdbf_response.status, 
                lenderHeder: lenderele3.data[0].hdbf_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele3.data[0].bajaj_response !=undefined){
            if(lenderele3.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot3.push(
                {
                  bfl_response_id: lenderele3.data[0].bajaj_response.bfl_response_id, 
                  bfl_unique_id:  lenderele3.data[0].bajaj_response.bfl_unique_id, 
                  offerId: lenderele3.data[0].bajaj_response.offerId, 
                  partner_unique_id: lenderele3.data[0].bajaj_response.partner_unique_id,
                }
                );
             
              }
            }
      //  console.log(this.lenderarrayWeb3);
      // console.log("fullerton_response",lenderele3.data[0].fullerton_response)  
  
      // console.log("hdbf_response",lenderele3.data[0].hdbf_response)  
      // console.log("bajaj_response",lenderele3.data[0].bajaj_response)  
      // console.log("Idfc_response",lenderele3.data[0].Idfc_response) 
      // console.log("loanTab_response",lenderele3.data[0].loanTab_response) 
       
    }
    this.TransfBOTCoun3 =this.lenderarrayBot3.length;	
    this.TransfWEBCount3 = this.lenderarrayWeb3.length;	


   // console.log("bot3",this.TransfBOTCoun3);
   // console.log("web3",this.TransfWEBCount3);
   

   
   
  }
  
 // console.log(this.TransfBOTCount);
// console.log(this.TransfWEBCount);

  });

//  console.log(this.thirdStartDate);
//console.log(this.thirdEndDate);

//**********************************************************start 3nd day  End  ************************************************************ */

//**********************************************************start 4nd day    ************************************************************ */





const E = new Date();
E.setMonth(E.getMonth() - 3);
while (E.getMonth() === month) {
  E.setDate(E.getDate() -3);
}
// console.log(E.toLocaleDateString());
//this.forthStartDate=E.toLocaleDateString();

var ForthStart=new Date(E.toLocaleDateString());
var ForthDay = new Date(ForthStart.getFullYear(), ForthStart.getMonth(), 1);
let convForthDaystartdate = moment(ForthDay, 'M/D/YYYY');  
this.forthStartDate = convForthDaystartdate.format('YYYY-MM-DD');


var forth=new Date(E.toLocaleDateString());
var forthMonthEndDay = new Date(forth.getFullYear(), forth.getMonth() + 1, 0);



let Forthend=forthMonthEndDay.toLocaleDateString();
let convForthEndDate = moment(Forthend, 'M/D/YYYY');  
this.forthEndDate = convForthEndDate.format('YYYY-MM-DD');   
this.totallDocRecBot4=0;
this.totalDocRecdweb4=0;
let docReceivedforth={ results: [{"fromdate":this.forthStartDate, "todate":this.forthEndDate}]};
// console.log(trdata);
this.DashboardService.GetdocReceived(docReceivedforth).subscribe(    
(transforth:any) => {

// console.log(trans); return false;
if(transforth.status = true  && transforth.response=== 200){
 for (let i = 0; i < transforth.GetdocReceived.length; i++) {
  const eledocforth = transforth.GetdocReceived[i];
      if(eledocforth.data[0].provider_id  ==="BOT MESSENGER"){
        this.totallDocRecBot4 ++;
      }else if(eledocforth.data[0].provider_id  ==="facebook.com"){
        this.totalDocRecdweb4 ++;	
      }
    
     
   }
 
}



});			



this.totalDocRecdweb4=this.totalDocRecdweb4;
this.totallDocRecBot4=this.totallDocRecBot4;
this.totalDocRecivCont4= (this.totalDocRecdweb4+this.totallDocRecBot4);

let dates4={ results: [{"startdate":this.forthStartDate, "enddate":this.forthEndDate}]};
this.DashboardService.lenderAPIResponse(dates4).subscribe(    
  (lender4:any) => {
    
    if (lender4.status = true  && lender4.response=== 200) {
      let mainLenderArray4 = [],
       apiLenderArray4 = lender4.lenderApi;
   
  //  console.log(lender);

     apiLenderArray4.forEach(element4 => {
      
    
            let    prependObject4 = false
            if (element4.data.length > 0) {
              let newObject4 = element4.data;
            
             
             //  console.log('key', newObject4.length);


              for (let i = 0; i < newObject4.length; i++) {
              
                const elementData4 = newObject4[i];
            
                if (Object.keys(elementData4).length>0) {
                  
                  prependObject4 = true
                }
              
              }
              if (prependObject4 ===true) {
                mainLenderArray4.push({data: newObject4})
              }
            }
          
      });

     // console.log(lenderele4.data[0]) 
      for (let i = 0; i < mainLenderArray4.length; i++) {
        const lenderele4 = mainLenderArray4[i];

          // Web
          if(lenderele4.data[0].fullerton_response !=undefined){
            if(lenderele4.data[0].fullerton_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb4.push(
                {
                  fullerton_lender_id: lenderele4.data[0].fullerton_response.fullerton_lender_id, 
                  hubble_id:  lenderele4.data[0].fullerton_response.hubble_id, 
                  responseDate: lenderele4.data[0].fullerton_response.responseDate,
                  status: lenderele4.data[0].fullerton_response.status,
                  substatus: lenderele4.data[0].fullerton_response.substatus,
                  lenderHeder: lenderele4.data[0].fullerton_response.lenderHeder,
                }
                );
            
              }
          }
          if(lenderele4.data[0].Idfc_response !=undefined){
          if(lenderele4.data[0].Idfc_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb4.push(
              {
                substatus: lenderele4.data[0].Idfc_response.fullerton_lender_id, 
                errorInfo:  lenderele4.data[0].Idfc_response.errorInfo, 
                idfc_lender_id: lenderele4.data[0].Idfc_response.idfc_lender_id,
                responseDate: lenderele4.data[0].Idfc_response.responseDate,
                sfdc_id: lenderele4.data[0].Idfc_response.sfdc_id,
                status: lenderele4.data[0].Idfc_response.status, 
                lenderHeder: lenderele4.data[0].Idfc_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele4.data[0].loanTab_response !=undefined){
          if(lenderele4.data[0].loanTab_response.provider_id ==="facebook.com"){
            this.lenderarrayWeb4.push(
              {
                substatus: lenderele4.data[0].loanTab_response.lenderHeder2, 
                loantab_lender_id:  lenderele4.data[0].loanTab_response.loantab_lender_id, 
                status: lenderele4.data[0].loanTab_response.status, 
                lenderHeder: lenderele4.data[0].loanTab_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele4.data[0].hdbf_response !=undefined){
          if(lenderele4.data[0].hdbf_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb4.push(
              {
                substatus: lenderele4.data[0].hdbf_response.lenderHeder2, 
                loantab_lender_id:  lenderele4.data[0].hdbf_response.loantab_lender_id, 
                status: lenderele4.data[0].hdbf_response.status, 
                lenderHeder: lenderele4.data[0].hdbf_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele4.data[0].bajaj_response !=undefined){
            if(lenderele4.data[0].bajaj_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb4.push(
                {
                  bfl_response_id: lenderele4.data[0].bajaj_response.bfl_response_id, 
                  bfl_unique_id:  lenderele4.data[0].bajaj_response.bfl_unique_id, 
                  offerId: lenderele4.data[0].bajaj_response.offerId, 
                  partner_unique_id: lenderele4.data[0].bajaj_response.partner_unique_id,
                }
                );
             
              }
            }
            //Bot
            if(lenderele4.data[0].fullerton_response !=undefined){
              if(lenderele4.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot4.push(
                  {
                    fullerton_lender_id: lenderele4.data[0].fullerton_response.fullerton_lender_id, 
                    hubble_id:  lenderele4.data[0].fullerton_response.hubble_id, 
                    responseDate: lenderele4.data[0].fullerton_response.responseDate,
                    status: lenderele4.data[0].fullerton_response.status,
                    substatus: lenderele4.data[0].fullerton_response.substatus,
                    lenderHeder: lenderele4.data[0].fullerton_response.lenderHeder,
                  }
                  );
              
                }
            }
            if(lenderele4.data[0].Idfc_response !=undefined){
            if(lenderele4.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot4.push(
                {
                  substatus: lenderele4.data[0].Idfc_response.fullerton_lender_id, 
                  errorInfo:  lenderele4.data[0].Idfc_response.errorInfo, 
                  idfc_lender_id: lenderele4.data[0].Idfc_response.idfc_lender_id,
                  responseDate: lenderele4.data[0].Idfc_response.responseDate,
                  sfdc_id: lenderele4.data[0].Idfc_response.sfdc_id,
                  status: lenderele4.data[0].Idfc_response.status, 
                  lenderHeder: lenderele4.data[0].Idfc_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele4.data[0].loanTab_response !=undefined){
            if(lenderele4.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
              this.lenderarrayBot4.push(
                {
                  substatus: lenderele4.data[0].loanTab_response.lenderHeder2, 
                  loantab_lender_id:  lenderele4.data[0].loanTab_response.loantab_lender_id, 
                  status: lenderele4.data[0].loanTab_response.status, 
                  lenderHeder: lenderele4.data[0].loanTab_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele4.data[0].hdbf_response !=undefined){
            if(lenderele4.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot4.push(
                {
                  substatus: lenderele4.data[0].hdbf_response.lenderHeder2, 
                  loantab_lender_id:  lenderele4.data[0].hdbf_response.loantab_lender_id, 
                  status: lenderele4.data[0].hdbf_response.status, 
                  lenderHeder: lenderele4.data[0].hdbf_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele4.data[0].bajaj_response !=undefined){
              if(lenderele4.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot4.push(
                  {
                    bfl_response_id: lenderele4.data[0].bajaj_response.bfl_response_id, 
                    bfl_unique_id:  lenderele4.data[0].bajaj_response.bfl_unique_id, 
                    offerId: lenderele4.data[0].bajaj_response.offerId, 
                    partner_unique_id: lenderele4.data[0].bajaj_response.partner_unique_id,
                  }
                  );
               
                }
              }
        //  console.log(this.lenderarrayWeb4);
        // console.log("fullerton_response",lenderele4.data[0].fullerton_response)  
    
        // console.log("hdbf_response",lenderele4.data[0].hdbf_response)  
        // console.log("bajaj_response",lenderele4.data[0].bajaj_response)  
        // console.log("Idfc_response",lenderele4.data[0].Idfc_response) 
        // console.log("loanTab_response",lenderele4.data[0].loanTab_response) 
         
      }
      this.TransfBOTCoun4 =this.lenderarrayBot4.length;	
      this.TransfWEBCount4 = this.lenderarrayWeb4.length;	

     // console.log("bot4",this.TransfBOTCoun4);
     // console.log("web4",this.TransfWEBCount4);
     

     
     
    }
    
   // console.log(this.TransfBOTCount);
  // console.log(this.TransfWEBCount);
  
    });
    //**********************************************************start 4nd day  End  ************************************************************ */

//**********************************************************start 5nd day    ************************************************************ */


const F = new Date();
F.setMonth(F.getMonth() - 4);
while (F.getMonth() === month) {
  F.setDate(F.getDate() -4);
}
//   console.log(F.toLocaleDateString());
//this.fiveStartDate=F.toLocaleDateString();

var FiftStart=new Date(F.toLocaleDateString());
var fiftdDay = new Date(FiftStart.getFullYear(), FiftStart.getMonth(), 1);
let convFiftDaystartdate = moment(fiftdDay, 'M/D/YYYY');  
this.fiveStartDate = convFiftDaystartdate.format('YYYY-MM-DD');



var five=new Date(F.toLocaleDateString());
var fiveMonthEndDay = new Date(five.getFullYear(), five.getMonth() + 1, 0);
//this.fiveEndDate=fiveMonthEndDay.toLocaleDateString();

let Fiftend=fiveMonthEndDay.toLocaleDateString();
let convFifthEndDate = moment(Fiftend, 'M/D/YYYY');  
this.fiveEndDate = convFifthEndDate.format('YYYY-MM-DD');   
this.totallDocRecBot5=0;
this.totalDocRecdweb5=0;
let docReceivedfive={ results: [{"fromdate":this.fiveStartDate, "todate":this.fiveEndDate}]};
// console.log(trdata);
this.DashboardService.GetdocReceived(docReceivedfive).subscribe(    
(transfive:any) => {

// console.log(trans); return false;
if(transfive.status = true  && transfive.response=== 200){
 for (let i = 0; i < transfive.GetdocReceived.length; i++) {
  const eledocfive = transfive.GetdocReceived[i];
      if(eledocfive.data[0].provider_id  ==="BOT MESSENGER"){
        this.totallDocRecBot5 ++;
      }else if(eledocfive.data[0].provider_id  ==="facebook.com"){
        this.totalDocRecdweb5 ++;	
      }
    
     
   }
 
}



});			



this.totalDocRecdweb5=this.totalDocRecdweb5;
this.totallDocRecBot5=this.totallDocRecBot5;
this.totalDocRecivCont5= (this.totalDocRecdweb5+this.totallDocRecBot5);

let dates5={ results: [{"startdate":this.fiveStartDate, "enddate":this.fiveEndDate}]};
this.DashboardService.lenderAPIResponse(dates5).subscribe(    
  (lender5:any) => {
    
    if (lender5.status = true  && lender5.response=== 200) {
      let mainLenderArray5 = [],
       apiLenderArray5 = lender5.lenderApi;
   
  //  console.log(lender);

     apiLenderArray5.forEach(element5 => {
      
    
            let    prependObject5 = false
            if (element5.data.length > 0) {
              let newObject5 = element5.data;
            
             
             //  console.log('key', newObject5.length);


              for (let i = 0; i < newObject5.length; i++) {
              
                const elementData5 = newObject5[i];
            
                if (Object.keys(elementData5).length>0) {
                  
                  prependObject5 = true
                }
              
              }
              if (prependObject5 ===true) {
                mainLenderArray5.push({data: newObject5})
              }
            }
          
      });

     // console.log(lenderele5.data[0]) 
      for (let i = 0; i < mainLenderArray5.length; i++) {
        const lenderele5 = mainLenderArray5[i];

          // Web
          if(lenderele5.data[0].fullerton_response !=undefined){
            if(lenderele5.data[0].fullerton_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb5.push(
                {
                  fullerton_lender_id: lenderele5.data[0].fullerton_response.fullerton_lender_id, 
                  hubble_id:  lenderele5.data[0].fullerton_response.hubble_id, 
                  responseDate: lenderele5.data[0].fullerton_response.responseDate,
                  status: lenderele5.data[0].fullerton_response.status,
                  substatus: lenderele5.data[0].fullerton_response.substatus,
                  lenderHeder: lenderele5.data[0].fullerton_response.lenderHeder,
                }
                );
            
              }
          }
          if(lenderele5.data[0].Idfc_response !=undefined){
          if(lenderele5.data[0].Idfc_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb5.push(
              {
                substatus: lenderele5.data[0].Idfc_response.fullerton_lender_id, 
                errorInfo:  lenderele5.data[0].Idfc_response.errorInfo, 
                idfc_lender_id: lenderele5.data[0].Idfc_response.idfc_lender_id,
                responseDate: lenderele5.data[0].Idfc_response.responseDate,
                sfdc_id: lenderele5.data[0].Idfc_response.sfdc_id,
                status: lenderele5.data[0].Idfc_response.status, 
                lenderHeder: lenderele5.data[0].Idfc_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele5.data[0].loanTab_response !=undefined){
          if(lenderele5.data[0].loanTab_response.provider_id ==="facebook.com"){
            this.lenderarrayWeb5.push(
              {
                substatus: lenderele5.data[0].loanTab_response.lenderHeder2, 
                loantab_lender_id:  lenderele5.data[0].loanTab_response.loantab_lender_id, 
                status: lenderele5.data[0].loanTab_response.status, 
                lenderHeder: lenderele5.data[0].loanTab_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele5.data[0].hdbf_response !=undefined){
          if(lenderele5.data[0].hdbf_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb5.push(
              {
                substatus: lenderele5.data[0].hdbf_response.lenderHeder2, 
                loantab_lender_id:  lenderele5.data[0].hdbf_response.loantab_lender_id, 
                status: lenderele5.data[0].hdbf_response.status, 
                lenderHeder: lenderele5.data[0].hdbf_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele5.data[0].bajaj_response !=undefined){
            if(lenderele5.data[0].bajaj_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb5.push(
                {
                  bfl_response_id: lenderele5.data[0].bajaj_response.bfl_response_id, 
                  bfl_unique_id:  lenderele5.data[0].bajaj_response.bfl_unique_id, 
                  offerId: lenderele5.data[0].bajaj_response.offerId, 
                  partner_unique_id: lenderele5.data[0].bajaj_response.partner_unique_id,
                }
                );
             
              }
            }
            //Bot
            if(lenderele5.data[0].fullerton_response !=undefined){
              if(lenderele5.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot5.push(
                  {
                    fullerton_lender_id: lenderele5.data[0].fullerton_response.fullerton_lender_id, 
                    hubble_id:  lenderele5.data[0].fullerton_response.hubble_id, 
                    responseDate: lenderele5.data[0].fullerton_response.responseDate,
                    status: lenderele5.data[0].fullerton_response.status,
                    substatus: lenderele5.data[0].fullerton_response.substatus,
                    lenderHeder: lenderele5.data[0].fullerton_response.lenderHeder,
                  }
                  );
              
                }
            }
            if(lenderele5.data[0].Idfc_response !=undefined){
            if(lenderele5.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot5.push(
                {
                  substatus: lenderele5.data[0].Idfc_response.fullerton_lender_id, 
                  errorInfo:  lenderele5.data[0].Idfc_response.errorInfo, 
                  idfc_lender_id: lenderele5.data[0].Idfc_response.idfc_lender_id,
                  responseDate: lenderele5.data[0].Idfc_response.responseDate,
                  sfdc_id: lenderele5.data[0].Idfc_response.sfdc_id,
                  status: lenderele5.data[0].Idfc_response.status, 
                  lenderHeder: lenderele5.data[0].Idfc_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele5.data[0].loanTab_response !=undefined){
            if(lenderele5.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
              this.lenderarrayBot5.push(
                {
                  substatus: lenderele5.data[0].loanTab_response.lenderHeder2, 
                  loantab_lender_id:  lenderele5.data[0].loanTab_response.loantab_lender_id, 
                  status: lenderele5.data[0].loanTab_response.status, 
                  lenderHeder: lenderele5.data[0].loanTab_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele5.data[0].hdbf_response !=undefined){
            if(lenderele5.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot5.push(
                {
                  substatus: lenderele5.data[0].hdbf_response.lenderHeder2, 
                  loantab_lender_id:  lenderele5.data[0].hdbf_response.loantab_lender_id, 
                  status: lenderele5.data[0].hdbf_response.status, 
                  lenderHeder: lenderele5.data[0].hdbf_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele5.data[0].bajaj_response !=undefined){
              if(lenderele5.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot5.push(
                  {
                    bfl_response_id: lenderele5.data[0].bajaj_response.bfl_response_id, 
                    bfl_unique_id:  lenderele5.data[0].bajaj_response.bfl_unique_id, 
                    offerId: lenderele5.data[0].bajaj_response.offerId, 
                    partner_unique_id: lenderele5.data[0].bajaj_response.partner_unique_id,
                  }
                  );
               
                }
              }
        //  console.log(this.lenderarrayWeb5);
        // console.log("fullerton_response",lenderele5.data[0].fullerton_response)  
    
        // console.log("hdbf_response",lenderele5.data[0].hdbf_response)  
        // console.log("bajaj_response",lenderele5.data[0].bajaj_response)  
        // console.log("Idfc_response",lenderele5.data[0].Idfc_response) 
        // console.log("loanTab_response",lenderele5.data[0].loanTab_response) 
         
      }
      this.TransfBOTCoun5 =this.lenderarrayBot5.length;	
      this.TransfWEBCount5 = this.lenderarrayWeb5.length;	

    //  console.log("bot5",this.TransfBOTCoun5);
    //  console.log("web5",this.TransfWEBCount5);
     

     
     
    }
    
   // console.log(this.TransfBOTCount);
  // console.log(this.TransfWEBCount);
  
    });
//**********************************************************start 5nd day  End  ************************************************************ */

//**********************************************************start 6th day    ************************************************************ */

const h = new Date();
h.setMonth(h.getMonth() - 5);
while (h.getMonth() === month) {
  h.setDate(h.getDate() -5);
}
//  console.log(G.toLocaleDateString());
this.SixtartDate=h.toLocaleDateString();

var sixStart=new Date(h.toLocaleDateString());
var sixdDay = new Date(sixStart.getFullYear(), sixStart.getMonth(), 1);
let convSixDaystartdate = moment(sixdDay, 'M/D/YYYY');  
this.SixtartDate = convSixDaystartdate.format('YYYY-MM-DD');



var Six=new Date(h.toLocaleDateString());
var SixMonthEndDay = new Date(Six.getFullYear(), Six.getMonth() + 1, 0);

let Sixend=SixMonthEndDay.toLocaleDateString();
let convSixEndDate = moment(Sixend, 'M/D/YYYY');  
this.SixEndDate = convSixEndDate.format('YYYY-MM-DD');  
this.totallDocRecBot6=0;
this.totalDocRecdweb6=0;
let docReceivedsix={ results: [{"fromdate":this.SixtartDate, "todate":this.SixEndDate}]};
// console.log(trdata);
this.DashboardService.GetdocReceived(docReceivedsix).subscribe(    
(transsix:any) => {

// console.log(trans); return false;
if(transsix.status = true  && transsix.response=== 200){
 for (let i = 0; i < transsix.GetdocReceived.length; i++) {
  const eledocsix = transsix.GetdocReceived[i];
      if(eledocsix.data[0].provider_id  ==="BOT MESSENGER"){
        this.totallDocRecBot6 ++;
      }else if(eledocsix.data[0].provider_id  ==="facebook.com"){
        this.totalDocRecdweb6 ++;	
      }
    
     
   }
 
}



});			



this.totalDocRecdweb6=this.totalDocRecdweb6;
this.totallDocRecBot6=this.totallDocRecBot6;
this.totalDocRecivCont6= (this.totalDocRecdweb6+this.totallDocRecBot6);
let dates6={ results: [{"startdate":this.SixtartDate, "enddate":this.SixEndDate}]};
this.DashboardService.lenderAPIResponse(dates6).subscribe(    
  (lender6:any) => {
    
    if (lender6.status = true  && lender6.response=== 200) {
      let mainLenderArray6 = [],
       apiLenderArray = lender6.lenderApi;
   
  //  console.log(lender);

     apiLenderArray.forEach(element6 => {
      
    
            let    prependObject6 = false
            if (element6.data.length > 0) {
              let newObject6 = element6.data;
            
             
             //  console.log('key', newObject.length);


              for (let i = 0; i < newObject6.length; i++) {
              
                const elementData6 = newObject6[i];
            
                if (Object.keys(elementData6).length>0) {
                  
                  prependObject6 = true
                }
              
              }
              if (prependObject6 ===true) {
                mainLenderArray6.push({data: newObject6})
              }
            }
          
      });

     // console.log(lenderele6.data[0]) 
      for (let i = 0; i < mainLenderArray6.length; i++) {
        const lenderele6 = mainLenderArray6[i];

          // Web
          if(lenderele6.data[0].fullerton_response !=undefined){
            if(lenderele6.data[0].fullerton_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb6.push(
                {
                  fullerton_lender_id: lenderele6.data[0].fullerton_response.fullerton_lender_id, 
                  hubble_id:  lenderele6.data[0].fullerton_response.hubble_id, 
                  responseDate: lenderele6.data[0].fullerton_response.responseDate,
                  status: lenderele6.data[0].fullerton_response.status,
                  substatus: lenderele6.data[0].fullerton_response.substatus,
                  lenderHeder: lenderele6.data[0].fullerton_response.lenderHeder,
                }
                );
            
              }
          }
          if(lenderele6.data[0].Idfc_response !=undefined){
          if(lenderele6.data[0].Idfc_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb6.push(
              {
                substatus: lenderele6.data[0].Idfc_response.fullerton_lender_id, 
                errorInfo:  lenderele6.data[0].Idfc_response.errorInfo, 
                idfc_lender_id: lenderele6.data[0].Idfc_response.idfc_lender_id,
                responseDate: lenderele6.data[0].Idfc_response.responseDate,
                sfdc_id: lenderele6.data[0].Idfc_response.sfdc_id,
                status: lenderele6.data[0].Idfc_response.status, 
                lenderHeder: lenderele6.data[0].Idfc_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele6.data[0].loanTab_response !=undefined){
          if(lenderele6.data[0].loanTab_response.provider_id ==="facebook.com"){
            this.lenderarrayWeb6.push(
              {
                substatus: lenderele6.data[0].loanTab_response.lenderHeder2, 
                loantab_lender_id:  lenderele6.data[0].loanTab_response.loantab_lender_id, 
                status: lenderele6.data[0].loanTab_response.status, 
                lenderHeder: lenderele6.data[0].loanTab_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele6.data[0].hdbf_response !=undefined){
          if(lenderele6.data[0].hdbf_response.provider_id ==="facebook.com" ){
            this.lenderarrayWeb6.push(
              {
                substatus: lenderele6.data[0].hdbf_response.lenderHeder2, 
                loantab_lender_id:  lenderele6.data[0].hdbf_response.loantab_lender_id, 
                status: lenderele6.data[0].hdbf_response.status, 
                lenderHeder: lenderele6.data[0].hdbf_response.lenderHeder,
              }
              );
           
            }
          }
          if(lenderele6.data[0].bajaj_response !=undefined){
            if(lenderele6.data[0].bajaj_response.provider_id ==="facebook.com" ){
              this.lenderarrayWeb6.push(
                {
                  bfl_response_id: lenderele6.data[0].bajaj_response.bfl_response_id, 
                  bfl_unique_id:  lenderele6.data[0].bajaj_response.bfl_unique_id, 
                  offerId: lenderele6.data[0].bajaj_response.offerId, 
                  partner_unique_id: lenderele6.data[0].bajaj_response.partner_unique_id,
                }
                );
             
              }
            }
            //Bot
            if(lenderele6.data[0].fullerton_response !=undefined){
              if(lenderele6.data[0].fullerton_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot6.push(
                  {
                    fullerton_lender_id: lenderele6.data[0].fullerton_response.fullerton_lender_id, 
                    hubble_id:  lenderele6.data[0].fullerton_response.hubble_id, 
                    responseDate: lenderele6.data[0].fullerton_response.responseDate,
                    status: lenderele6.data[0].fullerton_response.status,
                    substatus: lenderele6.data[0].fullerton_response.substatus,
                    lenderHeder: lenderele6.data[0].fullerton_response.lenderHeder,
                  }
                  );
              
                }
            }
            if(lenderele6.data[0].Idfc_response !=undefined){
            if(lenderele6.data[0].Idfc_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot6.push(
                {
                  substatus: lenderele6.data[0].Idfc_response.fullerton_lender_id, 
                  errorInfo:  lenderele6.data[0].Idfc_response.errorInfo, 
                  idfc_lender_id: lenderele6.data[0].Idfc_response.idfc_lender_id,
                  responseDate: lenderele6.data[0].Idfc_response.responseDate,
                  sfdc_id: lenderele6.data[0].Idfc_response.sfdc_id,
                  status: lenderele6.data[0].Idfc_response.status, 
                  lenderHeder: lenderele6.data[0].Idfc_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele6.data[0].loanTab_response !=undefined){
            if(lenderele6.data[0].loanTab_response.provider_id ==="BOT MESSENGER"){
              this.lenderarrayBot6.push(
                {
                  substatus: lenderele6.data[0].loanTab_response.lenderHeder2, 
                  loantab_lender_id:  lenderele6.data[0].loanTab_response.loantab_lender_id, 
                  status: lenderele6.data[0].loanTab_response.status, 
                  lenderHeder: lenderele6.data[0].loanTab_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele6.data[0].hdbf_response !=undefined){
            if(lenderele6.data[0].hdbf_response.provider_id ==="BOT MESSENGER" ){
              this.lenderarrayBot6.push(
                {
                  substatus: lenderele6.data[0].hdbf_response.lenderHeder2, 
                  loantab_lender_id:  lenderele6.data[0].hdbf_response.loantab_lender_id, 
                  status: lenderele6.data[0].hdbf_response.status, 
                  lenderHeder: lenderele6.data[0].hdbf_response.lenderHeder,
                }
                );
             
              }
            }
            if(lenderele6.data[0].bajaj_response !=undefined){
              if(lenderele6.data[0].bajaj_response.provider_id ==="BOT MESSENGER" ){
                this.lenderarrayBot6.push(
                  {
                    bfl_response_id: lenderele6.data[0].bajaj_response.bfl_response_id, 
                    bfl_unique_id:  lenderele6.data[0].bajaj_response.bfl_unique_id, 
                    offerId: lenderele6.data[0].bajaj_response.offerId, 
                    partner_unique_id: lenderele6.data[0].bajaj_response.partner_unique_id,
                  }
                  );
               
                }
              }
        //  console.log(this.lenderarrayWeb6);
        // console.log("fullerton_response",lenderele6.data[0].fullerton_response)  
    
        // console.log("hdbf_response",lenderele6.data[0].hdbf_response)  
        // console.log("bajaj_response",lenderele6.data[0].bajaj_response)  
        // console.log("Idfc_response",lenderele6.data[0].Idfc_response) 
        // console.log("loanTab_response",lenderele6.data[0].loanTab_response) 
         
      }
      this.TransfBOTCoun6 =this.lenderarrayBot6.length;	
      this.TransfWEBCount6 = this.lenderarrayWeb6.length;	

    //  console.log("bot6",this.TransfBOTCoun6);
     // console.log("web6",this.TransfWEBCount6);
     

     
     
    }
    
   // console.log(this.TransfBOTCount);
  // console.log(this.TransfWEBCount);
  
    });
















 
  
         
var ourDate = new Date();
//Change it so that it is 7 days in the past.
var pastDate = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
//ourDate.setDate(pastDate);
let lastseveDay=ourDate;

let date = new Date();
// adjust 0 before single digit date
let cdates = ("0" + date.getDate()).slice(-2);
// current month
let cmonth = ("0" + (date.getMonth() + 1)).slice(-2);
// current year
let year = date.getFullYear();
this.currentdate =(year + "-" + cmonth + "-" + cdates);
//console.log(this.currentdate); 
//let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
// adjust 0 before single digit date
let datesFirstDay = ("0" + pastDate.getDate()).slice(-2);
// current month
let monthFirstDay = ("0" + (pastDate.getMonth() + 1)).slice(-2);
// current year
let yearFirstDay = pastDate.getFullYear();
// this.FirstDaydate ="2021-07-26";
//this.FirstDaydate = new Date(date.getFullYear(), date.getMonth(), 1);

this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);


this.registerForm = this.formBuilder.group({			
  filterchart: this.formBuilder.group({
    
    datefrom: ['', Validators.required],
    dateto: ['', Validators.required],
  }),
  
  });
  this.leadsService
  .userCampain()
  .subscribe( (result:any) => {


    if (result.status = true  && result.response=== 200) {      //        
    for (let i = 0; i < result.iapc.length;  i++) {
      const element = result.iapc[i];
    
     
      this.elements.push(
      {
        user_id: element.data[0].user_id, 
        provider_id: element.data[0].provider_id, 
        app_stage: element.data[0].app_stage, 
        elig_status: element.data[0].elig_status, 
        iapdate: element.data[0].iapdate, 
        
      }
      );
          
     
              

      }

      const mainarray= this.elements;
     


      this.result1 = mainarray.filter(d => {
        const  reg_date= d.iapdate;
        const regdate = reg_date.split(' ')[0];
        return (this.FirstDaydate  <= regdate && regdate  <=  this.currentdate );            
      
        
      });

        this.totaliap=this.result1.length;
           // doc complete
        let docrec={ results: [{"fromdate":this.FirstDaydate, "todate":this.currentdate}]};
        // console.log(trdata);
        this.DashboardService.GetdocReceived(docrec).subscribe(    
        (doc:any) => {
        
        // console.log(trans); return false;
        if(doc.status = true  && doc.response=== 200){
         for (let i = 0; i < doc.GetdocReceived.length; i++) {
          const ucamp = doc.GetdocReceived[i];
          //console.log(ucamp);
            //doc complete
            if(ucamp.data[0].stage === "21"){
              
              this.doccomplete.push({ status: ucamp.data[0].stage});
              
            }
             // doc complete but camp na
             if(ucamp.data[0].campain_name === "NA" && ucamp.data[0].campain_id === "NA"  ){
      
              this.Direct.push({ status: ucamp.data[0].stage});
              
            }
            if(ucamp.data[0].stage === "21" && ucamp.data[0].campain_id === "29"  ){
      
              this.Direct.push({ status: ucamp.data[0].stage});
              
            }
             // dpdm1
            if(ucamp.data[0].campain_id === "1" && ucamp.data[0].stage === "21"){
    
              this.dpdm1.push({ status: ucamp.data[0].stage});
              
            }
             // dpdm2
             if(ucamp.data[0].campain_id === "2" && ucamp.data[0].stage === "21"){
    
              this.dpdm2.push({ status: ucamp.data[0].stage});
              
            }
              // dpdm3
              if(ucamp.data[0].campain_id === "3" && ucamp.data[0].stage === "21"){
    
                this.dpdm3.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm4
              if(ucamp.data[0].campain_id === "4" && ucamp.data[0].stage === "21"){
    
                this.dpdm4.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm5
              if(ucamp.data[0].campain_id === "5" && ucamp.data[0].stage === "21"){
    
                this.dpdm5.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm6
              if(ucamp.data[0].campain_id === "6" && ucamp.data[0].stage === "21"){
    
                this.dpdm6.push({ status: ucamp.data[0].stage});
                
              }
               // dpdm7
               if(ucamp.data[0].campain_id === "7" && ucamp.data[0].stage === "21"){
    
                this.dpdm7.push({ status: ucamp.data[0].stage});
                
              }
             
           }
           this.totalDoccomplete=this.doccomplete.length;
                  // doc complete but camp na
                  this.Directtotal=this.Direct.length;
                  // dpdm1
                  this.totaldpdm1=this.dpdm1.length;
                  // dpdm2
                  this.totaldpdm2=this.dpdm2.length;
                  // dpdm3
                  this.totaldpdm3=this.dpdm3.length;
                  // dpdm4
                  this.totaldpdm4=this.dpdm4.length;
                  // dpdm5
                  this.totaldpdm5=this.dpdm5.length;
                  //dpdm6
                  this.totaldpdm6=this.dpdm6.length;
                  //dpdm7
                  this.totaldpdm7=this.dpdm7.length;
         
        }
        
        
        
        });	














         

       
   


        

        
      
        

      
      }else{
        console.log('User list Api faile');
      }
      
      });
     
























    
    
  }


  chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }

    filter_chart(){
      const date_from =new Date(this.filterchart.value.datefrom);
      const date_to   =new Date(this.filterchart.value.dateto);
      var today = new Date();
      var dd = today.getDate()+1;
      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      const  todays =new Date(yyyy+'-'+mm+'-'+dd);
      const fixeddate=new Date("2021-07-28");
      
    
     if(fixeddate <= date_from  && todays >= date_to){     
                        
                        //  console.log(this.date_from);
                        // console.log(this.date_to); return false;
                          this.totalDoccomplete=0;
                          this.totaliap  =0;
                          this.totaldpdm1=0;
                          this.totaldpdm2=0;
                          this.totaldpdm3=0;
                          this.totaldpdm4=0;
                          this.totaldpdm5=0;
                          this.totaldpdm6=0;
                          this.totaldpdm7=0;
                          this.dpdm1.length=0;
                          this.dpdm2.length=0;
                          this.dpdm3.length=0;
                          this.dpdm4.length=0;
                          this.dpdm5.length=0;
                          this.dpdm6.length=0;
                          this.dpdm7.length=0;
                          this.doccomplete.length=0;
                          this.Direct.length=0;
                          this.Directtotal=0;
                          this.date_from =this.filterchart.value.datefrom;
                          this.date_to   =this.filterchart.value.dateto;
                        //  console.log(this.date_from);
                        // console.log(this.date_to);
                          const mainarray= this.elements;
                      
                          this.result2 = mainarray.filter(d => {
                            const  reg_date= d.iapdate;
                            const regdate = reg_date.split(' ')[0];
                            return (this.date_from  <= regdate && regdate  <=  this.date_to );            
                          
                            
                            });
                            this.totaliap=this.result2.length;
     	 
                          // doc complete
                         // doc complete
        let docrec={ results: [{"fromdate":this.date_from, "todate":this.date_to}]};
        // console.log(trdata);
        this.DashboardService.GetdocReceived(docrec).subscribe(    
        (doc:any) => {
        
        // console.log(trans); return false;
        if(doc.status = true  && doc.response=== 200){
         for (let i = 0; i < doc.GetdocReceived.length; i++) {
          const ucamp = doc.GetdocReceived[i];
              
            //doc complete
            if(ucamp.data[0].stage === "21"){
              
              this.doccomplete.push({ status: ucamp.data[0].stage});
              
            }
             // doc complete but camp na
             if(ucamp.data[0].campain_name === "NA" && ucamp.data[0].campain_id === "NA" ){
      
              this.Direct.push({ status: ucamp.data[0].stage});
              
            }
             // dpdm1
            if(ucamp.data[0].campain_id === "1" && ucamp.data[0].stage === "21"){
    
              this.dpdm1.push({ status: ucamp.data[0].stage});
              
            }
             // dpdm2
             if(ucamp.data[0].campain_id === "2" && ucamp.data[0].stage === "21"){
    
              this.dpdm2.push({ status: ucamp.data[0].stage});
              
            }
              // dpdm3
              if(ucamp.data[0].campain_id === "3" && ucamp.data[0].stage === "21"){
    
                this.dpdm3.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm4
              if(ucamp.data[0].campain_id === "4" && ucamp.data[0].stage === "21"){
    
                this.dpdm4.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm5
              if(ucamp.data[0].campain_id === "5" && ucamp.data[0].stage === "21"){
    
                this.dpdm5.push({ status: ucamp.data[0].stage});
                
              }
              // dpdm6
              if(ucamp.data[0].campain_id === "6" && ucamp.data[0].stage === "21"){
    
                this.dpdm6.push({ status: ucamp.data[0].stage});
                
              }
               // dpdm7
               if(ucamp.data[0].campain_id === "7" && ucamp.data[0].stage === "21"){
    
                this.dpdm7.push({ status: ucamp.data[0].stage});
                
              }
             
           }
           this.totalDoccomplete=this.doccomplete.length;
                  // doc complete but camp na
                  this.Directtotal=this.Direct.length;
                  // dpdm1
                  this.totaldpdm1=this.dpdm1.length;
                  // dpdm2
                  this.totaldpdm2=this.dpdm2.length;
                  // dpdm3
                  this.totaldpdm3=this.dpdm3.length;
                  // dpdm4
                  this.totaldpdm4=this.dpdm4.length;
                  // dpdm5
                  this.totaldpdm5=this.dpdm5.length;
                  //dpdm6
                  this.totaldpdm6=this.dpdm6.length;
                  //dpdm7
                  this.totaldpdm7=this.dpdm7.length;
         
        }
        
        
        
        });
       
                                
          }else{
            const from="2021-07-28";
            const  to =yyyy+'-'+mm+'-'+dd;
           alert("Date range should be "+from+" AND "+to+" ");
          }
       
    }
//end
  getLeadDetails(data,data1,data2,data3){
    let formdate=data;
    let todate=data1;
    let source=data2;
    let aapstage=data3;
    // console.log(data);
    // console.log(data1);
    // console.log(data2);
    // console.log(data3);
     // console.log(data);
     //this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMIStage-Transfer?fromdate=";        
     const detailsurl = base_url + formdate.toString();
     const dateur=detailsurl+"&todate="+todate.toString()+"&source="+source.toString()+"&aapstage="+aapstage.toString();
    // console.log(dateur);
     window.open(dateur, "_blank");
  }
}

function datestr(date: any, formatOut: string) {
  throw new Error('Function not implemented.');
}
