import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable } from 'rxjs';
import { Role } from 'src/app/model/role/role';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { BusinessPartnerService } from 'src/app/services/business-partner/business-partner.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  
  user:any;
  sub: Subscription;

  isValue: number=0;
  followupStageCount: any=0;
  PendingfeedbackCount: any=0;
  IApPendingfeedbackCount: any=0;
  EligPendingfeedbackCount: any=0;
  currentdate: string;
  userdate: string;
  elements: any=[];
  arrayelement: any=[];
  elements_elig: any=[];
  elig_Pendingfeedback_elements: any=[];
  iap_Pendingfeedback_elements: any=[];
  follow_update: any;
  stage_color: string;
  appstage: string;
  urlstage: string;
  elig_status: string;
  follwup_elig: any=0;
  provider: string;
  lender_name: string;
  element_main: any=[];
  iap_pendingCount:number=0;
  getContactRequest:number=0;
  
  totalUserSelfEmp: number=0;
  totalUserProfessional: number=0;
  totalpartner: number=0;
  rolecode: any;
  loginAdmin: any;
  adminname: any;
  webselfemp: any=[];
  ctwaSelfEmp: any=[];
  totalUserctwaSelfEmp: number=0;
  constructor(
    public afAuth: AngularFireAuth,
    public router:Router,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private FeedbackService:FeedbackService,  private leadsService:LeadsService,
    private SelfEmpLeadService:SelfEmpLeadService,
    private BusinessPartnerService:BusinessPartnerService,
    private cryptojsService:CryptojsService,
    ) { }

  ngOnInit() {
    
    this.FeedbackService
    .GetContactRequest()
    .subscribe( (result:any) => {  
      //console.log(result);  
            if (result.status = 'true' && result.response===200) {
              for (let i = 0; i < result.contact.length; i++) {
                const element = result.contact[i];
               // console.log(element);
                
           if(element.data[0].provider_id == "BOT MESSENGER"){
             this.provider = 'BOT';
             
           }else{
             this.provider = 'WEB';
           }
                this.arrayelement.push(
                  {
                    user_id: element.data[0].user_id, 
                    first_name:  element.data[0].first_name, 
                    last_name:  element.data[0].last_name,              
                    provider: this.provider,
                    date:  element.data[0].date,     
                    email_address:  element.data[0].email_address, 
                    mobile_number:  element.data[0].mobile_number,   
                  }
                  );
            
               
                  
                  
            }
            this.getContactRequest= this.arrayelement.length;
              
            }
            
          });


         
              this.SelfEmpLeadService
              .getAllLeads()
              .subscribe( (result:any) => {
             // console.log(result);
              
                if (result.status = true  && result.response=== 200) {      //    
                  for (let i = 0; i < result.selfemp.length;  i++) {
                      const element = result.selfemp[i];   
                      if(element.data[0].provider_id  == "facebook.com"){
                        this.provider = 'WEB';
                        
                      }else {
                        this.provider = 'BOT';
                      } 
                    
                        this.webselfemp.push(
                          {
                            user_id: element.data[0].user_id, 
                            provider: this.provider,
                            city_name: element.data[0].location,
                            loan_amount: element.data[0].loan_amount,
                            ad_id: element.data[0].ad_id,
                            reg_date: element.data[0].date,
                            status: this.appstage,
                            feedbackstage:this.urlstage,                                                                                
                            user_monthly_income:element.data[0].user_monthly_income,
                            empcategory:element.data[0].salaried,
                            Campaign	:element.data[0].campain_name,
                            mobileNumber	:element.data[0].mobile_number
                            
                          }
                          );
                       
                       

                          
                }
                  this.totalUserSelfEmp=this.webselfemp.length;
                 
                }
                });   


                this.SelfEmpLeadService
                .getAllLeadsCTWA()
                .subscribe( (result:any) => {
               // console.log(result);
                
                  if (result.status = true  && result.response=== 200) {      //    
                    for (let i = 0; i < result.ctwa.length;  i++) {
                        const element = result.ctwa[i];   
                         if(element.data[0].provider_id  == "whatsapp"){
                          this.provider = 'Whatsapp';
                        }
                       
                      
                            this.ctwaSelfEmp.push(
                              {
                                user_id: element.data[0].user_id, 
                                provider: this.provider,
                                city_name: element.data[0].location,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,                                                                                
                                user_monthly_income:element.data[0].user_monthly_income,
                                empcategory:element.data[0].salaried,
                                Campaign	:element.data[0].campain_name,
                                mobileNumber	:element.data[0].mobile_number
                                
                              }
                              );
                             
  
                            
                  }
                   
                    this.totalUserctwaSelfEmp=this.ctwaSelfEmp.length;
                  }
                  });  



                this.SelfEmpLeadService
                .getProfessionalAllLeads()
                .subscribe( (result:any) => {
               
                  //console.log(result);
                  if (result.status = true  && result.response=== 200) {         
                    this.totalUserProfessional=result.selfemp.length;
                  }
                    });
    this.BusinessPartnerService
    .getAllbusinessPartner()
    .subscribe( (result:any) => {
   // console.log(result);
    
      if (result.status = true  && result.response=== 200) {      
        this.totalpartner=result.data[0].partner.length;        
        
      }
        });
    //console.log(nextDay);
    this.sub = this.authState$.subscribe( user => {

      this.user = user;
      if(this.user){
          // console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.adminname=this.user.first_name;
            //console.log(this.user.first_name);
          }
        )
      }
     
    
    });

   // this.decryptData = this.cryptojsService.decryptData(this.data);
 

  }
  toggle1() { 
    this.isValue = 1;
     
  }
  toggle2() { 
    this.isValue = 2;
    
  }
  toggle3() { 
    this.isValue = 3; 
    
  }
  
  get authenticated() {
    return this.user !== null;
  }
  rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  mis_user(event){   
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{      
      event.srcElement.classList.remove("tooltiptext");
      location.reload();
    },0)
  }
  support(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  chat(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  logout(){
    this.afAuth.auth.signOut();
    
 //   localStorage.removeItem('currentUser');
   // localStorage.removeItem('token_rm');
    this.cookieService.delete('currentUser');
    this.cookieService.delete('token_rm');
    this.router.navigate(['login']);

  }

}
