import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';

import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';

export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-criteria-fail',
    templateUrl: './meraemi-criteria-fail.component.html',
    styleUrls: ['./meraemi-criteria-fail.component.scss']
})
export class MeraemiCriteriaFailComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id"
     },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
       name:"Loan Amount",
       sortBy:"Amount"
     },
     
     {
       name:"Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
     {
      name:"Dropoff Msg",
      sortBy:"sys_user_dropoffs_msg"
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     {
       name:"View",
       sortBy:"send_message"
     },

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup; 
  submitted = false;
  from_user_id: any;
  to_user_id: any;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  messages: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  question: string;
  answer: string;

  constructor( 
      private cryptojsService:CryptojsService,
      //private firebasedataService:FirebasedataService,      
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,) { }

  ngOnInit() {

          this.leadsService
        .GetCriteriaFail()
          .subscribe( (result:any) => {
          // console.log(result);
            if (result.status = 'true') {              
              for (let i = 0; i < result.contact.length; i++) {
                const element = result.contact[i];
                this.elig_status ='Criteria Fail';
                this.urlstage ='Criteria_Fail';
                this.stage_color = "info-color";
                this.provider = 'BOT';
                if(element.data[0].bot_stage_name ==="Mobile Number"){
                  this.question =element.data[0].bot_stage_name;
                  this.answer =element.data[0].mobileno;
                }
                this.elements.push(
                  {
                    user_id: element.data[0].user_id, 
                    first_name:  element.data[0].first_name, 
                    last_name:  element.data[0].last_name,              
                    provider: this.provider,
                    city_name: element.data[0].location,
                    reg_date: element.data[0].dropoffdate,
                    mobileno: element.data[0].mobileno,
                    email: element.data[0].email,
                    status: this.elig_status,
                    feedbackstage:this.urlstage,
                    loan_amount: element.data[0].loan_amount,
                    ad_id: element.data[0].ad_id,
                    bot_stage_name: element.data[0].bot_stage_name,
                    usr_dropoffs_msg: element.data[0].sys_user_dropoffs_msg,
                  }
                  );
          
              }
             // console.log(this.elements);
              this.mdbTable.setDataSource(this.elements);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
          });
   
         
   
    
    
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
 

 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
   
    
     
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
    //user Details
    getLeadDetails(user,data){
      let stage=  data;
     // console.log(data);
     // console.log(user);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/Mmeraemi-criteria-fail-details?data=";        
     const detailsurl = base_url + this.useid.toString();
     const dateur=detailsurl+"&stage="+stage.toString();
    
     window.open(dateur, "_blank");
 }
}


