import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from 'src/app/services/uplods/upload.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Subscription, Observable, empty } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
 // selector: 'app-doc-uplods',
  selector: 'app-file-upload',
  templateUrl: './doc-uplods.component.html',
  styleUrls: ['./doc-uplods.component.scss']
})
export class DocUplodsComponent implements OnInit {
  @ViewChild("docVeryFiNo", {static: true}) docVeryFiNo: ModalDirective;
  @ViewChild("filepath", {static: true}) filepath: ModalDirective;
 

  optionsSelect = [
    { value: '1', label: 'Transaction 1' },
    { value: '2', label: 'Transaction 2' },
    { value: '3', label: 'Transaction 3' },
    { value: '4', label: 'Transaction 4' },
    { value: '5', label: 'Transaction 5' },
    { value: '6', label: 'Transaction 6' }
]

  selectedFile: File | null = null;
  uploadedFiles: any[] = [];
  isFileSelected = false;
  filePreview: string | ArrayBuffer | null = null; // For file preview
  slika: any;
  imgSelect: number=0;
  imgDelete: number=0;
  docidArray: any = [];
  docid: any = []; 
  docCatArray: any = [];
  alldocListion:any = []; 
  catgid: any = [];
  docId: any;
  docname: any;
  inputfordivActive: number=0;
  selectFile: any;
  uplodsFile: any;
  selectedFiles: any;
  currentFile: any;
  localUrl: String;
  addSftpModel: any;
  category = { filepath: '' }; // Initialize an object to hold the file path
  loan_code: string;
  user_id: string;
  loan_id: string;
  sub: any;
  user:any;
  loginAdmin: any;
  rolecode: any;
  data: any;
  decryptData: any;
  driveurl: any;
  deleteFileNme: any;
  deleteResponseId: any;
  pdfSrc: any;
  doclabel: any;
  pdfpass: number;
  loancode: any;
  loanid: any;
  docTypeId: any;
  catid: any;
  pdfpassDiv: number=0;
  pdfpassVal: string;
  pass: string;
  constructor(
     fb: FormBuilder,
     private uploadService:UploadService,
     private leadsService:LeadsService,
     private http: HttpClient, 
     public afAuth: AngularFireAuth, 
     private firebasedataService:FirebasedataService,   
     private cryptojsService:CryptojsService,
     private route:ActivatedRoute,    
     private router:Router,
     private authService:AuthenticationService,
     public cookieService:CookieService,private sanitizer: DomSanitizer) {

    
    }
    
      ngOnInit() {//this.fetchUploadedFiles();
        
           //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
       // console.log(this.user);
          //  console.log(this.afAuth.auth.currentUser)
        //  this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
          //console.log(this.user);
          }
        )
      }
    
    
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
       // const  loancode =  params.loancode.replace(/\s/g, "+");
        this.decryptData = this.cryptojsService.decryptData(this.data);
        //console.log(this.decryptData);
        //console.log(params.loanid);
        this.loancode=params.loancode;
        this.loanid=params.loanid;
   
    });
    this.uploadService
    .documentListing(this.decryptData)
    .subscribe( (result:any) => {
      //console.log(result);
      if (result.status = 'true' && result.response !=   400) {
   
        for (let i = 0; i < result.uplodsDocListing.length; i++) {
          const menuarray = result.uplodsDocListing[i];
             
          const inputString = menuarray.data[0].fileName;
          const delimiter = ".";
          const stringArray = inputString.split(delimiter);
          //console.log(stringArray[1]);
             this.alldocListion.push(
              {
                doc_response_id: menuarray.data[0].doc_response_id,
                category_name: menuarray.data[0].category_name,
                created_on: menuarray.data[0].created_on,  
                doc_name: menuarray.data[0].doc_name,   
                fileName: menuarray.data[0].fileName,
                filePassword: menuarray.data[0].filePassword,   
                loan_code: menuarray.data[0].loan_code,      
                loan_id: menuarray.data[0].loan_id,     
                user_id: menuarray.data[0].user_id,  
                docType: stringArray[1],                    
               
              }
              

              );
        }
      }
      
    });

    
       
        this.uploadService
        .getDocCategory()
        .subscribe( (result:any) => {
       //console.log(result);return false;
                if (result.status = 'true') {
                  

                  for (let i = 0; i < result.docCat.length; i++) {
                    const menuarray = result.docCat[i];
                   //console.log(menuarray.data[0].pincode);
                  
                    this.docCatArray.push(
                      {
                        value: menuarray.data[0].doc_catg_id,
                        label: menuarray.data[0].document_category,                          
                       
                      }
                      );
                      //console.log(this.messages);
                  
                  
                     
                      
                  }
                  this.catgid =this.docCatArray;
                  //console.log(this.optionslocation);
                }else{
                  console.log('Doc Cat fail Api faile');
                }
                
              });
      }

      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }




















      getDocCatId(event) {
        this.docidArray = [];
        this.docid = [];
        this.docId=event.value;
        this.docname=event.label
        this.uploadService
        .getDocId(this.docId)
        .subscribe( (result:any) => {
         
          if (result.status = 'true') {
                  

            for (let i = 0; i < result.docid.length; i++) {
              const menuarray = result.docid[i];
             //console.log(menuarray.data[0].pincode);
            
              this.docidArray.push(
                {
                  value: menuarray.data[0].doc_id,
                  label: menuarray.data[0].dscription,                          
                 
                }
                );
                //console.log(this.messages);
            
            
               
                
            }
            this.docid =this.docidArray;
            console.log(this.docid);
          }else{
            console.log('Doc id fail Api faile');
          }
        });
       // console.log(event.value);
      }
      getDocid(event) {
      this.doclabel=event.label;
      this.docTypeId=event.value;

        console.log(event);
        if(event.value =="25"){
          this.inputfordivActive=1;
        }else{
          this.inputfordivActive=0;
        }
        if(event.value =="6"){
          this.pdfpass=1;
        }else{
          this.pdfpass=0;
        }
        console.log(event.value);
      }
 


      previewFile(cat,filename,){
        const requestData = {
          loan_code: this.loancode,
          fileName: cat,
          user_id: this.decryptData    
           };
       
        this.uploadService.docUserDocDetails(requestData).subscribe(
          (data) => {
            console.log(data);  return false;
          });
       }
      
        deleteFile(cat,filename,response_id){
        this.docVeryFiNo.show();
        this.deleteFileNme=filename;
        this.deleteResponseId=response_id;
        this.catid=cat;
        }
        docDeleteSubmit(){
          this.docVeryFiNo.hide();
          const requestData = {
            user_id: this.decryptData ,
            loan_code: this.loancode,
            docType:   this.catid,
            fileName: this.deleteFileNme,
            response_id: this.deleteResponseId,
            
           
             };
         
          this.uploadService.docDeletefile(requestData).subscribe(
            (data) => {
              if(data.response == 200 && data.status==true){
                this.alldocListion.length=0;
                this.uploadService
                .documentListing(this.decryptData)
                .subscribe( (result:any) => {
                  if (result.status = 'true') {
                    for (let i = 0; i < result.uplodsDocListing.length; i++) {
                      const menuarray = result.uplodsDocListing[i];
                         
                      const inputString = menuarray.data[0].fileName;
                      const delimiter = ".";
                      const stringArray = inputString.split(delimiter);
                      //console.log(stringArray[1]);
                         this.alldocListion.push(
                          {
                            doc_response_id: menuarray.data[0].doc_response_id,
                            category_name: menuarray.data[0].category_name,
                            created_on: menuarray.data[0].created_on,  
                            doc_name: menuarray.data[0].doc_name,   
                            fileName: menuarray.data[0].fileName,
                            filePassword: menuarray.data[0].filePassword,   
                            loan_code: menuarray.data[0].loan_code,      
                            loan_id: menuarray.data[0].loan_id,     
                            user_id: menuarray.data[0].user_id,  
                            docType: stringArray[1],                    
                           
                          }
                          
            
                          );
                    }
                  }
                  
                });
              }else{
                            console.log(data);  return false;
              }
             
            });
        }
        docDeleteCancle(){
          this.docVeryFiNo.hide();
        }
    
       
     
      //work file
       downlods(doc_name,filename){
        const requestData = {
          user_id: this.decryptData ,
          loan_code:this.loancode,
          docType:   doc_name,
          fileName: filename
          
         
           };
       
        this.uploadService.docdownloadFilefile(requestData).subscribe(
          (data) => {
            if(data.response==200 && data.status==true){
              const dateur=data.docDownlods.toString();

                //console.log(dateur);
                const blob = new Blob([dateur], { type: 'application/pdf' });

                // Use FileSaver.js to trigger the download
                FileSaver.saveAs(blob, filename);
             

            }else{
              console.log(data);  return false;
            }
            
          });
       }
    
   

       docpass(inputValue: string) {
        this.pdfpassVal=inputValue;
        
        // Access the input value
       
      }


    onFileSelected(event: any) {
      const file: File = event.target.files[0];
      this.selectedFile = file;
      
      if(file.type ==='image/png' || file.type ==='image/jpeg' ){
        this.pdfpassDiv=0;
        // this.localUrl=event.target.value;
         if (event.target.files && event.target.files[0]) {
        
         var reader = new FileReader();
         reader.onload = (event: any) => {
          
             this.slika = event.target.result;
            
         }
       }
       console.log('Alert img file type.'+this.slika);
       }else if (file.type === 'application/pdf') {
        this.pdfpassDiv=1;
        
 





         //this.localUrl=event.target.value;
         if (event.target.files && event.target.files[0]) {
          this.driveurl =  this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result); 
           var reader = new FileReader();
           reader.onload = (event: any) => {
            
               this.driveurl = event.target.result;
               let stage= event.target.result;
             //  console.log(dateur);
            
               
                 const objectUrl: string = URL.createObjectURL(file);
                 this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
             
           }
         }else {
          // Handle non-PDF file selection
          this.pdfSrc = null;
        }
         console.log('Alert PDF file type.');
       } else {
         console.log('Unsupported file type.');
       }
        reader.readAsDataURL(event.target.files[0]);
    }
   
    uploadFile() {
      if(this.pdfpassVal !=undefined){
        this.pass=this.pdfpassVal
      }else{
        this.pass="na"
       }
      
    
        
     const files =this.selectedFile;
     const fileName = files.name;
    // console.log(fileName);  return false;
     // return false;
      const formData = new FormData();
      formData.append('file', files);
      formData.append('user_id', this.decryptData);
      formData.append('doc_id', this.docTypeId);
      formData.append('loan_id',  this.loanid);
      formData.append('loan_code',  this.loancode);
      formData.append('doc_type', this.doclabel);
      formData.append('file_password', this.pass);
      formData.append('fileName', fileName);
      formData.append('catid', this.docId);
      
      console.log("files"+files); 
      console.log("this.decryptData"+this.decryptData);
      console.log("this.docTypeId"+this.docTypeId);
      console.log("this.loanid"+this.loanid);
      console.log("this.loancode"+this.loancode);
      console.log("this.doclabel"+this.doclabel);
      console.log("this.pass"+this.pass);
      console.log("fileName"+this.pass);
      console.log("fileName"+fileName);
      console.log("docId"+this.docId);
    //  return false;
      this.uploadService.docUplods(formData).subscribe(
        (data) => {
          console.log("data"+data);
          this.pdfSrc=null;
          this.slika=null;
          this.imgDelete=0;
          this.imgSelect=0;
          this.alldocListion.length=0;
          this.uploadService
          .documentListing(this.decryptData)
          .subscribe( (result:any) => {
            if (result.status = 'true') {
              for (let i = 0; i < result.uplodsDocListing.length; i++) {
                const menuarray = result.uplodsDocListing[i];
                   
                const inputString = menuarray.data[0].fileName;
                const delimiter = ".";
                const stringArray = inputString.split(delimiter);
                //console.log(stringArray[1]);
                   this.alldocListion.push(
                    {
                      category_name: menuarray.data[0].category_name,
                      created_on: menuarray.data[0].created_on,  
                      doc_name: menuarray.data[0].doc_name,   
                      fileName: menuarray.data[0].fileName,
                      filePassword: menuarray.data[0].filePassword,   
                      loan_code: menuarray.data[0].loan_code,      
                      loan_id: menuarray.data[0].loan_id,     
                      user_id: menuarray.data[0].user_id,  
                      docType: stringArray[1],                    
                     
                    }
                    
      
                    );
              }
            }
            
          });
          console.log(data);  return false;
        });
     
    }

    removeFile(){
      this.pdfSrc=null;
      this.slika=null;
      this.imgDelete=0;
      this.imgSelect=0;
    }



























  
    
    
    FileSubmit(){

      this.imgSelect=1;
      this.imgDelete=1;
    }
 
}
