import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class CryptojsService {
  encryptSecretKey = "MPFiOtRx30gKAPnXYbFtv9n8Z8AgymNL";

  constructor() { 

  }
  encryptData(data) {
 
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
      return 'false';
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
     //console.log(bytes);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
   //   return data;
      return 'false';
    } catch (e) {
      console.log(e);
      return 'false';

    }
  }
}
