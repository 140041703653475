import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-mera-emi-ctwa-lead-listing',
  templateUrl: './mera-emi-ctwa-lead-listing.component.html',
  styleUrls: ['./mera-emi-ctwa-lead-listing.component.scss']
})
export class MeraEMICTWALeadListingComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  @ViewChild("fromdate", {static: true}) fromdate: ModalDirective;
  @ViewChild("todate", {static: true}) todate: ModalDirective;
  @ViewChild("editPoup", {static: true}) editPoup: ModalDirective;
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id",
       width: 150
     },
     {
      name:"Profile",
      sortBy:"Profile",
      width: 150
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
      name:"Loan Amount",
      sortBy:"user_monthly_income"
    },
     
     {
       name:"Last  Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
      {
      name:"CRM Status",
      sortBy:"MeraEmi"
     },
     {
      name:"Lender",
      sortBy:"Lender Name"
    },
    {
      name:"Campaign",
      sortBy:"Campaign"
    },
     {
       name:"View",
       sortBy:"send_message"
     },
     {
      name:"Edit By",
      sortBy:"edit_by"
    },

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id:number = 0;
  to_user_id: number = 0;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  resultpush: any = [];
  responseArray:any = [];
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
 
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  iapstage: number=0;
  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;
  usersatge: string;
  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;
  usernamelist: number = 0;
  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
  dbuserid:number=0;
  lendername: any;
  crmstages: any;
  userstages: any;
  empcategory: string;
  dateAll: any = [];  
  useridAll: any = [];  
  sourceAll: any = [];
  adidAll: any = [];
  arryData: any = [];
  user_id: any;
  loder: number;
  constructor( 
      private cryptojsService:CryptojsService,
      private FeedbackService:FeedbackService,    
      private lenderContactService:LenderContactService,
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,
      private SelfEmpLeadService:SelfEmpLeadService,
      
      ) { }

  ngOnInit() {
    this.optionsSelect= [

      { value: 'WEB', label: 'WEB' },      
      { value: 'BOT', label: 'BOT' },
      
      ];  
      this.optionsStage= [
      { value: 'Eligibility Pending', label: 'Eligibility Pending' },      
      { value: 'Eligibility Clear', label: 'Eligibility Clear' },      
      { value: 'Eligibility Fail', label: 'Eligibility Fail' },
      { value: 'Criteria Fail', label: 'Criteria Fail' },      
      { value: 'IAP Pending', label: 'IAP Pending' },
      { value: 'IAP Complete', label: 'IAP Complete' },
      { value: 'IAP Error', label: 'IAP Error' },
      { value: 'IAP Fail', label: 'IAP Fail' },
      { value: 'IAP Success', label: 'IAP Success' },    
      { value: 'Success', label: 'Success' },
        
        ];  
        
        this.leadsService
        .getCityname()
        .subscribe( (result:any) => {
        //console.log(result.filter_city);
                if (result.status = 'true') {
                  
                  for (let i = 0; i < result.filter_city.length; i++) {
                    const menuarray = result.filter_city[i];
                   //console.log(menuarray.data[0].pincode);
                   if(menuarray.data[0].location !=undefined){
                    this.lendernamearray.push(
                      {
                        value: menuarray.data[0].location.toUpperCase(),
                        label: menuarray.data[0].location.toUpperCase(),                          
                       
                      }
                      );
                      //console.log(this.messages);
                     }
                  
                     
                      
                  }
                  this.optionslocation =this.lendernamearray;
                  //console.log(this.optionslocation);
                }else{
                  console.log('Location Api faile');
                }
                
              });

              this.leadsService
              .filterCrmStageAndUserstage()
              .subscribe( (result:any) => {
           //   console.log(result);
                      if (result.status === true && result.response===200) {
                        this.CrmStatus.push(
                          {
                            value: "Eligibility Clear",
                            label: "Eligibility Clear",                          
                           
                          }
                          );
                        for (let i = 0; i < result.userstage.length; i++) {
                          const menuarray = result.userstage[i];
                         
                         if(menuarray.data[0].meraemi_stage !=undefined){

                          this.CrmStatus.push(
                            {
                              value: menuarray.data[0].meraemi_stage,
                              label: menuarray.data[0].meraemi_stage,                          
                             
                            }
                            );
                           
                            //console.log(this.messages);
                           }

                           if(menuarray.data[0].user_stage !=undefined){
                            this.UserStatus.push(
                              {
                                value: menuarray.data[0].user_stage,
                                label: menuarray.data[0].user_stage,                          
                               
                              }
                              );
                              //console.log(this.messages);
                             }
                        

                            
                        }
                        
                        const array = this.UserStatus;
                      
                      const distinctItems = [...new Map(array.map(item => [item["value"], item])).values()];
                     // console.log(distinctItems);
                        this.crm_status =this.CrmStatus;
                        this.user_status =distinctItems;
                        
                       // console.log(this.crm_status);
                        //console.log(this.user_status);
                      }else{
                        console.log('Location Api faile');
                      }
                      
                    });
     this.lenderContactService
      .getLenderName()
      .subscribe( (result:any) => {     
              if (result.status = 'true') {                
                for (let i = 0; i < result.lender_name.length; i++) {
                  const menuarray = result.lender_name[i];
                  this.messages.push(
                    {
                      value: menuarray.data[0].lender_name,
                      label: menuarray.data[0].lender_name,                         
                     
                    }
                    );
                    
                }
                this.optionsArray =this.messages;                
              }else{
                console.log('Lender Api faile');
              }
              
            });

    this.registerForm = this.formBuilder.group({
      idFilter: this.formBuilder.group({
        fromuserid: ['', Validators.required],
        touserid: ['', Validators.required],
      }),
      sourceFilter: this.formBuilder.group({
        source: ['', Validators.required]
      }),
      adidFilter: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      loanFilter: this.formBuilder.group({
        fromLonAmt: ['', Validators.required],
        toLonAmt: ['', Validators.required],
      }),
      stageFilter: this.formBuilder.group({
        stage: ['', Validators.required]
       
      }),
      dateFilter: this.formBuilder.group({
        fromdate: ['', Validators.required],
        todate: ['', Validators.required],
       
      }),
      locationFilter: this.formBuilder.group({
        location: ['', Validators.required],
       
      }),
       lendernamefilter: this.formBuilder.group({
        lendername: ['', Validators.required],
       
      }),
      crmstagefilter: this.formBuilder.group({
        crmstage: ['', Validators.required],
       
      }),
      userstagefilter: this.formBuilder.group({
        userstage: ['', Validators.required],
       
      }),
      adidedit: this.formBuilder.group({
        adids: ['', Validators.required]
      })
    });
    this.leadsService
    .getAllAdId()
    .subscribe( (result:any) => {
     
             if (result.status = 'true') {
               
               for (let i = 0; i < result.fb_adid.length; i++) {
                 const menuarray = result.fb_adid[i];
               // console.log(menuarray);
                if(menuarray.data[0].ad_id !=0){
                 this.All_ad_id.push(
                   {
                    
                                                       
                    
                    value: menuarray.data[0].ad_id,
                    label: menuarray.data[0].ad_id,
                   }
                   );
                   
                  }
               
                  
                   
               }
               this.optionsadid =this.All_ad_id;
               
             }else{
              console.log('Ad Api faile');
            }
             
           });
          // console.log(this.All_ad_id);
    
    this.SelfEmpLeadService
    .getAllLeadsCTWA()
    .subscribe( (result:any) => {
    //console.log(result);
   
   // console.log(results);
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.ctwa.length;  i++) {
          const element = result.ctwa[i];
        
         //console.log(element);
        //  console.log(element.data[0].elig_status);
        // console.log(result);
                  this.stage_color = "info-color";
            
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                    this.iapstage =4;
                    this.usersatge="Active";
                    this.appstage = this.elig_status;
                    this.usernamelist=4;
                  
                  }else if(element.data[0].app_stage  === "1"){
                     if(element.data[0].elig_status =="1" || element.data[0].elig_status =="na"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Active";
                     }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                      this.iapstage =4;
                      this.usernamelist=4;
                      this.usersatge="Inactive";
                     }
                     this.appstage = this.elig_status;
                   
                   }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
                    this.iapstage=5;
                    this.usernamelist=4;
                    this.usersatge="Active";
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                  this.iapstage=5;
                  this.usernamelist=5;
                   
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
                  this.iapstage=5;
                  this.usernamelist=5;
                }
              if(element.data[0].lender_name !="NA"){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else if(element.data[0].lender_name === "Lender Not Selected"){
                  this.lender_name = "NA";
                 }else{
                  this.lender_name = element.data[0].feedback_lender_name;
                }
              
                
               }
               if(this.usernamelist ===5){
                 if(element.data[0].iap_fname !="NA" && element.data[0].iap_lname !="NA"){
                   this.first_name=element.data[0].iap_fname;
                  this.last_name=element.data[0].iap_lname;
                  // this.first_name="rajeev";
                   //this.last_name="pathak"
                 }
               }
               
               if(this.usernamelist ===4){
                if(element.data[0].first_name !="NA" && element.data[0].last_name !="NA"){
                  this.first_name=element.data[0].first_name;
                  this.last_name=element.data[0].last_name;
                }
               
               }
               if(this.iapstage ===5){
                 if(element.data[0].feedback_stage !=undefined){
                  this.meraemistage=element.data[0].feedback_stage;
                 }else{
                  this.meraemistage="Pending Review";
                 }
                if(element.data[0].user_stage !=undefined){
                      this.usersatge=element.data[0].user_stage;
                    }else{
                      this.usersatge="Active";
                    }
                
                //.log(element.data[0].feedback_stage);
                //console.log(element.data[0].user_stage);
               }else{
                this.meraemistage="NA";
                if(element.data[0].user_stage !=undefined){
                  this.usersatge=element.data[0].user_stage;
                 }
               }
               if(element.data[0].provider_id  == "whatsapp"){
                this.provider = 'Whatsapp';
              }

              
              if(element.data[0].ad_id ==="NULL" || element.data[0].ad_id ==="null"){
                this.adupdatebtnEnable=1;
              }else{
                this.adupdatebtnEnable=0;
              }
             //  (CRM_Cancel , CRM Decline, Cust Cancel)
              this.fnamelname= this.first_name+' '+this.last_name



              if(element.data[0].salaried==="2"){
                this.empcategory="SEP";

              }
              if(element.data[0].feedback_stage !="na"){
                this.meraemistage=element.data[0].feedback_stage;
               }else{
                this.meraemistage="Pending Review";
               }
             // console.log(element.data[0].user_stage);
            //   console.log(this.lender_name);
            if(element.data[0].provider_id  == "whatsapp"){

           
                            this.elements.push(
                              {
                                user_id: element.data[0].user_id, 
                                name:  this.fnamelname, 
                                          
                                provider: this.provider,
                                city_name: element.data[0].location,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:element.data[0].feedback_lender_name,
                                feedback_stage:this.meraemistage,
                                usersatge:this.usersatge,
                                adupdatebtnEnable:this.adupdatebtnEnable,
                                user_monthly_income:element.data[0].user_monthly_income,
                                empcategory:element.data[0].salaried,
                                Campaign	:element.data[0].campain_name,
                                mobileNumber	:element.data[0].mobile_number
                                
                              }
                              );
                              this.element_main.push(
                                {
                                  user_id: element.data[0].user_id, 
                                  name:  this.fnamelname,               
                                  provider: this.provider,
                                  city_name: element.data[0].location,
                                  loan_amount: element.data[0].loan_amount,
                                  ad_id: element.data[0].ad_id,
                                  reg_date: element.data[0].date,
                                  status: this.appstage,
                                  feedbackstage:this.urlstage,
                                  lender_name:this.lender_name,
                                   feedback_stage:this.meraemistage,
                                   usersatge:this.usersatge,
                                   adupdatebtnEnable:this.adupdatebtnEnable,
                                   user_monthly_income:element.data[0].user_monthly_income,
                                   empcategory:element.data[0].salaried,
                                   Campaign	:element.data[0].campain_name,
                                   mobileNumber	:element.data[0].mobile_number
             
                                }
                                );
                              }
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  adidSubmitted: boolean = false;
  useridSubmitted: boolean = false;
  sourceSubmitted: boolean = false;
  loanSubmitted: boolean = false;
  stageSubmitted: boolean = false;
  dateSubmitted: boolean = false;
  locationSubmitted: boolean = false;
  lenderSubmitted: boolean = false;
  crmstageSubmitted: boolean = false;
  userstageSubmitted: boolean = false;

  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }

  get fc() { return (<FormGroup>this.registerForm.get('sourceFilter')).controls; }
  get fa() { return (<FormGroup>this.registerForm.get('adidFilter')).controls; }
  get fd() { return (<FormGroup>this.registerForm.get('loanFilter')).controls; }
  get fg() { return (<FormGroup>this.registerForm.get('stageFilter')).controls; }
  get fh() { return (<FormGroup>this.registerForm.get('dateFilter')).controls; }
  get fi() { return (<FormGroup>this.registerForm.get('locationFilter')).controls; }
  get lf() { return (<FormGroup>this.registerForm.get('lendernamefilter')).controls; }
  get lg() { return (<FormGroup>this.registerForm.get('crmstagefilter')).controls; }
  get lh() { return (<FormGroup>this.registerForm.get('userstagefilter')).controls; }
  get hc() { return (<FormGroup>this.registerForm.get('adidedit')).controls; }
  
  get adidedit() {
    return this.registerForm.get('adidedit');
  }
  get idFilter() {
    return this.registerForm.get('idFilter');
  }

  get sourceFilter() {
    return this.registerForm.get('sourceFilter');
  }
 get adidFilter(){
  return this.registerForm.get('adidFilter');
}
get loanFilter(){
  return this.registerForm.get('loanFilter');
}get stageFilter(){
  return this.registerForm.get('stageFilter');
}
get dateFilter(){
  return this.registerForm.get('dateFilter');
}
get locationFilter(){
  return this.registerForm.get('locationFilter');
}
get lendernamefilter(){
  return this.registerForm.get('lendernamefilter');
}
get crmstagefilter(){
  return this.registerForm.get('crmstagefilter');
}
get userstagefilter(){
  return this.registerForm.get('userstagefilter');
}
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
    //filter user id
      filterUserId(){
      this.useridSubmitted = true;
      // stop here if form is invalid
      if (this.idFilter.invalid) {
        return;
      }else{
        //console.log("ok");
        this.valuemain=1;
        this.userid =1;
        this.activeClass ="activbutton";
        this.display = "none";
        //user id
        this.from_user_id =parseInt(this.idFilter.value.fromuserid);
        this.to_user_id =parseInt(this.idFilter.value.touserid);
        this.useridAll.push({
          from_user_id : this.from_user_id,
          to_user_id : this.to_user_id
        });
        const data = this.mdbTable.getDataSource();
          
        this.result = data.filter(d => {

          this.dbuserid = parseInt(d.user_id);        
          return ((this.dbuserid >= this.from_user_id)  && (this.dbuserid  <=   this.to_user_id) );  
          
        });
       
        

     // console.log(this.result); return false;
        this.elementsfilter.length=0;
        if(this.result.length > 0){
        for (let i = 0; i < this.result.length; i++) {
          const elements = this.result[i];
          //console.log(elements);
          this.stage_color = "info-color";

          this.elementsfilter.push(
            {
            user_id: elements.user_id, 
            name:  elements.name, 
            //last_name:  elements.last_name,              
            provider: elements.provider,
            city_name: elements.city_name,
            loan_amount: elements.loan_amount,
            ad_id: elements.ad_id,
            reg_date: elements.reg_date,
            status: elements.status,
            feedbackstage: elements.feedbackstage,
            lender_name: elements.lender_name,
            feedback_stage:elements.feedback_stage,
            usersatge:elements.usersatge,
            user_monthly_income:elements.user_monthly_income, 
            empcategory:elements.empcategory, 
            Campaign:elements.Campaign, 
            
            }
            );
           //
        }
      }else{
        this.messageDiv=1;
        this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
        this.clearid=11;
      }
        
        this.mdbTable.setDataSource(this.elementsfilter);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();      
        
                  
    }

      }
      //filter user source
      filterSource(){
        this.sourceSubmitted = true;
        if (this.sourceFilter.invalid) {
          return;
        }else{
          this.valuemain=1;           
          this.sourcename=1;
          this.basicModal2.hide();
          this.activeSource ="activbutton";
          //source
          this.source =this.sourceFilter.value.source;
          this.sourceAll.push({
            source : this.source
            
          });
          
          const data = this.mdbTable.getDataSource();
 
          const  source = this.source;
         
          let filtered = [];
          
          source.filter(function(newsourceData) {
            return   data.filter(function(mainArray) {
                if (newsourceData === mainArray.provider) {
                filtered.push({
                user_id:     mainArray.user_id, 
               // name:  elements.name,
                name:  mainArray.name, 
               // last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,
                feedback_stage:mainArray.feedback_stage,
                usersatge:mainArray.usersatge,
                user_monthly_income:mainArray.user_monthly_income, 
                empcategory:mainArray.empcategory, 
                Campaign:mainArray.Campaign,
                })
              }
            })
          });
          this.elementsSource.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];       
                this.stage_color = "info-color";
        
                this.elementsSource.push(
                  {
                  user_id: element.user_id, 
                  name:  element.name, 
                  //last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  feedback_stage:element.feedback_stage,
                  usersatge:element.usersatge,
                  user_monthly_income:element.user_monthly_income, 
                  empcategory:element.empcategory, 
                  Campaign:element.Campaign,
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Source'+"  "  +this.source;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elementsSource);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          






        
        }
      }
      //ad id
      filterAdid() {
        this.adidSubmitted = true;
        if (this.adidFilter.invalid) {
          //return;
          alert('add value');
        }else{
          this.valuemain=1;           
          this.ad_id=1;
          this.basicModal4.hide();
          this.activeadid ="activbutton";
          // add id
          this.adid =this.adidFilter.value.adid;
        
           this.adidAll.push({
            adid : this.adid
             
           });
          //console.log(this.result);
          const data = this.mdbTable.getDataSource();
         
          const  adid = this.adid;
         
          let filtered = [];
          
          adid.filter(function(newadid) {
            return   data.filter(function(mainArray) {
                if (newadid === mainArray.ad_id) {
                filtered.push({
                user_id:     mainArray.user_id, 
                name:  mainArray.name, 
               // last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,
                feedback_stage:mainArray.feedback_stage,
                usersatge:mainArray.usersatge,                
                user_monthly_income:mainArray.user_monthly_income, 
                empcategory:mainArray.empcategory, 
                Campaign:mainArray.Campaign,
                })
              }
            })
          });
          //console.log(filtered);
          this.elements_adid.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];
              
                this.stage_color = "info-color";
        
                this.elements_adid.push(
                  {
                  user_id: element.user_id, 
                  name:  element.name, 
                  //last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  feedback_stage:element.feedback_stage,
                  usersatge:element.usersatge,
                  user_monthly_income:element.user_monthly_income, 
                  empcategory:element.empcategory, 
                  Campaign:element.Campaign,
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Add id'+"  "  +this.adid;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elements_adid);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
          
        }
      }
      //loan amt filter
      filterLonAmt(){
        this.loanSubmitted = true;    
          // stop here if form is invalid
          if (this.loanFilter.invalid) {
            return;
          }else{
            
            
            this.valuemain=1;           
            this.loan_amt=1;
            this.basicModal.hide();
            this.activeloan ="activbutton";
            //Loan amt
            this.fromLonAmt =this.loanFilter.value.fromLonAmt;
            this.toLonAmt =this.loanFilter.value.toLonAmt;
           
            const data = this.mdbTable.getDataSource();
           
            const  toamt = parseFloat(this.toLonAmt.toString());
            const  fromamt = parseFloat(this.fromLonAmt.toString());
          //  console.log(toamt);
          //  console.log(fromamt); 
              
              //user id
              this.result = data.filter(d => {
                
                if(d.loan_amount != 'undefined' || d.loan_amount != undefined ){
                //  console.log(d);return false;
                  const  loanamt= parseFloat(d.loan_amount);
                  //return (fromamt  <= loanamt && loanamt  <=  toamt );
                  return ((loanamt >= fromamt ) && (loanamt  <=  toamt ));
                }


                
              });
             // console.log(this.result);
              this.elementsLoan.length=0;
            for (let i = 0; i < this.result.length; i++) {
              const element = this.result[i];
            // console.log(element);
              this.stage_color = "info-color";

              this.elementsLoan.push(
                {
                user_id: element.user_id, 
                name:  element.name, 
                //last_name:  element.last_name,              
                provider: element.provider,
                city_name: element.city_name,
                loan_amount: element.loan_amount,
                ad_id: element.ad_id,
                reg_date: element.reg_date,
                status: element.status,
                feedbackstage: element.feedbackstage,
                lender_name: element.lender_name,
                 feedback_stage:element.feedback_stage,
                 usersatge:element.usersatge,
                 user_monthly_income:element.user_monthly_income, 
                 empcategory:element.empcategory, 
                 Campaign:element.Campaign,
                }
                );
              // console.log(this.elementsfilter);
            }
            this.mdbTable.setDataSource(this.elementsLoan);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          //loan_amount
          
            //console.log(this.toLonAmt);
          }
      }
      // satge filter
      filterstage(){
        this.stageSubmitted = true;    
          // stop here if form is invalid
          if (this.stageFilter.invalid) {
            return;
          }else{
            
            this.valuemain=1;
            this.loan_stage =1;
            this.activestage ="activbutton";
            this.basicModal3.hide();
            //elig stage
            this.stage =this.stageFilter.value.stage;
            // add id
            
            const data = this.mdbTable.getDataSource();
 
            const  stage = this.stage;
            let filtered = [];
          
            stage.filter(function(newStageData) {
            return   data.filter(function(mainArray) {
                if (newStageData === mainArray.status) {
                filtered.push({
                user_id:     mainArray.user_id, 
                name:  mainArray.name, 
                //last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,
                feedback_stage:mainArray.feedback_stage,
                usersatge:mainArray.usersatge,
                user_monthly_income:mainArray.user_monthly_income, 
                empcategory:mainArray.empcategory, 
                Campaign:mainArray.Campaign,
                
                })
              }
            })
          });
            //console.log(filtered);
            this.elements_stage.length=0;
            if(filtered.length > 0){
              for (let i = 0; i < filtered.length; i++) {
                  const element = filtered[i];
              
                  this.stage_color = "info-color";
          
                  this.elements_stage.push(
                    {
                    user_id: element.user_id, 
                    name:  element.name, 
                   // last_name:  element.last_name,              
                    provider: element.provider,
                    city_name: element.city_name,
                    loan_amount: element.loan_amount,
                    ad_id: element.ad_id,
                    reg_date: element.reg_date,
                    status: element.status,
                    feedbackstage: element.feedbackstage,
                    lender_name: element.lender_name,
                    feedback_stage:element.feedback_stage,
                    usersatge:element.usersatge,
                    user_monthly_income:element.user_monthly_income, 
                    empcategory:element.empcategory, 
                    Campaign:element.Campaign,
                    }
                    );
                  // console.log(this.elementsfilter);
                }
              }else{
              this.messageDiv=1;
              this.message ='Record not for stage'+"  "  +this.stage;
              this.clearid=14;
             
             
            }
            this.mdbTable.setDataSource(this.elements_stage);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          }
      }
      //date
      filterdate(){
        
        this.dateSubmitted = true;    
        // stop here if form is invalid
        if (this.dateFilter.invalid) {
          return;
        }else{
       
          this.valuemain=1;
          this.loan_date =1;
          this.activedate ="activbutton";
          this.frame.hide();
           //elig date dateFilter
           this.fromdate =this.dateFilter.value.fromdate;
           //  alert(this.fromdate);
             this.todate =this.dateFilter.value.todate;
           this.dateAll.push({
             fromdate : this.fromdate,
             todate : this.todate
           });
         
            const data = this.mdbTable.getDataSource();
              
              const  fromdate = this.fromdate;
              const  todate = this.todate;
              
            //user id
            this.result = data.filter(d => {
              const  reg_date= d.reg_date;
              const regdate = reg_date.split(' ')[0];
              //return (fromdate  <= regdate && regdate  <=  todate );            
              return (regdate >= fromdate  && regdate  <=  todate );
              
            });
            
            this.elementsdate.length=0;
            if(this.result.length > 0){
              for (let i = 0; i < this.result.length; i++) {
                const element = this.result[i];
            
                this.stage_color = "info-color";
  
                this.elementsdate.push(
                  {
                  user_id: element.user_id, 
                  name:  element.name, 
                  //last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  feedback_stage:element.feedback_stage,
                  usersatge:element.usersatge,
                  user_monthly_income:element.user_monthly_income, 
                  empcategory:element.empcategory, 
                  Campaign:element.Campaign,
                  
                  }
                  );
                // console.log(this.elementsfilter);
                this.mdbTable.setDataSource(this.elementsdate);
                this.elements = this.mdbTable.getDataSource();
                this.previous = this.mdbTable.getDataSource();
              }
            }else{
              this.messageDiv=1;
              this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
              this.clearid=14;
             
             
            }
           
            
          
            
        
        }
      }
      filterlocation(){
        //console.log(this.elements);
        //console.log(this.locationFilter.value.location);
        this.locationSubmitted = true;    
        //
        if (this.myControl.invalid) {
          return;
        }else{
         // console.log(this.elementscityname);
          
          this.valuemain=1;
          this.city_name =1;
          this.activeLocation ="activbutton";
          this.basicModal1.hide();
          //fiter location
          this.cityname =this.locationFilter.value.location;
          
            const  data = this.mdbTable.getDataSource();
          //  console.log(data);
                const  cityname = this.cityname;
               // console.log(cityname);
                let filtered = [];
                
                cityname.filter(function(newCityData) {
                  return   data.filter(function(mainArray) {
                      if (newCityData === mainArray.city_name) {
                      filtered.push({
                      user_id:     mainArray.user_id, 
                      name:  mainArray.name, 
                      //last_name:   mainArray.last_name,              
                      provider:    mainArray.provider,
                      city_name:   mainArray.city_name,
                      loan_amount: mainArray.loan_amount,
                      ad_id:       mainArray.ad_id,
                      reg_date:    mainArray.reg_date,
                      status:      mainArray.status,
                      feedbackstage: mainArray.feedbackstage,
                      lender_name: mainArray.lender_name,
                      feedback_stage:mainArray.feedback_stage,
                      usersatge:mainArray.usersatge,
                      user_monthly_income:mainArray.user_monthly_income, 
                      empcategory:mainArray.empcategory, 
                      Campaign:mainArray.Campaign,
                      
                      })
                    }
                  })
                });
                
              
              // console.log(filtered);
                this.elementscityname.length=0;
              if(filtered.length > 0){
                for (let i = 0; i < filtered.length; i++) {
                    const element = filtered[i];
                  // 
                    this.stage_color = "info-color";
            
                    this.elementscityname.push(
                      {
                      user_id: element.user_id, 
                      name:  element.name, 
                      //last_name:  element.last_name,              
                      provider: element.provider,
                      city_name: element.city_name,
                      loan_amount: element.loan_amount,
                      ad_id: element.ad_id,
                      reg_date: element.reg_date,
                      status: element.status,
                      feedbackstage: element.feedbackstage,
                      lender_name: element.lender_name,
                      feedback_stage:element.feedback_stage,
                      usersatge:element.usersatge,
                      user_monthly_income:element.user_monthly_income, 
                      empcategory:element.empcategory, 
                      Campaign:element.Campaign,
                      }
                      );
                    // console.log(this.elementsfilter);
                  }
              }else{
                this.messageDiv=1;
                this.message ='Record not for city name'+"  "  +this.cityname;
                this.clearid=14;
               
               
              }
              this.mdbTable.setDataSource(this.elementscityname);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
          
        }
      }  
       // satge filter
      filterLendername(){
       // this.stageSubmitted = true;   
               this.lenderSubmitted = true;                      
      
          // stop here if form is invalid
          if (this.lendernamefilter.invalid) {
            return;
          }else{
            
            this.valuemain=1;
            this.loan_lender =1;
            this.activelender ="activbutton";
            this.basicModal10.hide();

            //elig stage
           this.lender_filter =this.lendernamefilter.value.lendername;
            // add id
            
            const data = this.mdbTable.getDataSource();
          // console.log(data);
            const  stages = this.lender_filter;
           // console.log(stage);
            let filtered = [];
          
            stages.filter(function(newStageData) {
            return   data.filter(function(mainArray) {
                if (newStageData === mainArray.lender_name) {
                filtered.push({
                  user_id: mainArray.user_id, 
                  name:  mainArray.name, 
                  //last_name:  element.last_name,              
                  provider: mainArray.provider,
                  city_name: mainArray.city_name,
                  loan_amount: mainArray.loan_amount,
                  ad_id: mainArray.ad_id,
                  reg_date: mainArray.reg_date,
                  status: mainArray.status,
                  feedbackstage: mainArray.feedbackstage,
                  lender_name: mainArray.lender_name,
                  feedback_stage:mainArray.feedback_stage,
                  usersatge:mainArray.usersatge,
                  user_monthly_income:mainArray.user_monthly_income, 
                  empcategory:mainArray.empcategory, 
                  Campaign:mainArray.Campaign, 
                
                })
              }
            })
          });
         // console.log(filtered.length);
          this.elements_stage.length=0;
            if(filtered.length > 0){
              for (let i = 0; i < filtered.length; i++) {
                  const element = filtered[i];
              
                  this.stage_color = "info-color";
          
                  this.elements_stage.push(
                    {
                    user_id: element.user_id, 
                    name:  element.name,                              
                    provider: element.provider,
                    city_name: element.city_name,
                    loan_amount: element.loan_amount,
                    ad_id: element.ad_id,
                    reg_date: element.reg_date,
                    status: element.status,
                    feedbackstage: element.feedbackstage,
                    lender_name: element.lender_name,
                    feedback_stage:element.feedback_stage,
                    usersatge:element.usersatge,
                    user_monthly_income:element.user_monthly_income, 
                    empcategory:element.empcategory, 
                  Campaign:element.Campaign, 
                    }
                    );
                // console.log(this.elements_stage);
                }
              }else{
              this.messageDiv=1;
              this.message ='Record not for stage'+"  "  +this.stage;
              this.clearid=14;
             
             
            }
            this.mdbTable.setDataSource(this.elements_stage);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          }
      } 
      filterCrmStage(){
       // this.lender_filter =this.crmstagefilter.value.crmstage;
       // console.log(this.lender_filter);
                this.crmstageSubmitted = true;                      
       
           // stop here if form is invalid
           if (this.crmstagefilter.invalid) {
             return;
           }else{
             
             this.valuemain=1;
             this.meraemi_stage =1;
             
             this.activeCrmstage ="activbutton";
             
            this.basicModal11.hide();
 
             //elig stage
            this.crmstage =this.crmstagefilter.value.crmstage;
             // add id
             
             const data = this.mdbTable.getDataSource();
           // console.log(data);
             const  stages = this.crmstage;
            // console.log(stage);
             let filtered = [];
           
             stages.filter(function(newStageData) {
             return   data.filter(function(mainArray) {
                 if (newStageData === mainArray.feedback_stage) {
                 filtered.push({
                 user_id:     mainArray.user_id, 
                 name:  mainArray.name,                             
                 provider:    mainArray.provider,
                 city_name:   mainArray.city_name,
                 loan_amount: mainArray.loan_amount,
                 ad_id:       mainArray.ad_id,
                 reg_date:    mainArray.reg_date,
                 status:      mainArray.status,
                 feedbackstage: mainArray.feedbackstage,
                 lender_name: mainArray.lender_name,
                  feedback_stage:mainArray.feedback_stage,
                  usersatge:mainArray.usersatge,
                  user_monthly_income:mainArray.user_monthly_income, 
                  empcategory:mainArray.empcategory, 
                  Campaign:mainArray.Campaign,
                  
                 
                 })
               }
             })
           });
          // console.log(filtered.length);
           this.elements_stage.length=0;
             if(filtered.length > 0){
               for (let i = 0; i < filtered.length; i++) {
                   const element = filtered[i];
               
                   this.stage_color = "info-color";
           
                   this.elements_stage.push(
                     {
                     user_id: element.user_id, 
                     name:  element.name,                              
                     provider: element.provider,
                     city_name: element.city_name,
                     loan_amount: element.loan_amount,
                     ad_id: element.ad_id,
                     reg_date: element.reg_date,
                     status: element.status,
                     feedbackstage: element.feedbackstage,
                     lender_name: element.lender_name,
                     feedback_stage:element.feedback_stage,
                     usersatge:element.usersatge,
                     user_monthly_income:element.user_monthly_income, 
                     empcategory:element.empcategory, 
                     Campaign:element.Campaign,
                     }
                     );
                //  console.log(this.elements_stage);
                 }
               }else{
               this.messageDiv=1;
               this.message ='Record not for stage'+"  "  +this.stage;
               this.clearid=14;
              
              
             }
             this.mdbTable.setDataSource(this.elements_stage);
             this.elements = this.mdbTable.getDataSource();
             this.previous = this.mdbTable.getDataSource();
           }
       } 
       filteruserstage(){
        // this.lender_filter =this.userstagefilter.value.userstage;
        // console.log(this.lender_filter);
                 this.crmstageSubmitted = true;                      
        
            // stop here if form is invalid
            if (this.userstagefilter.invalid) {
              return;
            }else{
              
              this.valuemain=1;
              this.meraemi_sub_stage =1;
              this.activeUserstage ="activbutton";
              this.basicModal12.hide();
  
              //elig stage
             this.userstage =this.userstagefilter.value.userstage;
              // add id
              
              const data = this.mdbTable.getDataSource();
            // console.log(data);
              const  stages = this.userstage;
             // console.log(stage);
              let filtered = [];
            
              stages.filter(function(newStageData) {
              return   data.filter(function(mainArray) {
                  if (newStageData === mainArray.usersatge) {
                  filtered.push({
                  user_id:     mainArray.user_id, 
                  name:  mainArray.name,                             
                  provider:    mainArray.provider,
                  city_name:   mainArray.city_name,
                  loan_amount: mainArray.loan_amount,
                  ad_id:       mainArray.ad_id,
                  reg_date:    mainArray.reg_date,
                  status:      mainArray.status,
                  feedbackstage: mainArray.feedbackstage,
                  lender_name: mainArray.lender_name,
                   feedback_stage:mainArray.feedback_stage,
                   usersatge:mainArray.usersatge,
                   user_monthly_income:mainArray.user_monthly_income, 
                   empcategory:mainArray.empcategory, 
                   Campaign:mainArray.Campaign,
                  
                  })
                }
              })
            });
           // console.log(filtered.length);
            this.elements_stage.length=0;
              if(filtered.length > 0){
                for (let i = 0; i < filtered.length; i++) {
                    const element = filtered[i];
                
                    this.stage_color = "info-color";
            
                    this.elements_stage.push(
                      {
                      user_id: element.user_id, 
                      name:  element.name,                              
                      provider: element.provider,
                      city_name: element.city_name,
                      loan_amount: element.loan_amount,
                      ad_id: element.ad_id,
                      reg_date: element.reg_date,
                      status: element.status,
                      feedbackstage: element.feedbackstage,
                      lender_name: element.lender_name,
                      feedback_stage:element.feedback_stage,
                      usersatge:element.usersatge,
                      user_monthly_income:element.user_monthly_income, 
                      empcategory:element.empcategory, 
                      Campaign:element.Campaign,
                      
                      }
                      );
                  // console.log(this.elements_stage);
                  }
                }else{
                this.messageDiv=1;
                this.message ='Record not for stage'+"  "  +this.stage;
                this.clearid=14;
               
               
              }
              this.mdbTable.setDataSource(this.elements_stage);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            }
        } 

       



//master cleare dynamic value
clearUserid(user_id){

                  
                    //this.cityname;
                    this.messageDiv=0;
                    this.message=null;
                    this.clearid=null;
                    this.filterarray.length = 0;
                    //this.result.length=0;
                    this.filtered_people.length=0;
                    if(user_id ===10){
                      //user id
                      this.elements.length = 0;
                      this.clear_stagr.length = 0;
                      this.valuemain=1;
                      this.userid =0;
                      this.activeClass ="savemessage";
                      this.display = "none";
                      this.idFilter.reset();
                      //this.useridAll.reset();
                      
                    }else if(user_id ===11){
                      //source
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.sourcename=0;
                      this.basicModal2.hide();
                      this.activeSource ="savemessage";
                      this.sourceFilter.reset(); 
                      //this.filtered_people.length=0;
                          
                    }else if(user_id ===12){
                      //Location
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;       
                      this.valuemain=1;
                      this.city_name =0;
                      this.activeLocation ="savemessage";
                      this.basicModal1.hide();
                      this.locationFilter.reset(); 
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===13){
                      //loan amt
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_amt=0;
                      this.basicModal.hide();
                      this.activeloan ="savemessage";
                      this.loanFilter.reset();
                      

                    }else if(user_id ===14){
                      //date
                      this.elements.length = 0;    
                      this.valuemain=1;           
                      this.loan_date=0;
                      this.basicModal.hide();     
                      this.activedate ="savemessage"; 
                      this.dateFilter.reset();
                      
                      
                    }else if(user_id ===15){
                      //add id
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;    
                      this.valuemain=1;           
                      this.ad_id=0;
                      this.basicModal.hide();       
                      this.activeadid ="savemessage";  
                      this.adidFilter.reset(); 
                      //this.filtered_people.length=0;
                      
                      
                    }else if(user_id ===16){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_stage=0;
                      this.basicModal.hide();
                      this.activestage ="savemessage"; 
                      this.stageFilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===17){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_lender=0;
                      this.basicModal.hide();
                      this.activelender ="savemessage"; 
                      this.lendernamefilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===18){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.meraemi_stage=0;
                      this.basicModal.hide();
                      this.activeCrmstage ="savemessage"; 
                      this.crmstagefilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===19){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.meraemi_sub_stage =0;
                      this.basicModal.hide();
                      this.activeUserstage ="savemessage"; 
                      this.userstagefilter.reset();
                    
                    }
                    this.elementsdate=[];
                    this.result=[];
                    //this.mdbTable.setDataSource.reset();
                    // if(this.elementsdate.length ===0){
                    //   this.arryData= this.element_main;
                    // }else{
                    //   this.arryData=  this.elementsdate;
                    // }
                    const data = this.element_main;
                    console.log(data);
                   // user date 
                   console.log(this.dateAll);
                  if(this.dateAll !='')
                  {
                   // console.log(data);
                   this.fromdate =this.dateAll[0].fromdate;
                   //  alert(this.fromdate);
                     this.todate =this.dateAll[0].todate;
                 
                    const  fromdate = this.fromdate;
                    const  todate = this.todate;
                    
                  //user id
                  this.result = data.filter(d => {
                    const  reg_date= d.reg_date;
                    console.log(reg_date);
                    const regdate = reg_date.split(' ')[0];
                    //return (fromdate  <= regdate && regdate  <=  todate );            
                    return (regdate >= fromdate  && regdate  <=  todate );
                    
                  }); 
                 // console.log(this.result); //return false;
                 this.elementsdate.length=0;
                  if(this.result.length > 0){
                    for (let i = 0; i < this.result.length; i++) {
                      const element = this.result[i];
                  
                      this.stage_color = "info-color";
            
                      this.elementsdate.push(
                        {
                        user_id: element.user_id, 
                        name:  element.name, 
                        //last_name:  element.last_name,              
                        provider: element.provider,
                        city_name: element.city_name,
                        loan_amount: element.loan_amount,
                        ad_id: element.ad_id,
                        reg_date: element.reg_date,
                        status: element.status,
                        feedbackstage: element.feedbackstage,
                        lender_name: element.lender_name,
                        feedback_stage:element.feedback_stage,
                        usersatge:element.usersatge,
                        user_monthly_income:element.user_monthly_income, 
                        empcategory:element.empcategory, 
                        Campaign:element.Campaign,
                        
                        }
                        );
                      // console.log(this.elementsfilter);
                      this.mdbTable.setDataSource(this.elementsdate);
                      this.elements = this.mdbTable.getDataSource();
                      this.previous = this.mdbTable.getDataSource();
                    }
                  }else{
                    this.messageDiv=1;
                    this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
                    this.clearid=14;
                   
                   
                  }
                
                  }else{
                    this.elementsdate=this.element_main;
                  }

                   // user id form value
                   //console.log(this.useridAll);
                  if(this.useridAll !='')
                  {
                   // console.log(this.elementsdate);
                    this.from_user_id =this.useridAll[0].from_user_id;
                    this.to_user_id =this.useridAll[0].to_user_id;
                    const data = this.elementsdate;
      
                    this.result = data.filter(d => {
                
                      this.dbuserid = parseInt(d.user_id);        
                      return ((this.dbuserid >= this.from_user_id)  && (this.dbuserid  <=   this.to_user_id) );  
                      
                    });
                           this.elementsdate.length=0;
                          if(this.result.length > 0){
                                  for (let i = 0; i < this.result.length; i++) {
                                    const elements = this.result[i];
                                    //console.log(elements);
                                    this.stage_color = "info-color";
                              
                                    this.elementsdate.push(
                                      {
                                      user_id: elements.user_id, 
                                      name:  elements.name, 
                                      //last_name:  elements.last_name,              
                                      provider: elements.provider,
                                      city_name: elements.city_name,
                                      loan_amount: elements.loan_amount,
                                      ad_id: elements.ad_id,
                                      reg_date: elements.reg_date,
                                      status: elements.status,
                                      feedbackstage: elements.feedbackstage,
                                      lender_name: elements.lender_name,
                                      feedback_stage:elements.feedback_stage,
                                      usersatge:elements.usersatge,
                                      user_monthly_income:elements.user_monthly_income, 
                                      empcategory:elements.empcategory, 
                                      Campaign:elements.Campaign, 
                                      
                                      }
                                      );
                                    //
                                  }
                                  this.mdbTable.setDataSource(this.elementsdate);
                                  this.elements = this.mdbTable.getDataSource();
                                  this.previous = this.mdbTable.getDataSource();  
                                
                          }else{
                            this.elementsdate=this.elementsfilter;
                          }
                  }else{
                    this.elementsdate=this.elementsdate;
                  }

                  if(this.sourceAll !=''){
                   const provider=    this.sourceAll[0].source;
                    const data = this.elementsdate;
                    this.filtered_people = data.filter((person) => (provider.includes(person.provider)))
                    this.elementsdate.length=0;
                    if(this.filtered_people.length > 0){
                            for (let i = 0; i < this.filtered_people.length; i++) {
                              const elements = this.filtered_people[i];
                              //console.log(elements);
                              this.stage_color = "info-color";
                        
                              this.elementsdate.push(
                                {
                                user_id: elements.user_id, 
                                name:  elements.name, 
                                //last_name:  elements.last_name,              
                                provider: elements.provider,
                                city_name: elements.city_name,
                                loan_amount: elements.loan_amount,
                                ad_id: elements.ad_id,
                                reg_date: elements.reg_date,
                                status: elements.status,
                                feedbackstage: elements.feedbackstage,
                                lender_name: elements.lender_name,
                                feedback_stage:elements.feedback_stage,
                                usersatge:elements.usersatge,
                                user_monthly_income:elements.user_monthly_income, 
                                empcategory:elements.empcategory, 
                                Campaign:elements.Campaign, 
                                
                                }
                                );
                              //
                            }
                            this.mdbTable.setDataSource(this.elementsdate);
                            this.elements = this.mdbTable.getDataSource();
                            this.previous = this.mdbTable.getDataSource();  
                          
                    }else{
                      this.elementsdate=this.elementsfilter;
                    }  
                  }else{
                    this.elementsdate=this.elementsdate;
                  }

                  if(this.adidAll !=''){
                    const adid=    this.adidAll[0].adid;
                     const data = this.elementsdate;
                     const adidfilter = data.filter((person) => (adid.includes(person.ad_id)))
                     this.elementsdate.length=0;
                     if(adidfilter.length > 0){
                             for (let i = 0; i < adidfilter.length; i++) {
                               const elements = adidfilter[i];
                               //console.log(elements);
                               this.stage_color = "info-color";
                         
                               this.elementsdate.push(
                                 {
                                 user_id: elements.user_id, 
                                 name:  elements.name, 
                                 //last_name:  elements.last_name,              
                                 provider: elements.provider,
                                 city_name: elements.city_name,
                                 loan_amount: elements.loan_amount,
                                 ad_id: elements.ad_id,
                                 reg_date: elements.reg_date,
                                 status: elements.status,
                                 feedbackstage: elements.feedbackstage,
                                 lender_name: elements.lender_name,
                                 feedback_stage:elements.feedback_stage,
                                 usersatge:elements.usersatge,
                                 user_monthly_income:elements.user_monthly_income, 
                                 empcategory:elements.empcategory, 
                                 Campaign:elements.Campaign, 
                                 
                                 }
                                 );
                               //
                             }
                             this.mdbTable.setDataSource(this.elementsdate);
                             this.elements = this.mdbTable.getDataSource();
                             this.previous = this.mdbTable.getDataSource();  
                           
                     }else{
                       this.elementsdate=this.elementsfilter;
                     }  
                   }else{
                     this.elementsdate=this.elementsdate;
                   }
                
                  console.log(this.elementsdate);           
                  
                     
  }






        
     
     //user Details
       getLeadDetails(user,data){
        //console.log(data);
       // console.log(user); return false;
         let stage=  data;
         if(user.usersatge !=undefined){
          let userstage=user.usersatge;

           this.useid = this.cryptojsService.encryptData((user.user_id));       
           const base_url=location.origin+"/#/ctwa-details?data=";        
           const detailsurl = base_url + this.useid.toString();
   
           const dateur=detailsurl+"&stage="+stage.toString()+"&userstage="+userstage.toString();
          
           //console.log(detailsurl);
           window.open(dateur, "_blank");
         }else{
         // console.log(user.usersatge);
          this.useid = this.cryptojsService.encryptData((user.user_id));       
          const base_url=location.origin+"/#/MeraEMI-Self-Emp-Leads-Details?data=";        
          const detailsurl = base_url + this.useid.toString();
  
          const dateur=detailsurl+"&stage="+stage.toString();
         
          //console.log(detailsurl);
          window.open(dateur, "_blank");
         }
   
       }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
    openModaloneidid(data,data1) {
      this.adid=data1;
      this.updateuserad=data;
      this.Modaloneidid =data;
   
      
    }
    Addid(){
      let user_id=this.updateuserad;
      let adid=this.adidedit.value.adids;
      console.log(user_id);
      console.log(adid);
      let detail = { results: [{ "user_id":user_id, "adid":adid}] };
      detail['results']; 
      this.FeedbackService.updateUserAdId(detail).subscribe((result: any) => {
        if(result.status===true  && result.response== 200){
          this.Modaloneidid=0;
          this.adidedit.reset();
          alert("Are you sure you want to update Ad ID?");
        }
       /// console.log(result);
      });
      
    }
    CancelAd(){
      this.adidedit.reset();
      this.Modaloneidid=0;
    }
    userEdit(data){
      this.user_id=data;
      this.editPoup.show();
      this.loder=1;
      setTimeout(()=>{ 
        this.loder=0;
        this.editPoup.hide();
      
      
    },9000); 
    
    }
    whatsappUserUpdate(){
      this.loder=0;
        this.editPoup.hide();
      //this.user_id
      this.useid = this.cryptojsService.encryptData((this.user_id));       
      const base_url=location.origin+"/#/meraEMI-registerEdit?uid=";        
      const detailsurl = base_url + this.useid.toString();
      console.log(detailsurl);
      window.open(detailsurl, "_blank");
 
    
    }
    redirectToIntrakt(mobileno){
    //  const contact  = this.cryptojsService.decryptData((mobileno)); 
      //console.log(mobileno); return false;
      const externalUrl = 'https://app.interakt.ai/inbox?channelPhoneNumber=91'+mobileno;
      window.open(externalUrl, '_blank');
     }
   //     
   
}