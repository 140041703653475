import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Global } from 'src/app/global/global';
import { CookieService } from 'ngx-cookie-service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public getLoggedIn = new Subject(); 

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global
  ) { }

  
  login(details:any){
   // console.log(JSON.stringify({ token:token }))
  //  return this.http.post<any>(`http://api.meraemi.c/login/getdatabytoken`,JSON.stringify({ token:token }))
  
  return this.http.post<any>(`${this.global.apiurl}`+'users/details',details)
      .pipe(map(user => {
        //console.log(user)
        this.getLoggedIn.next(user);

          return user;
      }));
  }
  logout(){
  //  localStorage.removeItem('currentUser');
    this.cookieService.delete('currentUser');
    this.cookieService.delete('token_rm');
    this.getLoggedIn.next('Sign In');


  }
}
