import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  MustMatch
} from 'src/app/helpers/must-match.validator';
import {
  AngularFireAuth
} from 'angularfire2/auth';
import {
  FirebasedataService
} from 'src/app/services/firebasedata/firebasedata.service';
import {
  auth,
  User
} from 'firebase';
import * as firebase from 'firebase';
import {
  RegisterService
} from 'src/app/services/register/register.service';
import {
  ReportService
} from 'src/app/services/report/report.service';
import {
  CryptojsService
} from 'src/app/services/cryptojs/cryptojs.service';
export {
  User
}
from 'firebase';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean;
  exist: Promise < boolean > ;
  error: string;
  progress: boolean;
  errorMessage: string;
  RM: 'RM';
  hide: boolean = true;
  hideconf: boolean = true;
  showLoader: boolean;
  token: any;
  successMessage: any;
  success: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public afAuth: AngularFireAuth,
    private firebasedataService: FirebasedataService,
    private registerService: RegisterService,
    private report: ReportService,
    public cryptojsService: CryptojsService
  ) {}


  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      reg_email: ['', [Validators.required, Validators.email]],
      reg_password: ['', [Validators.required, Validators.minLength(6)]],
      password_conf: ['', [Validators.required, Validators.minLength(6)]],
      role_code: ["RM"]
    }, {
      validator: MustMatch('reg_password', 'password_conf')
    });
  }
  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }
  private showError(error: string) {

    this.error = error;
    this.progress = false;
    // setTimeout(() => this.error = null, 5000);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }
    // console.log(this.registerForm.value)
    this.registerNew(this.f.reg_email.value, this.f.reg_password.value)
   

    //this.CreateRecord(credential.user)

    // this.router.navigate['/login'];
  }
  private showSuccess(message: string) {

    this.success = message;
    this.progress = true;
    setTimeout(() => this.successMessage = null, 5000);
  }
  public registerNew(email: string, password: string) {
    var user = null;
    // Create a new user with email and password
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(credential => {

        user = firebase.auth().currentUser;
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
        //  this.SendVerificationMail();
        // this.CreateRecord()
        var userdet = this.afAuth.auth.currentUser;

        var fname, useremail, uid, emailVerified, role_code, lname, providerId, password;


        if (userdet != null) {
          fname = this.f.fname.value;
          useremail = userdet.email;
          emailVerified = userdet.emailVerified;
          uid = userdet.uid;
          role_code = this.f.role_code.value;
          lname = this.f.lname.value;
          providerId = userdet.providerData[0].providerId;
          password = this.f.reg_password.value
        }

        let details = {
          data: {
            results: [{
              "fb_uid": uid,
              "first_name": fname,
              "Middle_Name": "",
              "last_name": lname,
              "email": useremail,
              "provider_id": providerId,
              "mobileno": "",
              "otp": "",
              "iemi": "",
              "macaddress": "",
              "ipaddress": "",
              "osid": "",
              "uuid": "",
              "rolecode": role_code,
              "profile_pic": "",
              "mobile_verified_on": "",
              "password": password
            }]

          }
        };
        details['data']['results'][0]['created_on'] = userdet.metadata.creationTime;
        details['data']['results'][0]['signin_on'] = userdet.metadata.lastSignInTime;
        // console.log(details) 
        this.registerService.addUserData(details)
          .subscribe((result: any) => {
            //    console.log(result)
            if (result.status == 'TRUE') {
              this.token = result.result.token_key;
              details['data']['results'][0]['token'] = this.token;
              this.firebasedataService.create_new(details.data.results[0]);
              this.afAuth.auth.currentUser.sendEmailVerification()
              this.success = "Successfully registered";
              this.showSuccess(this.success);
              setTimeout(() =>               this.reportSuccess('Registred', '/login'), 5000);
            } else {
              var userdet = this.afAuth.auth.currentUser;
              // console.log(userdet)
              userdet.delete();
              this.errorMessage = 'error in registration';
              this.showError(this.errorMessage);
              this.reportSuccess('Registred', '/register')
            }



          });



      })
      .catch(error => {
        console.log(error)
        this.showLoader = false;
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
        if (error.code == 'auth/email-already-in-use') {
          this.errorMessage = 'Your email adress is alredy registerd with us';

        }
        if (error.code == 'auth/weak-password') {
          this.errorMessage = 'Password should be at least 6 characters';

        }
        this.showError(this.errorMessage);

      });
  }

}
