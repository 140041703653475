import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription, Observable } from 'rxjs';
import { LoansService } from 'src/app/services/loans/loans.service';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';
import {ExcelService} from 'src/app/services/excel/excel.service';
import * as XLSX from 'xlsx'; 
import { MasterService } from 'src/app/services/master/master.service';
@Component({
    selector: 'app-meaemi-crm-status-m',
  templateUrl: './meaemi-crm-status-m.component.html',
  styleUrls: ['./meaemi-crm-status-m.component.scss']
})
export class MeaemiCrmStatusMComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @Output() change = new EventEmitter();
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;

  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  editvalidatingForm: FormGroup;
  lenderproductoffer: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  
  elements: any = []; 
  lenderName: any = []; 
  locationName: any = []; 
  location: any = []; 
  lendeEmail: any = []; 
  lende_email: any = [];
  divaction: number=0;
  sub: any;
  user: User;
  rolecode: any;
  loginAdmin: any;
  data: any;
  feedback_Stage: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  delete = {};
  cust: any;
  userdetils: {};
  edit = {};
  previous: any;
  element:any = [];

 
  currentdate: Date;
  headElements = [
    {
       name:"Stage id",
       sortBy:"id"
     },
     {
       name:"Meraemi Stage",
       sortBy:"meraemi_stage"
     },
     
     {
       name:"User Stage",
       sortBy:"user_stage"
     },
     {
      name:"Stage Type",
      sortBy:"stage_type"
    },
     
     {
       name:"Status",
       sortBy:"rate"
     },
     {
       name:"Date",
       sortBy:"tenure"
     },
     
       
     {
      name:"Action",
      sortBy:"action"
    },  
     
        ];
  display: string;
  id: string;
  product_name: string;
  rate_of_interest_from: string;
  tenure: string;
  interest_rate_as_on: string;
  lenderid: string;
  registerForm: FormGroup;
  lenderdate: string;
  lender_id: string;
  product_id: string;
  rate_from: string;
  tenureedit: string;
  editlenderid: any;
  editproductid: any;


  doc_satge_id: any;
  doc_name: any;
  doc_shout_name: any;
  status: number=0;
  edit_doc_name: any;
  edit_doc_shout_name: any;
  edi_tstage: any;
  edit_stage: number=0;
  switch: any;
  renderer: any;
  isFavorite: boolean;
  editstage: number;
  stage_id : string;
  meraemi_stage: string;
  stage_type: string;
//  currentdate: string;
  constructor(fb: FormBuilder,
    private lenderContactService:LenderContactService,
    private leadsService:LeadsService,
    private masterService:MasterService,

    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
   
    public afAuth: AngularFireAuth,
    private excelService:ExcelService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,

    
    ) {

   
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      
 
         // validation 

     
      this.lenderproductoffer= new FormGroup({
        meraemi_stage: new FormControl(null, Validators.required),
        user_stage: new FormControl(null, Validators.required),
        stage_type: new FormControl(null, Validators.required),
        
      });
      this.editvalidatingForm= new FormGroup({
        edit_doc_name: new FormControl(null, Validators.required),
        edit_doc_shout_name: new FormControl(null, Validators.required),     
    });
   
    this.masterService
    .getCrmStatus()
    .subscribe( (result:any) => {
      console.log(result);
     if (result.status === true && result.response===200) { 
       for (let i = 0; i < result.meraemistage.length; i++) {
         const element = result.meraemistage[i];
         this.element.push(
           {
            stage_id: element.data[0].stage_id,
            meraemi_stage: element.data[0].meraemi_stage,
            user_stage: element.data[0].user_stage,
            stage_type: element.data[0].stage_type,
            status: element.data[0].status,  
            created_on: element.data[0].created_on,  
            
           });
     // console.log(this.element);
       }
       this.mdbTable.setDataSource(this.element);
       this.element = this.mdbTable.getDataSource();
       this.previous = this.mdbTable.getDataSource();
     }else{
       console.log("API Error");
     }
    }); 
 
    }
      get meraemis() {return this.lenderproductoffer.get('meraemi_stage'); } 
      get meraemic() {return this.lenderproductoffer.get('user_stage'); }     
      get meraemid() {return this.lenderproductoffer.get('stage_type'); }    
      //  edit
      get editN() {return this.editvalidatingForm.get('edit_doc_name'); } 
      get editL() {return this.editvalidatingForm.get('edit_doc_shout_name'); }     
     
      onSubmit() {
        let date_ob = new Date();
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // prints date in YYYY-MM-DD format    
        
      
       // this.currentdate =(year + "-" + month + "-" + date);
       this.currentdate =date_ob;
          this.custsD["results"] = [{
            "meraemistage":this.lenderproductoffer.value.meraemi_stage,
            "userstage":this.lenderproductoffer.value.user_stage,            
            "stagetype":this.lenderproductoffer.value.stage_type, 
            "status":'1',
            "created_on":this.currentdate,
            "created_by":'1'
          }];
        
   //  console.log(this.custsD);
       if(this.custsD){
      
         this.divaction=1;
      
        this.masterService.InsertCrmStatus(this.custsD).subscribe( 
            
          (Crmstage:any) => {
           // console.log(Crmstage);
            if(Crmstage.response=== 200 && Crmstage.status=== true){
              
              this.element.length=0;
              this.lenderproductoffer.reset();
              this.masterService
              .getCrmStatus()
              .subscribe( (result:any) => {
             //   console.log(result);
               if (result.status === true && result.response===200) { 
                 for (let i = 0; i < result.meraemistage.length; i++) {
                   const element = result.meraemistage[i];
                   this.element.push(
                     {
                      stage_id: element.data[0].stage_id,
                      meraemi_stage: element.data[0].meraemi_stage,
                      user_stage: element.data[0].user_stage,
                      stage_type: element.data[0].stage_type,
                      status: element.data[0].status,  
                      created_on: element.data[0].created_on, 
                      
                     });
                // console.log(this.element);
                 }
                 this.mdbTable.setDataSource(this.element);
                 this.element = this.mdbTable.getDataSource();
                 this.previous = this.mdbTable.getDataSource();
               }else{
                 console.log("API Error");
               }
              });
             
            }else{
              console.log("Insert Crm Status Masert Error");
            }
            
          
              
        
          })
       }else{
        console.log("form value invalide!");
       }
        
        
      
      }
      onNativeChange(){
        this.isFavorite = !(this.isFavorite);
        if(this.isFavorite ===true){
         this.editstage=1;
        }else{
          this.editstage=0;
        }
       // console.log(this.editstage);
        
      } 
      onNativeChangeischeck(){
        this.isFavorite = !(this.isFavorite);
        if(this.isFavorite ===true){
         this.editstage=0;
        }else{
          this.editstage=1;
        }
       // console.log(this.editstage);
        
      } 
      onSubmitedit(){
        let date_ob = new Date();
        
        if(this.editvalidatingForm.value.edit_doc_name !=null && this.editvalidatingForm.value.edit_doc_name !=""){
            this.edit_doc_name =this.editvalidatingForm.value.edit_doc_name;
        }else{
          this.edit_doc_name = this.meraemi_stage;
        }
        if(this.editvalidatingForm.value.edit_doc_shout_name !=null && this.editvalidatingForm.value.edit_doc_shout_name !="" ){
              this.edit_doc_shout_name =this.editvalidatingForm.value.edit_doc_shout_name;
          }else{
            this.edit_doc_shout_name =this.stage_type;
          }
          if(this.editstage !=undefined){
            this.edit_stage =this.editstage;
            }else{
              this.edit_stage=this.status;
            }
           
           
         
        this.edit["results"] = [{
          "edit_doc_name": this.edit_doc_name,
          "edit_doc_shout_name": this.edit_doc_shout_name,
          "edit_stage": this.edit_stage,          
          "id":  this.stage_id,
          "modified_by":"1",
          "modified_on":date_ob,
        }];
      //  console.log(this.edit);
        this.masterService.UpdateCrmStage(this.edit).subscribe((result:any) => {
          
          if(result.response=== 200 && result.status=== true){
              
            this.element.length=0;
            this.display = "none";
            this.masterService
            .getCrmStatus()
            .subscribe( (result:any) => {
              //console.log(result);
             if (result.status === true && result.response===200) { 
               for (let i = 0; i < result.meraemistage.length; i++) {
                 const element = result.meraemistage[i];
                 this.element.push(
                   {
                    stage_id: element.data[0].stage_id,
                      meraemi_stage: element.data[0].meraemi_stage,
                      user_stage: element.data[0].user_stage,
                      stage_type: element.data[0].stage_type,
                      status: element.data[0].status,  
                      created_on: element.data[0].created_on,   
                    
                   });
              // console.log(this.element);
               }
               this.mdbTable.setDataSource(this.element);
               this.element = this.mdbTable.getDataSource();
               this.previous = this.mdbTable.getDataSource();
             }else{
               console.log("API Error");
             }
            });
           
          }else{
            console.log("Insert Crm Status Masert Error");
          }
        });

      
      }
      lenderproductofferDelete(data){
        this.delete["results"] = [{
          "id":data,
  
        }];
    
      }
     
      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }
      
      openModalone(data1,data2,data3,data4,data5,data6,data7,data8) {
        this.stage_id =data1;
        this.meraemi_stage=data2;
        this.stage_type=data3;
        this.status =data4;
        this.display;
       
       // console.log(this.status);
    
      } 
      onCloseHandledone() {
    
        this.display = "none";
      }
   
     
      
}
