import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { LeadsService } from 'src/app/services/leads/leads.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { AngularFireAuth } from 'angularfire2/auth';
export interface Color {
  name: string;
}
@Component({
  providers: [DatePipe],
    selector: 'app-temp-self-emp-data',
    templateUrl: './temp-self-emp-data.component.html',
    styleUrls: ['./temp-self-emp-data.component.scss']
})
export class TempSelfEmpDataComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("tempid", {static: true}) tempid: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
 

  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
 
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
  decryptData: any;
  headElements = [
    {
      name:"Date",
      sortBy:"reg_date"
    }, 
    {
       name:"Temp Id",
       sortBy:"PS_id"
     },
     {
      name:"User Id",
      sortBy:"User_id"
    },    
    {
      name:"Ad Id",
      sortBy:"ad_id"
    },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Email",
       sortBy:"Email"
     },
     {
       name:"Mobile No",
       sortBy:"Mobile"
     },
     {
       name:"Emp Type",
       sortBy:"Amount"
     },
     
     {
       name:"Status",
       sortBy:"Status"
     },
      
    //  {
    //    name:"Action",
    //    sortBy:"Action"
    //  },
     
      
      


   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup; 
  submitted = false;
  from_user_id: any;
  to_user_id: any;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  messages: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  question: string;
  answer: string;
  email: string;
  whatsappSend: number=0;
  email_address: number;
  mobile_number: number;
  psid: number;
  sub: any;
 
  userdetils: {};
  userhavesendWhataap: number;
  user:any;
  rolecode: any;
  loginAdmin: any;
  adminname: string;
  totempid: any;
  fromtempid: any;
  temp_id: number=0;
  activetempid: string;
  leadstage:any =[];
  touserid: any;
  fromuserid: any;
  leadstages: string;
  leadstg: number;
  constructor( 
      private cryptojsService:CryptojsService,
      private firebasedataService:FirebasedataService,      
      public afAuth: AngularFireAuth,
      private formBuilder: FormBuilder,
      private route:ActivatedRoute,
      private router:Router,
      private cdRef: ChangeDetectorRef,
      private leadsService:LeadsService,private SelfEmpLeadService:SelfEmpLeadService,
      private FeedbackService:FeedbackService, private datePipe: DatePipe,) { }

  ngOnInit() {
  
    this.leadstage.push(
      {
       
                                          
       
       value: "Lead",
       label: "Lead",
      }, {
       
                                          
       
        value: "Registered",
        label: "Registered",
       
       }
      );
      this.optionsStage=this.leadstage;
         this.registerForm = this.formBuilder.group({
      dateFilter: this.formBuilder.group({
        fromdate: ['', Validators.required],
        todate: ['', Validators.required],
       
      }), 
      tempidfilter: this.formBuilder.group({
        fromtempid: ['', Validators.required],
        totempid: ['', Validators.required],
       
      }),  
      idFilter: this.formBuilder.group({
        fromuserid: ['', Validators.required],
        touserid: ['', Validators.required],
      }),
      
      adidFilter: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      
      stageFilter: this.formBuilder.group({
        stage: ['', Validators.required]
       
      }),
       
      
      
      
    });
    this.leadsService
    .GetTempSelfEmpDataController()
    .subscribe( (result:any) => {
      //
             if (result.status = 'true') {
               
               for (let i = 0; i < result.temp.length; i++) {
                 const menuarray = result.temp[i];
               // console.log(menuarray);
                if(menuarray.data[0].ad_id !="NA"){
                 this.All_ad_id.push(
                   {
                    
                                                       
                    
                    value: menuarray.data[0].ad_id,
                    label: menuarray.data[0].ad_id,
                   }
                   );
                   
                  }
               
                  
                   
               }
               const      adarray =(this.All_ad_id.reduce((acc, val) => {
                if (!acc.find(el => el.value === val.value)) {
                  acc.push(val);
                }
                return acc;
              }, []));
               this.optionsadid =adarray;
               
             }else{
              console.log('Ad Api faile');
            }
             
           });
    //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
            this.adminname=(this.user.first_name+' '+this.user.last_name);
      
          }
        )
      }
     
    
    });

          this.leadsService
        .GetTempSelfEmpDataController()
          .subscribe( (result:any) => {
     
            if (result.status === true && result.response === 200) {              
              for (let i = 0; i < result.temp.length; i++) {
                const element = result.temp[i];

               
            
              
                        if(element.data[0].email ==='NA'){
                          this.email=element.data[0].email;
                         }else{
                          this.email=element.data[0].personal_email;
                         }
                         if(element.data[0].email_address ==='NA'){
                          this.email_address=0;
                         }else{
                          this.email_address=1;
                         }
                         if(element.data[0].mobile_number ==='NA'){
                          this.mobile_number=0;
                         }else{
                          this.mobile_number=1;
                         }
                         if(element.data[0].psid ==='NA'){
                          this.psid=0;
                         }else{
                          this.psid=1;
                         }

                         
                        if(result.response=="200" && result.status==true){
                           this.userhavesendWhataap=1;
                           //object.push(this.userhavesendWhataap);
                        }else{
                          this.userhavesendWhataap=0;
                          //object.push(this.userhavesendWhataap);
                        }

                        if(element.data[0].user_id === "NA"){
                          this.leadstages="Lead";

                        }else{
                          this.leadstages="Registered";
                        }
                        this.elements.push(
                          {
                            
                            temp_id: element.data[0].temp_id,                   
                            mobileno:  element.data[0].mobileno, 
                            first_name:  element.data[0].first_name,              
                            middle_name:  element.data[0].middle_name,
                            last_name:  element.data[0].last_name,
                            email:  this.email,
                            EmpType:  element.data[0].EmpType,
                            created_on:  element.data[0].created_on,
                            user_id:  element.data[0].user_id,
                            psid:  this.psid,
                            mobile_number:  this.mobile_number,
                            email_address:  this.email_address,
                            ad_id:  element.data[0].ad_id,
                            feedbackid:  element.data[0].feedbackid,
                            userhavesendWhataap:this.userhavesendWhataap,
                            leadstages:this.leadstages
                          }
                          );
                          this.element_main.push(
                            {
                              
                              temp_id: element.data[0].temp_id,                   
                              mobileno:  element.data[0].mobileno, 
                              first_name:  element.data[0].first_name,              
                              middle_name:  element.data[0].middle_name,
                              last_name:  element.data[0].last_name,
                              email:  this.email,
                              EmpType:  element.data[0].EmpType,
                              created_on:  element.data[0].created_on,
                              user_id:  element.data[0].user_id,
                              psid:  this.psid,
                              mobile_number:  this.mobile_number,
                              email_address:  this.email_address,
                              ad_id:  element.data[0].ad_id,
                              feedbackid:  element.data[0].feedbackid,
                              userhavesendWhataap:this.userhavesendWhataap,
                              leadstages:this.leadstages
                            }
                            );
                                    // console.log(this.elements);
                                   
                      
                     
                
          
              }
              
              this.mdbTable.setDataSource(this.elements);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();

            }else{
              console.log('User list Api faile');
            }
          });
   
         
   
    
    
  }
  filterdate(){
    this.dateSubmitted = true;    
    // stop here if form is invalid
    if (this.dateFilter.invalid) {
      return;
    }else{
      this.valuemain=1;
      this.loan_date =1;
      this.activedate ="activbutton";
      this.frame.hide();

      //elig date
      this.fromdate =this.dateFilter.value.fromdate;
      this.todate =this.dateFilter.value.todate;
      //console.log(this.todate); return false;
        const data = this.mdbTable.getDataSource();
        //console.log(data); return false;
          const  fromdate = this.fromdate;
          const  todate = this.todate;
          
        //user id
        this.result = data.filter(d => {
          const  reg_date= d.created_on;
          const regdate = reg_date.split(' ')[0];
          //return (fromdate  <= regdate && regdate  <=  todate );            
          return (regdate >= fromdate  && regdate  <=  todate );
          
        });
        //console.log(this.result);
        this.elementsdate.length=0;
        if(this.result.length > 0){
          for (let i = 0; i < this.result.length; i++) {
            const elements = this.result[i];
        
            this.stage_color = "info-color";

            this.elementsdate.push(
              {
                temp_id             : elements.temp_id,                   
                mobileno            :  elements.mobileno, 
                first_name          :  elements.first_name,              
                middle_name         :  elements.middle_name,
                last_name           :  elements.last_name,
                email               :  elements.email,
                EmpType             :  elements.EmpType,
                created_on          :  elements.created_on,
                user_id             :  elements.user_id,
                psid                :  elements.psid,
                mobile_number       :  elements.mobile_number,
                email_address       :  elements.email_address,
                ad_id               :  elements.ad_id,
                feedbackid          :  elements.feedbackid,
                userhavesendWhataap : elements.userhavesendWhataap,
                leadstages          : elements.leadstages,
              }
              );
            // console.log(this.elementsfilter);
          }
        }else{
          this.messageDiv=1;
          this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
          this.clearid=14;
         
         
        }
       
        
        this.mdbTable.setDataSource(this.elementsdate);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        
    
    }
  }

  submitTempIdFilter(){
    this.dateSubmitted = true;    
    // stop here if form is invalid
    if (this.tempidfilter.invalid) {
      return;
    }else{
      this.valuemain=1;
      this.temp_id =1;
      this.activetempid ="activbutton";
      this.tempid.hide();

      //elig date
      this.fromtempid =this.tempidfilter.value.fromtempid;
      this.totempid =this.tempidfilter.value.totempid;
      //console.log(this.fromtempid);
     // console.log(this.totempid); return false;
        const data = this.mdbTable.getDataSource();
        //console.log(data); return false;
          const  fromtempid = this.fromtempid;
          const  totempid = this.totempid;
          
        //user id
        this.result = data.filter(d => {
          const  reg_date= d.temp_id;
          
          //return (fromdate  <= regdate && regdate  <=  todate );            
          return (reg_date >= fromtempid  && reg_date  <=  totempid );
          
        });
        //console.log(this.result);
        this.elementsdate.length=0;
        if(this.result.length > 0){
          for (let i = 0; i < this.result.length; i++) {
            const elements = this.result[i];
        
            this.stage_color = "info-color";

            this.elementsdate.push(
              {
                temp_id             : elements.temp_id,                   
                mobileno            :  elements.mobileno, 
                first_name          :  elements.first_name,              
                middle_name         :  elements.middle_name,
                last_name           :  elements.last_name,
                email               :  elements.email,
                EmpType             :  elements.EmpType,
                created_on          :  elements.created_on,
                user_id             :  elements.user_id,
                psid                :  elements.psid,
                mobile_number       :  elements.mobile_number,
                email_address       :  elements.email_address,
                ad_id               :  elements.ad_id,
                feedbackid          :  elements.feedbackid,
                userhavesendWhataap : elements.userhavesendWhataap,
                leadstages          : elements.leadstages,
              }
              );
            // console.log(this.elementsfilter);
          }
        }else{
          this.messageDiv=1;
          this.message ='Record not for Temp id  between'+"  "  +this.fromtempid+  '  To  '  + this.totempid;
          this.clearid=14;
         
         
        }
       
        
        this.mdbTable.setDataSource(this.elementsdate);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        
    
    }
  }
  filterUserId(){
    this.dateSubmitted = true;    
    // stop here if form is invalid
    if (this.idFilter.invalid) {
      return;
    }else{
      this.valuemain=1;
      this.userid =1;
      this.display = "none";
      this.tempid.hide();

      //elig date
      this.fromuserid =this.idFilter.value.fromuserid;
      this.touserid =this.idFilter.value.touserid;
      //console.log(this.fromtempid);
     // console.log(this.totempid); return false;
        const data = this.mdbTable.getDataSource();
        //console.log(data); return false;
          const  fromuserid = this.fromuserid;
          const  touserid = this.touserid;
          
        //user id
        this.result = data.filter(d => {
          const  userid= d.user_id;
          
          //return (fromdate  <= regdate && regdate  <=  todate );            
          return (userid >= fromuserid  && userid  <=  touserid );
          
        });
        //console.log(this.result);
        this.elementsdate.length=0;
        if(this.result.length > 0){
          for (let i = 0; i < this.result.length; i++) {
            const elements = this.result[i];
        
            this.stage_color = "info-color";

            this.elementsdate.push(
              {
                temp_id             :  elements.temp_id,                   
                mobileno            :  elements.mobileno, 
                first_name          :  elements.first_name,              
                middle_name         :  elements.middle_name,
                last_name           :  elements.last_name,
                email               :  elements.email,
                EmpType             :  elements.EmpType,
                created_on          :  elements.created_on,
                user_id             :  elements.user_id,
                psid                :  elements.psid,
                mobile_number       :  elements.mobile_number,
                email_address       :  elements.email_address,
                ad_id               :  elements.ad_id,
                feedbackid          :  elements.feedbackid,
                userhavesendWhataap : elements.userhavesendWhataap,
                leadstages          : elements.leadstages,
              }
              );
            // console.log(this.elementsfilter);
          }
        }else{
          this.messageDiv=1;
          this.message ='Record not for User id  between'+"  "  +this.fromuserid+  '  To  '  + this.touserid;
          this.clearid=14;
         
         
        }
       
        
        this.mdbTable.setDataSource(this.elementsdate);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        
    
    }
  }
  filterAdid() {
    this.adidSubmitted = true;
    if (this.adidFilter.invalid) {
      //return;
      alert('add value');
    }else{
      this.valuemain=1;           
      this.ad_id=1;
      this.basicModal4.hide();
      this.activeadid ="activbutton";
      // add id
      this.adid =this.adidFilter.value.adid;
      //console.log(this.adid);
      //console.log(this.result);
      const data = this.mdbTable.getDataSource();
     
      const  adid = this.adid;
     
      let filtered = [];
      
      adid.filter(function(newadid) {
        return   data.filter(function(mainArray) {
            if (newadid === mainArray.ad_id) {
            filtered.push({
              temp_id             :  mainArray.temp_id,                   
              mobileno            :  mainArray.mobileno, 
              first_name          :  mainArray.first_name,              
              middle_name         :  mainArray.middle_name,
              last_name           :  mainArray.last_name,
              email               :  mainArray.email,
              EmpType             :  mainArray.EmpType,
              created_on          :  mainArray.created_on,
              user_id             :  mainArray.user_id,
              psid                :  mainArray.psid,
              mobile_number       :  mainArray.mobile_number,
              email_address       :  mainArray.email_address,
              ad_id               :  mainArray.ad_id,
              feedbackid          :  mainArray.feedbackid,
              userhavesendWhataap :  mainArray.userhavesendWhataap,
              leadstages          : mainArray.leadstages,
            
            })
          }
        })
      });
      //console.log(filtered);
      this.elements_adid.length=0;
      if(filtered.length > 0){
        for (let i = 0; i < filtered.length; i++) {
            const adidelement = filtered[i];
          
            this.stage_color = "info-color";
    
            this.elements_adid.push(
              {
            
              temp_id             :  adidelement.temp_id,                   
              mobileno            :  adidelement.mobileno, 
              first_name          :  adidelement.first_name,              
              middle_name         :  adidelement.middle_name,
              last_name           :  adidelement.last_name,
              email               :  adidelement.email,
              EmpType             :  adidelement.EmpType,
              created_on          :  adidelement.created_on,
              user_id             :  adidelement.user_id,
              psid                :  adidelement.psid,
              mobile_number       :  adidelement.mobile_number,
              email_address       :  adidelement.email_address,
              ad_id               :  adidelement.ad_id,
              feedbackid          :  adidelement.feedbackid,
              userhavesendWhataap :  adidelement.userhavesendWhataap,
              leadstages          : adidelement.leadstages,
              
              }
              );
            // console.log(this.elementsfilter);
          }
      }else{
        this.messageDiv=1;
        this.message ='Record not for Add id'+"  "  +this.adid;
        this.clearid=14;
       
       
      }
      this.mdbTable.setDataSource(this.elements_adid);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      
      
    }
  }
  filterstage() {
    this.adidSubmitted = true;
    if (this.stageFilter.invalid) {
      //return;
      alert('add value');
    }else{
      this.valuemain=1;           
      this.leadstg=1;
      this.basicModal3.hide();
      this.activestage ="activbutton";
      // add id
      this.stage =this.stageFilter.value.stage;
      //console.log(this.adid);
      //console.log(this.result);
      const data = this.mdbTable.getDataSource();
     
      const  stage = this.stage;
     
      let filtered = [];
      
      stage.filter(function(newStage) {
        return   data.filter(function(mainArray) {
            if (newStage === mainArray.leadstages) {
            filtered.push({
              temp_id             :  mainArray.temp_id,                   
              mobileno            :  mainArray.mobileno, 
              first_name          :  mainArray.first_name,              
              middle_name         :  mainArray.middle_name,
              last_name           :  mainArray.last_name,
              email               :  mainArray.email,
              EmpType             :  mainArray.EmpType,
              created_on          :  mainArray.created_on,
              user_id             :  mainArray.user_id,
              psid                :  mainArray.psid,
              mobile_number       :  mainArray.mobile_number,
              email_address       :  mainArray.email_address,
              ad_id               :  mainArray.ad_id,
              feedbackid          :  mainArray.feedbackid,
              userhavesendWhataap :  mainArray.userhavesendWhataap,
              leadstages          : mainArray.leadstages,
            
            })
          }
        })
      });
      //console.log(filtered);
      this.elements_adid.length=0;
      if(filtered.length > 0){
        for (let i = 0; i < filtered.length; i++) {
            const adidelement = filtered[i];
          
            this.stage_color = "info-color";
    
            this.elements_adid.push(
              {
            
              temp_id             :  adidelement.temp_id,                   
              mobileno            :  adidelement.mobileno, 
              first_name          :  adidelement.first_name,              
              middle_name         :  adidelement.middle_name,
              last_name           :  adidelement.last_name,
              email               :  adidelement.email,
              EmpType             :  adidelement.EmpType,
              created_on          :  adidelement.created_on,
              user_id             :  adidelement.user_id,
              psid                :  adidelement.psid,
              mobile_number       :  adidelement.mobile_number,
              email_address       :  adidelement.email_address,
              ad_id               :  adidelement.ad_id,
              feedbackid          :  adidelement.feedbackid,
              userhavesendWhataap :  adidelement.userhavesendWhataap,
              leadstages          : adidelement.leadstages,
              
              }
              );
            // console.log(this.elementsfilter);
          }
      }else{
        this.messageDiv=1;
        this.message ='Record not for stage'+"  "  +this.stage;
        this.clearid=14;
       
       
      }
      this.mdbTable.setDataSource(this.elements_adid);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      
      
    }
  }
  clearUserid(userid){
    if(userid ===1){
      //user id
      this.elements.length = 0;
      this.clear_stagr.length = 0;
      this.valuemain=1;
      this.loan_date =0;
      this.activedate ="savemessage";
      this.frame.hide();
      this.dateFilter.reset();
      
    }else if(userid ===2){
      //user id
      this.elements.length = 0;
      this.clear_stagr.length = 0;
      this.valuemain=1;
      this.temp_id =0;
      this.activetempid ="savemessage";
      this.tempid.hide();
      this.tempidfilter.reset();
      
    }else if(userid ===3){
      //user id
      this.elements.length = 0;
      this.clear_stagr.length = 0;
      this.valuemain=1;
      this.userid =0;
      this.activeClass ="savemessage";
      this.display = "none";
      this.idFilter.reset();
      
    }else if(userid ===4){
      //user id
      this.elements.length = 0;
      this.clear_stagr.length = 0;
      this.valuemain=1;
      this.ad_id =0;
      this.activeadid ="savemessage";
      this.basicModal4.hide();
      this.adidFilter.reset();
      
    }else if(userid ===5){
      //user id
      this.elements.length = 0;
      this.clear_stagr.length = 0;
      this.valuemain=1;
      this.leadstg =0;
      this.activestage ="savemessage";
      this.basicModal3.hide();
      this.stageFilter.reset();
      
    }
    //date
    if(this.dateFilter.value.fromdate !='' && this.dateFilter.value.todate !=''){
      this.fromdate =this.dateFilter.value.fromdate;
      this.todate =this.dateFilter.value.todate;
  
    }else {
      this.fromdate =null;
      this.todate =null;
    }
     //userid
     if(this.idFilter.value.fromuserid !='' && this.idFilter.value.touserid !=''){
      this.fromuserid =this.idFilter.value.fromuserid;
      this.touserid =this.idFilter.value.touserid;
  
    }else {
      this.fromuserid =null;
      this.touserid =null;
    }
    //tempid
    if(this.tempidfilter.value.fromtempid !='' && this.tempidfilter.value.totempid !=''){
      this.fromtempid =this.tempidfilter.value.fromtempid;
      this.totempid =this.tempidfilter.value.totempid;
  
    }else {
      this.fromtempid =null;
      this.totempid =null;
    }
    //adid
    if(this.adidFilter.value.adid !='' ){
      this.adid =this.adidFilter.value.adid;
      this.filterarray.push(this.adid);

    }else {
      this.adid =null;
      this.filterarray.push(this.adid);
      
    }
    //stage
    if(this.stageFilter.value.stage !='' ){
      this.stage =this.stageFilter.value.stage;
      this.filterarray.push(this.stage);

    }else {
      this.stage =null;
      this.filterarray.push(this.stage);
      
    }

   
    var filter = {
      adid : this.filterarray[0],
      stage : this.filterarray[1],
      
   }
   const  fromdate = this.fromdate;
   const  todate = this.todate;
   const data = this.element_main;
   this.result = data.filter(d => {
    const  reg_date= d.created_on;
    const regdate = reg_date.split(' ')[0];
   
    const  tempid= d.temp_id;
    const  userid= d.user_id;
    
    return ((regdate >= fromdate  && regdate  <=  todate ) || (tempid >= this.fromtempid  && tempid  <=  this.totempid ) || (userid >= this.fromuserid  && userid  <=  this.touserid ) );
    
  });

// console.log(this.result.length); //return false;
 if(this.result.length > 0){
  const  resultarray= this.result;

  if(filter.adid != null){
    this.filtered_people = resultarray.filter((person) => (filter.adid.includes(person.ad_id)))
  
  }else{
    this.filtered_people=this.result;
  } 
  if(filter.stage != null){
    this.filtered_people = resultarray.filter((person) => (filter.stage.includes(person.leadstages)))
  
  }else{
    this.filtered_people = this.result;
  }
 }else{
  if(filter.adid != null){
    this.filtered_people = data.filter((person) => (filter.adid.includes(person.ad_id)))
  
  }else if(filter.stage != null){
    this.filtered_people = data.filter((person) => (filter.stage.includes(person.leadstages)))
  
  } else{
    this.filtered_people = this.element_main;
  }
 }

 this.mdbTable.setDataSource(this.filtered_people);
 this.elements = this.mdbTable.getDataSource();
 this.previous = this.mdbTable.getDataSource();

   

  }

  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
 
  adidSubmitted: boolean = false;
  useridSubmitted: boolean = false;
  sourceSubmitted: boolean = false;
  loanSubmitted: boolean = false;
  stageSubmitted: boolean = false;
  dateSubmitted: boolean = false;
  locationSubmitted: boolean = false;
  lenderSubmitted: boolean = false;
  crmstageSubmitted: boolean = false;
  userstageSubmitted: boolean = false;
  usersalarybtn: boolean = false;
  // Employer  Login Method
  get fh() { return (<FormGroup>this.registerForm.get('dateFilter')).controls; }

  get fc() { return (<FormGroup>this.registerForm.get('tempidfilter')).controls; }

  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }


  get fa() { return (<FormGroup>this.registerForm.get('adidFilter')).controls; }
  get fd() { return (<FormGroup>this.registerForm.get('loanFilter')).controls; }
  get fg() { return (<FormGroup>this.registerForm.get('stageFilter')).controls; }
  
  get fi() { return (<FormGroup>this.registerForm.get('locationFilter')).controls; }
  get lf() { return (<FormGroup>this.registerForm.get('lendernamefilter')).controls; }
  get lg() { return (<FormGroup>this.registerForm.get('crmstagefilter')).controls; }
  get lh() { return (<FormGroup>this.registerForm.get('userstagefilter')).controls; }
  get hc() { return (<FormGroup>this.registerForm.get('adidedit')).controls; }
  get usl() { return (<FormGroup>this.registerForm.get('usersalary')).controls; }
 
  get dateFilter(){
    return this.registerForm.get('dateFilter');
  }
  get tempidfilter() {
    return this.registerForm.get('tempidfilter');
  }

  get idFilter() {
    return this.registerForm.get('idFilter');
  }



  get adidedit() {
    return this.registerForm.get('adidedit');
  }
 

  
 get adidFilter(){
  return this.registerForm.get('adidFilter');
}
get loanFilter(){
  return this.registerForm.get('loanFilter');
}get stageFilter(){
  return this.registerForm.get('stageFilter');
}

get locationFilter(){
  return this.registerForm.get('locationFilter');
}
get lendernamefilter(){
  return this.registerForm.get('lendernamefilter');
}
get crmstagefilter(){
  return this.registerForm.get('crmstagefilter');
}
get userstagefilter(){
  return this.registerForm.get('userstagefilter');
}
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
   
    
     
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
    //user Details
    getLeadDetails(user,data){
      let stage=  data;
     // console.log(data);
     // console.log(user);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/Mmeraemi-criteria-fail-details?data=";        
     const detailsurl = base_url + this.useid.toString();
     const dateur=detailsurl+"&stage="+stage.toString();
    
     window.open(dateur, "_blank");
 }
 callIntPai(data){
  this.whatsappSend=1;
  var date = new Date();
  var transformDate = this.datePipe.transform(date, 'yyyy-MM-dd');
  let details = {
                      
    results: [{
      "user_id":"0", 
      "temp_id":data.temp_id,            
      "phoneNumber":data.mobileno,          
      "countryCode": "+91",
      "name":data.first_name+' '+data.last_name,
      "email":data.email,                      
      "campain_name":"SEECDM1",
      "meraemistage":"Eligibility Clear",
      "createdAt" :transformDate,
      "campain_id" :"29",
      "crmstage" :"3",
      "channel_id" :"4",
      "admin" :this.adminname,
      "feedmsg" :"Temp user send Interakt",
      
    
      
      
    }]

  
};

// console.log(details); return false;
this.SelfEmpLeadService.tempSelfEmpWhataap(details)
.subscribe((result: any) => { 
  
    if(result.response ==200 &&  result.status ==true){
       this.elements.length=0;
 
      
        this.leadsService
        .GetTempSelfEmpDataController()
          .subscribe( (results:any) => {
           
           // 
           //console.log(results); //return false;
            if (results.status === true && results.response === 200) {              
              for (let i = 0; i < results.temp.length; i++) {
                const element = results.temp[i];
        
               
            
              
                        if(element.data[0].email ==='NA'){
                          this.email=element.data[0].email;
                         }else{
                          this.email=element.data[0].personal_email;
                         }
                         if(element.data[0].email_address ==='NA'){
                          this.email_address=0;
                         }else{
                          this.email_address=1;
                         }
                         if(element.data[0].mobile_number ==='NA'){
                          this.mobile_number=0;
                         }else{
                          this.mobile_number=1;
                         }
                         if(element.data[0].psid ==='NA'){
                          this.psid=0;
                         }else{
                          this.psid=1;
                         }
        
                         
                        if(result.response=="200" && result.status==true){
                           this.userhavesendWhataap=1;
                           //object.push(this.userhavesendWhataap);
                        }else{
                          this.userhavesendWhataap=0;
                          //object.push(this.userhavesendWhataap);
                        }
                        if(element.data[0].user_id === "NA"){
                          this.leadstages="Lead";

                        }else{
                          this.leadstages="Registered";
                        }
                       
                        this.elements.push(
                          {
                            
                            temp_id: element.data[0].temp_id,                   
                            mobileno:  element.data[0].mobileno, 
                            first_name:  element.data[0].first_name,              
                            middle_name:  element.data[0].middle_name,
                            last_name:  element.data[0].last_name,
                            email:  this.email,
                            EmpType:  element.data[0].EmpType,
                            created_on:  element.data[0].created_on,
                            user_id:  element.data[0].user_id,
                            psid:  this.psid,
                            mobile_number:  this.mobile_number,
                            email_address:  this.email_address,
                            ad_id:  element.data[0].ad_id,
                            feedbackid:  element.data[0].feedbackid,
                            userhavesendWhataap:this.userhavesendWhataap,
                            leadstages:this.leadstages,
                          }
                          );
                     
                          this.whatsappSend=0;
                          // console.log(this.elements);
                          this.mdbTable.setDataSource(this.elements);
                          this.elements = this.mdbTable.getDataSource();
                          this.previous = this.mdbTable.getDataSource();
                      
                     
                
          
              }
              
                                   
            }else{
              console.log('User list Api faile');
            }
          });
         
       
      
    }else{
      console.log("api not call!");
    }

 
       
});
// console.log(data);
}
}


