import { Component, OnInit, ViewChild } from '@angular/core';

import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-meraemi-email-campaign',
  templateUrl: './meraemi-email-campaign.component.html',
  styleUrls: ['./meraemi-email-campaign.component.scss']
})
export class MeraemiEmailCampaignComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;
  optionslocation: any = []; 
  optionsTemplate: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  smsvalidatingForm: FormGroup;
  emailvalidatingForm: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  constructor(fb: FormBuilder) {

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactFormCopy': ['', Validators.requiredTrue],

      'campaignName': ['', Validators.requiredTrue],
      'location': ['', Validators.requiredTrue],
      });
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      this.validatingForm = new FormGroup({
        campaignSource: new FormControl(null, Validators.required),
        campaignStage: new FormControl(null, Validators.required),
        campaignSerialNo: new FormControl(null, Validators.required),
        
      });
      this.emailvalidatingForm= new FormGroup({
        campaignid: new FormControl(null, Validators.required),
        mailguntemplatename: new FormControl(null, Validators.required),
        previousCampaignID: new FormControl(null, Validators.required),
        campaignSubject: new FormControl(null, Validators.required),
        urltags: new FormControl(null, Validators.required),
        SourceText: new FormControl(null, Validators.required),
      });

      this.smsvalidatingForm= new FormGroup({
        smsSource: new FormControl(null, Validators.required),
        smsSerialNo: new FormControl(null, Validators.required),
        smsName: new FormControl(null, Validators.required),
        smsubject: new FormControl(null, Validators.required),
        message: new FormControl(null, Validators.required),
        smsStage: new FormControl(null, Validators.required),
        smsSchedule: new FormControl(null, Validators.required),
      });
      this.optionslocation= [
        { value: 'Eligibility Pending', label: 'Eligibility Pending' },      
        { value: 'Eligibility Clear', label: 'Eligibility Clear' },      
        { value: 'Eligibility Fail', label: 'Eligibility Fail' },
        { value: 'IAP Pending', label: 'IAP Pending' },
        { value: 'IAP Complete', label: 'IAP Complete' },
        { value: 'IAP Error', label: 'IAP Error' },
        { value: 'IAP Fail', label: 'IAP Fail' },
        { value: 'IAP Success', label: 'IAP Success' },    
        { value: 'Success', label: 'Success' },
          
          ];  
      this.optionsTemplate=[
        { value: 'template.dm1', label: 'template.dm1' },
        { value: 'template.dm2', label: 'template.dm2' },
        { value: 'template.dm3', label: 'template.dm3' },
        { value: 'template.dm4', label: 'template.dm4' },
        { value: 'template.dm5', label: 'template.dm5' },
        { value: 'template.dm6', label: 'template.dm6' },
      ] ;
      this.optionsSelect = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '29', label: '29' },
        { value: '30', label: '30' },
        ];
      this.optionsSource=[
        { value: 'EMAIL', label: 'EMAIL' },
        { value: 'FB BOT', label: 'FB BOT' },
        { value: 'SMS', label: 'SMS' }
      
      ];
      this.optionstags =[
        { value: 'EML', label: 'EML' },
        { value: 'FBB', label: 'FBB' },
        { value: 'SMS', label: 'SMS' },
        { value: 'EC', label: 'EC' },
        { value: 'EP', label: 'EP' },
        { value: 'EF', label: 'EF' },
        { value: 'IAPP', label: 'IAPP' },
        { value: 'IAPF', label: 'IAPF' },
        { value: 'IAPC', label: 'IAPC' },
        { value: 'IAPS', label: 'IAPS' },        
        { value: 'Campaign ID', label: 'Campaign ID' },
        { value: 'Session', label: 'Session' },
      ]
      }
      get source() {  return this.validatingForm.get('campaignSource');  }
      get stage() { return this.validatingForm.get('campaignStage');   }     
      get serialno() {  return this.validatingForm.get('campaignSerialNo');  }

      

      get cid() { return this.emailvalidatingForm.get('campaignid'); }
      get tempid() { return this.emailvalidatingForm.get('mailguntemplatename'); } 
      get pschedule() {  return this.emailvalidatingForm.get('previousCampaignID');  } 
      get mesub() {        return this.emailvalidatingForm.get('campaignSubject');      }
      get tag() {  return this.emailvalidatingForm.get('urltags');  }      
      get text() {    return this.emailvalidatingForm.get('SourceText');   }

      
      get sname() {
        return this.smsvalidatingForm.get('smsName');
      }
      get ssource() {
        return this.smsvalidatingForm.get('smsSource');
      }
     
     
      get smsno() {
        return this.smsvalidatingForm.get('smsSerialNo');
      }
      get smstg() {
        return this.smsvalidatingForm.get('smsStage');
      }
      get smsched() {
        return this.smsvalidatingForm.get('smsSchedule');
      }
      
      onSubmit() {
        this.campaignSerialNo=this.validatingForm.value.campaignSerialNo;
        this.campaignSource=this.validatingForm.value.campaignSource;
        this.campaignStage=this.validatingForm.value.campaignStage;
        if( this.campaignSource==="EMAIL"){
          this.stagetemp=1;
        }else if( this.campaignSource==="FB BOT"){
          this.stagetemp=2;
        }else if( this.campaignSource==="SMS"){
          this.stagetemp=3;
        }
        if(this.campaignStage==="Eligibility Pending"){
          this.urlstg="EL";

        }else if(this.campaignStage==="Eligibility Clear"){
          this.urlstg="EC";

        }else if(this.campaignStage==="Eligibility Fail"){
          this.urlstg="EF";
        }else if(this.campaignStage==="IAP Pending"){
          this.urlstg="IP";
        }else if(this.campaignStage==="IAP Complete"){
          this.urlstg="IC";
        }else if(this.campaignStage==="IAP Error"){
          this.urlstg="IE";
        }else if(this.campaignStage==="IAP Fail"){
          this.urlstg="IF";
        }else if(this.campaignStage==="IAP Success"){
          this.urlstg="IS";
        }else if(this.campaignStage==="Success"){
          this.urlstg="S";
        }
        this.QeryUrl= (this.campaignSource+this.urlstg+0+this.campaignSerialNo);
      console.log(this.QeryUrl);
      // console.log(this.emailvalidatingForm);
      }
      onSubmitEmail(){
        console.log(this.validatingForm);
        console.log(this.emailvalidatingForm);
      }
  onClick(check){
    //console.log(check);
    if(check==1){
      this.tab = 'tab1';
      this.tabval=1;
    }else if(check==2){
      this.tab = 'tab2';
      this.tabval=2;
    }else if(check==3){
      this.tab = 'tab3';
      this.tabval=3;
    }     
  
}
onSwitch(check){

switch (check) {
 case 1:
   return 'tab1';
 case 2:
   return 'tab2';
   case 3:
   return 'tab3';
 
}
}
onChange(value) {
  if(value ==='1'){
    this.tempSchedule=1;
  }else if(value ==='2'){
    this.tempSchedule=3;
  }else if(value ==='3'){
    this.tempSchedule=6;
  }
 // console.log(this.tempSchedule);
}
onChangesms(value) {
  if(value ==='1'){
    this.tempSchedule=1;
  }else if(value ==='2'){
    this.tempSchedule=3;
  }else if(value ==='3'){
    this.tempSchedule=6;
  }
 // console.log(this.tempSchedule);
}
valueChange(value) {
  //console.log(value);
  if(value !=undefined){
    this.remainingText = 160 - value.length;
  //console.log(this.remainingText);
  }
  
  
 }
 valueChangefb(value){
  if(value !=undefined){
    this.remainingTextfb = 160 - value.length;
  //console.log(this.remainingText);
  }
 }
}
