import { Component, OnInit, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription, Observable } from 'rxjs';
import { LoansService } from 'src/app/services/loans/loans.service';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';
import {ExcelService} from 'src/app/services/excel/excel.service';
import * as XLSX from 'xlsx'; 
import { MisSendLenderService } from 'src/app/services/mis-send-lender/mis-send-lender.service';
@Component({
    selector: 'app-mis-send-lender',
    templateUrl: './mis-send-lender.component.html',
    styleUrls: ['./mis-send-lender.component.scss']
})
export class MisSendLenderComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;

  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  smsvalidatingForm: FormGroup;
  emailvalidatingForm: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  currentdate: string;
  elements: any = []; 
  lenderName: any = []; 
  locationName: any = []; 
  location: any = []; 
  lendeEmail: any = []; 
  lende_email: any = [];
  divaction: number=0;
  sub: any;
  user: User;
  rolecode: any;
  loginAdmin: any;
  data: any;
  feedback_Stage: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  cust: any;
  userdetils: {};
  leadDetails: any;
  provider: string;
  interest_rate: any;
  ProposedEmi: any;
  e_emi: number;
  foir: any;
  iapstage: string;
  mapstage: string;
  iapstageBackColour: string;
  iap_stage_color: string;
  trxStatus: any;
  trxSubStatus: any;
  isTrackProgress: any;
  requestJsonDisabled: any;
  responseJsonDisabled: any;
  trackProgressJsonDisabled: any;
  showDocuments: boolean;
  jsonresp: number;
  mainobject: any;
  previous: any;
  element:any = [];
  lendername: any;
  lenderLocation: any;
  lenderEmail: any;
  empPerformance: any;
  dataForExcel: any=[];
  lender_name: string;
  lender_Location: string;
  lender_Email: string;
  current_date: string;
  loan_code: string;
  sub_stage: string;
  sub_stage_title: string;
  iap_user_name: any;
  pan_no: any;
  fatherFirstName: string;
  current_address: string;
  current_landmark: any;
  own_house: any;
  residence_no: any;
  pre_monile_no: any;
  email: any;
  office_email: any;
  office_no: any;
  office_address: string;
  office_landmark: any;
  Qualification: any;
  marital_status: any;
  current_company: any;
  cur_res_since_from: any;
  loan_amount: any;
  Reference1: any;
  refernceName1: string;
  refernceContact1: any;
  Reference2: any;
  refernceName2: string;
  refernceContact2: any;
  mother_name: any;
  constructor(fb: FormBuilder,private lenderContactService:LenderContactService,private leadsService:LeadsService,
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
   
    public afAuth: AngularFireAuth,
    private excelService:ExcelService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    public ete: MisSendLenderService
    
    ) {

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactFormCopy': ['', Validators.requiredTrue],

      'campaignName': ['', Validators.requiredTrue],
      'location': ['', Validators.requiredTrue],
      });
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
       //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
           // this.rolecode= this.user.rolecode;
            //this.loginAdmin= this.user.first_name;
           //console.log(data)
          }
         
        )
        //console.log(this.user);
      }
     
    
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        this.feedback_Stage=params.stage;
       // console.log(out);
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
         
          //console.log(this.decryptData);
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"user_id":parseInt(this.decryptData)}];
            //console.log(this.custsD)
            this.cust = this.custsD["results"];
            //console.log(this.custsD["results"])
           
            this.userdetils =this.custsD;
          }else{
            this.showLoader = true;    
            this.router.navigate(['/leads']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
    // this.htmlstring = 'Plain Text Example &amp; <strong>Bold Text Example</strong>';
     //console.log(this.htmlstring); die();
      this.emailvalidatingForm= new FormGroup({
        lenderLocation: new FormControl(null, Validators.required),
        lenderName: new FormControl(null, Validators.required),
        lenderEmail: new FormControl(null, Validators.required),

        
      });
    //  Lender name
      this.lenderContactService
     .getLenderName()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.lender_name.length; i++) {
                 const element = result.lender_name[i];
               //  console.log(element);
                 this.elements.push(
                  {
                    value: element.data[0].lender_name,
                    label: element.data[0].lender_name, 

                                 
                  });
        }
        this.lenderName =this.elements;
      // console.log(this.elements);
      }
       
     });
      //  Lender name
      this.lenderContactService
     .GetAllLendercontact()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.Lendercontact.length; i++) {
                 const element = result.Lendercontact[i];
               //  console.log(element);
                 this.location.push(
                  {
                    value: element.data[0].location,
                    label: element.data[0].location, 
                     
                                 
                  });
        }
        this.locationName =this.location;
      // console.log(this.elements);
      }
       
     });
     //  Lender Email
     this.lenderContactService
     .GetAllLendercontact()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.Lendercontact.length; i++) {
                 const element = result.Lendercontact[i];
               //  console.log(element);
                 this.lendeEmail.push(
                  {
                    value: element.data[0].contact_email,
                    label: element.data[0].contact_email, 
                     
                                 
                  });
        }
        this.lende_email =this.lendeEmail;
      // console.log(this.elements);
      }
       
     });
      }
      get lenderN() {return this.emailvalidatingForm.get('lenderName'); } 
      get lenderL() {return this.emailvalidatingForm.get('lenderLocation'); }     
      get lenderE() {return this.emailvalidatingForm.get('lenderEmail');  } 

     
      
      onSubmit() {
        let date_ob = new Date();
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // prints date in YYYY-MM-DD format    
        
      
        this.currentdate =(year + "-" + month + "-" + date);
       
        let details = [{
           "lender_name":this.emailvalidatingForm.value.lenderName,
           "location":this.emailvalidatingForm.value.lenderLocation,            
           "contact_email":this.emailvalidatingForm.value.lenderEmail,                  
           "date":this.currentdate,
          
      }];
       if(details.length!=0){
         this.lendername=this.emailvalidatingForm.value.lenderName;
         this.lenderLocation=this.emailvalidatingForm.value.lenderLocation;
         this.lenderEmail=this.emailvalidatingForm.value.lenderEmail;
         this.currentdate=this.currentdate;
         this.divaction=1;
        //console.log(details);
        //console.log(this.userdetils);
        this.loansService.getLoanDetByUser(this.userdetils).subscribe( 
            
          (leadres:any) => {
           //console.log(leadres);
            if(leadres.status != false){
              this.leadDetails =leadres['data'];
      
              if(this.leadDetails[0].loans[0].userDetails.provider_id == "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }
             // console.log(this.provider);
              this.leadDetails.forEach(element => {
                
                 //console.log(this.elig_status);
                let monthlyIncome = Math.round(element.loans[0].employmentDetails.net_monthly_income);
  
                let lender_id =element.loans[0].lenderDetails.lender_id;
                let product_id = element.loans[0].loanDetails.product_id;
  
               //console.log(lender_id)
                this.custsD["results"] = [{
                  "user_income":monthlyIncome.toString(),
                  "lender_id":lender_id,
                  "product_id":product_id,
                }];
              
                //console.log(this.custsD)
                this.leadsService.getSuccessParameters(this.custsD).subscribe( 
                  (result:any) => {
                 // console.log(result.data)
                    if(result.status ==true){

                      this.interest_rate =result.data[0].interest_rate;
                      let tenure = element.loans[0].loanDetails.loan_tenure;
                      let principal = element.loans[0].loanDetails.loan_amount;
                      if(this.interest_rate != undefined  && tenure != undefined  && principal != undefined ){
                        this.ProposedEmi = this.calculateEMI(this.interest_rate,tenure,principal);
                      }
                       if(isNaN(this.ProposedEmi)){
                        this.ProposedEmi = 0;
                      }
                      let existingEmi = element.loans[0].employmentDetails.total_emi_paid;
                      
                      if(existingEmi != undefined && existingEmi != 0){
                        this.e_emi = Math.round(existingEmi);
        
                      }else{
                        this.e_emi = 0;
                      }
                    
                      this.foir = this.calculateFOIR(this.e_emi,this.ProposedEmi,monthlyIncome);
                      
                      if(element.loans[0].app_stage == "2" ){
                        this.iapstage = "IAP Pending";
                        this.mapstage = "1";
                      }
                      if(element.loans[0].app_stage == "3" ){
                        this.iapstage ="IAP Complete";
                          this.iapstageBackColour ="iap_complete";
                          this.mapstage = "1";
                      }
                      if(element.loans[0].resp_status_code != undefined){
  
                        if(element.data[0].iap_status.resp_status_code == "1" ){
                          this.iapstage ="IAP Complete";
                          this.iapstageBackColour ="iap_complete";
                          this.mapstage = "1";
                        }
                        if(element.data[0].iap_status.resp_status_code == "0" || element.data[0].iap_status.resp_status_code == "3" || element.data[0].iap_status.resp_status_code == "4"){
                          this.iapstage ="IAP Fail";
                          this.iapstageBackColour ="iap_fail";
                          this.mapstage = "1";
                        }
                      }
                     // console.log(element.loans[0].Idfc_response.sfdc_id)
                      if(element.loans[0].Idfc_response != "undefined" && element.loans[0].Idfc_response){
                          ///iap stage 
                          if(element.loans[0].Idfc_response.sfdc_id != "null" || element.loans[0].Idfc_response.trx_status == 'DDE' || element.loans[0].Idfc_response.trx_status == 'QDE'){
                          
                            this.iapstage ="IAP Complete";
                            this.iapstageBackColour ="iap_complete";
                            this.mapstage = "1";
                          } 
                          if(element.loans[0].Idfc_response.sfdc_id == "null" || element.loans[0].Idfc_response.trx_status == 'Cancel' || element.loans[0].Idfc_response.sfdc_id == undefined || element.loans[0].Idfc_response.status == '0'){
                            this.iapstage ="IAP Error";
                          } 
                          if(element.loans[0].Idfc_response.trx_status == 'Reject'){
                            this.iapstage ="IAP Fail";
                            this.iapstageBackColour ="iap_fail";
                            this.iap_stage_color ="danger";
                            this.mapstage = "1";
                          } 
                          if(element.loans[0].Idfc_response.trx_status == 'Data Verification'){
                            this.iapstage = "IAP Success";
                            this.iapstageBackColour ="iap_success";
                            this.mapstage = "1";
                          }
                        
                        this.trxStatus = element.loans[0].Idfc_response.trx_status;
                        this.trxSubStatus = element.loans[0].Idfc_response.trx_substatus;
                     
                     // console.log(this.lenderMainArrya);
                        //check user trackprogress status btn enable disable
                        if(element.loans[0].Idfc_response.trx_status == "DDE" || element.loans[0].Idfc_response.trx_status == "QDE" || element.loans[0].Idfc_response.trx_status == "Data Verification"){
                          this.isTrackProgress = this.isTrackProgress;
  
                        }else{
                          this.isTrackProgress = !this.isTrackProgress;
                        }
  
                        //request and response json btn enable disable
                  
                        if(element.loans[0].Idfc_response.request_file_name == '' || element.loans[0].Idfc_response.request_file_name == 'null'  || element.loans[0].Idfc_response.request_file_name == 'undefined'){
                          this.requestJsonDisabled = this.requestJsonDisabled;
                        }else{
                          this.requestJsonDisabled = !this.requestJsonDisabled;
                        }
                      
                       
                        if(element.loans[0].Idfc_response.response_file_name == '' || element.loans[0].Idfc_response.response_file_name == "null" && element.loans[0].Idfc_response.response_file_name != 'undefined'){
                          this.responseJsonDisabled = this.responseJsonDisabled;
                        }else{
                          this.responseJsonDisabled = !this.responseJsonDisabled;
  
                        }
                        if(element.loans[0].Idfc_response.trackprogress_file_name == '' || element.loans[0].Idfc_response.trackprogress_file_name == "null" && element.loans[0].Idfc_response.trackprogress_file_name != 'undefined'){
                          this.trackProgressJsonDisabled = this.trackProgressJsonDisabled;
                        }else{
                          this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
  
                        }
                        
                      }else{
                        this.isTrackProgress = !this.isTrackProgress;
                        this.requestJsonDisabled = !this.requestJsonDisabled;
                        this.responseJsonDisabled = !this.responseJsonDisabled;
                        this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
                      }
                      
                    }
                  });
                
                 // console.log(element.loans[0].elig_fail_quest)
                  if(element.loans[0].elig_fail_quest){
                    for (let index = 0; index < element.loans[0].elig_fail_quest.length; index++) {
                      const value = element.loans[0].elig_fail_quest;
                     //console.log(element.loans[0].elig_fail_quest.length)
                     value.forEach(result=> {
                      
                        // console.log(result.quest_id)
                        // console.log(result.quest_value)
                        // console.log(element.loans[0].loanDetails.loan_amount)
                        // if(result.quest_id == 14){
                        //   console.log(element.loans[0].loanDetails.loan_amount)
                        // }
                      });
                    
                    }
                  }
                  if(element.loans[0].documents){
                    for (let index = 0; index < element.loans[0].documents.length; index++) {
                      const elements = element.loans[0].documents;
                     // console.log(elements)
                      elements.forEach(result=> {
                        if(result['DocTypeId'] !=undefined){
                          if(result['DocTypeId'].length < element.loans[0].documents.length){
                          //  console.log(true)
                            this.showDocuments=true;
                          }else{
                         //   console.log(false)
                            this.showDocuments=false;
                          }
                        }
                       
                      });
                    
                    }
                  }
                
              
              });
              //main console
              if(this.leadDetails[0].loans[0].Idfc_response !=undefined){
                this.jsonresp =1;
              }else{
                this.jsonresp =0;
              }
          //  console.log(this.leadDetails[0].loans[0].Idfc_response);
              if(this.leadDetails[0].loans[0].loanDetails.lender_name ==="Offline"){
                this.iapstage ="IAP Complete";
                    this.iapstageBackColour ="iap_complete";
                    this.mapstage = "1";
              }
             
             this.mainobject =this.leadDetails[0].loans[0].userMisLender;
             this.lender_name=("Lender Name:"+this.emailvalidatingForm.value.lenderName);
             this.lender_Location=("Location :"+this.leadDetails[0].loans[0].userDetails.location_city);
             this. lender_Email=("Email Address :"+this.emailvalidatingForm.value.lenderEmail);
             this.current_date=("Lead submission date:"+this.currentdate);
             this.current_date=("Lead submission date:"+this.currentdate);
             this.loan_code=("Application ID :"+this.leadDetails[0].loans[0].userMisLender.loan_code);
             this.sub_stage=("PRIVATE CONFIDENTIAL – NOT TO BE SHARED");
             this.sub_stage_title=("CHECKLYST SOLUTIONS PVT LTD");
            // body text
            
            this.iap_user_name=(this.leadDetails[0].loans[0].iapDetails.iap_fname+'   '+this.leadDetails[0].loans[0].iapDetails.iap_mname+'  '+this.leadDetails[0].loans[0].iapDetails.iap_lname);
            this.pan_no=(this.leadDetails[0].loans[0].userMisLender.pan_no);
            this.fatherFirstName=(this.leadDetails[0].loans[0].userMisLender.fatherFirstName+' '+this.leadDetails[0].loans[0].userMisLender.fatherLastName);
            this.current_address=(this.leadDetails[0].loans[0].userMisLender.address_1+' '+this.leadDetails[0].loans[0].userMisLender.address_2
            +' '+this.leadDetails[0].loans[0].userMisLender.location+' '+this.leadDetails[0].loans[0].userMisLender.location_city);
            this.current_landmark=this.leadDetails[0].loans[0].userMisLender.address_2;
            this.own_house=this.leadDetails[0].loans[0].userMisLender.residence;
            this.residence_no=this.leadDetails[0].loans[0].userMisLender.mobile_number;
            this.pre_monile_no=this.leadDetails[0].loans[0].userMisLender.mobile_number;
            this.email=this.leadDetails[0].loans[0].userMisLender.personal_email;
            this.office_email=this.leadDetails[0].loans[0].empaddressDetails.employer_office_email;
            this.office_no=this.leadDetails[0].loans[0].userMisLender.employer_office_landline;
            this.office_address=(this.leadDetails[0].loans[0].userMisLender.employer_address1+' '+
            this.leadDetails[0].loans[0].userMisLender.employer_address2+' '+
            this.leadDetails[0].loans[0].userMisLender.employer_address3+' '+
            this.leadDetails[0].loans[0].userMisLender.employer_pin_code);
            this.office_landmark =this.leadDetails[0].loans[0].userMisLender.employer_address2;
            this.Qualification=this.leadDetails[0].loans[0].userMisLender.edu_degree_level_name;
          
            this.current_company=this.leadDetails[0].loans[0].employmentDetails.name_of_employer;
            this.cur_res_since_from=this.leadDetails[0].loans[0].resaddressDetails.cur_res_since_from;
            this.loan_amount=this.leadDetails[0].loans[0].loanDetails.loan_amount;
            this.Reference1=this.leadDetails[0].loans[0].userMisLender.ref_relation;
            this.refernceName1=this.leadDetails[0].loans[0].userMisLender.ref_title+' '+this.leadDetails[0].loans[0].userMisLender.ref_first_name
            +' '+this.leadDetails[0].loans[0].userMisLender.ref_last_name; 
            this.refernceContact1=this.leadDetails[0].loans[0].userMisLender.ref_mobile_no; 
            this.Reference2=this.leadDetails[0].loans[0].userMisLender.ref_relation1;
            this.refernceName2=this.leadDetails[0].loans[0].userMisLender.ref_title+' '+this.leadDetails[0].loans[0].userMisLender.ref_first_name1
            +' '+this.leadDetails[0].loans[0].userMisLender.ref_last_name1;
            this.refernceContact2= this.leadDetails[0].loans[0].userMisLender.ref_mobile_no1; 
            this.marital_status=this.leadDetails[0].loans[0].userMisLender.marital_status_name;
            this.mother_name=this.leadDetails[0].loans[0].userMisLender.mother_maiden_name;
            // console.log(this.leadDetails);
            // APPLICATION ID – PL0520187161
           
           
             //console.log(this.leadDetails);
             //const  out =  this.mainobject.office_email.replace(/\s/g, "+");
              //let email = this.cryptojsService.decryptData(out);
             // console.log(email);
                        
              //this.mdbTable.setDataSource(this.element);
              //this.elements = this.mdbTable.getDataSource();
              //this.previous = this.mdbTable.getDataSource();
                        
  
            
            
            
            }
              
        
          })
       }else{
        console.log("form value invalide!");
       }
        
        
      
      }
     
      calculateEMI(interest:number,tenure:number,principal:number){
        var emi = 0;
        var a = (1 + interest / 1200);
        var b = Math.pow(a, tenure);
        var c = Math.pow(a, tenure) - 1;
        emi = Math.round(principal * (interest / 1200) * b / c);
        //console.log(emi)
        return emi;
      }
      calculateFOIR(existemi:number,proposedemi:number,income:number){
        var foir= 0;
       // console.log(existemi)
       // console.log(proposedemi)
      //  console.log(income)
        foir = ((existemi + proposedemi) / income * 100);
       // console.log(foir)
        return foir;
      }
      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }
      exportToExcel() {

        this.element.forEach((row: any) => {
          this.dataForExcel.push(Object.values(row))
        })
    
        let reportData = {
          title: 'MeraEMI.Com',
         // titletext2: 'titletext2',
          data: {
            left: {
              text1: "User Name",
              text2: "PAN  Number",
              text3: "Father Name",
              text4: "Mother Name",
              text5:"Current Address",
              text6:"Current Address Landmark",
              text7:"Own house / Rented",
              text8:"Permanent Address",
              text9:"Permanent Address Landmark",
              text10:"Residence Telephone/Mobile No",
              text11:"Permanent Address Telephone/ Mobile No",
              text12:"Email",
              text13:"Official Email",
              text14:"Office Landline No",
              text15:"Office Address",
              text16:"Office  Landmark",
              text17:"Education Qualification",
              text18:"Marital Status",
              text19:"current company",
              text20:"No of years in current Residence",             
              text21:"Loan Amount",
              text22:"Reference 1 (Relation)",
              text23:"Refernce Name",
              text24:"Reference Contact No",
              text25:"Reference 2 (Relation)",
              text26:"Refernce Name",
              text27:"Reference Contact No",
            }, right: {
              text1: this.iap_user_name,
              text2: this.pan_no,
              text3: this.fatherFirstName,
              text4: this.mother_name,
              text5: this.current_address,
              text6: this.current_landmark,
              text7: this.own_house,
              text8: this.current_address,
              text9: this.current_landmark,
              text10: this.residence_no,
              text11: this.pre_monile_no,
              text12: this.email,
              text13: this.office_email,
              text14: this.office_no,
              text15:this.office_address,
              text16:this.office_landmark,
              text17:this.Qualification,
              text18:this.marital_status,
              text19:this.current_company,
              text20:this.cur_res_since_from,
              text21:this.loan_amount,
              text22:this.Reference1,
              text23:this.refernceName1,
              text24:this.refernceContact1,
              text25:this.Reference2,
              text26:this.refernceName2,
              text27:this.refernceContact2,
            },
          },
          headers: {
            left: {
              text1: this.sub_stage_title,
              text2: this.loan_code,
              text3: this.lender_Location
            },
            right: {
              text1: this.lender_name,
              text2: this. lender_Email,
              text3: this.current_date,
              text4: this.sub_stage
            },
          }
        //  headers: Object.keys(this.dataForExcel)
        }
    
        this.ete.exportExcel(reportData);
      }
     
}

function die() {
  throw new Error('Function not implemented.');
}

