import { Component, OnInit, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription, Observable } from 'rxjs';
import { LoansService } from 'src/app/services/loans/loans.service';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';
import {ExcelService} from 'src/app/services/excel/excel.service';
import * as XLSX from 'xlsx'; 
import { MisSendLenderService } from 'src/app/services/mis-send-lender/mis-send-lender.service';
@Component({
    selector: 'app-meraemi-send-user-mail',
    templateUrl: './meraemi-send-user-mail.component.html',
    styleUrls: ['./meraemi-send-user-mail.component.scss']
})
export class MeraemiSendUserMailComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;

  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  editvalidatingForm: FormGroup;
  lenderproductoffer: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  
  elements: any = []; 
  lenderName: any = []; 
  locationName: any = []; 
  location: any = []; 
  lendeEmail: any = []; 
  lende_email: any = [];
  divaction: number=0;
  sub: any;
  user: User;
  rolecode: any;
  loginAdmin: any;
  data: any;
  feedback_Stage: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  delete = {};
  cust: any;
  userdetils: {};
  edit = {};
  previous: any;
  element:any = [];

 
  currentdate: Date;
  headElements = [
    {
       name:"ID",
       sortBy:"id"
     },
     {
       name:"Email ID",
       sortBy:"lender_name"
     },
     {
      name:"Email Status Date",
      sortBy:"rate"
    },
     {
       name:"Email Status",
       sortBy:"product_name"
     },
     {
       name:"Email type",
       sortBy:"rate"
     },
     
     {
       name:"Email Send Date",
       sortBy:"tenure"
     },{
      name:"Status",
      sortBy:"tenure"
    }
     
        ];
  display: string;
  id: string;
  product_name: string;
  rate_of_interest_from: string;
  tenure: string;
  interest_rate_as_on: string;
  lenderid: string;
  registerForm: FormGroup;
  lenderdate: string;
  lender_id: string;
  product_id: string;
  rate_from: string;
  tenureedit: string;
  editlenderid: any;
  editproductid: any;
//  currentdate: string;
  constructor(fb: FormBuilder,private lenderContactService:LenderContactService,private leadsService:LeadsService,
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
   
    public afAuth: AngularFireAuth,
    private excelService:ExcelService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    public ete: MisSendLenderService
    
    ) {

   
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      
 
  
    
    
  
     this.lenderContactService
     .getSendemailUser()
     .subscribe( (result:any) => {
      console.log(result);
       for (let i = 1; i < result.items.length; i++) {
        const element = result.items[i];
        let usersendemailid =element.message;
       
        let strArr = usersendemailid.split('→');
       
        let arrayvalue =strArr[1];
        //let strArrarrayvalue = arrayvalue.split("'");
        //console.log(strArrarrayvalue); 
       // console.log(strArrarrayvalue); // gives ["15","16","17"];
        //let mailgunuseEmail =strArrarrayvalue[0];
        
        this.element.push(
          {
            id: i, 
            responcedate: element.created_at,
            status: element.hap,
            type: element.type,
            email: arrayvalue,   
            emailsenddate: element.created_at,  
               
          });
      
      }

      
    //  console.log(this.element); 
        this.mdbTable.setDataSource(this.element);
        this.element = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      
     });
 
    }
  
     
      calculateEMI(interest:number,tenure:number,principal:number){
        var emi = 0;
        var a = (1 + interest / 1200);
        var b = Math.pow(a, tenure);
        var c = Math.pow(a, tenure) - 1;
        emi = Math.round(principal * (interest / 1200) * b / c);
        //console.log(emi)
        return emi;
      }
      calculateFOIR(existemi:number,proposedemi:number,income:number){
        var foir= 0;
       // console.log(existemi)
       // console.log(proposedemi)
      //  console.log(income)
        foir = ((existemi + proposedemi) / income * 100);
       // console.log(foir)
        return foir;
      }
      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }
      
      openModalone(data1,data2,data3,data4,data5,data6,data7,data8) {
        this.id =data1;
        this.lenderid=data2;
        this.product_name=data3;
        this.rate_of_interest_from =data4;
        this.tenure =data5;
        this.interest_rate_as_on =data6;
        this.editlenderid =data7;
        this.editproductid =data8;
       
      //  console.log(data1);
        this.display = "block";
      } 
      onCloseHandledone() {
    
        this.display = "none";
      }
}

function die() {
  throw new Error('Function not implemented.');
}

