import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
@Component({
  selector: 'app-meraemi-document-view',
  templateUrl: './meraemi-document-view.component.html',
  styleUrls: ['./meraemi-document-view.component.scss']
})
export class MeraemiDocumentViewComponent implements OnInit {
  data: any;
  sub: any;
  decryptData: any;
  driveurl: any;
  filename: string;
  constructor(private route:ActivatedRoute, private cryptojsService:CryptojsService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
       this.data = out;
        this.decryptData = this.cryptojsService.decryptData(this.data);
      
        this.driveurl =  this.sanitizer.bypassSecurityTrustResourceUrl(this.decryptData); 
       // file_name
       this.filename =params.stag;

      },(err)=>{
        console.log(err);
      });
     // console.log(this.userdetils);
  }
  onRightClick() {
    alert("can not");
    return false;
}

}
