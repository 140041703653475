import { Component, OnInit, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
@Component({
    selector: 'app-lender-contact',
    templateUrl: './lender-contact.component.html',
    styleUrls: ['./lender-contact.component.scss']
})
export class LenderContactComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  headElements = [
    {
       name:"Lender Name",
       sortBy:"lender_name"
     },
     {
       name:"Lender Location",
       sortBy:"lender_location"
     },
     {
       name:"Lender Person Name",
       sortBy:"lender_person_name"
     },
     
     {
      name:"Lender Email",
      sortBy:"lender_email"
    }, {
      name:"Lender Contact No",
      sortBy:"cender_contact_no"
    }, {
      name:"Action",
      sortBy:"Action"
    }
      
     

   ];
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;
  messages: any = []; 
  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  smsvalidatingForm: FormGroup;
  emailvalidatingForm: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  currentdate: string;
  elements: any = []; 
  optionslocation: any = []; 
  constructor(fb: FormBuilder,private lenderContactService:LenderContactService,private leadsService:LeadsService,) {

    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactFormCopy': ['', Validators.requiredTrue],

      'campaignName': ['', Validators.requiredTrue],
      'location': ['', Validators.requiredTrue],
      });
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      
      this.emailvalidatingForm= new FormGroup({
        lenderLocation: new FormControl(null, Validators.required),
        lenderName: new FormControl(null, Validators.required),
        lenderEmail: new FormControl(null, Validators.required),
        lenderContact: new FormControl(null, Validators.required),
        lenderStaffName: new FormControl(null, Validators.required),
      });
      this.leadsService
      .getCityname()
      .subscribe( (result:any) => {
    //  console.log(result.filter_city);
              if (result.status = 'true') {
                
                for (let i = 0; i < result.filter_city.length; i++) {
                  const menuarray = result.filter_city[i];
                 //console.log(menuarray.data[0].pincode);
                 if(menuarray.data[0].location !=undefined){
                  this.optionCity.push(
                    {
                      value: menuarray.data[0].location.toUpperCase(),
                      label: menuarray.data[0].location.toUpperCase(),                          
                     
                    }
                    );
                    //console.log(this.messages);
                   }
                
                   
                    
                }
                this.optionslocation =this.optionCity;
                //console.log(this.optionslocation);
              }else{
                console.log('Location Api faile');
              }
              
            });
      this.lenderContactService
      .getLenderName()
      .subscribe( (result:any) => {     
              if (result.status = 'true') {                
                for (let i = 0; i < result.lender_name.length; i++) {
                  const menuarray = result.lender_name[i];
                  this.messages.push(
                    {
                      value: menuarray.data[0].lender_id,
                      label: menuarray.data[0].lender_name,                         
                     
                    }
                    );
                    
                }
                this.optionsArray =this.messages;                
              }else{
                console.log('Lender Api faile');
              }
              
            });
      this.lenderContactService
     .GetAllLendercontact()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.Lendercontact.length; i++) {
                 const element = result.Lendercontact[i];
               //  console.log(element);
                 this.elements.push(
                  {
                    contact_email: element.data[0].contact_email, 
                    contact_id: element.data[0].contact_id, 
                    contact_name:  element.data[0].contact_name, 
                    contact_number:  element.data[0].contact_number,
                    created_on: element.data[0].created_on,
                    lender_name: element.data[0].lender_name,
                    location: element.data[0].location,   
                                 
                  });
        }
       console.log(this.elements);
      }
       
     });
      }
      get lenderL() { return this.emailvalidatingForm.get('lenderLocation'); }
      get lenderN() { return this.emailvalidatingForm.get('lenderName'); } 
      get lenderE() {  return this.emailvalidatingForm.get('lenderEmail');  } 
      get lenderC() {        return this.emailvalidatingForm.get('lenderContact');      }
      get lenderSF() {        return this.emailvalidatingForm.get('lenderStaffName');      }
      
      onSubmit() {
        let date_ob = new Date();
        // adjust 0 before single digit date
        let date = ("0" + date_ob.getDate()).slice(-2);
        // current month
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        // current year
        let year = date_ob.getFullYear();
        // prints date in YYYY-MM-DD format    
        
      
        this.currentdate =(year + "-" + month + "-" + date);
       
        let details = {data: { results: [{
           "lender_id":this.emailvalidatingForm.value.lenderName,
           "location":this.emailvalidatingForm.value.lenderLocation,
           "contact_name":this.emailvalidatingForm.value.lenderStaffName,         
           "contact_email":this.emailvalidatingForm.value.lenderEmail,
           "contact_number":this.emailvalidatingForm.value.lenderContact,
           "created_by":"1",
           "created_on":this.currentdate,
          
      }] } };
        details['data']['results']; 
        console.log(details);
        this.lenderContactService.PostLenderContact(details)
            .subscribe((result: any) => {  
              console.log(result);
            });
      
      
      }

 
}
