
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class FacebookAdService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    InsertCreateAdAccount(data){
       //console.log(data);
       let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'addManager/InsertCreateAdAccount',{data:data},{headers: headers});
       
      }
      getAllFBAd(){
  
       let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');     
       return this.http.get<Global>(`${this.global.apiurl}`+'addManager/getAllFBAd',{headers:headers});
    }

    getfacebookAdDashboard(){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');     
      return this.http.get<Global>(`${this.global.apiurl}`+'addManager/getfacebookAdDashboard',{headers:headers});
   }
   getPageView(data){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'addManager/getPageView',{data:data},{headers: headers});
    
   }
   getFbAdEligibilityStage(data){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'addManager/getFbAdEligibilityStage',{data:data},{headers: headers});
    
   }
   getSelfempUserStage(){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');     
    return this.http.get<Global>(`${this.global.apiurl}`+'addManager/selfempUserStage',{headers:headers});
 }
 getSelfEmpGraphDataDocRecv(){
  
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');     
  return this.http.get<Global>(`${this.global.apiurl}`+'addManager/selfEmpGraphDataDocRecv',{headers:headers});
}
    // GetFbAdName(){
  
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');     
    //   return this.http.get<Global>(`${this.global.apiurl}`+'Fbad/FbAdName',{headers:headers});
    //  }
    //  PostAdActive(data){
    //   //console.log(data);
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //    return this.http.post<any>(`${this.global.apiurl}`+'Fbad/AdStatus',{data:data},{headers: headers});
       
    //  }
    //  PostAdAmountSpent(data){
    //   //console.log(data);
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //    return this.http.post<any>(`${this.global.apiurl}`+'Fbad/AdAmountSpent',{data:data},{headers: headers});
       
    //  }
    //  PostAdBidStrategy(data){
    //   //console.log(data);
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //    return this.http.post<any>(`${this.global.apiurl}`+'Fbad/AdBidStrategy',{data:data},{headers: headers});
       
    //  }
    //  PostAdDailyBudget(data){
    //   //console.log(data);
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //    return this.http.post<any>(`${this.global.apiurl}`+'Fbad/AdDailyBudget',{data:data},{headers: headers});
       
    //  }
    //  PostAdResultStage(data){
    //   //console.log(data);
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //    return this.http.post<any>(`${this.global.apiurl}`+'Fbad/AdResultStage',{data:data},{headers: headers});
       
    //  }
}
