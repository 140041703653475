import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http:HttpClient,
    private global:Global
  ) { }

  addUserData(details){
   return this.http.post(`${this.global.apiurl}`+'users/createUser', {data:details.data});
 // return this.http.post('/crm/users/createUser', {data:details.data});
    // .subscribe(
    //   data  => {

    //   console.log("POST Request is successful ", data);
    //   return data;
    //   },
    //   error  => {
    //   console.log("Error", error);
    //   return false;

      
    //   } );


  }
}
