import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class LenderService {

  constructor(
    private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,
) {
}
bearer_token = this.TokenService.bearer_token ;
PostLoantapNormal(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'lender/loantapnormal',{data:data},{headers: headers});

 }
 PostLoantapImpact(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'lender/loantapimpact',{data:data},{headers: headers});

 }
 insertAdminRecord(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'lender/insertAdminRecord',{data:data},{headers: headers});

 }
 adminCallLenderapiResponce(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'lender/adminCallLenderapiResponce',{data:data},{headers: headers});

 }

 GetCrmStage(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetCrmStage',{data:data},{headers: headers});

 }
 PostHdbf(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'lender/PostHdbf',{data:data},{headers: headers});

 }
 getHdbRequestFile(uid){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.get<any>(`${this.global.apiurl}`+'lender/getHdbRequestFile/'+uid,{headers: headers});

 }
}
