import { Component, OnInit, EventEmitter, Input, Output, ViewChild ,ElementRef} from '@angular/core';
import { IMyOptions, MDBDatePickerComponent, ClockPickerComponent } from 'ng-uikit-pro-standard';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans/loans.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';
//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';


@Component({
    selector: 'app-meraemi-hlbt-leads-details',
  templateUrl: './meraemi-hlbt-leads-details.component.html',
  styleUrls: ['./meraemi-hlbt-leads-details.component.scss']
})
export class MeraemiHlbtLeadsDetailsComponent implements OnInit {
 // pdfSrc = "http://www.africau.edu/images/default/sample.pdf"; 

  public radioModel: string = 'Left';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  Clicked : boolean
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('datePicker', { static: true }) datePicker: MDBDatePickerComponent;
  @ViewChild('timePicker', { static: true }) timePicker: ClockPickerComponent;
  darkClock: any;
  user:any;
  subs: Subscription;
  sub: any;
  data: any;
  showLoader: boolean;
  decryptData: any;
  leadDetails: any=[];
  elements: any=[];
  elements_feedback: any=[];
  meraemiStage: any=[];
  showDocuments:boolean=false;
  custsD = {};
  cust: any;
  ProposedEmi: number;
  foir: number;
  e_emi: number;
  interest_rate: number;
  isTrackProgress:boolean=true;
  trackData= {};
  trxStatus: any;
  trxSubStatus: any;
  requestJsonDisabled:boolean=false;
  responseJsonDisabled:boolean=false;
  trackProgressJsonDisabled:boolean=false;
  iapstage: string;
  mapstage:string;
  iap_stage_color: string;
  eligstage: string;
 
  elig_stage_color: string;
  userdetils: {};
  //registerForm: any;
  registerForm: FormGroup;
  useridSubmitted: boolean;
  from_user_id: any;
  message: any;
  user_id: any;
  feedForId: any;
  display: string;
  name = "Angular CLI: 11.2.1,  Node: 13.14.0,  OS: win32 x64";
  checkFormArray: Array<any> = [];
  
 
  followupdate: any;
  interactiondate: any;
  status: any;
  followuptime: any;
  today= new Date();
  jstoday = '';
  popupaddBtn: number;
  mesg: string;
  messagediv: number;
  checkAction: string;
  fedbacklength: any;
  fedbackStage: any;  
  currentdate: string;
  userdate: string;
  rolecode: any;
  popupbtnactive: any;
  loginAdmin: any;
  loanid: any;
  elig_status: string;
  eligStatus: any;
  feedback_Stage: any;

  activeClass: string;
  valuemain: number=0;
  userid: number=0;
  tabdiv: number=0;
  isValue: number = 0;
  idfc: number=0;
  fullerton: number=0;
  document: number=0;
  stage: number=0;
  aapstage: number=0;
  
  lenderResponse: any = [];
  lender_Response: any = [];
  sub_lender_Response: any = [];
  min_lender_Response: any = [];
  lenderMainArrya: any[];
  iapstageBackColour: string;
  datesarray: any=[];
  re_active: Date;
  re_active_feedback: any=[];
  reactive_stage: any;
  ConsentArray: any=[];
  eligStage: string;
  eligArray: any=[];
  lenderStage: any=[];
  quest_label: any;
  quest_value_text: any;
  custError: any= [];
  ErrorDetils= {};
  
  eligError: {};
  eligerrorstage: number=0;
  eligcheck: number=0;
  remainingText: number=0;
  iapstageBack_Colour: string;
  tabval: number;
  title: any;
  imageur: string;
  css: string;
  jsonresp: number=0;
  provider: string;
  userdetails:any=[];
  profile_pic: string;

  question: string;
  answer: string;
  provider_id: string;
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private leadsService:LeadsService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,
   
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
   
    
  ) { this.jstoday = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US'); }
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  onDateChange = (event: any) => {
    this.input.nativeElement.value = event.actualDateFormatted; // set value to input
    this.datePicker.closeBtnClicked(); // close date picker
    this.timePicker.openBtnClicked(); // open time picker
  };

  onTimeChange = (event: any) => {
    this.input.nativeElement.value = `${this.input.nativeElement.value}, ${event}`; // set value to input
  };
  
  ngOnInit() {
    
    this.isValue = 1;
    this.idfc=0;
  //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
          // 
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          //console.log(this.firebasedataService);
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        
        )
        //console.log(this.firebasedataService);
      }
     
    
    });
    
    // validation input form
    this.registerForm = this.formBuilder.group({
        idFilter: this.formBuilder.group({
        message: ['', Validators.required],        
        meremistage: ['', Validators.required],
       // follwuptime: ['', Validators.required],
        
       
      })
    });
    this.sub = this.route
  
    .queryParams
    .subscribe(params => {
      //console.log(params);
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        //console.log(this.data);
      
        //this.feedback_Stage=params.stage;       
        if(this.data != undefined){
         // console.log(this.data);
          this.decryptData = this.cryptojsService.decryptData(this.data);         
         //console.log(this.decryptData);
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"user_id":parseInt(this.decryptData)}];
            //console.log(this.custsD)
            this.cust = this.custsD["results"];
            //console.log(this.custsD["results"])
           
            this.userdetils =this.custsD;
           // console.log(this.userdetils)
          }else{
            this.showLoader = true;    
            this.router.navigate(['/leads']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
   
          this.loansService.PostMortgageDetails(this.userdetils).subscribe( 
           // PostMortgageDetails
            (Mortgag:any) => {
             // console.log(Mortgag)
            if(Mortgag.status===true){
            
             this.userdetails =Mortgag.mortgageloandetails[0];
             if(this.userdetails.data[0].provider_id==="facebook.com"){
                 this.provider_id="Boot";
             }else{
              this.provider_id="Web";
             }
            
             //console.log(this.userdetails);
            
            }else{
              console.log("Api not Response !");
            }
                
            
            })
            let details = {data: { results: [{ "user_id":this.decryptData}] } };
            details['data']['results']; 
            this.FeedbackService.postfeedback_getcrm(details)
            .subscribe((result: any) => {  
              //console.log(result);
              if (result.status == true) {
               // console.log(result);
                for (let i = 0; i < result.feedvalue.length; i++) {
                  const element = result.feedvalue[i];
                 // console.log(element);
                  if(element.data[0].stage == "Active"   && element.data[0].status ==='1' || element.data[0].status ==='3'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }if(element.data[0].stage == "Active"   && element.data[0].status ==='2' || element.data[0].status ==='4'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        status:  element.data[0].status, 
                        
                      }
                      );
                  }
                  
                  else if(element.data[0].stage == "Transfer" && element.data[0].status === '1' ||  element.data[0].status === '3'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }else if(element.data[0].stage == "Transfer" && element.data[0].status === '2' ||  element.data[0].status === '4'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }                
                  else if(element.data[0].stage == "Cancel" && element.data[0].status === '1' ||   element.data[0].status === '4'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }
                  
                  this.elements_feedback.push(
                    {
                      admin_name: element.data[0].admin_name, 
                      feedback_id: element.data[0].feedback_id, 
                      feedback_message:  element.data[0].feedback_message, 
                      followupdate:  element.data[0].followupdate, 
                      interactiondate:  element.data[0].interactiondate, 
                      stage:  element.data[0].stage, 
                      user_id:  element.data[0].user_id, 
                      status:  element.data[0].status,
                    }
                    );
                    var dates =element.data[0].interactiondate;
                    if((element.data[0].stage ==="Cancel" && element.data[0].status==="0") || (element.data[0].stage ==="Cancel" && element.data[0].status==="4")){
                      this.datesarray.push(new Date(dates));
                      //this.datesarray.push(element.data[0].admin_name);
                    }
                  

                    
                }
                this.fedbacklength =this.elements_feedback.length;
               // var maxDate=new Date(Math.min.apply(null,this.datesarray));
                var maxDate=new Date(Math.max.apply(null,this.datesarray));
                if (isNaN(maxDate.getTime())) {  // d.valueOf() could also work
                  // date is not valid
                  console.log("Admin has not re-activated ");
                } else {
                  this.re_active=maxDate;
                  // date is valid
                  for (let i = 0; i < this.fedbacklength; i++) {
                    const element = this.elements_feedback[i];
                    let date =element.interactiondate;
                    let userdate =new Date(date);
                    // console.log(date);
                    //console.log(userdate);
                    //status: "1"                  
                    let status = element.status;
                    if((status==="1") || (status==="3")){
                     
                      this.re_active_feedback.push(
                        {
                          admin_name: element.admin_name,                      
                          interactiondate:  element.interactiondate, 
                          stage:  element.stage,                      
                          status:  element.status,
                        }
                        );
                    }
                   
                  }
                  this.reactive_stage=this.re_active_feedback;
                  //console.log(this.reactive_stage);
                }
               
               
               
             
                
                  
  
               // console.log(this.fedbackStage);
              this.popupbtnactive=this.meraemiStage[0].stage;
               //console.log(this.popupbtnactive);
                this.fedbacklength =this.elements_feedback.length;
                         
                this.message = result.message;
                 
                 
              }else{
                this.popupaddBtn =0;
                //("hello");
              }
            });
            

         
            
            //console.log(this.decryptData);
        
    }
    
    adidSubmitted: boolean = false;
 
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }

 
  get idFilter() {
    return this.registerForm.get('idFilter');
  }
  onChange1(email:string, isChecked: boolean) {
   // console.log(email);
    if(isChecked) {
      this.checkFormArray.push(email);
    } else {
      let index = this.checkFormArray.indexOf(email);
      this.checkFormArray.splice(index,1);
    }
}


tempElig(){
  //console.log(this.idFilter);
  if(this.idFilter.value.meremistage === 'Left'){
    this.status ="Active";

  }else if(this.idFilter.value.meremistage === 'Middle'){
    this.status ="Cancel";

  }else if(this.idFilter.value.meremistage === 'Right'){
    this.status ="Transfer";
    
  }   
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  this.message =this.idFilter.value.message;
  let datetime =this.input.nativeElement.value;
  let foloupD =datetime.replace(", 19:30",""); 
  var d2 = new Date(datetime);
  var dateformdate = d2.getFullYear()+''+(d2.getMonth()+1)+''+d2.getDate();    
  var currdate = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate(); 
  //date compare
          var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        var d2 = new Date(datetime);

        let db_date = ("0" + d2.getDate()).slice(-2);
                                // current month
        let db_month = ("0" + (d2.getMonth() + 1)).slice(-2);
                                // current year
        let db_year = d2.getFullYear();
        this.userdate =(db_year + "-" + db_month + "-" + db_date);
  

   
  this.useridSubmitted = true; 
  if(this.status === "Cancel"){
    if(this.idFilter.invalid){
      this.mesg ="Sorry! Feedback must not be blankfor updating status as Cancel";
             this.messagediv = 1;
            setTimeout(() => {
              this.message;
            
             this.messagediv = 0;
            
              
            }, 3000);
         return;
    }
    this.followupdate =(date+' '+time);
    this.loanid="0";
  

  }
  else if(this.status === "Active"){
    //console.log(this.currentdate);
     //console.log(this.userdate);
        if(this.currentdate <= this.userdate){
          this.followupdate =datetime.replace(/,?\s/, " ");
          
        }else{
          this.mesg ="Invalid input! Please select valid date and time."; 
          this.messagediv = 1;
          setTimeout(() => {
            this.message;         
          this.messagediv = 0;   
            
          }, 3000);
        return; 
        }
        if(this.idFilter.invalid)
          {
          this.mesg ="Sorry! Feedback must not be blankfor updating status as Active";     
                        
                    this.messagediv = 1;
                    setTimeout(() => {
                      this.message;
                    
                      this.messagediv = 0;
                    
                      
                    }, 3000);
          return;
          }
      if(this.input.nativeElement.value ==='')
        {
          this.mesg ="Sorry! Feedback must not be blankfor updating followup date/time.";
          this.messagediv = 1;
        setTimeout(() => {
          this.message;
          
          this.messagediv = 0;
        
          
        }, 3000);
          return;
        }
        this.loanid="0";
       
  }
  else if(this.status === "Transfer"){
        if(this.currentdate <= this.userdate){
          this.followupdate =datetime.replace(/,?\s/, " ");
          
        }else{
          this.mesg ="Invalid input! Please select valid date and time."; 
          this.messagediv = 1;
        setTimeout(() => {
          this.message;         
        this.messagediv = 0;   
          
        }, 3000);
      return;
        }
        if(this.idFilter.invalid)
          {
          this.mesg ="Sorry! Feedback must not be blankfor updating status as Active";     
                        
                    this.messagediv = 1;
                    setTimeout(() => {
                      this.message;
                    
                      this.messagediv = 0;
                    
                      
                    }, 3000);
          return;
          }
          if(this.input.nativeElement.value ==='')
            {
              this.mesg ="Sorry! Feedback must not be blankfor updating followup date/time.";
              this.messagediv = 1;
            setTimeout(() => {
              this.message;
              
              this.messagediv = 0;
            
              
            }, 3000);
              return;
            }
            this.loanid="0";
        
       
  }



 //console.log(this.feedback_Stage);
  this.feedback_Stage="Eligibility_fail"
   if(this.feedback_Stage=='Eligibility_fail'){
    this.user_id=this.decryptData;
    let details = {
      data: {
        results: [{
          "message":this.message,            
          "interactiondatetime":date+' '+time,          
          "status": this.status,
          "user_id":this.user_id,
          "admin_name":this.user.first_name,
         // "admin_name":this.user.first_name+' '+this.user.last_name,
          "loan_id":this.loanid,
          "followupdate":this.followupdate
              
        }]
    
      }
    };
   //console.log(details);
    details['data']['results']; 
        this.FeedbackService.postfeedback(details)
        .subscribe((result: any) => { 
          //console.log(result);
          if (result.status == true) {
            
            this.elements_feedback.length=0;
           
           
          let details = {data: { results: [{ "user_id":this.user_id}] } };
          details['data']['results']; 
          this.FeedbackService.postfeedback_getcrm(details)
          .subscribe((result: any) => {  
              
            if (result.status == true) {
              for (let i = 0; i < result.feedvalue.length; i++) {
                const element = result.feedvalue[i];
                if(element.data[0].stage == "Active"   && element.data[0].status ==='1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }if(element.data[0].stage == "Active"   && element.data[0].status ==='2'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      status:  element.data[0].status, 
                      
                    }
                    );
                }
                
                else if(element.data[0].stage == "Transfer" && element.data[0].status === '1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }else if(element.data[0].stage == "Cancel" && element.data[0].status === '1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }
                this.elements_feedback.push(
                  {
                    admin_name: element.data[0].admin_name, 
                    feedback_id: element.data[0].feedback_id, 
                    feedback_message:  element.data[0].feedback_message, 
                    followupdate:  element.data[0].followupdate, 
                    interactiondate:  element.data[0].interactiondate, 
                    stage:  element.data[0].stage, 
                    user_id:  element.data[0].user_id, 
                    status:  element.data[0].status, 
                  }
                  );
                  var dates = element.data[0].interactiondate;
                  if((element.data[0].stage ==="Cancel" && element.data[0].status==="0") || (element.data[0].stage ==="Cancel" && element.data[0].status==="4")){
                    this.datesarray.push(new Date(dates));
                    //this.datesarray.push(element.data[0].admin_name);
                  }
                  
              }
              this.fedbacklength =this.elements_feedback.length;
              var maxDate=new Date(Math.max.apply(null,this.datesarray));
              //console.log(this.elements_feedback);
              //console.log(maxDate);
              this.re_active=maxDate;
              this.popupbtnactive=this.meraemiStage[0].stage;
              this.fedbacklength =this.elements_feedback.length;
              
             
              this.display = "none";
                this.message = result.message;
                this.showLoader = false;
                //this.messagediv = false;     
                //this.messagediv = 1;
              setTimeout(() => {
                this.message;
                this.showLoader = true;
                this.popupaddBtn =1;
                
              }, 2000);
            }
          });
          
        }
        });
      }else{
        console.log("Iap stage");
      }
    //console.log(details);

}
 
  onCloseHandledone() {
    this.display = "none";
  }
    backmenu(){
      this.showLoader = false;
      setTimeout(()=>{    
        //this.showLoader = true;
        this.router.navigate(['/MeraEMI-All-Users-Leads']);
      }, 2000);
     
      
      }
     
     
  calculateEMI(interest:number,tenure:number,principal:number){
    var emi = 0;
    var a = (1 + interest / 1200);
    var b = Math.pow(a, tenure);
    var c = Math.pow(a, tenure) - 1;
    emi = Math.round(principal * (interest / 1200) * b / c);
    //console.log(emi)
    return emi;
  }
  calculateFOIR(existemi:number,proposedemi:number,income:number){
    var foir= 0;
   // console.log(existemi)
   // console.log(proposedemi)
  //  console.log(income)
    foir = ((existemi + proposedemi) / income * 100);
   // console.log(foir)
    return foir;
  }
  trackProgress(data){
    // console.log(data)
    // return false;
    data.sfdc_id = "0328568170";
    this.trackData["results"]= {
      "record_id":"0328568170",
      "email_send": "NO",
      "sms_send": "NO",
      //"user_id":107
    };

    this.isTrackProgress = !this.isTrackProgress;

    setTimeout(()=>{  
      
      this.isTrackProgress = true;
     // console.log('set disable '+this.isTrackProgress)
      this.trackProgressService.updateTrackProgress(this.trackData)
      .subscribe(result => {
       // console.log(result)
          
        if(result.status == 1){
          //console.log(result.trx_status)
          if(result.trx_status != 'undefined'){
            this.trxStatus = result.trx_status;
            this.trxSubStatus =result.trx_substatus;
            //console.log(this.setBtnDisable);
            
              if(result.trx_status == "DDE" || result.trx_status == "QDE" || result.trx_status == "Data Verification"){
               // console.log(result.trx_status)
                this.isTrackProgress = this.isTrackProgress;
                //console.log(this.isTrackProgress)
              }else{
                this.isTrackProgress = !this.isTrackProgress;
               // console.log(this.isTrackProgress)
              }
          }  
        }
        
      });
    }, 20000);
  

  }
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  downloadRequestFile(filename){  
    saveAs("./home/meraemi/public_html/crm.meraemi.com/assets/UserRequestResponse/idfc/Request/"+filename+".json",filename);
  
  }
  downloadResponseFile(filename){  
    saveAs("./home/meraemi/public_html/crm.meraemi.com/assets/UserRequestResponse/idfc/Response/"+filename+".json",filename);
  }
  downloadTrackProgressFile(filename){   
    saveAs("./home/meraemi/public_html/crm.meraemi.com/assets/UserRequestResponse/idfc/trackprogress/"+filename+".json",filename);
  }
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
   
    this.display = "block";
 
    this.valuemain=1;
    this.userid =1;
    this.isValue = 1;
  }
  

toggle1() { 
  alert('1');
  this.isValue = 1;
   this.idfc=1;
  
   this.document=0;
   this.stage=0;
   
}
toggle6() { 
  alert('2');
  this.isValue = 2;
   this.idfc=1;
  
   this.document=0;
   this.stage=0;
   
}
toggle2() { 
  alert('3');
  this.isValue = 2;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle5() { 
  alert('4');
  this.isValue = 1;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle3() { 
  alert('5');
  this.isValue = 3; 
  this.idfc= 0;

  this.document=1;
  this.stage=0;
}
toggle4() { 
  alert('6');
  this.isValue = 4;
  this.idfc= 1;
 
  this.document=0;
  this.stage=1;

}

valueChange(value) {
  //console.log(value);
  if(value !=undefined){
    this.remainingText = 300 - value.length;
  //console.log(this.remainingText);
  }
  
  
 }
 
 onClick(check){
      //console.log(check);
      if(check==1){
        this.tab = 'tab1';
        this.tabval=1;
      }else if(check==2){
        this.tab = 'tab2';
        this.tabval=2;
      }   
    
  }
 onSwitch(check){
 
  switch (check) {
   case 1:
     return 'tab1';
   case 2:
     return 'tab2';
   
 }
}
getapencard(el){
  // console.log(el);
   this.title=el;
   this.imageur="../../../assets/documents/pencard.png";
   this.css="border_clas";
 }
 getadharcard(el){
   this.title=el;
   this.imageur="../../../assets/documents/adharcard.png";
   this.css="border_clas";
 }
 

}
