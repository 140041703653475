import { Component, OnInit } from '@angular/core';
import {EmailService} from 'src/app/services/email/email.service';
@Component({
  selector: 'app-meraemi-email',
  templateUrl: './meraemi-email.component.html',
  styleUrls: ['./meraemi-email.component.scss']
})
export class MeraemiEmailComponent implements OnInit {
  curentDate: any;

  appstage: string;
  stage_color: string;
  urlstage: string;
  elig_status: string;
  UserSendEmail: any = [];
  provider: string;

  constructor(private emailService:EmailService) { }

  ngOnInit() {
    let current_Date = new Date();
    //console.log(date);
 

  }

}
