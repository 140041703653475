import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateEmi'
})
export class CalculateEmiPipe implements PipeTransform {

  transform(interest,tenure,principal): any {
    // interest =16;
    // tenure=36;
    // principal=200000;
      var emi = 0;
			var a = (1 + interest / 1200);
			var b = Math.pow(a, tenure);
			var c = Math.pow(a, tenure) - 1;
      emi = (principal * (interest / 1200) * b / c);
      console.log(emi)
			return emi;
  }

}
