import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { CampaignService } from 'src/app/services/campaign/campaign.service';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';

import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';

export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-campaign',
    templateUrl: './meraemi-campaign.component.html',
    styleUrls: ['./meraemi-campaign.component.scss']
})
export class MeraemiCampaignComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("modalSubscriptionForm", {static: true}) modalSubscriptionForm: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  myControl = new FormControl(); 
  elements: any = [];
  campalluser: any = [];
  headElements = [
    {
       name:"User Id",
       sortBy:"userid"
     },          
     {
      name:"Camp Id",
      sortBy:"campid"
    },
     {
       name:"Name",
       sortBy:"name"
     },
     
     {
      name:"Camp Name",
      sortBy:"campname"
     },  
      
     {
      name:"Camp Date ",
      sortBy:"Date "
    }, 
    {
      name:"DPDM Stage",
      sortBy:"stage"
    },
    {
      name:"Camp Stage",
      sortBy:"stage"
    },
     {
       name:"Edit",
       sortBy:"Edit"
     }
     
    
     
   ];
 
  first_name: string;
  last_name: string;
  previous: any;
  searchText: any;
  useid: any;
  showLoader: boolean;
  stage_color: string;
  username: string;
  registerForm: FormGroup;
   currentdate: string;
	FirstDaydate: string;
  display: string;
 
  result: any;
  tabledivactive: number=0;
  Campaigndate: any;
  constructor( 
      private cryptojsService:CryptojsService,
      //private firebasedataService:FirebasedataService,      
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private CampaignService:CampaignService,) { }

  ngOnInit() {
    let date = new Date();
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//console.log(this.currentdate); 
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		// adjust 0 before single digit date
		let datesFirstDay = ("0" + firstDay.getDate()).slice(-2);
		// current month
		let monthFirstDay = ("0" + (firstDay.getMonth() + 1)).slice(-2);
		// current year
		let yearFirstDay = firstDay.getFullYear();
		this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
    this.registerForm = this.formBuilder.group({			
			campUser: this.formBuilder.group({
			  iapstage: ['', Validators.required],
			  details: ['', Validators.required],
        datefrom: ['', Validators.required],
			  dateto: ['', Validators.required],
			}), 
      dateFilter: this.formBuilder.group({
        Campaigndate: ['', Validators.required],
        
       
      }),
			
		  });
 
    
    this.CampaignService
    .getUserCampaignList()
    .subscribe( (result:any) => {
 // console.log(result);
      if (result.status === true) {            
        for (let i = 0; i < result.camp.length;  i++) {
          const element = result.camp[i];
          
       //  console.log(element);
           
         this.stage_color = "info-color";
   
        this.username=(element.data[0].first_name+' '+element.data[0].last_name);
                            this.campalluser.push(
                              {
                                user_id: element.data[0].user_id,   
                                name:  this.username,                                                               
                                capain_auto_id: element.data[0].capain_auto_id,
                                campain_id: element.data[0].campain_id,
                                schedule_date: element.data[0].schedule_date,
                                dm_stage: element.data[0].dm_stage,                            
                                status: element.data[0].status,
                                campain_name: element.data[0].campain_name,
                                
                              }
                              );
                             

            }
         
            }else{
              console.log('HLBT Leads list Api faile');
            }
            
          });
         
   
    
    
  }
  chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('campUser')).controls; }
  get fh() { return (<FormGroup>this.registerForm.get('dateFilter')).controls; }
  get campUser(){
	return this.registerForm.get('campUser');
    }
    get dateFilter(){
      return this.registerForm.get('dateFilter');
    }
    filterdate(){
      this.Campaigndate =this.dateFilter.value.Campaigndate;
      //console.log( this.Campaigndate);
      const data = this.mdbTable.getDataSource();

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        let details={ results: [{"user_id":(element.user_id) , "capain_auto_id": (element.capain_auto_id) , "status":(element.status) ,"schedule_date": (this.Campaigndate) }]};
        //console.log(details);
        this.CampaignService.updateScheduleDate(details).subscribe((result: any) => {
          if(result.status===true  && result.response== 200){
            // check value result
            this.basicModal4.hide();

            const  fromdate =this.campUser.value.datefrom;
            //console.log(fromdate);
            const  todate =this.campUser.value.dateto; 
            //console.log(todate);
            //console.log(this.campalluser);
            this.elements.length = 0; 

  
                          const data = this.campalluser;
                          
                          this.result = data.filter(d => {
                            const  reg_date= d.schedule_date;
                            const regdate = reg_date.split(' ')[0];
                            //return (fromdate  <= regdate && regdate  <=  todate );            
                            return (regdate >= fromdate  && regdate  <=  todate );
                            
                          });

                          if(this.result.length > 0){
                          
                            for (let i = 0; i < this.result.length; i++) {
                              const element = this.result[i];
                            
                              this.elements.push(
                                {
                                  user_id: element.user_id,   
                                  name:  element.name,                                                               
                                  capain_auto_id: element.capain_auto_id,
                                  campain_id: element.campain_id,
                                  schedule_date: element.schedule_date,
                                  dm_stage: element.dm_stage,                            
                                  status: element.status,
                                  campain_name: element.campain_name,
                                  
                                }
                                );
                                
                               // console.log( this.elements); //return false;
                                this.mdbTable.setDataSource(this.elements);
                                this.elements = this.mdbTable.getDataSource();
                                this.previous = this.mdbTable.getDataSource();
                            }
                          }
          }
        });
      }
    }
    cancle(){
      this.basicModal4.hide();
    }
    modelshow(){
      this.basicModal4.show();
    }
    filter_campUser(){
      this.display = "block";
      
      this.tabledivactive=1;
     // this.tabledivActive=1;
      const  fromdate =this.campUser.value.datefrom;
      //console.log(fromdate);
      const  todate =this.campUser.value.dateto; 
      //console.log(todate);
      //console.log(this.campalluser);


    
      const data = this.campalluser;
      
      this.result = data.filter(d => {
        const  reg_date= d.schedule_date;
        const regdate = reg_date.split(' ')[0];
        //return (fromdate  <= regdate && regdate  <=  todate );            
        return (regdate >= fromdate  && regdate  <=  todate );
        
      });

      if(this.result.length > 0){
       
        for (let i = 0; i < this.result.length; i++) {
          const element = this.result[i];
         
          this.elements.push(
            {
              user_id: element.user_id,   
              name:  element.name,                                                               
              capain_auto_id: element.capain_auto_id,
              campain_id: element.campain_id,
              schedule_date: element.schedule_date,
              dm_stage: element.dm_stage,                            
              status: element.status,
              campain_name: element.campain_name,
              
            }
            );
            this.basicModal4.show();
            console.log( this.elements); //return false;
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
        }
      }




    }
  basicModal11(){
    this.modalSubscriptionForm.show();

  }
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
 


   
    
   
   
   
    
    
     
     //user Details
       getLeadDetails(user,data){
         let stage=  data;
        console.log(user);
        this.useid = this.cryptojsService.encryptData((user.lead_id));       
        const base_url=location.origin+"/#/Meraemi-HLBT-Leads-Details?data=";        
        const detailsurl = base_url + this.useid.toString();
        const dateur=detailsurl+"&stage="+stage.toString();
       
        console.log(detailsurl);
        window.open(dateur, "_blank");
    }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
}