import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Leads } from 'src/app/model/admin/leads.model';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  message: any;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;
  getDocCategory(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'doc/getDocCategory',{headers: headers});
  }
  getDocId(docid){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'doc/getDocId/'+docid,{headers:headers});
  }
  documentListing(uid){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'doc/documentListing/'+uid,{headers:headers});
  }
 
  docUplods(data){
  
  
    let headers = new HttpHeaders()
    // .set('Content-Type', 'multipart/form-data')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'doc/docuplods',data,{headers: headers});
   }
  //  docUserDocDetails(data){
  
  
 
  previewFile(data){
  
  
    let headers = new HttpHeaders()
    // .set('Content-Type', 'multipart/form-data')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/previewFile',data,{headers: headers});
   }

   reasonForRejection(data){
  
    let headers = new HttpHeaders()
    // .set('Content-Type', 'multipart/form-data')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/reasonForRejection',data,{headers: headers});
   }





  docUserDocDetails(data) {
    let headers = new HttpHeaders()
        .set('Authorization', this.bearer_token);

    let params = new HttpParams();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            params = params.append(key, data[key]);
        }
    }

    return this.http.get<any>(
        `${this.global.apiurl}doc/docUserDocDetails`,
        { params: params, headers: headers }
    );
}




deleteFileold(user_id, loan_code, docType, fileName){
  
  
  let headers = new HttpHeaders()
  // .set('Content-Type', 'multipart/form-data')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'doc/deleteFile/'+user_id+'/'+loan_code+'/'+docType+'/'+fileName,{headers: headers});
 }


 deleteFile(data){
  let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  return this.http.post<any>(`${this.global.apiurl}`+'doc/deleteFile',{data:data},{headers:headers});
  
}





// docUserDocDetailsfile(data) {
//   console.log(data); //return false;
//   let headers = new HttpHeaders()
//       .set('Content-Type', 'application/json')
//       .set('Authorization', this.bearer_token);

//   return this.http.get<Global>(
//       `${this.global.apiurl}doc/downloadFile/`,data,{ headers: headers}
//   );
// }







docdownloadFilefile(data) {
  let headers = new HttpHeaders()
      .set('Authorization', this.bearer_token);

  let params = new HttpParams();
  for (const key in data) {
      if (data.hasOwnProperty(key)) {
          params = params.append(key, data[key]);
      }
  }

  return this.http.get<any>(
      `${this.global.apiurl}doc/downloadFile`,
      { params: params, headers: headers }
  );
}
docDeletefile(data) {
  console.log(data);
  let headers = new HttpHeaders()
      .set('Authorization', this.bearer_token);

  let params = new HttpParams();
  for (const key in data) {
      if (data.hasOwnProperty(key)) {
          params = params.append(key, data[key]);
      }
  }

  return this.http.delete<any>(
      `${this.global.apiurl}doc/deleteFile`,
      { params: params, headers: headers }
  );
}
}
