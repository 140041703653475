import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { LoansService } from 'src/app/services/loans/loans.service';
import { Subscription } from 'rxjs';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss']
})
export class LoansComponent implements OnInit {
  @Input() searchModel;
  @Input() model;
  @Output() searchModelChange: EventEmitter<any> = new EventEmitter();
  @Output() dateModelChange: EventEmitter<any> = new EventEmitter();
 
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'yyyy-mm-dd'
  };
  loanslist:any=[];
  userLoanDetails: any=[];
  result: string;
  value: any;
  message: string;
  showLoader: boolean;
  constructor(
    private loansService:LoansService,
    private router: Router,
    private cryptojsService:CryptojsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(()=>{    
      this.showLoader = true;
    }, 2000);
  
  
    // this.loansService.getAllLoans().subscribe( 
    //   (result:any) => {
    //     if(result.status == true){
    //       console.log(result)
    //       this.loanslist=result['data']
    //      //console.log(this.loanslist) 
        

    //     }
       
    //   })
  }
  
   updateSearchModel(value) {
    this.searchModel = value;
    this.searchModelChange.emit(this.searchModel);
  }

  searchDate(value){
    this.model = value;
    this.dateModelChange.emit(this.model);

  }
  getDetails(loan_code,event){
    console.log(loan_code)
    console.log(this.cryptojsService.encryptData(loan_code))
    this.router.navigate(['/loan-details'], { queryParams: { data: this.cryptojsService.encryptData(loan_code) } });

  }

}
