import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from '../main-layout/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoansComponent } from './loans/loans.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { ModalComponent } from './modal/modal.component';
import { MisListDetailsComponent } from './mis-list-details/mis-list-details.component';


import { LeadsComponent } from './leads/leads.component';
import { PendingFeedbackComponent } from './pending-feedback/pending-feedback.component';
import { LeadDetilsComponent } from './lead-detils/lead-detils.component';
import { MeraemiStageActiveComponent } from './meraemi-stage-active/meraemi-stage-active.component';
import { MeraemiStageCancelComponent } from './meraemi-stage-cancel/meraemi-stage-cancel.component';
import { MeraemiStageTransferComponent } from './meraemi-stage-transfer/meraemi-stage-transfer.component';
import { LeadStageComponent } from './lead-stage/lead-stage.component';
import { LeadsStageLeastComponent } from './leads-stage-least/leads-stage-least.component';
import { DetailedMisComponent } from './detailed-mis/detailed-mis.component';
import { MeraemiAllUsersLeadsComponent } from './meraemi-all-users-leads/meraemi-all-users-leads.component';
import { MeraemiAllUsersLeadsDetailsComponent } from './meraemi-all-users-leads-details/meraemi-all-users-leads-details.component';
import { EligibilityFeedBackComponent } from './eligibility-feed-back/eligibility-feed-back.component';
import { EligibilityFollowUpStatusComponent } from './eligibility-follow-up-status/eligibility-follow-up-status.component';
import { FacebookAdComponent } from './facebook-ad/facebook-ad.component';
import { MeraemiDashboardComponent } from './meraemi-dashboard/meraemi-dashboard.component';
import { MeraemiEmailComponent } from './meraemi-email/meraemi-email.component';
import { MeraemiLenderDocumentsComponent } from './meraemi-lender-documents/meraemi-lender-documents.component';
import { MeraEmiIAPErrorComponent } from './mera-emi-iap-error/mera-emi-iap-error.component';
import { MeraemiContactRequestComponent } from './meraemi-contact-request/meraemi-contact-request.component';
import { MeraemiEmailCampaignComponent } from './meraemi-email-campaign/meraemi-email-campaign.component';
import { MeraemiOfflineIapCompleteComponent } from './meraemi-offline-iap-complete/meraemi-offline-iap-complete.component';
import { MisSendLenderComponent } from './mis-send-lender/mis-send-lender.component';
import { LenderContactComponent } from './lender-contact/lender-contact.component';
import { MeraemiCriteriaFailComponent } from './meraemi-criteria-fail/meraemi-criteria-fail.component';
import { MmeraemiCriteriaFailDetailsComponent } from './mmeraemi-criteria-fail-details/mmeraemi-criteria-fail-details.component';
import { LenderProdOfferListComponent } from './lender-prod-offer-list/lender-prod-offer-list.component';
import { MeraemiSendUserMailComponent } from './meraemi-send-user-mail/meraemi-send-user-mail.component';
import { MeaemiEmailCampainComponent } from './meaemi-email-campain/meaemi-email-campain.component';
import { MeraemiHlbtLeadsComponent } from './meraemi-hlbt-leads/meraemi-hlbt-leads.component';
import { MeraemiHlbtLeadsDetailsComponent } from './meraemi-hlbt-leads-details/meraemi-hlbt-leads-details.component';
import { MeraemiDocumentViewComponent } from './meraemi-document-view/meraemi-document-view.component';
import { MeaemiCrmStatusMComponent } from './meaemi-crm-status-m/meaemi-crm-status-m.component';
import { MeraemiIapCompleteComponent } from './meraemi-iap-complete/meraemi-iap-complete.component';
import { DashboardNewComponent } from './dashboard_new/dashboard-new/dashboard-new.component';
import { TempSelfEmpDataComponent } from './temp-self-emp-data/temp-self-emp-data.component';
import { MeraemiSelfEmpUserListComponent } from './meraemi-self-emp-user-list/meraemi-self-emp-user-list.component';
import { MeraemiUsersLeadsSelfEmpDetailsComponent } from './meraemi-users-leads-self-emp-details/meraemi-users-leads-self-emp-details.component';
import { MeraemiCampaignComponent } from './meraemi-campaign/meraemi-campaign.component';
import { MeraEMISelfProLeadsComponent } from './mera-emi-self-pro-leads/mera-emi-self-pro-leads.component';
import { MeraEMIAllUsersSalariedComponent } from './mera-emi-all-users-salaried/mera-emi-all-users-salaried.component';
import { MeraEMIBusinessPartnerComponent } from './mera-emi-business-partner/mera-emi-business-partner.component';
import { MeraemiBusinessPartnerDetailsComponent } from './meraemi-business-partner-details/meraemi-business-partner-details.component';
import { MeraemiUsersLeadsProfessionalDetailsComponent } from './meraemi-users-leads-professional-details/meraemi-users-leads-professional-details.component';
import { MeraEMIActiveApplicationDashboardComponent } from './mera-emi-active-application-dashboard/mera-emi-active-application-dashboard.component';
import { MeraEMISelfEmpCrmstageComponent } from './mera-emi-self-emp-crmstage/mera-emi-self-emp-crmstage.component';
import { MeraEMIFacebookAdComponent } from './mera-emi-facebook-ad/mera-emi-facebook-ad.component';
import { MeraEMIWhatsappLeadsComponent } from './mera-emi-whatsapp-leads/mera-emi-whatsapp-leads.component';
import { MeraEMIWhatsappLeadsDetailsComponent } from './mera-emi-whatsapp-leads-details/mera-emi-whatsapp-leads-details.component';
import { MeraEMIUserRegisterComponent } from './mera-emi-user-register/mera-emi-user-register.component';
import { MeraEMIRegisterFormEditComponent } from './mera-emi-register-form-edit/mera-emi-register-form-edit.component';
import { MeraEMIDocuplodsUserComponent } from './mera-emi-docuplods-user/mera-emi-docuplods-user.component';
import { MeraEMICTWALeadListingComponent } from './mera-emi-ctwa-lead-listing/mera-emi-ctwa-lead-listing.component';
import { MeraEMICtwaUserDetailsComponent } from './mera-emi-ctwa-user-details/mera-emi-ctwa-user-details.component';
import { DocUplodsComponent } from './doc-uplods/doc-uplods.component';




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyCb44fZMVNTqsA7phK5chbOolMgsJl9mFw'
    }),
    CalendarModule.forRoot()
  ],
  declarations: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    ResetPasswordComponent,   
    LoansComponent,
    SetPasswordComponent,
    LoanDetailsComponent,    
    ModalComponent,
    MisListDetailsComponent,
    LeadsComponent,   
    PendingFeedbackComponent,
    LeadDetilsComponent,
    MeraemiStageActiveComponent,
    MeraemiStageCancelComponent,
    MeraemiStageTransferComponent,
    LeadStageComponent,
    LeadsStageLeastComponent,
    DetailedMisComponent,
   
    MeraemiAllUsersLeadsComponent,
   
    MeraemiAllUsersLeadsDetailsComponent,
   
    EligibilityFeedBackComponent,
   
    EligibilityFollowUpStatusComponent,
   
    FacebookAdComponent,
   
    MeraemiDashboardComponent,
   
    MeraemiEmailComponent,
   
    MeraemiLenderDocumentsComponent,
   
    MeraEmiIAPErrorComponent,
   
    MeraemiContactRequestComponent,
   
    MeraemiEmailCampaignComponent,
   
    MeraemiOfflineIapCompleteComponent,
   
    MisSendLenderComponent,
   
    LenderContactComponent,
   
    MeraemiCriteriaFailComponent,
   
    MmeraemiCriteriaFailDetailsComponent,
   
    LenderProdOfferListComponent,
   
    MeraemiSendUserMailComponent,
   
    MeaemiEmailCampainComponent,
   
    MeraemiHlbtLeadsComponent,
   
    MeraemiHlbtLeadsDetailsComponent,
   
    MeraemiDocumentViewComponent,
   
    MeaemiCrmStatusMComponent,
    MeraemiIapCompleteComponent,
    DashboardNewComponent,
    TempSelfEmpDataComponent,
    MeraemiSelfEmpUserListComponent,
    MeraemiUsersLeadsSelfEmpDetailsComponent,
    MeraemiCampaignComponent,
    MeraEMISelfProLeadsComponent,
    MeraEMIAllUsersSalariedComponent,
    MeraEMIBusinessPartnerComponent,
    MeraemiBusinessPartnerDetailsComponent,
    MeraemiUsersLeadsProfessionalDetailsComponent,
    MeraEMIActiveApplicationDashboardComponent,
    MeraEMISelfEmpCrmstageComponent,
    MeraEMIFacebookAdComponent,
    MeraEMIWhatsappLeadsComponent,
    MeraEMIWhatsappLeadsDetailsComponent,
    MeraEMIUserRegisterComponent,
    MeraEMIRegisterFormEditComponent,
    MeraEMIDocuplodsUserComponent,
    MeraEMICTWALeadListingComponent,
    MeraEMICtwaUserDetailsComponent,
    DocUplodsComponent,
    
   
    
  ],
  exports: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    SetPasswordComponent,
    ResetPasswordComponent,  
    LoansComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }
