/*app.component.ts*/
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { formatDate } from '@angular/common';
import { MdbTableDirective, MdbTablePaginationComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
import { FacebookAdService } from 'src/app/services/facebook-ad/facebook-ad.service';
import * as moment from 'moment';

@Component({
	selector: 'app-mera-emi-active-application-dashboard',
	templateUrl: './mera-emi-active-application-dashboard.component.html',
	styleUrls: ['./mera-emi-active-application-dashboard.component.scss']
})
 
export class MeraEMIActiveApplicationDashboardComponent implements OnInit {
 // @ViewChild('datefrom', {static: true}) usernameElement: ElementRef;
  @ViewChild("datefrom", {static: true}) datefrom: ModalDirective;
  @ViewChild("dateto", {static: true}) dateto: ModalDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePaginations: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTables: MdbTableDirective
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
	FirstDaydate: string;
	optionsadid: any = []; 
  currentdate: string;
  formdates:  number=0;	
  registerForm: FormGroup;
  main_array: any=[];
  showLoader: boolean;
  router: any;
  form: string;
  to: string;
  activedate:  number=0;	
  daternagediv: number=0;	
  public radioModel: string = 'Left';
  tabval:number=0;
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  lastmontStartDate: string;
  lastmontEndDate: string;
  totalApplicationActiveCurrentMonth:number=0;
  totalLonMtAciveCurrentMonth: number=0;
  activeDocPending: any = []; 
  activeDocPendingTotal:  number=0;
  activeDocReceived: any = []; 
  activeDocReceivedTotal: number=0;
  activeTransfer:  any = []; 
  activeTransferTotal: number=0;
  activeLenderProcess: any = [];
  activeLenderProcessTotal: number=0;
  
  activeDisbursed: any = [];
  uid:number=0;
  activeDisbursedTotal: number=0;
  activeLoanTap: any = [];
  activeLoanTapTotal: number=0;
  activeFullerton: any = [];
  activeFullertonTotal: number=0;
  activeIdfcFirstBank: any = [];
  activeIdfcFirstBankTotal: number=0; 
  activeABCLBank: any = [];
  activeABCLBankTotal: number=0; 
  activeTataCapital:  any = [];
  activeTataCapitalTotal: number=0; 
  activeKotak: any = [];
  activeKotakTotal:number=0; 
  activeHDB: any = [];
  activeHDBTotal: number=0; 
 

  totalLonMtInAciveCurrentMonth : number=0; 
  totalApplicationInActiveCurrentMonth : number=0; 
  InactiveCustCancel : any = [];
  inactiveCRMCancel: any = [];
   inactiveCRMDecline: any = [];
   InactiveCustCancelTotal: number=0; 
   inactiveCRMCancelTotal: number=0; 
   inactiveCRMDeclineTotal: number=0; 

   inActiveLoanTap: any = [];
   inActiveLoanTapTotal: number=0;
   inActiveFullerton: any = [];
   inActiveFullertonTotal: number=0;
   inActiveIdfcFirstBank: any = [];
   inActiveIdfcFirstBankTotal: number=0;
   inActiveABCLBank: any = [];
   inActiveABCLBankTotal: number=0;
   inActiveTataCapital: any = [];
   inActiveTataCapitalTotal: number=0;
   inActiveKotak: any = [];
   inActiveKotakTotal: number=0;
   inActiveHDB: any = [];
   inActiveHDBTotal: number=0;
  loanAmtActive: number=0;
  loanAmtinActive: number=0;
 
  activelendingkartTotal: number=0;
  activeAxisBankTotal: number=0;
  activeHeroFincorpLtdTotal: number=0;
  activePoonawallaFinanceLtdTotal: number=0;
  inActivelendingkartTotal: number=0;
  inActiveAxisBankTotal: number=0;
  inActiveHeroFincorpLtdTotal: number=0;
  inActivePoonawallaFinanceLtdTotal: number=0;
  activeLenderTotal: number=0;
  inactiveLenderTotal: number=0;

//lastmonth Active user
lastmonthLoanAmtActive: number=0;
LastMonthloanAmtActive: number=0;
lastMontActiveTotal: number=0;
activeDocPendingLastMonth: any = [];
activeDocPendingLastMonthTotal: number=0;
activeDocReceivedLastMonth: any = [];
activeDocReceivedLastMonthTotal: number=0;
activeTransferLastMonth: any = [];
activeTransferLastMonthTotal: number=0;
activeLenderProcessLastMonth: any = [];
activeLenderProcessLastMonthTotal: number=0;
activeDisbursedLastMonth: any = [];
activeDisbursedLastMonthTotal: number=0;
activeLenderLastmonthTotal: number=0;
activeLoanTapLastMonth: any = [];
activeLoanTapLastMonthTotal: number=0;
activeFullertonLastMonth: any = [];
activeFullertonLastMonthTotal: number=0;
activeIdfcFirstBankLastMonth: any = [];
activeIdfcFirstBankLastMonthTotal: number=0;
activeABCLBankLastMonth: any = [];
activeABCLBankLastMonthTotal: number=0;
activeTataCapitalLastMonth: any = [];
activeTataCapitalLastMonthTotal: number=0;
activeKotakLastMonth: any = [];
activeKotakLastMonthTotal: number=0;
activeHDBLastMonth: any = [];
activeHDBLastMonthTotal: number=0;
activelendingkartLastMonthTotal: number=0; 
activeAxisBankLastMonthTotal: number=0;
activeHeroFincorpLtdLastMonthTotal: number=0;
   

activePoonawallaFinanceLtdLastMonthTotal: number=0;previousdayFrom: string;
  previousdayTo: string;
  totoaldocCompletePercentage: number;
  docCompletDiv: number;
  totoaldocReceivedPercentage: number;
  totoalDocIncompletePercentage: number;
  totoalDocPendingPercentage: number;
  applicationActiveDiv: number;
  totoalApplicationActivePercentage: number;
  loanAmtDiv: number;
  loanAmtPercentage: number;
  totoalPendingReviewPercentage: number;
  transferDiv: number;
  totoaltransferPercentage: number;
  lenderProcessDiv: number;
  totoallenderProcessPercentage: number;
  disbursedDiv: number;
  totoaldisbursedPercentage: number;
  leadApplicationsInactDiv: number;
  totoaleadApplicationsInacPercentage: number;
  elig_status: string;
  appstage: string;
  lender_name: string;
  provider: string;
  empcategory: string;
  meraemistage: any;
  elements: any= [];
  lendername: any;
  maindataToDate: string;
  maindatafFomDate: string;
  elementsdate: any= [];
  tabledata: number=0;
  tableDataFromDate: string;
  tableDataToDate: string;
  totalusercount: any=0;
  results: any;
  maindataFilter: any= [];
activeOfflineLastMonth: any = [];
activeOfflineLastMonthTotal: number=0;
datanotfound: number=0;

activeOffline: any = [];
activeOfflineTotal: number=0;
inActiveOffline: any = [];
inActiveOfflineTotal: number=0;
//lastmonth 
totalApplicationInActiveLastMonth: number=0;
totalLonMtInAciveLastMonth: number=0;
InactiveCustCancelLastMonth: any = [];
InactiveCustCancelLastMonthTotal: number=0;
inactiveCRMCancelLastMonth: any = [];
inactiveCRMCancelLastMonthTotal: number=0;
inactiveCRMDeclineLastMonth: any = [];
inactiveCRMDeclineLastMonthTotal: number=0;
inActiveOfflineLastMonth: any = [];
inActiveOfflineLastMonthTotal: number=0;
inActiveLoanTapLastMonth: any = [];
inActiveLoanTapLastMonthTotal: number=0;
inActiveFullertonLastMonth: any = [];
inActiveFullertonLastMonthTotal: number=0;
inActiveIdfcFirstBankLastMonth: any = [];
inActiveIdfcFirstBankLastMonthTotal: number=0;
inActiveABCLBankLastMonth: any = [];
inActiveABCLBankLastMonthTotal: number=0;
inActiveTataCapitalLastMonth: any = [];
inActiveTataCapitalLastMonthTotal: number=0;
inActiveKotakLastMonth: any = [];
inActiveKotakLastMonthTotal: number=0;
inActiveHDBLastMonth: any = [];
inActiveHDBLastMonthTotal: number=0;
inActivelendingkartLastMonthTotal: number=0;adid: any;
  elig_c:  number=0;
  elig_p:  number=0;
  doc_p: number=0;
  doc_r: number=0;
  secStartDate: string;
  secEndDate: string;
  thirdStartDate: string;
  thirdEndDate: string;
  forthStartDate: string;
  forthEndDate: string;
  fiveStartDate: string;
  fiveEndDate: string;
  SixtartDate: string;
  SixEndDate: string;
  currentdateStart: string;
  currentdateEnd: string;
  

inActiveAxisBankLastMonthTotal: number=0;;
inActiveHeroFincorpLtdLastMonthTotal: number=0;;
inActivePoonawallaFinanceLtdLastMonthTotal: number=0;;
///Active
activePendingReview: any = [];
activeCallPending: any = [];
activeCallComplete: any = [];
activeDocComplete: any = [];
activeDocIncomplete: any = [];
activePendingReviewTotal: number=0;
activeCallPendingTotal: number=0;                  
activeCallCompleteTotal: number=0;                
activeDocCompleteTotal: number=0;
activeDocIncompleteTotal: number=0;
//lstmonth Inactive
activePendingReviewLastMonth: any = [];
activePendingReviewLastMonthTotal: number=0;
activeCallPendingLastMonth: any = [];
activeCallPendingLastMonthTotal: number=0;
activeCallCompleteLastMonth: any = [];
activeCallCompleteLastMonthTotal: number=0;
activeDocCompleteLastMonth: any = [];
activeDocCompleteLastMonthTotal: number=0;
activeDocIncompleteLastMonth: any = [];
activeDocIncompleteLastMonthTotal: number=0;
PendingReviewDiv: number=0;
PendingReview: number=0;
callPendinDiv: number=0;
 callPendin: number=0;
 callCompleteDiv: number=0;
 callComplete: number=0;
 docComplete: number=0;
 docCompleteDiv: number=0;
 docIncompleteDiv: number=0;
 docIncomplete: number=0;
 docPendingDiv: number=0;
 docPending: number=0;
 docReceivedDiv: number=0;
 docReceived: number=0;
 //graph
 activeDocPendingGrap:  any = []; 
activeDocReceivedGraph:  any = []; 
activeTransferGraph:  any = []; 
activeLenderProcessGraph:  any = []; 
activeDisbursedGraph:  any = []; 
activePendingReviewGraph:  any = []; 
activeCallPendingGraph:  any = []; 
activeCallCompleteGraph:  any = []; 
activeDocCompleteGraph:  any = []; 
activeDocIncompleteGraph:  any = []; 
cust: {} ;
mainaaary: any = []; 
mainaaaryInactive: any = []; 
previous: any = [];
result: any = [];
elementsdatearray: any = [];
allLeadslov: any = [];
elementsdatefacebookAd: any = [];
facebookadFilterData: any = [];
totaview: number=0;
elementsdatefacebookAdFilter: any = [];
fbDatafilter: any = [];
adDateFilter: string;
filterday: any = [];
adfiderday: string;
elementsdatefacebookAdPAUSED: any = [];
activedataTotal: number=0;
dataTableFilter: number=0;
sixMonthArrayData: any = [];
resultFrstArray: any = [];
totalEligFisrt: number=0;
totalDocReciv: number=0;
resultSecArray: any = [];
totalEligSec: number=0;
totalDocSec: number=0;
resultThirArray: any = [];
totalDocThir: number=0;
totalEligThir: number=0;
resultForthArray: any = [];
totalEligForth: number=0;
totalDocForth: number=0;
resultFiveArray: any = [];
totalEligFive: number=0;
totalDocFive: number=0;
resultSixArray: any = [];
totalEligSix: number=0;
totalDocSix: number=0;
arrayDocReciv: any = [];
resultDocRecivFirst: any = [];
resultDocRecivSec: any = [];
resultDocRecivThird: any = [];
resultDocRecivForth: any = [];
resultDocRecivFive: any = [];
resultDocRecivSix: any = [];
elementsAll: any = [];
elementsPageview: any = [];
	constructor(  private formBuilder: FormBuilder, private cryptojsService:CryptojsService,
		private leadsService:LeadsService ,private DashboardService:DashboardService,
    private SelfEmpLeadService:SelfEmpLeadService, private cdRef: ChangeDetectorRef,    private facebookAdService:FacebookAdService,
    ) {


           

     }
	
		  
     ngOnInit() {    
  
      this.tabval=1;
      let date = new Date();
            //month first date   
            var ourDate = new Date();
            let firstDays = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // adjust 0 before single digit date
            let datesFirstDay = ("0" + firstDays.getDate()).slice(-2);
            // current month
            let monthFirstDay = ("0" + (firstDays.getMonth() + 1)).slice(-2);
            // current year
            let yearFirstDay = firstDays.getFullYear();
            this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
            //console.log('current Month Start Date' +this.FirstDaydate);
  
  
  
     
              //month current day
            
              
              let dates = ("0" + date.getDate()).slice(-2);
              // current month
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              // current year
              let year = date.getFullYear();
              this.currentdate =(year + "-" + month + "-" + dates);
            //  console.log('current Month Current Date' +this.currentdate);            
  
            this.registerForm = this.formBuilder.group({			
              filterchart: this.formBuilder.group({	 
                 date : ['', Validators.required],
                 datefrom: ['', Validators.required],
                 dateto: ['', Validators.required],
              }),
              maindatafilter: this.formBuilder.group({	 
                 date : ['', Validators.required],
                 datefroms: ['', Validators.required],
                 datetos: ['', Validators.required],
              }),
            
              });
              this.optionsadid.push(
                {
                value: "0",
                label: "Today",
                },
                {
                  value: "1",
                  label: "Yesterday",
                  },
                  {
                    value: "7",
                    label: "Last 7 Days",
                    },
                    {
                      value: "30",
                      label: "Last 30 Days",
                      }
                    ,
                    {
                      value: "thismonth",
                      label: "This Month",
                      }
                      ,
                      
                    {
                      value: "lastmonth",
                      label: "Last  Month",
                      },
                      
                      {
                        value: "date",
                        label: "Custom Date Range",
                        }
                      
                        
                  
                );
              this.allLeadslov.push(
                {
                value: "0",
                label: "Today",
                },
                {
                  value: "1",
                  label: "Yesterday",
                  },
                  {
                    value: "7",
                    label: "Last 7 Days",
                    },
                    {
                      value: "30",
                      label: "Last 30 Days",
                      }
                    ,
                    {
                      value: "thismonth",
                      label: "This Month",
                      }
                      ,
                      
                    {
                      value: "lastmonth",
                      label: "Last  Month",
                      },
                      
                     
                        
                      {
                        value: "60",
                        label: "60 Days",
                        }
                        ,
                        {
                          value: "dateTable",
                          label: "Custom Date Range",
                          }
                  
                );
                this.facebookadFilterData.push(
                  {
                  value: "today",
                  label: "today",
                  },
                  {
                    value: "yesterday",
                    label: "yesterday",
                    },
                    {
                      value: "last_3d",
                      label: "last_3d",
                      },
                      {
                        value: "last_7d",
                        label: "last_7d",
                        }
                      ,
                      {
                        value: "last_14d",
                        label: "last_14d",
                        }                        
                        ,                        
                        {
                        value: "last_28d",
                        label: "last_28d",
                        },
                        {
                          value: "last_30d",
                          label: "last_30d",
                          },
                          {
                            value: "last_90d",
                            label: "last_90d",
                            },{
                              value: "last_week_mon_sun",
                              label: "last_week_mon_sun",
                              },{
                                value: "last_week_sun_sat",
                                label: "last_week_sun_sat",
                                },{
                                  value: "this_month",
                                  label: "this_month",
                                  },{
                                    value: "last_month",
                                    label: "last_month",
                                    },{
                                      value: "this_quarter",
                                      label: "this_quarter",
                                      },{
                                        value: "maximum",
                                        label: "maximum",
                                        },{
                                          value: "data_maximum",
                                          label: "data_maximum",
                                          },{
                                            value: "last_quarter",
                                            label: "last_quarter",
                                            },{
                                              value: "last_year",
                                              label: "last_year",
                                              },{
                                                value: "this_week_mon_today",
                                                label: "this_week_mon_today",
                                                },{
                                                  value: "this_week_sun_today",
                                                  label: "this_week_sun_today",
                                                  },{
                                                    value: "this_year",
                                                    label: "this_year",
                                                    },
                    
                  );



                // Active stage
                      //this month Active data show 	  
                         let detail = { results: [{ 
                        "fromdate":this.FirstDaydate,            
                        "todate":this.currentdate,          
                        
                        }] };
                        detail['results']; 
                        this.totalLonMtAciveCurrentMonth=0;
                       this.DashboardService.getActiveApplications(detail).subscribe((result: any) => {
                       // console.log(result);
                        if(result.status===true &&  result.response=== 200){
                        //console.log(result.selfemp);
                            this.totalApplicationActiveCurrentMonth=result.selfemp.length;
                          
                            for (let i = 0; i < result.selfemp.length; i++) {
                              const startMonthmenuarray = result.selfemp[i]; 
                            //  console.log(startMonthmenuarray.data[0].feedback_stage_id);          
                              this.totalLonMtAciveCurrentMonth += Number(startMonthmenuarray.data[0].loan_amount);	
                          
                              if(startMonthmenuarray.data[0].feedback_stage_id =="3"){
                                this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});      
                              }else  if(startMonthmenuarray.data[0].feedback_stage_id =="20" ){
                                this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});             
                              }
                              //Doc Received
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='21'){
                                this.activeDocReceived.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Transfer
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='18'){
                                this.activeTransfer.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              
                              //Lender Process
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='17'){
                                this.activeLenderProcess.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Disbursed
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='19'){
                                this.activeDisbursed.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Pending Review
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='13'){
                                this.activePendingReview.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Call Pending
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='22'){
                                this.activeCallPending.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Call Complete                    
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='23'){
                                this.activeCallComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Doc Complete                  
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='25'){
                                this.activeDocComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }
                              //Doc Incomplete
                              if(startMonthmenuarray.data[0].feedback_stage_id ==='26'){
                                this.activeDocIncomplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                                
                              }


                                  //tranfer to lender list
                                  this.uid= parseInt(startMonthmenuarray.data[0].user_id);

                                  this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                                    (result:any) => {
                                    
                                      if(result.response=== 200  && result.status === true){
                                        
                                      let lenderdata= result.data[0].lenderTransfer;
                                      this.activeLenderTotal= lenderdata.length;
                                        for (let i = 0; i < lenderdata.length; i++) {
                                          const lenderArray = lenderdata[i];
                                        //Offline
                                        if(lenderArray.lenderId ==='1'){
                                          this.activeOffline.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                          //Loan Tap
                                          if(lenderArray.lenderId ==='2'){
                                            this.activeLoanTap.push({ lenderName:lenderArray.lenderName}); 
                                            
                                          }
                                          // FULLERTON INDIA
                                          if(lenderArray.lenderId ==='22'){
                                            this.activeFullerton.push({ lenderName:lenderArray.lenderName}); 
                                            
                                          }
                                            // "IDFC First Bank"
                                          if(lenderArray.lenderId ==='53'){
                                            this.activeIdfcFirstBank.push({ lenderName:lenderArray.lenderName}); 
                                            
                                          }
                                            //Aditya Birla Capital Ltd
                                          if(lenderArray.lenderId ==='26'){
                                            this.activeABCLBank.push({ lenderName:lenderArray.lenderName}); 
                                            
                                          }
                                        //Tata Capital Limited
                                        if(lenderArray.lenderId ==='19'){
                                          this.activeTataCapital.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                        //'Kotak Mahindra Bank Ltd'
                                        if(lenderArray.lenderId ==='14'){
                                          this.activeKotak.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                        //HDB Financial Services Ltd
                                        if(lenderArray.lenderId ==='31'){
                                          this.activeHDB.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                    
                                        }
                                            // offline
                                            
                                            this.activeOfflineTotal=this.activeOffline.length;
                                          //Loan Tap
                                          this.activeLoanTapTotal=this.activeLoanTap.length;
                                        // FULLERTON INDIA
                                        this.activeFullertonTotal=this.activeFullerton.length;
                                        // IDFC First Bank
                                        this.activeIdfcFirstBankTotal=this.activeIdfcFirstBank.length;
                                          //Aditya Birla Capital Ltd
                                        this.activeABCLBankTotal=this.activeABCLBank.length;
                                        //Tata Capital Limited
                                        this.activeTataCapitalTotal=this.activeTataCapital.length;
                                          //'Kotak Mahindra Bank Ltd'
                                        this.activeKotakTotal=this.activeKotak.length;
                                          //HDB Financial Services Ltd
                                          this.activeHDBTotal=this.activeHDB.length;
                                        // console.log(this.activeKotakTotal);

                                        //not useLendingkart
                                        this.activelendingkartTotal=0;
                                        //not Axis Bank
                                        this.activeAxisBankTotal=0;
                                        //Hero Fincorp Ltd
                                        this.activeHeroFincorpLtdTotal=0;
                                        //Poonawalla Finance Ltd
                                        this.activePoonawallaFinanceLtdTotal=0;
                                      }else{
                                        
                                      }
                                    });

                            }
                             //Doc Pending
                             this.activeDocPendingTotal=this.activeDocPending.length;
                             //Doc Received
                             this.activeDocReceivedTotal=this.activeDocReceived.length;
                             //Transfer
                             this.activeTransferTotal=this.activeTransfer.length;
                             //Lender Process
                             this.activeLenderProcessTotal=this.activeLenderProcess.length;
                             //Disbursed
                             this.activeDisbursedTotal=this.activeDisbursed.length;
                             //Pending Review
                             this.activePendingReviewTotal=this.activePendingReview.length;
                             //Call Pending
                             this.activeCallPendingTotal=this.activeCallPending.length;
                             //Call Complete                    
                             this.activeCallCompleteTotal=this.activeCallComplete.length;
                             //Doc Complete                  
                             this.activeDocCompleteTotal=this.activeDocComplete.length;
                             //Doc Incomplete
                             this.activeDocIncompleteTotal=this.activeDocIncomplete.length;
                            //  console.log(this.activeDocIncompleteTotal);
                              //totalLonMtAciveCurrentMonth
                              this.loanAmtActive =(this.totalLonMtAciveCurrentMonth);
                          }
                          let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
                          let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
                          // current month
                          let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
                          // current year
                          let lstmontYear = lstmontfirstday.getFullYear();
                          this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
                          // console.log('Previous  Month Start Date' +this.lastmontStartDate);
                          
                          let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
                          let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
                          // current month
                          let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
                          // current year
                          let lstmontEndYear = lstmontLasttday.getFullYear();
                          //  this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
                          this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + dates);
                          
                          // console.log('Previous  Month End Date' +this.lastmontEndDate);
                          //lstmonth Active user     
                          //lstmonth Active user     

                            let detailLastMonthActive = { results: [{ 
                              "fromdate":this.lastmontStartDate,            
                              "todate":this.lastmontEndDate,          
                              
                              }] };
                              detailLastMonthActive['results']; 
                                this.lastmonthLoanAmtActive=0;
                                this.DashboardService.getActiveApplications(detailLastMonthActive).subscribe((lastmonthActiveUser: any) => {

                                if(lastmonthActiveUser.status===true &&  lastmonthActiveUser.response=== 200){

                                this.lastMontActiveTotal=lastmonthActiveUser.selfemp.length;

                                for (let i = 0; i < lastmonthActiveUser.selfemp.length; i++) {
                                const lastmonthActive = lastmonthActiveUser.selfemp[i];           
                                this.lastmonthLoanAmtActive += Number(lastmonthActive.data[0].loan_amount);	

                                //Doc Pending
                                if(lastmonthActive.data[0].feedback_stage_id =="1"){
                                  this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});      
                                  }else  if(lastmonthActive.data[0].feedback_stage_id =="20" ){
                                  this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});             
                                  }
                                  //Doc Received
                                  if(lastmonthActive.data[0].feedback_stage_id ==='21'){
                                  this.activeDocReceivedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                  }
                                  //Transfer
                                  if(lastmonthActive.data[0].feedback_stage_id ==='18'){
                                  this.activeTransferLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                  }
                                  //Lender Process
                                  if(lastmonthActive.data[0].feedback_stage_id ==='17'){
                                  this.activeLenderProcessLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                  }
                                  //Disbursed
                                  if(lastmonthActive.data[0].feedback_stage_id ==='19'){
                                  this.activeDisbursedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                  }
                                  
                                  
                                  //Pending Review
                                  if(lastmonthActive.data[0].feedback_stage_id ==='13'){
                                    this.activePendingReviewLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                    
                                  }
                                  //Call Pending
                                  if(lastmonthActive.data[0].feedback_stage_id ==='22'){
                                    this.activeCallPendingLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                    
                                  }
                                  //Call Complete                    
                                  if(lastmonthActive.data[0].feedback_stage_id ==='23'){
                                    this.activeCallCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                    
                                  }
                                  //Doc Complete                  
                                  if(lastmonthActive.data[0].feedback_stage_id ==='25'){
                                    this.activeDocCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                    
                                  }
                                  //Doc Incomplete
                                  if(lastmonthActive.data[0].feedback_stage_id ==='26'){
                                    this.activeDocIncompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                    
                                  }                    


                                //tranfer to lender list
                                this.uid= parseInt(lastmonthActive.data[0].user_id);

                                this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                                (resultLender:any) => {

                                  if(resultLender.response=== 200  && resultLender.status === true){
                                    
                                  let lenderdataLender= resultLender.data[0].lenderTransfer;
                                  this.activeLenderLastmonthTotal= lenderdataLender.length;;
                                    for (let i = 0; i < lenderdataLender.length; i++) {
                                      const lenderArrayLastmonth = lenderdataLender[i];
                                      //offline
                                      if(lenderArrayLastmonth.lenderId ==='1'){
                                        this.activeOfflineLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                        
                                      }
                                      //Loan Tap
                                      if(lenderArrayLastmonth.lenderId ==='2'){
                                        this.activeLoanTapLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                        
                                      }
                                      // FULLERTON INDIA
                                      if(lenderArrayLastmonth.lenderId ==='22'){
                                        this.activeFullertonLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                        
                                      }
                                        // "IDFC First Bank"
                                      if(lenderArrayLastmonth.lenderId ==='53'){
                                        this.activeIdfcFirstBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                        
                                      }
                                        //Aditya Birla Capital Ltd
                                      if(lenderArrayLastmonth.lenderId ==='26'){
                                        this.activeABCLBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                        
                                      }
                                    //Tata Capital Limited
                                    if(lenderArrayLastmonth.lenderId ==='19'){
                                      this.activeTataCapitalLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                    //'Kotak Mahindra Bank Ltd'
                                    if(lenderArrayLastmonth.lenderId ==='14'){
                                      this.activeKotakLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                    //HDB Financial Services Ltd
                                    if(lenderArrayLastmonth.lenderId ==='31'){
                                      this.activeHDBLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }

                                    }
                                        // console.log(this.activeKotakLastMonth);
                                          //offline
                                      this.activeOfflineLastMonthTotal=this.activeOfflineLastMonth.length;
                                      //Loan Tap
                                      this.activeLoanTapLastMonthTotal=this.activeLoanTapLastMonth.length;
                                    // FULLERTON INDIA
                                    this.activeFullertonLastMonthTotal=this.activeFullertonLastMonth.length;
                                    // IDFC First Bank
                                    this.activeIdfcFirstBankLastMonthTotal=this.activeIdfcFirstBankLastMonth.length;
                                      //Aditya Birla Capital Ltd
                                    this.activeABCLBankLastMonthTotal=this.activeABCLBankLastMonth.length;
                                    //Tata Capital Limited
                                    this.activeTataCapitalLastMonthTotal=this.activeTataCapitalLastMonth.length;
                                      //'Kotak Mahindra Bank Ltd'
                                    this.activeKotakLastMonthTotal=this.activeKotakLastMonth.length;
                                      //HDB Financial Services Ltd
                                      this.activeHDBLastMonthTotal=this.activeHDBLastMonth.length;
                                    // console.log(this.activeKotakLastMonthTotal);

                                    //not useLendingkart
                                    this.activelendingkartLastMonthTotal=0;
                                    //not Axis Bank
                                    this.activeAxisBankLastMonthTotal=0;
                                    //Hero Fincorp Ltd
                                    this.activeHeroFincorpLtdLastMonthTotal=0;
                                    //Poonawalla Finance Ltd
                                    this.activePoonawallaFinanceLtdLastMonthTotal=0;
                                  }else{
                                    this.activeLenderLastmonthTotal=0; 
                                  }
                                });

                                }

                                //Doc Pending
                                this.activeDocPendingLastMonthTotal=this.activeDocPendingLastMonth.length;
                                //Doc Received
                                this.activeDocReceivedLastMonthTotal=this.activeDocReceivedLastMonth.length;
                                //Transfer
                                this.activeTransferLastMonthTotal=this.activeTransferLastMonth.length;
                                //Lender Process
                                this.activeLenderProcessLastMonthTotal=this.activeLenderProcessLastMonth.length;
                                //Disbursed
                                this.activeDisbursedLastMonthTotal=this.activeDisbursedLastMonth.length;

                                //Pending Review
                                this.activePendingReviewLastMonthTotal=this.activePendingReviewLastMonth.length;
                                //Call Pending
                                this.activeCallPendingLastMonthTotal=this.activeCallPendingLastMonth.length;
                                //Call Complete                    
                                this.activeCallCompleteLastMonthTotal=this.activeCallCompleteLastMonth.length;
                                //Doc Complete                  
                                this.activeDocCompleteLastMonthTotal=this.activeDocCompleteLastMonth.length;
                                //Doc Incomplete
                                this.activeDocIncompleteLastMonthTotal=this.activeDocIncompleteLastMonth.length;
                                //lastmonthLoanAmtActive
                                this.LastMonthloanAmtActive =(this.lastmonthLoanAmtActive);

                                }
 // --------------------------------------------------------------------------------------------------------------


                   // ApplicationActive total percentage
                   let applicationActive=(this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal);
                      
                   if(applicationActive > 0){ 
                       const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                       if(totoalapplicationActive !=Infinity){
                         this.applicationActiveDiv=1;
                         this.totoalApplicationActivePercentage=totoalapplicationActive;
                       }else{
                         this.docPendingDiv=1;
                         this.totoalApplicationActivePercentage=100;
                       }
                  
                    } else  if(applicationActive < 0){ 
                     const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                       if(totoalapplicationActive !=Infinity){
                         this.applicationActiveDiv=0;
                         this.totoalApplicationActivePercentage=totoalapplicationActive;
                       }else{
                         this.docPendingDiv=0;
                         this.totoalApplicationActivePercentage=100;
                       }
                    }else{
                     this.docPendingDiv=1;
                     this.totoalApplicationActivePercentage=100;
                   
                    }
                    // Loan Amount (Rs. Lacs) total percentage
                   let loanAmt=(this.loanAmtActive-this.LastMonthloanAmtActive);
                                         
                   if(loanAmt > 0){ 
                       const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                       if(totoalLoanAmt !=Infinity){
                         this.loanAmtDiv=1;
                         this.loanAmtPercentage=totoalLoanAmt;
                       }else{
                         this.loanAmtDiv=1;
                         this.loanAmtPercentage=100;
                       }

                   } else  if(loanAmt < 0){ 
                     const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                     if(totoalLoanAmt !=Infinity){
                       this.loanAmtDiv=0;
                       this.loanAmtPercentage=totoalLoanAmt;
                     }else{
                       this.loanAmtDiv=0;
                       this.loanAmtPercentage=100;
                     }
                   }else{
                     this.loanAmtDiv=1;
                     this.loanAmtPercentage=100;

                   }
                  //Doc Complete total percentage
                  let docComplete=(this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal);
                  //const  totoaldocComplet=0;
                  if(docComplete > 0){ 
                  const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
                  if(totoaldocComplet !=Infinity){
                    this.docCompletDiv=1;
                    this.totoaldocCompletePercentage=totoaldocComplet;
                  }else{
                    this.docCompletDiv=1;
                    this.totoaldocCompletePercentage=100;
                  }
                //  console.log('positive');
                  } else  if(docComplete < 0){ 
                    const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
                    if(totoaldocComplet !=Infinity){
                      this.docCompletDiv=0;
                      this.totoaldocCompletePercentage=totoaldocComplet;
                    }else{
                      this.docCompletDiv=0;
                      this.totoaldocCompletePercentage=100;
                    }
                  }else{
                    this.docCompletDiv=1;
                    this.totoaldocCompletePercentage=100;
                  }
                  //Doc Received total percentage                 
                  let DocReceived=(this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal);
                  //console.log(this.activeDocReceivedLastMonthTotal);
                  //const  totoaldocComplet=0;
                  if(DocReceived > 0){ 
                      const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
                      if(totoalDocReceived !=Infinity){
                        this.docReceivedDiv=1;
                        this.totoaldocReceivedPercentage=totoalDocReceived;
                      }else{
                        this.docReceivedDiv=1;
                        this.totoaldocReceivedPercentage=100;
                      }
                  //  console.log('positive');
                    } else  if(DocReceived < 0){ 
                    const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
                    if(totoalDocReceived !=Infinity){
                      this.docReceivedDiv=0;
                      this.totoaldocReceivedPercentage=totoalDocReceived;
                    }else{
                      this.docReceivedDiv=0;
                      this.totoaldocReceivedPercentage=100;
                    }
                    }else{
                      this.docReceivedDiv=1;
                      this.totoaldocReceivedPercentage=100;
                    }
                  //Doc Incomplete total percentage
                  let DocIncomplete=(this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal);
                
                  if(DocIncomplete > 0){ 
                      const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
                      if(totoalDocIncomplete !=Infinity){
                        this.docIncompleteDiv=1;
                        this.totoalDocIncompletePercentage=totoalDocIncomplete;
                      }else{
                        this.docIncompleteDiv=1;
                        this.totoalDocIncompletePercentage=100;
                      }
                  
                    } else  if(DocIncomplete < 0){ 
                    const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
                    if(totoalDocIncomplete !=Infinity){
                      this.docIncompleteDiv=0;
                      this.totoalDocIncompletePercentage=totoalDocIncomplete;
                    }else{
                      this.docIncompleteDiv=0;
                      this.totoalDocIncompletePercentage=100;
                    }
                    }else{
                      this.docIncompleteDiv=1;
                      this.totoalDocIncompletePercentage=100;
                    }
                  //lDoc Pending total percentage   
                  let DocPending=(this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal);
                  // console.log(DocPending );
                  if(DocPending > 0){ 
                      const totoalDocPending =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
                      if(totoalDocPending !=Infinity){
                        this.docPendingDiv=1;
                        this.totoalDocPendingPercentage=totoalDocPending;
                      }else{
                        this.docPendingDiv=1;
                        this.totoalDocIncompletePercentage=100;
                      }
                  
                    } else  if(DocPending < 0){ 
                    const totoalDocIncomplete =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
                    if(totoalDocIncomplete !=Infinity){
                      this.docPendingDiv=0;
                      this.totoalDocIncompletePercentage=totoalDocIncomplete;
                      // console.log(this.totoalDocIncompletePercentage );
                    }else{
                      this.docPendingDiv=0;
                      this.totoalDocIncompletePercentage=100;
                    }
                    }else{
                      this.docPendingDiv=1;
                      this.totoalDocIncompletePercentage=100;
                  
                    }
                  //Pending Reviewtotal percentage   
                  let pendingReview=(this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal);
              
                  if(pendingReview > 0){ 
                      const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
                      if(totoalPendingReview !=Infinity){
                        this.PendingReviewDiv=1;
                        this.totoalPendingReviewPercentage=totoalPendingReview;
                      }else{
                        this.PendingReviewDiv=1;
                        this.totoalPendingReviewPercentage=100;
                      }
                  
                    } else  if(pendingReview < 0){ 
                    const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
                    if(totoalPendingReview !=Infinity){
                      this.PendingReviewDiv=0;
                      this.totoalPendingReviewPercentage=totoalPendingReview;
                    
                    }else{
                      this.PendingReviewDiv=0;
                      this.totoalDocIncompletePercentage=100;
                    }
                    }else{
                      this.PendingReviewDiv=1;
                      this.totoalPendingReviewPercentage=100;
                  
                    }
                    //Transfer percentage   
                    let transfer=(this.activeTransferTotal-this.activeTransferLastMonthTotal);
                
                    if(transfer > 0){ 
                        const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
                        if(totoaltransfer !=Infinity){
                          this.transferDiv=1;
                          this.totoaltransferPercentage=totoaltransfer;
                        }else{
                          this.transferDiv=1;
                          this.totoaltransferPercentage=100;
                        }
                    
                      } else  if(transfer < 0){ 
                      const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
                      if(totoaltransfer !=Infinity){
                        this.transferDiv=0;
                        this.totoaltransferPercentage=totoaltransfer;
                      
                      }else{
                        this.transferDiv=0;
                        this.totoalDocIncompletePercentage=100;
                      }
                      }else{
                        this.transferDiv=1;
                        this.totoaltransferPercentage=100;
                    
                      }
                       //Lender Process percentage   
                      let lenderProcess=(this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal);
                      
                      if(lenderProcess > 0){ 
                          const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
                          if(totoallenderProcess !=Infinity){
                            this.lenderProcessDiv=1;
                            this.totoallenderProcessPercentage=totoallenderProcess;
                          }else{
                            this.lenderProcessDiv=1;
                            this.totoallenderProcessPercentage=100;
                          }
                      
                        } else  if(lenderProcess < 0){ 
                        const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
                        if(totoallenderProcess !=Infinity){
                          this.lenderProcessDiv=0;
                          this.totoallenderProcessPercentage=totoallenderProcess;
                        
                        }else{
                          this.lenderProcessDiv=0;
                          this.totoalDocIncompletePercentage=100;
                        }
                        }else{
                          this.lenderProcessDiv=1;
                          this.totoallenderProcessPercentage=100;
                      
                        }
                       //Disbursed percentage   
                       let disbursed=(this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal);
           
                       if(disbursed > 0){ 
                           const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                           if(totoaldisbursed !=Infinity){
                             this.disbursedDiv=1;
                             this.totoaldisbursedPercentage=totoaldisbursed;
                           }else{
                             this.disbursedDiv=1;
                             this.totoaldisbursedPercentage=100;
                           }
                      
                        } else  if(disbursed < 0){ 
                         const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                         if(totoaldisbursed !=Infinity){
                          this.disbursedDiv=0;
                          this.totoaldisbursedPercentage=totoaldisbursed;
                        
                         }else{
                           this.disbursedDiv=0;
                           this.totoaldisbursedPercentage=100;
                         }
                        }else{
                          this.disbursedDiv=1;
                          this.totoaldisbursedPercentage=100;
                       
                        }
                       


                              });


                        });












                      

                        let graphinactive = { results: [{ 
                          "fromdate":this.FirstDaydate,            
                          "todate":this.currentdate,          
                          
                          }] };
                          graphinactive['results']; 
                          this.DashboardService.getActiveApplications(graphinactive).subscribe((grapresultAll: any) => {
                           
                            if(grapresultAll.status===true &&  grapresultAll.response=== 200){ 
                          
                                  // console.log(this.InactiveCustCancelLastMonth);                                     
                                      this.totalApplicationActiveCurrentMonth=grapresultAll.selfemp.length;                                              
                                      for (let i = 0; i < grapresultAll.selfemp.length; i++) {
                                        const graphStartMonthmenuarrayAll = grapresultAll.selfemp[i];  
                                        //Doc Pending

                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="3"){
                                              this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});      
                                            }else  if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="20" ){
                                              this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});             
                                            }
                                            //Doc Received
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='21'){
                                              this.activeDocReceivedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Transfer
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='18'){
                                              this.activeTransferGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            
                                            //Lender Process
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='17'){
                                              this.activeLenderProcessGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Disbursed
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='19'){
                                              this.activeDisbursedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Pending Review
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='13'){
                                              this.activePendingReviewGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Call Pending
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='22'){
                                              this.activeCallPendingGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Call Complete                    
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                              this.activeCallCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Doc Complete                  
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                              this.activeDocCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            }
                                            //Doc Incomplete
                                            if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='26'){
                                              this.activeDocIncompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                              
                                            } 
                                  
                                      }
                                    }
                                      this.DashboardService.getInActiveApplications(graphinactive).subscribe((resultInInactive: any) => {
                                        if(resultInInactive.status===true &&  resultInInactive.response=== 200){                                    
                                          this.totalApplicationInActiveLastMonth=resultInInactive.inactive.length;                                          
                                               for (let i = 0; i < resultInInactive.inactive.length; i++) {
                                                const menuarrayInactive = resultInInactive.inactive[i];  
                                                 //Cust Cancel
                                                  if(menuarrayInactive.data[0].feedback_stage_id  =="14"){
                                                    this.InactiveCustCancelLastMonth.push({ stage_id: menuarrayInactive.data[0].feedback_stage_id });      
                                                    }
                                                    //CRM Cancel
                                                    if(menuarrayInactive.data[0].feedback_stage_id  ==='15'){
                                                    this.inactiveCRMCancelLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                                    
                                                    }
                                                    //CRM Decline
                                                    if(menuarrayInactive.data[0].feedback_stage_id  ==='16'){
                                                    this.inactiveCRMDeclineLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                                    
                                                    }
                                               }
                                            
                                              }

                                              //-----------------------------------------------Graph api-----------------------------------------------------------------
                                              let  custCancel =   this.InactiveCustCancelLastMonth.length;
                                              let  crmCancel  = this.inactiveCRMCancelLastMonth.length;
                                              let  crmDecline  = this.inactiveCRMDeclineLastMonth.length;
                                              
                                              let  docReceived  = this.activeDocReceivedGraph.length;
                                              let  docPendin  = this.activeDocPendingGrap.length;
                                              let  transfer  = this.activeTransferGraph.length;
                                              let  lenderProcess  =this.activeLenderProcessGraph.length;
                                              let  disbursed  =this.activeDisbursedGraph.length;
                                              let  pendingReview  =this.activePendingReviewGraph.length;
                                              let  callPending  =this.activeCallPendingGraph.length;
                                              let  callComplete  =this.activeCallCompleteGraph.length;
                                              let  docIncomplete  =this.activeDocIncompleteGraph.length;
                                              
                                              
                                            // console.log(docReceived);
                                                 let cCancel =custCancel;
                                                 let crm =crmCancel;              
                                                 let decline =crmDecline;
                                                 let docrec =docReceived;
                                                 let docpend =docPendin;
                                                 let trans =transfer;
                                                 let lenderProc =lenderProcess;
                                                 let disb =disbursed;
                                                 let pendingRev =pendingReview;
                                                 let callPend =callPending;
                                                 let callComp =callComplete;
                                                let docIncomp =docIncomplete;
                                           
                                              
                                                                        this.cust={   allleadArray: [
                                                                              { y: cCancel, name: "Cust Cancel" , total: cCancel},
                                                                              { y: crm, name: "CRM Cancel",total: crm },
                                                                              { y: decline, name: "CRM Decline",total: decline },
                                                                              { y: docrec, name: "Doc Received",total: docrec },
                                                                              { y: docpend, name: "Doc Pending",total: docpend },
                                                                              { y: trans, name: "Transfer",total: trans },
                                                                              { y: lenderProc, name: "Lender Process",total: lenderProc },                                                  
                                                                              { y: disb, name: "Disbursed",total: disb },
                                                                            { y:pendingRev , name: "Pending Review",total: pendingRev    },
                                                                            { y:callPend , name: "Call Pending",total: callPend    },
                                                                            { y:callComp , name: "Call Complete",total: callComp    },
                                                                            { y:docIncomp , name: "Doc Incomplete",total: docIncomp    },
                                                                              
                                                                            ]
                                                                        }
                                               
                                              
                                                                        for (let i = 0; i < this.cust['allleadArray'].length; i++) {
                                                                          const menuarrayActive = this.cust['allleadArray'][i];
                                                                          if(menuarrayActive.y !=0){
                                                                          // console.log(menuarrayActive);
                                                                          // this.mainaaary =menuarrayActive;
                                                                            this.mainaaary.push(
                                                                              {
                                                                                y: menuarrayActive.y,
                                                                                name: menuarrayActive.name, 
                                                                                total: menuarrayActive.total, 
                                                                                            
                                                                              });
                                                                          }  
                                                                        
                                                                        }
                                          
                                                                      //Canvas 
                                                                         let charts = new CanvasJS.Chart("inactive", {
                                                                          theme: "light",
                                                                          animationEnabled: true,
                                                                          ///exportEnabled: false,
                                                                          title:{text: "All Leads - Stages"},
                                                                          data: [{
                                                                            type: "pie",
                                                                            showInLegend: true,
                                                                            toolTipContent: "<b>{name}</b>: {total}",
                                                                            indexLabel: "{name} - #percent%",
                                                                            dataPoints: this.mainaaary
                                                                          
                                                                            
                                                                          }]
                                                                        });
                                                                          
                                                                        charts.render();

                                                                        
                                                                       
                            
                              let cucancle =custCancel;
                              let crmCan =crmCancel;
                              let crmDec =crmDecline;
                            
                           let inactiveData=  { inactive: [
                                { y: cucancle, name: "Cust Cancel" ,total: cucancle},
                                { y: crmCan, name: "CRM Cancel",total: crmCan },
                                { y: crmDec, name: "CRM Decline",total: crmDec },
                                
                              ]}
                              for (let i = 0; i < inactiveData['inactive'].length; i++) {
                                const menuarrayInactive = this.cust['allleadArray'][i];
                                if(menuarrayInactive.y !=0){
                                  this.mainaaaryInactive.push(
                                    {
                                      y: menuarrayInactive.y,
                                      name: menuarrayInactive.name, 
                                      total: menuarrayInactive.total, 
                                                  
                                    });
                                }
                              }
                            //  console.log(this.mainaaaryInactive);

                            //dataPoints
                            
                            //Canvas 
                              let chart = new CanvasJS.Chart("chartContainer", {
                                theme: "light",
                                animationEnabled: true,
                                ///exportEnabled: false,
                                title:{text: "All Leads InActive-  Status"},
                                data: [{
                                  type: "pie",
                                  showInLegend: true,
                                  toolTipContent: "<b>{name}</b>: {total}",
                                  indexLabel: "{name} - #percent%",
                              
                                  dataPoints: this.mainaaaryInactive
                                  
                                }]
                              });
                                
                              chart.render();


                                            });

                                        });





                                        this.facebookAdService
                                        .getSelfempUserStage()
                                        .subscribe( (result:any) => {
                                       // console.log(result);
                                        
                                          if (result.status = true  && result.response=== 200) {      //        
                                            for (let i = 0; i < result.selfemp.length;  i++) {
                                              const element = result.selfemp[i];
                                              //console.log(element);
                                                       if(element.data[0].app_stage  === "1"){
                                                        this.elig_status ='Eligibility Clear';  
                                                        
                                                       
                                                        }else if(element.data[0].app_stage  === "0"){
                                                          this.elig_status ='Eligibility Pending';                                                        
                                                          this.appstage = this.elig_status;                                                   
                                                        
                                                        }

                                                        this.appstage = this.elig_status;




                                                  if(element.data[0].lender_name !="NA"){
                                                    if(element.data[0].lender_name=== "MeraEMI"){
                                                      this.lender_name = "Offline";
                                                    }else if(element.data[0].lender_name === "Lender Not Selected"){
                                                      this.lender_name = "NA";
                                                     }else{
                                                      this.lender_name = element.data[0].lender_name;
                                                    }
                                                  
                                                    
                                                   }
                                                   
                                                   
                                                   
                                                  
                                                  if(element.data[0].provider_id === "BOT MESSENGER"){
                                                    this.provider = 'BOT';
                                                    
                                                  }else{
                                                    this.provider = 'WEB';
                                                  }
                                    
                                                  
                                                
                                    
                                    
                                                  if(element.data[0].salaried==="2"){
                                                    this.empcategory="SEP";
                                    
                                                  }
                                                  if(element.data[0].feedback_stage !="na"){
                                                    this.meraemistage=element.data[0].feedback_stage;
                                                   }else{
                                                    this.meraemistage="Pending Review";
                                                   }
                                                   if(element.data[0].feedback_lender_name !="na"){
                                                    this.lendername=element.data[0].feedback_lender_name;
                                                   }else{
                                                    this.lendername=element.data[0].lender_name;
                                                   }
                                                  let name=  element.data[0].last_name +' ' +element.data[0].first_name
                                                   
                                                 // console.log(element.data[0].user_stage);
                                                //   console.log(this.lender_name);

                                             
                                                                 this.elements.push(
                                                                  {
                                                                    user_id: element.data[0].user_id,
                                                                    name:name,
                                                                    lendername: this.lendername, 
                                                                    loan_amount: element.data[0].loan_amount,
                                                                    meraemistage: element.data[0].feedback_stage,
                                                                    usersate: element.data[0].user_stage,
                                                                    date: element.data[0].login_date ,
                                                                    admin: element.data[0].admin_name ,
                                                                    crmdate: element.data[0].admincrmstagechangedate ,    
                                                                    
                                                                  }
                                                                  );
                                                                  this.maindataFilter.push(
                                                                    {
                                                                      user_id: element.data[0].user_id,
                                                                      name:name,
                                                                      lendername: this.lendername, 
                                                                      loan_amount: element.data[0].loan_amount,
                                                                      meraemistage: element.data[0].feedback_stage,
                                                                      usersate: element.data[0].user_stage,
                                                                      date: element.data[0].login_date ,
                                                                      admin: element.data[0].admin_name ,
                                                                      crmdate: element.data[0].admincrmstagechangedate , 
                                                                      
                                                                    }
                                                                    );

                                                                  
                                                
                                                                  
                                                                    
                                                }
                                              //console.log(this.elements);
                                               // this.mdbTable.setDataSource(this.elements);
                                                //this.elements = this.mdbTable.getDataSource();
                                                //this.previous = this.mdbTable.getDataSource();
                                                }else{
                                                  console.log('User list Api faile');
                                                }
                                          let dte = new Date(); 
                                        

                                          let privfrom =dte.setDate(dte.getDate() - 60);
                                          let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
                                          //back month date 
                                          this.tableDataToDate =this.currentdate;
                                          this.tableDataFromDate=privfromdate;

                                          //console.log("today  fromdate",this.tableDataFromDate)
                                          //console.log("today  todate",this.tableDataToDate)
                                          const data = this.elements;
              
                                          const  fromdate = this.tableDataFromDate;
                                          const  todate = this.tableDataToDate;
                                          this.result = data.filter(d => {
                                            const  reg_date= d.date;
                                            const regdate = reg_date.split(' ')[0];
                                            //return (fromdate  <= regdate && regdate  <=  todate );            
                                            return (regdate >= fromdate  && regdate  <=  todate );
                                            
                                          });
                                         // console.log("to",this.result);
                                          if(this.result.length > 0){
                                            this.tabledata=1;
                                            for (let i = 0; i < this.result.length; i++) {
                                              const element = this.result[i];
                                          
                                
                                              this.elementsdate.push(
                                                {
                                                  user_id: element.user_id, 
                                                  name: element.name,
                                                  lendername: element.lendername,
                                                  loan_amount: element.loan_amount,
                                                  meraemistage: element.meraemistage,
                                                  usersate: element.usersate,
                                                  date: element.date,
                                                  admin: element.admin ,
                                                  crmdate: element.crmdate ,
                                                
                                                }
                                                );
                                                this.totalusercount=this.elementsdate.length;
                                          //   console.log(this.elementsdate);
                                              this.mdbTable.setDataSource(this.elementsdate);
                                              this.elements = this.mdbTable.getDataSource();
                                              this.previous = this.mdbTable.getDataSource();
                                            }
                                          }else{
                                            this.datanotfound=1;
                                          }
                                         
                                              });

                                              this.facebookAdService
                                              .getfacebookAdDashboard()
                                              .subscribe( (result:any) => {
                                                if (result.status = true  && result.response=== 200) { 
                                                  console.log(result);
                                                  this.adDateFilter="last_30d"; 
                                                  for (let i = 0; i < result.fbad.length;  i++) {
                                                    const array = result.fbad[i];
                                                  
                                                    if(array.data[0].total_eligibility_pending !=null){
                                                      this.elig_p= array.data[0].total_eligibility_pending;
                                                    }else{
                                                      this.elig_p=0;

                                                    }
                                                    if(array.data[0].total_eligibility_clear !=null){
                                                      this.elig_c= array.data[0].total_eligibility_clear;
                                                    }else{
                                                      this.elig_c=0;

                                                    }
                                                    if(array.data[0].doc_rec !=null){
                                                      this.doc_r= array.data[0].doc_rec;
                                                    }else{
                                                      this.doc_r=0;

                                                    }
                                                    if(array.data[0].doc_pend !=null){
                                                      this.doc_p= array.data[0].doc_pend;
                                                    }else{
                                                      this.doc_p=0;

                                                    }
                                                    const    addcampainbudget= (array.data[0].fb_campain_budget/100);
                                                    const     costPerClick =   (array.data[0].fb_campain_budget/ array.data[0].fb_cost_per_result);
                                                    const    costPerPageView=   (array.data[0].fb_cost_per_result/ array.data[0].page_view);
                                                    const    CostPerEP= (array.data[0].fb_cost_per_result/this.elig_p);
                                                    const    CostEC= (array.data[0].fb_cost_per_result/this.elig_c);
                                                    this.elementsdatefacebookAdFilter.push(
                                                      {
                                                        fb_ads_account_id : array.data[0].fb_ads_account_id,
                                                        fb_adset_acc_id : array.data[0].fb_adset_acc_id, 
                                                        
                                                        fb_ads_account_name: array.data[0].fb_ads_account_name,
                                                         
                                                        fb_ad_set_id: array.data[0].fb_ad_set_id, 
                                                          
                                                         
                                                                
                                                         
                                                        
                                                        fb_reach : array.data[0].fb_reach, 
                                                        fb_status : array.data[0].fb_status, 
                                                        fb_impressions : array.data[0].fb_impressions, 
                                                        fb_cpm_cost : array.data[0].fb_cpm_cost,   
                                                        fb_linc_clicks : array.data[0].fb_linc_clicks, 
                                                        fb_campain_budget: addcampainbudget,
                                                        fb_cost_per_result: array.data[0].fb_cost_per_result,
                                                        day: array.data[0].day,
                                                        
                                                        view:  array.data[0].page_view,
                                                        costPerClicks:costPerClick,
                                                        costPerPageViews:costPerPageView,
                                                        eligp: this.elig_p,
                                                        eligc:this.elig_c,
                                                        costPer_ep:CostPerEP,
                                                        costPer_ec:CostEC,
                                                        docR:this.doc_r,
                                                        docP: this.doc_p,
                                                        
                                                        
                                                        
                                                      }
                                                      );
                                                   
                                                      const day=array.data[0].day;
                                                      const status=array.data[0].fb_status;
                                                      const notofday="last_30d";
                                                      const active="ACTIVE";
                                                      const pose="PAUSED";
                                                     // console.log(status== active); return false;
                                                        if(status==active && notofday== day){
                                                         // console.log(array.data[0].day);
                                                        //console.log(array.data[0].fb_status);
                                                          this.elementsdatefacebookAd.push(
                                                            {
                                                              fb_ads_account_id : array.data[0].fb_ads_account_id,
                                                              fb_adset_acc_id : array.data[0].fb_adset_acc_id,                                                               
                                                              fb_ads_account_name: array.data[0].fb_ads_account_name,                                                               
                                                              fb_ad_set_id: array.data[0].fb_ad_set_id,                                                              
                                                              fb_reach : array.data[0].fb_reach, 
                                                              fb_status : array.data[0].fb_status, 
                                                              fb_impressions : array.data[0].fb_impressions, 
                                                              fb_cpm_cost : array.data[0].fb_cpm_cost,   
                                                              fb_linc_clicks : array.data[0].fb_linc_clicks, 
                                                              fb_campain_budget: addcampainbudget,
                                                              fb_cost_per_result: array.data[0].fb_cost_per_result,
                                                              day: array.data[0].day,                                                              
                                                              view:  array.data[0].page_view,
                                                              costPerClicks:costPerClick,
                                                              costPerPageViews:costPerPageView,
                                                              eligp: this.elig_p,
                                                              eligc:this.elig_c,
                                                              costPer_ep:CostPerEP,
                                                              costPer_ec:CostEC,
                                                              docR:this.doc_r,
                                                              docP: this.doc_p,
                                                              
                                                              
                                                              
                                                            }
                                                            );
                                                         
                                                          }else if( status==pose &&  notofday== day){
                                                            this.elementsdatefacebookAdPAUSED.push(
                                                              {
                                                                fb_ads_account_id : array.data[0].fb_ads_account_id,
                                                                fb_adset_acc_id : array.data[0].fb_adset_acc_id,                                                                 
                                                                fb_ads_account_name: array.data[0].fb_ads_account_name,                                                                 
                                                                fb_ad_set_id: array.data[0].fb_ad_set_id, 
                                                                fb_reach : array.data[0].fb_reach, 
                                                                fb_status : array.data[0].fb_status, 
                                                                fb_impressions : array.data[0].fb_impressions, 
                                                                fb_cpm_cost : array.data[0].fb_cpm_cost,   
                                                                fb_linc_clicks : array.data[0].fb_linc_clicks, 
                                                                fb_campain_budget: addcampainbudget,
                                                                fb_cost_per_result: array.data[0].fb_cost_per_result,
                                                                day: array.data[0].day,                                                                
                                                                view:  array.data[0].page_view,
                                                                costPerClicks:costPerClick,
                                                                costPerPageViews:costPerPageView,
                                                                eligp: this.elig_p,
                                                                eligc:this.elig_c,
                                                                costPer_ep:CostPerEP,
                                                                costPer_ec:CostEC,
                                                                docR:this.doc_r,
                                                                docP: this.doc_p,
                                                                
                                                                
                                                              }
                                                              );
                                                          }
                                                         // console.log(this.elementsdatefacebookAd);
                                                         // console.log(this.elementsdatefacebookAdPAUSED);
                                                          
                                                          this.activedataTotal=this.elementsdatefacebookAd.length;

                                                  }

                                                }
                                                
                                                
                                              });























                                            // console.log(this.elementsdatefacebookAdFilter);
                                            //  ------------------------------------------------------------------------
                                                   this.SelfEmpLeadService
                                                    .getAllLeads()
                                                    .subscribe( (result:any) => {
                                                  // console.log(result);
                                                    
                                                      if (result.status = true  && result.response=== 200) {      //        
                                                        for (let i = 0; i < result.selfemp.length;  i++) {
                                                          const element = result.selfemp[i];
                                                        
                                                 
                                                                 
                                                            
                                                                  if(element.data[0].app_stage  === "0"){
                                                                    this.elig_status ='Eligibility Pending';
                                                                    
                                                                  
                                                                  }else if(element.data[0].app_stage  === "1"){
                                                                    if(element.data[0].elig_status =="1" || element.data[0].elig_status =="na"){
                                                                      this.elig_status ='Eligibility Clear';
                                                                     
                                                                    }else if(element.data[0].elig_status =="0"){
                                                                      this.elig_status ='Eligibility Fail';
                                                                     
                                                                    }
                                                                    this.appstage = this.elig_status;
                                                                  
                                                                  }else if(element.data[0].app_stage==="2"){
                                                                
                                                                    this.appstage ="IAP Pending";
                                                                    
                                                              
                                                                }else if(element.data[0].app_stage==="3"){
                                                                  this.appstage ="IAP Complete";
                                                                 
                                                                  
                                                                }else if(element.data[0].app_stage === "4"){
                                                                  this.appstage = 'Document Upload';
                                                                 
                                                                }else if(element.data[0].app_stage ==="5"){
                                                                  this.appstage = 'Meeting Scheduler';
                                                                  
                                                                }else if(element.data[0].app_stage === "6"){
                                                                  this.appstage = 'Success';
                                                                  
                                                                }
                                                                if(element.data[0].feedback_stage !="na"){
                                                                  this.meraemistage=element.data[0].feedback_stage;
                                                                 }else{
                                                                  this.meraemistage="Pending Review";
                                                                 }
                                                              let crmstageid=element.data[0].stage_id;
                                                              
                                                                this.sixMonthArrayData.push(
                                                                  {
                                                                    user_id: element.data[0].user_id, 
                                                                   
                                                                    stage_id: element.data[0].stage_id, 
                                                                    status: this.appstage,    
                                                                    feedback_stage:this.meraemistage,
                                                                    reg_date: element.data[0].date,
                                                                   
                                                                    
                                                                  }
                                                                  );
                                                            
                                                                            
                                                                             
                                                                                
                                                            }
                                                        
                                                           
                                                            }else{
                                                              console.log('User list Api faile');
                                                            }
                                                            
                                                         
                                                            const data = this.sixMonthArrayData;
                                            //**********************************************************start 2nd day  ************************************************************ */
                                                         // console.log(this.sixMonthArrayData);
                                                          const d = new Date();
                                                          var fir =new Date();
                                                        
                                                            var currentMonth = new Date(fir.getFullYear(), fir.getMonth(), 1); 
                                                            let firstdate=currentMonth.toLocaleDateString();
                                                            let convstrtdate = moment(firstdate, 'M/D/YYYY');  
                                                            this.currentdateStart = convstrtdate.format('YYYY-MM-DD');
                                                           // console.log(this.currentdateStart);
                                                    
                                                            let firstdateend=d.toLocaleDateString();
                                                            let convstrtdateend = moment(firstdateend, 'M/D/YYYY');  
                                                            this.currentdateEnd = convstrtdateend.format('YYYY-MM-DD');
                                                          //  console.log(this.currentdateEnd);
                                                            const  fromdateFirst = this.currentdateStart;
                                                            const  todateFirst = this.currentdateEnd;
                                                           
                                                            this.resultFrstArray = data.filter(
                                                              m => new Date(m.reg_date) >= new Date(fromdateFirst) && new Date(m.reg_date) <= new Date(todateFirst)
                                                              );
                                                           
                                                           
                                                           // console.log(this.resultFrstArray);
                                                            if(this.resultFrstArray.length > 0){
                                                              this.totalEligFisrt=this.resultFrstArray.length;
                                                              
                                                            }else{
                                                              this.totalEligFisrt=0;
                                                            }
                                                    
//**********************************************************start 2nd day  ************************************************************ */
                                                            const months = d.getMonth();

                                                            d.setMonth(d.getMonth() - 1);
                                                            while (d.getMonth() === months) {
                                                                d.setDate(d.getDate() -1);
                                                            }
                                                            var sendstart=new Date(d.toLocaleDateString());
                                                            var secondtDay = new Date(sendstart.getFullYear(), sendstart.getMonth(), 1);
                                                            let convsecondstartdate = moment(secondtDay, 'M/D/YYYY');  
                                                            this.secStartDate = convsecondstartdate.format('YYYY-MM-DD');
                                                         //   console.log(this.secStartDate);
                                                          //  this.secStartDate=firstDay.toLocaleDateString();
                                                            var secend=new Date(d.toLocaleDateString());
                                                            var monthEndDay = new Date(secend.getFullYear(), secend.getMonth() + 1, 0);
                                                            // this.secEndDate=monthEndDay.toLocaleDateString();
                                                            
                                                            let secendend=monthEndDay.toLocaleDateString();
                                                            let convSecEndDate = moment(secendend, 'M/D/YYYY');  
                                                            this.secEndDate = convSecEndDate.format('YYYY-MM-DD');

                                                            const  fromdateSec= this.secStartDate;
                                                            const  todateSec = this.secEndDate;
                                                           
                                                            this.resultSecArray = data.filter(
                                                              m => new Date(m.reg_date) >= new Date(fromdateSec) && new Date(m.reg_date) <= new Date(todateSec)
                                                              );
                                                           
                                                           
                                                           
                                                           // console.log(this.resultFrstArray);
                                                            if(this.resultSecArray.length > 0){
                                                              this.totalEligSec=this.resultSecArray.length;
                                                             
                                                            }else{
                                                              this.totalEligSec=0;
                                                            }
                                                            
                                                          //  console.log(this.secEndDate);
  //**********************************************************start 2nd day  End  ************************************************************ */
//**********************************************************start 3nd day    ************************************************************ */

                                                              const c = new Date();
                                                              c.setMonth(c.getMonth() - 2);
                                                              while (c.getMonth() === months) {
                                                                  c.setDate(c.getDate() -2);
                                                              }
                                                              //  console.log(c.toLocaleDateString());

                                                              // this.thirdStartDate=c.toLocaleDateString();

                                                              var thirdStart=new Date(c.toLocaleDateString());
                                                              var thirdDay = new Date(thirdStart.getFullYear(), thirdStart.getMonth(), 1);
                                                              let convthirdDaystartdate = moment(thirdDay, 'M/D/YYYY');  
                                                              this.thirdStartDate = convthirdDaystartdate.format('YYYY-MM-DD');

                                                             // console.log(this.thirdStartDate);


                                                              

                                                              var third=new Date(c.toLocaleDateString());
                                                              var thirdMonthEndDay = new Date(third.getFullYear(), third.getMonth() + 1, 0);

                                                              let thirdend=thirdMonthEndDay.toLocaleDateString();
                                                              let convThirdEndDate = moment(thirdend, 'M/D/YYYY');  
                                                              this.thirdEndDate = convThirdEndDate.format('YYYY-MM-DD'); 
                                                             // console.log(this.thirdEndDate);

                                                             const  fromdateThir= this.thirdStartDate;
                                                             const  todateThir = this.thirdEndDate;
                                                            this.resultThirArray = data.filter(
                                                              m => new Date(m.reg_date) >= new Date(fromdateThir) && new Date(m.reg_date) <= new Date(todateThir)
                                                              );
                                                            
                                                           // console.log(this.resultFrstArray);
                                                            if(this.resultThirArray.length > 0){
                                                              this.totalEligThir=this.resultThirArray.length;
                                                              
                                                            }else{
                                                              this.totalEligThir=0;
                                                            }
                                                          
//**********************************************************start 3nd day  End  ************************************************************ */

//**********************************************************start 4nd day    ************************************************************ */


                                                            const E = new Date();
                                                            E.setMonth(E.getMonth() - 3);
                                                            while (E.getMonth() === months) {
                                                              E.setDate(E.getDate() -3);
                                                            }
                                                            // console.log(E.toLocaleDateString());
                                                            //this.forthStartDate=E.toLocaleDateString();

                                                            var ForthStart=new Date(E.toLocaleDateString());
                                                            var ForthDay = new Date(ForthStart.getFullYear(), ForthStart.getMonth(), 1);
                                                            let convForthDaystartdate = moment(ForthDay, 'M/D/YYYY');  
                                                            this.forthStartDate = convForthDaystartdate.format('YYYY-MM-DD');

                                                            //console.log(this.forthStartDate);
                                                            var forth=new Date(E.toLocaleDateString());
                                                            var forthMonthEndDay = new Date(forth.getFullYear(), forth.getMonth() + 1, 0);



                                                            let Forthend=forthMonthEndDay.toLocaleDateString();
                                                            let convForthEndDate = moment(Forthend, 'M/D/YYYY');  
                                                            this.forthEndDate = convForthEndDate.format('YYYY-MM-DD');  
                                                           
                                                           
                                                            const  fromdateForth= this.forthStartDate;
                                                            const  todateForth = this.forthEndDate;
                                                            this.resultForthArray = data.filter(
                                                              m => new Date(m.reg_date) >= new Date(fromdateForth) && new Date(m.reg_date) <= new Date(todateForth)
                                                              );
                                                            
                                                           
                                                            
                                                           // console.log(this.resultFrstArray);
                                                            if(this.resultForthArray.length > 0){
                                                              this.totalEligForth=this.resultForthArray.length;
                                                              
                                                            }else{
                                                              this.totalEligForth=0;
                                                            }
                                                           
                                                           // console.log(this.forthStartDate);
  //**********************************************************start 4nd day  End  ************************************************************ */

//**********************************************************start 5nd day    ************************************************************ */
                                                                  const F = new Date();
                                                                  F.setMonth(F.getMonth() - 4);
                                                                  while (F.getMonth() === months) {
                                                                    F.setDate(F.getDate() -4);
                                                                  }
                                                                  //   console.log(F.toLocaleDateString());
                                                                  //this.fiveStartDate=F.toLocaleDateString();

                                                                  var FiftStart=new Date(F.toLocaleDateString());
                                                                  var fiftdDay = new Date(FiftStart.getFullYear(), FiftStart.getMonth(), 1);
                                                                  let convFiftDaystartdate = moment(fiftdDay, 'M/D/YYYY');  
                                                                  this.fiveStartDate = convFiftDaystartdate.format('YYYY-MM-DD');
                                                                 // console.log(this.fiveStartDate);


                                                                  var five=new Date(F.toLocaleDateString());
                                                                  var fiveMonthEndDay = new Date(five.getFullYear(), five.getMonth() + 1, 0);
                                                                  //this.fiveEndDate=fiveMonthEndDay.toLocaleDateString();

                                                                  let Fiftend=fiveMonthEndDay.toLocaleDateString();
                                                                  let convFifthEndDate = moment(Fiftend, 'M/D/YYYY');  
                                                                  this.fiveEndDate = convFifthEndDate.format('YYYY-MM-DD'); 
                                                                  const  fromdateFive= this.fiveStartDate;
                                                                  const  todateFive = this.fiveEndDate;
                                                                  
                                                                  this.resultFiveArray = data.filter(
                                                                    m => new Date(m.reg_date) >= new Date(fromdateFive) && new Date(m.reg_date) <= new Date(todateFive)
                                                                    );
                                                                  
                                                                  // console.log(this.resultFrstArray);
                                                                  if(this.resultFiveArray.length > 0){
                                                                    this.totalEligFive=this.resultFiveArray.length;
                                                                   
                                                                  }else{
                                                                    this.totalEligFive=0;
                                                                  }

                                                                 // console.log(this.fiveEndDate);
 //**********************************************************start 5nd day  End  ************************************************************ */

//**********************************************************start 6th day    ************************************************************ */
                                                                const h = new Date();
                                                                h.setMonth(h.getMonth() - 5);
                                                                while (h.getMonth() === months) {
                                                                  h.setDate(h.getDate() -5);
                                                                }
                                                            
                                                               

                                                                var sixStart=new Date(h.toLocaleDateString());
                                                                var sixdDay = new Date(sixStart.getFullYear(), sixStart.getMonth(), 1);
                                                                let convSixDaystartdate = moment(sixdDay, 'M/D/YYYY');  
                                                                this.SixtartDate = convSixDaystartdate.format('YYYY-MM-DD');

                                                               // console.log(this.SixtartDate);

                                                                var Six=new Date(h.toLocaleDateString());
                                                                var SixMonthEndDay = new Date(Six.getFullYear(), Six.getMonth() + 1, 0);

                                                                let Sixend=SixMonthEndDay.toLocaleDateString();
                                                                let convSixEndDate = moment(Sixend, 'M/D/YYYY');  
                                                                this.SixEndDate = convSixEndDate.format('YYYY-MM-DD'); 
                                                               // console.log(this.SixEndDate); 
                                                               const  fromdateSix= this.SixtartDate;
                                                                const  todateSix = this.SixEndDate;
                                                                
                                                                this.resultSixArray = data.filter(
                                                                  m => new Date(m.reg_date) >= new Date(fromdateSix) && new Date(m.reg_date) <= new Date(todateSix)
                                                                  );
                                                             
                                                                // console.log(this.resultFrstArray);
                                                                if(this.resultSixArray.length > 0){
                                                                  this.totalEligSix=this.resultSixArray.length;
                                                                  
                                                                }else{
                                                                  this.totalEligSix=0;
                                                                }

                                                                
                                                                  this.facebookAdService
                                                                  .getSelfEmpGraphDataDocRecv()
                                                                  .subscribe( (result:any) => {
                                                                    if (result.status = true  && result.response=== 200) {             
                                                                      for (let i = 0; i < result.docRecv.length;  i++) {
                                                                        const eleDocRecv = result.docRecv[i];
                                                                        if(eleDocRecv.data[0].app_stage  === "1"){
                                                                          this.elig_status ='Eligibility Clear';  
                                                                          
                                                                         
                                                                          }else if(eleDocRecv.data[0].app_stage  === "0"){
                                                                            this.elig_status ='Eligibility Pending';                                                        
                                                                                                                            
                                                                          
                                                                          }
                                                          
                                                                         let appstage = this.elig_status;
                                                          
                                                          
                                                                        this.arrayDocReciv.push(
                                                                          {
                                                                            user_id: eleDocRecv.data[0].user_id, 
                                                                           
                                                                            stage_id: eleDocRecv.data[0].stage_id, 
                                                                               
                                                                            feedback_stage:eleDocRecv.data[0].feedback_stage    ,
                                                                            reg_date: eleDocRecv.data[0].login_date,
                                                                            appstage: appstage,
                                                                            
                                                                          }
                                                                          );
                                                                          
                                                                      }
                                                                    }
                                                                    const dataDocReciv = this.arrayDocReciv;
                                                                   // console.log(dataDocReciv);
                                                                   //**********************************************************1st doc reciv    ************************************************************ */
                                                                    const  fromdateFirst = this.currentdateStart;
                                                                    const  todateFirst = this.currentdateEnd;
                                                                    //console.log(fromdateFirst,"fromdateFirst");
                                                                   // console.log(todateFirst,"todateFirst");
                                                                    this.resultDocRecivFirst = dataDocReciv.filter(d => {
                                                                      const  reg_date= d.reg_date;
                                                                      const regdate = reg_date.split(' ')[0];
                                                                      //return (fromdate  <= regdate && regdate  <=  todate );            
                                                                      return (regdate >= fromdateFirst  && regdate  <=  todateFirst );
                                                                      
                                                                    });
                                                                   // console.log(this.resultDocRecivFirst);
                                                                    if(this.resultDocRecivFirst.length > 0){
                                                                      
                                                                      for (let i = 0; i < this.resultDocRecivFirst.length; i++) {
                                                                      
                                                                        
                                                                       
                                                                           this.totalDocReciv++;
                                                                        
                                                                      }
                                                                    }
                                                                //**********************************************************2st doc reciv    ************************************************************ */
                                                                const  fromdateSec= this.secStartDate;
                                                                const  todateSec = this.secEndDate;
                                                                // console.log(fromdateSec,"fromdateSec");
                                                                // console.log(todateSec,"todateSec");
                                                                this.resultDocRecivSec = dataDocReciv.filter(
                                                                  m => new Date(m.reg_date) >= new Date(fromdateSec) && new Date(m.reg_date) <= new Date(todateSec)
                                                                  );
                                                               
                                                               // console.log(this.resultDocRecivSec);
                                                                if(this.resultDocRecivSec.length > 0){                                                                 
                                                                  for (let i = 0; i < this.resultDocRecivSec.length; i++) {                                                                                                                                  
                                                                  
                                                                   
                                                                       this.totalDocSec++;
                                                                    
                                                                  }
                                                                }  
                                                              
                                                              //**********************************************************3st doc reciv    ************************************************************ */
                                                              const  fromdateThir= this.thirdStartDate;
                                                              const  todateThir = this.thirdEndDate;
                                                              //console.log(fromdateThir,"fromdateThir");
                                                              //console.log(todateThir,"todateThir");

                                                              
                                                              this.resultDocRecivThird = dataDocReciv.filter(
                                                              m => new Date(m.reg_date) >= new Date(fromdateThir) && new Date(m.reg_date) <= new Date(todateThir)
                                                              );
                                                             // console.log(selectedMembers,"selectedMembers");
                                                             
                                                              
                                                              if( this.resultDocRecivThird.length > 0){                                                                 
                                                                for (let i = 0; i <  this.resultDocRecivThird.length; i++) {                                                                                                                                  
                                                                  this.totalDocThir++;
                                                                     
                                                                  
                                                                }
                                                                //console.log(this.totalDocThir);
                                                              }      
                                                               //**********************************************************4th doc reciv    ************************************************************ */
                                                               const  fromdateForth= this.forthStartDate;
                                                               const  todateForth = this.forthEndDate;

                                                               this.resultDocRecivForth = dataDocReciv.filter(
                                                                m => new Date(m.reg_date) >= new Date(fromdateForth) && new Date(m.reg_date) <= new Date(todateForth)
                                                                );
                                                              
                                                              // console.log(this.resultFrstArray);
                                                               if(this.resultDocRecivForth.length > 0){                                                                 
                                                                 for (let i = 0; i < this.resultDocRecivForth.length; i++) {                                                                                                                                  
                                                                 
                                                                  
                                                                      this.totalDocForth++;
                                                                   
                                                                 }
                                                               }  
                                                                //**********************************************************5th doc reciv    ************************************************************ */
                                                                const  fromdateFive= this.fiveStartDate;
                                                                const  todateFive = this.fiveEndDate;
                                                                this.resultDocRecivFive = dataDocReciv.filter(
                                                                  m => new Date(m.reg_date) >= new Date(fromdateFive) && new Date(m.reg_date) <= new Date(todateFive)
                                                                  );
                                                                
                                                                
                                                              
                                                               // console.log(this.resultFrstArray);
                                                                if(this.resultDocRecivFive.length > 0){                                                                 
                                                                  for (let i = 0; i < this.resultDocRecivFive.length; i++) {                                                                                                                                  
                                                                  
                                                                   
                                                                       this.totalDocFive++;
                                                                    
                                                                  }
                                                                }    
                                                                //**********************************************************6th doc reciv    ************************************************************ */
                                                                const  fromdateSix= this.SixtartDate;
                                                                const  todateSix = this.SixEndDate;
                                                                
                                                                this.resultDocRecivSix = dataDocReciv.filter(
                                                                  m => new Date(m.reg_date) >= new Date(fromdateSix) && new Date(m.reg_date) <= new Date(todateSix)
                                                                  );
                                                                
                                                                
                                                               
                                                               // console.log(this.resultFrstArray);
                                                                if(this.resultDocRecivSix.length > 0){                                                                 
                                                                  for (let i = 0; i < this.resultDocRecivSix.length; i++) {                                                                                                                                  
                                                                  
                                                                   
                                                                       this.totalDocSix++;
                                                                    
                                                                  }
                                                                }                                                       
                                                                // ------------------------------  Graph APi-----------------------------------------------
                                                               let firstDates=convstrtdateend.format('MMMM  y');
                                                               let secDates=convSecEndDate.format('MMMM  y');
                                                               let thirdDates=convThirdEndDate.format('MMMM  y');
                                                               let forthDates=convForthDaystartdate.format('MMMM  y');
                                                               let fiveDates=convFiftDaystartdate.format('MMMM  y');
                                                               let sixDates=convSixEndDate.format('MMMM  y');
                                                               
                                                                                                                             
                                                               let chart = new CanvasJS.Chart("filedata", {
                                                                  animationEnabled: true,
                                                                 
                                                                  axisX: {
                                                                  labelAngle: 180
                                                                  },
                                                                 
                                                                  axisY2: {
                                                                  title: "No. of Leads"
                                                                  },
                                                                  toolTip: {
                                                                  shared: true
                                                                  },
                                                                  legend:{
                                                                  cursor:"pointer",
                                                                  itemclick: function(e: any){
                                                                    if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                                                                    e.dataSeries.visible = false;
                                                                    }
                                                                    else {
                                                                    e.dataSeries.visible = true;
                                                                    }
                                                                    e.chart.render();
                                                                  }
                                                                  },
                                                                  data: [{
                                                                    type: "column",	
                                                             
                                                                    name: "Eligibility Clear",
                                                                  
                                                                     axisYType: "secondary",
                                                                    showInLegend: true, 
                                                                    dataPoints:[
                                                                      {label: firstDates,  y: this.totalEligFisrt},
                                                                      {label: secDates,    y: this.totalEligSec},
                                                                      {label: thirdDates,  y:  this.totalEligThir},
                                                                      {label: forthDates,  y: this.totalEligForth},
                                                                      {label: fiveDates,   y: this.totalEligFive},
                                                                      {label:  sixDates,    y:  this.totalEligSix}
                                                                  
                                                                  ]
                                                                }
                                                                ,{
                                                                    type: "column",	
                                                                 
                                                                  name: "Doc Rec",
                                                                  axisYType: "secondary",
                                                                  showInLegend: true,
                                                                  dataPoints:[
                                                                    {label: firstDates, y: this.totalDocReciv},
                                                                    {label: secDates,   y: this.totalDocSec},
                                                                    {label: thirdDates, y: this.totalDocThir},
                                                                    {label: forthDates, y: this.totalDocForth},
                                                                    {label: fiveDates,  y: this.totalDocFive},
                                                                    {label:  sixDates,  y:  this.totalDocSix}
                                                                  
                                                                  ]
                                                                
                                                                  }
                                                                
                                                                
                                                                ]
                                                                });
                                                                  
                                                                chart.render();
                                                                  });
       
                                                         });
    }
  

	chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }
 
    get fg() { return (<FormGroup>this.registerForm.get('maindatafilter')).controls; }

    get maindatafilter(){
    return this.registerForm.get('maindatafilter');
      }
	
	
	DashbordReset(user_id){
		this.filterchart.reset();
		this.main_array=0;
		window.location.reload();
	}
	backmenu(){
		this.showLoader = false;
		setTimeout(()=>{    
		  //this.showLoader = true;
		  this.router.navigate(['/dashboard']);
		}, 2000);
	   
		
		}
		rotate(event){
			event.srcElement.classList.remove("rotate");
			event.srcElement.classList.remove("tooltiptext");
			setTimeout(()=>{
			  event.srcElement.classList.add("rotate");
			  event.srcElement.classList.remove("tooltiptext");
			location.reload();
			},0)
		  }
     

      getdateValue(){
        this.daternagediv=0;
        this.activedate=0;
        
       }
    
     onClick(check){
      //console.log(check);
      if(check==1){
        this.tab = 'tab1';
        this.tabval=1;
      }else if(check==2){
        this.tab = 'tab2';
        this.tabval=2;
       // console.log(this.cust);
      }   
    
     }

     getValue(event) {
      this.cust= {} ;
      this.mainaaary=[];
      this.mainaaaryInactive=[];
      this.activeDocPending=[];
      this.activeDocReceived=[];
      this.activeTransfer=[];
      this.activeLenderProcess=[];
      this.activeDisbursed=[];
      this.activePendingReview=[];
      this.activeCallPending=[];
      this.activeCallComplete=[];
      this.activeDocComplete=[];
      this.activeDocIncomplete=[];
      this.activeOffline=[];
      this.activeLoanTap=[];
      this.activeFullerton=[];
      this.activeIdfcFirstBank=[];
      this.activeABCLBank=[];
      this.activeTataCapital=[];
      this.activeKotak=[];
      this.activeHDB=[];
      this.activeOfflineTotal=0
      //Loan Tap
      this.activeLoanTapTotal=0
      // FULLERTON INDIA
      this.activeFullertonTotal=0
      // IDFC First Bank
      this.activeIdfcFirstBankTotal=0
      //Aditya Birla Capital Ltd
      this.activeABCLBankTotal=0
      //Tata Capital Limited
      this.activeTataCapitalTotal=0
      //'Kotak Mahindra Bank Ltd'
      this.activeKotakTotal=0
      //HDB Financial Services Ltd
      this.activeHDBTotal=0
      // console.log(this.activeKotakTotal);
      //Doc Pending
      this.activeDocPendingTotal=0;
      //Doc Received
      this.activeDocReceivedTotal=0;
      //Transfer
      this.activeTransferTotal=0
      //Lender Process
      this.activeLenderProcessTotal=0
      //Disbursed
      this.activeDisbursedTotal=0
      //Pending Review
      this.activePendingReviewTotal=0
      //Call Pending
      this.activeCallPendingTotal=0
      //Call Complete                    
      this.activeCallCompleteTotal=0
      //Doc Complete                  
      this.activeDocCompleteTotal=0
      //Doc Incomplete
      this.activeDocIncompleteTotal=0
      //  console.log(this.activeDocIncompleteTotal);
      //totalLonMtAciveCurrentMonth
      this.loanAmtActive =0
      
      
      
      //last month
      this.lastmonthLoanAmtActive=0;
      this.activeDocPendingLastMonth=[];
      this.activeDocReceivedLastMonth=[];
      this.activeTransferLastMonth=[];
      this.activeLenderProcessLastMonth=[];
      this.activeDisbursedLastMonth=[];
      this.activePendingReviewLastMonth=[];
      this.activeCallPendingLastMonth=[];
      this.activeCallCompleteLastMonth=[];
      this.activeDocCompleteLastMonth=[];
      this.activeDocIncompleteLastMonth=[];
      this.activeLenderLastmonthTotal=0;
      this.activeOfflineLastMonth=[];
      this.activeLoanTapLastMonth=[];
      this.activeFullertonLastMonth=[];
      this.activeIdfcFirstBankLastMonth=[];
      this.activeABCLBankLastMonth=[];
      this.activeTataCapitalLastMonth=[];
      this.activeKotakLastMonth=[];
      this.activeHDBLastMonth=[];
      
          //offline
          this.activeOfflineLastMonthTotal=0;
          //Loan Tap
          this.activeLoanTapLastMonthTotal=0;
        // FULLERTON INDIA
        this.activeFullertonLastMonthTotal=0;
        // IDFC First Bank
        this.activeIdfcFirstBankLastMonthTotal=0;
          //Aditya Birla Capital Ltd
        this.activeABCLBankLastMonthTotal=0;
        //Tata Capital Limited
        this.activeTataCapitalLastMonthTotal=0;
          //'Kotak Mahindra Bank Ltd'
        this.activeKotakLastMonthTotal=0;
          //HDB Financial Services Ltd
          this.activeHDBLastMonthTotal=0;
        // console.log(this.activeKotakLastMonthTotal);
        //Doc Pending
        this.activeDocPendingLastMonthTotal=0;
        //Doc Received
        this.activeDocReceivedLastMonthTotal=0;
        //Transfer
        this.activeTransferLastMonthTotal=0;
        //Lender Process
        this.activeLenderProcessLastMonthTotal=0;
        //Disbursed
        this.activeDisbursedLastMonthTotal=0;
      
        //Pending Review
        this.activePendingReviewLastMonthTotal=0;
        //Call Pending
        this.activeCallPendingLastMonthTotal=0;
        //Call Complete                    
        this.activeCallCompleteLastMonthTotal=0;
        //Doc Complete                  
        this.activeDocCompleteLastMonthTotal=0;
        //Doc Incomplete
        this.activeDocIncompleteLastMonthTotal=0;
        //lastmonthLoanAmtActive
        this.LastMonthloanAmtActive =0;
        this.applicationActiveDiv=0;
        this.docPendingDiv=0;
       this.totoalApplicationActivePercentage=0;
       this.loanAmtDiv=0;
       this.loanAmtPercentage=0;
       this.docCompletDiv=0;
       this.totoaldocCompletePercentage=0;
       this.docReceivedDiv=0;
       this.totoaldocReceivedPercentage=0;
       this.docIncompleteDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.docPendingDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.PendingReviewDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.transferDiv=0;
       this.totoaltransferPercentage=0;
       this.lenderProcessDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.disbursedDiv=0;
       this.totoaldisbursedPercentage=0;
      
      
       this.InactiveCustCancelLastMonth=[];
       this.inactiveCRMCancelLastMonth=[];
       this.inactiveCRMDeclineLastMonth=[];
       this.activeDocPendingGrap=[];
       this.activeTransferGraph=[];
       this.activeLenderProcessGraph=[];
       this.activeDisbursedGraph=[];
       this.activePendingReviewGraph=[];
       this.activeCallPendingGraph=[];
       this.activeCallCompleteGraph=[];
       this.activeDocCompleteGraph=[];
       this.activeDocIncompleteGraph=[];
        this.daternagediv=0;
        this.activedate=0;
      this.daternagediv=0;
      this.inactiveCRMDeclineLastMonthTotal=0;
      this.InactiveCustCancelLastMonthTotal=0;
     //  alert(event.value);
      let dte = new Date();
      //current day
      if(event.value ==='0'){
        this.activedate=1;
        this.daternagediv=0;
        
        let fromdate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
  
       // console.log("today  fromdate",fromdate)
       // console.log("today  todate",todate)
        this.form=(fromdate);
        this.to=(todate);
        console.log("today  fromdate",this.form)
       console.log("today  todate",this.to)
        
        //Previous Day
        var previousDate = new Date()
        let previous =previousDate.setDate(previousDate.getDate() - 1)
        let previousdayformate =formatDate(previous, 'yyyy-MM-dd', 'en-US');
        this.previousdayFrom=previousdayformate;
        this.previousdayTo=previousdayformate;
     
      }else  if(event.value === '1'){
        this.daternagediv=0;
        this.activedate=1;
       let dayval =event.value;
       let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let fromdatechnage =dte.setDate(dte.getDate() - dayval);
        let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
        console.log("Yesterday  fromdate",fromdate)
        console.log("Yesterday  todate",todate)
        this.form=(fromdate);
        this.to=(todate);
        let dtes = new Date();
       let privfrom =dte.setDate(dtes.getDate() - 2);
       let privto =dte.setDate(dtes.getDate() - 1);
  
        let previousdayformatefrom =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
        let previousdayformateto =formatDate(privto, 'yyyy-MM-dd', 'en-US');
       this.previousdayFrom=previousdayformatefrom;
       this.previousdayTo=previousdayformateto;
        //console.log(previousdayformateto);
        
  // Prints yesterday's date in date format
        //Previous 7 Day
      }else  if(event.value === '7'){
        this.daternagediv=0;
        this.activedate=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.form=(fromdate);
         this.to=(todate);
        // console.log("Last 7 days  fromdate",fromdate)
        // console.log("Last 7 days  todate",todate)
         let privfrom =dte.setDate(dte.getDate() - 8);
         let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
         let privto =dte.setDate(dte.getDate() - 14);
         let privtoDate =formatDate(privto, 'yyyy-MM-dd', 'en-US');
        //back month date 
         this.previousdayFrom=privfromdate;
       this.previousdayTo=privtoDate;
       //Previous 30 Day
      }else  if(event.value === '30'){
        this.daternagediv=0;
        this.activedate=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.form=(fromdate);
         this.to=(todate);
       //  console.log("Last 30 days  fromdate",fromdate)
      //   console.log("Last 30 days  todate",todate)
         let privfrom =dte.setDate(dte.getDate() - 31);
         let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
         let privto =dte.setDate(dte.getDate() - 60);
         let privtoDate =formatDate(privto, 'yyyy-MM-dd', 'en-US');
        //back month date 
        this.previousdayFrom=privfromdate;
       this.previousdayTo=privtoDate;
      //this month record
      }else if(event.value === 'date'){
        let dayval =event.value;
        this.daternagediv=1;
        this.activedate=0;
        console.log(dayval);
       //Previous month all data
      }else if(event.value === 'thismonth'){
        this.daternagediv=0;
        this.activedate=1;
        let date = new Date();
        //month first date   
        var ourDate = new Date();
        let firstDays = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // adjust 0 before single digit date
        let datesFirstDay = ("0" + firstDays.getDate()).slice(-2);
        // current month
        let monthFirstDay = ("0" + (firstDays.getMonth() + 1)).slice(-2);
        // current year
        let yearFirstDay = firstDays.getFullYear();
        this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
        
  
  
  
  
          //month current day
        
          
          let dates = ("0" + date.getDate()).slice(-2);
          // current month
          let month = ("0" + (date.getMonth() + 1)).slice(-2);
          // current year
          let year = date.getFullYear();
          this.currentdate =(year + "-" + month + "-" + dates);
     
  
        
         this.form=this.FirstDaydate;
         this.to=this.currentdate;
                
         let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
         let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
         // current month
         let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
         // current year
         let lstmontYear = lstmontfirstday.getFullYear();
         this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
       //  console.log('Previous  Month Start Date' +this.lastmontStartDate);
  
         let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
         let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
         // current month
         let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
         // current year
         let lstmontEndYear = lstmontLasttday.getFullYear();
       //  this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
         this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + dates);
         
        // console.log('Previous  Month End Date' +this.lastmontEndDate);
             //back month date 
         this.previousdayFrom=this.lastmontStartDate;
         this.previousdayTo= this.lastmontEndDate;
  
        
      }else if(event.value === 'lastmonth'){
        this.daternagediv=0;
        this.activedate=1;
      // alert('ok');
        let date = new Date();
        let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
        // current month
        let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontYear = lstmontfirstday.getFullYear();
        this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
       
  
        let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
        let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
        // current month
        let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontEndYear = lstmontLasttday.getFullYear();
        this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
       
     
  
        
         this.form=this.lastmontStartDate;
         this.to=this.lastmontEndDate;
  
  
         let previousdayFrom=new Date(date.getFullYear(), date.getMonth() - 2, 1);
         let privfromdate =formatDate(previousdayFrom, 'yyyy-MM-dd', 'en-US');
         let previousdayto=new Date(date.getFullYear(), date.getMonth() - 1, 0);
         let privtoDate =formatDate(previousdayto, 'yyyy-MM-dd', 'en-US');
         //back month date 
         this.previousdayFrom=privfromdate;
         this.previousdayTo=privtoDate;
  
      }
      this.FirstDaydate=this.form;
      this.currentdate=this.to;
    //  this.form=thiscurrentdate.FirstDaydate
    //  this.to=this.currentdate;
      
     // console.log("today  fromdate",this.FirstDaydate)
     // console.log("today  todate",this.currentdate)
        //back month date 
        
     // console.log("Yesterday  fromdate ",this.previousdayFrom)
      //console.log("Yesterday  todate",this.previousdayTo)
         
  
   
                     // Active stage                     
                     let detail = { results: [{ 
                      "fromdate":this.FirstDaydate,            
                      "todate":this.currentdate,          
                      
                      }] };
                      detail['results']; 
                      this.totalLonMtAciveCurrentMonth=0;
                     this.DashboardService.getActiveApplications(detail).subscribe((result: any) => {
                      
                      if(result.status===true &&  result.response=== 200){
                      //console.log(result.selfemp);
                          this.totalApplicationActiveCurrentMonth=result.selfemp.length;
                        
                          for (let i = 0; i < result.selfemp.length; i++) {
                            const startMonthmenuarray = result.selfemp[i]; 
                          //  console.log(startMonthmenuarray.data[0].feedback_stage_id);          
                            this.totalLonMtAciveCurrentMonth += Number(startMonthmenuarray.data[0].loan_amount);	
                        
                            if(startMonthmenuarray.data[0].feedback_stage_id =="3"){
                              this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});      
                            }else  if(startMonthmenuarray.data[0].feedback_stage_id =="20" ){
                              this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});             
                            }
                            //Doc Received
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='21'){
                              this.activeDocReceived.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Transfer
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='18'){
                              this.activeTransfer.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            
                            //Lender Process
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='17'){
                              this.activeLenderProcess.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Disbursed
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='19'){
                              this.activeDisbursed.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Pending Review
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='13'){
                              this.activePendingReview.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Call Pending
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='22'){
                              this.activeCallPending.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Call Complete                    
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='23'){
                              this.activeCallComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Doc Complete                  
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='25'){
                              this.activeDocComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }
                            //Doc Incomplete
                            if(startMonthmenuarray.data[0].feedback_stage_id ==='26'){
                              this.activeDocIncomplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                              
                            }


                                //tranfer to lender list
                                this.uid= parseInt(startMonthmenuarray.data[0].user_id);

                                this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                                  (result:any) => {
                                  
                                    if(result.response=== 200  && result.status === true){
                                      
                                    let lenderdata= result.data[0].lenderTransfer;
                                    this.activeLenderTotal= lenderdata.length;
                                      for (let i = 0; i < lenderdata.length; i++) {
                                        const lenderArray = lenderdata[i];
                                      //Offline
                                      if(lenderArray.lenderId ==='1'){
                                        this.activeOffline.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                        //Loan Tap
                                        if(lenderArray.lenderId ==='2'){
                                          this.activeLoanTap.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                        // FULLERTON INDIA
                                        if(lenderArray.lenderId ==='22'){
                                          this.activeFullerton.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                          // "IDFC First Bank"
                                        if(lenderArray.lenderId ==='53'){
                                          this.activeIdfcFirstBank.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                          //Aditya Birla Capital Ltd
                                        if(lenderArray.lenderId ==='26'){
                                          this.activeABCLBank.push({ lenderName:lenderArray.lenderName}); 
                                          
                                        }
                                      //Tata Capital Limited
                                      if(lenderArray.lenderId ==='19'){
                                        this.activeTataCapital.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                      //'Kotak Mahindra Bank Ltd'
                                      if(lenderArray.lenderId ==='14'){
                                        this.activeKotak.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                      //HDB Financial Services Ltd
                                      if(lenderArray.lenderId ==='31'){
                                        this.activeHDB.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                  
                                      }
                                          // offline
                                          
                                          this.activeOfflineTotal=this.activeOffline.length;
                                        //Loan Tap
                                        this.activeLoanTapTotal=this.activeLoanTap.length;
                                      // FULLERTON INDIA
                                      this.activeFullertonTotal=this.activeFullerton.length;
                                      // IDFC First Bank
                                      this.activeIdfcFirstBankTotal=this.activeIdfcFirstBank.length;
                                        //Aditya Birla Capital Ltd
                                      this.activeABCLBankTotal=this.activeABCLBank.length;
                                      //Tata Capital Limited
                                      this.activeTataCapitalTotal=this.activeTataCapital.length;
                                        //'Kotak Mahindra Bank Ltd'
                                      this.activeKotakTotal=this.activeKotak.length;
                                        //HDB Financial Services Ltd
                                        this.activeHDBTotal=this.activeHDB.length;
                                      // console.log(this.activeKotakTotal);

                                      //not useLendingkart
                                      this.activelendingkartTotal=0;
                                      //not Axis Bank
                                      this.activeAxisBankTotal=0;
                                      //Hero Fincorp Ltd
                                      this.activeHeroFincorpLtdTotal=0;
                                      //Poonawalla Finance Ltd
                                      this.activePoonawallaFinanceLtdTotal=0;
                                    }else{
                                      
                                    }
                                  });

                          }
                           //Doc Pending
                           this.activeDocPendingTotal=this.activeDocPending.length;
                           //Doc Received
                           this.activeDocReceivedTotal=this.activeDocReceived.length;
                           //Transfer
                           this.activeTransferTotal=this.activeTransfer.length;
                           //Lender Process
                           this.activeLenderProcessTotal=this.activeLenderProcess.length;
                           //Disbursed
                           this.activeDisbursedTotal=this.activeDisbursed.length;
                           //Pending Review
                           this.activePendingReviewTotal=this.activePendingReview.length;
                           //Call Pending
                           this.activeCallPendingTotal=this.activeCallPending.length;
                          // console.log("activeCallPendingTotal",this.activeCallPendingTotal);
                           //Call Complete                    
                           this.activeCallCompleteTotal=this.activeCallComplete.length;
                           //Doc Complete                  
                           this.activeDocCompleteTotal=this.activeDocComplete.length;
                           //Doc Incomplete
                           this.activeDocIncompleteTotal=this.activeDocIncomplete.length;
                          //  console.log(this.activeDocIncompleteTotal);
                            //totalLonMtAciveCurrentMonth
                            this.loanAmtActive =(this.totalLonMtAciveCurrentMonth);
                        }
                          //this Back date data 	  

                          let detailLastMonthActive = { results: [{ 
                            "fromdate":this.previousdayFrom,            
                            "todate":this.previousdayTo,          
                            
                            }] };
                            detailLastMonthActive['results']; 
                              this.lastmonthLoanAmtActive=0;
                              this.DashboardService.getActiveApplications(detailLastMonthActive).subscribe((lastmonthActiveUser: any) => {

                              if(lastmonthActiveUser.status===true &&  lastmonthActiveUser.response=== 200){

                              this.lastMontActiveTotal=lastmonthActiveUser.selfemp.length;

                              for (let i = 0; i < lastmonthActiveUser.selfemp.length; i++) {
                              const lastmonthActive = lastmonthActiveUser.selfemp[i];           
                              this.lastmonthLoanAmtActive += Number(lastmonthActive.data[0].loan_amount);	

                              //Doc Pending
                              if(lastmonthActive.data[0].feedback_stage_id =="1"){
                                this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});      
                                }else  if(lastmonthActive.data[0].feedback_stage_id =="20" ){
                                this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});             
                                }
                                //Doc Received
                                if(lastmonthActive.data[0].feedback_stage_id ==='21'){
                                this.activeDocReceivedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                                }
                                //Transfer
                                if(lastmonthActive.data[0].feedback_stage_id ==='18'){
                                this.activeTransferLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                                }
                                //Lender Process
                                if(lastmonthActive.data[0].feedback_stage_id ==='17'){
                                this.activeLenderProcessLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                                }
                                //Disbursed
                                if(lastmonthActive.data[0].feedback_stage_id ==='19'){
                                this.activeDisbursedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                                }
                                
                                
                                //Pending Review
                                if(lastmonthActive.data[0].feedback_stage_id ==='13'){
                                  this.activePendingReviewLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                }
                                //Call Pending
                                if(lastmonthActive.data[0].feedback_stage_id ==='22'){
                                  this.activeCallPendingLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                }
                                //Call Complete                    
                                if(lastmonthActive.data[0].feedback_stage_id ==='23'){
                                  this.activeCallCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                }
                                //Doc Complete                  
                                if(lastmonthActive.data[0].feedback_stage_id ==='25'){
                                  this.activeDocCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                }
                                //Doc Incomplete
                                if(lastmonthActive.data[0].feedback_stage_id ==='26'){
                                  this.activeDocIncompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                  
                                }                    


                              //tranfer to lender list
                              this.uid= parseInt(lastmonthActive.data[0].user_id);

                              this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                              (resultLender:any) => {

                                if(resultLender.response=== 200  && resultLender.status === true){
                                  
                                let lenderdataLender= resultLender.data[0].lenderTransfer;
                                this.activeLenderLastmonthTotal= lenderdataLender.length;;
                                  for (let i = 0; i < lenderdataLender.length; i++) {
                                    const lenderArrayLastmonth = lenderdataLender[i];
                                    //offline
                                    if(lenderArrayLastmonth.lenderId ==='1'){
                                      this.activeOfflineLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                    //Loan Tap
                                    if(lenderArrayLastmonth.lenderId ==='2'){
                                      this.activeLoanTapLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                    // FULLERTON INDIA
                                    if(lenderArrayLastmonth.lenderId ==='22'){
                                      this.activeFullertonLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                      // "IDFC First Bank"
                                    if(lenderArrayLastmonth.lenderId ==='53'){
                                      this.activeIdfcFirstBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                      //Aditya Birla Capital Ltd
                                    if(lenderArrayLastmonth.lenderId ==='26'){
                                      this.activeABCLBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                      
                                    }
                                  //Tata Capital Limited
                                  if(lenderArrayLastmonth.lenderId ==='19'){
                                    this.activeTataCapitalLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                  //'Kotak Mahindra Bank Ltd'
                                  if(lenderArrayLastmonth.lenderId ==='14'){
                                    this.activeKotakLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                  //HDB Financial Services Ltd
                                  if(lenderArrayLastmonth.lenderId ==='31'){
                                    this.activeHDBLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }

                                  }
                                      // console.log(this.activeKotakLastMonth);
                                        //offline
                                    this.activeOfflineLastMonthTotal=this.activeOfflineLastMonth.length;
                                    //Loan Tap
                                    this.activeLoanTapLastMonthTotal=this.activeLoanTapLastMonth.length;
                                  // FULLERTON INDIA
                                  this.activeFullertonLastMonthTotal=this.activeFullertonLastMonth.length;
                                  // IDFC First Bank
                                  this.activeIdfcFirstBankLastMonthTotal=this.activeIdfcFirstBankLastMonth.length;
                                    //Aditya Birla Capital Ltd
                                  this.activeABCLBankLastMonthTotal=this.activeABCLBankLastMonth.length;
                                  //Tata Capital Limited
                                  this.activeTataCapitalLastMonthTotal=this.activeTataCapitalLastMonth.length;
                                    //'Kotak Mahindra Bank Ltd'
                                  this.activeKotakLastMonthTotal=this.activeKotakLastMonth.length;
                                    //HDB Financial Services Ltd
                                    this.activeHDBLastMonthTotal=this.activeHDBLastMonth.length;
                                  // console.log(this.activeKotakLastMonthTotal);

                                  //not useLendingkart
                                  this.activelendingkartLastMonthTotal=0;
                                  //not Axis Bank
                                  this.activeAxisBankLastMonthTotal=0;
                                  //Hero Fincorp Ltd
                                  this.activeHeroFincorpLtdLastMonthTotal=0;
                                  //Poonawalla Finance Ltd
                                  this.activePoonawallaFinanceLtdLastMonthTotal=0;
                                }else{
                                  this.activeLenderLastmonthTotal=0; 
                                }
                              });

                              }

                              //Doc Pending
                              this.activeDocPendingLastMonthTotal=this.activeDocPendingLastMonth.length;
                              //Doc Received
                              this.activeDocReceivedLastMonthTotal=this.activeDocReceivedLastMonth.length;
                              //Transfer
                              this.activeTransferLastMonthTotal=this.activeTransferLastMonth.length;
                              //Lender Process
                              this.activeLenderProcessLastMonthTotal=this.activeLenderProcessLastMonth.length;
                              //Disbursed
                              this.activeDisbursedLastMonthTotal=this.activeDisbursedLastMonth.length;

                              //Pending Review
                              this.activePendingReviewLastMonthTotal=this.activePendingReviewLastMonth.length;
                              //Call Pending
                              this.activeCallPendingLastMonthTotal=this.activeCallPendingLastMonth.length;
                              //Call Complete                    
                              this.activeCallCompleteLastMonthTotal=this.activeCallCompleteLastMonth.length;
                              //Doc Complete                  
                              this.activeDocCompleteLastMonthTotal=this.activeDocCompleteLastMonth.length;
                              //Doc Incomplete
                              this.activeDocIncompleteLastMonthTotal=this.activeDocIncompleteLastMonth.length;
                              //lastmonthLoanAmtActive
                              this.LastMonthloanAmtActive =(this.lastmonthLoanAmtActive);

                              }
// --------------------------------------------------------------------------------------------------------------


                 // ApplicationActive total percentage
                 let applicationActive=(this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal);
                    
                 if(applicationActive > 0){ 
                     const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                     if(totoalapplicationActive !=Infinity){
                       this.applicationActiveDiv=1;
                       this.totoalApplicationActivePercentage=totoalapplicationActive;
                     }else{
                       this.docPendingDiv=1;
                       this.totoalApplicationActivePercentage=100;
                     }
                
                  } else  if(applicationActive < 0){ 
                   const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                     if(totoalapplicationActive !=Infinity){
                       this.applicationActiveDiv=0;
                       this.totoalApplicationActivePercentage=totoalapplicationActive;
                     }else{
                       this.applicationActiveDiv=0;
                       this.totoalApplicationActivePercentage=100;
                     }
                  }else{
                   this.applicationActiveDiv=1;
                   this.totoalApplicationActivePercentage=100;
                 
                  }
                  // Loan Amount (Rs. Lacs) total percentage
                 let loanAmt=(this.loanAmtActive-this.LastMonthloanAmtActive);
                                       
                 if(loanAmt > 0){ 
                     const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                     if(totoalLoanAmt !=Infinity){
                       this.loanAmtDiv=1;
                       this.loanAmtPercentage=totoalLoanAmt;
                     }else{
                       this.loanAmtDiv=1;
                       this.loanAmtPercentage=100;
                     }

                 } else  if(loanAmt < 0){ 
                   const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                   if(totoalLoanAmt !=Infinity){
                     this.loanAmtDiv=0;
                     this.loanAmtPercentage=totoalLoanAmt;
                   }else{
                     this.loanAmtDiv=0;
                     this.loanAmtPercentage=100;
                   }
                 }else{
                   this.loanAmtDiv=1;
                   this.loanAmtPercentage=100;

                 }
             //Doc Complete total percentage
            let docComplete=(this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal);
            //const  totoaldocComplet=0;
            if(docComplete > 0){ 
             const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
             if(totoaldocComplet !=Infinity){
               this.docCompletDiv=1;
               this.totoaldocCompletePercentage=totoaldocComplet;
             }else{
               this.docCompletDiv=1;
               this.totoaldocCompletePercentage=100;
             }
           //  console.log('positive');
             } else  if(docComplete < 0){ 
               const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
               if(totoaldocComplet !=Infinity){
                 this.docCompletDiv=0;
                 this.totoaldocCompletePercentage=totoaldocComplet;
               }else{
                 this.docCompletDiv=0;
                 this.totoaldocCompletePercentage=100;
               }
             }else{
               this.docCompletDiv=1;
               this.totoaldocCompletePercentage=100;
             }
             //Doc Received total percentage
               
             let DocReceived=(this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal);
             //console.log(this.activeDocReceivedLastMonthTotal);
             //const  totoaldocComplet=0;
             if(DocReceived > 0){ 
                 const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
                 if(totoalDocReceived !=Infinity){
                   this.docReceivedDiv=1;
                   this.totoaldocReceivedPercentage=totoalDocReceived;
                 }else{
                   this.docReceivedDiv=1;
                   this.totoaldocReceivedPercentage=100;
                 }
            //  console.log('positive');
              } else  if(DocReceived < 0){ 
               const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
               if(totoalDocReceived !=Infinity){
                 this.docReceivedDiv=0;
                 this.totoaldocReceivedPercentage=totoalDocReceived;
               }else{
                 this.docReceivedDiv=0;
                 this.totoaldocReceivedPercentage=100;
               }
              }else{
                this.docReceivedDiv=1;
                this.totoaldocReceivedPercentage=100;
              }
             //Doc Incomplete total percentage
             let DocIncomplete=(this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal);
           
             if(DocIncomplete > 0){ 
                 const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
                 if(totoalDocIncomplete !=Infinity){
                   this.docIncompleteDiv=1;
                   this.totoalDocIncompletePercentage=totoalDocIncomplete;
                 }else{
                   this.docIncompleteDiv=1;
                   this.totoalDocIncompletePercentage=100;
                 }
            
              } else  if(DocIncomplete < 0){ 
               const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
               if(totoalDocIncomplete !=Infinity){
                 this.docIncompleteDiv=0;
                 this.totoalDocIncompletePercentage=totoalDocIncomplete;
               }else{
                 this.docIncompleteDiv=0;
                 this.totoalDocIncompletePercentage=100;
               }
              }else{
                this.docIncompleteDiv=1;
                this.totoalDocIncompletePercentage=100;
              }
            //lDoc Pending total percentage   
         let DocPending=(this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal);
        // console.log(DocPending );
             if(DocPending > 0){ 
                 const totoalDocPending =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
                 if(totoalDocPending !=Infinity){
                   this.docPendingDiv=1;
                   this.totoalDocPendingPercentage=totoalDocPending;
                 }else{
                   this.docPendingDiv=1;
                   this.totoalDocIncompletePercentage=100;
                 }
            
              } else  if(DocPending < 0){ 
               const totoalDocIncomplete =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
               if(totoalDocIncomplete !=Infinity){
                 this.docPendingDiv=0;
                 this.totoalDocIncompletePercentage=totoalDocIncomplete;
                // console.log(this.totoalDocIncompletePercentage );
               }else{
                 this.docPendingDiv=0;
                 this.totoalDocIncompletePercentage=100;
               }
              }else{
                this.docPendingDiv=1;
                this.totoalDocIncompletePercentage=100;
             
              }
                //Pending Reviewtotal percentage   
         let pendingReview=(this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal);
    
         if(pendingReview > 0){ 
             const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
             if(totoalPendingReview !=Infinity){
               this.PendingReviewDiv=1;
               this.totoalPendingReviewPercentage=totoalPendingReview;
             }else{
               this.PendingReviewDiv=1;
               this.totoalPendingReviewPercentage=100;
             }
        
          } else  if(pendingReview < 0){ 
           const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
           if(totoalPendingReview !=Infinity){
            this.PendingReviewDiv=0;
            this.totoalPendingReviewPercentage=totoalPendingReview;
          
           }else{
             this.PendingReviewDiv=0;
             this.totoalDocIncompletePercentage=100;
           }
          }else{
            this.PendingReviewDiv=1;
            this.totoalPendingReviewPercentage=100;
         
          }
           //Transfer percentage   
         let transfer=(this.activeTransferTotal-this.activeTransferLastMonthTotal);
    
         if(transfer > 0){ 
             const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
             if(totoaltransfer !=Infinity){
               this.transferDiv=1;
               this.totoaltransferPercentage=totoaltransfer;
             }else{
               this.transferDiv=1;
               this.totoaltransferPercentage=100;
             }
        
          } else  if(transfer < 0){ 
           const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
           if(totoaltransfer !=Infinity){
            this.transferDiv=0;
            this.totoaltransferPercentage=totoaltransfer;
          
           }else{
             this.transferDiv=0;
             this.totoalDocIncompletePercentage=100;
           }
          }else{
            this.transferDiv=1;
            this.totoaltransferPercentage=100;
         
          }
                     //Lender Process percentage   
         let lenderProcess=(this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal);
         
         if(lenderProcess > 0){ 
             const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
             if(totoallenderProcess !=Infinity){
               this.lenderProcessDiv=1;
               this.totoallenderProcessPercentage=totoallenderProcess;
             }else{
               this.lenderProcessDiv=1;
               this.totoallenderProcessPercentage=100;
             }
        
          } else  if(lenderProcess < 0){ 
           const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
           if(totoallenderProcess !=Infinity){
            this.lenderProcessDiv=0;
            this.totoallenderProcessPercentage=totoallenderProcess;
          
           }else{
             this.lenderProcessDiv=0;
             this.totoalDocIncompletePercentage=100;
           }
          }else{
            this.lenderProcessDiv=1;
            this.totoallenderProcessPercentage=100;
         
          }
                     //Disbursed percentage   
                     let disbursed=(this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal);
         
                     if(disbursed > 0){ 
                         const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                         if(totoaldisbursed !=Infinity){
                           this.disbursedDiv=1;
                           this.totoaldisbursedPercentage=totoaldisbursed;
                         }else{
                           this.disbursedDiv=1;
                           this.totoaldisbursedPercentage=100;
                         }
                    
                      } else  if(disbursed < 0){ 
                       const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                       if(totoaldisbursed !=Infinity){
                        this.disbursedDiv=0;
                        this.totoaldisbursedPercentage=totoaldisbursed;
                      
                       }else{
                         this.disbursedDiv=0;
                         this.totoaldisbursedPercentage=100;
                       }
                      }else{
                        this.disbursedDiv=1;
                        this.totoaldisbursedPercentage=100;
                     
                      }
                     


                            });


                      });












                  //----------------------------------------------------- Graph------------------------------------------------------------

                  let graphinactive = { results: [{ 
                    "fromdate":this.FirstDaydate,            
                    "todate":this.currentdate,          
                    
                    }] };
                    graphinactive['results']; 
                    this.DashboardService.getActiveApplications(graphinactive).subscribe((grapresultAll: any) => {
                     
                      if(grapresultAll.status===true &&  grapresultAll.response=== 200){ 
                    
                             //console.log(this.InactiveCustCancelLastMonth);                                     
                                this.totalApplicationActiveCurrentMonth=grapresultAll.selfemp.length;                                              
                                for (let i = 0; i < grapresultAll.selfemp.length; i++) {
                                  const graphStartMonthmenuarrayAll = grapresultAll.selfemp[i];  
                                  //Doc Pending
                
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="3"){
                                        this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});      
                                      }else  if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="20" ){
                                        this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});             
                                      }
                                      //Doc Received
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='21'){
                                        this.activeDocReceivedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Transfer
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='18'){
                                        this.activeTransferGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      
                                      //Lender Process
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='17'){
                                        this.activeLenderProcessGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Disbursed
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='19'){
                                        this.activeDisbursedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Pending Review
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='13'){
                                        this.activePendingReviewGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Call Pending
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='22'){
                                        this.activeCallPendingGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Call Complete                    
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                        this.activeCallCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Doc Complete                  
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                        this.activeDocCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      }
                                      //Doc Incomplete
                                      if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='26'){
                                        this.activeDocIncompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                        
                                      } 
                            
                                }
                              }
                                this.DashboardService.getInActiveApplications(graphinactive).subscribe((resultInInactive: any) => {
                                  if(resultInInactive.status===true &&  resultInInactive.response=== 200){                                    
                                    this.totalApplicationInActiveLastMonth=resultInInactive.inactive.length;                                          
                                         for (let i = 0; i < resultInInactive.inactive.length; i++) {
                                          const menuarrayInactive = resultInInactive.inactive[i];  
                                           //Cust Cancel
                                            if(menuarrayInactive.data[0].feedback_stage_id  =="14"){
                                              this.InactiveCustCancelLastMonth.push({ stage_id: menuarrayInactive.data[0].feedback_stage_id });      
                                              }
                                              //CRM Cancel
                                              if(menuarrayInactive.data[0].feedback_stage_id  ==='15'){
                                              this.inactiveCRMCancelLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                              
                                              }
                                              //CRM Decline
                                              if(menuarrayInactive.data[0].feedback_stage_id  ==='16'){
                                              this.inactiveCRMDeclineLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                              
                                              }
                                         }
                                      
                                        }
                
                                        //-----------------------------------------------Graph api-----------------------------------------------------------------
                                        let  custCancel =   this.InactiveCustCancelLastMonth.length;
                                        let  crmCancel  = this.inactiveCRMCancelLastMonth.length;
                                        let  crmDecline  = this.inactiveCRMDeclineLastMonth.length;
                                        
                                        let  docReceived  = this.activeDocReceivedGraph.length;
                                        let  docPendin  = this.activeDocPendingGrap.length;
                                        let  transfer  = this.activeTransferGraph.length;
                                        let  lenderProcess  =this.activeLenderProcessGraph.length;
                                        let  disbursed  =this.activeDisbursedGraph.length;
                                        let  pendingReview  =this.activePendingReviewGraph.length;
                                        let  callPending  =this.activeCallPendingGraph.length;
                                        let  callComplete  =this.activeCallCompleteGraph.length;
                                        let  docIncomplete  =this.activeDocIncompleteGraph.length;
                                        
                                        
                                      // console.log(docReceived);
                                           let cCancel =custCancel;
                                           let crm =crmCancel;              
                                           let decline =crmDecline;
                                           let docrec =docReceived;
                                           let docpend =docPendin;
                                           let trans =transfer;
                                           let lenderProc =lenderProcess;
                                           let disb =disbursed;
                                           let pendingRev =pendingReview;
                                           let callPend =callPending;
                                           let callComp =callComplete;
                                          let docIncomp =docIncomplete;
                                          this.cust={   allleadArray: [
                                            { y: cCancel, name: "Cust Cancel" , total: cCancel},
                                            { y: crm, name: "CRM Cancel",total: crm },
                                            { y: decline, name: "CRM Decline",total: decline },
                                            { y: docrec, name: "Doc Received",total: docrec },
                                            { y: docpend, name: "Doc Pending",total: docpend },
                                            { y: trans, name: "Transfer",total: trans },
                                            { y: lenderProc, name: "Lender Process",total: lenderProc },                                                  
                                            { y: disb, name: "Disbursed",total: disb },
                                          { y:pendingRev , name: "Pending Review",total: pendingRev    },
                                          { y:callPend , name: "Call Pending",total: callPend    },
                                          { y:callComp , name: "Call Complete",total: callComp    },
                                          { y:docIncomp , name: "Doc Incomplete",total: docIncomp    },
                                            
                                          ]
                                      }
                                      for (let i = 0; i < this.cust['allleadArray'].length; i++) {
                                        const menuarrayActive = this.cust['allleadArray'][i];
                                        if(menuarrayActive.y !=0){
                                        // console.log(menuarrayActive);
                                        // this.mainaaary =menuarrayActive;
                                          this.mainaaary.push(
                                            {
                                              y: menuarrayActive.y,
                                              name: menuarrayActive.name, 
                                              total: menuarrayActive.total, 
                                                          
                                            });
                                        }  
                                      
                                      }
                                           
                                           
                                                                   //Canvas 
                                                                   let charts = new CanvasJS.Chart("inactive", {
                                                                    theme: "light",
                                                                    animationEnabled: true,
                                                                    ///exportEnabled: false,
                                                                    title:{text: "All Leads - Stages"},
                                                                    data: [{
                                                                      type: "pie",
                                                                      showInLegend: true,
                                                                      toolTipContent: "<b>{name}</b>: {total}",
                                                                      indexLabel: "{name} - #percent%",                                                                  
                                                                      dataPoints: this.mainaaary
                                                                      
                                                                    }]
                                                                  });
                                                                    
                                                                  charts.render();
                
                                                                  
                                                                 
                      
                        let cucancle =custCancel;
                        let crmCan =crmCancel;
                        let crmDec =crmDecline;
                        let inactiveData=  { inactive: [
                          { y: cucancle, name: "Cust Cancel" ,total: cucancle},
                          { y: crmCan, name: "CRM Cancel",total: crmCan },
                          { y: crmDec, name: "CRM Decline",total: crmDec },
                          
                          ]}
                          for (let i = 0; i < inactiveData['inactive'].length; i++) {
                          const menuarrayInactive = this.cust['allleadArray'][i];
                          if(menuarrayInactive.y !=0){
                          this.mainaaaryInactive.push(
                          {
                           y: menuarrayInactive.y,
                           name: menuarrayInactive.name, 
                           total: menuarrayInactive.total, 
                                       
                          });
                          }
                          }
                        //Canvas 
                        let chart = new CanvasJS.Chart("chartContainer", {
                          theme: "light",
                          animationEnabled: true,
                          ///exportEnabled: false,
                          title:{text: "All Leads InActive-  Status"},
                          data: [{
                            type: "pie",
                            showInLegend: true,
                            toolTipContent: "<b>{name}</b>: {total}",
                            indexLabel: "{name} - #percent%",
                        
                            dataPoints: this.mainaaaryInactive
                            
                          }]
                        });
                          
                        chart.render();
                
                
                                      });
                
                                  });
              

     }
      filter_chart(){
        this.activedate=1;
        this.cust= {} ;
        this.mainaaary=[];
        this.mainaaaryInactive=[];
        this.activeDocPending=[];
        this.activeDocReceived=[];
        this.activeTransfer=[];
        this.activeLenderProcess=[];
        this.activeDisbursed=[];
        this.activePendingReview=[];
        this.activeCallPending=[];
        this.activeCallComplete=[];
        this.activeDocComplete=[];
        this.activeDocIncomplete=[];
        this.activeOffline=[];
        this.activeLoanTap=[];
        this.activeFullerton=[];
        this.activeIdfcFirstBank=[];
        this.activeABCLBank=[];
        this.activeTataCapital=[];
        this.activeKotak=[];
        this.activeHDB=[];
        this.activeOfflineTotal=0
        //Loan Tap
        this.activeLoanTapTotal=0
        // FULLERTON INDIA
        this.activeFullertonTotal=0
        // IDFC First Bank
        this.activeIdfcFirstBankTotal=0
        //Aditya Birla Capital Ltd
        this.activeABCLBankTotal=0
        //Tata Capital Limited
        this.activeTataCapitalTotal=0
        //'Kotak Mahindra Bank Ltd'
        this.activeKotakTotal=0
        //HDB Financial Services Ltd
        this.activeHDBTotal=0
        // console.log(this.activeKotakTotal);
        //Doc Pending
        this.activeDocPendingTotal=0;
        //Doc Received
        this.activeDocReceivedTotal=0;
        //Transfer
        this.activeTransferTotal=0
        //Lender Process
        this.activeLenderProcessTotal=0
        //Disbursed
        this.activeDisbursedTotal=0
        //Pending Review
        this.activePendingReviewTotal=0
        //Call Pending
        this.activeCallPendingTotal=0
        //Call Complete                    
        this.activeCallCompleteTotal=0
        //Doc Complete                  
        this.activeDocCompleteTotal=0
        //Doc Incomplete
        this.activeDocIncompleteTotal=0
        //  console.log(this.activeDocIncompleteTotal);
        //totalLonMtAciveCurrentMonth
        this.loanAmtActive =0
        
        
        
        //last month
        this.lastmonthLoanAmtActive=0;
        this.activeDocPendingLastMonth=[];
        this.activeDocReceivedLastMonth=[];
        this.activeTransferLastMonth=[];
        this.activeLenderProcessLastMonth=[];
        this.activeDisbursedLastMonth=[];
        this.activePendingReviewLastMonth=[];
        this.activeCallPendingLastMonth=[];
        this.activeCallCompleteLastMonth=[];
        this.activeDocCompleteLastMonth=[];
        this.activeDocIncompleteLastMonth=[];
        this.activeLenderLastmonthTotal=0;
        this.activeOfflineLastMonth=[];
        this.activeLoanTapLastMonth=[];
        this.activeFullertonLastMonth=[];
        this.activeIdfcFirstBankLastMonth=[];
        this.activeABCLBankLastMonth=[];
        this.activeTataCapitalLastMonth=[];
        this.activeKotakLastMonth=[];
        this.activeHDBLastMonth=[];
        
            //offline
            this.activeOfflineLastMonthTotal=0;
            //Loan Tap
            this.activeLoanTapLastMonthTotal=0;
          // FULLERTON INDIA
          this.activeFullertonLastMonthTotal=0;
          // IDFC First Bank
          this.activeIdfcFirstBankLastMonthTotal=0;
            //Aditya Birla Capital Ltd
          this.activeABCLBankLastMonthTotal=0;
          //Tata Capital Limited
          this.activeTataCapitalLastMonthTotal=0;
            //'Kotak Mahindra Bank Ltd'
          this.activeKotakLastMonthTotal=0;
            //HDB Financial Services Ltd
            this.activeHDBLastMonthTotal=0;
          // console.log(this.activeKotakLastMonthTotal);
          //Doc Pending
          this.activeDocPendingLastMonthTotal=0;
          //Doc Received
          this.activeDocReceivedLastMonthTotal=0;
          //Transfer
          this.activeTransferLastMonthTotal=0;
          //Lender Process
          this.activeLenderProcessLastMonthTotal=0;
          //Disbursed
          this.activeDisbursedLastMonthTotal=0;
        
          //Pending Review
          this.activePendingReviewLastMonthTotal=0;
          //Call Pending
          this.activeCallPendingLastMonthTotal=0;
          //Call Complete                    
          this.activeCallCompleteLastMonthTotal=0;
          //Doc Complete                  
          this.activeDocCompleteLastMonthTotal=0;
          //Doc Incomplete
          this.activeDocIncompleteLastMonthTotal=0;
          //lastmonthLoanAmtActive
          this.LastMonthloanAmtActive =0;
          this.applicationActiveDiv=0;
          this.docPendingDiv=0;
        this.totoalApplicationActivePercentage=0;
        this.loanAmtDiv=0;
        this.loanAmtPercentage=0;
        this.docCompletDiv=0;
        this.totoaldocCompletePercentage=0;
        this.docReceivedDiv=0;
        this.totoaldocReceivedPercentage=0;
        this.docIncompleteDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.docPendingDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.PendingReviewDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.transferDiv=0;
        this.totoaltransferPercentage=0;
        this.lenderProcessDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.disbursedDiv=0;
        this.totoaldisbursedPercentage=0;
        
        
        this.InactiveCustCancelLastMonth=[];
        this.inactiveCRMCancelLastMonth=[];
        this.inactiveCRMDeclineLastMonth=[];
        this.activeDocPendingGrap=[];
        this.activeTransferGraph=[];
        this.activeLenderProcessGraph=[];
        this.activeDisbursedGraph=[];
        this.activePendingReviewGraph=[];
        this.activeCallPendingGraph=[];
        this.activeCallCompleteGraph=[];
        this.activeDocCompleteGraph=[];
        this.activeDocIncompleteGraph=[];
          this.daternagediv=0;
        
        this.inactiveCRMDeclineLastMonthTotal=0;
      this.InactiveCustCancelLastMonthTotal=0;
        
        let startDate = new Date(this.filterchart.value.datefrom);
        let endDate = new Date(this.filterchart.value.dateto);
        
       // this.currentdate=endDate;
        //previous month yaear day  Start Date
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      // console.log(differenceInDays); 
        const currentDates = new Date();
        const pastDate = new Date(this.filterchart.value.datefrom);
        let backdayStartDay=new Date(pastDate.setDate(startDate.getDate() - 30));

        let FirstDayBackMonth = ("0" + backdayStartDay.getDate()).slice(-2);
        // current month
        let monthFirstDayBackMonth = ("0" + (backdayStartDay.getMonth() + 1)).slice(-2);
        // current year
        let yearBackMonth = backdayStartDay.getFullYear();
        let privStartdates =(yearBackMonth + "-" + monthFirstDayBackMonth + "-" + FirstDayBackMonth);
        //console.log('previous month yaear day  Start Date' + privStartdates);

        //Filter day previous month yaear day stop date
        const currentDatess =new Date(this.filterchart.value.datefrom);
        const previousDay =new Date(this.filterchart.value.datefrom);
        let backdayStopDay= new Date(previousDay.setDate(currentDatess.getDate() - 1));
        let FirstDaybackdayStop = ("0" + backdayStopDay.getDate()).slice(-2);
        // current month
        let monthFirstDaybackdayStop = ("0" + (backdayStopDay.getMonth() + 1)).slice(-2);
        // current year
        let yearBackbackdayStop= backdayStopDay.getFullYear();
        let previousDayStop =(yearBackbackdayStop + "-" + monthFirstDaybackdayStop + "-" + FirstDaybackdayStop);
      // console.log('previous month yaear day stop date' + previousDayStop);


    







        let fromdate =new Date(formatDate(startDate, 'yyyy-MM-dd', 'en-US'));
        let todate =new Date(formatDate(endDate, 'yyyy-MM-dd', 'en-US'));

        this.FirstDaydate=formatDate(startDate, 'yyyy-MM-dd', 'en-US');
        this.currentdate=formatDate(endDate, 'yyyy-MM-dd', 'en-US');
        
        const yearfrom = fromdate.getFullYear();
        const yearto = todate.getFullYear();
        const enddateFilter = new Date('2021-01-01');
        const enddate = enddateFilter.getFullYear();
        // console.log(enddate);
        // console.log(yearfrom);
        // console.log(yearto);
        if((yearfrom > enddate) && (yearto > enddate)){
          //this.FirstDaydate=this.form;
         // this.currentdate=this.to;
          this.form=formatDate(startDate, 'yyyy-MM-dd', 'en-US');
          this.to=formatDate(endDate, 'yyyy-MM-dd', 'en-US');

          
                // Active stage                     
                  let detail = { results: [{ 
                    "fromdate":this.form,            
                    "todate":this.to,          
                    
                    }] };
                    detail['results']; 
                    this.totalLonMtAciveCurrentMonth=0;
                  this.DashboardService.getActiveApplications(detail).subscribe((result: any) => {
                    
                    if(result.status===true &&  result.response=== 200){
                    //console.log(result.selfemp);
                        this.totalApplicationActiveCurrentMonth=result.selfemp.length;
                      
                        for (let i = 0; i < result.selfemp.length; i++) {
                          const startMonthmenuarray = result.selfemp[i]; 
                        //  console.log(startMonthmenuarray.data[0].feedback_stage_id);          
                          this.totalLonMtAciveCurrentMonth += Number(startMonthmenuarray.data[0].loan_amount);	
                      
                          if(startMonthmenuarray.data[0].feedback_stage_id =="3"){
                            this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});      
                          }else  if(startMonthmenuarray.data[0].feedback_stage_id =="20" ){
                            this.activeDocPending.push({ feedback_stage_id: startMonthmenuarray.data[0].feedback_stage_id});             
                          }
                          //Doc Received
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='21'){
                            this.activeDocReceived.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Transfer
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='18'){
                            this.activeTransfer.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          
                          //Lender Process
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='17'){
                            this.activeLenderProcess.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Disbursed
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='19'){
                            this.activeDisbursed.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Pending Review
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='13'){
                            this.activePendingReview.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Call Pending
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='22'){
                            this.activeCallPending.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Call Complete                    
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='23'){
                            this.activeCallComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Doc Complete                  
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='25'){
                            this.activeDocComplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }
                          //Doc Incomplete
                          if(startMonthmenuarray.data[0].feedback_stage_id ==='26'){
                            this.activeDocIncomplete.push({ docReceived: startMonthmenuarray.data[0].feedback_stage_id}); 
                            
                          }


                              //tranfer to lender list
                              this.uid= parseInt(startMonthmenuarray.data[0].user_id);

                              this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                                (result:any) => {
                                
                                  if(result.response=== 200  && result.status === true){
                                    
                                  let lenderdata= result.data[0].lenderTransfer;
                                  this.activeLenderTotal= lenderdata.length;
                                    for (let i = 0; i < lenderdata.length; i++) {
                                      const lenderArray = lenderdata[i];
                                    //Offline
                                    if(lenderArray.lenderId ==='1'){
                                      this.activeOffline.push({ lenderName:lenderArray.lenderName}); 
                                      
                                    }
                                      //Loan Tap
                                      if(lenderArray.lenderId ==='2'){
                                        this.activeLoanTap.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                      // FULLERTON INDIA
                                      if(lenderArray.lenderId ==='22'){
                                        this.activeFullerton.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                        // "IDFC First Bank"
                                      if(lenderArray.lenderId ==='53'){
                                        this.activeIdfcFirstBank.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                        //Aditya Birla Capital Ltd
                                      if(lenderArray.lenderId ==='26'){
                                        this.activeABCLBank.push({ lenderName:lenderArray.lenderName}); 
                                        
                                      }
                                    //Tata Capital Limited
                                    if(lenderArray.lenderId ==='19'){
                                      this.activeTataCapital.push({ lenderName:lenderArray.lenderName}); 
                                      
                                    }
                                    //'Kotak Mahindra Bank Ltd'
                                    if(lenderArray.lenderId ==='14'){
                                      this.activeKotak.push({ lenderName:lenderArray.lenderName}); 
                                      
                                    }
                                    //HDB Financial Services Ltd
                                    if(lenderArray.lenderId ==='31'){
                                      this.activeHDB.push({ lenderName:lenderArray.lenderName}); 
                                      
                                    }
                                
                                    }
                                        // offline
                                        
                                        this.activeOfflineTotal=this.activeOffline.length;
                                      //Loan Tap
                                      this.activeLoanTapTotal=this.activeLoanTap.length;
                                    // FULLERTON INDIA
                                    this.activeFullertonTotal=this.activeFullerton.length;
                                    // IDFC First Bank
                                    this.activeIdfcFirstBankTotal=this.activeIdfcFirstBank.length;
                                      //Aditya Birla Capital Ltd
                                    this.activeABCLBankTotal=this.activeABCLBank.length;
                                    //Tata Capital Limited
                                    this.activeTataCapitalTotal=this.activeTataCapital.length;
                                      //'Kotak Mahindra Bank Ltd'
                                    this.activeKotakTotal=this.activeKotak.length;
                                      //HDB Financial Services Ltd
                                      this.activeHDBTotal=this.activeHDB.length;
                                    // console.log(this.activeKotakTotal);

                                    //not useLendingkart
                                    this.activelendingkartTotal=0;
                                    //not Axis Bank
                                    this.activeAxisBankTotal=0;
                                    //Hero Fincorp Ltd
                                    this.activeHeroFincorpLtdTotal=0;
                                    //Poonawalla Finance Ltd
                                    this.activePoonawallaFinanceLtdTotal=0;
                                  }else{
                                    
                                  }
                                });

                        }
                        //Doc Pending
                        this.activeDocPendingTotal=this.activeDocPending.length;
                        //Doc Received
                        this.activeDocReceivedTotal=this.activeDocReceived.length;
                        //Transfer
                        this.activeTransferTotal=this.activeTransfer.length;
                        //Lender Process
                        this.activeLenderProcessTotal=this.activeLenderProcess.length;
                        //Disbursed
                        this.activeDisbursedTotal=this.activeDisbursed.length;
                        //Pending Review
                        this.activePendingReviewTotal=this.activePendingReview.length;
                        //Call Pending
                        this.activeCallPendingTotal=this.activeCallPending.length;
                        console.log("activeCallPendingTotal",this.activeCallPendingTotal);
                        //Call Complete                    
                        this.activeCallCompleteTotal=this.activeCallComplete.length;
                        //Doc Complete                  
                        this.activeDocCompleteTotal=this.activeDocComplete.length;
                        //Doc Incomplete
                        this.activeDocIncompleteTotal=this.activeDocIncomplete.length;
                        //  console.log(this.activeDocIncompleteTotal);
                          //totalLonMtAciveCurrentMonth
                          this.loanAmtActive =(this.totalLonMtAciveCurrentMonth);
                      }
                        //this Back date data 	  

                        let detailLastMonthActive = { results: [{ 
                          "fromdate":this.previousdayFrom,            
                          "todate":this.previousdayTo,          
                          
                          }] };
                          detailLastMonthActive['results']; 
                            this.lastmonthLoanAmtActive=0;
                            this.DashboardService.getActiveApplications(detailLastMonthActive).subscribe((lastmonthActiveUser: any) => {

                            if(lastmonthActiveUser.status===true &&  lastmonthActiveUser.response=== 200){

                            this.lastMontActiveTotal=lastmonthActiveUser.selfemp.length;

                            for (let i = 0; i < lastmonthActiveUser.selfemp.length; i++) {
                            const lastmonthActive = lastmonthActiveUser.selfemp[i];           
                            this.lastmonthLoanAmtActive += Number(lastmonthActive.data[0].loan_amount);	

                            //Doc Pending
                            if(lastmonthActive.data[0].feedback_stage_id =="1"){
                              this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});      
                              }else  if(lastmonthActive.data[0].feedback_stage_id =="20" ){
                              this.activeDocPendingLastMonth.push({ stage_id: lastmonthActive.data[0].feedback_stage_id});             
                              }
                              //Doc Received
                              if(lastmonthActive.data[0].feedback_stage_id ==='21'){
                              this.activeDocReceivedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                              
                              }
                              //Transfer
                              if(lastmonthActive.data[0].feedback_stage_id ==='18'){
                              this.activeTransferLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                              
                              }
                              //Lender Process
                              if(lastmonthActive.data[0].feedback_stage_id ==='17'){
                              this.activeLenderProcessLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                              
                              }
                              //Disbursed
                              if(lastmonthActive.data[0].feedback_stage_id ==='19'){
                              this.activeDisbursedLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                              
                              }
                              
                              
                              //Pending Review
                              if(lastmonthActive.data[0].feedback_stage_id ==='13'){
                                this.activePendingReviewLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                              }
                              //Call Pending
                              if(lastmonthActive.data[0].feedback_stage_id ==='22'){
                                this.activeCallPendingLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                              }
                              //Call Complete                    
                              if(lastmonthActive.data[0].feedback_stage_id ==='23'){
                                this.activeCallCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                              }
                              //Doc Complete                  
                              if(lastmonthActive.data[0].feedback_stage_id ==='25'){
                                this.activeDocCompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                              }
                              //Doc Incomplete
                              if(lastmonthActive.data[0].feedback_stage_id ==='26'){
                                this.activeDocIncompleteLastMonth.push({ docReceived: lastmonthActive.data[0].feedback_stage_id}); 
                                
                              }                    


                            //tranfer to lender list
                            this.uid= parseInt(lastmonthActive.data[0].user_id);

                            this.DashboardService.getActiveUserLenderName(this.uid).subscribe( 
                            (resultLender:any) => {

                              if(resultLender.response=== 200  && resultLender.status === true){
                                
                              let lenderdataLender= resultLender.data[0].lenderTransfer;
                              this.activeLenderLastmonthTotal= lenderdataLender.length;;
                                for (let i = 0; i < lenderdataLender.length; i++) {
                                  const lenderArrayLastmonth = lenderdataLender[i];
                                  //offline
                                  if(lenderArrayLastmonth.lenderId ==='1'){
                                    this.activeOfflineLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                  //Loan Tap
                                  if(lenderArrayLastmonth.lenderId ==='2'){
                                    this.activeLoanTapLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                  // FULLERTON INDIA
                                  if(lenderArrayLastmonth.lenderId ==='22'){
                                    this.activeFullertonLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                    // "IDFC First Bank"
                                  if(lenderArrayLastmonth.lenderId ==='53'){
                                    this.activeIdfcFirstBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                    //Aditya Birla Capital Ltd
                                  if(lenderArrayLastmonth.lenderId ==='26'){
                                    this.activeABCLBankLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                    
                                  }
                                //Tata Capital Limited
                                if(lenderArrayLastmonth.lenderId ==='19'){
                                  this.activeTataCapitalLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                  
                                }
                                //'Kotak Mahindra Bank Ltd'
                                if(lenderArrayLastmonth.lenderId ==='14'){
                                  this.activeKotakLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                  
                                }
                                //HDB Financial Services Ltd
                                if(lenderArrayLastmonth.lenderId ==='31'){
                                  this.activeHDBLastMonth.push({ lenderName:lenderArrayLastmonth.lenderName}); 
                                  
                                }

                                }
                                    // console.log(this.activeKotakLastMonth);
                                      //offline
                                  this.activeOfflineLastMonthTotal=this.activeOfflineLastMonth.length;
                                  //Loan Tap
                                  this.activeLoanTapLastMonthTotal=this.activeLoanTapLastMonth.length;
                                // FULLERTON INDIA
                                this.activeFullertonLastMonthTotal=this.activeFullertonLastMonth.length;
                                // IDFC First Bank
                                this.activeIdfcFirstBankLastMonthTotal=this.activeIdfcFirstBankLastMonth.length;
                                  //Aditya Birla Capital Ltd
                                this.activeABCLBankLastMonthTotal=this.activeABCLBankLastMonth.length;
                                //Tata Capital Limited
                                this.activeTataCapitalLastMonthTotal=this.activeTataCapitalLastMonth.length;
                                  //'Kotak Mahindra Bank Ltd'
                                this.activeKotakLastMonthTotal=this.activeKotakLastMonth.length;
                                  //HDB Financial Services Ltd
                                  this.activeHDBLastMonthTotal=this.activeHDBLastMonth.length;
                                // console.log(this.activeKotakLastMonthTotal);

                                //not useLendingkart
                                this.activelendingkartLastMonthTotal=0;
                                //not Axis Bank
                                this.activeAxisBankLastMonthTotal=0;
                                //Hero Fincorp Ltd
                                this.activeHeroFincorpLtdLastMonthTotal=0;
                                //Poonawalla Finance Ltd
                                this.activePoonawallaFinanceLtdLastMonthTotal=0;
                              }else{
                                this.activeLenderLastmonthTotal=0; 
                              }
                            });

                            }

                            //Doc Pending
                            this.activeDocPendingLastMonthTotal=this.activeDocPendingLastMonth.length;
                            //Doc Received
                            this.activeDocReceivedLastMonthTotal=this.activeDocReceivedLastMonth.length;
                            //Transfer
                            this.activeTransferLastMonthTotal=this.activeTransferLastMonth.length;
                            //Lender Process
                            this.activeLenderProcessLastMonthTotal=this.activeLenderProcessLastMonth.length;
                            //Disbursed
                            this.activeDisbursedLastMonthTotal=this.activeDisbursedLastMonth.length;

                            //Pending Review
                            this.activePendingReviewLastMonthTotal=this.activePendingReviewLastMonth.length;
                            //Call Pending
                            this.activeCallPendingLastMonthTotal=this.activeCallPendingLastMonth.length;
                            //Call Complete                    
                            this.activeCallCompleteLastMonthTotal=this.activeCallCompleteLastMonth.length;
                            //Doc Complete                  
                            this.activeDocCompleteLastMonthTotal=this.activeDocCompleteLastMonth.length;
                            //Doc Incomplete
                            this.activeDocIncompleteLastMonthTotal=this.activeDocIncompleteLastMonth.length;
                            //lastmonthLoanAmtActive
                            this.LastMonthloanAmtActive =(this.lastmonthLoanAmtActive);

                            }
                  // --------------------------------------------------------------------------------------------------------------


                  // ApplicationActive total percentage
                  let applicationActive=(this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal);

                  if(applicationActive > 0){ 
                  const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                  if(totoalapplicationActive !=Infinity){
                    this.applicationActiveDiv=1;
                    this.totoalApplicationActivePercentage=totoalapplicationActive;
                  }else{
                    this.docPendingDiv=1;
                    this.totoalApplicationActivePercentage=100;
                  }

                  } else  if(applicationActive < 0){ 
                  const totoalapplicationActive =((this.totalApplicationActiveCurrentMonth-this.lastMontActiveTotal)/this.lastMontActiveTotal)*100;
                  if(totoalapplicationActive !=Infinity){
                    this.applicationActiveDiv=0;
                    this.totoalApplicationActivePercentage=totoalapplicationActive;
                  }else{
                    this.applicationActiveDiv=0;
                    this.totoalApplicationActivePercentage=100;
                  }
                  }else{
                  this.applicationActiveDiv=1;
                  this.totoalApplicationActivePercentage=100;

                  }
                  // Loan Amount (Rs. Lacs) total percentage
                  let loanAmt=(this.loanAmtActive-this.LastMonthloanAmtActive);
                                    
                  if(loanAmt > 0){ 
                  const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                  if(totoalLoanAmt !=Infinity){
                    this.loanAmtDiv=1;
                    this.loanAmtPercentage=totoalLoanAmt;
                  }else{
                    this.loanAmtDiv=1;
                    this.loanAmtPercentage=100;
                  }

                  } else  if(loanAmt < 0){ 
                  const totoalLoanAmt =((this.loanAmtActive-this.LastMonthloanAmtActive)/this.LastMonthloanAmtActive)*100;
                  if(totoalLoanAmt !=Infinity){
                  this.loanAmtDiv=0;
                  this.loanAmtPercentage=totoalLoanAmt;
                  }else{
                  this.loanAmtDiv=0;
                  this.loanAmtPercentage=100;
                  }
                  }else{
                  this.loanAmtDiv=1;
                  this.loanAmtPercentage=100;

                  }
                  //Doc Complete total percentage
                  let docComplete=(this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal);
                  //const  totoaldocComplet=0;
                  if(docComplete > 0){ 
                  const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
                  if(totoaldocComplet !=Infinity){
                  this.docCompletDiv=1;
                  this.totoaldocCompletePercentage=totoaldocComplet;
                  }else{
                  this.docCompletDiv=1;
                  this.totoaldocCompletePercentage=100;
                  }
                  //  console.log('positive');
                  } else  if(docComplete < 0){ 
                  const totoaldocComplet =((this.activeDocCompleteTotal-this.activeDocCompleteLastMonthTotal)/this.activeDocCompleteLastMonthTotal)*100;
                  if(totoaldocComplet !=Infinity){
                  this.docCompletDiv=0;
                  this.totoaldocCompletePercentage=totoaldocComplet;
                  }else{
                  this.docCompletDiv=0;
                  this.totoaldocCompletePercentage=100;
                  }
                  }else{
                  this.docCompletDiv=1;
                  this.totoaldocCompletePercentage=100;
                  }
                  //Doc Received total percentage

                  let DocReceived=(this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal);
                 // console.log(this.activeDocReceivedLastMonthTotal);
                  //const  totoaldocComplet=0;
                  if(DocReceived > 0){ 
                  const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
                  if(totoalDocReceived !=Infinity){
                  this.docReceivedDiv=1;
                  this.totoaldocReceivedPercentage=totoalDocReceived;
                  }else{
                  this.docReceivedDiv=1;
                  this.totoaldocReceivedPercentage=100;
                  }
                  //  console.log('positive');
                  } else  if(DocReceived < 0){ 
                  const totoalDocReceived =((this.activeDocReceivedTotal-this.activeDocReceivedLastMonthTotal)/this.activeDocReceivedLastMonthTotal)*100;
                  if(totoalDocReceived !=Infinity){
                  this.docReceivedDiv=0;
                  this.totoaldocReceivedPercentage=totoalDocReceived;
                  }else{
                  this.docReceivedDiv=0;
                  this.totoaldocReceivedPercentage=100;
                  }
                  }else{
                  this.docReceivedDiv=1;
                  this.totoaldocReceivedPercentage=100;
                  }
                  //Doc Incomplete total percentage
                  let DocIncomplete=(this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal);

                  if(DocIncomplete > 0){ 
                  const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
                  if(totoalDocIncomplete !=Infinity){
                  this.docIncompleteDiv=1;
                  this.totoalDocIncompletePercentage=totoalDocIncomplete;
                  }else{
                  this.docIncompleteDiv=1;
                  this.totoalDocIncompletePercentage=100;
                  }

                  } else  if(DocIncomplete < 0){ 
                  const totoalDocIncomplete =((this.activeDocIncompleteTotal-this.activeDocIncompleteLastMonthTotal)/this.activeDocIncompleteLastMonthTotal)*100;
                  if(totoalDocIncomplete !=Infinity){
                  this.docIncompleteDiv=0;
                  this.totoalDocIncompletePercentage=totoalDocIncomplete;
                  }else{
                  this.docIncompleteDiv=0;
                  this.totoalDocIncompletePercentage=100;
                  }
                  }else{
                  this.docIncompleteDiv=1;
                  this.totoalDocIncompletePercentage=100;
                  }
                  //lDoc Pending total percentage   
                  let DocPending=(this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal);
                  // console.log(DocPending );
                  if(DocPending > 0){ 
                  const totoalDocPending =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
                  if(totoalDocPending !=Infinity){
                  this.docPendingDiv=1;
                  this.totoalDocPendingPercentage=totoalDocPending;
                  }else{
                  this.docPendingDiv=1;
                  this.totoalDocIncompletePercentage=100;
                  }

                  } else  if(DocPending < 0){ 
                  const totoalDocIncomplete =((this.activeDocPendingTotal-this.activeDocPendingLastMonthTotal)/this.activeDocPendingLastMonthTotal)*100;
                  if(totoalDocIncomplete !=Infinity){
                  this.docPendingDiv=0;
                  this.totoalDocIncompletePercentage=totoalDocIncomplete;
                  // console.log(this.totoalDocIncompletePercentage );
                  }else{
                  this.docPendingDiv=0;
                  this.totoalDocIncompletePercentage=100;
                  }
                  }else{
                  this.docPendingDiv=1;
                  this.totoalDocIncompletePercentage=100;

                  }
                  //Pending Reviewtotal percentage   
                  let pendingReview=(this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal);

                  if(pendingReview > 0){ 
                  const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
                  if(totoalPendingReview !=Infinity){
                  this.PendingReviewDiv=1;
                  this.totoalPendingReviewPercentage=totoalPendingReview;
                  }else{
                  this.PendingReviewDiv=1;
                  this.totoalPendingReviewPercentage=100;
                  }

                  } else  if(pendingReview < 0){ 
                  const totoalPendingReview =((this.activePendingReviewTotal-this.activePendingReviewLastMonthTotal)/this.activePendingReviewLastMonthTotal)*100;
                  if(totoalPendingReview !=Infinity){
                  this.PendingReviewDiv=0;
                  this.totoalPendingReviewPercentage=totoalPendingReview;

                  }else{
                  this.PendingReviewDiv=0;
                  this.totoalDocIncompletePercentage=100;
                  }
                  }else{
                  this.PendingReviewDiv=1;
                  this.totoalPendingReviewPercentage=100;

                  }
                  //Transfer percentage   
                  let transfer=(this.activeTransferTotal-this.activeTransferLastMonthTotal);

                  if(transfer > 0){ 
                  const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
                  if(totoaltransfer !=Infinity){
                  this.transferDiv=1;
                  this.totoaltransferPercentage=totoaltransfer;
                  }else{
                  this.transferDiv=1;
                  this.totoaltransferPercentage=100;
                  }

                  } else  if(transfer < 0){ 
                  const totoaltransfer =((this.activeTransferTotal-this.activeTransferLastMonthTotal)/this.activeTransferLastMonthTotal)*100;
                  if(totoaltransfer !=Infinity){
                  this.transferDiv=0;
                  this.totoaltransferPercentage=totoaltransfer;

                  }else{
                  this.transferDiv=0;
                  this.totoalDocIncompletePercentage=100;
                  }
                  }else{
                  this.transferDiv=1;
                  this.totoaltransferPercentage=100;

                  }
                  //Lender Process percentage   
                  let lenderProcess=(this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal);

                  if(lenderProcess > 0){ 
                  const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
                  if(totoallenderProcess !=Infinity){
                  this.lenderProcessDiv=1;
                  this.totoallenderProcessPercentage=totoallenderProcess;
                  }else{
                  this.lenderProcessDiv=1;
                  this.totoallenderProcessPercentage=100;
                  }

                  } else  if(lenderProcess < 0){ 
                  const totoallenderProcess =((this.activeLenderProcessTotal-this.activeLenderProcessLastMonthTotal)/this.activeLenderProcessLastMonthTotal)*100;
                  if(totoallenderProcess !=Infinity){
                  this.lenderProcessDiv=0;
                  this.totoallenderProcessPercentage=totoallenderProcess;

                  }else{
                  this.lenderProcessDiv=0;
                  this.totoalDocIncompletePercentage=100;
                  }
                  }else{
                  this.lenderProcessDiv=1;
                  this.totoallenderProcessPercentage=100;

                  }
                  //Disbursed percentage   
                  let disbursed=(this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal);

                  if(disbursed > 0){ 
                      const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                      if(totoaldisbursed !=Infinity){
                        this.disbursedDiv=1;
                        this.totoaldisbursedPercentage=totoaldisbursed;
                      }else{
                        this.disbursedDiv=1;
                        this.totoaldisbursedPercentage=100;
                      }

                    } else  if(disbursed < 0){ 
                    const totoaldisbursed =((this.activeDisbursedTotal-this.activeDisbursedLastMonthTotal)/this.activeDisbursedLastMonthTotal)*100;
                    if(totoaldisbursed !=Infinity){
                      this.disbursedDiv=0;
                      this.totoaldisbursedPercentage=totoaldisbursed;
                    
                    }else{
                      this.disbursedDiv=0;
                      this.totoaldisbursedPercentage=100;
                    }
                    }else{
                      this.disbursedDiv=1;
                      this.totoaldisbursedPercentage=100;
                  
                    }
                  


                          });


                    });




                    //----------------------------------------------------- Graph------------------------------------------------------------

                    let graphinactive = { results: [{ 
                      "fromdate":this.form,            
                      "todate":this.to,          
                      
                      }] };
                      graphinactive['results']; 
                      this.DashboardService.getActiveApplications(graphinactive).subscribe((grapresultAll: any) => {
                      
                        if(grapresultAll.status===true &&  grapresultAll.response=== 200){ 
                      
                              //console.log(this.InactiveCustCancelLastMonth);                                     
                                  this.totalApplicationActiveCurrentMonth=grapresultAll.selfemp.length;                                              
                                  for (let i = 0; i < grapresultAll.selfemp.length; i++) {
                                    const graphStartMonthmenuarrayAll = grapresultAll.selfemp[i];  
                                    //Doc Pending
                  
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="3"){
                                          this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});      
                                        }else  if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id =="20" ){
                                          this.activeDocPendingGrap.push({ stage_id:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id});             
                                        }
                                        //Doc Received
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='21'){
                                          this.activeDocReceivedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Transfer
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='18'){
                                          this.activeTransferGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        
                                        //Lender Process
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='17'){
                                          this.activeLenderProcessGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Disbursed
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='19'){
                                          this.activeDisbursedGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Pending Review
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='13'){
                                          this.activePendingReviewGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Call Pending
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='22'){
                                          this.activeCallPendingGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Call Complete                    
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                          this.activeCallCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Doc Complete                  
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='23'){
                                          this.activeDocCompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        }
                                        //Doc Incomplete
                                        if( graphStartMonthmenuarrayAll.data[0].feedback_stage_id ==='26'){
                                          this.activeDocIncompleteGraph.push({ docReceived:  graphStartMonthmenuarrayAll.data[0].feedback_stage_id}); 
                                          
                                        } 
                              
                                  }
                                }
                                  this.DashboardService.getInActiveApplications(graphinactive).subscribe((resultInInactive: any) => {
                                    if(resultInInactive.status===true &&  resultInInactive.response=== 200){                                    
                                      this.totalApplicationInActiveLastMonth=resultInInactive.inactive.length;                                          
                                          for (let i = 0; i < resultInInactive.inactive.length; i++) {
                                            const menuarrayInactive = resultInInactive.inactive[i];  
                                            //Cust Cancel
                                              if(menuarrayInactive.data[0].feedback_stage_id  =="14"){
                                                this.InactiveCustCancelLastMonth.push({ stage_id: menuarrayInactive.data[0].feedback_stage_id });      
                                                }
                                                //CRM Cancel
                                                if(menuarrayInactive.data[0].feedback_stage_id  ==='15'){
                                                this.inactiveCRMCancelLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                                
                                                }
                                                //CRM Decline
                                                if(menuarrayInactive.data[0].feedback_stage_id  ==='16'){
                                                this.inactiveCRMDeclineLastMonth.push({ docReceived: menuarrayInactive.data[0].feedback_stage_id }); 
                                                
                                                }
                                          }
                                        
                                          }
                  
                                          //-----------------------------------------------Graph api-----------------------------------------------------------------
                                          let  custCancel =   this.InactiveCustCancelLastMonth.length;
                                          let  crmCancel  = this.inactiveCRMCancelLastMonth.length;
                                          let  crmDecline  = this.inactiveCRMDeclineLastMonth.length;
                                          
                                          let  docReceived  = this.activeDocReceivedGraph.length;
                                          let  docPendin  = this.activeDocPendingGrap.length;
                                          let  transfer  = this.activeTransferGraph.length;
                                          let  lenderProcess  =this.activeLenderProcessGraph.length;
                                          let  disbursed  =this.activeDisbursedGraph.length;
                                          let  pendingReview  =this.activePendingReviewGraph.length;
                                          let  callPending  =this.activeCallPendingGraph.length;
                                          let  callComplete  =this.activeCallCompleteGraph.length;
                                          let  docIncomplete  =this.activeDocIncompleteGraph.length;
                                          
                                          
                                        // console.log(docReceived);
                                            let cCancel =custCancel;
                                            let crm =crmCancel;              
                                            let decline =crmDecline;
                                            let docrec =docReceived;
                                            let docpend =docPendin;
                                            let trans =transfer;
                                            let lenderProc =lenderProcess;
                                            let disb =disbursed;
                                            let pendingRev =pendingReview;
                                            let callPend =callPending;
                                            let callComp =callComplete;
                                            let docIncomp =docIncomplete;
                  
                                            
                                      this.cust={   allleadArray: [
                                        { y: cCancel, name: "Cust Cancel" , total: cCancel},
                                        { y: crm, name: "CRM Cancel",total: crm },
                                        { y: decline, name: "CRM Decline",total: decline },
                                        { y: docrec, name: "Doc Received",total: docrec },
                                        { y: docpend, name: "Doc Pending",total: docpend },
                                        { y: trans, name: "Transfer",total: trans },
                                        { y: lenderProc, name: "Lender Process",total: lenderProc },                                                  
                                        { y: disb, name: "Disbursed",total: disb },
                                      { y:pendingRev , name: "Pending Review",total: pendingRev    },
                                      { y:callPend , name: "Call Pending",total: callPend    },
                                      { y:callComp , name: "Call Complete",total: callComp    },
                                      { y:docIncomp , name: "Doc Incomplete",total: docIncomp    },
                                        
                                      ]
                                  }
        
        
                                  for (let i = 0; i < this.cust['allleadArray'].length; i++) {
                                    const menuarrayActive = this.cust['allleadArray'][i];
                                    if(menuarrayActive.y !=0){
                                    // console.log(menuarrayActive);
                                    // this.mainaaary =menuarrayActive;
                                      this.mainaaary.push(
                                        {
                                          y: menuarrayActive.y,
                                          name: menuarrayActive.name, 
                                          total: menuarrayActive.total, 
                                                      
                                        });
                                    }  
                                  
                                  }
                                            
                                                                    //Canvas 
                                                                    let charts = new CanvasJS.Chart("inactive", {
                                                                      theme: "light",
                                                                      animationEnabled: true,
                                                                      ///exportEnabled: false,
                                                                      title:{text: "All Leads - Stages"},
                                                                      data: [{
                                                                        type: "pie",
                                                                        showInLegend: true,
                                                                        toolTipContent: "<b>{name}</b>: {total}",
                                                                        indexLabel: "{name} - #percent%",
                                                                    
                                                                        dataPoints: this.mainaaary
                                                                        
                                                                      }]
                                                                    });
                                                                      
                                                                    charts.render();
                  
                                                                    
                                                                  
                        
                          let cucancle =custCancel;
                          let crmCan =crmCancel;
                          let crmDec =crmDecline;
                          let inactiveData=  { inactive: [
                            { y: cucancle, name: "Cust Cancel" ,total: cucancle},
                            { y: crmCan, name: "CRM Cancel",total: crmCan },
                            { y: crmDec, name: "CRM Decline",total: crmDec },
                            
                            ]}
                            for (let i = 0; i < inactiveData['inactive'].length; i++) {
                            const menuarrayInactive = this.cust['allleadArray'][i];
                            if(menuarrayInactive.y !=0){
                            this.mainaaaryInactive.push(
                            {
                            y: menuarrayInactive.y,
                            name: menuarrayInactive.name, 
                            total: menuarrayInactive.total, 
                                        
                            });
                            }
                            }
                          //Canvas 
                          let chart = new CanvasJS.Chart("chartContainer", {
                            theme: "light",
                            animationEnabled: true,
                            ///exportEnabled: false,
                            title:{text: "All Leads InActive-  Status"},
                            data: [{
                              type: "pie",
                              showInLegend: true,
                              toolTipContent: "<b>{name}</b>: {total}",
                              indexLabel: "{name} - #percent%",
                          
                              dataPoints: this.mainaaaryInactive
                              
                            }]
                          });
                            
                          chart.render();
                  
                  
                                        });
                  
                                    });
                












        }else{
          alert('Date is '+ enddate+ 'greater than or equal to From date is'+fromdate+ 'And To date is '+ todate)
      
        }



      

    








      
      }
      tabledatashow(event) {
        console.log("ok");
      }
    //user Details
    getLeadDetails(a,b,c){
      console.log(a);
      console.log(b);
      console.log(c);
      let FirstDaydate=a;
      let  currentdate=b;
      let active =c;
      const base_url=location.origin+"/#/MeraEMI-selfEmp-list?data="; 
      const detailsurl = base_url +"&from="+FirstDaydate.toString();
      const dateur=detailsurl+"&to="+currentdate.toString();
      const urlstring=dateur+"&Stage="+active.toString();
      window.open(urlstring, "_blank");
    

    }
    maindata(){
      console.log('working on');
    }
    //user Details
    selfEmpUserDetailsPage(user){
     
     // console.log(user); return false;
       let stage=  user.meraemistage;
       if(user.usersate !=undefined ){
        let userstage=user.usersate;

         let useid = this.cryptojsService.encryptData((user.user_id));       
         const base_url=location.origin+"/#/MeraEMI-Self-Emp-Leads-Details?data=";        
         const detailsurl = base_url + useid.toString();
 
         const dateur=detailsurl+"&stage="+stage.toString()+"&userstage="+userstage.toString();
        
         //console.log(detailsurl);
         window.open(dateur, "_blank");
       }else{
       // console.log(user.usersatge);
       let useid = this.cryptojsService.encryptData((user.user_id));       
        const base_url=location.origin+"/#/MeraEMI-Self-Emp-Leads-Details?data=";        
        const detailsurl = base_url + useid.toString();

        const dateur=detailsurl+"&stage="+stage.toString();
       
        //console.log(detailsurl);
        window.open(dateur, "_blank");
       }
 
     }
     ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);
  
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();

     
      this.cdRef.detectChanges();
    }
    tabledatashowshow(event) {

      let dte = new Date();
      //current day
      if(event.value ==='0'){
        this.tabledata=1;
        this.dataTableFilter=0;        
        let fromdate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        this.tableDataFromDate=fromdate;
        this.tableDataToDate=todate;
       // console.log("today  fromdate ok",this.form)
       //console.log("today  todate ok ",this.to)
        
        
     
      }else  if(event.value === '1'){
        this.dataTableFilter=0;
        this.tabledata=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let fromdatechnage =dte.setDate(dte.getDate() - dayval);
        let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
       // console.log("Yesterday  fromdate ok",fromdate)
       //console.log("Yesterday  todate ok",todate)
        this.tableDataFromDate = fromdate;
        this.tableDataToDate   = todate;        //Previous 7 Day
      }else  if(event.value === '7'){
        this.dataTableFilter=0;
        this.tabledata=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.tableDataFromDate=(fromdate);
         this.tableDataToDate=(todate);
        //console.log("Last 7 days  fromdate ok",fromdate)
       // console.log("Last 7 days  todateok",todate)
    
    
    
         
      }else  if(event.value === '30'){
        this.dataTableFilter=0;
        this.tabledata=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.tableDataFromDate=(fromdate);
         this.tableDataToDate=(todate);
       //  console.log("Last 30 days  fromdate",fromdate)
       //  console.log("Last 30 days  todate",todate)
         
             //this month record
      }else if(event.value === 'thismonth'){
        this.dataTableFilter=0;
        this.tabledata=1;
        let date = new Date();
        //month first date   
        var ourDate = new Date();
        let firstDays = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // adjust 0 before single digit date
        let datesFirstDay = ("0" + firstDays.getDate()).slice(-2);
        // current month
        let monthFirstDay = ("0" + (firstDays.getMonth() + 1)).slice(-2);
        // current year
        let yearFirstDay = firstDays.getFullYear();
        this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
        
    
    
    
    
          //month current day
        
          
          let dates = ("0" + date.getDate()).slice(-2);
          // current month
          let month = ("0" + (date.getMonth() + 1)).slice(-2);
          // current year
          let year = date.getFullYear();
          this.currentdate =(year + "-" + month + "-" + dates);
     
    
        
         this.tableDataFromDate=this.FirstDaydate;
         this.tableDataToDate=this.currentdate;
    
    
       //  console.log("this Month  fromdate",this.form)
       //  console.log("this Month  todate",this.to)
    
    
    
        
      }else if(event.value === 'lastmonth'){
        this.dataTableFilter=0;
        this.tabledata=1;
      // alert('ok');
        let date = new Date();
        let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
        // current month
        let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontYear = lstmontfirstday.getFullYear();
        this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
       
    
        let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
        let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
        // current month
        let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontEndYear = lstmontLasttday.getFullYear();
        this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
       
     
    
        
         this.tableDataFromDate=this.lastmontStartDate;
         this.tableDataToDate=this.lastmontEndDate;
    
         console.log("Last Month  fromdate",this.form)
         console.log("Last Month  todate",this.to)
    
    
    
    
    
      }else if(event.value === 'dateTable'){
        let dayval =event.value;
        this.dataTableFilter=1;
        this.activedate=0;
        console.log(dayval);
       //Previous month all data
      }else if(event.value === '60'){
                                          this.tabledata=1;
                                          this.dataTableFilter=0;  
                                         
                                          let date = new Date();
    
                                          let privfrom =dte.setDate(dte.getDate() - 60);
                                          let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
                                          //back month date 
    
                                          let dates = ("0" + date.getDate()).slice(-2);
                                          // current month
                                          let month = ("0" + (date.getMonth() + 1)).slice(-2);
                                          // current year
                                          let year = date.getFullYear();
                                          let currentdate =(year + "-" + month + "-" + dates);
                                        
                                          this.tableDataFromDate=privfromdate;
                                          this.tableDataToDate=currentdate;
                                         // console.log("today  fromdate",this.tableDataFromDate)
                                          //console.log("today  todate",this.tableDataToDate)
      }
    
      
     //console.log("today  fromdate",this.tableDataFromDate)
     //console.log("today  todate",this.tableDataToDate)
     this.elementsdate=[];
     this.totalusercount=0;
     const data = this.maindataFilter;
    // console.log("main data",data)       
     const  fromdate = this.tableDataFromDate;
     const  todate = this.tableDataToDate;
     this.results = data.filter(d => {
       const  reg_date= d.date;
       const regdate = reg_date.split(' ')[0];
       //return (fromdate  <= regdate && regdate  <=  todate );            
       return (regdate >= fromdate  && regdate  <=  todate );
       
     });
     if(this.results.length > 0){
       this.tabledata=1;
       for (let i = 0; i < this.results.length; i++) {
         const element = this.results[i];
     
        
     
         this.elementsdate.push(
           {
             user_id: element.user_id, 
             name: element.name,
             lendername: element.lendername,
             loan_amount: element.loan_amount,
             meraemistage: element.meraemistage,
             usersate: element.usersate,
             date: element.date,
             admin: element.admin ,
             crmdate: element.crmdate ,
           
           }
           );
           this.totalusercount=this.elementsdate.length;
        // console.log(this.elementsdate);
         this.mdbTable.setDataSource(this.elementsdate);
         this.elements = this.mdbTable.getDataSource();
         this.previous = this.mdbTable.getDataSource();
       }
     }else{
      this.datanotfound=1;
      console.log("ok");
     }
    
                   
    
     }
     facebookAdFilter(event) {
      this.adfiderday=null;
      this.elementsdatefacebookAd=[];
      this.elementsdatefacebookAdPAUSED=[];
      this.adDateFilter=event.value;
      this.elementsdatefacebookAd.length=0;
   //  this.fbDatafilter
      const mainarray= this.elementsdatefacebookAdFilter;
      for (let i = 0; i < mainarray.length; i++) {
        const menuarrayInactive = mainarray[i];
        if(menuarrayInactive.day ==  this.adDateFilter  && menuarrayInactive.fb_status=="ACTIVE"){
          this.elementsdatefacebookAd.push(
            {
              fb_ads_account_id : menuarrayInactive.fb_ads_account_id,
              fb_adset_acc_id : menuarrayInactive.fb_adset_acc_id, 
              
              fb_ads_account_name: menuarrayInactive.fb_ads_account_name,
               
              fb_ad_set_id: menuarrayInactive.fb_ad_set_id, 
                
               
                      
               
              
              fb_reach : menuarrayInactive.fb_reach, 
              fb_status : menuarrayInactive.fb_status, 
              fb_impressions : menuarrayInactive.fb_impressions, 
              fb_cpm_cost : menuarrayInactive.fb_cpm_cost,   
              fb_linc_clicks : menuarrayInactive.fb_linc_clicks, 
              fb_campain_budget: menuarrayInactive.fb_campain_budget,
              fb_cost_per_result: menuarrayInactive.fb_cost_per_result,
              day: menuarrayInactive.day,
              
              view:  menuarrayInactive.view,
              costPerClicks:menuarrayInactive.costPerClicks,
              costPerPageViews:menuarrayInactive.costPerPageViews,
              eligp: menuarrayInactive.eligp,
              eligc:menuarrayInactive.eligc,
              costPer_ep:menuarrayInactive.costPer_ep,
              costPer_ec:menuarrayInactive.costPer_ec,
              docR:menuarrayInactive.docR,
              docP:menuarrayInactive.docP,
              
              
              
            }
            );
        
        }else  if(menuarrayInactive.day ==  this.adDateFilter  && menuarrayInactive.fb_status=="PAUSED"){
          this.elementsdatefacebookAdPAUSED.push(
            {
              fb_ads_account_id : menuarrayInactive.fb_ads_account_id,
              fb_adset_acc_id : menuarrayInactive.fb_adset_acc_id, 
              
              fb_ads_account_name: menuarrayInactive.fb_ads_account_name,
               
              fb_ad_set_id: menuarrayInactive.fb_ad_set_id, 
                
               
                      
               
              
              fb_reach : menuarrayInactive.fb_reach, 
              fb_status : menuarrayInactive.fb_status, 
              fb_impressions : menuarrayInactive.fb_impressions, 
              fb_cpm_cost : menuarrayInactive.fb_cpm_cost,   
              fb_linc_clicks : menuarrayInactive.fb_linc_clicks, 
              fb_campain_budget: menuarrayInactive.fb_campain_budget,
              fb_cost_per_result: menuarrayInactive.fb_cost_per_result,
              day: menuarrayInactive.day,
              
              view:  menuarrayInactive.view,
              costPerClicks:menuarrayInactive.costPerClicks,
              costPerPageViews:menuarrayInactive.costPerPageViews,
              eligp: menuarrayInactive.eligp,
              eligc:menuarrayInactive.eligc,
              costPer_ep:menuarrayInactive.costPer_ep,
              costPer_ec:menuarrayInactive.costPer_ec,
              docR:menuarrayInactive.docR,
              docP:menuarrayInactive.docP,
              
              
              
            }
            );
        
        }
        
      }
      this.activedataTotal=this.elementsdatefacebookAd.length;
      this.filterday.push({
        filter: this.adDateFilter
       })
       this.adfiderday=this.filterday[0].filter;
       //console.log();
     }
     filterdatatableData(){
      this.dataTableFilter=0;
      let startDate = new Date(this.maindatafilter.value.datefroms);
      let endDate = new Date(this.maindatafilter.value.datetos);
       //previous month yaear day  Start Date
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
// console.log(differenceInDays); 
  const currentDates = new Date();
  const pastDate = new Date(this.filterchart.value.datefrom);
  let backdayStartDay=new Date(pastDate.setDate(startDate.getDate() - 30));

  let FirstDayBackMonth = ("0" + backdayStartDay.getDate()).slice(-2);
  // current month
  let monthFirstDayBackMonth = ("0" + (backdayStartDay.getMonth() + 1)).slice(-2);
  // current year
  let yearBackMonth = backdayStartDay.getFullYear();
  let privStartdates =(yearBackMonth + "-" + monthFirstDayBackMonth + "-" + FirstDayBackMonth);
  //console.log('previous month yaear day  Start Date' + privStartdates);

  //Filter day previous month yaear day stop date
  const currentDatess =new Date(this.filterchart.value.datefrom);
  const previousDay =new Date(this.filterchart.value.datefrom);
  let backdayStopDay= new Date(previousDay.setDate(currentDatess.getDate() - 1));
  let FirstDaybackdayStop = ("0" + backdayStopDay.getDate()).slice(-2);
  // current month
  let monthFirstDaybackdayStop = ("0" + (backdayStopDay.getMonth() + 1)).slice(-2);
  // current year
  let yearBackbackdayStop= backdayStopDay.getFullYear();
  let previousDayStop =(yearBackbackdayStop + "-" + monthFirstDaybackdayStop + "-" + FirstDaybackdayStop);

    this.tableDataFromDate=formatDate(startDate, 'yyyy-MM-dd', 'en-US');
    this.tableDataToDate=formatDate(endDate, 'yyyy-MM-dd', 'en-US');

    this.elementsdate=[];
    this.totalusercount=0;
    const data = this.maindataFilter;

    const  fromdate = this.tableDataFromDate;
    const  todate = this.tableDataToDate;
    this.results = data.filter(d => {
      const  reg_date= d.date;
      const regdate = reg_date.split(' ')[0];
      //return (fromdate  <= regdate && regdate  <=  todate );            
      return (regdate >= fromdate  && regdate  <=  todate );
      
    });
    if(this.results.length > 0){
      this.tabledata=1;
      for (let i = 0; i < this.results.length; i++) {
        const element = this.results[i];
    
       
    
        this.elementsdate.push(
          {
            user_id: element.user_id, 
            name: element.name,
            lendername: element.lendername,
            loan_amount: element.loan_amount,
            meraemistage: element.meraemistage,
            usersate: element.usersate,
            date: element.date,
            admin: element.admin ,
            crmdate: element.crmdate ,
          
          }
          );
          this.totalusercount=this.elementsdate.length;
       // console.log(this.elementsdate);
        this.mdbTable.setDataSource(this.elementsdate);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      }
    }else{
     this.datanotfound=1;
     console.log("ok");
    }
    
     }
     datafilter(){
      this.dataTableFilter=0;
  
      
     }

}