import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(list: any[], filterText: string,dateString:string): any {

        if(filterText) { 
          filterText = filterText.toLowerCase()
         
          return  list ? list.filter(it=>{  

            for (let index = 0; index < it.loans.length; index++) {
              const element = it.loans[index];

              const fId =  element.loan_code.toString().toLowerCase().includes(filterText);
              const product =  element.product_name.toLowerCase().search(new RegExp(filterText, 'i')) > -1;
              const city =  element.city_name.toLowerCase().search(new RegExp(filterText, 'i')) > -1;
              let stage;

              let elig_result =new RegExp(filterText, 'gi').test('eligibility');
              let lead_result = new RegExp(filterText, 'gi').test('lead');
             
              if(elig_result){
                const st  = '1';
                stage =element.loan_status.app_stage.search(new RegExp(st, 'i')) > -1;
                return (fId + product + city + stage );

              }else if(lead_result){
                const st  = '0';
                stage =element.loan_status.app_stage.search(new RegExp(st, 'i')) > -1;
                return (fId + product + city + stage );
                
              }
               return (fId + product + city  );
            }
              
          }) : [];
        }else if(dateString){
          return  list ? list.filter(it=>{  

            for (let index = 0; index < it.loans.length; index++) {
              const element = it.loans[index];
              var rightNow = new Date(element.created_on); 
               var date = rightNow.toISOString().slice(0,10).replace(/-/g,"-").search(new RegExp(dateString, 'i')) > -1;
              console.log(date)
               return (date);
            }
              
          }) : [];
        }else{
          return  list;
        }

    

  
  }

}
