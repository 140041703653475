import { Component, OnInit, ViewChild } from '@angular/core';
import { ClockPickerComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('timePicker', { static: true }) timePicker: ClockPickerComponent;
  darkClock: any;
  onClear() {
    this.timePicker.clearTimeInput();
    this.darkClock = null;
  }
  constructor() { }

  ngOnInit() {
  }

}
