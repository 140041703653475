import { MdbTablePaginationComponent, MdbTableDirective} from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild,  AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';


export interface Color {
  name: string;
}
@Component({
    selector: 'app-meraemi-contact-request',
    templateUrl: './meraemi-contact-request.component.html',
    styleUrls: ['./meraemi-contact-request.component.scss']
})
export class MeraemiContactRequestComponent  implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  elements: any = [];
  previous: any = [];
 
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id"
     },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
       name:"Loan Amount",
       sortBy:"Amount"
     },
     
     {
       name:"Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     {
       name:"View",
       sortBy:"send_message"
     },

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string; 
  submitted = false;
  
  showLoader: boolean;
  useid: any;
  urlstage: string;
 
 
 

  constructor( 
      private cryptojsService:CryptojsService,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private FeedbackService:FeedbackService) { }

  ngOnInit() { 
    this.FeedbackService
    .GetContactRequest()
    .subscribe( (result:any) => {  
      console.log(result);
            if (result.status = 'true' && result.response===200) {
              for (let i = 0; i < result.contact.length; i++) {
                const element = result.contact[i];
               
                this.stage_color = "info-color";
           
              //console.log(element);
            this.stage_color = "info-color";
            
            if(element.data[0].app_stage == 0){
            // this.stage ='Lead';
             this.appstage ='Eligibility Pending';
             this.stage_color = "info-color";
             this.urlstage ='Eligibility_Pending';

         
            }else if(element.data[0].app_stage == '1'){
              
               if(element.data[0].elig_status == "1"){
                 this.elig_status ='Eligibility Clear';
                 this.urlstage ='Eligibility_Clear';
                 this.stage_color = "info-color";
               }else if(element.data[0].elig_status == "0"){
                 this.elig_status ='Eligibility Fail';
                 this.urlstage ='Eligibility_fail';
                 this.stage_color = "info-color";
               }else{
                 this.elig_status ='Eligibility Pending';
                 this.urlstage ='Eligibility_Pending';
                 this.stage_color = "info-color";
               }
              // console.log( this.elig_status)
               this.appstage = this.elig_status;
         
           }else if(element.data[0].app_stage == 2){
             
               this.appstage ="IAP Pending";
               this.urlstage ='IAP_Pending';
               this.stage_color = "info-color";
         
           }else if(element.data[0].app_stage == 3){
              if(element.data[0].trx_status != undefined){
              
               if(element.data[0].sfdc_id != "null" || element.data[0].trx_status == 'DDE' || element.data[0].trx_status == 'QDE'){
              
                 this.appstage ="IAP Complete";
                 this.urlstage ='IAP_Complete';
                 this.stage_color = "info-color";
               }if(element.data[0].sfdc_id == "null" || element.data[0].trx_status == 'Cancel'){
               
                 this.appstage ="IAP Error";
                 this.urlstage ='IAP_Error';
                 //console.log(this.stage);
                 this.stage_color = "info-color";
               } if(element.data[0].trx_status == "Reject"){
              
                 this.appstage ="IAP Fail";
                 this.urlstage ='IAP_Fail';
                 this.stage_color = "info-color";
             
               }
               if(element.data[0].trx_status == 'Data Verification'){
                 this.appstage = "IAP Success";
                 this.urlstage ='IAP_Success';
               }
            
             }else {

               this.appstage ="IAP Complete";
               this.urlstage ='IAP_Complete';
               this.stage_color = "info-color";
             }
          
             if(element.data[0].resp_status_code != undefined){
         
               if(element.data[0].resp_status_code == "1" ){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                }
                if(element.data[0].resp_status_code == "0" || element.data[0].resp_status_code == "3" || element.data[0].resp_status_code == "4"){
                  this.appstage ="IAP Fail";
                  this.urlstage ='IAP_Fail';
                }
              }
             
             
           }else if(element.data[0].app_stage == 4){
             this.appstage = 'Document Upload';
             this.stage_color = "info-color";
           }else if(element.data[0].app_stage == 5){
             this.appstage = 'Meeting Scheduler';
             this.stage_color = "info-color";
           }else if(element.data[0].app_stage == 6){
             this.appstage = 'Success';
             this.urlstage ='Success';
             this.stage_color = "info-color";
           }else{
             this.appstage = '';
           }
           if(element.data[0].provider_id == "BOT MESSENGER"){
             this.provider = 'BOT';
             
           }else{
             this.provider = 'WEB';
           }
                this.elements.push(
                  {
                    user_id: element.data[0].user_id, 
                    first_name:  element.data[0].first_name, 
                    last_name:  element.data[0].last_name,              
                    provider: this.provider,
                    city_name: element.data[0].city_name,
                    loan_amount: element.data[0].loan_amount,
                    ad_id: element.data[0].ad_id,
                    reg_date: element.data[0].date,
                    status: this.appstage,
                    meraemistage: element.data[0].stage,
                   
                    admin: element.data[0].admin_name,
                    feedbackstage:this.urlstage
                  }
                  );
            
               
                  
                  
            }
                         //console.log(this.elements);
                         this.mdbTable.setDataSource(this.elements);
                          this.elements = this.mdbTable.getDataSource();
                          this.previous = this.mdbTable.getDataSource();
                        
              
            }
            
          });
         
    
         
  
  }
 
 

  canclebutton(){    
    this.showLoader = false;
   
  setTimeout(() => {  
    this.showLoader = true;

    location.reload();
  }, 2000);
  }
 
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(2000);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  


  //user details page next tab open
  
  getLeadDetails(user,data){  
    let stage=  data;
   // console.log(data);
   this.useid = this.cryptojsService.encryptData((user.user_id));       
   const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
   const detailsurl = base_url + this.useid.toString();
   const dateur=detailsurl+"&stage="+stage.toString();
   window.open(dateur, "_blank"); }
   backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/meraemi-dashboard']);
    }, 2000);
   
    
    }
    rotate(event){
        event.srcElement.classList.remove("rotate");
        event.srcElement.classList.remove("tooltiptext");
        setTimeout(()=>{
          event.srcElement.classList.add("rotate");
          event.srcElement.classList.remove("tooltiptext");
        location.reload();
        },0)
      }

}