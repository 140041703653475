import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { FacebookAdService } from 'src/app/services/facebook-ad/facebook-ad.service';

@Component({
  selector: 'app-mera-emi-facebook-ad',
  templateUrl: './mera-emi-facebook-ad.component.html',
  styleUrls: ['./mera-emi-facebook-ad.component.scss']
})
export class MeraEMIFacebookAdComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  registerForm: FormGroup;
  addmanager:any=[];
  previous: any = [];
  elements: any= [];
  fbAccountid: any= [];
  constructor(
    private facebookAdService:FacebookAdService,
    private formBuilder: FormBuilder,private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fbAccountid.push(
      {
      value: "2391968120930379",
      label: "PL",
      },
      {
        value: "1168646473316558",
        label: "MK",
        }
            
              
        
      );
    this.registerForm = this.formBuilder.group({			
      maindatafilter: this.formBuilder.group({	 
        accountid : ['', Validators.required],
        ad_set_name: ['', Validators.required],
        adset_id: ['', Validators.required],     
        ad_id: ['', Validators.required],
        
        
      
      })
      });
      this.facebookAdService
      .getAllFBAd()
      .subscribe( (result:any) => {
     
      
        if (result.status = true  && result.response=== 200) {              
          for (let i = 0; i < result.fbad.length;  i++) {
            const array = result.fbad[i];
            this.addmanager.push(
              {
                
                fb_adset_acc_id : array.data[0].fb_adset_acc_id,  
                fb_adset_name: array.data[0].fb_adset_name,
                fb_adset_id: array.data[0].fb_adset_id,  
                          
                fb_ad_name : array.data[0].fb_ad_name,  
                      
                status : array.data[0].status,   
                
                created_on : array.data[0].created_on,   
              }
              );

          }
          console.log(this.addmanager);
          this.mdbTable.setDataSource(this.addmanager);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
        }
          });
  }
  dateSubmitted: boolean = false;	
  // Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('maindatafilter')).controls; }

  get maindatafilter(){
	return this.registerForm.get('maindatafilter');
    }
    maindata(){
     this.dateSubmitted=true;
    // console.log(this.maindatafilter.value);   //   return false; 
     let adset_acc_id=  this.maindatafilter.value.accountid;
    let adset_name=  this.maindatafilter.value.ad_set_name;
    let adset_id=  this.maindatafilter.value.adset_id;
    let ad_name=  this.maindatafilter.value.ad_id;
   
    let details = {
                    
      results: [{
        "adset_acc_id": adset_acc_id,
        "adset_name":adset_name,            
        "adset_id":adset_id,
        "ad_name":ad_name, 
        "stage":"1",
        
      }]
  
    
  };
  this.addmanager=[]
// console.log(details);     // return false;     
  details['results']; 
  this.facebookAdService.InsertCreateAdAccount(details)
  .subscribe((result: any) => { 

    if(result.response==200 && result.status== true){
      this.facebookAdService
      .getAllFBAd()
      .subscribe( (result:any) => {
     
      
        if (result.status = true  && result.response=== 200) {      //        
          for (let i = 0; i < result.fbad.length;  i++) {
            const array = result.fbad[i];
            this.addmanager.push(
              {
                
                fb_adset_acc_id : array.data[0].fb_adset_acc_id,  
                fb_adset_name: array.data[0].fb_adset_name,
                fb_adset_id: array.data[0].fb_adset_id,  
                          
                fb_ad_name : array.data[0].fb_ad_name,  
                       
                status : array.data[0].status,   
                
                created_on : array.data[0].created_on,   
              }
              );

          }
          console.log(this.addmanager);
          this.mdbTable.setDataSource(this.addmanager);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
        }
          });
    }else{
      console.log(result);
    }
        
  });
   
  

      

    }
    ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);
  
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    }
}
