import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Leads } from 'src/app/model/admin/leads.model';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  message: any;
  //bearer_token:string = "Bearer 292252f6-1c85-4c79-b869-0d3328c333e9";
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;

  getUserCampaignList(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'Campaign/GetUserCampaignList',{headers:headers});
  }
  updateScheduleDate(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'Campaign/updateScheduleDate',{data:data},{headers: headers});
  }


}
