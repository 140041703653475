/*app.component.ts*/
import { CollapseComponent } from 'ng-uikit-pro-standard';

import { Component, AfterViewInit, ViewChildren, OnInit } from '@angular/core';
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
@Component({
    selector: 'app-meraemi-dashboard',
    templateUrl: './meraemi-dashboard.component.html',
    styleUrls: ['./meraemi-dashboard.component.scss']
})
 
export class MeraemiDashboardComponent implements OnInit {
	@ViewChildren(CollapseComponent) collapses: CollapseComponent[];
	optionsadid: any = []; 
	All_ad_id: any = [];
	cityname: any = [];
	optionslocation: any = [];
	optionslogdate
	appstage: string;
	elig_status: string;
	elements: any = [];
	elements_eliPend: any = [];
	mainarray: any;
	countIAPPending: any = [];
	countIAPSuccess: any = [];
	countIAPFail: any = [];
	countIAPComplete: any = [];
	countEligibilityFail: any = [];
	countLoanamt: any = [];
	countEligibilityClear:any = [];
	chart:any = [];
	totalLoanamt: number=0;
	totalEligibilityClear: number=0;
	totalEligibilityFail: number=0;
	totalIAPPending: number=0;
	totalIAPSuccess: number=0;
	totalIAPFail: number=0;
	totalIAPComplete: number=0;
	CountIAPCP: number=0;
	TotaltIAPCP: number=0;
	CountIAPFail: number=0;
	TotaltIAPFail: number=0;
	CountIAPSuss: number=0;
	TotaltIAPSuss: number=0;
	TotaltIAPPend: number=0;
	CountIAPPend: number=0;
	CountEligibilityClear: number=0;
	TotalEligibilityClear: number=0;
	CountEligibilityPen: number=0;
	TotalEligibilityPend: number=0;
	CountEligibilityFail: number=0;
	TotaltEligibilityFail: number=0;
	CountEligFail: number=0;
	TotaltEligFail: number=0;
	myObjArray:any = [];
	registerForm: FormGroup;
	adid: any;
	location: any;
	dateto: any;
	datefrom: any;
	total: number=0;
	totalcount: number=0;
	countLoanamt1: any = [];
	totalLoanamt1: number=0;
    totalEligibilityClear1: number=0;									
	countEligibilityClear1: any = []; 
	totalEligibilityFail1: number=0;	
	countEligibilityFail1: any = []; 
	totalIAPPending1: number=0;									
	countIAPPending1: any = []; 
    totalIAPSuccess1: number=0;									
	countIAPSuccess1: any = []; 
	totalIAPFail1: number=0;									
	countIAPFail1: any = []; 
	totalIAPComplete1: number=0;										
	countIAPComplete1: any = []; 
	
	totalIAPError: number=0;
	countIAPError: any = []; 
	
	appStage: any;
	filter: any;
	filterStage: any;
	aadkey: string;
	filterCityname: any;
	adidarray: any=[];
	main_array: any=[];
	date_from: any;
	date_to: any;
	cityarray: any=[];
	result: any;	
	ToandFromDate: string;
	fulldate: string;	
	currentdate: string;
	FirstDaydate: string;
	result1: any;
	aad_key: string;
	adDate: string;
	datemainArry: any=[];
	datemain_Arry: any=[];
	showLoader: boolean;
	router: any;
	
	EligibilityDropoffsPercentage: number=0;
	EligibilityCheckedPercentage: number=0;
	tatalelgEligibility: number=0;

	EligbilityFail: number=0;
	EligibilitySuccess: number=0;
	totalElgFailSucc: number=0;
	EligibilitySuccessDropoff: number=0;
	IAPTotal: number=0;
	IAPTotalPercentage: number=0;
	IAPPending: number=0;
	IAPAPICalled: number=0;
	IAPTotalCount: number=0;
	IAPAPIError: number=0;
	IAPAPISuccess: number=0;
	IAPAPICalledCount: number=0;
	IAPFail: number=0;
	IAPClear: number=0;
	
	TotalelgDropoffs: number=0;
	TotalelgCheckeds: number=0;
	TotalEliFail: number=0;
	TotalEligSuccess: number=0;
	TotalElilSuccDropoff: number=0;
	TotalIAPTotal: number=0;
	TotalIAPPending: number=0;
	IapApiCalled: number=0;
	TotalIapApiError: number=0;
	TotalIapAaiSuccess: number=0;
	TotalIAPFail: number=0;
	TotalIAPClear: number=0;
	TotalEligibilityPenCont: number=0;
	TotalEligibilityPenContChecked: number=0;
	TotalEligbilityFailCount: number=0;
	TotalEligibilitySuccessCount: number=0;
	TotalEligibilitySuccessDropoffCount: number=0;
	TotalIAPTotalCount: number=0;
	TotalIAPPendingCount: number=0;
	EligibilityDropoffsArray: any=[];
	EligibilityCheckedArray: any=[];
	EligibilityFailArray: any=[];
	EligibilitySuccessArray: any=[];
	EligibilitySuccessDropoffArray:any=[];
	IAPTotalffArray :any=[];
	IAPPendingArray :any=[];
	IAPAPICalledArray:any=[];
	IAPAPIErrorArray:any=[];
	IAPAPISuccessArray: any=[];
	IAPFailArray: any=[];
	IAPClearArray: any=[];
	CountIAPError: number=0;
	TotalIAPError: number=0;
	iapstage: number;
	meraemistage: string;
//crm stage new ad

countIAPDocp: any = [];
totalIAPDocP: number=0;
TotatalDocp: number=0;
CounttotalDocP: number=0;

countIAPDocR: any = [];
totalIAPDocR: number=0;
CounttotalDocR: number=0;
TotatalDocR: number=0;
//
countIAPDocC: any = [];
CounttotalDocC: number=0;
TotatalDocC: number=0;
totalIAPDocC: number=0;
//
countIAPDocIn: any = [];
CounttotalDocIn: number=0;
TotatalDocIn: number=0;
totalIAPDocIn: number=0;
//
countIAPDocPenR: any = [];
CounttotalDocPenR: number=0;
TotatalDocPenR: number=0;
totalIAPDocPenR: number=0;
//
countIAPDocTrans: any = [];
CounttotalDocTrans: number=0;
TotatalDocTrans: number=0;
totalIAPDocTrans: number=0;
//
countIAPDocLendPr: any = [];
CounttotalDocLendPr: number=0;
TotatalDocLendPr: number=0;
totalIAPDocLendPr: number=0;
//
countIAPDocCrmC: any = [];
CounttotalDocCrmC: number=0;
TotatalDocCrmC: number=0;
totalIAPDocCrmC: number=0;
//

countIAPDocCustC: any = [];
CounttotalDocCustC: number=0;
TotatalDocCustC: number=0;
totalIAPDocCustC: number=0;
//
countIAPDocCrmD: any = [];
CounttotalDocCrmD: number=0;
TotatalDocCrmD: number=0;
totalIAPDocCrmD: number=0;
//
countIAPDocDisb: any = [];
CounttotalDocDisb: number=0;
TotatalDocDisb: number=0;
totalIAPDocDisb: number=0;

tataolcrmArray:any=[];
tatalDocPendArray:any=[];
tatalDocRecArray:any=[];
tatalDocCampArray:any=[];
tatalDocIncArray:any=[];
tatalDocPendRArray:any=[];
tatalDocTransRArray:any=[];
tatalDocLenderPrArray:any=[];
tatalDocCrmCansArray:any=[];
tatalDocCustCanArray:any=[];
tatalCrmDanArray:any=[];
tatalDisbuArray:any=[];
tatalcrmstageAmt: number=0;
totalcrmstagecount: number=0;
	constructor(  private formBuilder: FormBuilder, private cryptojsService:CryptojsService,
		private leadsService:LeadsService,  private DashboardService:DashboardService,) { }
	
		  
	ngOnInit() {
		    var ourDate = new Date();
		
			let formatPrevMonth = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
			//console.log(formatPrevMonth);
			//let month1= new Date().getMonth();
			//let prevMonth = ourDate.setMonth(month1 - 1)
			//let formatPrevMonth = new Date(ourDate.setMonth(month1 - 1));
			
		
			//Change it so that it is 7 days in the past.
			var pastDate = ourDate.getDate() - 7;
			ourDate.setDate(pastDate);
			let lastseveDay=ourDate;
			//Log the date to our web console.
			//console.log(lastseveDay);
		let date = new Date();
		//console.log(date);
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//console.log(this.currentdate); 
		let firstDay = new Date(formatPrevMonth.getFullYear(), formatPrevMonth.getMonth(), 1);
		// adjust 0 before single digit date
		let datesFirstDay = ("0" + formatPrevMonth.getDate()).slice(-2);
		// current month
		let monthFirstDay = ("0" + (formatPrevMonth.getMonth() + 1)).slice(-2);
		// current year
		let yearFirstDay = formatPrevMonth.getFullYear();
		this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
		this.registerForm = this.formBuilder.group({			
			filterchart: this.formBuilder.group({
			  adid: ['', Validators.required],
			  location: ['', Validators.required],
			 datefrom: ['', Validators.required],
			  dateto: ['', Validators.required],
			}),
			
		  });
		//ad id
		this.leadsService
		.getAllAdId()
		.subscribe( (result:any) => {
		  //console.log(result);
				 if (result.status = 'true') {
				   
				   for (let i = 0; i < result.fb_adid.length; i++) {
					 const menuarray = result.fb_adid[i];
				    
					if(menuarray.data[0].ad_id !=0){
					 this.All_ad_id.push(
					   {
						value: menuarray.data[0].ad_id,
						label: menuarray.data[0].ad_id,
					   }
					   );
					   
					  }
				   
					   
				   }
				  // this.optionsadid =this.All_ad_id;
				   
				 }
				 let AllAdId = [
					{ value: "All-Adid-Select", label: "All-Adid-Select"} ];
					const AdMainArray = AllAdId.concat(this.All_ad_id);
					this.optionsadid =AdMainArray;
				  // console.log(array3);
				   
				 });
				 
			   
			   //location
			   this.leadsService
			   .getCityname()
			   .subscribe( (result:any) => {
			   // console.log(result.filter_city);
					   if (result.status = 'true') {
						 
						 for (let i = 0; i < result.filter_city.length; i++) {
						   const menuarray = result.filter_city[i];
						  //console.log(menuarray.data[0].pincode);
						  if(menuarray.data[0].location !=undefined){
						   this.cityname.push(
							 {
							   value: menuarray.data[0].location.toUpperCase(),
							   label: menuarray.data[0].location.toUpperCase(),                          
							  
							 }
							 );
							 //
							}
						 
						 }
						// this.optionslocation =this.cityname;
						 
					   }
					   let AllCity = [
						{ value: "All-city-Select", label: "All-city-Select"} ];
						const CitymainArray = AllCity.concat(this.cityname);
						this.optionslocation =CitymainArray;
					  // console.log(array3);
					   
					 });
	   
					// this.leadsService
					 //.getAllLeads()
					 this.DashboardService
					 .GetuserDashbord()
					 .subscribe( (result:any) => {
				//	console.log(result);
				if (result.status = true  && result.response=== 200) {            
					for (let i = 0; i < result.dashbord.length;  i++) {
					  const element = result.dashbord[i];
								// console.log(element);

								 if(element.data[0].app_stage  === "0"){
							
									this.elig_status ='Eligibility_Pending';
									this.iapstage =4;
									this.appstage = this.elig_status;
								  }else if(element.data[0].app_stage  === "1"){
									 if(element.data[0].elig_status =="1"){
							
									  this.elig_status ='Eligibility_Clear';
									  this.iapstage =4;
									
									 }else if(element.data[0].elig_status =="0"){
										this.elig_status ='Eligibility_Fail';
									  this.iapstage =4;
								
									 }
									 this.appstage = this.elig_status;
								   
								   }else if(element.data[0].app_stage==="2"){
								
									this.appstage ="IAP_Pending";
							
									
									this.iapstage=5;
								
							  
								}else if(element.data[0].app_stage==="3"){
									this.appstage ="IAP_Complete";
								
									this.iapstage=5;
								
								   
								}else if(element.data[0].app_stage === "4"){
									this.appstage = 'Document_Upload';
							
									
									this.iapstage=5;
								}else if(element.data[0].app_stage ==="5"){
									this.appstage = 'Meeting_Scheduler';					
									
									this.iapstage=5;
								}else if(element.data[0].app_stage === "6"){
								  this.appstage = 'Success';
								  this.iapstage=5;
								}
							
							   if(this.iapstage ===5){
								 if(element.data[0].feedback_stage !=undefined){
								  this.meraemistage=element.data[0].feedback_stage;
								 }else{
								  this.meraemistage="Pending Review";
								 }
								
							   }else{
								this.meraemistage="NA";
								
							   }
					
								

								
							  
							  //console.log(this.stage);
							  
								this.elements.push(
									{
									  user_id: element.data[0].user_id, 
									  first_name:  element.data[0].first_name, 
									  last_name:  element.data[0].last_name,           
									  city_name: element.data[0].city_name,
									  loan_amount: element.data[0].loan_amount,
									  ad_id: element.data[0].ad_id,
									  reg_date: element.data[0].date,
									  status: this.appstage,
									  meraemistage:this.meraemistage
									}
									);
							 
								
								   
								//console.log(this.elements);
								   
							 }
							 
							 this.mainarray= this.elements;
							// console.log(this.mainarray);
							 const mainarray= this.mainarray;
							// this.result1 = mainarray.filter(d => {var time = d.reg_date;
							// 	return (this.FirstDaydate <= time && time <= this.currentdate);
							// });

							//console.log(this.FirstDaydate);
							//console.log(this.currentdate);
							this.result1 = mainarray.filter(d => {
								const  reg_date= d.reg_date;
								const regdate = reg_date.split(' ')[0];
								//return (this.FirstDaydate  <= regdate && regdate  <=  this.currentdate );            
								return (reg_date >= this.FirstDaydate  && reg_date  <=  this.currentdate );
								
							  });
						    //console.log(this.result1.length);
							//console.log(this.currentdate);
							//console.log(this.result1);			 

							 this.totalLoanamt = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								const element = this.result1[i];
								if(element.status === "Eligibility_Pending"){
									
								   this.countLoanamt.push({ status: element.appstage});
								  // console.log(element);	
								}
								if(element.status == "Eligibility_Pending" && element.loan_amount != undefined){
								   this.totalLoanamt += Number(element.loan_amount); 
								}
							
								
							}	
							 this.CountEligibilityPen=this.countLoanamt.length;
							 this.TotalEligibilityPend=this.totalLoanamt;
							 
							// console.log(this.TotalEligibilityPend);


							 this.totalEligibilityClear = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "Eligibility_Clear"){
																	
									this.countEligibilityClear.push({ status: this.appstage}); 
								}
								if(element.status === "Eligibility_Clear" && element.loan_amount != undefined){
									this.totalEligibilityClear += Number(element.loan_amount);									
									
								}
								 
							 }	
							 this.CountEligibilityClear=this.countEligibilityClear.length;
							 this.TotalEligibilityClear=this.totalEligibilityClear;
							//  console.log(this.CountEligibilityClear);	 
		                    //   console.log(this.TotalEligibilityClear);
							 this.totalEligibilityFail = 0;
							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "Eligibility_Fail"){
									 
									this.countEligibilityFail.push({ status: this.appstage});
								 }
								 if(element.status === "Eligibility_Fail" && element.loan_amount != undefined){
									this.totalEligibilityFail += Number(element.loan_amount);	 
									
								 }
								 
							 }	
							 this.CountEligFail=this.countEligibilityFail.length;
							 this.TotaltEligFail=this.totalEligibilityFail;
							// console.log(this.CountEligFail);		
							// console.log(this.TotaltEligFail);	

							 this.totalIAPPending = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Pending"){
									
									this.countIAPPending.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Pending" && element.loan_amount != undefined){
									this.totalIAPPending += Number(element.loan_amount);									
								
									
								 }
								 
							 }	
							 this.CountIAPPend=this.countIAPPending.length;
							 this.TotaltIAPPend=this.totalIAPPending;
							 //console.log(this.countIAPPending.length);			 
		
							// console.log(this.totalIAPPending);
							 this.totalIAPSuccess = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Success"){
																	
									this.countIAPSuccess.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Success" && element.loan_amount != undefined){
									this.totalIAPSuccess += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							this.CountIAPSuss=this.countIAPSuccess.length;
							 this.TotaltIAPSuss=this.totalIAPSuccess;
							 //console.log(this.CountIAPSuss);
							 //console.log(this.TotaltIAPSuss);
							 this.totalIAPFail = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Fail" ){
																		
									this.countIAPFail.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Fail" && element.loan_amount != undefined){
									this.totalIAPFail += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CountIAPFail=this.countIAPFail.length;
							 this.TotaltIAPFail=this.totalIAPFail;
							 //console.log(this.countIAPFail.length);			 
		
							 //console.log(this.totalIAPFail);
							 this.totalIAPComplete = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.status === "IAP_Complete" ){
																	
									this.countIAPComplete.push({ status: this.appstage});
									
								 }
								 if(element.status === "IAP_Complete" && element.loan_amount != undefined){
									this.totalIAPComplete += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CountIAPCP=this.countIAPComplete.length;
							 this.TotaltIAPCP=this.totalIAPComplete;

                           

							 this.total =(this.TotalEligibilityPend+this.TotalEligibilityClear+this.TotaltEligFail+this.TotaltIAPPend+this.TotaltIAPSuss+this.TotaltIAPFail+this.TotaltIAPCP);
							 this.totalcount =(this.CountEligibilityPen+this.CountEligibilityClear+this.CountEligFail+this.CountIAPPend+this.CountIAPSuss+this.CountIAPFail+this.CountIAPCP);
							 //console.log(this.total);		
							 //console.log(this.totalcount);

							 //crm stage
							 //Doc Pending
							 this.totalIAPDocP = 0;							
							 for (let i = 0; i < this.result1.length; i++) {
								 const element = this.result1[i];
								 if(element.meraemistage === "Doc Pending" && element.status === "IAP_Complete"){
																	
									this.countIAPDocp.push({ status: this.appstage});
									
								 }
								 if(element.meraemistage === "Doc Pending" && element.loan_amount != undefined && element.status === "IAP_Complete"){
									this.totalIAPDocP += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CounttotalDocP=this.countIAPDocp.length;
							 this.TotatalDocp=this.totalIAPDocP;
							 	 // Doc Received
								  this.totalIAPDocR = 0;							
								  for (let i = 0; i < this.result1.length; i++) {
									  const element = this.result1[i];
									  if(element.meraemistage === "Doc Received" && element.status === "IAP_Complete"){
																		 
										 this.countIAPDocR.push({ status: this.appstage});
										 
									  }
									  if(element.meraemistage === "Doc Received" && element.loan_amount != undefined && element.status === "IAP_Complete"){
										 this.totalIAPDocR += Number(element.loan_amount);									
										 
										 
									  }
									  
								  }	
								  this.CounttotalDocR=this.countIAPDocR.length;
								  this.TotatalDocR=this.totalIAPDocR;
								  //Doc Complete

								  this.totalIAPDocC = 0;							
								  for (let i = 0; i < this.result1.length; i++) {
									  const element = this.result1[i];
									  if(element.meraemistage === "Doc Complete" && element.status === "IAP_Complete"){
																		 
										 this.countIAPDocC.push({ status: this.appstage});
										 
									  }
									  if(element.meraemistage === "Doc Complete" && element.loan_amount != undefined && element.status === "IAP_Complete"){
										 this.totalIAPDocC += Number(element.loan_amount);									
										 
										 
									  }
									  
								  }	
								  this.CounttotalDocC=this.countIAPDocC.length;
								  this.TotatalDocC=this.totalIAPDocC;
								  //Doc Incomplete
								  this.totalIAPDocIn = 0;							
								  for (let i = 0; i < this.result1.length; i++) {
									  const element = this.result1[i];
									  if(element.meraemistage === "Doc Incomplete" && element.status === "IAP_Complete"){
																		 
										 this.countIAPDocIn.push({ status: this.appstage});
										 
									  }
									  if(element.meraemistage === "Doc Incomplete" && element.loan_amount != undefined && element.status === "IAP_Complete"){
										 this.totalIAPDocIn += Number(element.loan_amount);									
										 
										 
									  }
									  
								  }	
								  this.CounttotalDocIn=this.countIAPDocIn.length;
								  this.TotatalDocIn=this.totalIAPDocIn;
                                  //Pending Review
								
                                  this.totalIAPDocIn = 0;							
								  for (let i = 0; i < this.result1.length; i++) {
									  const element = this.result1[i];
									  if(element.meraemistage === "Pending Review" && element.status === "IAP_Complete"){
																		 
										 this.countIAPDocPenR.push({ status: this.appstage});
										 
									  }
									  if(element.meraemistage === "Pending Review" && element.loan_amount != undefined && element.status === "IAP_Complete"){
										 this.totalIAPDocPenR += Number(element.loan_amount);									
										 
										 
									  }
									  
								  }	
								  this.CounttotalDocPenR=this.countIAPDocPenR.length;
								  this.TotatalDocPenR=this.totalIAPDocPenR;
												//Transfer
												this.totalIAPDocTrans = 0;							
												for (let i = 0; i < this.result1.length; i++) {
													const element = this.result1[i];
													if(element.meraemistage === "Transfer" && element.status === "IAP_Complete"){
																						
														this.countIAPDocTrans.push({ status: this.appstage});
														
													}
													if(element.meraemistage === "Transfer" && element.loan_amount != undefined && element.status === "IAP_Complete"){
														this.totalIAPDocTrans += Number(element.loan_amount);									
														
														
													}
													
												}	
												this.CounttotalDocTrans=this.countIAPDocTrans.length;
												this.TotatalDocTrans=this.totalIAPDocTrans;
												//Lender Process
												this.totalIAPDocLendPr = 0;							
												for (let i = 0; i < this.result1.length; i++) {
													const element = this.result1[i];
													if(element.meraemistage === "Lender Process" && element.status === "IAP_Complete"){
																						
														this.countIAPDocLendPr.push({ status: this.appstage});
														
													}
													if(element.meraemistage === "Lender Process" && element.loan_amount != undefined && element.status === "IAP_Complete"){
														this.totalIAPDocLendPr += Number(element.loan_amount);									
														
														
													}
													
												}	
												this.CounttotalDocLendPr=this.countIAPDocLendPr.length;
												this.TotatalDocLendPr=this.totalIAPDocLendPr;
                                                //CRM Cancel
													this.totalIAPDocCrmC = 0;							
													for (let i = 0; i < this.result1.length; i++) {
														const element = this.result1[i];
														if(element.meraemistage === "CRM Cancel" && element.status === "IAP_Complete"){
																							
															this.countIAPDocCrmC.push({ status: this.appstage});
															
														}
														if(element.meraemistage === "CRM Cancel" && element.loan_amount != undefined && element.status === "IAP_Complete"){
															this.totalIAPDocCrmC += Number(element.loan_amount);									
															
															
														}
														
													}	
													this.CounttotalDocCrmC=this.countIAPDocCrmC.length;
													this.TotatalDocCrmC=this.totalIAPDocCrmC;
													 //Cust Cancel
													 this.totalIAPDocCustC = 0;							
													 for (let i = 0; i < this.result1.length; i++) {
														 const element = this.result1[i];
														 if(element.meraemistage === "Cust Cancel" && element.status === "IAP_Complete" ){
																							
															this.countIAPDocCustC.push({ status: this.appstage});
															
														 }
														 if(element.meraemistage === "Cust Cancel" && element.loan_amount != undefined && element.status === "IAP_Complete"){
															this.totalIAPDocCustC += Number(element.loan_amount);									
															
															
														 }
														 
													 }	
													 this.CounttotalDocCustC=this.countIAPDocCustC.length;
													 this.TotatalDocCustC=this.totalIAPDocCustC;

													 //CRM Decline
													 this.totalIAPDocCrmD = 0;							
													 for (let i = 0; i < this.result1.length; i++) {
														 const element = this.result1[i];
														 if(element.meraemistage === "CRM Decline" && element.status === "IAP_Complete"){
																							
															this.countIAPDocCrmD.push({ status: this.appstage});
															
														 }
														 if(element.meraemistage === "CRM Decline" && element.loan_amount != undefined && element.status === "IAP_Complete"){
															this.totalIAPDocCrmD += Number(element.loan_amount);									
															
															
														 }
														 
													 }	
													 this.CounttotalDocCrmD=this.countIAPDocCrmD.length;
													 this.TotatalDocCrmD=this.totalIAPDocCrmD;
													  //Disbursed
													  this.totalIAPDocDisb = 0;							
													  for (let i = 0; i < this.result1.length; i++) {
														  const element = this.result1[i];
														  if(element.meraemistage === "Disbursed"  && element.status === "IAP_Complete"){
																							 
															 this.countIAPDocDisb.push({ status: this.appstage});
															 
														  }
														  if(element.meraemistage === "Disbursed" && element.loan_amount != undefined && element.status === "IAP_Complete"){
															 this.totalIAPDocDisb += Number(element.loan_amount);									
															 
															 
														  }
														  
													  }	
													  this.CounttotalDocDisb=this.countIAPDocDisb.length;
													  this.TotatalDocDisb=this.totalIAPDocDisb;

								  this.tatalcrmstageAmt=(this.TotatalDocp+this.TotatalDocR +this.TotatalDocC+this.TotatalDocIn+this.TotatalDocPenR +this.TotatalDocTrans +this.TotatalDocLendPr +this.TotatalDocCrmC+this.TotatalDocCustC +this.TotatalDocCrmD +this.TotatalDocDisb) ;
								  this.totalcrmstagecount=(this.CounttotalDocP+this.CounttotalDocR +this.CounttotalDocC+this.CounttotalDocIn+this.CounttotalDocPenR +this.CounttotalDocTrans +this.CounttotalDocLendPr +this.CounttotalDocCrmC +this.CounttotalDocCustC +this.CounttotalDocCrmD +this.CounttotalDocDisb);
							 	this.myObjArray.push([
									   {TotalEligibilityPend : this.TotalEligibilityPend} ,
									   {TotalEligibilityClear : this.TotalEligibilityClear},
									   {TotaltEligFail : this.TotaltEligFail},
									   {TotaltIAPPend : this.TotaltIAPPend},
									   {TotaltIAPSuss : this.TotaltIAPSuss},
									   {TotaltIAPFail : this.TotaltIAPFail},
									   {TotaltIAPCP : this.TotaltIAPCP}
										

								 ]);
								

							 }else{
								 alert("API connection Error");
							 }
              //********************************************** Chart Data 1****************************************** */
			    let Amount= this.total;   
			    let TotalUsers =this.totalcount;
				 //toat amt Eligibility Dropoffs
				 this.TotalelgDropoffs =this.TotalEligibilityPend;				
				 this.TotalelgCheckeds =(this.total-this.TotalEligibilityPend);
			    this.TotalEligibilityPenCont=this.CountEligibilityPen;	
				//console.log(this.TotalEligibilityPenCont);
			    //Eligibility Dropoffs %
                let TotalEligibilityPen=this.CountEligibilityPen;			  
			    this.EligibilityDropoffsPercentage =Math.round((TotalEligibilityPen/TotalUsers)*100);             
			    let EligibilityChecked=(TotalUsers-TotalEligibilityPen);
				let totalEligibilityChecked=(TotalUsers-TotalEligibilityPen);
			    this.EligibilityCheckedPercentage=Math.round((EligibilityChecked/TotalUsers)*100); 
				this.TotalEligibilityPenContChecked=(TotalUsers-TotalEligibilityPen);            
			    //********************************************** end Chart Data ****************************************** */
				// ******************************************** Data table data Eligibility Dropoffs Array 1*********************************************************************//
				
				let TotalAmtDropoffs=Math.round((this.TotalelgDropoffs/Amount)*100); 
				//console.log(TotalAmtDropoffs);

				this.EligibilityDropoffsArray.push([
					{Stages : "Eligibility Dropoffs"} ,
					{Count : TotalEligibilityPen},
					{Amount : this.TotalelgDropoffs},
					{Countper :  this.EligibilityDropoffsPercentage},
					{Amountper : TotalAmtDropoffs}
					 

			   ]);
			   //console.log(this.EligibilityDropoffsArray[0]);
               // ************************************************** End Data table data***************************************************************//
				// ******************************************** Data table data Eligibility Checked Array 2*********************************************************************//
				
				let TotalAmtChecked=Math.round((this.TotalelgCheckeds/Amount)*100);
				//console.log(TotalAmtDropoffs);

				this.EligibilityCheckedArray.push([
					{Stages : "Eligibility Checked"} ,
					{Count : totalEligibilityChecked},
					{Amount : this.TotalelgCheckeds},
					{Countper :   this.EligibilityCheckedPercentage},
					{Amountper : TotalAmtChecked}
					 

			  ]);
			  //console.log(this.EligibilityCheckedArray);
               // ************************************************** End Data table data***************************************************************//
			  //********************************************** Chart Data 2****************************************** */				 
				//total amt Eligibility Checked
				 this.TotalEliFail=this.TotaltEligFail;			
				 this.TotalEligSuccess=(this.TotalelgCheckeds-this.TotalEliFail);
				this.TotalEligbilityFailCount=this.CountEligFail;
				//Eligibility Success and Eligbility Fail

				let EligbilityFail=this.CountEligFail;				
				this.EligbilityFail=Math.round((EligbilityFail/EligibilityChecked)*100);					
				let EligibilitySuccess =(EligibilityChecked-EligbilityFail);				
				this.EligibilitySuccess=Math.round((EligibilitySuccess/EligibilityChecked)*100);
				this.tatalelgEligibility=Math.round(this.EligbilityFail+this.EligibilitySuccess);
				this.TotalEligibilitySuccessCount=(EligibilityChecked-EligbilityFail);
				
				//********************************************** end Chart Data ****************************************** */
				// ************************************************** End Data table data Eligibility Fail Array 3***************************************************************//
				//console.log(Amount);
				//console.log(this.TotalelgDropoffs);
				let EligibilityCheckedAmt=(Amount-this.TotalelgDropoffs);
				//console.log(this.TotalEliFail);
				let EligbilityFailAmountper=Math.round((this.TotalEliFail/EligibilityCheckedAmt)*100);
				this.EligibilityFailArray.push([
					{Stages : "Eligibility Fail"} ,
					{Count : EligbilityFail},
					{Amount : this.TotalEliFail},
					{Countper : this.EligbilityFail},
					{Amountper : EligbilityFailAmountper}
					 

			   ]);
			   //console.log(this.EligibilityFailArray);
				// ************************************************** End Data table data***************************************************************//
				// **************************************************  Data table data EligibilitySuccessArray 4***************************************************************//
				
				let totalEligibilitySuccess =(this.TotalelgCheckeds-this.TotalEliFail)
				let totalEligibilitySuccessper =Math.round((totalEligibilitySuccess/this.TotalelgCheckeds)*100);
				
				this.EligibilitySuccessArray.push([
					{Stages : "Eligibility Success"} ,
					{Count : EligibilitySuccess},
					{Amount : totalEligibilitySuccess},
					{Countper :  this.EligibilitySuccess},
					{Amountper : totalEligibilitySuccessper}
					 

			  ]);
			  //console.log(this.EligibilitySuccessArray);
				// ************************************************** End Data table data***************************************************************//
					// ************************************************** End Data table data***************************************************************//
				 //total amt Eligibility Success
				 this.TotalElilSuccDropoff=this.TotalEligibilityClear;
				
				 this.TotalIAPTotal=(this.TotaltIAPPend	+this.TotaltIAPSuss	+this.TotaltIAPFail+this.TotaltIAPCP);
				 this.TotalEligibilitySuccessDropoffCount= this.CountEligibilityClear;
				// Eligibility Clear
				let EligibilitySuccessDropoff= this.CountEligibilityClear;
				let EligibilitySuccess_Dropoff =Math.round((EligibilitySuccessDropoff/EligibilitySuccess)*100);
				if(EligibilitySuccess_Dropoff !=Infinity){
					this.EligibilitySuccessDropoff =EligibilitySuccess_Dropoff;
				}else{
					this.EligibilitySuccessDropoff =0;
				}
                let IAPTotal=(this.CountIAPPend+this.CountIAPSuss+this.CountIAPFail+this.CountIAPCP);
				this.IAPTotal =Math.round((IAPTotal/EligibilitySuccess)*100);			
				this.IAPTotalPercentage=Math.round(this.IAPTotal+this.EligibilitySuccessDropoff);
				this.TotalIAPTotalCount=IAPTotal;
			//	console.log( this.CountIAPPend);
			//	console.log( this.CountIAPSuss);
			//	console.log( this.CountIAPCP);
	            // ************************************************** End Data table data***************************************************************//
		        // ************************************************** End Data table data Eligibility Clear array***************************************************************//
				
				let EligibilityClearAmt=this.TotalEligibilityClear;
				let TotalAmtEligibilitySuccessDropoff=Math.round((EligibilityClearAmt/totalEligibilitySuccess)*100);
				this.EligibilitySuccessDropoffArray.push([
					{Stages : "Eligibility Clear"} ,
					{Count : EligibilitySuccessDropoff},
					{Amount : this.TotalElilSuccDropoff},
					{Countper :    this.EligibilitySuccessDropoff},
					{Amountper : TotalAmtEligibilitySuccessDropoff}
					 

			  ]);
			 // console.log( this.EligibilitySuccessDropoffArray);
				// ************************************************** End Data table data***************************************************************//
				 // ************************************************** End Data table data IAP Total array***************************************************************//
				
				 //let IAPTotalamt=(totalEligibilitySuccess-EligibilityClearAmt);
			
				 let IAPTotalamt=Math.round((this.TotalIAPTotal/totalEligibilitySuccess)*100);
				 //console.log(IAPTotalamt);
				 this.IAPTotalffArray.push([
					 {Stages : "IAP Total"} ,
					 {Count : IAPTotal},
					 {Amount : this.TotalIAPTotal},
					 {Countper :   this.IAPTotal},
					 {Amountper : IAPTotalamt}
					  
 
			   ]);
			   //console.log( this.IAPTotalffArray);
				 // ************************************************** End Data table data***************************************************************//
	 // ************************************************** End Data table data***************************************************************//
				//total amt IAP Total
				this.TotalIAPPending =this.TotaltIAPPend;				
				this.IapApiCalled = (this.TotalIAPTotal-this.TotalIAPPending);
				this.TotalIAPPendingCount =this.CountIAPPend;
				// IAP Total
				let IAPPending =this.CountIAPPend;
				this.IAPPending=Math.round((IAPPending/IAPTotal)*100);
				//console.log(this.IAPPending);
			
				let IAPAPICalled=(IAPTotal-IAPPending);
				this.IAPAPICalled=Math.round((IAPAPICalled/IAPTotal)*100);
				//console.log(this.IAPAPICalled);
				this.IAPTotalCount=(this.IAPAPICalled+this.IAPPending);
				//console.log(this.IAPTotalCount);

// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Total***************************************************************//
					let totalTotaltIAPPend=this.TotaltIAPPend;									
					let IAPPendingamtPr=Math.round((totalTotaltIAPPend/this.TotalIAPTotal)*100);					
					this.IAPPendingArray.push([
						{Stages : "IAP Pending"} ,
						{Count : IAPPending},
						{Amount : this.TotalIAPPending},
						{Countper :   this.IAPPending},
						{Amountper : IAPPendingamtPr}
						

					]);
				//	console.log( this.IAPPendingArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Complete Array***************************************************************//
					
					//console.log(totalTotaltIAPPend);
				
					let IAPAPICalledAmt=(this.TotalIAPTotal-totalTotaltIAPPend);	
						
					let IAPAPICalledAmtPr=Math.round((IAPAPICalledAmt/this.TotalIAPTotal)*100);
					//console.log(IAPAPICalledAmtPr);		
					//console.log(IAPTotalamt);
					this.IAPAPICalledArray.push([
						{Stages : "IAP Complet"} ,
						{Count : IAPAPICalled},
						{Amount : this.IapApiCalled},
						{Countper :    this.IAPAPICalled},
						{Amountper : IAPAPICalledAmtPr}
						

					]);
					//console.log( this.IAPAPICalledArray);



// ************************************************** End Data table data IAP Total***************************************************************//


// ************************************************** CRM STAGE  data IAP Total***************************************************************//
//tatalcrm 
let totalcrmcount =this.totalcrmstagecount;
let totalcountpercentage=this.IAPAPICalled;
let totalcrmAmt=this.tatalcrmstageAmt;
let totalcrmAmtPercentage=IAPAPICalledAmtPr;
this.tataolcrmArray.push([
	{Stages : "CRM Status"} ,
	{Count : totalcrmcount},
	{Amount : totalcrmAmt},
	{Countper :    totalcountpercentage},
	{Amountper : totalcrmAmtPercentage}
	

]);
//console.log(this.tatalcrmstageAmt);
//console.log(this.totalcrmstagecount);


//Doc Pending

//console.log(this.CounttotalDocP);
//console.log(this.TotatalDocp);
let totalcountDocP=this.CounttotalDocP;
let totalAmtDocP=this.TotatalDocp;
let totalcountDocPerc=Math.round((totalcountDocP/this.totalcrmstagecount)*100);
let totalAmtDocPerc=Math.round((totalAmtDocP/this.tatalcrmstageAmt)*100);
this.tatalDocPendArray.push([
	{Stages : "Doc Pending"} ,
	{Count : totalcountDocP},
	{Amount : totalAmtDocP},
	{Countper :    totalcountDocPerc},
	{Amountper : totalAmtDocPerc}
	

]);

//Doc Received

//console.log(this.CounttotalDocP);
//console.log(this.TotatalDocp);
let totalcountDocRec=this.CounttotalDocR;
let totalAmtDocRec=this.TotatalDocR;

let totalcountDocPercRec=Math.round((totalcountDocRec/this.totalcrmstagecount)*100);
let totalAmtDocPercRec=Math.round((totalAmtDocRec/this.tatalcrmstageAmt)*100);
this.tatalDocRecArray.push([
	{Stages : "Doc Received"} ,
	{Count : totalcountDocRec},
	{Amount : totalAmtDocRec},
	{Countper :    totalcountDocPercRec},
	{Amountper : totalAmtDocPercRec}
	

]);


//Doc Complete
let totalcountDocCamp=this.CounttotalDocC;
let totalAmtDocCamp=this.TotatalDocC;

let totalcountDocPercCamp=Math.round((totalcountDocCamp/this.totalcrmstagecount)*100);
let totalAmtDocPercCamp=Math.round((totalAmtDocCamp/this.tatalcrmstageAmt)*100);
this.tatalDocCampArray.push([
	{Stages : "Doc Complete"} ,
	{Count : totalcountDocCamp},
	{Amount : totalAmtDocCamp},
	{Countper :    totalcountDocPercCamp},
	{Amountper : totalAmtDocPercCamp}
	

]);
//Doc Incomplete
let totalcountDocInc=this.CounttotalDocIn;
let totalAmtDocInc=this.TotatalDocIn;

let totalcountDocPercInc=Math.round((totalcountDocInc/this.totalcrmstagecount)*100);
let totalAmtDocPercInc=Math.round((totalAmtDocInc/this.tatalcrmstageAmt)*100);
this.tatalDocIncArray.push([
	{Stages : "Doc Incomplete"} ,
	{Count : totalcountDocInc},
	{Amount : totalAmtDocInc},
	{Countper :    totalcountDocPercInc},
	{Amountper : totalAmtDocPercInc}
	

]);

//Pending Review
let totalcountDocPenr=this.CounttotalDocPenR;
let totalAmtDocPenr=this.TotatalDocPenR;

let totalcountDocPercPenr=Math.round((totalcountDocPenr/this.totalcrmstagecount)*100);
let totalAmtDocPercPenr=Math.round((totalAmtDocPenr/this.tatalcrmstageAmt)*100);
this.tatalDocPendRArray.push([
	{Stages : "Pending Review"} ,
	{Count : totalcountDocPenr},
	{Amount : totalAmtDocPenr},
	{Countper :    totalcountDocPercPenr},
	{Amountper : totalAmtDocPercPenr}
	

]);

//Transfer
let totalcountDocTrans=this.CounttotalDocTrans;
let totalAmtDocTrans=this.TotatalDocTrans;

let totalcountDocPercTrans=Math.round((totalcountDocTrans/this.totalcrmstagecount)*100);
let totalAmtDocPercTrans=Math.round((totalAmtDocTrans/this.tatalcrmstageAmt)*100);
this.tatalDocTransRArray.push([
	{Stages : "Transfer"} ,
	{Count : totalcountDocTrans},
	{Amount : totalAmtDocTrans},
	{Countper :    totalcountDocPercTrans},
	{Amountper : totalAmtDocPercTrans}
	

]);

//Lender Process
let totalcountDocLendPr=this.CounttotalDocLendPr;
let totalAmtDocLendPr=this.TotatalDocTrans;

let totalcountDocLenderPr=Math.round((totalcountDocLendPr/this.totalcrmstagecount)*100);
let totalAmtDocLenderPr=Math.round((totalAmtDocLendPr/this.tatalcrmstageAmt)*100);
this.tatalDocLenderPrArray.push([
	{Stages : "Lender Process"} ,
	{Count : totalcountDocLendPr},
	{Amount : totalAmtDocLendPr},
	{Countper :    totalcountDocLenderPr},
	{Amountper : totalAmtDocLenderPr}
	

]);



//Lender Process
let totalcountDocCrmC=this.CounttotalDocCrmC;
let totalAmtDocCrmC=this.TotatalDocCrmC;

let totalcountDocCrmCan=Math.round((totalcountDocCrmC/this.totalcrmstagecount)*100);
let totalAmtDocCrmCan=Math.round((totalAmtDocCrmC/this.tatalcrmstageAmt)*100);
this.tatalDocCrmCansArray.push([
	{Stages : "CRM Cancel"} ,
	{Count : totalcountDocCrmC},
	{Amount : totalAmtDocCrmC},
	{Countper :    totalcountDocCrmCan},
	{Amountper : totalAmtDocCrmCan}
	

]);

this.CounttotalDocCustC=this.countIAPDocCustC.length;
this.TotatalDocCustC=this.totalIAPDocCustC;

//Cust Cancel
let totalcountDocCustC=this.CounttotalDocCustC;
let totalAmtDocCustC=this.TotatalDocCustC;

let totalcountDocCustCan=Math.round((totalcountDocCustC/this.totalcrmstagecount)*100);
let totalAmtDocCustCan=Math.round((totalAmtDocCustC/this.tatalcrmstageAmt)*100);
this.tatalDocCustCanArray.push([
	{Stages : "Cust Cancel"} ,
	{Count : totalcountDocCustC},
	{Amount : totalAmtDocCustC},
	{Countper :    totalcountDocCustCan},
	{Amountper : totalAmtDocCustCan}
	

]);


//CRM Decline
let totalcountCrmD=this.CounttotalDocCrmD;
let totalAmtCrmD=this.TotatalDocCrmD;

let totalcountDrmdec=Math.round((totalcountCrmD/this.totalcrmstagecount)*100);
let totalAmtCrmDec=Math.round((totalAmtCrmD/this.tatalcrmstageAmt)*100);
this.tatalCrmDanArray.push([
	{Stages : "CRM Decline"} ,
	{Count : totalcountCrmD},
	{Amount : totalAmtCrmD},
	{Countper :    totalcountDrmdec},
	{Amountper : totalAmtCrmDec}
	

]);
this.CounttotalDocDisb=this.countIAPDocDisb.length;
this.TotatalDocDisb=this.totalIAPDocDisb;
//Disbursed
let totalcountDisb=this.CounttotalDocDisb;
let totalAmtDisb=this.TotatalDocDisb;

let totalcountDisbu=Math.round((totalcountDisb/this.totalcrmstagecount)*100);
let totalAmtDisbu=Math.round((totalAmtDisb/this.tatalcrmstageAmt)*100);
this.tatalDisbuArray.push([
	{Stages : "Disbursed"} ,
	{Count : totalcountDisb},
	{Amount : totalAmtDisb},
	{Countper :    totalcountDisbu},
	{Amountper : totalAmtDisbu}
	

]);



// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data***************************************************************//
				//total amt IAP Complete
				this.TotalIapApiError=this.TotaltIAPCP;
				//console.log(this.TotalIapApiError);
				this.TotalIapAaiSuccess=(this.IapApiCalled-this.TotalIapApiError);
				//console.log(this.TotalIapAaiSuccess);
				//IAP Complete
				
				let IAPAPIError=this.CountIAPCP;				
				this.IAPAPIError=Math.round((IAPAPIError/IAPAPICalled)*100);
				//console.log(this.IAPAPIError);
				let IAPAPISuccess=(IAPAPICalled-IAPAPIError);
				//console.log(IAPAPISuccess);
				this.IAPAPISuccess	=Math.round((IAPAPISuccess/IAPAPICalled)*100);			
				this.IAPAPICalledCount =Math.round(this.IAPAPISuccess+this.IAPAPIError);
			
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Complete***************************************************************//
					let IAPAPIErrorAmt =this.TotaltIAPCP;
					let IAPAPIErrorAmtCount=Math.round((IAPAPIErrorAmt/IAPAPICalledAmt)*100);	
					
					//console.log(IAPAPIErrorAmt);
                    this.IAPAPIErrorArray.push([
						//{Stages : "IAP API Error"} ,
						{Stages : "IAP Complete"} ,
						{Count : IAPAPIError},
						{Amount : this.TotalIapApiError},
						{Countper : this.IAPAPIError},
						{Amountper : IAPAPIErrorAmtCount}
						
					]);
					//console.log(this.IAPAPIErrorArray);
// ************************************************** End Data table data***************************************************************//
 // ************************************************** End Data table data IAP Complete***************************************************************//
						let IAPAPISuccessAmt =(IAPAPICalledAmt-IAPAPIErrorAmt);
					//	console.log(IAPAPISuccessAmt);
						let IAPAPISuccessAmtCount=Math.round((IAPAPISuccessAmt/IAPAPICalledAmt)*100);	
						
						//console.log(IAPAPIErrorAmt);
						this.IAPAPISuccessArray.push([
							{Stages : "IAP API Success"} ,
							{Count : IAPAPISuccess},
							{Amount : this.TotalIapAaiSuccess},
							{Countper : this.IAPAPISuccess},
							{Amountper : IAPAPISuccessAmtCount}
							
						]);
						//console.log(this.IAPAPISuccessArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data***************************************************************//

              //Totalam IAP API Success
					this.TotalIAPFail=this.TotaltIAPFail;
					//console.log(this.TotalIAPFail);
					this.TotalIAPClear=(this.TotalIapAaiSuccess-this.TotalIAPFail);
					//console.log(this.TotalIAPClear);
				// IAP API Success
				let IAPFail=this.CountIAPFail;				
				this.IAPFail=Math.round((IAPFail/IAPAPISuccess)*100);
				let IAPClear=(IAPAPISuccess-IAPFail);
				this.IAPClear=Math.round((IAPClear/IAPAPISuccess)*100);	
// **********************************************  End char data ****************************************************//
// ************************************************** End Data table data IAP Fail***************************************************************//
						let IAPFailAmt =this.TotaltIAPFail;	
						let IAPFailAmtCount=Math.round((IAPFailAmt/IAPAPISuccessAmt)*100);						
                            //  console.log(IAPFailAmtCount);
						this.IAPFailArray.push([
							{Stages : "IAP Fail"} ,
							{Count : IAPFail},
							{Amount : this.TotalIAPFail},
							{Countper : this.IAPFail},
							{Amountper : IAPFailAmtCount}
							
						]);
						//console.log(this.IAPFailArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Clear***************************************************************//
						let IAPClearAmt =(IAPAPISuccessAmt-this.TotaltIAPFail);	
						let IAPClearAmtCount=Math.round((IAPClearAmt/IAPAPISuccessAmt)*100);						
                       //console.log(IAPClearAmtCount);
						this.IAPClearArray.push([
							{Stages : "IAP Clear"} ,
							{Count : IAPClear},
							{Amount : this.TotalIAPClear},
							{Countper : this.IAPClear},
							{Amountper : IAPClearAmtCount}
							
						]);
						//console.log(this.IAPClearArray);
// ************************************************** End Data table data***************************************************************//

				
let chart = new CanvasJS.Chart("chartContainer", {
	animationEnabled: true,
	theme: "light2",
	title:{
		text: "Mera EMI Report"
	},
	axisY2:{
		
		lineThickness: 10,
		// prefix: "%"				
	},
	toolTip: {
		shared: false
	},
	legend:{
		verticalAlign: "top",
		horizontalAlign: "center"
	},
	data: [
	{     
		type: "stackedBar",
		showInLegend: false,							
		axisYType: "secondary",
		color: "#8bc34a",							
		toolTipContent: "<b>{name}</b>: {y}% <br><b>Nos</b>: {total}<br><b>Value</b>: ₹{totalvalue}",
		indexLabel: "{name}-{y}%",
		indexLabelFontSize: 10,
		
		dataPoints: [
			// { y: 10,label: "IAP Clear" , name: "IAP Disbursed" ,total:"NA",totalvalue:"NA"},
			// { y: this.IAPFail, label: "IAP API Success" ,name: "IAP Fail"  ,total:IAPFail,totalvalue:this.TotalIAPFail},
			//{ y: this.IAPAPIError, label: "IAP API Called", name: "IAP API Error" ,total:IAPAPIError,totalvalue:this.TotalIapApiError },
			{ y: this.IAPAPIError, label: "IAP API Called", name: "IAP Complete" ,total:IAPAPIError,totalvalue:this.TotalIapApiError },
			
			{ y: this.IAPPending,  label: "IAP Total", name: "IAP Pending"  ,total:IAPPending,totalvalue:this.TotalIAPPending},
			{ y: this.EligibilitySuccessDropoff, label: "Eligibility Success", name: "Eliligbility Success Dropoff",total:EligibilitySuccessDropoff,totalvalue:this.TotalElilSuccDropoff  },
			{ y: this.EligbilityFail, label: "Eligibility Checked", name: "Eligibility Fail" ,total:EligbilityFail,totalvalue:this.TotalEliFail },
			{ y: this.EligibilityDropoffsPercentage, label: "Total Users" , name: "Eligibility Dropoffs" ,total:TotalEligibilityPen,totalvalue:this.TotalelgDropoffs },
		]
	},
	{
		type: "stackedBar",
		showInLegend: false,							
		axisYType: "secondary",
		color: "#cddc39",
		toolTipContent: "<b>{name}</b>: {y}% <br><b>Nos</b>: {total}<br><b>Value</b>: ₹{totalvalue}",
		indexLabel: "{name}-{y}%",
		indexLabelFontSize: 10,
		dataPoints: [									
			// { y: 90, label: "IAP Clear" ,name: "IAP Cancel",total:"NA",totalvalue:"NA" },
			// { y: this.IAPClear, label: "IAP API Success" ,name: "IAP Clear" ,total:IAPClear,totalvalue:this.TotalIAPClear},
			{ y: this.IAPAPISuccess, label: "IAP Complete", name: "IAP API Success", total:IAPAPISuccess,totalvalue:this.TotalIapAaiSuccess},
			{ y: this.IAPAPICalled, label: "IAP Total", name: "IAP API Called" ,total:IAPAPICalled,totalvalue:this.IapApiCalled  },
			{ y: this.IAPTotal, label: "Eligibility Success", name: "IAP Total" ,total:IAPTotal,totalvalue: this.TotalIAPTotal },
			{ y: this.EligibilitySuccess, label: "Eligibility Checked", name: "Eligibility Success" ,total:EligibilitySuccess,totalvalue:this.TotalEligSuccess },
			{ y: this.EligibilityCheckedPercentage, label: "Total Users",name: "Eligibility Checked" ,total:totalEligibilityChecked,totalvalue:this.TotalelgCheckeds}
		]
	}
	
	]
});
					
				chart.render();

						   });
						  
               
  

	
	}
	chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }
 
	filter_chart(){
		
		this.EligibilityDropoffsArray.length=0;

		this.EligibilityCheckedArray.length=0;
		this.EligibilityFailArray.length=0;
		this.EligibilitySuccessArray.length=0;
		this.EligibilitySuccessDropoffArray.length=0;
		this.IAPTotalffArray.length=0;
		this.IAPPendingArray.length=0;
		this.IAPAPICalledArray.length=0;
		this.IAPAPIErrorArray.length=0;
		this.IAPAPISuccessArray.length=0;
		this.IAPFailArray.length=0;
		this.IAPClearArray.length=0;

		this.adid =this.filterchart.value.adid;
		this.location =this.filterchart.value.location;
		this.date_from =this.filterchart.value.datefrom;
		this.date_to =this.filterchart.value.dateto;
		this.CountEligibilityPen=0;
		this.TotalEligibilityPend=0;
		this.CountEligibilityClear=0;
		this.TotalEligibilityClear=0;
		this.CountEligFail=0;
		this.TotaltEligFail=0;
		this.CountIAPPend=0;
		this.TotaltIAPPend=0;
		this.CountIAPSuss=0;
		this.TotaltIAPSuss=0;
		this.CountIAPFail=0;
		this.TotaltIAPFail=0;
		this.CountIAPCP=0;
		this.TotaltIAPCP=0;
		this.countLoanamt1.length=0;
		this.countEligibilityClear1.length=0;
		this.countEligibilityFail1.length=0;
		this.countIAPPending1.length=0;
		this.countIAPSuccess1.length=0;
		this.countIAPFail1.length=0;
		this.countIAPComplete1.length=0;
		this.countIAPError.length=0;	
		
		//crm stage
		this.tatalcrmstageAmt==0;
        this.totalcrmstagecount==0;
		this.tataolcrmArray.length=0;	
		this.tatalDocPendArray.length=0;
		this.tatalDocRecArray.length=0;
		this.tatalDocCampArray.length=0;
		this.tatalDocIncArray.length=0;
		this.tatalDocPendRArray.length=0;
		this.tatalDocTransRArray.length=0;
		this.tatalDocLenderPrArray.length=0;
		this.tatalDocCrmCansArray.length=0;
		this.tatalDocCustCanArray.length=0;
		this.tatalCrmDanArray.length=0;
		this.tatalDisbuArray.length=0;


		this.countIAPDocp.length=0;
		this.countIAPDocR.length=0;
		this.countIAPDocC.length=0;
		this.countIAPDocIn.length=0;
		this.countIAPDocPenR.length=0;
		this.countIAPDocTrans.length=0;
		this.countIAPDocLendPr.length=0;
		this.countIAPDocCrmC.length=0;
		this.countIAPDocCustC.length=0;
		this.countIAPDocCrmD.length=0;
		this.countIAPDocDisb.length=0;
		this.total=0;
		this.totalcount	=0;	


		
		this.adid =this.filterchart.value.adid;
		this.filter =this.filterchart.value.adid;
		let date = new Date();
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//this.datemainArry.length=0;
		//console.log(this.currentdate);
		 if(this.adid !="" && this.location !="" && this.date_from !="" && this.date_to !=""){
			if(this.adid !=""){
				    this.aadkey ="adkey";
				    const mainarray= this.elements;				
					const  adid =this.filterchart.value.adid;
					//console.log(adid[0]);
					if(adid[0]==="All-Adid-Select"){
						this.adidarray =this.elements;
					}else{

					
					//console.log(adid);
					let filtered = [];
					
					adid.filter(function(newadid) {
						return   mainarray.filter(function(mainArray) {
							if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
							filtered.push({
							user_id:     mainArray.user_id, 
							first_name:  mainArray.first_name, 
							last_name:   mainArray.last_name,              
							provider:    mainArray.provider,
							city_name:   mainArray.city_name,
							loan_amount: mainArray.loan_amount,
							ad_id:       mainArray.ad_id,
							reg_date:    mainArray.reg_date,
							status:      mainArray.status,
							meraemistage:      mainArray.meraemistage,
			
							
							})
						  }
						})
					  });
					  this.adidarray =filtered;
					}
					 
			 } 
			if(this.location !=""){
				    const mainarray=this.adidarray;				  
				    this.aadkey ="cityname";
					const  location =this.location;
					if(location[0]==="All-city-Select"){
						this.cityarray =mainarray;
						let resultdate =[]
					
					resultdate = mainarray.filter(d => {
						const  reg_date= d.reg_date;
						const regdate = reg_date.split(' ')[0];					            
						return (regdate >= this.date_from   && regdate  <= this.date_to );            
					
						
					});
					}else{

					
					//console.log(location);
					let filtered = [];
					
					location.filter(function(newCity) {
				    return   mainarray.filter(function(mainArray) {
					if (newCity === mainArray.city_name && newCity !="All-city-Select") {
					filtered.push({
					user_id:     mainArray.user_id, 
					first_name:  mainArray.first_name, 
					last_name:   mainArray.last_name,              
					provider:    mainArray.provider,
					city_name:   mainArray.city_name,
					loan_amount: mainArray.loan_amount,
					ad_id:       mainArray.ad_id,
					reg_date:    mainArray.reg_date,
					status:      mainArray.status,
					meraemistage:      mainArray.meraemistage,
					
					})
				  }
				})
			  });
			  this.cityarray =filtered;
			}
            }
			if(this.date_from !="" && this.date_to !=""){
				this.date_from =this.filterchart.value.datefrom;
				this.date_to =this.filterchart.value.dateto;
				this.adDate="2020-05-02";
			
					
					this.ToandFromDate =(this.date_from+'/'+this.date_to);
					const mainarray= this.cityarray;
					//console.log(mainarray);
					this.aadkey ="filterDate";
					let resultdate =[]
					
					resultdate = mainarray.filter(d => {
						const  reg_date= d.reg_date;
						const regdate = reg_date.split(' ')[0];					            
						return (regdate >= this.date_from   && regdate  <= this.date_to );            
					
						
					});
					this.datemainArry=resultdate;
				
			
				
			}

		 }else if(this.adid !="" && this.location !=""){
			if(this.adid !=""){
				this.aadkey ="adkey";
				const mainarray= this.elements;				
				const  adid =this.filterchart.value.adid;
				//console.log(adid[0]);
				if(adid[0]==="All-Adid-Select"){
					this.adidarray =this.elements;
				}else{
			
				
				//console.log(adid);
				let filtered = [];
				
				adid.filter(function(newadid) {
					return   mainarray.filter(function(mainArray) {
						if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
						filtered.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status,
						meraemistage:      mainArray.meraemistage,
						
						})
					  }
					})
				  });
				  this.adidarray =filtered;
				}
				 
			} 
			if(this.location !=""){
				const mainarray=this.adidarray;				  
				this.aadkey ="cityname";
				const  location =this.location;
				if(location[0]==="All-city-Select"){
					this.cityarray =mainarray;
				
				
				}else{
			
				
				//console.log(location);
				let filtered = [];
				
				location.filter(function(newCity) {
				return   mainarray.filter(function(mainArray) {
				if (newCity === mainArray.city_name && newCity !="All-city-Select") {
				filtered.push({
				user_id:     mainArray.user_id, 
				first_name:  mainArray.first_name, 
				last_name:   mainArray.last_name,              
				provider:    mainArray.provider,
				city_name:   mainArray.city_name,
				loan_amount: mainArray.loan_amount,
				ad_id:       mainArray.ad_id,
				reg_date:    mainArray.reg_date,
				status:      mainArray.status,
				meraemistage:      mainArray.meraemistage,
			
				
				})
			  }
			})
			});
			this.cityarray =filtered;
			}
			
			}
			this.datemainArry=this.cityarray;
		 }else if(this.adid !="" && this.date_from !="" && this.date_to !=""){			
			if(this.adid !=""){
				this.aadkey ="ad_date";
				const mainarray= this.elements;				
				const  adid =this.filterchart.value.adid;
				if(adid[0]==="All-Adid-Select"){
					this.adidarray =this.elements;
				}else{			
				let filtered = [];				
				adid.filter(function(newadid) {
					return   mainarray.filter(function(mainArray) {
						if (newadid === mainArray.ad_id  && newadid !="All-Adid-Select") {
						filtered.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status,
						meraemistage:      mainArray.meraemistage,
		
						
						})
					  }
					})
				  });
				  this.adidarray =filtered;
				}
				  
				  if(this.date_from !="" && this.date_to !=""){
					this.date_from =this.filterchart.value.datefrom;
					this.date_to =this.filterchart.value.dateto;
					//console.log(this.date_from);
					this.adDate="2020-05-02";
				
						
						this.ToandFromDate =(this.date_from+'/'+this.date_to);
						const mainarray= this.adidarray;
						let filtered =[]
					
						filtered = mainarray.filter(d => {
							const  reg_date= d.reg_date;
							const regdate = reg_date.split(' ')[0];
							//console.log(regdate);
							//console.log(this.date_from);
							//console.log(this.date_to);					            
							return (regdate >= this.date_from   && regdate  <= this.date_to );            
						
							
						});
						this.datemainArry =filtered;
						//console.log(this.datemainArry);
						//console.log(mainarray);
					
				
					
				}
				 
		 }

		 }else if(this.location !="" && this.date_from !="" && this.date_to !=""){
			if(this.location !=""){
				const mainarray=this.elements;					  
				this.aadkey ="location_date";
				const  location =this.location;
				if(location[0]==="All-city-Select"){
					this.cityarray =mainarray;
				}else{
				let filtered = [];
				
				location.filter(function(newadid) {
				return   mainarray.filter(function(mainArray) {
				if (newadid === mainArray.city_name && newadid!="All-city-Select") {
				filtered.push({
				user_id:     mainArray.user_id, 
				first_name:  mainArray.first_name, 
				last_name:   mainArray.last_name,              
				provider:    mainArray.provider,
				city_name:   mainArray.city_name,
				loan_amount: mainArray.loan_amount,
				ad_id:       mainArray.ad_id,
				reg_date:    mainArray.reg_date,
				status:      mainArray.status,
				meraemistage:      mainArray.meraemistage,

				
				})
			  }
			})
		  });
		  this.cityarray =filtered;
		}
		}
		if(this.date_from !="" && this.date_to !=""){
			this.date_from =this.filterchart.value.datefrom;
			this.date_to =this.filterchart.value.dateto;
			this.adDate="2020-05-02";
			
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.cityarray;
				//console.log(mainarray);				
				let resultdate =[]			
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];					            
					return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				console.log(this.datemainArry);
			
		
			
		}
		 }else if(this.adid !="" ){
			this.aadkey ="addkey";
			const mainarray= this.elements;				
			const  adid =this.filterchart.value.adid;
			//console.log(adid);
			if(adid[0]==="All-Adid-Select"){
				let resultdate =[]  
				this.adDate="2020-05-02";
			
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];					            
					return (regdate >= this.adDate   && regdate  <= this.currentdate );            
				
					
				});
				
				this.datemainArry =resultdate;
			}else{

			let filtered = [];
		
			adid.filter(function(newadid) {
				return   mainarray.filter(function(mainArray) {
					if (newadid === mainArray.ad_id && newadid!="All-Adid-Select") {
					filtered.push({
					user_id:     mainArray.user_id, 
					first_name:  mainArray.first_name, 
					last_name:   mainArray.last_name,              
					provider:    mainArray.provider,
					city_name:   mainArray.city_name,
					loan_amount: mainArray.loan_amount,
					ad_id:       mainArray.ad_id,
					reg_date:    mainArray.reg_date,
					status:      mainArray.status,
					meraemistage:      mainArray.meraemistage,
		
					
					})
				  }
				})
			  });
			  this.datemainArry =filtered;
			}
		}else if(this.location !=""){
			const mainarray=this.elements;				  
			this.aadkey ="cityname";
			const  Cityname =this.location;
			//console.log(Cityname);
			if(Cityname[0]==="All-city-Select"){
				this.datemainArry =mainarray;
				const  location =this.optionslocation;
	
							
						let filtered1 = [];
						
						location.filter(function(newadid) {
							//console.log(newadid.value);
						return   mainarray.filter(function(mainArray) {
						if (newadid.value === mainArray.city_name && newadid.value !="All-city-Select") {
						filtered1.push({
						user_id:     mainArray.user_id, 
						first_name:  mainArray.first_name, 
						last_name:   mainArray.last_name,              
						provider:    mainArray.provider,
						city_name:   mainArray.city_name,
						loan_amount: mainArray.loan_amount,
						ad_id:       mainArray.ad_id,
						reg_date:    mainArray.reg_date,
						status:      mainArray.status,
						meraemistage:      mainArray.meraemistage,
					
						
						})
					}
					})
					});
					this.datemainArry =filtered1;
					console.log(this.datemainArry);
			}else{
			let filtered = [];
			const  location =this.location;
			location.filter(function(newadid) {
			return   mainarray.filter(function(mainArray) {
			if (newadid === mainArray.city_name && newadid!="All-city-Select") {
			filtered.push({
			user_id:     mainArray.user_id, 
			first_name:  mainArray.first_name, 
			last_name:   mainArray.last_name,              
			provider:    mainArray.provider,
			city_name:   mainArray.city_name,
			loan_amount: mainArray.loan_amount,
			ad_id:       mainArray.ad_id,
			reg_date:    mainArray.reg_date,
			status:      mainArray.status,
			meraemistage:      mainArray.meraemistage,
		
			
			})
		  }
		})
		});
		this.datemainArry =filtered;
	}
		}else if( this.date_from !="" && this.date_to !=""){
			this.date_from =this.filterchart.value.datefrom;
			this.date_to =this.filterchart.value.dateto;
			this.adDate="2020-05-02";
		
				
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.elements;
				//console.log(mainarray);
				this.aadkey ="Date";
				let resultdate =[]
				
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];	
									            
					return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				//console.log(this.datemainArry.length);
			
		 }else if( this.date_from !="" ){
			//console.log(this.currentdate,		this.FirstDaydate);
			this.date_from =this.filterchart.value.datefrom;
			this.date_to =this.currentdate;
			//console.log(this.date_from,			this.date_to);
			this.adDate="2020-05-02";
		
				
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.elements;
				//console.log(mainarray);
				this.aadkey ="Date";
				let resultdate =[]
				
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];	
					return (reg_date >= this.date_from  && reg_date  <=  this.date_to );				            
				//	return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				//console.log(this.datemainArry.length);
			
		 }else if(this.date_to !=""){
			this.date_from =this.FirstDaydate;
			this.date_to =this.filterchart.value.dateto;
			this.adDate="2020-05-02";
		
				
				this.ToandFromDate =(this.date_from+'/'+this.date_to);
				const mainarray= this.elements;
				//console.log(mainarray);
				this.aadkey ="Date";
				let resultdate =[]
				
				resultdate = mainarray.filter(d => {
					const  reg_date= d.reg_date;
					const regdate = reg_date.split(' ')[0];	
									            
					return (regdate >= this.date_from   && regdate  <= this.date_to );            
				
					
				});
				this.datemainArry =resultdate;
				//console.log(this.datemainArry.length);
			
		 }
		 //graph
		 if(this.datemainArry.length > 0){
			this.main_array=this.datemainArry;
			
		 }
		let mainarry= this.main_array;
	//	console.log(mainarry.length);
		//console.log(mainarry);
		//console.log(mainarry);
		 this.totalLoanamt1 = 0;
									
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 //console.log(element);
			 if(element.status == "Eligibility_Pending"){
			   //console.log(element);
				this.countLoanamt1.push({ status: element.appstage});
				
			 }
		    if(element.status === "Eligibility_Pending" && element.loan_amount != undefined){
				this.totalLoanamt1 += Number(element.loan_amount); 
				//console.log(this.totalLoanamt1);
			   
			 }
			 
		 }	
		 this.CountEligibilityPen=this.countLoanamt1.length;
		 this.TotalEligibilityPend=this.totalLoanamt1
		//console.log(this.CountEligibilityPen);			 
		 
		 //console.log(this.TotalEligibilityPend);
		 this.totalEligibilityClear1 = 0;
		 
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "Eligibility_Clear"){
				//console.log(element);
				this.countEligibilityClear1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "Eligibility_Clear" && element.loan_amount != undefined){
				this.totalEligibilityClear1 += Number(mainarry[i].loan_amount);									
 
			}
			 
		 }	
		 this.CountEligibilityClear=this.countEligibilityClear1.length;
		 this.TotalEligibilityClear=this.totalEligibilityClear1;
		 // console.log(this.CountEligibilityClear);	 
		 // console.log(this.TotalEligibilityClear);
		 this.totalEligibilityFail1 = 0;
		 
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "Eligibility_Fail"){
				//console.log(element);
				this.countEligibilityFail1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "Eligibility_Fail" && element.loan_amount != undefined){
				this.totalEligibilityFail1 += Number(mainarry[i].loan_amount); 
				//this.countEligibilityFail1.push({ status: this.appstage});
			 }
			 
		 }	
		 this.CountEligFail=this.countEligibilityFail1.length;
		 this.TotaltEligFail=this.totalEligibilityFail1;
		 //console.log(this.CountEligFail);		
		 //console.log(this.TotaltEligFail);	
		 
		 this.totalIAPPending1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Pending"){
				//console.log(element);
				this.countIAPPending1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Pending" && element.loan_amount != undefined){
				this.totalIAPPending1 += Number(mainarry[i].loan_amount);									
				//this.countIAPPending1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPPend=this.countIAPPending1.length;
		 this.TotaltIAPPend=this.totalIAPPending1;
		 //console.log(this.countIAPPending.length);			 
		 
		 //console.log(this.totalIAPPending);
		 this.totalIAPSuccess1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Success"){
				//console.log(element);
				this.countIAPSuccess1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Success" && element.loan_amount != undefined){
				this.totalIAPSuccess1 += Number(mainarry[i].loan_amount);									
				//this.countIAPSuccess1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPSuss=this.countIAPSuccess1.length;
		 this.TotaltIAPSuss=this.totalIAPSuccess1;
		 //console.log(this.CountIAPSuss);
		 //console.log(this.TotaltIAPSuss);
		 this.totalIAPFail1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Fail"){
				//console.log(element);
				this.countIAPFail1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Fail" && element.loan_amount != undefined){
				this.totalIAPFail1 += Number(mainarry[i].loan_amount);									
				//this.countIAPFail1.push({ status: this.appstage});
				
			 }
			 
		 }	
		 this.CountIAPFail=this.countIAPFail1.length;
		 this.TotaltIAPFail=this.totalIAPFail1;
		 //console.log(this.countIAPFail.length);			 
		 
		 //console.log(this.totalIAPFail);
		 this.totalIAPComplete1 = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status == "IAP_Complete"){
				//console.log(element);
				this.countIAPComplete1.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Complete" && element.loan_amount != undefined){
				this.totalIAPComplete1 += Number(mainarry[i].loan_amount);									
				//this.countIAPComplete1.push({ status: this.appstage});
				
			 }
			 
		 }
		 this.CountIAPCP=this.countIAPComplete1.length;
		 this.TotaltIAPCP=this.totalIAPComplete1;	
		 this.totalIAPError = 0;							
		 for (let i = 0; i < mainarry.length; i++) {
			 const element = mainarry[i];
			 if(element.status === "IAP_Error"){
				//console.log(element);
				this.countIAPError.push({ status: this.appstage});
				 
			  }
			 if(element.status === "IAP_Error" && element.loan_amount != undefined){
				this.totalIAPError += Number(mainarry[i].loan_amount);									
				//this.countIAPComplete1.push({ status: this.appstage});
				
			 }
			 //console.log(this.countIAPError);
		 }		
		 this.CountIAPError=this.countIAPError.length;
		 this.TotalIAPError=this.totalIAPError;

		// console.log(this.totalIAPError);
	
		 this.total =(this.TotalEligibilityPend+this.TotalEligibilityClear+this.TotaltEligFail+this.TotaltIAPPend+
			this.TotaltIAPSuss+this.TotaltIAPFail+this.TotaltIAPCP+this.TotalIAPError);
//*********************************************************** crm stage ******************************************************************************* */
                          //
							 //Doc Pending
							 this.totalIAPDocP = 0;							
							 for (let i = 0; i < mainarry.length; i++) {
								 const element = mainarry[i];
								 if(element.meraemistage === "Doc Pending"  && element.status === "IAP_Complete"){
																	
									this.countIAPDocp.push({ status: this.appstage});
									
								 }
								 if(element.meraemistage === "Doc Pending" && element.loan_amount != undefined){
									this.totalIAPDocP += Number(element.loan_amount);									
									
									
								 }
								 
							 }	
							 this.CounttotalDocP=this.countIAPDocp.length;
							 this.TotatalDocp=this.totalIAPDocP;


	 // Doc Received
	 this.totalIAPDocR = 0;							
	 for (let i = 0; i < mainarry.length; i++) {
		const element = mainarry[i];
		 if(element.meraemistage === "Doc Received" && element.status === "IAP_Complete"){
											
			this.countIAPDocR.push({ status: this.appstage});
			
		 }
		 if(element.meraemistage === "Doc Received" && element.loan_amount != undefined && element.status === "IAP_Complete"){
			this.totalIAPDocR += Number(element.loan_amount);									
			
			
		 }
		 
	 }	
	 this.CounttotalDocR=this.countIAPDocR.length;
	 this.TotatalDocR=this.totalIAPDocR;
	 //Doc Complete

	 this.totalIAPDocC = 0;							
	 for (let i = 0; i < mainarry.length; i++) {
		const element = mainarry[i];
		 if(element.meraemistage === "Doc Complete" && element.status === "IAP_Complete"){
											
			this.countIAPDocC.push({ status: this.appstage});
			
		 }
		 if(element.meraemistage === "Doc Complete" && element.loan_amount != undefined && element.status === "IAP_Complete"){
			this.totalIAPDocC += Number(element.loan_amount);									
			
			
		 }
		 
	 }	
	 this.CounttotalDocC=this.countIAPDocC.length;
	 this.TotatalDocC=this.totalIAPDocC;
	 //Doc Incomplete
	 this.totalIAPDocIn = 0;							
	 for (let i = 0; i < mainarry.length; i++) {
		const element = mainarry[i];
		 if(element.meraemistage === "Doc Incomplete" && element.status === "IAP_Complete"){
											
			this.countIAPDocIn.push({ status: this.appstage});
			
		 }
		 if(element.meraemistage === "Doc Incomplete" && element.loan_amount != undefined && element.status === "IAP_Complete"){
			this.totalIAPDocIn += Number(element.loan_amount);									
			
			
		 }
		 
	 }	
	 this.CounttotalDocIn=this.countIAPDocIn.length;
	 this.TotatalDocIn=this.totalIAPDocIn;
	 //Pending Review
   
	 this.totalIAPDocIn = 0;							
	 for (let i = 0; i < mainarry.length; i++) {
		const element = mainarry[i];
		 if(element.meraemistage === "Pending Review" && element.status === "IAP_Complete"){
											
			this.countIAPDocPenR.push({ status: this.appstage});
			
		 }
		 if(element.meraemistage === "Pending Review" && element.loan_amount != undefined && element.status === "IAP_Complete"){
			this.totalIAPDocPenR += Number(element.loan_amount);									
			
			
		 }
		 
	 }	
	 this.CounttotalDocPenR=this.countIAPDocPenR.length;
	 this.TotatalDocPenR=this.totalIAPDocPenR;
				   //Transfer
				   this.totalIAPDocTrans = 0;							
				   for (let i = 0; i < mainarry.length; i++) {
					const element = mainarry[i];
					   if(element.meraemistage === "Transfer" && element.status === "IAP_Complete"){
														   
						   this.countIAPDocTrans.push({ status: this.appstage});
						   
					   }
					   if(element.meraemistage === "Transfer" && element.loan_amount != undefined && element.status === "IAP_Complete"){
						   this.totalIAPDocTrans += Number(element.loan_amount);									
						   
						   
					   }
					   
				   }	
				   this.CounttotalDocTrans=this.countIAPDocTrans.length;
				   this.TotatalDocTrans=this.totalIAPDocTrans;
				   //Lender Process
				   this.totalIAPDocLendPr = 0;							
				   for (let i = 0; i < mainarry.length; i++) {
					const element = mainarry[i];
					   if(element.meraemistage === "Lender Process" && element.status === "IAP_Complete"){
														   
						   this.countIAPDocLendPr.push({ status: this.appstage});
						   
					   }
					   if(element.meraemistage === "Lender Process" && element.loan_amount != undefined && element.status === "IAP_Complete"){
						   this.totalIAPDocLendPr += Number(element.loan_amount);									
						   
						   
					   }
					   
				   }	
				   this.CounttotalDocLendPr=this.countIAPDocLendPr.length;
				   this.TotatalDocLendPr=this.totalIAPDocLendPr;
				  // console.log(this.CounttotalDocLendPr);
				  // console.log(this.TotatalDocLendPr);
				   //CRM Cancel
					   this.totalIAPDocCrmC = 0;							
					   for (let i = 0; i < mainarry.length; i++) {
						const element = mainarry[i];
						   if(element.meraemistage === "CRM Cancel" && element.status === "IAP_Complete"){
															   
							   this.countIAPDocCrmC.push({ status: this.appstage});
							   
						   }
						   if(element.meraemistage === "CRM Cancel" && element.loan_amount != undefined && element.status === "IAP_Complete"){
							   this.totalIAPDocCrmC += Number(element.loan_amount);									
							   
							   
						   }
						   
					   }	
					   this.CounttotalDocCrmC=this.countIAPDocCrmC.length;
					   this.TotatalDocCrmC=this.totalIAPDocCrmC;
						//Cust Cancel
						this.totalIAPDocCustC = 0;							
						for (let i = 0; i < mainarry.length; i++) {
							const element = mainarry[i];
							if(element.meraemistage === "Cust Cancel" && element.status === "IAP_Complete" ){
															   
							   this.countIAPDocCustC.push({ status: this.appstage});
							   
							}
							if(element.meraemistage === "Cust Cancel" && element.loan_amount != undefined && element.status === "IAP_Complete"){
							   this.totalIAPDocCustC += Number(element.loan_amount);									
							   
							   
							}
							
						}	
						this.CounttotalDocCustC=this.countIAPDocCustC.length;
						this.TotatalDocCustC=this.totalIAPDocCustC;

						//CRM Decline
						this.totalIAPDocCrmD = 0;							
						for (let i = 0; i < mainarry.length; i++) {
							const element = mainarry[i];
							if(element.meraemistage === "CRM Decline" && element.status === "IAP_Complete"){
															   
							   this.countIAPDocCrmD.push({ status: this.appstage});
							   
							}
							if(element.meraemistage === "CRM Decline" && element.loan_amount != undefined && element.status === "IAP_Complete"){
							   this.totalIAPDocCrmD += Number(element.loan_amount);									
							   
							   
							}
							
						}	
						this.CounttotalDocCrmD=this.countIAPDocCrmD.length;
						this.TotatalDocCrmD=this.totalIAPDocCrmD;
						 //Disbursed
						 this.totalIAPDocDisb = 0;							
						 for (let i = 0; i < mainarry.length; i++) {
							const element = mainarry[i];
							 if(element.meraemistage === "Disbursed"  && element.status === "IAP_Complete"){
																
								this.countIAPDocDisb.push({ status: this.appstage});
								
							 }
							 if(element.meraemistage === "Disbursed" && element.loan_amount != undefined && element.status === "IAP_Complete"){
								this.totalIAPDocDisb += Number(element.loan_amount);									
								
								
							 }
							 
						 }	
						 this.CounttotalDocDisb=this.countIAPDocDisb.length;
						 this.TotatalDocDisb=this.totalIAPDocDisb;

	 this.tatalcrmstageAmt=(this.TotatalDocp+this.TotatalDocR +this.TotatalDocC+this.TotatalDocIn+this.TotatalDocPenR +this.TotatalDocTrans +this.TotatalDocLendPr +this.TotatalDocCrmC+this.TotatalDocCustC +this.TotatalDocCrmD +this.TotatalDocDisb) ;
	 this.totalcrmstagecount=(this.CounttotalDocP+this.CounttotalDocR +this.CounttotalDocC+this.CounttotalDocIn+this.CounttotalDocPenR +this.CounttotalDocTrans +this.CounttotalDocLendPr +this.CounttotalDocCrmC +this.CounttotalDocCustC +this.CounttotalDocCrmD +this.CounttotalDocDisb);



			
		 this.totalcount =(
			 this.CountEligibilityPen+
			this.CountEligibilityClear+
			this.CountEligFail+
			this.CountIAPPend+
			this.CountIAPSuss+
			this.CountIAPFail+
			this.CountIAPCP +this.CountIAPError);		
	    //console.log(this.totalcount);
	
							   let epval =this.TotalEligibilityPend;
							   let ecval =this.TotalEligibilityClear;
							   let efval =this.TotaltEligFail;
							   let ipval =this.TotaltIAPPend;
							   let isval =this.TotaltIAPSuss;
							   let ifval =this.TotaltIAPFail;
							   let icval =this.TotaltIAPCP;

/********************************************** Chart Data 1****************************************** */
let Amount= this.total;   
let TotalUsers =this.totalcount;
//toat amt Eligibility Dropoffs
this.TotalelgDropoffs =this.TotalEligibilityPend;				
this.TotalelgCheckeds =(this.total-this.TotalEligibilityPend);
this.TotalEligibilityPenCont=this.CountEligibilityPen;	

//Eligibility Dropoffs %
let TotalEligibilityPen=this.CountEligibilityPen;			  
this.EligibilityDropoffsPercentage =Math.round((TotalEligibilityPen/TotalUsers)*100);             
let EligibilityChecked=(TotalUsers-TotalEligibilityPen);
let totalEligibilityChecked=(TotalUsers-TotalEligibilityPen);
this.EligibilityCheckedPercentage=Math.round((EligibilityChecked/TotalUsers)*100); 
this.TotalEligibilityPenContChecked=(TotalUsers-TotalEligibilityPen);            
//********************************************** end Chart Data ****************************************** */
// ******************************************** Data table data Eligibility Dropoffs Array 1*********************************************************************//

let TotalAmtDropoffs=Math.round((this.TotalelgDropoffs/Amount)*100); 
//console.log(TotalAmtDropoffs);

this.EligibilityDropoffsArray.push([
{Stages : "Eligibility Dropoffs"} ,
{Count : TotalEligibilityPen},
{Amount : this.TotalelgDropoffs},
{Countper :  this.EligibilityDropoffsPercentage},
{Amountper : TotalAmtDropoffs}


]);
//console.log(this.EligibilityDropoffsArray[0]);
// ************************************************** End Data table data***************************************************************//
// ******************************************** Data table data Eligibility Checked Array 2*********************************************************************//

let TotalAmtChecked=Math.round((this.TotalelgCheckeds/Amount)*100);
//console.log(TotalAmtDropoffs);

this.EligibilityCheckedArray.push([
{Stages : "Eligibility Checked"} ,
{Count : totalEligibilityChecked},
{Amount : this.TotalelgCheckeds},
{Countper :   this.EligibilityCheckedPercentage},
{Amountper : TotalAmtChecked}


]);
//console.log(this.EligibilityCheckedArray);
// ************************************************** End Data table data***************************************************************//
//********************************************** Chart Data 2****************************************** */				 
//total amt Eligibility Checked
this.TotalEliFail=this.TotaltEligFail;			
this.TotalEligSuccess=(this.TotalelgCheckeds-this.TotalEliFail);
this.TotalEligbilityFailCount=this.CountEligFail;
//Eligibility Success and Eligbility Fail

let EligbilityFail=this.CountEligFail;				
this.EligbilityFail=Math.round((EligbilityFail/EligibilityChecked)*100);					
let EligibilitySuccess =(EligibilityChecked-EligbilityFail);				
this.EligibilitySuccess=Math.round((EligibilitySuccess/EligibilityChecked)*100);
this.tatalelgEligibility=Math.round(this.EligbilityFail+this.EligibilitySuccess);
this.TotalEligibilitySuccessCount=(EligibilityChecked-EligbilityFail);

//********************************************** end Chart Data ****************************************** */
// ************************************************** End Data table data Eligibility Fail Array 3***************************************************************//
//console.log(Amount);
//console.log(this.TotalelgDropoffs);
let EligibilityCheckedAmt=(Amount-this.TotalelgDropoffs);
//console.log(this.TotalEliFail);
let EligbilityFailAmountper=Math.round((this.TotalEliFail/EligibilityCheckedAmt)*100);
this.EligibilityFailArray.push([
{Stages : "Eligibility Fail"} ,
{Count : EligbilityFail},
{Amount : this.TotalEliFail},
{Countper : this.EligbilityFail},
{Amountper : EligbilityFailAmountper}


]);
//console.log(this.EligibilityFailArray);
// ************************************************** End Data table data***************************************************************//
// **************************************************  Data table data EligibilitySuccessArray 4***************************************************************//

let totalEligibilitySuccess =(this.TotalelgCheckeds-this.TotalEliFail)
let totalEligibilitySuccessper =Math.round((totalEligibilitySuccess/this.TotalelgCheckeds)*100);

this.EligibilitySuccessArray.push([
{Stages : "Eligibility Success"} ,
{Count : EligibilitySuccess},
{Amount : totalEligibilitySuccess},
{Countper :  this.EligibilitySuccess},
{Amountper : totalEligibilitySuccessper}


]);
//console.log(this.EligibilitySuccessArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data***************************************************************//
//total amt Eligibility Success
this.TotalElilSuccDropoff=this.TotalEligibilityClear;

this.TotalIAPTotal=(this.TotaltIAPPend	+this.TotaltIAPSuss	+this.TotaltIAPFail+this.TotaltIAPCP);
this.TotalEligibilitySuccessDropoffCount= this.CountEligibilityClear;
// Eligibility Success Dropoff
let EligibilitySuccessDropoff= this.CountEligibilityClear;
let EligibilitySuccess_Dropoff =Math.round((EligibilitySuccessDropoff/EligibilitySuccess)*100);
if(EligibilitySuccess_Dropoff !=Infinity){
this.EligibilitySuccessDropoff =EligibilitySuccess_Dropoff;
}else{
this.EligibilitySuccessDropoff =0;
}
let IAPTotal=(this.CountIAPPend+this.CountIAPSuss+this.CountIAPFail+this.CountIAPCP);
this.IAPTotal =Math.round((IAPTotal/EligibilitySuccess)*100);			
this.IAPTotalPercentage=Math.round(this.IAPTotal+this.EligibilitySuccessDropoff);
this.TotalIAPTotalCount=IAPTotal;
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data Eligibility Success Dropoff array***************************************************************//

let EligibilityClearAmt=this.TotalEligibilityClear;
let TotalAmtEligibilitySuccessDropoff=Math.round((EligibilityClearAmt/totalEligibilitySuccess)*100);
this.EligibilitySuccessDropoffArray.push([
{Stages : "Eligibility Success"} ,
{Count : EligibilitySuccessDropoff},
{Amount : this.TotalElilSuccDropoff},
{Countper :    this.EligibilitySuccessDropoff},
{Amountper : TotalAmtEligibilitySuccessDropoff}


]);
// console.log( this.EligibilitySuccessDropoffArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Total array***************************************************************//

//let IAPTotalamt=(totalEligibilitySuccess-EligibilityClearAmt);

let IAPTotalamt=Math.round((this.TotalIAPTotal/totalEligibilitySuccess)*100);
//console.log(IAPTotalamt);
this.IAPTotalffArray.push([
{Stages : "IAP Total"} ,
{Count : IAPTotal},
{Amount : this.TotalIAPTotal},
{Countper :   this.IAPTotal},
{Amountper : IAPTotalamt}
 

]);
//console.log( this.IAPTotalffArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data***************************************************************//
//total amt IAP Total
this.TotalIAPPending =this.TotaltIAPPend;				
this.IapApiCalled = (this.TotalIAPTotal-this.TotalIAPPending);
this.TotalIAPPendingCount =this.CountIAPPend;
// IAP Total
let IAPPending =this.CountIAPPend;
this.IAPPending=Math.round((IAPPending/IAPTotal)*100);
//console.log(this.IAPPending);

let IAPAPICalled=(IAPTotal-IAPPending);
this.IAPAPICalled=Math.round((IAPAPICalled/IAPTotal)*100);
//console.log(this.IAPAPICalled);
this.IAPTotalCount=(this.IAPAPICalled+this.IAPPending);
//console.log(this.IAPTotalCount);

// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Total***************************************************************//
let totalTotaltIAPPend=this.TotaltIAPPend;									
let IAPPendingamtPr=Math.round((totalTotaltIAPPend/this.TotalIAPTotal)*100);					
this.IAPPendingArray.push([
   {Stages : "IAP Pending"} ,
   {Count : IAPPending},
   {Amount : this.TotalIAPPending},
   {Countper :   this.IAPPending},
   {Amountper : IAPPendingamtPr}
   

]);
//	console.log( this.IAPPendingArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP API Called Array***************************************************************//

//console.log(totalTotaltIAPPend);

let IAPAPICalledAmt=(this.TotalIAPTotal-totalTotaltIAPPend);	
   
let IAPAPICalledAmtPr=Math.round((IAPAPICalledAmt/this.TotalIAPTotal)*100);
//console.log(IAPAPICalledAmtPr);		
//console.log(IAPTotalamt);
this.IAPAPICalledArray.push([
   {Stages : "IAP Complete"} ,
   {Count : IAPAPICalled},
   {Amount : this.IapApiCalled},
   {Countper :    this.IAPAPICalled},
   {Amountper : IAPAPICalledAmtPr}
   

]);
//console.log( this.IAPAPICalledArray);
// ************************************************** End Data table data***************************************************************//


// ************************************************** CRM STAGE  data IAP Total***************************************************************//
//tatalcrm 



let totalcrmcount =this.totalcrmstagecount;
let totalcountpercentage=this.IAPAPICalled;
let totalcrmAmt=this.tatalcrmstageAmt;
let totalcrmAmtPercentage=IAPAPICalledAmtPr;
this.tataolcrmArray.push([
	{Stages : "CRM Status"} ,
	{Count : totalcrmcount},
	{Amount : totalcrmAmt},
	{Countper :    totalcountpercentage},
	{Amountper : totalcrmAmtPercentage}
	

]);
//console.log(this.tatalcrmstageAmt);
//console.log(this.totalcrmstagecount);

//Doc Pending

//console.log(this.CounttotalDocP);
//console.log(this.TotatalDocp);
let totalcountDocP=this.CounttotalDocP;
let totalAmtDocP=this.TotatalDocp;
let totalcountDocPerc=Math.round((totalcountDocP/this.totalcrmstagecount)*100);
let totalAmtDocPerc=Math.round((totalAmtDocP/this.tatalcrmstageAmt)*100);
this.tatalDocPendArray.push([
	{Stages : "Doc Pending"} ,
	{Count : totalcountDocP},
	{Amount : totalAmtDocP},
	{Countper :    totalcountDocPerc},
	{Amountper : totalAmtDocPerc}
	

]);

//Doc Received

//console.log(this.CounttotalDocP);
//console.log(this.TotatalDocp);
let totalcountDocRec=this.CounttotalDocR;
let totalAmtDocRec=this.TotatalDocR;

let totalcountDocPercRec=Math.round((totalcountDocRec/this.totalcrmstagecount)*100);
let totalAmtDocPercRec=Math.round((totalAmtDocRec/this.tatalcrmstageAmt)*100);
this.tatalDocRecArray.push([
	{Stages : "Doc Received"} ,
	{Count : totalcountDocRec},
	{Amount : totalAmtDocRec},
	{Countper :    totalcountDocPercRec},
	{Amountper : totalAmtDocPercRec}
	

]);


//Doc Complete
let totalcountDocCamp=this.CounttotalDocC;
let totalAmtDocCamp=this.TotatalDocC;

let totalcountDocPercCamp=Math.round((totalcountDocCamp/this.totalcrmstagecount)*100);
let totalAmtDocPercCamp=Math.round((totalAmtDocCamp/this.tatalcrmstageAmt)*100);
this.tatalDocCampArray.push([
	{Stages : "Doc Complete"} ,
	{Count : totalcountDocCamp},
	{Amount : totalAmtDocCamp},
	{Countper :    totalcountDocPercCamp},
	{Amountper : totalAmtDocPercCamp}
	

]);
//Doc Incomplete
let totalcountDocInc=this.CounttotalDocIn;
let totalAmtDocInc=this.TotatalDocIn;

let totalcountDocPercInc=Math.round((totalcountDocInc/this.totalcrmstagecount)*100);
let totalAmtDocPercInc=Math.round((totalAmtDocInc/this.tatalcrmstageAmt)*100);
this.tatalDocIncArray.push([
	{Stages : "Doc Incomplete"} ,
	{Count : totalcountDocInc},
	{Amount : totalAmtDocInc},
	{Countper :    totalcountDocPercInc},
	{Amountper : totalAmtDocPercInc}
	

]);

//Pending Review
let totalcountDocPenr=this.CounttotalDocPenR;
let totalAmtDocPenr=this.TotatalDocPenR;

let totalcountDocPercPenr=Math.round((totalcountDocPenr/this.totalcrmstagecount)*100);
let totalAmtDocPercPenr=Math.round((totalAmtDocPenr/this.tatalcrmstageAmt)*100);
this.tatalDocPendRArray.push([
	{Stages : "Pending Review"} ,
	{Count : totalcountDocPenr},
	{Amount : totalAmtDocPenr},
	{Countper :    totalcountDocPercPenr},
	{Amountper : totalAmtDocPercPenr}
	

]);

//Transfer
let totalcountDocTrans=this.CounttotalDocTrans;
let totalAmtDocTrans=this.TotatalDocTrans;

let totalcountDocPercTrans=Math.round((totalcountDocTrans/this.totalcrmstagecount)*100);
let totalAmtDocPercTrans=Math.round((totalAmtDocTrans/this.tatalcrmstageAmt)*100);
this.tatalDocTransRArray.push([
	{Stages : "Transfer"} ,
	{Count : totalcountDocTrans},
	{Amount : totalAmtDocTrans},
	{Countper :    totalcountDocPercTrans},
	{Amountper : totalAmtDocPercTrans}
	

]);

//Lender Process
let totalcountDocLendPr=this.CounttotalDocLendPr;
let totalAmtDocLendPr=this.TotatalDocLendPr;

let totalcountDocLenderPr=Math.round((totalcountDocLendPr/this.totalcrmstagecount)*100);
let totalAmtDocLenderPr=Math.round((totalAmtDocLendPr/this.tatalcrmstageAmt)*100);
this.tatalDocLenderPrArray.push([
	{Stages : "Lender Process"} ,
	{Count : totalcountDocLendPr},
	{Amount : totalAmtDocLendPr},
	{Countper :    totalcountDocLenderPr},
	{Amountper : totalAmtDocLenderPr}
	

]);



//Lender Process
let totalcountDocCrmC=this.CounttotalDocCrmC;
let totalAmtDocCrmC=this.TotatalDocCrmC;

let totalcountDocCrmCan=Math.round((totalcountDocCrmC/this.totalcrmstagecount)*100);
let totalAmtDocCrmCan=Math.round((totalAmtDocCrmC/this.tatalcrmstageAmt)*100);
this.tatalDocCrmCansArray.push([
	{Stages : "CRM Cancel"} ,
	{Count : totalcountDocCrmC},
	{Amount : totalAmtDocCrmC},
	{Countper :    totalcountDocCrmCan},
	{Amountper : totalAmtDocCrmCan}
	

]);

this.CounttotalDocCustC=this.countIAPDocCustC.length;
this.TotatalDocCustC=this.totalIAPDocCustC;

//Cust Cancel
let totalcountDocCustC=this.CounttotalDocCustC;
let totalAmtDocCustC=this.TotatalDocCustC;

let totalcountDocCustCan=Math.round((totalcountDocCustC/this.totalcrmstagecount)*100);
let totalAmtDocCustCan=Math.round((totalAmtDocCustC/this.tatalcrmstageAmt)*100);
this.tatalDocCustCanArray.push([
	{Stages : "Cust Cancel"} ,
	{Count : totalcountDocCustC},
	{Amount : totalAmtDocCustC},
	{Countper :    totalcountDocCustCan},
	{Amountper : totalAmtDocCustCan}
	

]);


//CRM Decline
let totalcountCrmD=this.CounttotalDocCrmD;
let totalAmtCrmD=this.TotatalDocCrmD;

let totalcountDrmdec=Math.round((totalcountCrmD/this.totalcrmstagecount)*100);
let totalAmtCrmDec=Math.round((totalAmtCrmD/this.tatalcrmstageAmt)*100);
this.tatalCrmDanArray.push([
	{Stages : "CRM Decline"} ,
	{Count : totalcountCrmD},
	{Amount : totalAmtCrmD},
	{Countper :    totalcountDrmdec},
	{Amountper : totalAmtCrmDec}
	

]);
this.CounttotalDocDisb=this.countIAPDocDisb.length;
this.TotatalDocDisb=this.totalIAPDocDisb;
//Disbursed
let totalcountDisb=this.CounttotalDocDisb;
let totalAmtDisb=this.TotatalDocDisb;

let totalcountDisbu=Math.round((totalcountDisb/this.totalcrmstagecount)*100);
let totalAmtDisbu=Math.round((totalAmtDisb/this.tatalcrmstageAmt)*100);
this.tatalDisbuArray.push([
	{Stages : "Disbursed"} ,
	{Count : totalcountDisb},
	{Amount : totalAmtDisb},
	{Countper :    totalcountDisbu},
	{Amountper : totalAmtDisbu}
	

]);



// ************************************************** End Data table data***************************************************************//














// ************************************************** End Data table data***************************************************************//
//total amt IAP API Called
this.TotalIapApiError=this.TotaltIAPCP;
//console.log(this.TotalIapApiError);
this.TotalIapAaiSuccess=(this.IapApiCalled-this.TotalIapApiError);
//console.log(this.TotalIapAaiSuccess);
//IAP API Called

let IAPAPIError=this.CountIAPCP;				
this.IAPAPIError=Math.round((IAPAPIError/IAPAPICalled)*100);
//console.log(this.IAPAPIError);
let IAPAPISuccess=(IAPAPICalled-IAPAPIError);
//console.log(IAPAPISuccess);
this.IAPAPISuccess	=Math.round((IAPAPISuccess/IAPAPICalled)*100);			
this.IAPAPICalledCount =Math.round(this.IAPAPISuccess+this.IAPAPIError);

// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP API Called***************************************************************//
let IAPAPIErrorAmt =this.TotaltIAPCP;
let IAPAPIErrorAmtCount=Math.round((IAPAPIErrorAmt/IAPAPICalledAmt)*100);	

//console.log(IAPAPIErrorAmt);
this.IAPAPIErrorArray.push([
  // {Stages : "IAP API Error"} ,
   {Stages : "IAP Complete"} ,
   
   {Count : IAPAPIError},
   {Amount : this.TotalIapApiError},
   {Countper : this.IAPAPIError},
   {Amountper : IAPAPIErrorAmtCount}
   
]);
//console.log(this.IAPAPIErrorArray);
// ************************************************** End Data table data***************************************************************//



// ************************************************** End Data table data IAP API Called***************************************************************//
   let IAPAPISuccessAmt =(IAPAPICalledAmt-IAPAPIErrorAmt);
//	console.log(IAPAPISuccessAmt);
   let IAPAPISuccessAmtCount=Math.round((IAPAPISuccessAmt/IAPAPICalledAmt)*100);	
   
   //console.log(IAPAPIErrorAmt);
   this.IAPAPISuccessArray.push([
       {Stages : "IAP API Success"} ,
       {Count : IAPAPISuccess},
       {Amount : this.TotalIapAaiSuccess},
       {Countper : this.IAPAPISuccess},
       {Amountper : IAPAPISuccessAmtCount}
       
   ]);
   //console.log(this.IAPAPISuccessArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data***************************************************************//

//Totalam IAP API Success
this.TotalIAPFail=this.TotaltIAPFail;
//console.log(this.TotalIAPFail);
this.TotalIAPClear=(this.TotalIapAaiSuccess-this.TotalIAPFail);
//console.log(this.TotalIAPClear);
// IAP API Success
let IAPFail=this.CountIAPFail;				
this.IAPFail=Math.round((IAPFail/IAPAPISuccess)*100);
let IAPClear=(IAPAPISuccess-IAPFail);
this.IAPClear=Math.round((IAPClear/IAPAPISuccess)*100);	
// **********************************************  End char data ****************************************************//
// ************************************************** End Data table data IAP Fail***************************************************************//
   let IAPFailAmt =this.TotaltIAPFail;	
   let IAPFailAmtCount=Math.round((IAPFailAmt/IAPAPISuccessAmt)*100);						
       //  console.log(IAPFailAmtCount);
   this.IAPFailArray.push([
       {Stages : "IAP Fail"} ,
       {Count : IAPFail},
       {Amount : this.TotalIAPFail},
       {Countper : this.IAPFail},
       {Amountper : IAPFailAmtCount}
       
   ]);
   //console.log(this.IAPFailArray);
// ************************************************** End Data table data***************************************************************//
// ************************************************** End Data table data IAP Clear***************************************************************//
   let IAPClearAmt =(IAPAPISuccessAmt-this.TotaltIAPFail);	
   let IAPClearAmtCount=Math.round((IAPClearAmt/IAPAPISuccessAmt)*100);						
  //console.log(IAPClearAmtCount);
   this.IAPClearArray.push([
       {Stages : "IAP Clear"} ,
       {Count : IAPClear},
       {Amount : this.TotalIAPClear},
       {Countper : this.IAPClear},
       {Amountper : IAPClearAmtCount}
       
   ]);
   //console.log(this.IAPClearArray);
// ************************************************** End Data table data***************************************************************//


let chart = new CanvasJS.Chart("chartContainer", {
       animationEnabled: true,
       theme: "light2",
       title:{
           text: "Mera EMI Report"
       },
       axisY2:{
           
           lineThickness: 10,
           // prefix: "%"				
       },
       toolTip: {
           shared: false
       },
       legend:{
           verticalAlign: "top",
           horizontalAlign: "center"
       },
       data: [
       {     
           type: "stackedBar",
           showInLegend: false,							
           axisYType: "secondary",
           color: "#8bc34a",							
           toolTipContent: "<b>{name}</b>: {y}% <br><b>Nos</b>: {total}<br><b>Value</b>: ₹{totalvalue}",
           indexLabel: "{name}-{y}%",
           indexLabelFontSize: 10,
           
           dataPoints: [
            //    { y: 10,label: "IAP Clear" , name: "IAP Disbursed" ,total:"NA",totalvalue:"NA"},
            //    { y: this.IAPFail, label: "IAP API Success" ,name: "IAP Fail"  ,total:IAPFail,totalvalue:this.TotalIAPFail},
              
			  // { y: this.IAPAPIError, label: "IAP API Called", name: "IAP API Error" ,total:IAPAPIError,totalvalue:this.TotalIapApiError },
			   { y: this.IAPAPIError, label: "IAP API Called", name: "IAP Complete" ,total:IAPAPIError,totalvalue:this.TotalIapApiError },
              
			   
			   { y: this.IAPPending,  label: "IAP Total", name: "IAP Pending"  ,total:IAPPending,totalvalue:this.TotalIAPPending},
               { y: this.EligibilitySuccessDropoff, label: "Eligibility Success", name: "Eliligbility Success Dropoff",total:EligibilitySuccessDropoff,totalvalue:this.TotalElilSuccDropoff  },
               { y: this.EligbilityFail, label: "Eligibility Checked", name: "Eligibility Fail" ,total:EligbilityFail,totalvalue:this.TotalEliFail },
               { y: this.EligibilityDropoffsPercentage, label: "Total Users" , name: "Eligibility Dropoffs" ,total:TotalEligibilityPen,totalvalue:this.TotalelgDropoffs },
           ]
       },
       {
           type: "stackedBar",
           showInLegend: false,							
           axisYType: "secondary",
           color: "#cddc39",
           toolTipContent: "<b>{name}</b>: {y}% <br><b>Nos</b>: {total}<br><b>Value</b>: ₹{totalvalue}",
           indexLabel: "{name}-{y}%",
           indexLabelFontSize: 10,
           dataPoints: [									
            //    { y: 90, label: "IAP Clear" ,name: "IAP Cancel",total:"NA",totalvalue:"NA" },
            //    { y: this.IAPClear, label: "IAP API Success" ,name: "IAP Clear" ,total:IAPClear,totalvalue:this.TotalIAPClear},
               { y: this.IAPAPISuccess, label: "IAP API Called", name: "IAP API Success", total:IAPAPISuccess,totalvalue:this.TotalIapAaiSuccess},
               { y: this.IAPAPICalled, label: "IAP Total", name: "IAP API Called" ,total:IAPAPICalled,totalvalue:this.IapApiCalled  },
               { y: this.IAPTotal, label: "Eligibility Success", name: "IAP Total" ,total:IAPTotal,totalvalue: this.TotalIAPTotal },
               { y: this.EligibilitySuccess, label: "Eligibility Checked", name: "Eligibility Success" ,total:EligibilitySuccess,totalvalue:this.TotalEligSuccess },
               { y: this.EligibilityCheckedPercentage, label: "Total Users",name: "Eligibility Checked" ,total:totalEligibilityChecked,totalvalue:this.TotalelgCheckeds}
           ]
       }
       
       ]
});

chart.render();


		}
	
	getLeadStage(stage){ 
		//console.log(stage); 
		var res = stage.split("/");
		//console.log(res); 
		const appstage =res[0];
		const filter =res[1];
		const cityname =res[2];
		const adkey =res[3];
		const date =res[4]+'/'+res[5];
		if(adkey === "filterDate"){
			//alert('ok');
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;	  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();
			const main_url=mainurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			// console.log(user.user_id);
		//console.log(dateurl2);
		window.open(dateurl2, "_blank");
		}else if(adkey === "ad_location"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;	  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();
			const main_url=mainurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="ad_date"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();			
			const dateurl=mainurl+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			//console.log(this.fulldate); 
			window.open(dateurl2, "_blank");
		}else if(adkey ==="location_date"){
			this.appStage = this.cryptojsService.encryptData((appstage));				  
			this.filterCityname = cityname;	 
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();			
			const main_url=detailsurl+"&data3="+this.filterCityname.toString();
			const dateurl=main_url+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="addkey"){
			
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.filterStage = filter;
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const mainurl=detailsurl+"&data2="+this.filterStage.toString();		
			const dateurl2=mainurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="cityname"){
			this.appStage = this.cryptojsService.encryptData((appstage));				  
			this.filterCityname = cityname;
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();			
			const main_url=detailsurl+"&data3="+this.filterCityname.toString();			
			const dateurl2=main_url+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}else if(adkey ==="Date"){
			this.appStage = this.cryptojsService.encryptData((appstage));
			this.fulldate= date; 
			const base_url=location.origin+"/#/leads-stage-least?data=";
			const detailsurl = base_url + this.appStage.toString();
			const dateurl=detailsurl+"&data4="+this.fulldate.toString();
			const dateurl2=dateurl+"&data5="+adkey.toString();
			window.open(dateurl2, "_blank");
		}
		
		else{
		this.appStage = this.cryptojsService.encryptData((appstage));
		this.filterStage = filter;
	   //const  out =  useid.replace(/\s/g, "");
	   //console.log(this.useid);
		const base_url=location.origin+"/#/leads-stage-least?data=";
		const detailsurl = base_url + this.appStage.toString();
		const mainurl=detailsurl+"&data2="+this.filterStage.toString();
	   // console.log(user.user_id);
		//console.log(mainurl);
		window.open(mainurl, "_blank");
		}
		
		
		
	}
	DashbordReset(user_id){
		this.filterchart.reset();
		this.main_array=0;
		window.location.reload();
	}
	backmenu(){
		this.showLoader = false;
		setTimeout(()=>{    
		  //this.showLoader = true;
		  this.router.navigate(['/dashboard']);
		}, 2000);
	   
		
		}
		rotate(event){
			event.srcElement.classList.remove("rotate");
			event.srcElement.classList.remove("tooltiptext");
			setTimeout(()=>{
			  event.srcElement.classList.add("rotate");
			  event.srcElement.classList.remove("tooltiptext");
			location.reload();
			},0)
		  }
		  ngAfterViewInit() {
			Promise.resolve().then(() => {
				//alert(CollapseComponent);
			  this.collapses.forEach((collapse: CollapseComponent) => {
			
				collapse.toggle();
			  });
			})
		  }
		  collapsed(){
			 console.log('fsg');
		  }
}