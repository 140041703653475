import { Component, OnInit, EventEmitter, Input, Output, ViewChild ,ElementRef} from '@angular/core';
import { IMyOptions, MDBDatePickerComponent, ClockPickerComponent } from 'ng-uikit-pro-standard';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoansService } from 'src/app/services/loans/loans.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';
//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-mis-list-details',
  templateUrl: './mis-list-details.component.html',
  styleUrls: ['./mis-list-details.component.scss']
})
export class MisListDetailsComponent implements OnInit {
  public radioModel: string = 'Left';
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('datePicker', { static: true }) datePicker: MDBDatePickerComponent;
  @ViewChild('timePicker', { static: true }) timePicker: ClockPickerComponent;
  darkClock: any;
  user:any;
  subs: Subscription;
  sub: any;
  data: any;
  showLoader: boolean;
  decryptData: any;
  leadDetails: any=[];
  elements: any=[];
  elements_feedback: any=[];
  meraemiStage: any=[];
  showDocuments:boolean=false;
  custsD = {};
  cust: any;
  ProposedEmi: number;
  foir: number;
  e_emi: number;
  interest_rate: number;
  isTrackProgress:boolean=true;
  trackData= {};
  trxStatus: any;
  trxSubStatus: any;
  requestJsonDisabled:boolean=false;
  responseJsonDisabled:boolean=false;
  trackProgressJsonDisabled:boolean=false;
  iapstage: string;
  iap_stage_color: string;
  eligstage: string;
 
  elig_stage_color: string;
  userdetils: {};
  //registerForm: any;
  registerForm: FormGroup;
  useridSubmitted: boolean;
  from_user_id: any;
  message: any;
  user_id: any;
  feedForId: any;
  display: string;
  name = 'Angular 5';
  checkFormArray: Array<any> = [];
  // categories = [ 
  //   {"checked": true, name :"Active", id: 1},
  //   {"checked": false, name :"Cancel", id: 2},
  //   {"checked": false, name :"Transfer", id: 3},
    
  // ];
  followupdate: any;
  interactiondate: any;
  status: any;
  followuptime: any;
  today= new Date();
  jstoday = '';
  popupaddBtn: number;
  mesg: string;
  messagediv: number;
  checkAction: string;
  fedbacklength: any;
  fedbackStage: any;  
  currentdate: string;
  userdate: string;
  rolecode: any;
  popupbtnactive: any;
  loginAdmin: any;
  loanid: any;
  elig_status: string;
  eligStatus: any;
 
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private leadsService:LeadsService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,
   
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
   
    
  ) { this.jstoday = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US'); }
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  onDateChange = (event: any) => {
    this.input.nativeElement.value = event.actualDateFormatted; // set value to input
    this.datePicker.closeBtnClicked(); // close date picker
    this.timePicker.openBtnClicked(); // open time picker
  };

  onTimeChange = (event: any) => {
    this.input.nativeElement.value = `${this.input.nativeElement.value}, ${event}`; // set value to input
  };
  ngOnInit() {
    
  //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        )
      }
     
    
    });
    
    // validation input form
    this.registerForm = this.formBuilder.group({
        idFilter: this.formBuilder.group({
        message: ['', Validators.required],        
        meremistage: ['', Validators.required],
       // follwuptime: ['', Validators.required],
        
       
      })
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        //console.log(this.data);
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
         
          //console.log(this.decryptData);
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"user_id":parseInt(this.decryptData)}];
            //console.log(this.custsD)
            this.cust = this.custsD["results"];
            //console.log(this.custsD["results"])
           
            this.userdetils =this.custsD;
          }else{
            this.showLoader = true;    
            this.router.navigate(['/leads']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
     
       // console.log(data)
          this.loansService.getLoanDetByUser(this.userdetils).subscribe( 
            
            (leadres:any) => {
             //console.log(leadres);
              if(leadres.status != false){
                this.leadDetails =leadres['data'];
               // console.log(this.leadDetails);
                this.leadDetails.forEach(element => {
                  
                   // console.log(this.elig_status);
                  let monthlyIncome = Math.round(element.loans[0].employmentDetails.net_monthly_income);
    
                  let lender_id =element.loans[0].lenderDetails.lender_id;
                  let product_id = element.loans[0].loanDetails.product_id;
    
                 //console.log(lender_id)
                  this.custsD["results"] = [{
                    "user_income":monthlyIncome.toString(),
                    "lender_id":lender_id,
                    "product_id":product_id,
                  }];
                
                  //console.log(this.custsD)
                  this.leadsService.getSuccessParameters(this.custsD).subscribe( 
                    (result:any) => {
                    //console.log(result.data)
                      if(result.status ==true){
                        
                        this.interest_rate =result.data[0].interest_rate;
                        let tenure = element.loans[0].loanDetails.loan_tenure;
                        let principal = element.loans[0].loanDetails.loan_amount;
                        if(this.interest_rate != undefined  && tenure != undefined  && principal != undefined ){
                          this.ProposedEmi = this.calculateEMI(this.interest_rate,tenure,principal);
                        }
                         if(isNaN(this.ProposedEmi)){
                          this.ProposedEmi = 0;
                        }
                        let existingEmi = element.loans[0].employmentDetails.total_emi_paid;
                        
                        if(existingEmi != undefined && existingEmi != 0){
                          this.e_emi = Math.round(existingEmi);
          
                        }else{
                          this.e_emi = 0;
                        }
                      
                        this.foir = this.calculateFOIR(this.e_emi,this.ProposedEmi,monthlyIncome);
                        
                        if(element.loans[0].app_stage == "2" ){
                          this.iapstage = "IAP Pending";
                        }
                        if(element.loans[0].resp_status_code != undefined){
    
                          if(element.data[0].iap_status.resp_status_code == "1" ){
                            this.iapstage ="IAP Complete";
                          }
                          if(element.data[0].iap_status.resp_status_code == "0" || element.data[0].iap_status.resp_status_code == "3" || element.data[0].iap_status.resp_status_code == "4"){
                            this.iapstage ="IAP Fail";
                          }
                        }
                       // console.log(element.loans[0].Idfc_response.sfdc_id)
                        if(element.loans[0].Idfc_response != "undefined" && element.loans[0].Idfc_response){
                            ///iap stage 
                            if(element.loans[0].Idfc_response.sfdc_id != "null" || element.loans[0].Idfc_response.trx_status == 'DDE' || element.loans[0].Idfc_response.trx_status == 'QDE'){
                            
                              this.iapstage ="IAP Complete";
                            } 
                            if(element.loans[0].Idfc_response.sfdc_id == "null" || element.loans[0].Idfc_response.trx_status == 'Cancel' || element.loans[0].Idfc_response.sfdc_id == undefined || element.loans[0].Idfc_response.status == '0'){
                              this.iapstage ="IAP Error";
                            } 
                            if(element.loans[0].Idfc_response.trx_status == 'Reject'){
                              this.iapstage ="IAP Fail";
                              this.iap_stage_color ="danger";
                            } 
                            if(element.loans[0].Idfc_response.trx_status == 'Data Verification'){
                              this.iapstage = "IAP Success";
                            }
                          
                          this.trxStatus = element.loans[0].Idfc_response.trx_status;
                          this.trxSubStatus = element.loans[0].Idfc_response.trx_substatus;
    
                          //check user trackprogress status btn enable disable
                          if(element.loans[0].Idfc_response.trx_status == "DDE" || element.loans[0].Idfc_response.trx_status == "QDE" || element.loans[0].Idfc_response.trx_status == "Data Verification"){
                            this.isTrackProgress = this.isTrackProgress;
    
                          }else{
                            this.isTrackProgress = !this.isTrackProgress;
                          }
    
                          //request and response json btn enable disable
                    
                          if(element.loans[0].Idfc_response.request_file_name == '' || element.loans[0].Idfc_response.request_file_name == 'null'  || element.loans[0].Idfc_response.request_file_name == 'undefined'){
                            this.requestJsonDisabled = this.requestJsonDisabled;
                          }else{
                            this.requestJsonDisabled = !this.requestJsonDisabled;
                          }
                        
                         
                          if(element.loans[0].Idfc_response.response_file_name == '' || element.loans[0].Idfc_response.response_file_name == "null" && element.loans[0].Idfc_response.response_file_name != 'undefined'){
                            this.responseJsonDisabled = this.responseJsonDisabled;
                          }else{
                            this.responseJsonDisabled = !this.responseJsonDisabled;
    
                          }
                          if(element.loans[0].Idfc_response.trackprogress_file_name == '' || element.loans[0].Idfc_response.trackprogress_file_name == "null" && element.loans[0].Idfc_response.trackprogress_file_name != 'undefined'){
                            this.trackProgressJsonDisabled = this.trackProgressJsonDisabled;
                          }else{
                            this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
    
                          }
                          
                        }else{
                          this.isTrackProgress = !this.isTrackProgress;
                          this.requestJsonDisabled = !this.requestJsonDisabled;
                          this.responseJsonDisabled = !this.responseJsonDisabled;
                          this.trackProgressJsonDisabled = !this.trackProgressJsonDisabled;
                        }
                        
                      }
                    });
                  
                   // console.log(element.loans[0].elig_fail_quest)
                    if(element.loans[0].elig_fail_quest){
                      for (let index = 0; index < element.loans[0].elig_fail_quest.length; index++) {
                        const value = element.loans[0].elig_fail_quest;
                       //console.log(element.loans[0].elig_fail_quest.length)
                       value.forEach(result=> {
                        
                          // console.log(result.quest_id)
                          // console.log(result.quest_value)
                          // console.log(element.loans[0].loanDetails.loan_amount)
                          // if(result.quest_id == 14){
                          //   console.log(element.loans[0].loanDetails.loan_amount)
                          // }
                        });
                      
                      }
                    }
                    if(element.loans[0].documents){
                      for (let index = 0; index < element.loans[0].documents.length; index++) {
                        const elements = element.loans[0].documents;
                       // console.log(elements)
                        elements.forEach(result=> {
                          if(result['DocTypeId'] !=undefined){
                            if(result['DocTypeId'].length < element.loans[0].documents.length){
                            //  console.log(true)
                              this.showDocuments=true;
                            }else{
                           //   console.log(false)
                              this.showDocuments=false;
                            }
                          }
                         
                        });
                      
                      }
                    }
                  
                
                });
                //main console
               // console.log(this.leadDetails);
              }
                
          
            })

            let details = {data: { results: [{ "user_id":this.decryptData}] } };
            details['data']['results']; 
            this.FeedbackService.postfeedback_getcrm(details)
            .subscribe((result: any) => {  
              //console.log(result);
              if (result.status == true) {
               // console.log(result);
                for (let i = 0; i < result.feedvalue.length; i++) {
                  const element = result.feedvalue[i];
                  //console.log(element.data[0].stage);
                 // console.log(element.data[0].status);
                 // console.log(element);
                 
                  if(element.data[0].stage == "Active"   && element.data[0].status ==='1'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }if(element.data[0].stage == "Active"   && element.data[0].status ==='2'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        status:  element.data[0].status, 
                        
                      }
                      );
                  }
                  
                  else if(element.data[0].stage == "Transfer" && element.data[0].status === '1'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }else if(element.data[0].stage == "Transfer" && element.data[0].status === '2'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }                
                  else if(element.data[0].stage == "Cancel" && element.data[0].status === '1'){
                    this.meraemiStage.push(
                      {
                        stage:  element.data[0].stage, 
                        
                      }
                      );
                  }
                  
                  this.elements_feedback.push(
                    {
                      admin_name: element.data[0].admin_name, 
                      feedback_id: element.data[0].feedback_id, 
                      feedback_message:  element.data[0].feedback_message, 
                      followupdate:  element.data[0].followupdate, 
                      interactiondate:  element.data[0].interactiondate, 
                      stage:  element.data[0].stage, 
                      user_id:  element.data[0].user_id, 
                      status:  element.data[0].status,
                    }
                    );
                    
                    

                    
                }
               ////console.log(this.elements_feedback);
              this.popupbtnactive=this.meraemiStage[0].stage;
              //console.log(this.popupbtnactive);
                this.fedbacklength =this.elements_feedback.length;
                         
                this.message = result.message;
                 
                 
              }else{
                this.popupaddBtn =0;
                //("hello");
              }
            });
            
            //console.log(this.decryptData);
        
    }
    
    adidSubmitted: boolean = false;
 
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }

 
  get idFilter() {
    return this.registerForm.get('idFilter');
  }
  onChange1(email:string, isChecked: boolean) {
   // console.log(email);
    if(isChecked) {
      this.checkFormArray.push(email);
    } else {
      let index = this.checkFormArray.indexOf(email);
      this.checkFormArray.splice(index,1);
    }
}


filterUserId(){
  //console.log(this.idFilter.value.meremistage);
  if(this.idFilter.value.meremistage === 'Left'){
    this.status ="Active";

  }else if(this.idFilter.value.meremistage === 'Middle'){
    this.status ="Cancel";

  }else if(this.idFilter.value.meremistage === 'Right'){
    this.status ="Transfer";
    
  }   
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  this.message =this.idFilter.value.message;
  let datetime =this.input.nativeElement.value;
  let foloupD =datetime.replace(", 19:30",""); 
  var d2 = new Date(datetime);
  var dateformdate = d2.getFullYear()+''+(d2.getMonth()+1)+''+d2.getDate();    
  var currdate = today.getFullYear()+''+(today.getMonth()+1)+''+today.getDate(); 
  //date compare
          var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        var d2 = new Date(datetime);

        let db_date = ("0" + d2.getDate()).slice(-2);
                                // current month
        let db_month = ("0" + (d2.getMonth() + 1)).slice(-2);
                                // current year
        let db_year = d2.getFullYear();
        this.userdate =(db_year + "-" + db_month + "-" + db_date);
  

   
  this.useridSubmitted = true; 
  if(this.status === "Cancel"){
    if(this.idFilter.invalid){
      this.mesg ="Sorry! Feedback must not be blankfor updating status as Cancel";
             this.messagediv = 1;
            setTimeout(() => {
              this.message;
            
             this.messagediv = 0;
            
              
            }, 3000);
         return;
    }
    this.followupdate =(date+' '+time);
    if(this.leadDetails[0].loans[0].loanDetails.loan_id === undefined){
      this.loanid="0";
      
     }else{
      this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
     }

  }
  else if(this.status === "Active"){
    //console.log(this.currentdate);
     //console.log(this.userdate);
        if(this.currentdate <= this.userdate){
          this.followupdate =datetime.replace(/,?\s/, " ");
          
        }else{
          this.mesg ="Invalid input! Please select valid date and time."; 
          this.messagediv = 1;
          setTimeout(() => {
            this.message;         
          this.messagediv = 0;   
            
          }, 3000);
        return; 
        }
        if(this.idFilter.invalid)
          {
          this.mesg ="Sorry! Feedback must not be blankfor updating status as Active";     
                        
                    this.messagediv = 1;
                    setTimeout(() => {
                      this.message;
                    
                      this.messagediv = 0;
                    
                      
                    }, 3000);
          return;
          }
      if(this.input.nativeElement.value ==='')
        {
          this.mesg ="Sorry! Feedback must not be blankfor updating followup date/time.";
          this.messagediv = 1;
        setTimeout(() => {
          this.message;
          
          this.messagediv = 0;
        
          
        }, 3000);
          return;
        }
        if(this.leadDetails[0].loans[0].loanDetails.loan_id === undefined){
          this.loanid="0";

         }else{
          this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
         }
  }
  else if(this.status === "Transfer"){
        if(this.currentdate <= this.userdate){
          this.followupdate =datetime.replace(/,?\s/, " ");
          
        }else{
          this.mesg ="Invalid input! Please select valid date and time."; 
          this.messagediv = 1;
        setTimeout(() => {
          this.message;         
        this.messagediv = 0;   
          
        }, 3000);
      return;
        }
        if(this.idFilter.invalid)
          {
          this.mesg ="Sorry! Feedback must not be blankfor updating status as Active";     
                        
                    this.messagediv = 1;
                    setTimeout(() => {
                      this.message;
                    
                      this.messagediv = 0;
                    
                      
                    }, 3000);
          return;
          }
          if(this.input.nativeElement.value ==='')
            {
              this.mesg ="Sorry! Feedback must not be blankfor updating followup date/time.";
              this.messagediv = 1;
            setTimeout(() => {
              this.message;
              
              this.messagediv = 0;
            
              
            }, 3000);
              return;
            }
        if(this.leadDetails[0].loans[0].loanDetails.loan_id === undefined){
          this.loanid="0";
          
         }else{
          this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
         }
       
  }



  
   
       
    this.user_id=this.decryptData;
    let details = {
      data: {
        results: [{
          "message":this.message,            
          "interactiondatetime":date+' '+time,          
          "status": this.status,
          "user_id":this.user_id,
          "admin_name":this.user.first_name,
         // "admin_name":this.user.first_name+' '+this.user.last_name,
          "loan_id":this.loanid,
          "followupdate":this.followupdate
              
        }]
    
      }
    };
   //console.log(details);
    details['data']['results']; 
        this.FeedbackService.postfeedback(details)
        .subscribe((result: any) => { 
          //console.log(result);
          if (result.status == true) {
            
            this.elements_feedback.length=0;
           
           
          let details = {data: { results: [{ "user_id":this.user_id}] } };
          details['data']['results']; 
          this.FeedbackService.postfeedback_getcrm(details)
          .subscribe((result: any) => {  
              
            if (result.status == true) {
              for (let i = 0; i < result.feedvalue.length; i++) {
                const element = result.feedvalue[i];
                if(element.data[0].stage == "Active"   && element.data[0].status ==='1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }if(element.data[0].stage == "Active"   && element.data[0].status ==='2'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      status:  element.data[0].status, 
                      
                    }
                    );
                }
                
                else if(element.data[0].stage == "Transfer" && element.data[0].status === '1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }else if(element.data[0].stage == "Cancel" && element.data[0].status === '1'){
                  this.meraemiStage.push(
                    {
                      stage:  element.data[0].stage, 
                      
                    }
                    );
                }
                this.elements_feedback.push(
                  {
                    admin_name: element.data[0].admin_name, 
                    feedback_id: element.data[0].feedback_id, 
                    feedback_message:  element.data[0].feedback_message, 
                    followupdate:  element.data[0].followupdate, 
                    interactiondate:  element.data[0].interactiondate, 
                    stage:  element.data[0].stage, 
                    user_id:  element.data[0].user_id, 
                    status:  element.data[0].status, 
                  }
                  );
                  
              }
              this.popupbtnactive=this.meraemiStage[0].stage;
              this.fedbacklength =this.elements_feedback.length;
              
             
              this.display = "none";
                this.message = result.message;
                this.showLoader = false;
                //this.messagediv = false;     
                //this.messagediv = 1;
              setTimeout(() => {
                this.message;
                this.showLoader = true;
                this.popupaddBtn =1;
                
              }, 2000);
            }
          });
          
        }
        });
    console.log(details);

}
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    this.display = "none";
  }
    backmenu(){
      this.showLoader = false;
      setTimeout(()=>{    
        //this.showLoader = true;
        this.router.navigate(['/leads']);
      }, 2000);
     
      
      }
     
     
  calculateEMI(interest:number,tenure:number,principal:number){
    var emi = 0;
    var a = (1 + interest / 1200);
    var b = Math.pow(a, tenure);
    var c = Math.pow(a, tenure) - 1;
    emi = Math.round(principal * (interest / 1200) * b / c);
    //console.log(emi)
    return emi;
  }
  calculateFOIR(existemi:number,proposedemi:number,income:number){
    var foir= 0;
   // console.log(existemi)
   // console.log(proposedemi)
  //  console.log(income)
    foir = ((existemi + proposedemi) / income * 100);
   // console.log(foir)
    return foir;
  }
  trackProgress(data){
    // console.log(data)
    // return false;
    data.sfdc_id = "0328568170";
    this.trackData["results"]= {
      "record_id":"0328568170",
      "email_send": "NO",
      "sms_send": "NO",
      //"user_id":107
    };

    this.isTrackProgress = !this.isTrackProgress;

    setTimeout(()=>{  
      
      this.isTrackProgress = true;
     // console.log('set disable '+this.isTrackProgress)
      this.trackProgressService.updateTrackProgress(this.trackData)
      .subscribe(result => {
       // console.log(result)
          
        if(result.status == 1){
          //console.log(result.trx_status)
          if(result.trx_status != 'undefined'){
            this.trxStatus = result.trx_status;
            this.trxSubStatus =result.trx_substatus;
            //console.log(this.setBtnDisable);
            
              if(result.trx_status == "DDE" || result.trx_status == "QDE" || result.trx_status == "Data Verification"){
               // console.log(result.trx_status)
                this.isTrackProgress = this.isTrackProgress;
                //console.log(this.isTrackProgress)
              }else{
                this.isTrackProgress = !this.isTrackProgress;
               // console.log(this.isTrackProgress)
              }
          }  
        }
        
      });
    }, 20000);
  

  }
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  downloadRequestFile(filename){  
    saveAs("./assets/UserRequestResponse/idfc/Request/"+filename+".json",filename);
  
  }
  downloadResponseFile(filename){   
    saveAs("./assets/UserRequestResponse/idfc/Response/"+filename+".json",filename);
  }
  downloadTrackProgressFile(filename){   
    saveAs("./assets/UserRequestResponse/idfc/trackprogress/"+filename+".json",filename);
  }
  
  
}
