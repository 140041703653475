import { Injectable } from '@angular/core';
@Injectable()
export class Global{
    // accessControlOrigin(arg0: string, accessControlOrigin: any) {
     
    //   //throw new Error("Method not implemented.");
    // }
    accessControlOrigin="*";
    
  
   //apiurl:string = 'http://localhost:5000/v1/';  

  //apiurl:string = 'https://java-api.meraemi.in/uat_crm/v1/';
 
 
 apiurl:string = 'https://api2.meraemi.com/crm/v1/';
   










//dbmeraemi_local_test

}



// UAT CRM Token: db0b150a-1616-436a-be0a-652dc8ddd783
// Site URL: https://uat-crm.meraemi.in
// CRM API URL: https://java-api.meraemi.in/uat_crm
// UAT API URL: https://java-api.meraemi.in/uat
// Messageing API URL: https://java-api.meraemi.in/messeging