import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { Router } from '@angular/router';
import {ExcelService} from 'src/app/services/excel/excel.service';
@Component({
  selector: 'app-detailed-mis',
  templateUrl: './detailed-mis.component.html',
  styleUrls: ['./detailed-mis.component.scss']
})
export class DetailedMisComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  
  elements: any = [];
  result: any = [];
  currentdate: string;
	FirstDaydate: string;
  searchText: any;
  registerForm: FormGroup;
  stage_color: string;
  appstage: string;
  urlstage: string;
  elig_status: string;
  provider: string;
  element_main: any = [];
  previous: any = [];
  adidarray: any;
  headElements: any[];
  main_array: any;
  dateOfBirth: any;
  location_id: any;
  mobile_number: any;
  emailAddres: any;
  location: string;
  first_name: string;
  last_name: string;
  gender: string;
  Loan_amount: string;
  tenure: string;
  residence_status: any;
  company_name: any;
  work_experience: any;
  net_monthly_income: any;
  salary_mode: any;
  new_to_loan: any;
  new_to_cc: any;
  credit_profile: any;
  duplicate_record: any;
  type_of_loan: any;
  marital_status: any;
  existing_emi_amount: any;
  employer_status: any;
  existing_loan_type: string;
  user_id: any;
  created_on: any;
  eligstatus: string;
  loan_type: any;
  app_stage: string;
  stage: string;
  iap_trx_status: any;
  response_status: string;
  eligStatus: string;
  eleAppstage: string;
  stagecolor: string;
  useid: any;
  showLoader: boolean;

  iapstage: string;
  constructor(private formBuilder: FormBuilder, private cryptojsService:CryptojsService,
		private leadsService:LeadsService,private excelService:ExcelService,  private router: Router,) { }
    disabled = true;
    options = [
      { value: 'Eligibility_Pending', label: 'Eligibility_Pending' },      
      { value: 'Eligibility_Clear', label: 'Eligibility_Clear' },      
      { value: 'Eligibility_Fail', label: 'Eligibility_Fail' },
      { value: 'IAP_Pending', label: 'IAP_Pending' },
      { value: 'IAP_Complete', label: 'IAP Complete' }, 
      { value: 'IAP_Fail', label: 'IAP_Fail' },
      { value: 'IAP_Success', label: 'IAP_Success' },    
     
      
    ];
    optionsDetails = [    
    { value: 'user_id', label: 'user_id', disabled: false },
    { value: 'first_name', label: 'first_name', disabled: false },
    { value: 'last_name', label: 'last_name', disabled: false }, 
    { value: 'DOB', label: 'DOB', disabled: false },
    { value: 'Location_id', label: 'Location_id', disabled: false },
    { value: 'email_address', label: 'email_address', disabled: false },
    { value: 'mobile_no', label: 'mobile_no', disabled: false },
    { value: 'elig_status', label: 'elig_status', disabled: false },      
    { value: 'iap_status', label: 'iap_status', disabled: false },
    { value: 'Location', label: 'Location', disabled: false },          
    { value: 'gender', label: 'gender', disabled: false },
    { value: 'Loan_amount', label: 'Loan_amount', disabled: false },
    { value: 'tenure', label: 'tenure', disabled: false },
    { value: 'residence_status', label: 'residence_status', disabled: false },
    { value: 'employer_status', label: 'employer_status', disabled: false },
    { value: 'company_name', label: 'company_name', disabled: false },
    { value: 'work_experience', label: 'work_experience', disabled: false },
    { value: 'net_monthly_income', label: 'net_monthly_income', disabled: false },
    { value: 'salary_mode', label: 'salary_mode', disabled: false },
    { value: 'New_to_loan', label: 'New_to_loan', disabled: false },
    { value: 'New_to_cc', label: 'New_to_cc', disabled: false },
    { value: 'existing_emi_amount', label: 'existing_emi_amount', disabled: false },
    { value: 'existing_loan_type', label: 'existing_loan_type', disabled: false },
    { value: 'credit_profile', label: 'credit_profile', disabled: false },
    { value: 'duplicate_record', label: 'duplicate_record', disabled: false },
    { value: 'type_of_loan', label: 'type_of_loan', disabled: false },
    { value: 'marital_status', label: 'marital_status', disabled: false },
        { value: 'provider', label: 'provider', disabled: false },
      
      
    ];
  ngOnInit() {
    
    let date = new Date();
		// adjust 0 before single digit date
		let dates = ("0" + date.getDate()).slice(-2);
		// current month
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		// current year
		let year = date.getFullYear();
		this.currentdate =(year + "-" + month + "-" + dates);
		//console.log(this.currentdate); 
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		// adjust 0 before single digit date
		let datesFirstDay = ("0" + firstDay.getDate()).slice(-2);
		// current month
		let monthFirstDay = ("0" + (firstDay.getMonth() + 1)).slice(-2);
		// current year
		let yearFirstDay = firstDay.getFullYear();
		this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
		this.registerForm = this.formBuilder.group({			
			filterchart: this.formBuilder.group({
			  iapstage: ['', Validators.required],
			  details: ['', Validators.required],
        datefrom: ['', Validators.required],
			  dateto: ['', Validators.required],
			}),
			
		  });
      this.leadsService
      .DetailedMis()
      .subscribe( (result:any) => {
        //console.log(result);
                if (result.status = 'true') {              
                  for (let i = 0; i < result.filter_city.length; i++) {
                    const element = result.filter_city[i];
                    console.log(element);
                    if(element.data[0].app_stage  === "0"){
                      // this.stage ='Lead';
                       this.eleAppstage ='Eligibility_Pending';  
                       this.stagecolor = "infocolor"; 
                      }else  if(element.data[0].app_stage  === "1"){
                      
                      if(element.data[0].elig_status == '1'){
                        this.eligStatus ='Eligibility_Clear';
                        this.stagecolor = "infocolor";
                     
                      }else if(element.data[0].elig_status == '0'){
                        this.eligStatus ='Eligibility_Fail';
                        this.stagecolor = "infocolor";
                      
                      }else{
                        this.eligStatus ='Eligibility_Pending';
                        this.stagecolor = "infocolor";
                       
                      }
                      this.eleAppstage = this.eligStatus;
                    
                    }else if(element.data[0].app_stage ==="2"){
                    
                      this.appstage ="IAP_Pending";
                      this.urlstage ='IAP_Pending';
                      this.stage_color = "info-color";
                
                  }else if(element.data[0].app_stage === "3"){
                    this.appstage ="IAP_Complete";
                    this.urlstage ='IAP_Complete';
                    this.stage_color = "info-color";
                   
                    
                    
                    
                  }else if(element.data[0].app_stage === "4"){
                    this.appstage = 'Document_Upload';
                    this.stage_color = "info-color";
                  }else if(element.data[0].app_stage === "5"){
                    this.appstage = 'Meeting_Scheduler';
                    this.stage_color = "info-color";
                  }else if(element.data[0].app_stage === "6"){
                    this.appstage = 'Success';
                    this.urlstage ='Success';
                    this.stage_color = "info-color";
                  }else{                                    
                    this.appstage ='Eligibility_Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                  }
                  this.stage=this.appstage;
                  if(element.data[0].provider_id == "BOT MESSENGER"){
                    this.provider = 'BOT';
                    
                  }else{
                    this.provider = 'WEB';
                  }

                 //console.log(this.stage);
                    //check null value
                   
                if(element.data[0].first_name === undefined){
                  this.first_name ="NA";
                }else{
                  this.first_name =element.data[0].first_name;
                }
                if(element.data[0].last_name === undefined){
                  this.last_name ="NA";
                }else{
                  this.last_name =element.data[0].last_name;
                }
                if(element.data[0].company_name === undefined){
                  this.company_name ="NA";
                }else{
                  this.company_name =element.data[0].company_name;
                }
                
                if(element.data[0].email_address === null	){
                  this.emailAddres ="NA";
                }else{
                  this.emailAddres =element.data[0].email_address;
                }
                if(element.data[0].employer_status === undefined){
                  this.employer_status ="NA";
                }else{
                  this.employer_status =element.data[0].employer_status;
                }
                if(element.data[0].existing_emi_amount === undefined){
                  this.existing_emi_amount ="NA";
                }else{
                  this.existing_emi_amount =element.data[0].existing_emi_amount;
                }
                if(element.data[0].gender === undefined){
                  this.gender ="NA";
                }else{
                  this.gender =element.data[0].gender;
                }
                if(element.data[0].loan_amount === undefined){
                  this.Loan_amount ="NA";
                }else{
                  this.Loan_amount =element.data[0].loan_amount;
                }
                if(element.data[0].location === undefined){
                  this.location ="NA";
                }else{
                  this.location =element.data[0].location;
                }
                if(element.data[0].location_id === undefined){
                  this.location_id ="NA";
                }else{
                  this.location_id =element.data[0].location_id;
                }
                if(element.data[0].marital_status === undefined){
                  this.marital_status ="NA";
                }else{
                  this.marital_status =element.data[0].marital_status;
                }
                if(element.data[0].mobile_number === undefined){
                  this.mobile_number ="NA";
                }else{
                  this.mobile_number =element.data[0].mobile_number;
                }
                
                if(element.data[0].net_monthly_income === undefined){
                  this.net_monthly_income ="NA";
                }else{
                  this.net_monthly_income =element.data[0].net_monthly_income;
                }
                if(element.data[0].new_to_loan === undefined){
                  this.new_to_loan ="NA";
                }else{
                  this.new_to_loan =element.data[0].new_to_loan;
                }
                if(element.data[0].new_to_cc === undefined){
                  this.new_to_cc ="NA";
                }else{
                  this.new_to_cc =element.data[0].new_to_cc;
                }
                
                if(element.data[0].residence_status === undefined){
                  this.residence_status ="NA";
                }else{
                  this.residence_status =element.data[0].residence_status;
                }
                if(element.data[0].salary_mode === undefined){
                  this.salary_mode ="NA";
                }else{
                  this.salary_mode =element.data[0].salary_mode;
                }
                if(element.data[0].tenure === undefined){
                  this.tenure ="NA";
                }else{
                  this.tenure =element.data[0].tenure;
                }
                if(element.data[0].duplicate_record === undefined){
                  this.duplicate_record ="NA";
                }else{
                  this.duplicate_record =element.data[0].duplicate_record;
                }
                if(element.data[0].work_experience === undefined){
                  this.work_experience ="NA";
                }else{
                  this.work_experience =element.data[0].work_experience;
                }
                if(element.data[0].existing_loan_type === undefined){
                  this.existing_loan_type ="NA";
                }else{
                  this.existing_loan_type =element.data[0].existing_loan_type;
                }
                
                if(element.data[0].credit_profile === undefined){
                  this.credit_profile ="NA";
                }else{
                  this.credit_profile =element.data[0].credit_profile;
                }
                if(element.data[0].type_of_loan === undefined){
                  this.type_of_loan ="NA";
                }else{
                  this.type_of_loan =element.data[0].type_of_loan;
                }
                
                if(element.data[0].user_id === undefined){
                  this.user_id ="NA";
                }else{
                  this.user_id =element.data[0].user_id;
                }
                if(element.data[0].iap_trx_status === undefined){
                  this.iap_trx_status ="NA";
                }else{
                  this.iap_trx_status =element.data[0].iap_trx_status;
                }
                if(element.data[0].response_status === undefined){
                  this.response_status ="NA";
                }else{
                  this.response_status =element.data[0].response_status;
                }
                if(this.stage==="Eligibility_Pending"){
                  this.iapstage="NA";
                }else if(this.stage==="Eligibility_Fail"){
                  this.iapstage="NA";
                }else if(this.stage==="Eligibility_Clear"){
                  this.iapstage="NA";
                }else{
                  this.iapstage=this.stage;
                }
                      this.element_main.push(
                        {
                          user_id: this.user_id, 
                          first_name:  this.first_name, 
                          last_name:  this.last_name,              
                          provider: this.provider,
                          ad_id: element.data[0].ad_id,
                          status: this.stage,
                          company_name: this.company_name,
                          created_on: element.data[0].created_on,
                          dob: element.data[0].dob,
                          email_address: this.emailAddres,
                          employer_status: this.employer_status,
                          existing_emi_amount: this.existing_emi_amount,                         
                          gender: this.gender,
                          iap_trx_status: this.iap_trx_status,                          
                          lender_id: element.data[0].lender_id,
                          loan_amount: this.Loan_amount,
                          location:this.location,
                          location_id: this.location_id,
                          marital_status: this.marital_status,
                          mobile_number: this.mobile_number,
                          net_monthly_income: this.net_monthly_income,
                          new_to_cc: this.new_to_cc,
                          new_to_loan: this.new_to_loan,
                          provider_id:element.data[0].provider_id,
                          residence_status: this.residence_status,

                          response_id: element.data[0].response_id,
                          response_status: this.response_status,

                          role_id: element.data[0].role_id,
                          role_name: element.data[0].role_name,

                          salary_mode: this.salary_mode,                        
                          tenure: this.tenure,

                          trx_application_response_user_id: element.data[0].trx_application_response_user_id,
                          duplicate_record :this.duplicate_record,
                          work_experience: this.work_experience,
                          existing_loan_type:this.existing_loan_type,
                          credit_profile :this.credit_profile,                          
                          type_of_loan :this.type_of_loan,
                          elig_status :this.eleAppstage,
                          app_status :this.iapstage,
                          
     
                        }
                        );
                      // console.log(this.element_main);
                       
                      
                        
                }
                              
                  
                }
                
              });
       

  }
  chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }
    filter_chart(){
      
      const  iapstage =this.filterchart.value.iapstage;
//      console.log(iapstage);
      const  datefrom =this.filterchart.value.datefrom;
      //console.log(datefrom);
      const  dateto =this.filterchart.value.dateto; 
      //console.log(dateto);
      //const  details =this.filterchart.value.details;
      //console.log(details);
      
       //this.adidarray =this.elements;
      const maina_rray=this.element_main;
      //console.log(maina_rray);
      
      this.result =  maina_rray.filter(d => { 
        //console.log(d);
        const  reg_date= d.created_on;
       
         const regdate = reg_date.split(' ')[0];     
         //console.log(regdate);          
         return  (regdate >= datefrom    &&  regdate  <=  dateto);            
         
        });
        const  stage = this.result;
            let filtered = [];
          
            iapstage.filter(function(newStageData) {
            return   stage.filter(function(mainArray) {
                if (newStageData === mainArray.status) {
                filtered.push({               
                user_id: mainArray.user_id, 
                first_name:  mainArray.first_name, 
                last_name:  mainArray.last_name,
                DOB: mainArray.dob,
                Location_id: mainArray.location_id,
                email_address: mainArray.email_address,
                mobile_no: mainArray.mobile_number,
                Location: mainArray.location,
                gender: mainArray.gender,
                Loan_amount: mainArray.loan_amount,
                tenure: mainArray.tenure,
                residence_status: mainArray.residence_status,
                employer_status: mainArray.employer_status,
                company_name: mainArray.company_name,
                work_experience : mainArray.work_experience,
                net_monthly_income: mainArray.net_monthly_income,
                salary_mode: mainArray.salary_mode,                 
                New_to_cc: mainArray.new_to_cc,
                New_to_loan: mainArray.new_to_loan,                
                existing_emi_amount: mainArray.existing_emi_amount,
                existing_loan_type :mainArray.existing_loan_type,
                credit_profile :mainArray.credit_profile,
                duplicate_record: mainArray.duplicate_record,                
                type_of_loan: mainArray.type_of_loan,                
                marital_status: mainArray.marital_status,                
                filterdate:mainArray.created_on,
                iap_status:mainArray.app_status,
                elig_status:mainArray.elig_status,
                 provider:mainArray.provider,

                
                })
              }
            })
          });
        console.log(filtered);
          const dataarray =filtered;
         // console.log(dataarray);
          const lavelarray = Object.keys(dataarray[0]);
        // console.log(Object.keys(filtered[0]));
          const  details =this.filterchart.value.details;
          const  optionsDetails = [
          
               "user_id","first_name","last_name","email_address","mobile_no","elig_status","iap_status"
          
            
          ];
          
  var  resultArr= optionsDetails.concat(details);
  //var myArr = [1, 2, 2, 2, 3];
                        var mySet = new Set(resultArr);
                        resultArr = [...mySet];
                        //console.log(myArr);
    //console.log(resultArr);
          let level_array= [];
          resultArr.filter(function(detail) {
            return   lavelarray.filter(function(mainArray) {
            if (detail === mainArray) {
              level_array.push({
                name:mainArray
            })
            
          }
        })
      });
      
      this.headElements =level_array;
      const headElements=  this.headElements ;
      var arry1=filtered;
      var arry2=  this.headElements ;
      var levelarray = [];
      var arr = [];
     arr = arry1.map((value,key)=>{
    var childObj = {};
    arry2.forEach((value2,key2)=>{
      //console.log(value2.name);
        childObj[value2.name] = value[value2.name];
        });
        levelarray.push(childObj)
        //console.log(childObj);
        //return childObj
     }); 
     
    

     this.mdbTable.setDataSource(levelarray);
     this.elements = this.mdbTable.getDataSource();
     this.previous = this.mdbTable.getDataSource();
   
     //console.log(levelarray);
        //console.log(filtered);
   
//console.log(filtered);
    }
  DashbordReset(){
  // this.filterchart.reset();
   this.elements.length=0;
   this.headElements.length=0;
  // location.reload();
    //console.log(formvalueReset);
  }
  getLeadDetails(user,data){
    let stage=  data;
    console.log(data);
   this.useid = this.cryptojsService.encryptData((user.user_id));       
   const base_url=location.origin+"/#/MeraEMI-user-details?data=";        
   const detailsurl = base_url + this.useid.toString();
   const dateur=detailsurl+"&stage="+stage.toString();
  
   //console.log(detailsurl);
   window.open(dateur, "_blank");
}

searchItems() {
  const prev = this.mdbTable.getDataSource();

  if (!this.searchText) {
    this.mdbTable.setDataSource(this.previous);
    this.elements = this.mdbTable.getDataSource();
  }

  if (this.searchText) {
    this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
    this.mdbTable.setDataSource(prev);
  }
}

backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/meraemi-dashboard']);
  }, 2000);
 
  
  }
    rotate(event){
        event.srcElement.classList.remove("rotate");
        event.srcElement.classList.remove("tooltiptext");
        setTimeout(()=>{
          event.srcElement.classList.add("rotate");
          event.srcElement.classList.remove("tooltiptext");
        location.reload();
        },0)
      }
     
     
      excelReport():void {

        this.excelService.exportAsExcelFile(this.elements, 'MeraEMI');
      }
}
