import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-mera-emi-docuplods-user',
  templateUrl: './mera-emi-docuplods-user.component.html',
  styleUrls: ['./mera-emi-docuplods-user.component.scss']
})
export class MeraEMIDocuplodsUserComponent implements OnInit {
  file: File;

  constructor(

    private formBuilder: FormBuilder
  ) { }
  myForm: FormGroup;
  ngOnInit() {
    this.myForm = this.formBuilder.group({
      selectfile: ['', Validators.required],
    });
  }
  onFileAdd(file: File) {
    this.file = file;
  }

  onFileRemove() {
    this.file = null;
  }
  submitForm() {
    console.log(this.myForm.value.selectfile);
    if(this.myForm.value.selectfile == '' || this.myForm.value.selectfile ==null){
    }
  }
  onFileChanged(event){
    console.log(event);
  }
}
