import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  //bearer_token:string = "Bearer a07e2f5c-fb91-4a1c-9bcd-f6eb50d0fcaf";
    message: any;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;
  // getAllReport(){
  //   //return this.http.get<Leads>('/crm/leads');
 
  //    return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/Report');
  //  }
  //  postpermissiontable(details){
  //   console.log(details);
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/permisson',{data:details.data});

  // }
  // getTablePerm(){
  //   //return this.http.get<Leads>('/crm/leads');
  //   //console.log('hello');
  //    return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/table_name');
  //  }
  //  getSuccessParameters(data){
  //    return this.http.post<any>(`${this.global.apiurl}`+'leads/success_parameters',{data:data});
 
  //  }
  //  getEligibility(){    
  //   return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/Eligibility');

  // }
  // getUser(){    
  //   return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/user_role');

  // }
  // getmenu(){    
  //   return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/permission_table');

  // }
  // postPermissionMenu(details){
   
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/permisson_sideMenu',{data:details.data});

  // }
  
  // getPermissionCheck(details){
   
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/get_permission_check',{data:details.data});

  // }
  // locationMaster(){    
  //   return this.http.get<Global>(`${this.global.apiurl}`+'permissioncrm/location');

  // }
  // filterCityname(details){    
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/filterCityname',{data:details.data});
  // }
  // filterMaster(details){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.post<any>(`${this.global.apiurl}`+'filtermaster/filtermaster',{data:details.data},{headers:headers});

  // }
 
  //  StageFilter(details){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.post<any>(`${this.global.apiurl}`+'filtermaster/StageFilter',{data:details.data},{headers:headers});

  // }
  // filterLocation(details){
  //   return this.http.post<any>(`${this.global.apiurl}`+'filtermaster/location',{data:details.data});
  // }
  // filterSource(details){
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/filterSource',{data:details.data});
  // }
  // filterLonAmt(details){
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/filterLonAmt',{data:details.data});
  // }
  // filterDate(details){
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/filterDate',{data:details.data});
  // }
 
  // filterLeads(details){
  //   return this.http.post<any>(`${this.global.apiurl}`+'permissioncrm/filterLeads',{data:details.data});
  // }
  // getAllLeads(){

  //   return this.http.get<Global>(`${this.global.apiurl}`+'leads/Leads');
  //  }
  
   
  //  GetSemdEmailUser(){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.get<Global>(`${this.global.apiurl}`+'mail/SemdEmailUser',{headers:headers});
  // }
}
