import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class LoansService {
  //bearer_token:string = "Bearer a07e2f5c-fb91-4a1c-9bcd-f6eb50d0fcaf";
  constructor(
        private http: HttpClient,
        public global:Global,
        private TokenService:TokenService,
  ) {
   }
   bearer_token = this.TokenService.bearer_token ;
  //  getLoans(){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.get<any>(`${this.global.apiurl}`+'loans/details',{headers: headers});
  // }
  // getAllLoans(){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.get<any>(`${this.global.apiurl}`+'permissioncrm/userLeads',{headers: headers});
  // }

  getLoanDetByUser(data){
  
   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'loans/users',{data:data},{headers: headers});

  }
  GetLenderStage(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'loans/GetLenderStage',{data:data},{headers: headers});
 
   }
   GetMultipleLenderConsent(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'loans/GetMultipleLenderConsent',{data:data},{headers: headers});
 
   }
   
   GetEligiError(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'loans/GetLenderEligiError',{data:data},{headers: headers});
 
   }
  //  getCriteriaFailDetails(data){
  
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //    return this.http.post<any>(`${this.global.apiurl}`+'getAllTempCriteriaFail/getCriteriaFailDetails',{data:data},{headers: headers});
 
  //  }
  //  GetCriteriaFail(data){
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
  //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
  //   return this.http.post<any>(`${this.global.apiurl}`+'getAllTempCriteriaFail/GetCriteriaFail',{data:data},{headers: headers});

  //  }
   PostMortgageDetails(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'MortgageCont/PostMortgageUserDetils',{data:data},{headers: headers});

   }
  
}
