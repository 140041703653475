import { Component, OnInit, ViewChild } from '@angular/core';
import { LenderContactService } from 'src/app/services/lender-contact/lender-contact.service';
import { FormGroup, FormBuilder, Validators ,FormControl} from '@angular/forms';
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Subscription, Observable } from 'rxjs';
import { LoansService } from 'src/app/services/loans/loans.service';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';
import {ExcelService} from 'src/app/services/excel/excel.service';
import * as XLSX from 'xlsx'; 
import { MisSendLenderService } from 'src/app/services/mis-send-lender/mis-send-lender.service';

@Component({
   selector: 'app-meaemi-email-campain',
  templateUrl: './meaemi-email-campain.component.html',
  styleUrls: ['./meaemi-email-campain.component.scss']
})
export class MeaemiEmailCampainComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  file: File;
  tabval: number=2
  tabstage="active";
  tab: string;
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;

  optionCity: any = []; 
  optionstags: any = []; 
  validatingForm: FormGroup;
  editvalidatingForm: FormGroup;
  lenderproductoffer: FormGroup;
  remainingText: number=0;  
  remainingTextfb: number=0;
  optionsSource:any = []; 
  tempSchedule: number=0;
  campaignSerialNo: any;
  campaignSource: any;
  campaignStage: any;
  stagetemp: number=0;
  urlstg: string;
  QeryUrl: any;
  optionsArray: any = []; 
  
  elements: any = []; 
  emailstage: any = []; 
  locationName: any = []; 
  location: any = []; 
  lendeEmail: any = []; 
  lende_email: any = [];
  divaction: number=0;
  sub: any;
  user: User;
  rolecode: any;
  loginAdmin: any;
  data: any;
  feedback_Stage: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  delete = {};
  cust: any;
  userdetils: {};
  edit = {};
  previous: any;
  element:any = [];

 
  currentdate: any;
  headElements = [
    {
      name:"Compain Id",
      sortBy:"id"
    },
    {
       name:"Compain Name",
       sortBy:"id"
     },
     {
       name:"Campain  Stage",
       sortBy:"campain_name"
     },
     
     {
       name:"Compain Status",
       sortBy:"compain_stage"
     },
     {
       name:"date",
       sortBy:"rate"
     },
     {
      name:"Action",
      sortBy:"action"
    },  
     
        ];
  display: string;
  id: string;
  product_name: string;
  rate_of_interest_from: string;
  tenure: string;
  interest_rate_as_on: string;
  lenderid: string;
  registerForm: FormGroup;
  lenderdate: string;
  lender_id: string;
  product_id: string;
  rate_from: string;
  tenureedit: string;
  editlenderid: any;
  editproductid: any;
  stage: string;
  compainstage: number=0;
  campain_name: any;
  compain_stage: any;
  appstage: any;
  emailstageedit: any;
  campain_nameedit: any;
//  currentdate: string;
  constructor(fb: FormBuilder,private lenderContactService:LenderContactService,private leadsService:LeadsService,
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private loansService:LoansService,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
   
    public afAuth: AngularFireAuth,
    private excelService:ExcelService,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    public ete: MisSendLenderService
    
    ) {

   
    }
    onFileAdd(file: File) {
      this.file = file;
      }
      
      onFileRemove() {
      this.file = null;
      }
    ngOnInit() {
      
      this.emailstage= [
       
        { value: 'Eligibility_Clear', label: 'Eligibility Clear' },      
        { value: 'Eligibility_Fail', label: 'Eligibility Fail' },        
        { value: 'IAP_Pending', label: 'IAP Pending' },
      
          
          ];  
         // validation 

     
      this.lenderproductoffer= new FormGroup({
        emailstage: new FormControl(null, Validators.required),        
        campain_name: new FormControl(null, Validators.required),
       
      });
      this.editvalidatingForm= new FormGroup({
        emailstageedit: new FormControl(null, Validators.required),
        campain_nameedit: new FormControl(null, Validators.required),
   
    
      
     
    });
    
      //  Product name
      this.lenderContactService
     .getProductName()
     .subscribe( (result:any) => {
      if (result.status === true && result.response===200) {    
        for (let i = 0; i < result.product_name.length; i++) {
                 const element = result.product_name[i];
               //  console.log(element);
                 this.location.push(
                  {
                    value: element.data[0].product_id,
                    label: element.data[0].product_name, 
                     
                                 
                  });
        }
        this.locationName =this.location;
      // 
      }
       
     });
  
     this.lenderContactService
     .GetEmailCampain()
     .subscribe( (result:any) => {
   
      if (result.status === true && result.response===200) { 
      
        for (let i = 0; i < result.UaseEmailsendlist.length; i++) {
          const element = result.UaseEmailsendlist[i];
          if(element.data[0].campain_status === "1"){
            this.stage= "Active";
          }else{
            this.stage= "In-Active";
          }
          console.log(element);
          this.element.push(
            {
             
              id: element.data[0].campain_id,
              campain_name: element.data[0].campain_name,
              compain_stage: element.data[0].compain_stage,
              appstage: this.stage,
              date: element.data[0].created_on,   
                   
            });
       // console.log(this.element);
        }
        this.mdbTable.setDataSource(this.element);
        this.element = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      }else{
        console.log("API Error");
      }
     });
 
    }
      get estage() {return this.lenderproductoffer.get('emailstage'); }       
      get cname() {return this.lenderproductoffer.get('campain_name');  } 
  
      
      //  edit
      get edits() {return this.editvalidatingForm.get('emailstageedit'); } 
      get editL() {return this.editvalidatingForm.get('campain_nameedit'); }     
    
     
      status: boolean = false;
        clickEvent(event){
          console.log(event);
            this.status = !this.status;   
            console.log(this.status);
        }
        eligfail(event){
          this.status = !this.status;  
          console.log(event);
        }
      onSubmit() {
        if(this.status ===true){
          this.compainstage=1;
        }else{
          this.compainstage=0;
        }
      //  console.log(this.status); 
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(); 
        
      
       // this.currentdate =(year + "-" + month + "-" + date);
       this.currentdate =(date+' '+time);;
          this.custsD["results"] = [{
            "emailstage":this.lenderproductoffer.value.emailstage,
            "campain_name":this.lenderproductoffer.value.campain_name,            
            "status":this.compainstage,
            "created_on":this.currentdate,
            "created_by":'1'
          }];
        
      console.log(this.custsD);
       if(this.custsD){
      
         this.divaction=1;
      
        this.lenderContactService.PostEmailCampainIntrest(this.custsD).subscribe( 
            
          (leadres:any) => {
            console.log(leadres);
          
            if(leadres.response=== 200 && leadres.status=== true){
              
              this.element.length=0;
           //   console.log(leadres);
              this.lenderContactService
              .GetEmailCampain()
              .subscribe( (result:any) => {
             if (result.status === true && result.response===200) { 
                 for (let i = 0; i < result.UaseEmailsendlist.length; i++) {
                   const element = result.UaseEmailsendlist[i];
                   if(element.data[0].campain_status === "1"){
                    this.stage= "Active";
                  }else{
                    this.stage= "In-Active";
                  }
                   this.element.push(
                     {
                       id: element.data[0].campain_id,
                       campain_name: element.data[0].campain_name,
                       compain_stage: element.data[0].compain_stage,
                       appstage: this.stage,
                       date: element.data[0].created_on,       
                     });
                // console.log(this.element);
                 }
                 this.mdbTable.setDataSource(this.element);
                 this.element = this.mdbTable.getDataSource();
                 this.previous = this.mdbTable.getDataSource();
               }else{
                 console.log("API Error");
               }
              });
             
            }else{
              console.log("Add Email Compain Api Error");
            }
        
          })
       }else{
        console.log("form value invalide!");
       }
        
        
      
      }
      onSubmitedit(){
  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();   
        
      
       // this.currentdate =(year + "-" + month + "-" + date);
       this.currentdate =(date +' '+time);
        if(this.editvalidatingForm.value.emailstageedit !=null && this.editvalidatingForm.value.emailstageedit !="" ){
            this.emailstageedit =this.editvalidatingForm.value.emailstageedit;
        }else{
          this.emailstageedit =this.compain_stage;
        }
        if(this.editvalidatingForm.value.campain_nameedit !=null && this.editvalidatingForm.value.campain_nameedit !="" ){
              this.campain_nameedit =this.editvalidatingForm.value.campain_nameedit;
          }else{
            this.campain_nameedit =this.editlenderid;
          }
          

              //  console.log(this.lenderdate);
        this.edit["results"] = [{
          "lender_date": this.lenderdate,
          "lender_id": this.lender_id,
          "product_id": this.product_id,
          "rate_from": this.rate_from,
          "tenureedit": this.tenureedit,
          "id": this.id,
          "modified_by":"1",
         
        }];
        if(this.edit){
          this.lenderContactService.EditRateofIntrest(this.edit).subscribe( 
            
            (leadres:any) => {
              if(leadres.response=== 200 && leadres.status=== true){
              
                this.element.length=0;
                this.display = "none";
               // console.log(leadres);
                this.lenderContactService
                .getLenderProdOffer()
                .subscribe( (result:any) => {
                 if (result.status === true && result.response===200) { 
                   for (let i = 0; i < result.lender_offerList.length; i++) {
                     const element = result.lender_offerList[i];
                     this.element.push(
                       {
                         id: element.data[0].len_prod_offer_id, 
                         interest_rate_as_on: element.data[0].interest_rate_as_on,
                         lender_name: element.data[0].lender_name,
                         product_name: element.data[0].product_name,
                         rate_of_interest_from: element.data[0].rate_of_interest_from,   
                         tenure: element.data[0].tenure,  
                         lender_id:element.data[0].lender_id,
                         product_id:element.data[0].product_id       
                       });
                  // console.log(this.element);
                   }
                   this.mdbTable.setDataSource(this.element);
                   this.element = this.mdbTable.getDataSource();
                   this.previous = this.mdbTable.getDataSource();
                 }else{
                   console.log("API Error");
                 }
                });
               
              }else{
                console.log("Edit lender product offer Api  Error");
              }
             
                
          
            })
        }
     //  console.log(this.edit);
  
      }
      lenderproductofferDelete(data){
        this.delete["results"] = [{
          "id":data,
  
        }];
        if(this.edit){
          this.lenderContactService.DeleteRateofIntrest(this.delete).subscribe( 
            
            (leadres:any) => {
              if(leadres.response=== 200 && leadres.status=== true){
              
                this.element.length=0;
                //console.log(leadres);
                this.lenderContactService
                .getLenderProdOffer()
                .subscribe( (result:any) => {
                 if (result.status === true && result.response===200) { 
                   for (let i = 0; i < result.lender_offerList.length; i++) {
                     const element = result.lender_offerList[i];
                     this.element.push(
                       {
                         id: element.data[0].len_prod_offer_id, 
                         interest_rate_as_on: element.data[0].interest_rate_as_on,
                         lender_name: element.data[0].lender_name,
                         product_name: element.data[0].product_name,
                         rate_of_interest_from: element.data[0].rate_of_interest_from,   
                         tenure: element.data[0].tenure,  
                         lender_id:element.data[0].lender_id,
                         product_id:element.data[0].product_id       
                       });
                  // console.log(this.element);
                   }
                   this.mdbTable.setDataSource(this.element);
                   this.element = this.mdbTable.getDataSource();
                   this.previous = this.mdbTable.getDataSource();
                 }else{
                   console.log("API Error");
                 }
                });
               
              }else{
                console.log("Delete  lender product offer Api  Error");
              }
             
                
          
            })
        }
      // console.log(this.delete);
      }
      calculateEMI(interest:number,tenure:number,principal:number){
        var emi = 0;
        var a = (1 + interest / 1200);
        var b = Math.pow(a, tenure);
        var c = Math.pow(a, tenure) - 1;
        emi = Math.round(principal * (interest / 1200) * b / c);
        //console.log(emi)
        return emi;
      }
      calculateFOIR(existemi:number,proposedemi:number,income:number){
        var foir= 0;
       // console.log(existemi)
       // console.log(proposedemi)
      //  console.log(income)
        foir = ((existemi + proposedemi) / income * 100);
       // console.log(foir)
        return foir;
      }
      get authState$(): Observable<User|null> {
        return this.afAuth.user;
      }
      
      openModalone(data1,data2,data3,data4) {
        this.id =data1;
        this.campain_name=data2;
        this.compain_stage=data3;
        this.appstage =data4;
    
       
      //  console.log(data1);
        this.display = "block";
      } 
      onCloseHandledone() {
    
        this.display = "none";
      }
}

function die() {
  throw new Error('Function not implemented.');
}

