import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackProgressService } from 'src/app/services/trackprogress/trackprogress.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';
import { BusinessPartnerService } from 'src/app/services/business-partner/business-partner.service';

@Component({
  selector: 'app-meraemi-business-partner-details',
  templateUrl: './meraemi-business-partner-details.component.html',
  styleUrls: ['./meraemi-business-partner-details.component.scss']
})
export class MeraemiBusinessPartnerDetailsComponent implements OnInit {
  subs: Subscription;
  sub: any;
  data: any;
  user:any;
  rolecode: any;
  loginAdmin: any;
  decryptData: any;
  showLoader: boolean;
  custsD = {};
  cust: any;
  userdetils: {};
  pid: number=0;
  businessPartner: any;
  Products: string;
  services:{};
  patnerid: any;
  name: string;
  email: any;
  mobile: any;
  address: any;
  pincode: any;
  date: any;

  constructor(private cryptojsService:CryptojsService,
    private route:ActivatedRoute,
    private router:Router,
    private trackProgressService:TrackProgressService,
    private formBuilder: FormBuilder,
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private businessPartnerService:BusinessPartnerService,
   ) { }

  ngOnInit() {
    //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        )
      }
     
    
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
         
     
          if( this.decryptData != false){
            this.showLoader = true;           
            this.pid= parseInt(this.decryptData);
            //console.log(this.pid);
           
           
            
          }else{
            this.showLoader = false;    
            this.router.navigate(['/MeraEMI-Business-Partner']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
      //console.log(this.pid);
      this.businessPartnerService.getAllbusinessPartnerDetails(this.pid).subscribe( 
        (result:any) => {

          if(result.response=== 200  && result.status === true){
            this.businessPartner =result['data'][0]['BusinessPartnerDetails'][0];
            if(this.businessPartner.BusinessPartnerDetailsProdServices !='null'){
              const service =this.businessPartner.BusinessPartnerDetailsProdServices;
              // let citydata = service.Cities.split(',')
              
              this.services = service.split(',');
               //console.log(this.BusinessPartnerDetailsProdServices ); return false;
            }else{
              this.services="NA";
            }
            this.patnerid=this.businessPartner.BusinessPartnerDetailsId;
            this.name= this.businessPartner.BusinessPartnerDetailsFname+' '+this.businessPartner.BusinessPartnerDetailsLname;
            this.email=this.businessPartner.BusinessPartnerDetailsEmail;
            this.mobile=this.businessPartner.BusinessPartnerDetailsMobile;
            this.address= (this.businessPartner.BusinessPartnerDetailsAddress1+' '+this.businessPartner.BusinessPartnerDetailsAddress2+' '+this.businessPartner.BusinessPartnerDetailsAddress3);
            this.pincode=this.businessPartner.BusinessPartnerDetailsPincode;   
            this.date=this.businessPartner.BusinessPartnerDetailsDate;      
           // this.BusinessPartnerDetailsProdServices=
            if(this.businessPartner.BusinessPartnerDetailsFinProducts === '1'){
              this.Products="Yes";
            }else{
              this.Products="No";
            }
            
         
          }
                    
          
        });
   
  }


  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }

}
