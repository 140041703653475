import {RouterModule, Route} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { LoanDetailsComponent } from './views/loan-details/loan-details.component';
import { MisListDetailsComponent } from './views/mis-list-details/mis-list-details.component';
import { LeadsComponent } from './views/leads/leads.component';
import { LeadStageComponent } from './views/lead-stage/lead-stage.component';
import { PendingFeedbackComponent } from './views/pending-feedback/pending-feedback.component';
import { LeadDetilsComponent } from './views/lead-detils/lead-detils.component';
import { MeraemiStageActiveComponent } from './views/meraemi-stage-active/meraemi-stage-active.component';
import { MeraemiStageCancelComponent } from './views/meraemi-stage-cancel/meraemi-stage-cancel.component';
import { MeraemiStageTransferComponent } from './views/meraemi-stage-transfer/meraemi-stage-transfer.component';
import { LeadsStageLeastComponent } from './views/leads-stage-least/leads-stage-least.component';
import { DetailedMisComponent } from './views/detailed-mis/detailed-mis.component';
import { MeraemiAllUsersLeadsComponent } from './views/meraemi-all-users-leads/meraemi-all-users-leads.component';
import { MeraemiAllUsersLeadsDetailsComponent } from './views/meraemi-all-users-leads-details/meraemi-all-users-leads-details.component';
import { EligibilityFeedBackComponent } from './views/eligibility-feed-back/eligibility-feed-back.component';
import { FacebookAdComponent } from './views/facebook-ad/facebook-ad.component';
import { EligibilityFollowUpStatusComponent } from './views/eligibility-follow-up-status/eligibility-follow-up-status.component';

import { MeraemiEmailComponent } from './views/meraemi-email/meraemi-email.component';
import { MeraemiLenderDocumentsComponent } from './views/meraemi-lender-documents/meraemi-lender-documents.component';
import { MeraEmiIAPErrorComponent } from './views/mera-emi-iap-error/mera-emi-iap-error.component';
import { MeraemiContactRequestComponent } from './views/meraemi-contact-request/meraemi-contact-request.component';
import { MeraemiEmailCampaignComponent } from './views/meraemi-email-campaign/meraemi-email-campaign.component';
import { MeraemiOfflineIapCompleteComponent } from './views/meraemi-offline-iap-complete/meraemi-offline-iap-complete.component';
import { MisSendLenderComponent } from './views/mis-send-lender/mis-send-lender.component';
import { LenderContactComponent } from './views/lender-contact/lender-contact.component';
import { MeraemiCriteriaFailComponent } from './views/meraemi-criteria-fail/meraemi-criteria-fail.component';
import { MmeraemiCriteriaFailDetailsComponent } from './views/mmeraemi-criteria-fail-details/mmeraemi-criteria-fail-details.component';
import { LenderProdOfferListComponent } from './views/lender-prod-offer-list/lender-prod-offer-list.component';
import { MeraemiSendUserMailComponent } from './views/meraemi-send-user-mail/meraemi-send-user-mail.component';
import { MeaemiEmailCampainComponent } from './views/meaemi-email-campain/meaemi-email-campain.component';
import { MeraemiHlbtLeadsComponent } from './views/meraemi-hlbt-leads/meraemi-hlbt-leads.component';
import { MeraemiHlbtLeadsDetailsComponent } from './views/meraemi-hlbt-leads-details/meraemi-hlbt-leads-details.component';
import { MeraemiDocumentViewComponent } from './views/meraemi-document-view/meraemi-document-view.component';
import { MeaemiCrmStatusMComponent } from './views/meaemi-crm-status-m/meaemi-crm-status-m.component';
import { MeraemiIapCompleteComponent } from './views/meraemi-iap-complete/meraemi-iap-complete.component';
import { DashboardNewComponent } from './views/dashboard_new/dashboard-new/dashboard-new.component';
import { TempSelfEmpDataComponent } from './views/temp-self-emp-data/temp-self-emp-data.component';
import { MeraemiSelfEmpUserListComponent } from './views/meraemi-self-emp-user-list/meraemi-self-emp-user-list.component';
import { MeraemiUsersLeadsSelfEmpDetailsComponent } from './views/meraemi-users-leads-self-emp-details/meraemi-users-leads-self-emp-details.component';
import { MeraemiCampaignComponent } from './views/meraemi-campaign/meraemi-campaign.component';

import { MeraEMISelfProLeadsComponent } from './views/mera-emi-self-pro-leads/mera-emi-self-pro-leads.component';

import { MeraEMIAllUsersSalariedComponent } from './views/mera-emi-all-users-salaried/mera-emi-all-users-salaried.component';
import { MeraEMIBusinessPartnerComponent } from './views/mera-emi-business-partner/mera-emi-business-partner.component';
import { MeraemiBusinessPartnerDetailsComponent } from './views/meraemi-business-partner-details/meraemi-business-partner-details.component';

import { MeraemiUsersLeadsProfessionalDetailsComponent } from './views/meraemi-users-leads-professional-details/meraemi-users-leads-professional-details.component';

import { MeraEMIActiveApplicationDashboardComponent } from './views/mera-emi-active-application-dashboard/mera-emi-active-application-dashboard.component';

import { MeraEMISelfEmpCrmstageComponent } from './views/mera-emi-self-emp-crmstage/mera-emi-self-emp-crmstage.component';
import { MeraEMIFacebookAdComponent } from './views/mera-emi-facebook-ad/mera-emi-facebook-ad.component';

import { MeraEMIWhatsappLeadsComponent } from './views/mera-emi-whatsapp-leads/mera-emi-whatsapp-leads.component';
import { MeraEMIWhatsappLeadsDetailsComponent } from './views/mera-emi-whatsapp-leads-details/mera-emi-whatsapp-leads-details.component';
import { MeraEMIUserRegisterComponent } from './views/mera-emi-user-register/mera-emi-user-register.component';
import { MeraEMIRegisterFormEditComponent } from './views/mera-emi-register-form-edit/mera-emi-register-form-edit.component';
import { MeraEMIDocuplodsUserComponent } from './views/mera-emi-docuplods-user/mera-emi-docuplods-user.component';
import { MeraEMICTWALeadListingComponent } from './views/mera-emi-ctwa-lead-listing/mera-emi-ctwa-lead-listing.component';
import { MeraEMICtwaUserDetailsComponent } from './views/mera-emi-ctwa-user-details/mera-emi-ctwa-user-details.component';
import { DocUplodsComponent }              from './views/doc-uplods/doc-uplods.component';



const routes: Route[] = [
    {path: '', pathMatch:'full', redirectTo:'meraemi-dashboard'},
    
    {path: 'register', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'set-password', component: SetPasswordComponent},
    {path: 'reset-password', component: ResetPasswordComponent},

    {path: 'meraemi-dashboard', component:  DashboardNewComponent, canActivate:[AuthGuard]},
    {path: 'ctwa', component:   MeraEMIWhatsappLeadsComponent, canActivate:[AuthGuard]},

    {path: 'loan-details', component: LoanDetailsComponent,canActivate:[AuthGuard]},  
    {path: 'lead-details', component: MisListDetailsComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-user-details', component: MeraemiAllUsersLeadsDetailsComponent, canActivate:[AuthGuard]},  

    {path: 'MeraEMI-iap-Pending-Stage', component: PendingFeedbackComponent, canActivate:[AuthGuard]},
    {path: 'tab', component: LeadDetilsComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMIStage-Active', component: MeraemiStageActiveComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMIStage-Cancel', component: MeraemiStageCancelComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMIStage-Transfer', component: MeraemiStageTransferComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-IAP-Follow-up-Stage', component: LeadStageComponent, canActivate:[AuthGuard]},
    {path: 'leads-stage-least', component: LeadsStageLeastComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-MIS-Detailed', component: DetailedMisComponent, canActivate:[AuthGuard]},
   
    {path: 'MeraEMI-eligibility-Pending-Stage', component: EligibilityFeedBackComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Eligibility-Follow-up-Status', component: EligibilityFollowUpStatusComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Facebook-Ad', component: FacebookAdComponent, canActivate:[AuthGuard]},
 
    {path: 'MeraEMI-Email-Temp', component:   MeraemiEmailComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Lender-Documents', component:   MeraemiLenderDocumentsComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-List-all-IAP-Error', component:   MeraEmiIAPErrorComponent, canActivate:[AuthGuard]},

    {path: 'MeraEMI-email-campaign', component:   MeraemiEmailCampaignComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-iap-Complete(Offline)', component:   MeraemiOfflineIapCompleteComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-mis-send-lender', component:   MisSendLenderComponent, canActivate:[AuthGuard]},
  
   
    {path: 'Mmeraemi-criteria-fail-details', component:   MmeraemiCriteriaFailDetailsComponent, canActivate:[AuthGuard]},
    
    {path: 'Meraemi-UserSend-Mail-list', component:   MeraemiSendUserMailComponent, canActivate:[AuthGuard]},
    {path: 'Meraemi-Email-Auto-Campain', component:   MeaemiEmailCampainComponent, canActivate:[AuthGuard]}, 
    {path: 'meraemi-hLBT-leads', component:   MeraemiHlbtLeadsComponent, canActivate:[AuthGuard]},
    {path: 'Meraemi-HLBT-Leads-Details', component:   MeraemiHlbtLeadsDetailsComponent, canActivate:[AuthGuard]},
    {path: 'Meraemi-Document-View', component:   MeraemiDocumentViewComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-crmStage-m', component:   MeaemiCrmStatusMComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-iap-complete-user-list', component:   MeraemiIapCompleteComponent, canActivate:[AuthGuard]},
    
    
 
    {path: 'MeraEMI-Self-Emp-Leads-Details', component:   MeraemiUsersLeadsSelfEmpDetailsComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Campaign-List', component:   MeraemiCampaignComponent, canActivate:[AuthGuard]},
    
    {path: 'MeraEMI-Salaried-Leads', component:   MeraEMIAllUsersSalariedComponent, canActivate:[AuthGuard]},
 
    {path: 'MeraEMI-professional-Leads-Details', component:   MeraemiUsersLeadsProfessionalDetailsComponent, canActivate:[AuthGuard]},

    {path: 'MeraEMI-selfEmp-list', component:   MeraEMISelfEmpCrmstageComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Facebook', component:   MeraEMIFacebookAdComponent, canActivate:[AuthGuard]},

    {path: 'MeraEMI-whatsapp-leads-details', component:   MeraEMIWhatsappLeadsDetailsComponent, canActivate:[AuthGuard]},   
   
    {path: 'meraEMI-user-register', component:   MeraEMIUserRegisterComponent, canActivate:[AuthGuard]},
    {path: 'meraEMI-registerEdit', component:   MeraEMIRegisterFormEditComponent, canActivate:[AuthGuard]},
   
    {path: 'meraemi-temp-data', component:   TempSelfEmpDataComponent, canActivate:[AuthGuard]},
   
    {path: 'meraemi-active-dashboard', component:   MeraEMIActiveApplicationDashboardComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-all-users-leads', component: MeraemiAllUsersLeadsComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-self-emp-leads', component:   MeraemiSelfEmpUserListComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-Self-emp-prof-leads', component:   MeraEMISelfProLeadsComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-business-partner', component:   MeraEMIBusinessPartnerComponent, canActivate:[AuthGuard]},
    {path: 'MeraEMI-Business-Partner-details', component:   MeraemiBusinessPartnerDetailsComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-contact-request', component:   MeraemiContactRequestComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-lender-contact', component:   LenderContactComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-criteria-fail', component:   MeraemiCriteriaFailComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-lender-product-offer-list', component:   LenderProdOfferListComponent, canActivate:[AuthGuard]},
    {path: 'meraemi-docuplods', component:   MeraEMIDocuplodsUserComponent, canActivate:[AuthGuard]},
    {path: 'ctwa-lead-listing', component:   MeraEMICTWALeadListingComponent, canActivate:[AuthGuard]},
    {path: 'ctwa-details', component:   MeraEMICtwaUserDetailsComponent, canActivate:[AuthGuard]},
    {path: 'docUplods', component:   DocUplodsComponent, canActivate:[AuthGuard]},
 
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes,{useHash: true});
